import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { IAppointmentData } from "../../../utils/interfaces";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";
import { auxiliarData } from "../AuxiliarData";

export async function PostAppointment(objData: IAppointmentData) {
  let url = `${endpoints.EHRPOSTER}/Appointment`;

  const data = auxiliarData.Appointment(objData);
  const headers = {
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
    Accept: "application/fhir+json",
  };

  const response = await useAxios
    .post(url, data, { headers, timeout: 20000 })
    .then((response) => {
      if (
        response.status !== HTTPResponseStatus.Created &&
        response.status !== HTTPResponseStatus.Success
      ) {
        throw new Error(`PostAppointment: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("PostAppointment ", error);
      // toast.error(`PostAppointment Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) {
    return response;
  }
}
