import { DialogTitle, Stack } from "@mui/material";

import { SectionEditDialog } from "./SectionEditDialog";

import { useForm, useWatch } from "react-hook-form";

import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { PatchSchedule } from "../../../services/fhir";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CommonDialog } from "../../../components/common/CommonDialog";

import {
  FormButtonGroup,
  FormDatePicker,
  FormSelectInput,
  FormTextInput,
} from "../../../components/form";
import { IEditScheduleDialog } from "../types";

export function EditScheduleDialog({
  editScheduleDialog,
  handleClose,
  scheduleData,
}: any) {
  const navigate = useNavigate();
  const [timeSchedule, setTimeSchedule] = useState(
    scheduleData?.extension?.find((ext: any) => ext.url.includes("period"))
      ?.valueDuration?.value
  );
  const [loading, setLoading] = useState(false);

  const defaultValues = {
    scheduleName: scheduleData?.name || "",
    // R4 Version  scheduleName: scheduleData?.extension?.find((ext: any) =>
    //   ext.url.includes("scheduleName")
    // )?.valueString || "",
    dataStart: dayjs(scheduleData?.planningHorizon?.start),
    dataEnd: dayjs(scheduleData?.planningHorizon?.end),
    advanceWarning: timeSchedule,
  };

  const methods = useForm<IEditScheduleDialog>({ defaultValues });
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = methods;

  const daysWarningSchedule = [
    { display: "Agenda completa", value: timeSchedule },
    { display: "1 semana", value: 7 },
    { display: "2 semanas", value: 14 },
    { display: "3 semanas", value: 21 },
    { display: "1 mês", value: 30 },
    { display: "2 meses", value: 60 },
    { display: "3 meses", value: 90 },
    { display: "6 meses", value: 180 },
  ].filter((element) => element.value <= timeSchedule);

  const onSubmit = async (data: IEditScheduleDialog) => {
    setLoading(true);
    const patchOperations = [
      !defaultValues.dataStart.isSame(data.dataStart) && {
        op: "replace",
        path: "/planningHorizon/start",
        value: dayjs(data.dataStart).toISOString(),
      },
      !defaultValues.dataEnd.isSame(data.dataEnd) && {
        op: "replace",
        path: "/planningHorizon/end",
        value: dayjs(data.dataEnd).toISOString(),
      },
      data.scheduleName !== defaultValues.scheduleName && {
        op: "replace",
        path: `/name`,
        value: data?.scheduleName,
      },
    ]?.filter((el) => el);

    try {
      await PatchSchedule(scheduleData?.id, patchOperations);
      toast.success("Agenda atualizada com sucesso!");
      navigate("/schedule");
    } catch (err) {
      console.log("err:", err);
    } finally {
      setLoading(false);
    }
  };

  let watchdatastart = useWatch({ name: "dataStart", control });
  let watchdataend = useWatch({ name: "dataEnd", control });

  useEffect(() => {
    if (watchdatastart === null || watchdataend === null) {
      setTimeSchedule(0);
      return;
    }

    const initialDate = defaultValues.dataStart.isBefore(dayjs())
      ? dayjs()
      : dayjs(watchdatastart);
    const endDate = dayjs(watchdataend);
    const days = endDate.diff(initialDate, "day");

    setTimeSchedule(days);
  }, [watchdatastart, watchdataend]);

  return (
    <CommonDialog isOpen={editScheduleDialog} handleClose={handleClose}>
      <DialogTitle textAlign="center">
        Editar configurações da agenda
      </DialogTitle>

      <SectionEditDialog
        title="Nome da agenda"
        description="Escolha um nome para essa agenda"
      >
        <FormTextInput
          name="scheduleName"
          label="Nome Agenda"
          control={control}
          required
        />
      </SectionEditDialog>

      <Stack mt={5}>
        <SectionEditDialog
          title="Duração da agenda"
          description="Esta ação remove a disponibilidade de horários fora do novo intervalo de duração da agenda mas não exclui os horários já agendados."
        >
          <Stack direction="row" spacing={2}>
            <FormDatePicker
              readonly={defaultValues.dataStart.isBefore(dayjs())}
              name="dataStart"
              label="Início"
              control={control}
              required
            />
            <FormDatePicker
              name="dataEnd"
              minDate={dayjs()}
              label="Fim"
              control={control}
              required
            />
          </Stack>
        </SectionEditDialog>
      </Stack>
      <Stack mt={5} mb={5}>
        <SectionEditDialog
          title="Antecedência de liberação de vagas"
          description="Antecedência em que as vagas para agendamento de consultas serão liberadas para os pacientes."
        >
          <Stack direction="row" spacing={2}>
            <FormSelectInput
              name="advanceWarning"
              label="Período em que as vagas devem ser liberadas"
              control={control}
              selectOptions={daysWarningSchedule}
              required
            />
          </Stack>
        </SectionEditDialog>
      </Stack>

      <FormButtonGroup
        disabled={!isDirty}
        onGoBackButtonClick={handleClose}
        onNextButtonClick={handleSubmit(onSubmit)}
        nextButtonText="Salvar"
        goBackButtonText="Cancelar"
        loading={loading}
      />
    </CommonDialog>
  );
}
