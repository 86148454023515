import { IconButton, Stack, Typography } from "@mui/material";

interface IProps {
  text: string;
  onClick: any;
  icon: any;
  transparent?: boolean;
  noPadding?: boolean;
}

export function MobileMenuItens({
  text,
  onClick,
  icon,
  transparent,
  noPadding,
}: IProps) {
  return (
    <Stack width="100%" marginY={noPadding ? 0 : 1}>
      <IconButton
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          width: "100%",
          paddingX: 0,
          gap: 1,
        }}
        onClick={onClick}
      >
        {icon}
        <Typography
          variant="body1"
          color={transparent ? "neutral600.main" : "neutral700.main"}
          fontWeight={600}
        >
          {text}
        </Typography>
      </IconButton>
    </Stack>
  );
}
