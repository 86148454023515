import { toast } from "react-toastify";
import { endpoints } from "../../configs/Settings";
import { HTTPResponseStatus } from "../../utils/enum";
import { useAuth } from "../../utils/useAuth";
import { useAxios } from "../../utils/useAxios";

type ConsentsType = "granted" | "received";

interface IProps {
  identifier: string;
  type: ConsentsType;
}

/**
 * @param identifier cpf
 * @param type tipo de consentimento
 * @returns Array
 */
export async function GetConsents({ identifier, type }: IProps) {
  const url = `${endpoints.CONSENT}/${
    type === "granted" ? "recurso" : "sujeito"
  }/${identifier}/consentimento`;

  const headers = {
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const response = await useAxios
    .get(url, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`GetAccessHistory: ${response.status}`);
      }
      return { status: HTTPResponseStatus.Success, data: response.data };
    })
    .catch((error) => {
      console.error("GetConsents ", error);
      // toast.error(`GetConsents Erro: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
