import { Keyboard } from "@mui/icons-material";
import { Box, Chip, Divider, Popover, Typography } from "@mui/material";
import { useState } from "react";

export const ShortCutsViewer = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const shortcutsTabs = [
    { key: "Ctrl + Seta para baixo", description: "Navegar para proxima aba" },
    { key: "Ctrl + Seta para cima", description: "Navegar para aba anterior" },
    // { key: "Ctrl + 1", description: "Acessar aba de (Anamnese)" },
    // { key: "Ctrl + 2", description: "Acessar aba de (Exames Físicos)" },
    // { key: "Ctrl + 3", description: "Acessar aba de (Problemas)" },
    // { key: "Ctrl + 4", description: "Acessar aba de (Planos de Tratamento)" },
    // { key: "Ctrl + 5", description: "Acessar aba de (Condutas Orientadas)" },
    // { key: "Ctrl + 6", description: "Acessar aba de (Diagnósticos)" },
    // { key: "Ctrl + 7", description: "Acessar aba de (Resultado de Exame)" },
    // {
    //   key: "Ctrl + 8",
    //   description: "Acessar aba de (Tratamentos Declarados)",
    // },
  ];

  const shortcutsAreas = [
    {
      key: "Ctrl + Seta para direita",
      description: "Navegar para proxima aba",
    },
    {
      key: "Ctrl + Seta para esquerda",
      description: "Navegar para aba anterior",
    },
    // { key: "Alt + 1", description: "Acessar (Atendimento)" },
    // { key: "Alt + 2", description: "Acessar (Intervenções)" },
    // { key: "Alt + 3", description: "Acessar (Documentos e Assinatura)" },
  ];
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Chip
        icon={<Keyboard sx={{ cursor: "pointer", color: "#ccc" }} />}
        label="Atalhos"
        onClick={handleClick}
        style={{
          cursor: "pointer",
          width: "fit-content",
          paddingLeft: 4,
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              width: "fit-content",
              padding: 4,
            },
          },
        }}
        sx={{ zIndex: 9999999, padding: 8 }}
      >
        <Typography
          variant="body1"
          textAlign={"center"}
          sx={{ p: 2, fontWeight: "500", fontSize: "16px" }}
        >
          Lista de atalhos
        </Typography>

        <Divider />

        <Typography
          variant="body1"
          textAlign={"left"}
          sx={{ p: 2, fontWeight: "500" }}
        >
          Navegar entre passos
        </Typography>
        {shortcutsAreas.map((el) => (
          <Box
            key={el.key}
            sx={{ display: "flex", p: 1, alignItems: "center", gap: 4 }}
          >
            <Chip
              label={el.key}
              key={el.key}
              size="small"
              sx={{ p: 0.5, fontWeight: "500" }}
            />
            <Typography>{el.description}</Typography>
          </Box>
        ))}

        <Typography
          variant="body1"
          textAlign={"left"}
          sx={{ p: 2, fontWeight: "500" }}
        >
          Navegar entre abas
        </Typography>

        {shortcutsTabs.map((el) => (
          <Box
            key={el.key}
            sx={{ display: "flex", p: 1, alignItems: "center", gap: 4 }}
          >
            <Chip
              label={el.key}
              key={el.key}
              size="small"
              sx={{ p: 0.5, fontWeight: "500" }}
            />
            <Typography>{el.description}</Typography>
          </Box>
        ))}
      </Popover>
    </>
  );
};
