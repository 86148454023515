import { GenericTimeline } from "../GenericTimeline";
import { Button, Stack } from "@mui/material";
import { DiagnosticosAccordion } from "./DiagnosticosAccordion";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { useFhirData } from "../../../../utils/useFhirData";
import { useDict } from "../../../../utils/useDict";

export function DiagnoticosHistory({
  reset,
  filter,
  setEditCondition,
}: {
  reset: any;
  filter: any;
  setEditCondition: (data: any) => void;
}) {
  const { summaryData, loadingDiagnostics } = useMedicalCareContext();

  const typeDiagnostic = {
    allergy: 1,
    condition: 0,
  };

  const condition = useFhirData.extractPatientSummaryResource(
    summaryData,
    "Condition"
  );
  const allergy = useFhirData.extractPatientSummaryResource(
    summaryData,
    "AllergyIntolerance"
  );

  const allergyData = allergy?.[0]?.entry?.map((el: any) => ({
    fullResource: el?.resource,
    type: "alergia",
    id: el?.resource?.id,
    criticality: useDict.criticality[el?.resource?.criticality] || "-",
    category: useDict.category[el?.resource?.category?.[0]] || "-",
    verificationStatus:
      useDict.verificationStatus[
        el?.resource?.verificationStatus?.coding?.[0]?.code
      ] || "",
    clinicalStatus:
      useDict.clinicalStatus[el?.resource?.clinicalStatus?.coding?.[0]?.code] ||
      "",
    description: el.resource?.code?.text,
    awnserDate: el?.resource?.recordedDate,
    displayInfo: {
      practitioner: useFhirData.findParticipant(
        el?.resource?.participant,
        "Practitioner"
      ).display,
      healthcare: null,
    },
  }));

  const conditionData = condition?.[0]?.entry?.map((el: any) => {
    return {
      fullResource: el?.resource,
      id: el?.resource?.id,
      cid10: el?.resource?.code?.coding?.[0]?.code,
      description: el?.resource?.code?.coding?.[0]?.display || "",
      awnserDate: el?.resource?.recordedDate,
      displayInfo: {
        practitioner: useFhirData.findParticipant(
          el?.resource?.participant,
          "Practitioner"
        ).display,
        healthcare: null,
      },
      verificationStatus:
        useDict.verificationStatus[
          el?.resource?.verificationStatus?.coding?.[0]?.code
        ] || "",
      clinicalStatus:
        useDict.clinicalStatus[
          el?.resource?.clinicalStatus?.coding?.[0]?.code
        ] || "",
      practitioner: useFhirData.findParticipant(
        el?.resource?.participant,
        "Practitioner"
      )?.display,
      informant: useFhirData.findParticipant(
        el?.resource?.participant,
        "Patient"
      )
        ? "Paciente"
        : "Profissional",
    };
  });

  let filterData =
    filter === typeDiagnostic?.condition ? conditionData : allergyData;
  if (loadingDiagnostics) {
    filterData?.unshift({ loadingData: loadingDiagnostics });
  }

  return (
    <Stack spacing={1}>
      <GenericTimeline
        data={filterData}
        // dotColor={style}
        component={(data: any, index: number) => (
          <DiagnosticosAccordion
            // style={style}
            reset={reset}
            data={data}
            page={filter}
            index={index}
            setEditCondition={setEditCondition}
          />
        )}
      />
    </Stack>
  );
}
