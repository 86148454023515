import { DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";

import { useSessionContext } from "../../../contexts/SessionContext";

import { toast } from "react-toastify";
import { FormButtonGroup } from "../../../components/form";

import { InativatePractitionerDialog } from "../../ManagePractitioners/components/InativatePractitionerDialog";
import { ManageInativateSchedule } from "./ManageInativateSchedule";
import { PatchLocation } from "../../../services/fhir/location/PatchLocation";
import { useLocationContext } from "../../../contexts/LocationContext";

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  locationId: any;
}

export function InativateConfirmDialog({
  isOpen,
  setIsOpen,
  locationId,
}: IProps) {
  const { access } = useSessionContext();
  const { refetchLocation } = useLocationContext();
  const [loading, setLoading] = useState(false);

  const [disabled, setDisabled] = useState<boolean>(true);

  const handleChangeActive = async (id: any, active: boolean) => {
    setLoading(true);
    try {
      await PatchLocation({
        id: locationId,
        operation: "replace",
        path: "/status",
        content: "inactive",
      });
      refetchLocation();

      toast.success("A localização foi desativada com sucesso!");
    } catch (err) {
      toast.error("Não foi possível concluir a operação.");
      console.log("err", err);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  function handleClose() {
    setIsOpen(false);
  }

  return (
    <InativatePractitionerDialog
      handleClose={handleClose}
      isOpen={isOpen}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle textAlign="center"> Inativar localização </DialogTitle>
      <DialogContent>
        <Stack
          minWidth="100%"
          height="100%"
          flex="1"
          justifyContent="center"
          spacing={2}
        >
          <ManageInativateSchedule
            setDisabled={setDisabled}
            locationId={locationId}
          ></ManageInativateSchedule>
        </Stack>
        <Typography
          variant="subtitle2"
          textAlign="center"
          color="neutral700.main"
          fontWeight="400"
          m={{ xs: 2, md: 5 }}
        >
          Após a confirmação a localização selecionada será inativada e as suas
          agendas canceladas.
        </Typography>

        <Typography
          variant="subtitle2"
          textAlign="center"
          color="neutral700.main"
          fontWeight="bold"
          m={{ xs: 2, md: 5 }}
        >
          As consultas previamente marcadas não serão excluidas automaticamente.
        </Typography>
        <Stack display="flex" mt={3} direction="row">
          <FormButtonGroup
            onGoBackButtonClick={() => setIsOpen(false)}
            onNextButtonClick={async () =>
              await handleChangeActive(locationId, false)
            }
            disabled={disabled}
            goBackButtonText="Cancelar"
            nextButtonText="Confirmar"
            loading={loading}
          />
        </Stack>
      </DialogContent>
    </InativatePractitionerDialog>
  );
}
