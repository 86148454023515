import { Dialog, Grid, IconButton, Slide, Stack } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import {
  ReactElement,
  ReactEventHandler,
  Ref,
  forwardRef,
  useEffect,
  useState,
} from "react";
import { PatientSummary } from "../../ehrrecords/components/PatientSummary";
import { BundlePatientSummary } from "../../../services/fhir";
import { PatientData } from "../../ehrrecords/components/PatientData";
import { Close } from "@mui/icons-material";
import { CircularLoading } from "../../../components/common";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export function EHRRecordsDialog({
  open,
  handleClose,
  references,
}: {
  open: boolean;
  handleClose: ReactEventHandler;
  references: any;
}) {
  const [loading, setLoading] = useState<boolean>(true);
  const [summaryData, setSummaryData] = useState();

  useEffect(() => {
    setLoading(true);
    async function GetBundle() {
      try {
        const bundleResponse = await BundlePatientSummary({
          identifier: references?.subject,
        });

        setSummaryData(bundleResponse);
      } catch (err) {
        console.log("PatientSummary error:", err);
      } finally {
        setLoading(false);
      }
    }
    if (references?.subject && open) GetBundle();
  }, [references?.subject, open]);

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <IconButton
        onClick={handleClose}
        sx={{ position: "absolute", right: 5, top: 5 }}
      >
        <Close />
      </IconButton>
      <Stack p={2} rowGap={2}>
        <Grid container direction="row" rowSpacing={3} columnSpacing={3}>
          <PatientData patientId={references?.subject} />
        </Grid>
        {loading ? (
          <Stack paddingY={2}>
            <CircularLoading />
          </Stack>
        ) : (
          <Stack>
            <PatientSummary summary={summaryData} />
          </Stack>
        )}
      </Stack>
    </Dialog>
  );
}
