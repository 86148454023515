import { Button, Grid, Stack } from "@mui/material";
import { FormAutoComplete, FormTextInput } from "../../../components/form";
import { CustomMedicationOption } from "../../medicaldocumentation/components/prescription/CustomMedicationOption";

import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Add, Edit } from "@mui/icons-material";

export function ProcedureAndCertificate({
  control,
  setValue,
  action,
  setClean,
  handleSubmit,
  isEdit,
}: any) {
  const [render, setRender] = useState<boolean>(false);
  const onSubmit = async (data: any) => {
    try {
      if (!data.procedure) {
        toast.warn("Selecione algum item pra efetuar a prescrição");
        throw new Error("Formulário vazio");
      } else {
        const auxForm = [
          data?.quantity?.label && `${data?.quantity?.label}`,
          data?.form?.label && `${data?.form?.label}`,
          data?.frequency?.label && `a cada ${data?.frequency?.label}`,
          data?.time?.label && `por ${data?.time?.label}`,
        ]
          ?.filter((e: string) => e)
          ?.join(" ");
        const auxItem = {
          name: data?.procedure?.name,
          tag: data?.procedure?.tag,
          inputValue: data?.procedure,
          quantity: data?.quantity,
          frequency: data?.frequency,
          formValue: data?.form,
          time: data?.time,
          note: data?.note,
          form: auxForm,
          type: "procedure",
          tabValue: "1",
        };
        action(auxItem);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setRender(false);
      setClean(true);
    }
  };

  useEffect(() => {
    if (!render) setRender(true);
  }, [render]);
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          {render && (
            <FormAutoComplete
              required
              freeSolo
              name="procedure"
              onInputChange={(e: any) => {
                if (e?.target?.value?.length)
                  setValue("procedure", {
                    name: e?.target?.value,
                    label: e?.target?.value,
                    value: e?.target?.value,
                  });
              }}
              control={control}
              label="Selecione o procedimento"
              renderOption={(option: any) => (
                <CustomMedicationOption option={option} />
              )}
              selectOptions={[]}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          {render && (
            <FormTextInput
              multiline
              minRows={3}
              label="Observação"
              name="note"
              control={control}
            />
          )}
        </Grid>
      </Grid>
      <Stack width="100%" direction="row" mt={2} justifyContent="right">
        <Button
          sx={{ width: "fit-content", border: "1px solid #007C6D" }}
          onClick={handleSubmit(onSubmit)}
          variant="text"
        >
          {isEdit ? <Edit /> : <Add />}
        </Button>
      </Stack>
    </>
  );
}
