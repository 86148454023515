import { endpoints } from "../../../configs/Settings";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";
import { HTTPResponseStatus } from "../../../utils/enum";
import { toast } from "react-toastify";

export async function PatchSchedule(id: string, objData: any) {
  const url = `${endpoints.WORKFLOWFHIR}/Schedule/${id}`;

  const data = objData;
  const headers = {
    Accept: "application/fhir+json",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/json-patch+json",
  };
  const response = await useAxios
    .patch(url, data, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`PatchSchedule: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("PatchSchedule ", error);
      // toast.error(`PatchSchedule Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
