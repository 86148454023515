import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useSessionContext } from "../../../contexts/SessionContext";
import { useFormatter } from "../../../utils/useFormatter";
import { GenericTable } from "../../../components/GenericTable/GenericTable";
import { isMobile } from "react-device-detect";
import { Check, Warning } from "@mui/icons-material";

export function LegalGuardianList() {
  const { guardianRelated } = useSessionContext();

  const columns = [
    { column: "Nome", rowName: "name" },
    { column: "CPF", rowName: "identifier" },
    { column: "Relação", rowName: "relationship" },
    { column: "Verificado", rowName: "active" },
    { column: "Status", rowName: "ends" },
  ];

  const mobileColumns = [
    { column: "Nome", rowName: "name" },
    { column: "CPF", rowName: "identifier" },
  ];

  const rows = guardianRelated?.map((el: any) => ({
    name: useFormatter.formatNameDisplay(el?.resource?.name?.[0]?.text || ""),
    identifier: useFormatter.formatCPF(
      el?.resource?.identifier?.[0]?.value || ""
    ),
    relationship: useFormatter.translateRelationship(
      el?.resource?.relationship?.[0]?.text || ""
    ),
    active: el?.resource?.active ? (
      <Tooltip title="Verificação realizada">
        <Check color="primary" />
      </Tooltip>
    ) : (
      <>
        {el?.resource?.period?.start ? (
          <Tooltip title="Verificação pendente">
            <Warning sx={{ color: "warning500.main" }} />
          </Tooltip>
        ) : (
          <> - </>
        )}
      </>
    ),
    ends: el?.resource?.period?.end ? "Inativo" : "Ativo",
  }));

  return (
    <Stack>
      <Accordion
        defaultExpanded
        disableGutters
        sx={{
          border: "1px solid #ccc",
          boxShadow: "1px 1px 8px 1px rgba(0, 0, 0, 0.2)",
          position: "initial",
          marginBottom: "20px",
        }}
      >
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Lista de responsáveis legais</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <GenericTable
            tableColumns={isMobile ? mobileColumns : columns}
            tableRows={rows}
          />
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}
