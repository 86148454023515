import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { GetHealthcareService } from "../services/fhir";
import { useSessionContext } from "./SessionContext";
import { oids } from "../configs/Settings";

interface HealthcareServiceContextValue {
  healthcareService: fhir5.BundleEntry<fhir5.HealthcareService>[] | undefined;
  refetchHealthcareService: Function;
  healthcareLoading: boolean;
}

export const HealthcareServiceContext =
  createContext<HealthcareServiceContextValue>(null!);

export function HealthcareServiceContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { access } = useSessionContext();

  const [healthcareService, setHealthcareService] =
    useState<fhir5.BundleEntry<fhir5.HealthcareService>[]>();
  const [refetch, setRefetch] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  function refetchHealthcareService() {
    setRefetch(!refetch);
  }

  useEffect(() => {
    setLoading(true);
    async function fetchHealthcareService() {
      const healhcareResponse = await GetHealthcareService(
        "organization",
        `${oids.cnpj}-${access?.organizationId}`
      );

      setHealthcareService(healhcareResponse?.entry);
    }
    try {
      if (access?.organizationId) fetchHealthcareService();
    } catch (err) {
      console.log("HealthcareService", err);
    } finally {
      setLoading(false);
    }
  }, [access?.organizationId, refetch]);

  const contextValue = {
    healthcareService,
    refetchHealthcareService,
    healthcareLoading: loading,
  };

  return (
    <HealthcareServiceContext.Provider value={contextValue}>
      {children}
    </HealthcareServiceContext.Provider>
  );
}

export function useHealthcareServiceContext() {
  return useContext(HealthcareServiceContext);
}
