import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSessionContext } from "./SessionContext";
import { GetDocumentReference } from "../services/fhir";

interface PatientHealthCareInformationContextValue {
  documentReferenceExam:
    | fhir5.BundleEntry<fhir5.DocumentReference>[]
    | undefined;
  documentReferenceNote:
    | fhir5.BundleEntry<fhir5.DocumentReference>[]
    | undefined;
  documentReferencePrescription:
    | fhir5.BundleEntry<fhir5.DocumentReference>[]
    | undefined;
  medicationRequestPrescription:
    | fhir5.BundleEntry<fhir5.MedicationRequest>[]
    | undefined;
  results: fhir5.BundleEntry<fhir5.DocumentReference>[] | undefined;
  serviceRequest: fhir5.BundleEntry<fhir5.DocumentReference>[] | undefined;
  setRefetchServiceRequest: Dispatch<SetStateAction<boolean>>;
  setRefetchNote: Dispatch<SetStateAction<boolean>>;
  setRefetchPrescription: Dispatch<SetStateAction<boolean>>;
}

export const PatientHealthCareInformationContext =
  createContext<PatientHealthCareInformationContextValue>(null!);

export function PatientHealthCareInformationContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { user, access } = useSessionContext();

  const [documentReferenceExam, setDocumentReferenceExam] = useState<any>();
  const [documentReferenceNote, setDocumentReferenceNote] = useState<any>();
  const [documentReferencePrescription, setDocumentReferencePrescription] =
    useState<any>();

  const [medicationRequestPrescription, setMedicationRequestPrescription] =
    useState<any>();

  const [serviceRequest, setServiceRequest] = useState<any>();
  const [results, setResults] = useState<any>();

  const [refetchServiceRequest, setRefetchServiceRequest] =
    useState<boolean>(true);
  const [refetchNote, setRefetchNote] = useState<boolean>(true);
  const [refetchPrescription, setRefetchPrescription] = useState<boolean>(true);

  // Resultados de exames
  useEffect(() => {
    async function fetchServices() {
      const serviceReqResponse = await GetDocumentReference({
        patient: user?.username,
        category: "service-request",
      });

      const resultsResponse = await GetDocumentReference({
        patient: user?.username,
        category: "resultado",
      });

      const documentReference = serviceReqResponse?.entry?.filter(
        (el: any) => el?.resource?.resourceType === "DocumentReference"
      );

      const serviceRequest = serviceReqResponse?.entry?.filter(
        (el: any) => el?.resource?.resourceType === "ServiceRequest"
      );

      setResults(resultsResponse?.entry);
      setDocumentReferenceExam(documentReference);
      setServiceRequest(serviceRequest);
      setRefetchServiceRequest(false);
    }
    if (refetchServiceRequest && user?.username && access?.type === "patient") {
      fetchServices();
    }
  }, [user, refetchServiceRequest, access?.type]);

  // Atestados
  useEffect(() => {
    async function fetchCertificates() {
      const response = await GetDocumentReference({
        patient: user.username,
        category: "atestado",
      });

      const documentReference = response?.entry?.filter(
        (el: any) => el?.resource?.resourceType === "DocumentReference"
      );

      setDocumentReferenceNote(documentReference);
      setRefetchNote(false);
    }
    if (refetchNote && user?.username && access?.type === "patient") {
      fetchCertificates();
    }
  }, [user, refetchNote, access?.type]);

  // Prescrições
  useEffect(() => {
    async function fetchPrescriptions() {
      const response = await GetDocumentReference({
        patient: user.username,
        category: "prescricao",
      });

      const documentReference = response?.entry?.filter(
        (el: any) => el?.resource?.resourceType === "DocumentReference"
      );

      const medicationRequest = response?.entry?.filter(
        (el: any) => el?.resource?.resourceType === "MedicationRequest"
      );

      setDocumentReferencePrescription(documentReference);
      setMedicationRequestPrescription(medicationRequest);
      setRefetchPrescription(false);
    }
    if (refetchPrescription && user?.username && access?.type === "patient") {
      fetchPrescriptions();
    }
  }, [user, refetchPrescription, access?.type]);

  const contextValue = {
    documentReferenceExam,
    documentReferenceNote,
    documentReferencePrescription,
    medicationRequestPrescription,
    results,
    serviceRequest,
    setRefetchServiceRequest,
    setRefetchNote,
    setRefetchPrescription,
  };

  return (
    <PatientHealthCareInformationContext.Provider value={contextValue}>
      {children}
    </PatientHealthCareInformationContext.Provider>
  );
}

export function usePatientHealthCareInformationContext() {
  return useContext(PatientHealthCareInformationContext);
}
