import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { IPatchOperation } from "../../../utils/interfaces";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";
import { auxiliarData } from "../AuxiliarData";

interface IProps {
  id: string;
  operation: string;
  path: string;
  content?: any;
}

export async function PatchLocation({ id, operation, path, content }: IProps) {
  const url = `${endpoints.WORKFLOWFHIR}/Location/${id}`;

  let operations: IPatchOperation[] = [];

  operations.push({
    op: operation,
    path: path,
    value: content,
  });
  const data = auxiliarData.Patch({
    operations,
  });

  const headers = {
    Accept: "application/fhir+json",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/json-patch+json",
  };

  const response = await useAxios
    .patch(url, data, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`PatchLocation: ${response.status}`);
      }
      return { data: response.data, status: response.status };
    })
    .catch((error) => {
      console.error("PatchLocation ", error);
      // toast.error(`PatchLocation Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
