import { Stack, Typography } from "@mui/material";

type IProps = {
  label: string;
  value: string;
  format?: any;
};

export function LineInformation({ label, value, format }: IProps) {
  return (
    <Stack direction="column" margin="16px 0px" gap={1}>
      <Typography variant="body2" fontWeight={600} color="neutral700.main">
        {label}
      </Typography>
      <Typography variant="body2" color="neutral700.main">
        {format ? format(value) : value}
      </Typography>
    </Stack>
  );
}
