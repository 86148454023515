import { DocumentReference } from "fhir/r5";
import { GenericTable } from "../../../../../../components/GenericTable/GenericTable";
import { CertificateActions } from "../../CertificateDocs/CertificateActions";
import dayjs from "dayjs";

export function NoteReport({
  data,
  handleOpen,
  setBinaryUrl,
  sign,
  setRefetch,
}: any) {
  const columns = [
    { column: "Profissional", rowName: "practitioner" },

    { column: "Data", rowName: "date" },
    {
      column: "Especialidade",
      rowName: "specialty",
    },
  ];

  const rowData =
    data
      ?.filter((e: any) => {
        return sign
          ? e?.resource?.securityLabel?.[0]?.coding?.[0]?.code ===
              "ProofOfOrigin"
          : e?.resource?.securityLabel?.[0]?.coding?.[0]?.code !==
              "ProofOfOrigin";
      })
      ?.map((el: fhir5.BundleEntry<DocumentReference | any>, index: number) => {
        const auxPractitioner = el?.resource?.contained.find(
          (contained: any) => contained?.resourceType === "Practitioner"
        );

        const auxSpecialty =
          el?.resource?.practiceSetting?.coding?.[0]?.display;

        return {
          fullResource: el?.resource,
          practitioner:
            auxPractitioner?.name?.[0]?.given?.[0] ||
            auxPractitioner?.identifier?.[0]?.value,
          date: dayjs(el?.resource?.date).format("DD/MM/YYYY") || "-",
          specialty: auxSpecialty || "-",
          sign,
          binaryUrl: el?.resource?.content?.[0]?.attachment?.url || "",
        };
      }) || [];

  return (
    <GenericTable
      tableColumns={columns}
      tableRows={rowData}
      actions={(rowData: any) => (
        <CertificateActions
          setRefetch={setRefetch}
          row={rowData}
          handleOpen={handleOpen}
          setBinaryUrl={setBinaryUrl}
        />
      )}
    />
  );
}
