import { QuestionnaireResponse } from "../../../../types";
import { BuildQuestionAnswer } from "./BuildQuestionAnswer";
import { oids } from "../../../../../../configs/Settings";
import { GetCondition } from "../../../../../../services/fhir/patientsummary/condition/GetCondition";

export function groupResponsesByBaseKey(obj: object) {
  const result: { [key: string]: any[] } = {};

  Object.entries(obj).forEach(([key, value]) => {
    const baseKey = key.replace(/_.*/, "");

    if (!result[baseKey]) {
      result[baseKey] = [];
    }
    result[baseKey].push(value);
  });

  return result;
}

export const BuildQuestionnaireResponse = function ({
  questionnaire,
  response,
  user,
  state,
  questionnaireID,
  status,
}: {
  questionnaire: any;
  response: any;
  user?: any;
  state?: any;
  questionnaireID: string;
  status?:
    | "in-progress"
    | "completed"
    | "amended"
    | "entered-in-error"
    | "stopped";
}) {
  const responsesByBaseKey = groupResponsesByBaseKey(response);
  async function BuildQuestionItem() {
    const conditions = await GetCondition({
      patient: state.patientId,
      encounter: state.encounterReference?.id,
    });

    console.log("conditions", conditions?.entry);
  }

  let questionAnswers: any = [];

  questionnaire?.item?.forEach((item: any, index: number) => {
    const questionnaireItemAnswers: any = [];

    const questionAnswer = BuildQuestionAnswer(item, responsesByBaseKey);

    questionnaireItemAnswers.push(questionAnswer);

    questionAnswers.push(...questionnaireItemAnswers);
  });

  const QuestionnaireResponse: QuestionnaireResponse = {
    resourceType: "QuestionnaireResponse",
    questionnaire: "Questionnaire/Atendimento",
    status: status || "in-progress",
    authored: new Date(),
    author: {
      reference: `Practitioner/${oids.cpf}-${user.username}`,
    },
    subject: {
      reference: `Patient?identifier=urn:oid:${oids.cpf}|${state.patientId}`,
    },
    encounter: { reference: `Encounter/${state.encounterReference?.id}` },
    item: questionAnswers,
  };

  return QuestionnaireResponse;
};
