import { Dialog, DialogTitle, IconButton, Stack } from "@mui/material";

import { Condutas } from "./Condutas/Condutas";
import { Problemas } from "./Problemas/Problemas";
import { Diagnosticos } from "./Diagnosticos/Diagnosticos";
import { Close } from "@mui/icons-material";

interface IProps {
  type: 0 | 1 | 2;
  isOpen: boolean;
  handleClose: any;
  control: any;
}

export function QuickActionsDialog({
  type,
  isOpen,
  handleClose,
  control,
}: IProps) {
  function renderSelectedForm() {
    if (type === 0)
      return (
        <Diagnosticos dialog handleClose={handleClose} control={control} />
      );
    if (type === 1) return <Condutas />;
    else return <Problemas />;
  }
  const handleDialogClose = (event: any, reason: string) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      handleClose(event, reason);
    }
  };

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          borderRadius: "5px",
          padding: 4,
          minWidth: "600px",
        },
      }}
      maxWidth="xl"
      onClose={handleDialogClose}
    >
      <IconButton
        onClick={() => handleClose(null, "iconButtonClick")}
        sx={{ position: "absolute", right: 5, top: 5 }}
      >
        <Close />
      </IconButton>
      <DialogTitle textAlign="center">
        {type === 0 && "Adicionar Diagnóstico"}
        {type === 1 && "Adicionar Conduta"}
        {type === 2 && "Adicionar Problema"}
      </DialogTitle>
      <Stack direction="row">{renderSelectedForm()}</Stack>
    </Dialog>
  );
}
