import {
  CircularProgress,
  IconButton,
  Stack,
  styled,
  TableCell,
  Tooltip,
} from "@mui/material";
import { isAndroid, isMobile, isMobileSafari } from "react-device-detect";
import {
  ContentPaste,
  Autorenew,
  Description,
  Edit,
  FileDownload,
  FileUpload,
  Visibility,
  Delete,
} from "@mui/icons-material";
import { useRef, useState } from "react";
import { ConfirmDocumentDialog } from "../../../../examsolicitation/components/ConfirmDocumentDialog";
import dayjs from "dayjs";
import { useSessionContext } from "../../../../../contexts/SessionContext";
import { GetBinary } from "../../../../../services/fhir/patientsummary/Binary/GetBinary";
import { usePractitionerRoleContext } from "../../../../../contexts/PractitionerRoleContext";
import { useLocationContext } from "../../../../../contexts/LocationContext";
import { useInterventionsContext } from "../../../../../contexts/InterventionsContext";
import { GetAppointment, GetPatient } from "../../../../../services/fhir";
import { useFhirData } from "../../../../../utils/useFhirData";
import { PostDocumentReference } from "../../../../../services/fhir/patientsummary/documentreference/PostDocumentReference";
import { SignatureDialog } from "../../../../medicaldocumentation/components/prescription/SignatureDialog";
import { DocumentVisualization } from "../../../../medicaldocumentation/components/DocumentVisualization";
import { Base64SignPDF } from "../../../../../services/dsig";
import { toast } from "react-toastify";
import { GetDocumentReferenceById } from "../../../../../services/fhir/documentreference/GetDocumentReferenceById";
import { PatchEHRPoster } from "../../../../../services/fhir/patientsummary/PatchEHRPoster";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export function ServiceRequestActions({
  row,
  handleOpen,
  setBinaryUrl,
  setRefetch,
  openVisualizer,
  verifyVisualizer,
}: any) {
  const { user } = useSessionContext();
  const { access } = useSessionContext();
  const { userPractitionerRole } = usePractitionerRoleContext();
  const { location } = useLocationContext();

  const [patient, setPatient] = useState<any>();
  const [locationData, setLocationData] = useState<any>();

  const { action, selectedInterventions, setInterventionsList } =
    useInterventionsContext();

  const [originalDocument, setOriginalDocument] = useState<any>();
  const [base64, setBase64] = useState<any>();

  const [signatureDialogOpen, setSignatureDialogOpen] =
    useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [item, setItem] = useState<any>([]);

  const auxOrganization = row?.fullResource?.author
    ?.find((el: any) => el?.reference?.includes("Organization"))
    ?.reference?.split("/")?.[1];

  const auxPractitioner = row?.fullResource?.author
    ?.find((el: any) => el?.reference?.includes("Practitioner"))
    ?.reference?.split("/")?.[1];

  const auxReplaces = `identifier=${row?.fullResource?.identifier?.[1]?.system}%7C${row?.fullResource?.identifier?.[1]?.value}`;

  const replaces = row?.fullResource?.id;

  const objData = {
    organization: {
      id: auxOrganization,
      alias: row?.fullResource?.contained?.[1]?.alias?.[0],
      name: row?.fullResource?.contained?.[1]?.name,
    },
    practitioner: {
      id: auxPractitioner,
      name: row?.fullResource?.contained?.[0]?.name?.[0]?.given?.[0],
    },
    servicePratice: row?.fullResource?.practiceSetting?.coding?.[0],
    time: {
      start: row?.fullResource?.period?.start,
      end: row?.fullResource?.period?.end,
    },
    relatesTo: auxReplaces,
    codeValue: row?.fullResource?.type?.coding?.[0]?.code,
    codeDisplay: row?.fullResource?.type?.coding?.[0]?.display,
    categoryValue: row?.fullResource?.category?.[0]?.coding?.[0]?.code,
    categoryDisplay: row?.fullResource?.category?.[0]?.coding?.[0]?.display,
  };

  const basedOn = row?.fullResource?.basedOn
    ?.map(
      (el: any) =>
        `<fhir:basedOn>
    <fhir:reference value="${el?.reference}"/>
    </fhir:basedOn>
    `
    )
    ?.join("");

  const encounter = {
    id: row?.fullResource?.context
      ?.find((el: any) => el?.reference?.includes("Encounter"))
      ?.reference?.split("/")?.[1],

    appointmentId: row?.fullResource?.context
      ?.find((el: any) => el?.reference?.includes("Appointment"))
      ?.reference?.split("/")?.[1],
    requester: {
      reference: `PractitionerRole/${userPractitionerRole?.id}`,
      display: row?.fullResource?.contained?.[0]?.name?.[0]?.given?.[0],
    },
    subject: row?.fullResource?.subject?.reference?.split("-")?.[1],
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleClickUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const convertToBase64 = (file: File) => {
    if (file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    }
  };

  const [fileBase64, setFileBase64] = useState<any>();

  const uploadImage = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setFileBase64(base64);
  };

  const docReferenceData = {
    servicePratice: {
      code: row?.fullResource?.practiceSetting?.coding?.[0]?.code,
      system: row?.fullResource?.practiceSetting?.coding?.[0]?.system,
      display: row?.fullResource?.practiceSetting?.coding?.[0]?.display,
    },
    time: {
      start: dayjs().toISOString(),
      end: dayjs().toISOString(),
    },
  };

  const refs = {
    id: row?.fullResource?.context
      ?.find((el: any) => el?.reference?.includes("Encounter"))
      ?.reference?.split("/")?.[1],
    subject: user?.username,
    appointmentId: row?.fullResource?.context
      ?.find((el: any) => el?.reference?.includes("Appointment"))
      ?.reference?.split("/")?.[1],
  };

  const base64ToBlob = (base64: any, contentType = "") => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  async function downloadBase64File(binary: string) {
    try {
      const response = await GetBinary({
        urlBinary: binary,
      });
      const blob = base64ToBlob(response?.data, response?.contentType);
      const fileUrl = URL.createObjectURL(blob);
      const auxFile = {
        fileUrl,
        fileName: `Documento.pdf`,
      };

      if (isMobileSafari && !isAndroid) {
        if (auxFile) {
          const link = document.createElement("a"); // Cria um elemento <a>
          link.href = auxFile?.fileUrl; // Define o URL do Blob
          link.download = auxFile?.fileName; // Define o nome do arquivo
          // document.body.appendChild(link); // Adiciona o link ao documento
          link.click(); // Simula o clique no link
          // document.body.removeChild(link); // Remove o link do documento
          // URL.revokeObjectURL(auxFile?.fileUrl); // Libera a memória do URL Blob
        } else {
          alert(
            "O arquivo ainda está sendo preparado, por favor tente novamente."
          );
        }
      } else {
        window.open(auxFile.fileUrl, "_blank");
        // setDataRow(e.row);
        // setOpenInformation(true);
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  const handleSignature = async (
    provider: "vidaas" | "safeid",
    password?: string,
    lifeTime?: number
  ) => {
    try {
      setLoading(true);
      toast.warn("Aguardando verificação e segurança do seu provedor!");
      const signResponse = await Base64SignPDF({
        provider: provider,
        ...(provider === "safeid" && {
          userPassword: password,
        }),
        pdfBase64: base64
          ? base64?.[0]?.exam?.base64?.split(",")?.[1]
          : originalDocument,
        cpfCnpj: user?.username,
        lifeTime: lifeTime ?? 0,
      });

      if (!signResponse?.signedPdfBase64) throw new Error("");

      const auxUuid = base64?.[0]?.exam?.uuid;

      await PostDocumentReference(
        { ...objData, base64: signResponse?.signedPdfBase64 },
        encounter,
        basedOn,
        true,
        auxUuid
      );
      toast.success("Assinatura realizada!");
    } catch (error) {
      toast.warn(
        "Não foi possível realizar a assinatura, por favor tente novamente!"
      );

      console.error("HandleSignature error:", error);
    } finally {
      setLoading(false);
      setRefetch((prev: boolean) => !prev);
    }
  };

  async function generateItem() {
    const patient = await GetPatient({ identifier: encounter?.subject });
    setPatient(patient?.data);

    const appointment = await GetAppointment({
      ehrrunner: encounter?.subject,
      _id: encounter?.appointmentId,
    });

    const locationReference = useFhirData.findParticipant(
      appointment?.entry?.[0]?.resource?.participant,
      "Location"
    );

    const selectedLocation = location?.find((el: any) =>
      el?.resource?.id?.includes(locationReference?.reference.split("/")[1])
    )?.resource;

    setLocationData(selectedLocation);
    const exams = row?.fullServiceResource?.map((e: any) => {
      const auxItem = {
        type: "service",
        tabValue: "2",
        // inputValue: serviceList.find(
        //   (e: any) => data?.service?.code === e?.code
        // ) || {
        //   name: data?.service?.label,
        //   value: data?.service?.label,
        //   label: data?.service?.label,
        // },
        refact: true,
        name: e?.resource?.code?.concept?.coding?.[0]?.display,
        tag: "EX",
        form: "",
        note: e?.resource?.note,
        condition: {
          value: e?.resource?.reason?.[0]?.concept?.coding?.[0]?.code,
          label: e?.resource?.reason?.[0]?.concept?.coding?.[0]?.display,
        },
        category: e?.resource?.category?.[0]?.coding?.[0],
      };
      return auxItem;
    });

    setItem(exams);
    exams?.map((e: any) => action(e));
    setLoading(false);
  }

  async function removeUpload() {
    const documentReference = await GetDocumentReferenceById({
      patient: user?.username,
      docId: row?.resultUrl?.split("/")?.[1],
      isLogged: true,

      subjectId: user?.username,
    });

    await PatchEHRPoster({
      resourceType: "DocumentReference",
      resourceId: documentReference?.id || "",
      operation: "replace",
      path: "/status",
      value: "entered-in-error",
    });

    toast.success("Resultado removido com sucesso!");

    setRefetch((prev: boolean) => !prev);
  }

  return (
    <TableCell align="center">
      <Stack direction="row" justifyContent="center">
        {row.resultUrl ? (
          <>
            {isMobile && (
              <Tooltip title="Remover resultado">
                <IconButton onClick={async () => await removeUpload()}>
                  <Delete />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="Visualizar resultado">
              <IconButton
                onClick={() => {
                  setBinaryUrl(row.resultUrl);
                  if (isMobile) {
                    downloadBase64File(row?.resultUrl);
                  } else {
                    handleOpen();
                  }
                }}
              >
                <Visibility />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <IconButton
            onClick={handleClickUpload}
            onChange={(e: any) => uploadImage(e)}
          >
            <Tooltip title="Enviar resultado">
              <FileUpload sx={{ color: "primary700.main" }} />
            </Tooltip>
            <VisuallyHiddenInput ref={fileInputRef} type="file" accept=".pdf" />
          </IconButton>
        )}

        {row?.binaryUrl && (
          <>
            <Tooltip title="Baixar solicitação">
              <IconButton
                onClick={() => {
                  downloadBase64File(row?.binaryUrl);
                }}
              >
                <FileDownload />
              </IconButton>
            </Tooltip>

            {!isMobile && (
              <Tooltip title="Visualizar solicitação">
                <IconButton
                  onClick={() => {
                    setBinaryUrl(row?.binaryUrl);
                    handleOpen();
                  }}
                >
                  <Description />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}

        {access?.type === "professional" &&
          userPractitionerRole?.id ===
            row?.fullServiceResource?.[0]?.resource?.requester?.reference?.split(
              "/"
            )?.[1] && (
            <Tooltip
              title={!row?.sign ? "Assinar documento" : "Reemitir documento"}
            >
              <IconButton
                onClick={async () => {
                  setLoading(true);
                  const response = await GetBinary({
                    urlBinary: row?.binaryUrl,
                  });
                  setOriginalDocument(response?.data);
                  await generateItem();
                  setSignatureDialogOpen(true);
                }}
              >
                {loading ? (
                  <CircularProgress size={20} />
                ) : !row?.sign ? (
                  <Edit />
                ) : (
                  <Autorenew />
                )}
              </IconButton>
            </Tooltip>
          )}

        {verifyVisualizer &&
          verifyVisualizer(row?.encounterId) &&
          access?.type === "professional" && (
            <Tooltip title="Visualizar atendimento">
              <IconButton
                onClick={() => {
                  openVisualizer(row?.encounterId);
                }}
              >
                <ContentPaste />
              </IconButton>
            </Tooltip>
          )}
      </Stack>

      {fileBase64 && (
        <ConfirmDocumentDialog
          setRenderButton={() => {}}
          setRefetch={setRefetch}
          fileBase64={fileBase64}
          isOpenDialog={fileBase64}
          onCloseDialog={() => {
            setFileBase64(null);
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
          }}
          basedOn={row?.fullResource?.basedOn?.filter((el: any) =>
            el.reference?.includes("ServiceRequest")
          )}
          encounterReference={refs}
          data={{
            ...docReferenceData,
            base64: fileBase64?.split(",")?.[1],
            codeValue: "RESULT",
            codeDisplay: "Resultado de exame",
            categoryValue: "resultado",
            categoryDisplay: "Resultado de exame",
          }}
        />
      )}

      {item?.length && !loading && patient ? (
        <>
          <div style={{ display: "none" }}>
            <DocumentVisualization
              refact
              setBase64={setBase64}
              dataPdf={[
                {
                  patientCPF: patient?.id?.split("-")?.[1],
                  patientData: patient?.name?.[0]?.text || "",

                  professionalData: userPractitionerRole,
                  location: locationData,
                },
              ]}
            />
          </div>
        </>
      ) : (
        <></>
      )}

      {signatureDialogOpen && originalDocument ? (
        <SignatureDialog
          visualize={
            base64 && item
              ? base64?.[0]?.exam?.base64?.split(",")?.[1]
              : originalDocument
          }
          isOpen={signatureDialogOpen}
          handleClose={() => {
            setSignatureDialogOpen(false);
            const indexes = item?.map((e: any) =>
              selectedInterventions?.all.indexOf(e)
            );

            setInterventionsList(
              selectedInterventions?.all?.filter(
                (e: any, index: number) => !indexes?.includes(index)
              )
            );

            setItem([]);
          }}
          handleSignature={handleSignature}
          row={{ sign: "Pendente" }}
          loading={loading}
        />
      ) : (
        <></>
      )}
    </TableCell>
  );
}
