import { Avatar, Stack, Typography } from "@mui/material";

export function CustomMedicationOption({ option }: { option: any }) {
  const tarjas = [
    {
      name: "VERMELHA",
      value: "CE",
      color: "red",
      display: "Controle especial",
    },
    {
      name: "PRETA",
      value: "CE",
      color: "black",
      display: "Controle especial",
    },
    {
      name: "AMARELA",
      value: "AM",
      color: "#FEE2A9",
      display: "Receita amarela",
    },
    {
      name: "AZUL",
      value: "AZ",
      color: "#A4CBE8",
      display: "Receita azul",
    },
    {
      name: "SEM TARJA",
      value: "BR",
      color: "black",
      display: "Receita branca",
    },
  ];

  const auxTag = tarjas.find((el: any) =>
    option.tag.toUpperCase()?.includes(el.name)
  );

  return (
    <Stack
      width="100%"
      direction="row"
      justifyContent="space-between"
      key={`${option.label}-${option.value}`}
    >
      <Stack direction="row" width="70%" gap={1}>
        <Avatar
          sx={{
            fontSize: 16,
            background: "white",
            color: auxTag?.color || "black",
            border: `1px solid ${auxTag?.color || "black"}`,
          }}
        >
          {auxTag?.value || "-"}
        </Avatar>

        <Stack minWidth="45%">
          <Stack direction="row" gap={1}>
            <Typography
              variant="body1"
              color="neutral1000.main"
              fontWeight={500}
            >
              {option.name}
            </Typography>
            <Typography>{option?.presentation || "Apresentação"} </Typography>
          </Stack>

          <Typography>{option?.description || "Descrição"}</Typography>
        </Stack>
      </Stack>

      <Stack width="10%">
        <Typography variant="body1" color="neutral1000.main" fontWeight={500}>
          Tipo
        </Typography>
        <Typography> {option?.type || "Tipo "}</Typography>
      </Stack>

      <Stack width="45%">
        <Typography variant="body1" color="neutral1000.main" fontWeight={500}>
          Classe
        </Typography>
        <Typography> {option?.class || "-"}</Typography>
      </Stack>
    </Stack>
  );
}
