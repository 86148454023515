import { CircularProgress, Stack } from "@mui/material";

export function CircularLoading() {
  return (
    <Stack
      spacing={2}
      height="100%"
      alignItems="center"
      display="flex"
      direction="row"
      justifyContent="center"
    >
      <CircularProgress size={80} />
    </Stack>
  );
}
