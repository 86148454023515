import React from "react";
import { Stack, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

export function NoDataWarning({ message, bgColor, justifyX }: any) {
  return (
    <Stack
      direction="row"
      //bgcolor="primary200.main"
      bgcolor={bgColor ? `${bgColor}` : "transparent"}
      alignItems="center"
      px={2}
      spacing={2}
      width="100%"
      height="60px"
      justifyContent={justifyX ? `${justifyX}` : "center"}
    >
      <InfoOutlined />
      <Typography variant="subtitle2" fontWeight={400}>
        {message ? message : "Não há dados para exibir"}
      </Typography>
    </Stack>
  );
}
