import { Stack } from "@mui/material";
import { PrimaryButton } from "../../../../components/common";
import { Add, Edit } from "@mui/icons-material";

export function SubmitItemButton({
  action,
  isEdit,
}: {
  action: VoidFunction;
  isEdit?: boolean;
}) {
  return (
    <Stack direction="row-reverse" width="100%" mt={2}>
      <PrimaryButton width="58px" height="42px" onClick={action}>
        {isEdit ? <Edit /> : <Add />}
      </PrimaryButton>
    </Stack>
  );
}
