import { ReactNode, createContext, useContext, useState } from "react";

interface SideBarContextValue {
  handleExpand: any;
  expandedSections: any;
}

interface State {
  [key: string]: boolean;
}

export const SideBarContext = createContext<SideBarContextValue>(null!);

export function SideBarContextProvider({ children }: { children: ReactNode }) {
  const [expandedSections, setExpandedSections] = useState<State>();

  const handleExpand = (e: any, value: boolean) => {
    setExpandedSections({
      ...expandedSections,
      [e]: !value,
    });
  };

  const contextValue = {
    expandedSections,
    handleExpand,
  };

  return (
    <SideBarContext.Provider value={contextValue}>
      {children}
    </SideBarContext.Provider>
  );
}

export function useSideBarContext() {
  return useContext(SideBarContext);
}
