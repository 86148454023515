import { Dialog, Divider, IconButton, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  Assignment,
  Close,
  ExitToApp,
  HelpOutline,
  PeopleAlt,
  PrivacyTip,
  Restore,
  Share,
} from "@mui/icons-material";
import { MobileMenuItens } from "./options/MobileMenuItens";
import { RegisterDataMenuMobile } from "../../../../pages/registerdatamenumobile/RegisterDataMenuMobile";
import { useState } from "react";
import { PrivacyMenuMobile } from "../../../../pages/privacymenumobile/PrivacyMenuMobile";

export function MobileMenu({ open, setModalOpen }: any) {
  const navigate = useNavigate();

  const [openRegistration, setOpenRegistration] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);

  return (
    <>
      <Dialog
        hideBackdrop
        sx={{
          marginTop: "70px",
          marginBottom: "52px",
          "& .MuiDialog-paper": {
            boxShadow: "none",
          },
        }}
        fullScreen
        open={open}
        onClose={setModalOpen}
      >
        <Stack width="100%" height="100%" paddingX={2}>
          <Stack
            direction="column"
            alignItems="flex-start"
            width="100%"
            sx={{ backgroundColor: "neutral0.main" }}
            height="100%"
          >
            <Stack
              width="100%"
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={1}
            >
              <Typography
                variant="subtitle2"
                color="neutral700.main"
                fontWeight={600}
                margin="20px 0px 8px"
              >
                Você e o Fluxmed
              </Typography>
              <IconButton
                sx={{ color: "neutral1000.main" }}
                onClick={setModalOpen}
              >
                <Close />
              </IconButton>
            </Stack>
            <Divider sx={{ width: "100%", background: "neutral400.main" }} />

            <MobileMenuItens
              icon={<PeopleAlt />}
              text="Dados cadastrais"
              onClick={() => setOpenRegistration(true)}
            />
            <Divider sx={{ width: "100%", background: "neutral400.main" }} />

            <MobileMenuItens
              icon={<Assignment />}
              text="Meus registros"
              onClick={() => navigate("/myrecords")}
            />
            <Divider sx={{ width: "100%", background: "neutral400.main" }} />
            <MobileMenuItens
              icon={<Restore />}
              text="Histórico de acessos"
              onClick={() => navigate("/history")}
            />
            <Divider sx={{ width: "100%", background: "neutral400.main" }} />
            <MobileMenuItens
              icon={<Share />}
              text="Consentimentos"
              onClick={() => navigate("/consent")}
            />
            <Divider sx={{ width: "100%", background: "neutral400.main" }} />
            <MobileMenuItens
              icon={<PrivacyTip />}
              text="Privacidade"
              onClick={() => setOpenPrivacy(true)}
            />
            <Divider sx={{ width: "100%", background: "neutral400.main" }} />
            <Stack mt={5} width="100%">
              <MobileMenuItens
                icon={<HelpOutline />}
                text="Ajuda"
                onClick={() => navigate("/")}
                transparent
                noPadding
              />
              <Divider sx={{ width: "100%", background: "neutral400.main" }} />
              <MobileMenuItens
                icon={<ExitToApp />}
                text="Sair"
                onClick={() => {
                  if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage("Logout");
                    return;
                  }
                  navigate("/signin");
                }}
                transparent
                noPadding
              />
            </Stack>
          </Stack>
        </Stack>
      </Dialog>

      <RegisterDataMenuMobile
        open={openRegistration}
        onClose={() => setOpenRegistration(false)}
      />

      <PrivacyMenuMobile
        open={openPrivacy}
        onClose={() => setOpenPrivacy(false)}
      />
    </>
  );
}
