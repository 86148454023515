import dayjs from "dayjs";
import { IObservationData } from "../../utils/interfaces";
import { ObservationType } from "../../utils/types";

const generateBodyObservation = (
  objData: IObservationData,
  type: ObservationType
) => {
  let observationBody = {};

  switch (type) {
    case "pressure":
      observationBody = {
        resourceType: "Observation",
        status: "final",
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/observation-category",
                code: "vital-signs",
                display: "Vital Signs",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "55284-4",
              display: "Blood pressure systolic and diastolic",
            },
          ],
          text: "Pressão Arterial Sistólica e Diastólica",
        },
        subject: {
          reference: `Patient/2.16.840.1.113883.13.237-${objData.patientID}`,
        },
        encounter: {
          reference: `Encounter/${objData.encounterID}`, //34156
        },
        effectiveDateTime: dayjs().toISOString(),
        component: [
          {
            code: {
              coding: [
                {
                  system: "http://loinc.org",
                  code: "8480-6",
                  display: "Systolic blood pressure",
                },
              ],
              text: "Pressão Arterial Sistólica",
            },
            valueQuantity: {
              value: objData.pressure.sistolic,
              unit: "mm[Hg]",
              system: "http://unitsofmeasure.org",
              code: "mm[Hg]",
            },
          },
          {
            code: {
              coding: [
                {
                  system: "http://loinc.org",
                  code: "8462-4",
                  display: "Diastolic blood pressure",
                },
              ],
              text: "Pressão Arterial Diastólica",
            },
            valueQuantity: {
              value: objData.pressure.diastolic,
              unit: "mm[Hg]",
              system: "http://unitsofmeasure.org",
              code: "mm[Hg]",
            },
          },
        ],
      };
      break;
    case "bodyWeight":
      observationBody = {
        resourceType: "Observation",
        status: "final",
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/observation-category",
                code: "vital-signs",
                display: "Vital Signs",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "29463-7",
              display: "Body weight",
            },
          ],
          text: "Peso",
        },
        subject: {
          reference: `Patient/2.16.840.1.113883.13.237-${objData.patientID}`,
        },
        encounter: {
          reference: `Encounter/${objData.encounterID}`,
        },
        effectiveDateTime: dayjs().toISOString(),
        valueQuantity: {
          value: objData.bodyWeight,
          unit: "kg",
          system: "http://unitsofmeasure.org",
          code: "kg",
        },
      };
      break;
    case "bodyHeight":
      observationBody = {
        resourceType: "Observation",
        status: "final",
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/observation-category",
                code: "vital-signs",
                display: "Vital Signs",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "8302-2",
              display: "Body height",
            },
          ],
          text: "Altura",
        },
        subject: {
          reference: `Patient/2.16.840.1.113883.13.237-${objData.patientID}`,
        },
        encounter: {
          reference: `Encounter/${objData.encounterID}`,
        },
        effectiveDateTime: dayjs().toISOString(),
        valueQuantity: {
          value: objData.bodyHeight,
          unit: "cm",
          system: "http://unitsofmeasure.org",
          code: "cm",
        },
      };
      break;
    case "abdominalCircumference":
      observationBody = {
        resourceType: "Observation",
        status: "final",
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/observation-category",
                code: "vital-signs",
                display: "Vital Signs",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "8280-0",
              display: "Waist Circumference at umbilicus by Tape measure",
            },
          ],
          text: "Circunferência Abdominal",
        },
        subject: {
          reference: `Patient/2.16.840.1.113883.13.237-${objData.patientID}`,
        },
        encounter: {
          reference: `Encounter/${objData.encounterID}`,
        },
        effectiveDateTime: dayjs().toISOString(),
        valueQuantity: {
          value: objData.abdominalCircumference,
          unit: "cm",
          system: "http://unitsofmeasure.org",
          code: "cm",
        },
      };
      break;
    case "headCircumference":
      observationBody = {
        resourceType: "Observation",
        status: "final",
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/observation-category",
                code: "vital-signs",
                display: "Vital Signs",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "9843-4",
              display: "Head Occipital-frontal circumference",
            },
          ],
          text: "Perímetro Cefálico",
        },
        subject: {
          reference: `Patient/2.16.840.1.113883.13.237-${objData.patientID}`,
        },
        encounter: {
          reference: `Encounter/${objData.encounterID}`,
        },
        effectiveDateTime: dayjs().toISOString(),
        valueQuantity: {
          value: objData.headCircumference,
          unit: "cm",
          system: "http://unitsofmeasure.org",
          code: "cm",
        },
      };
      break;
    case "alcoholic":
      observationBody = {
        resourceType: "Observation",
        status: "final",
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/observation-category",
                code: "social-history",
                display: "Social History",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "74013-4",
              display: "Alcoholic drinks per day",
            },
          ],
          text: "Consumo diário de álcool",
        },
        subject: {
          reference: `Patient/2.16.840.1.113883.13.237-${objData.patientID}`,
        },
        encounter: {
          reference: `Encounter/${objData.encounterID}`,
        },
        effectiveDateTime: dayjs().toISOString(),
        valueQuantity: {
          value: objData.alcoholic,
          system: "http://unitsofmeasure.org",
          code: "ml/d",
        },
      };
      break;
    case "smoking":
      observationBody = {
        resourceType: "Observation",
        status: "final",
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/observation-category",
                code: "social-history",
                display: "Social History",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "72166-2",
              display: "Tobacco smoking status",
            },
          ],
          text: "Tabagismo",
        },
        subject: {
          reference: `Patient/2.16.840.1.113883.13.237-${objData.patientID}`,
        },
        encounter: {
          reference: `Encounter/${objData.encounterID}`,
        },
        effectiveDateTime: dayjs().toISOString(),
        valueCodeableConcept: {
          coding: [
            {
              system: "http://loinc.org",
              code: objData.smoking?.code,
              display: objData.smoking?.display,
            },
          ],
          text: objData.smoking?.value,
        },
      };
      break;
    case "menstrualPeriod":
      observationBody = {
        resourceType: "Observation",
        status: "final",
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/observation-category",
                code: "vital-signs",
                display: "Vital Signs",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "8665-2",
              display: "Last menstrual period start date observation",
            },
          ],
          text: "Data da Última Menstruação",
        },
        subject: {
          reference: `Patient/2.16.840.1.113883.13.237-${objData.patientID}`,
        },
        encounter: {
          reference: `Encounter/${objData.encounterID}`,
        },
        effectiveDateTime: dayjs().toISOString(),
        valueDateTime: dayjs(objData.menstrualPeriod).toISOString(),
      };
      break;
    case "pregnancies":
      observationBody = {
        resourceType: "Observation",
        status: "final",
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/observation-category",
                code: "vital-signs",
                display: "Vital Signs",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "11996-6",
              display: "[#] Pregnancies",
            },
          ],
          text: "Quantidade de Gestações",
        },
        subject: {
          reference: `Patient/2.16.840.1.113883.13.237-${objData.patientID}`,
        },
        encounter: {
          reference: `Encounter/${objData.encounterID}`,
        },
        effectiveDateTime: dayjs().toISOString(),
        valueQuantity: {
          value: objData.pregnancies,
        },
      };
      break;
    case "abortions":
      observationBody = {
        resourceType: "Observation",
        status: "final",
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/observation-category",
                code: "vital-signs",
                display: "Vital Signs",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "11612-9",
              display: "[#] Abortions",
            },
          ],
          text: "Quantidade de Abortos",
        },
        subject: {
          reference: `Patient/2.16.840.1.113883.13.237-${objData.patientID}`,
        },
        encounter: {
          reference: `Encounter/${objData.encounterID}`,
        },
        effectiveDateTime: dayjs().toISOString(),
        valueQuantity: {
          value: objData.abortions, //Adicionar do formulário
        },
      };
      break;
    case "birthsTotal":
      observationBody = {
        resourceType: "Observation",
        status: "final",
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/observation-category",
                code: "vital-signs",
                display: "Vital Signs",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "11640-0",
              display: "[#] Births total", //Adicionar do formulário
            },
          ],
          text: "Partos",
        },
        subject: {
          reference: `Patient/2.16.840.1.113883.13.237-${objData.patientID}`,
        },
        encounter: {
          reference: `Encounter/${objData.encounterID}`,
        },
        effectiveDateTime: dayjs().toISOString(),
        valueQuantity: {
          value: objData.birthsTotal, //Adicionar do formulário
        },
      };
      break;
    case "breastfeeding":
      observationBody = {
        resourceType: "Observation",
        status: "final",
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/observation-category",
                code: "vital-signs",
                display: "Vital Signs",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "63895-7",
              display: "Breastfeeding status",
            },
          ],
          text: "Tipo de Aleitamento Materno",
        },
        subject: {
          reference: `Patient/2.16.840.1.113883.13.237-${objData.patientID}`,
        },
        encounter: {
          reference: `Encounter/${objData.encounterID}`,
        },
        effectiveDateTime: dayjs().toISOString(),
        valueCodeableConcept: {
          coding: [
            {
              system:
                "http://www.saude.gov.br/fhir/r4/CodeSystem/BRTipoAleitamentoMaterno",
              code: objData.breastfeeding?.value, //Colocar code
              display: objData.breastfeeding?.display, // Colocar display
            },
          ],
        },
      };
      break;
    case "ageGestacional":
      observationBody = {
        resourceType: "Observation",
        status: "final",
        category: [
          {
            coding: [
              {
                system:
                  "http://terminology.hl7.org/CodeSystem/observation-category",
                code: "vital-signs",
                display: "Vital Signs",
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: "http://loinc.org",
              code: "11885-1",
              display:
                "Gestational age estimate based on date of first day of last menstrual period",
            },
          ],
          text: "Idade Gestacional",
        },
        subject: {
          reference: `Patient/2.16.840.1.113883.13.237-${objData.patientID}`,
        },
        encounter: {
          reference: `Encounter/${objData.encounterID}`,
        },
        effectiveDateTime: dayjs().toISOString(),
        valueQuantity: {
          value: objData.ageGestacional,
          unit: "week", //Colocar as opções de meses ou semanas
          system: "http://unitsofmeasure.org",
          code: "wk", //Alterar aqui tbm
        },
      };
      break;
  }

  return JSON.stringify(observationBody);
};

export const auxiliarObservations = {
  generateBodyObservation,
};
