import { Box, Stack, Tab, Tabs } from "@mui/material";

import { SyntheticEvent } from "react";
import { useMedicalCareContext } from "../../../contexts/MedicalCareContext";
import { useQuestionnaireContext } from "../../../contexts/QuestionnaireContext";
import { Anamnese } from "./Anamnese/Anamnese";
import { Exames } from "./Exames/Exames";
import { Problemas } from "./Problemas/Problemas";
import { PlanosDeTratamento } from "./PlanosDeTratamento/PlanosDeTratamento";
import { Condutas } from "./Condutas/Condutas";
import { Diagnosticos } from "./Diagnosticos/Diagnosticos";
import { Resultado } from "./Resultados/Resultados";
import { TratamentosDeclarado } from "./TratamentosDeclarado/TratamentosDeclarado";
import { KeyPressShortCutTabs } from "./KeyPressShortcuts/KeyPressShortcut";
import { Antropometricos } from "./Anthropometrics/Antropometricos";
import { useLocation } from "react-router-dom";
import { SocialHabits } from "./SocialHabits/SocialHabits";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{ width: "100%" }}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Stack
          spacing={3}
          sx={{ pl: 3, width: "100%" }}
          direction="row"
          justifyContent="space-between"
        >
          {children}
        </Stack>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export function TabsAtendimento() {
  const { registerAreaTab, setRegisterAreaTab } = useMedicalCareContext();
  const {
    questionnaire,
    loadingQuestionnaire,
    response,
    questionnairesResponse,
  } = useQuestionnaireContext();
  const { state } = useLocation();
  const defaultValues = response?.entry?.find(
    (attendance: any) =>
      attendance.resource?.encounter?.reference?.split("/").pop() ===
      state?.encounterReference?.id
  )?.resource?.item;
  // state?.encounterReference?.id

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setRegisterAreaTab(newValue);
  };

  const tabs = [
    ...questionnaire?.item
      ?.filter((e: any) => e?.text)
      ?.map((el: any) => {
        return el?.text;
      }),
    "Hábitos sociais",
  ];
  tabs.splice(2, 0, "Antropométricos");
  return (
    <>
      <KeyPressShortCutTabs />
      {!loadingQuestionnaire && (
        <Box
          sx={{
            bgcolor: "background.paper",
            display: "flex",
            height: "fit-content",
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={registerAreaTab}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{
              borderRight: 1,
              borderColor: "divider",
              display: "flex",
              alignItems: "center",
            }}
          >
            {tabs?.map((tab: any, index: number) => (
              <Tab label={tab} {...a11yProps(index)} />
            ))}
          </Tabs>

          <TabPanel index={registerAreaTab} value={registerAreaTab}>
            {registerAreaTab === 0 && <Anamnese />}
            {registerAreaTab === 1 ? <Exames /> : <></>}
            {registerAreaTab === 2 ? <Antropometricos /> : <></>}
            {registerAreaTab === 3 ? <Condutas /> : <></>}
            {registerAreaTab === 4 ? <Diagnosticos /> : <></>}
            {registerAreaTab === 5 ? <TratamentosDeclarado /> : <></>}
            {registerAreaTab === 6 && <Resultado />}
            {registerAreaTab === 7 ? <Problemas /> : <></>}
            {registerAreaTab === 8 ? <PlanosDeTratamento /> : <></>}
            {registerAreaTab === 9 ? <SocialHabits /> : <></>}
          </TabPanel>
        </Box>
      )}
    </>
  );
}
