import { Add } from "@mui/icons-material";
import { Button, Chip, Stack, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { InputText } from "../../ClinicalRegister/components/QuestionnaireViewer/components/Form";
import { useMedicalCareContext } from "../../../contexts/MedicalCareContext";
import { useWatch } from "react-hook-form";

export function AddNewValue({ lista, setLista, item }: any) {
  const { control, unregister } = useMedicalCareContext();
  const [render, setRender] = useState<boolean>(false);

  const inputValue = useWatch({
    name: `New_${item?.[0]?.text}.${lista?.length}`,
    control,
  });

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setLista([...lista, inputValue]);
    }
  };

  useEffect(() => {
    setRender(true);
  }, [render]);
  return (
    <>
      {lista?.length ? (
        <Stack
          direction="row"
          flexWrap="wrap"
          minHeight="fit-content"
          spacing={1}
          paddingBottom={1}
          gap={1}
        >
          {lista?.map((el: any, index: number) => (
            <>
              {el?.length ? (
                <Chip
                  sx={{
                    padding: "8px",
                    height: "auto",
                  }}
                  key={index}
                  label={
                    el?.length > 85 ? (
                      <Tooltip
                        placement="right"
                        title={el}
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [80, 20],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <Typography>{el.substring(0, 85)}...</Typography>
                      </Tooltip>
                    ) : (
                      <Typography>{el}</Typography>
                    )
                  }
                  onDelete={() => {
                    setLista((prevItems: any) => {
                      const updatedItems = [...prevItems]; // Faz uma cópia do array
                      updatedItems[index] = ""; // Altera o item específico
                      return updatedItems; // Retorna o novo array modificado
                    });
                    unregister(`New_${item?.[0]?.text}.${index}`);
                    setRender(false);
                  }}
                />
              ) : (
                <></>
              )}
            </>
          ))}
        </Stack>
      ) : (
        <></>
      )}

      <Stack direction="row" spacing={1}>
        {render && (
          <InputText
            key={lista?.length}
            label={item?.[0]?.text}
            name={`New_${item?.[0]?.text}.${lista?.length}`}
            control={control}
            sx={{ mb: 4 }}
            onKeyDown={handleKeyDown}
            autoFocus
            multiline={true}
          />
        )}
      </Stack>
      <Stack direction="row" width="100%" justifyContent="right">
        <Button
          sx={{ width: "fit-content" }}
          onClick={(e) => {
            setLista([...lista, inputValue]);
          }}
          variant="text"
        >
          <Add />
          Adicionar
        </Button>
      </Stack>
    </>
  );
}
