import { useFormatter } from "../../utils/useFormatter";
import { IContact, IDeviceID, INotification } from "./interfaces";

function SignUpValidate(objData: any) {
  return JSON.stringify({
    schemas: [
      "urn:ietf:params:scim:schemas:core:2.0:User",
      "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User",
    ],
    name: {
      familyName: objData.surname.toUpperCase(),
      givenName: objData.firstName.toUpperCase(),
    },
    userName: objData.identifier.replace(/[^a-zA-Z0-9]/g, ""),
    phoneNumbers: [
      {
        type: "mobile",
        value: "",
      },
    ],
    emails: [""],
    "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User": {
      emailAT: "",
      dateOfBirth: objData.birthdate.toISOString().split("T")[0],
      tokenfirebase: "",
      notificationAgree: true,
    },
    password: "",
  });
}

function SignUpRegister(objData: any) {
  return JSON.stringify({
    schemas: [
      "urn:ietf:params:scim:schemas:core:2.0:User",
      "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User",
    ],
    name: {
      familyName: useFormatter.fixName(objData.surname.toUpperCase()),
      givenName: useFormatter.fixName(objData.firstName.toUpperCase()),
    },
    userName: objData.identifier.replace(/[^a-zA-Z0-9]/g, ""),
    phoneNumbers: [
      {
        type: "mobile",
        value: objData.phoneNumber
          .replace(/[^a-zA-Z0-9]/g, "")
          .replace(" ", ""),
      },
    ],
    emails: [objData.email],
    "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User": {
      emailAT: "empty",
      dateOfBirth: objData.birthdate.toISOString().split("T")[0],
      tokenfirebase: objData.firebaseToken,
      notificationAgree: true,
    },
    password: objData.password,
    gender: objData.gender,
  });
}

function ProfileContactPatch(newContact: IContact) {
  return JSON.stringify({
    schemas: ["urn:ietf:params:scim:api:messages:2.0:PatchOp"],
    Operations: [
      {
        op: "replace",
        value: {
          ...(newContact.phone && {
            phoneNumbers: [{ type: "mobile", value: newContact.phone }],
          }),
          ...(newContact.email && {
            emails: [newContact.email],
            "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User": {
              emailAT: newContact.email.replace("@", "[AT]"),
            },
          }),
        },
      },
    ],
  });
}

function DeviceIDPatch({ operation, pathName, valueField }: IDeviceID) {
  if (operation === "add") {
    return JSON.stringify({
      schemas: ["urn:ietf:params:scim:api:messages:2.0:PatchOp"],
      Operations: [
        {
          op: "add",
          path: pathName,
          value: valueField,
        },
      ],
    });
  }
  if (operation === "replace") {
    return JSON.stringify({
      schemas: ["urn:ietf:params:scim:api:messages:2.0:PatchOp"],
      Operations: [
        {
          op: "replace",
          path: pathName,
          value: valueField,
        },
      ],
    });
  }
}

function NotificationPatch({ operation, valueField }: INotification) {
  if (operation === "replace") {
    return JSON.stringify({
      schemas: ["urn:ietf:params:scim:api:messages:2.0:PatchOp"],
      Operations: [
        {
          op: "replace",
          value: {
            "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User": {
              notificationAgree: valueField,
            },
          },
        },
      ],
    });
  }
}

export const auxiliarData = {
  SignUpValidate,
  SignUpRegister,
  ProfileContactPatch,
  DeviceIDPatch,
  NotificationPatch,
};
