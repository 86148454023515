import { ExpandMore, Filter } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

export function ExamesHistoryAccordion({ data, index, setFilter }: any) {
  function resolveDisplayName(code: keyof typeof displays): string {
    const displays = {
      alterado: "Alterado",
      normal: "Normal",
      not_evaluated: "Não avaliado",
    };

    return displays[code] || "Não avaliado";
  }

  function resolveColors(code: keyof typeof colors): string {
    const colors = {
      alterado: "#FEE2A9",
      normal: "#E6F7F5",
      not_evaluated: "#F5F5F5",
    };

    return colors[code] || "#F5F5F5";
  }

  return (
    <Accordion
      defaultExpanded={index === 0}
      sx={{
        width: "500px",
        boxShadow: "1px 1px 16px 1px rgba(0, 0, 0, 0.1)",
        position: "initial",
        border: "1px solid",
        borderColor: "neutral200.main",
        borderRadius: 1,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Stack direction="row" width="100%" spacing={2}>
          <Chip
            label={data?.displayInfo?.healthcare || "Sem especialidade"}
            size="small"
            sx={{
              color: data?.displayInfo?.healthcare
                ? "neutral700.main"
                : "neutral500.main",

              fontWeight: 500,
              backgroundColor: data?.displayInfo?.healthcare ? "" : "#f1f1f1",
            }}
          />
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {/* <Stack
          direction="row"
          spacing={1}
          sx={{
            overflowX: "auto", // Para scroll horizontal
            display: "flex",
            width: "100%", // Ajuste a largura conforme necessário
          }}
        >
          <Button
            variant={"contained"}
            sx={{ minWidth: 120 }}
            onClick={() => setFilter(0)}
          >
            Geral
          </Button>

          <Button
            variant="outlined"
            sx={{ minWidth: 120 }}
            onClick={() => setFilter(1)}
          >
            Específico
          </Button>
        </Stack> */}

        {data?.filterItens?.item?.map((el: any) => {
          const auxAlterado =
            el?.answer?.[0]?.item?.[0]?.answer?.[0]?.valueString !== "undefined"
              ? true
              : false;

          return (
            <Stack
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              height="50px"
              direction="row"
              sx={{ mt: 1 }}
            >
              <Stack p={1}>
                <Typography fontWeight={500}>{el?.text}</Typography>
                {auxAlterado && (
                  <Tooltip
                    placement="right"
                    title={el?.answer?.[0]?.item?.[0]?.answer?.[0]?.valueString}
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -90],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <Typography>
                      {el?.answer?.[0]?.item?.[0]?.answer?.[0]?.valueString.substring(
                        0,
                        80
                      )}
                      {el?.answer?.[0]?.item?.[0]?.answer?.[0]?.valueString
                        ?.length > 80 && "..."}
                    </Typography>
                  </Tooltip>
                )}
              </Stack>
              <Stack
                width="124px"
                borderRadius="5px"
                justifyContent="center"
                alignItems="center"
                padding={1}
                sx={{
                  background: resolveColors(el?.answer?.[0]?.valueCoding?.code),
                  ":hover": {
                    border: "1px solid",
                    borderColor: "neutral400.main",
                  },
                }}
              >
                <Typography fontWeight={500} sx={{ color: "neutral600.main" }}>
                  {resolveDisplayName(el?.answer?.[0]?.valueCoding?.code)}
                </Typography>
              </Stack>
            </Stack>
          );
        })}
        <Divider sx={{ my: 2 }}></Divider>
        <Typography
          color="neutral700.main"
          sx={{ fontWeight: 500, fontStyle: "italic", fontSize: 12 }}
        >
          Dr. {data?.displayInfo?.practitioner}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
