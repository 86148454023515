import { DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { CommonDialog } from "../../../../components/common/CommonDialog";
import { FormButtonGroup } from "../../../../components/form";
import { useNavigate } from "react-router-dom";
import { usePrivacyContext } from "../../../../contexts/PrivacyContext";

export function PrivacyAlertDialog() {
  const { isSharing } = usePrivacyContext();
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(-1);
  };

  const handleConfirm = () => {
    navigate("/privacy");
  };

  return (
    <CommonDialog handleClose={handleClose} isOpen={!isSharing}>
      <DialogTitle textAlign="center">
        {" "}
        Você optou em não compartilhar suas informações de saúde.
      </DialogTitle>
      <DialogContent>
        <Typography
          mb={2}
          variant="body1"
          textAlign="justify"
          color="neutral700.main"
        >
          Para que você possa voltar a controlar suas opções de
          compartilhamento, altere sua opção de privacidade no menu
          “Privacidade”.
        </Typography>
        <Stack width="100%" direction="row" justifyContent="space-between">
          <FormButtonGroup
            onGoBackButtonClick={handleClose}
            onNextButtonClick={handleConfirm}
            goBackButtonText="Cancelar"
            nextButtonText="Prosseguir"
          />
        </Stack>
      </DialogContent>
    </CommonDialog>
  );
}
