import { Box, Stack, Tab, Tabs } from "@mui/material";

import { ReactNode, SyntheticEvent, useState } from "react";
import { PrimaryButton, SecondaryButton } from "../../../../components/common";
import { DiagnoticosHistory } from "./DiagnosticosHistory";
import { ConditionForm } from "./components/ConditionForm";
import { useForm, useFormState } from "react-hook-form";
import { toast } from "react-toastify";
import { AllergyForm } from "./components/AllergyForm";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { useLocation } from "react-router-dom";
import { PostAllergyIntolerance } from "../../../../services/fhir/patientsummary/allergyintolerance/PostAllergyIntolerance";
import { PostCondition } from "../../../../services/fhir/patientsummary/condition/PostCondition";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { PutCondition } from "../../../../services/fhir/patientsummary/condition/PutCondition";
import { PutAllergyIntolerance } from "../../../../services/fhir/patientsummary/allergyintolerance/PutAllergyIntolerance";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const typeDiagnostic = {
  allergy: 1,
  condition: 0,
};

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function Diagnosticos({ dialog, handleClose }: any) {
  const { setRefetch, setLoadingDiagnostics, loadingDiagnostics } =
    useMedicalCareContext();
  const { state } = useLocation();
  const { user } = useSessionContext();
  const methods = useForm();
  const { handleSubmit, control, reset, setValue } = methods;
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);
  const { dirtyFields } = useFormState({
    control,
  });

  const [editCondition, setEditCondition] = useState<any>(null);
  const references = state?.encounterReference;

  const onSubmit = async (data: any) => {
    setLoadingDiagnostics(true);

    if (references)
      try {
        if (tabValue === typeDiagnostic?.allergy) {
          await PostAllergyIntolerance(
            {
              ...data?.allergy,
              practitionerId: user?.username,
              practitionerName: user?.name,
            },
            references
          );
          toast.success("Alergia adicionada!");
        } else if (tabValue === typeDiagnostic?.condition) {
          await PostCondition(
            {
              ...data?.condition,
              practitionerId: user?.username,
              practitionerName: user?.name,
            },
            references
          );
          toast.success("Condição adicionada!");
        }
      } catch {
        toast.error("Error ao adicionar o diagnóstico!");
      } finally {
        handleClose && handleClose();
        reset();
        setRefetch(true);
      }
  };

  const onEditableCondition = async (data: any) => {
    setLoadingEdit(true);
    try {
      if (tabValue === typeDiagnostic?.condition) {
        const bodyPutCondition = {
          ...editCondition?.fullResource,
          clinicalStatus: {
            coding: [
              {
                code:
                  data?.condition?.clinicalStatus?.code ||
                  data?.condition?.clinicalStatus?.value,
              },
            ],
          },
          verificationStatus: {
            coding: [
              {
                code:
                  data?.condition?.verificationStatus?.code ||
                  data?.condition?.verificationStatus?.value,
              },
            ],
          },
          code: {
            coding: [
              {
                code: data?.condition?.condition?.code,
                display: data?.condition?.condition?.display,
                system: data?.condition.condition.system,
              },
            ],
          },
          ...(data?.condition?.note
            ? { note: [{ text: data?.condition?.note }] }
            : {}),
        };
        await PutCondition(bodyPutCondition, editCondition?.id);
      } else if (tabValue === typeDiagnostic?.allergy) {
        const bodyPutAllergy = {
          ...editCondition?.fullResource,
          category: [data?.allergy.category?.value],
          clinicalStatus: {
            coding: [{ code: data?.allergy.clinicalStatus?.value }],
          },
          code: { text: data?.allergy.substance },
          criticality: data?.allergy.criticality?.value,
          verificationStatus: {
            coding: [{ code: data?.allergy.verificationStatus?.value }],
          },
        };
        await PutAllergyIntolerance(bodyPutAllergy, editCondition?.id);
      }
    } catch (err) {
      console.log("err", err);
      setLoadingEdit(false);
      return;
    } finally {
      setRefetch(true);
    }
    setLoadingEdit(false);
    reset();
    setEditCondition(null);
    return;
  };

  const [tabValue, setTabValue] = useState<any>(typeDiagnostic?.condition);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setEditCondition(null);
    reset();
    setTabValue(newValue);
  };

  return (
    <>
      <Stack width="100%" maxHeight="60vh" overflow="auto">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Condições" {...a11yProps(typeDiagnostic?.condition)} />
            <Tab label="Alergias" {...a11yProps(typeDiagnostic?.allergy)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={typeDiagnostic?.condition}>
          <ConditionForm
            control={control}
            setValue={setValue}
            editCondition={editCondition}
            tabValue={tabValue}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={typeDiagnostic?.allergy}>
          <AllergyForm
            setValue={setValue}
            editCondition={editCondition}
            control={control}
          />
        </CustomTabPanel>
        <Stack
          width="100%"
          direction="row"
          mt={2}
          justifyContent={dialog ? "space-between" : "right"}
          alignItems="center"
        >
          {dialog && (
            <SecondaryButton
              onClick={() => handleClose && handleClose()}
              width="fit-content"
              height="40px"
            >
              Cancelar
            </SecondaryButton>
          )}
          {!editCondition ? (
            <PrimaryButton
              onClick={handleSubmit(onSubmit)}
              width="fit-content"
              height="40px"
            >
              Salvar
            </PrimaryButton>
          ) : (
            <Stack direction="row" width="100%" justifyContent="space-between">
              <SecondaryButton
                width="fit-content"
                height="40px"
                onClick={() => {
                  reset();
                  setEditCondition(null);
                }}
              >
                Cancelar
              </SecondaryButton>
              <PrimaryButton
                onClick={handleSubmit(onEditableCondition)}
                width="fit-content"
                height="40px"
                loading={loadingEdit}
                disabled={
                  tabValue === typeDiagnostic?.condition
                    ? !dirtyFields?.condition?.condition &&
                      !dirtyFields?.condition?.clinicalStatus &&
                      !dirtyFields?.condition?.verificationStatus &&
                      !dirtyFields?.condition?.note
                    : !dirtyFields?.allergy?.category &&
                      !dirtyFields?.allergy?.clinicalStatus &&
                      !dirtyFields?.allergy?.criticality &&
                      !dirtyFields?.allergy?.substance &&
                      !dirtyFields?.allergy?.verificationStatus
                }
              >
                Editar
              </PrimaryButton>
            </Stack>
          )}
        </Stack>
      </Stack>

      <Stack
        width="100%"
        minWidth="600px"
        overflow="auto"
        maxHeight="60vh"
        paddingRight={3}
      >
        <DiagnoticosHistory
          reset={reset}
          filter={tabValue}
          setEditCondition={setEditCondition}
        />
      </Stack>
    </>
  );
}
