import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, DialogActions, Stack, Typography } from "@mui/material";

import TermOfOwner from "./components/TermOfOwner";
import { useWatch } from "react-hook-form";
import { ContentBoxTitle } from "../../components/common";
import TermOfPatient from "./components/TermOfPatient";
import { FormButtonGroup } from "../../components/form";
import { IFormInput } from "../signup/types";
import { SignUpRegister } from "../../services/scim";
import { PutPerson } from "../../services/fhir/person/PutPerson";
import { Warning } from "@mui/icons-material";
import TermText from "./components/TermText";

type IProps = {
  termOf: string;
  handleSubmit: any;
  navigateToNextPage: Function;
  control?: any;
};

export function TermsOfUse({
  termOf,
  handleSubmit,
  navigateToNextPage,
  control,
}: IProps) {
  const TermOfPatientChecked = useWatch({ control, name: "termOfPatient" });
  const TermOfOwnerChecked = useWatch({ control, name: "termOfOwner" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (data: IFormInput) => {
    setLoading(true);
    try {
      await SignUpRegister(data);
      const dataPerson = {
        identifier: data?.identifier,
        name: data?.firstName + " " + data.surname,
        birthDate: data?.birthdate,
      };
      await PutPerson(dataPerson, true);
      navigateToNextPage();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack width={{ lg: "155%", sm: "130%" }}>
      <ContentBoxTitle>Termos de Uso e Política de Privacidade</ContentBoxTitle>

      <Box
        maxHeight="500px"
        sx={{
          backgroundColor: "neutral0.main",
          overflowY: "scroll",
          paddingLeft: 4,
          paddingRight: 4,
        }}
        mt={2}
      >
        {termOf === "Patient" && (
          <>
            <TermText termOf="patient" />

            <TermOfPatient
              control={control}
              isAssigned={!TermOfPatientChecked}
            />
            <FormButtonGroup
              onNextButtonClick={handleSubmit(onSubmit)}
              onGoBackButtonClick={() => navigate("/")}
              nextButtonText={"Confirmar"}
              goBackButtonText={"Recusar"}
              disabled={!TermOfPatientChecked}
              disabledBackButton={TermOfPatientChecked}
            />
          </>
        )}
        {(termOf === "Owner" || termOf === "Organization") && (
          <>
            <TermText termOf="owner" />
            <TermOfOwner control={control} />

            <FormButtonGroup
              onNextButtonClick={handleSubmit(onSubmit)}
              onGoBackButtonClick={() => navigate("/")}
              nextButtonText={"Confirmar"}
              goBackButtonText={"Recusar"}
              disabled={!TermOfOwnerChecked}
            />
          </>
        )}
      </Box>
    </Stack>
  );
}
