import { useState } from "react";
import { Avatar, IconButton } from "@mui/material";
import { useFormatter } from "../../../../../utils/useFormatter";
import { useSessionContext } from "../../../../../contexts/SessionContext";
import { MobileMenu } from "../MobileMenu";

export function OptionsMenuMobile() {
  const { user } = useSessionContext();
  const [viewMenuMobile, setViewMenuMobile] = useState<boolean>(false);

  const handleClick = () => {
    setViewMenuMobile((prev: boolean) => !prev);
  };

  const { formatNameFirstInitials } = useFormatter;
  return (
    <>
      <IconButton
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
        sx={{
          ml: 2,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Avatar
          sx={{
            width: 44,
            height: 44,
            backgroundColor: "neutral0.main",
            color: "primary900.main",
            fontSize: "25px",
          }}
        >
          {formatNameFirstInitials(user?.name, 1)}
        </Avatar>
      </IconButton>

      <MobileMenu open={viewMenuMobile} setModalOpen={handleClick} />
    </>
  );
}
