import { Stack } from "@mui/material";
import { useQuestionnaireContext } from "../../../../contexts/QuestionnaireContext";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { useFhirData } from "../../../../utils/useFhirData";
import { GenericTimeline } from "../GenericTimeline";
import { AntropometricHistoryAccordion } from "./AntropometricHistoryAccordion";
import dayjs from "dayjs";

export function AnthropometricHistory() {
  const { summaryData } = useMedicalCareContext();
  const { questionnaireDefualt } = useQuestionnaireContext();

  const observationSummary = useFhirData.extractPatientSummaryResource(
    summaryData,
    "Observation"
  )?.[0];
  const encounterSummary = useFhirData.extractPatientSummaryResource(
    summaryData,
    "Encounter"
  )?.[0];

  const filterEncounter = useFhirData?.groupObservationsByEncounter(
    observationSummary?.entry?.filter((e: any) => {
      const verifyDate = questionnaireDefualt
        ? dayjs(questionnaireDefualt?.resource?.authored).isAfter(
            e?.resource?.effectiveDateTime
          )
        : true;
      return (
        !(
          e?.resource?.code?.text === "Tabagismo" ||
          e?.resource?.code?.text === "Consumo diário de álcool" ||
          e?.resource?.code?.coding?.[0]?.code === "72166-2" ||
          e?.resource?.code?.coding?.[0]?.code === "74013-4"
        ) && verifyDate
      );
    }),
    encounterSummary
  );

  return (
    <Stack>
      <GenericTimeline
        data={filterEncounter
          ?.filter((enc: any) =>
            questionnaireDefualt
              ? dayjs(enc?.answerDate).isAfter(
                  questionnaireDefualt?.resource?.authored
                )
              : true
          )
          ?.sort((a: any, b: any) => {
            const dateA = dayjs(a?.answerDate);
            const dateB = dayjs(b?.answerDate);

            return dateB.diff(dateA);
          })}
        component={(data: any, index: number) => (
          <AntropometricHistoryAccordion data={data} index={index} />
        )}
      />
    </Stack>
  );
}
