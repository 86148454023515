import { Pages } from "@mui/icons-material";
import { IFeatures } from "../../utils/interfaces";
import ExamSolicitation from "./ExamSolicitation";
import { useSessionContext } from "../../contexts/SessionContext";

export function useExamSolicitation() {
  const { access } = useSessionContext();
  const configs: IFeatures = {
    initial: false,
    active: access.type === "patient",
    path: "/examsolicitation",
    text: "Solicitação de email",
    icon: Pages,

    sideMenu: false,
    sideMenuIndex: 0,

    component: () => <ExamSolicitation />,
  };

  return configs;
}
