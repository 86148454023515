export const labelsStepperPrescription = [
  "Prescrição eletrônica, tratamento e procedimentos",
  "Visualizar documentos",
  "Assinar digitalmente e imprimir",
];

export const labelsStepperPrescription2 = [
  "Prescrição eletrônica, tratamento e procedimentos",
  "Assinar digitalmente e imprimir",
];
export const dictStepsPrescription: any = {
  0: "Avançar",
  1: "Gerar prescrição",
  2: "Finalizar",
};

export const dictStepsPrescription2: any = {
  0: "Gerar prescrição",
  1: "Finalizar",
};

export const translateDocuments: any = {
  certificate: "Relatório médico",
  procedure: "Procedimentos",
  simple: "Receituário simples",
  special: "Receituário de controle Especial",
  exam: "Exames",
};

export const translateTypes: any = {
  certificate: "Atestado médico",
  procedure: "Procedimento",
  medication: "Medicamento",
  exam: "Solicitação de exame",
};

export const enum Pages {
  S1Prescription,
  S2Document,
  S3SignDocument,
}

export const enum Pages2 {
  S1Prescription,
  S2SignDocument,
}
