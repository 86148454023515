import { Text, View, StyleSheet, Image, Page } from "@react-pdf/renderer";
import dayjs from "dayjs";
import { useFormatter } from "../../../utils/useFormatter";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#f9f9f9",
    width: "100%",
    padding: "130px 40px 250px 40px",
  },
  sectionMedication: {
    flexDirection: "column",
    flexGrow: 1,
  },
  headerMedication: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 100,
    textTransform: "uppercase",
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
  },
  contentMedication: {
    marginBottom: 5,
  },
  footerMedication: {
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    height: 250,
    padding: "5px 40px",
  },
  titleMedication: {
    fontSize: 22,
    width: 240,
    fontFamily: "Helvetica-Bold",
    textAlign: "center",
    textTransform: "uppercase",
  },
  text: {
    fontSize: 12,
    fontFamily: "Helvetica-Bold",
    textTransform: "uppercase",
    padding: "3px 0px",
  },
  textFooter: {
    fontSize: 12,
    fontFamily: "Helvetica-Bold",
    textTransform: "uppercase",
  },
  textResponse: {
    fontSize: 12,
    fontFamily: "Helvetica",
    textTransform: "uppercase",
  },
  textBold: {
    fontFamily: "Helvetica-Bold",
    textTransform: "uppercase",
  },
  textAuxiliar: {
    fontSize: 6,
    fontFamily: "Helvetica",
    textTransform: "lowercase",
  },
  centerText: {
    textAlign: "center",
    fontSize: 12,
    fontFamily: "Helvetica-Bold",
    textTransform: "uppercase",
  },
  box: {
    marginBottom: 20,
  },
  pageBreak: {
    marginTop: 20,
    borderTop: "1px solid black",
    paddingTop: 20,
  },
  pageBackground: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    minWidth: "100%",
    minHeight: "100%",
    zIndex: -1,
  },
});

const splitNoteIntoPages = (note: any, maxLines: any) => {
  const lines = note.split("\n");
  const pages = [];

  for (let i = 0; i < lines.length; i += maxLines) {
    pages.push(lines.slice(i, i + maxLines).join("\n"));
  }

  return pages;
};

interface IProps {
  filterSpecialMedication: any;
  dataPdf: any;
  documentHasBackground: any;
  imgBase64: any;
  gender: "male" | "female";
}

export function MedicationSpecialControl({
  filterSpecialMedication,
  dataPdf,
  documentHasBackground,
  imgBase64,
  gender,
}: IProps) {
  const crm = dataPdf?.[0]?.professionalData?.identifier?.[0]?.value
    .split("/")[1]
    .slice(2);

  const ufCrm = dataPdf?.[0]?.professionalData?.identifier?.[0]?.value
    .split("/")[1]
    .slice(0, 2);

  return (
    <Page wrap={true} size="A4" style={styles.page}>
      <>
        {imgBase64 && (
          <Image src={imgBase64} style={styles.pageBackground} fixed />
        )}
        <View style={styles.headerMedication} fixed>
          <Text style={styles.titleMedication}>
            {documentHasBackground ? "" : "RECEITUÁRIO DE CONTROLE ESPECIAL"}
          </Text>
        </View>

        <View style={styles.contentMedication}>
          {!documentHasBackground && (
            <View
              style={{
                height: "20vh",
                border: "1px solid #000",
                marginBottom: 10,
              }}
            >
              <View
                style={{
                  height: "2vh",
                  borderBottom: "1px solid #000",
                  marginTop: "1px",
                }}
              >
                <Text style={styles.centerText}>IDENTIFICAÇÃO DO EMITENTE</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <View style={{ width: "60%" }}>
                  <Text style={styles.text}>
                    NOME COMPLETO:
                    <Text style={styles.textResponse}>
                      {dataPdf?.[0]?.professionalData?.practitioner.display ||
                        ""}
                    </Text>
                  </Text>
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={styles.text}>
                      CRM: <Text style={styles.textResponse}>{crm || ""}</Text>
                    </Text>
                    <Text style={styles.text}>
                      UF: <Text style={styles.textResponse}>{ufCrm || ""}</Text>
                    </Text>
                  </View>
                  <Text style={styles.text}>
                    CIDADE:
                    <Text style={styles.textResponse}>
                      {dataPdf?.[0]?.location?.address?.city || "-"}
                    </Text>
                  </Text>
                  <Text style={styles.text}>
                    DATA:
                    <Text style={styles.textResponse}>
                      {dayjs().format("DD/MM/YYYY") || "-"}
                    </Text>
                  </Text>
                </View>
                <View
                  style={{
                    width: "40%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      height: "8vh",
                      width: "100%",
                      border: "1px solid #000",
                    }}
                  ></View>
                  <Text style={styles.text}>ASSINATURA MÉDICO(A)</Text>
                </View>
              </View>
            </View>
          )}

          <View style={{ flexDirection: "column", marginBottom: 20 }}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text style={styles.text}>
                {gender === "male" ? "dr." : "dra."}{" "}
                <Text style={styles.textResponse}>
                  {dataPdf?.[0]?.professionalData?.practitioner.display || ""}
                </Text>
              </Text>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <Text style={styles.textFooter}>
                  CRM:
                  <Text style={styles.textResponse}>
                    {`${crm}-${ufCrm}` || ""}
                  </Text>
                </Text>
              </View>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text style={styles.text}>
                Paciente:
                <Text style={styles.textResponse}>
                  {dataPdf?.[0]?.patientData || ""}
                </Text>
              </Text>
              <Text style={styles.text}>
                CPF:
                <Text style={styles.textResponse}>
                  {useFormatter.formatCPF(dataPdf?.[0]?.patientCPF)}
                </Text>
              </Text>
            </View>

            {documentHasBackground && (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  marginTop: "5px",
                }}
              >
                <Text style={styles.textFooter}>
                  DATA:
                  <Text style={styles.textResponse}>
                    {dayjs().format("DD/MM/YYYY")}
                  </Text>
                </Text>
              </View>
            )}

            <Text style={styles.text}>PRESCRIÇÃO:</Text>
            <View
              style={{
                // height: "28vh",
                width: "100%",
                backgroundColor: documentHasBackground
                  ? "transparent"
                  : "#e6e6e6",
              }}
            >
              {/* <View style={{ marginTop: "10px" }}> */}
              <Text style={styles.text}>
                <Text style={[styles.textResponse, styles.textBold]}>
                  {filterSpecialMedication?.[0].label}
                </Text>
              </Text>
              <Text style={styles.text}>
                <Text style={styles.textResponse}>
                  {" "}
                  {filterSpecialMedication?.[0].form}{" "}
                </Text>
              </Text>

              {filterSpecialMedication?.[0].note?.length && (
                <Text style={styles.text}>
                  OBS:
                  <Text style={styles.textResponse}>
                    {filterSpecialMedication?.[0].note}{" "}
                  </Text>
                </Text>
              )}

              {/* </View> */}
            </View>
          </View>
        </View>

        <View style={styles.footerMedication} fixed>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              opacity: documentHasBackground ? 0 : 1,
            }}
          >
            <View
              style={{
                border: "1px solid #000",
                width: "100%",
                height: "25vh",
                marginRight: "5px",
              }}
            >
              <View style={{ borderBottom: "1px solid #000" }}>
                <Text style={styles.centerText}>
                  IDENTIFICAÇÃO DO COMPRADOR
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  rowGap: "1px",
                }}
              >
                <Text style={styles.textFooter}>
                  NOME COMPLETO:
                  <Text style={styles.textResponse}></Text>
                </Text>
                <br />
                <br />
                <Text style={styles.textFooter}>
                  RG: <Text style={styles.textResponse}></Text>
                </Text>
                <Text style={styles.textFooter}>
                  ORGÃO EMISSOR:
                  <Text style={styles.textResponse}></Text>
                </Text>
                <Text style={styles.textFooter}>
                  ENDEREÇO COMPLETO:
                  <Text style={styles.textResponse}></Text>
                </Text>
                <br />
                <br />
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginRight: "40px",
                  }}
                >
                  <Text style={styles.textFooter}>
                    CIDADE:
                    <Text style={styles.textResponse}></Text>
                  </Text>
                  <Text style={styles.textFooter}>
                    UF: <Text style={styles.textResponse}></Text>
                  </Text>
                </View>

                <Text style={styles.textFooter}>
                  TELEFONE:
                  <Text style={styles.textResponse}></Text>
                </Text>
              </View>
            </View>
            <View
              style={{
                border: "1px solid #000",
                width: "100%",
                height: "25vh",
                marginLeft: "5px",
              }}
            >
              <View
                style={{
                  borderBottom: "1px solid #000",
                  paddingBottom: "2px",
                }}
              >
                <Text style={styles.centerText}>
                  IDENTIFICAÇÃO DO FORNECEDOR
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "24vh",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    rowGap: "3px",
                  }}
                >
                  <Text style={styles.textFooter}>NOME FARMACÊUTICO(A):</Text>
                  <br />
                  <br />
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginRight: "40px",
                    }}
                  >
                    <Text style={styles.textFooter}>CRF:</Text>
                    <Text style={styles.textFooter}>UF:</Text>
                  </View>
                  <Text style={styles.textFooter}>NOME FARMÁCIA:</Text>
                  <Text style={styles.textFooter}>ENDEREÇO:</Text>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginRight: "40px",
                    }}
                  >
                    <Text style={styles.textFooter}>CIDADE:</Text>
                    <Text style={styles.textFooter}>UF:</Text>
                  </View>
                  <Text style={styles.textFooter}>CNPJ:</Text>
                  <Text style={styles.textFooter}>TELEFONE:</Text>
                </View>
              </View>
              <View style={{ marginBottom: "5px" }}>
                <Text style={styles.centerText}>
                  ASSINATURA FARMACÊUTICO(A)
                </Text>
              </View>
            </View>
          </View>
        </View>
      </>
    </Page>
  );
}
