import { endpoints } from "../../configs/Settings";
import { useAuth } from "../../utils/useAuth";
import { useAxios } from "../../utils/useAxios";

export async function OAuthUser(username: string, password: string) {
  const url = `${endpoints.token_api}?scope=fluxmed_${
    username.split("[AT]")[0]
  }`;
  const data = `grant_type=password&username=${username}&password=${encodeURIComponent(
    password
  )}`;
  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getBasicToken(),
    "Content-Type": "application/x-www-form-urlencoded",
  };
  const response = await useAxios
    .post(url, data, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(`OAuthRequest error: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
  if (response) return response;
}
