import { Typography } from "@mui/material";
import { ScreenMobile } from "../../components/common/ScreenMobile";
import { BorderContent } from "./components/BorderContent";
import { LineInformationWithButton } from "./components/LineInformationWithButton";
import { useFormatter } from "../../utils/useFormatter";
import { useSessionContext } from "../../contexts/SessionContext";
import EditEmailDialog from "../profile/components/EditEmailDialog";
import { EditPhoneDialog } from "../profile/components/EditPhoneDialog";
import { useState } from "react";

export function ContactMobile({ open, onClose }: any) {
  const { user } = useSessionContext();

  const [isEditPhoneOpen, setIsEditPhoneOpen] = useState<boolean>(false);
  const [isEditEmailOpen, setIsEditEmailOpen] = useState<boolean>(false);

  return (
    <>
      <ScreenMobile title="Contato" open={open} onClose={onClose}>
        <BorderContent variant="body1">
          <Typography variant="body1" fontWeight={600} color="neutral700.main">
            Dados pessoais
          </Typography>
          <LineInformationWithButton
            label="Telefone"
            value={`${user?.phone}`}
            format={useFormatter.formatPhoneNumber}
            onClick={() => setIsEditPhoneOpen(true)}
          />
          <LineInformationWithButton
            label="Email"
            value={`${user?.email}`}
            onClick={() => setIsEditEmailOpen(true)}
          />
        </BorderContent>
      </ScreenMobile>

      <EditPhoneDialog
        isOpen={isEditPhoneOpen}
        setIsOpen={setIsEditPhoneOpen}
      />
      <EditEmailDialog
        isOpen={isEditEmailOpen}
        setIsOpen={setIsEditEmailOpen}
      />
    </>
  );
}
