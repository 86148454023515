import { Stack, Typography } from "@mui/material";
import { PractitionerAvatar } from "./PractitionerAvatar";
import { useFhirData } from "../../../utils/useFhirData";
import { AccordionButtons } from "./AccordionButtons";
import { Circle, VideoCameraFront } from "@mui/icons-material";
import { useLocationContext } from "../../../contexts/LocationContext";

export function AccordionHeader({
  id,
  schedule,
  setOpenAccordion,
  setRefetch,
}: any) {
  const { location } = useLocationContext();
  const healthcare = useFhirData.findActor(
    schedule?.resource.actor,
    "HealthcareService"
  )?.display;

  const practitioners = useFhirData.filterActor(
    schedule?.resource.actor,
    "Practitioner/"
  );

  const locationName = useFhirData.findActor(
    schedule?.resource.actor,
    "Location"
  )?.display;



  const locationTeleconsulting = useFhirData.virtualService(
    location?.find((loc: any) =>
      loc?.resource?.id.includes(
        schedule?.resource?.actor
          .find((sch: any) => sch.reference.includes("Location"))
          .reference.split("/")[1]
      )
    )?.resource
  );

  return (
    <Stack
      direction="row"
      p={1}
      sx={{
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Stack width="30%" flexDirection="row" alignItems="center" gap={2}>
        {schedule?.resource.active ? (
          <Circle sx={{ color: "primary.main", width: "12px" }} />
        ) : (
          <Circle sx={{ color: "neutral400.main", width: "12px" }} />
        )}
        {locationTeleconsulting ? <VideoCameraFront /> : <></>}
        <Typography>
          {`${schedule?.resource?.name || id} - ${healthcare}`}
          {/* R4 Version {`${
            schedule?.resource.extension?.find((el: any) =>
              el.url.includes("scheduleName")
            )?.valueString || id
          } - ${healthcare}`}   */}
        </Typography>
      </Stack>

      <Typography>{locationName}</Typography>

      <Stack
        direction="row"
        justifyContent="right"
        alignItems="center"
        width="30%"
        minWidth="fit-content"
        spacing={3}
      >
        <PractitionerAvatar filter={practitioners} />

        <AccordionButtons
          setRefetch={setRefetch}
          schedule={schedule}
          setOpenAccordion={setOpenAccordion}
        />
      </Stack>
    </Stack>
  );
}
