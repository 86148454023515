import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { FormAutoComplete, FormTextInput } from "../../../../components/form";
import { SubmitItemButton } from "./SubmitItemButton";
import { useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CustomMedicationOption } from "./CustomMedicationOption";
import { HelpOutline } from "@mui/icons-material";

interface IProps {
  methods: any;
  isEdit: boolean;
  action: Function;
}

const unit = [
  { value: 1, label: "Capsula(s)" },
  { value: 2, label: "Gota(s)" },
  { value: 3, label: "Comprimido(s)" },
];

export function HerbalMedicineTab({ methods, action, isEdit }: IProps) {
  const [isDescriptionOpen, setIsDescriptionOpen] = useState<boolean>(false);
  const { control, reset, handleSubmit, setValue } = methods;

  let fit = useWatch({ name: "herbalMedicine", control });

  function extrairNumero(string: string) {
    const match = string?.match(/\d+/);
    return match ? Number(match[0]) : null;
  }

  const onSubmit = async (data: any) => {
    try {
      if (!data.herbalMedicine) {
        toast.warn("Selecione algum item pra efetuar a prescrição");
        throw new Error("Formulário vazio");
      } else {
        const auxForm = [
          data?.quantity && `${data?.quantity}`,
          data?.form?.label && `${data?.form?.label}`,
          data?.frequency?.label && `a cada ${data?.frequency?.label}`,
          data?.time?.label && `por ${data?.time?.label}`,
        ]
          ?.filter((e: string) => e)
          ?.join(" ");
        const auxItem = {
          name: data?.herbalMedicine?.name,
          tag: data?.herbalMedicine?.tag,
          inputValue: {
            label: data?.herbalMedicine,
            value: data?.herbalMedicine,
          }.label,
          quantity: data?.quantity,
          frequency: data?.frequency,
          formValue: data?.form,
          time: data?.time,
          note: data?.note,
          form: auxForm,
          type: "herbalMedicine",
          tabValue: "3",
        };
        action(auxItem);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsDescriptionOpen(false);
      reset();
    }
  };

  useEffect(() => {
    if (fit) setIsDescriptionOpen(true);
  }, [fit]);

  return (
    <Stack gap={1}>
      <FormAutoComplete
        required
        freeSolo
        name="herbalMedicine"
        onInputChange={(e: any) => {
          if (e?.target?.value?.length)
            setValue("herbalMedicine", {
              name: e?.target?.value,
              label: e?.target?.value,
              value: e?.target?.value,
              type: "herbalMedicine",
            });
        }}
        control={control}
        label="Selecione o fitoterápico"
        renderOption={(option: any) => (
          <CustomMedicationOption option={option} />
        )}
        selectOptions={[]}
      />

      {isDescriptionOpen && (
        <>
          <Stack direction={{ md: "row" }} gap={2}>
            <Box width={{ xs: "100%", lg: "60%" }}>
              <FormTextInput
                type="number"
                name="quantity"
                control={control}
                label="Posologia"
              />
            </Box>

            <FormAutoComplete
              freeSolo
              onInputChange={(e: any) => {
                if (e?.target?.value?.length)
                  setValue("form", {
                    name: e?.target?.value,
                    label: e?.target?.value,
                    value: e?.target?.value,
                  });
              }}
              name="form"
              control={control}
              label="Forma"
              selectOptions={unit}
            />
            <FormAutoComplete
              freeSolo
              onInputChange={(e: any) => {
                if (e?.target?.value?.length) {
                  const debounce = setTimeout(() => {
                    if (extrairNumero(e?.target?.value) !== null)
                      setValue("frequency", {
                        name: `${extrairNumero(e?.target?.value)} h` || "",
                        label: `${extrairNumero(e?.target?.value)} h` || "",
                        value: extrairNumero(e?.target?.value) || "",
                      });
                    else {
                      setValue("frequency", {
                        name: "",
                        label: "",
                        value: "",
                      });
                    }
                  }, 1200);
                  return () => clearTimeout(debounce);
                }
              }}
              name="frequency"
              control={control}
              label="Intervalo de uso em horas"
              selectOptions={[]}
            />
            <FormAutoComplete
              freeSolo
              onInputChange={(e: any) => {
                if (e?.target?.value?.length) {
                  const debounce = setTimeout(() => {
                    if (extrairNumero(e?.target?.value) !== null)
                      setValue("time", {
                        name: `${extrairNumero(e?.target?.value)} dias` || "",
                        label: `${extrairNumero(e?.target?.value)} dias` || "",
                        value: extrairNumero(e?.target?.value) || "",
                      });
                    else {
                      setValue("time", {
                        name: "",
                        label: "",
                        value: "",
                      });
                    }
                  }, 1200);
                  return () => clearTimeout(debounce);
                }
              }}
              name="time"
              control={control}
              label="Tempo de tratamento em dias"
              selectOptions={[]}
            />
            <Tooltip
              title="Para tempo de tratamento indeterminado digite 0"
              placement="left"
              sx={{
                width: "8px",
                "&:hover": { backgroundColor: "transparent" },
              }}
            >
              <IconButton>
                <HelpOutline />
              </IconButton>
            </Tooltip>
          </Stack>
          <Stack>
            <FormTextInput
              multiline
              rows={3}
              label="Observação"
              name="note"
              control={control}
            />
          </Stack>
        </>
      )}

      <SubmitItemButton isEdit={isEdit} action={handleSubmit(onSubmit)} />
    </Stack>
  );
}
