import { Button, Stack } from "@mui/material";

export function OptionsButtons({ value, setValue }: any) {
  const handleToggleView = (type: 0 | 1 | 2) => {
    setValue(type);
  };
  return (
    <Stack width="100%" spacing={2} direction="row" justifyContent="stretch">
      <Button
        variant={value === 0 ? "contained" : "outlined"}
        onClick={() => handleToggleView(0)}
        sx={{
          borderRadius: "8px",
          width: "100%",
          fontSize: "16px",
          fontWeight: 600,
        }}
      >
        Próximas consultas
      </Button>
      <Button
        variant={value === 1 ? "contained" : "outlined"}
        color="secondary"
        onClick={() => handleToggleView(1)}
        sx={{
          borderRadius: "8px",
          width: "100%",
          fontSize: "16px",
          fontWeight: 600,
        }}
      >
        Horários disponíveis
      </Button>

      <Button
        variant={value === 2 ? "contained" : "outlined"}
        onClick={() => handleToggleView(2)}
        sx={{
          borderRadius: "8px",
          width: "100%",
          fontSize: "16px",
          fontWeight: 600,
          borderColor: "neutral500.main",
          backgroundColor: value === 2 ? "neutral600.main" : "transparent",
          color: value === 2 ? "neutral0.main" : "neutral600.main",
          "&:hover": {
            backgroundColor:
              value === 2 ? "neutral700.main" : "neutral100.main",
            borderColor: "neutral700.main",
          },
        }}
      >
        Consultas realizadas
      </Button>
    </Stack>
  );
}
