import { IconButton, TableCell, Tooltip } from "@mui/material";
import { Check, Close, NoteAdd } from "@mui/icons-material";

export function Actions({ row, openCancelDialog }: any) {
  return (
    <TableCell align="center">
      {row?.status !== "cancelled" && (
        <Tooltip title="Cancelar consulta">
          <IconButton
            onClick={() => openCancelDialog(row.id)}
            sx={{
              color: "error600.main",
              "&:hover": {
                background: "error700.main",
              },
            }}
          >
            <Close />
          </IconButton>
        </Tooltip>
      )}

      {(row?.status === "pending" || row?.status === "proposed") && (
        <IconButton>
          <Check />
        </IconButton>
      )}
    </TableCell>
  );
}
