import { Button, styled } from "@mui/material";

interface IProps {
  children: React.ReactNode;
  width: string;
  height: string;
  disabled?: boolean;
  textAlign?: string;
  onClick(): any;
  id?: string;
}

export const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.neutral0.main,
  borderRadius: 10,
  border: `1px solid ${theme.palette.primary700.main}`,
  fontSize: theme.typography.subtitle2.fontSize,
  color: theme.palette.primary700.main,
  textTransform: "none",
  ":hover": {
    backgroundColor: theme.palette.neutral0.main,
    color: theme.palette.primary800.main,
  },
  ":disabled": {
    border: `1px solid`,
    borderColor: theme.palette.neutral300.main,
    color: theme.palette.neutral600.main,
    backgroundColor: theme.palette.neutral300.main,
  },
}));

export function SecondaryButton({
  children,
  width,
  height,
  disabled,
  textAlign,
  onClick,
  id,
}: IProps) {
  return (
    <CustomButton
      id={id}
      variant="contained"
      sx={{ width, height, justifyContent: textAlign }}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </CustomButton>
  );
}
