import { Avatar, Chip, Stack, Typography } from "@mui/material";

interface IProps {
  namePractitioner: string;
  selectedPractitioner: boolean;
  healthcare: any;
  onClick: VoidFunction;
}

export function CardProfessional({
  namePractitioner,
  selectedPractitioner,
  healthcare,
  onClick,
}: IProps) {
  const formatName = namePractitioner.split(" ");
  const nameCustom = `${formatName[0]} ${formatName[1]} `;

  return (
    <Stack
      direction="row"
      width="100%"
      minWidth="20%"
      p={1}
      spacing={1}
      justifyContent="flex-start"
      alignItems="center"
      border={selectedPractitioner ? "2px solid" : "1px solid"}
      borderColor="neutral400.main"
      borderRadius="5px"
      onClick={onClick}
      sx={{
        backgroundColor: `${
          selectedPractitioner ? "neutral300.main" : "neutral0.main"
        }`,
      }}
    >
      <Avatar
        sx={{
          width: 28,
          height: 28,
          background: "#009E8C",
          color: "white",
          fontSize: "16px",
        }}
      >
        {nameCustom[0]}
      </Avatar>
      <Typography textAlign="center" variant="body1">
        Dr. {nameCustom}
      </Typography>

      <Chip
        label={healthcare}
        size="small"
        sx={{
          m: 0.5,
          color: "neutral700.main",
          fontWeight: 500,
          backgroundColor: "#f1f1f1",
        }}
      />
    </Stack>
  );
}
