import { useEffect, useState } from "react";
import {
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  Stack,
} from "@mui/material";
import dayjs from "dayjs";
import { Search } from "@mui/icons-material";

import {
  LargeContentBox,
  PageTitle,
  PrimaryButton,
} from "../../components/common";
import { PrivateMainLayout } from "../../components/layout";
import { ConsentTabs } from "./components/ConsentTabs";
import { useSessionContext } from "../../contexts/SessionContext";
import { GetConsents } from "../../services/consent";
import { RevokeConsentDialog } from "./components/RevokeConsentDialog";
import { RenewConsentDialog } from "./components/RenewConsentDialog";
import { NewConsentDialog } from "./components/NewConsentDialog";
import { useConsentContext } from "../../contexts/ConsentContext";
import { ConfirmConsentDialog } from "./components/ConfirmConsentDialog";
import { useDatetime } from "../../utils/useDatetime";
import { usePrivacyContext } from "../../contexts/PrivacyContext";

import { useOrganizationContext } from "../../contexts/OrganizationContext";
import { PrivacyAlertDialog } from "./components/PrivacyAlertDialog";
import { useFormatter } from "../../utils/useFormatter";
import { isMobile } from "react-device-detect";
import { BundlePatientSummary } from "../../services/fhir";
import { useDict } from "../../utils/useDict";

export function Consents() {
  const { user } = useSessionContext();
  const {
    setNewConsentDialog,
    resetStates,
    newConsentDialog,
    confirmConsentDialog,
    revokeDialog,
    renewDialog,
    setConsentType,
  } = useConsentContext();

  const { formatNameDisplay } = useFormatter;

  const { isSharing } = usePrivacyContext();
  const { organizationList } = useOrganizationContext();

  const { formatToDDMMYYYY, formatToHHmm } = useDatetime;
  const [searchField, setSearchField] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [granted, setGranted] = useState<any>([]);
  const [received, setReceived] = useState([]);

  const [refetch, setRefetch] = useState<boolean>(false);

  const [selectedTab, setSelectedTab] = useState<string>("1");

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
    if (newValue === "1") setConsentType("cpf");
    else if (newValue === "2") setConsentType("cnpj");
    else setConsentType("document");
  };
  function formatConsent(identifier: any, name: any, el: any) {
    const isValid =
      dayjs(el?.vigencia?.split("T")?.[0]).isAfter(dayjs()) || !el?.vigencia;

    return {
      identifier: identifier || "-",
      name: formatNameDisplay(name || identifier) || "-",
      subject: el?.sujeito?.id,
      date: dayjs(el?.hora).isValid() ? formatToDDMMYYYY(dayjs(el.hora)) : "-",
      hour: dayjs(el?.hora).isValid() ? formatToHHmm(dayjs(el.hora)) : "-",
      fullDate: el.hora,
      vigency: dayjs(el?.vigencia).isValid()
        ? formatToDDMMYYYY(dayjs(el.vigencia))
        : null,
      isValid: isValid,
      motivo: el?.contexto?.motivoAcesso,
      status: isValid ? "valid" : "expired",
      binaryUrl: el?.binaryUrl,
    };
  }

  useEffect(() => {
    resetStates();
    async function fetchData() {
      setLoading(true);

      const documentReference = await BundlePatientSummary({
        identifier: user.username,
        entries: ["DocumentReference"],
      });

      const documentResource = documentReference.entry
        ?.flatMap((el: any) => el.resource.entry)
        ?.filter(
          (item: any) =>
            item !== undefined &&
            item.resource.resourceType.includes("DocumentReference")
        );

      try {
        const grantedResponse = await GetConsents({
          identifier: user.username,
          type: "granted",
        });

        const findResource = (id: string) => {
          const filterDocument = documentResource.find(
            (el: any) => el.resource.id === id
          );
          return filterDocument;
        };

        const receivedResponse = await GetConsents({
          identifier: user.username,
          type: "received",
        });
        setReceived(
          receivedResponse?.data
            ?.filter(
              (el: any) =>
                el?.recurso?.id !== user.username && el?.recurso?.nome
            )
            ?.map((el: any) =>
              formatConsent(el?.recurso?.id, el?.recurso?.nome, el)
            )
        );

        const activeConsents = grantedResponse?.data?.filter(
          (el: any) => el?.acao === "Autorizar"
        );

        const auxDocments = activeConsents
          ?.filter((el: any) => el?.recurso?.idDocumento)
          ?.map((el: any) => {
            const documentReference = findResource(el?.sujeito?.id);

            return formatConsent(
              useDict.signedDocsType[
                documentReference?.resource?.category?.[0]?.coding?.[0]?.display
              ] || "",

              documentReference?.resource?.contained?.find(
                (el: any) => el?.resourceType === "Practitioner"
              )?.name?.[0]?.given?.[0] || "",
              {
                ...el,
                binaryUrl:
                  documentReference?.resource?.content?.[0]?.attachment?.url ||
                  "",
              }
            );
          });

        const auxIndividual =
          activeConsents
            ?.filter(
              (el: any) =>
                !el.contexto.instituicao &&
                el?.sujeito?.id !== user.username &&
                el?.sujeito?.nome
            )
            ?.map((el: any) =>
              formatConsent(el?.sujeito?.id, el?.sujeito?.nome, el)
            ) || [];

        const auxOrganization =
          activeConsents
            ?.filter((el: any) => el.contexto.instituicao)
            ?.map((el: any) => {
              const organization = organizationList?.find(
                (org: any) =>
                  org.resource?.id.split("-")[1] === el?.contexto?.instituicao
              );

              const alias =
                organization?.resource?.alias?.[0] !== "null"
                  ? organization?.resource?.alias?.[0]
                  : organization?.resource?.name;

              return formatConsent(el?.contexto?.instituicao, alias, el);
            }) || [];

        setGranted([auxIndividual, auxOrganization, auxDocments]);
      } catch (err) {
        console.log("Fetch consents", err);
      } finally {
        setLoading(false);
      }
    }
    if (isSharing) fetchData();
  }, [isSharing, user.username, refetch]);

  return (
    <PrivateMainLayout
      noSidebar={isMobile}
      mobileView={isMobile}
      noPadding={isMobile}
    >
      {!isSharing ? (
        <PrivacyAlertDialog />
      ) : (
        <LargeContentBox>
          <Stack width="100%" spacing={5}>
            <PageTitle>Compartilhamentos</PageTitle>

            <Stack
              direction={{ sm: "row", xs: "column" }}
              spacing={3}
              alignItems="center"
            >
              <FormControl
                sx={{ width: "100%", marginBottom: { xs: 2, lg: 0 } }}
                variant="filled"
              >
                <FilledInput
                  id="filled-adornment-password"
                  onChange={(e) => setSearchField(e.target.value.toLowerCase())}
                  type="text"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <PrimaryButton
                width="100%"
                maxWidth="350px"
                height="54px"
                onClick={() => setNewConsentDialog(true)}
              >
                Novo consentimento
              </PrimaryButton>
            </Stack>

            <ConsentTabs
              selectedTab={selectedTab}
              handleChangeTab={handleChangeTab}
              searchField={searchField}
              granted={granted}
              received={received}
            />
          </Stack>

          {newConsentDialog && <NewConsentDialog defaultTab={selectedTab} />}
          {confirmConsentDialog && (
            <ConfirmConsentDialog setRefetch={setRefetch} />
          )}
          {revokeDialog && <RevokeConsentDialog setRefetch={setRefetch} />}
          {renewDialog && <RenewConsentDialog setRefetch={setRefetch} />}
        </LargeContentBox>
      )}
    </PrivateMainLayout>
  );
}
