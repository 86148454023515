import { Person } from "@mui/icons-material";
import { IFeatures } from "../../utils/interfaces";
import { Profile } from "./Profile";
import { currentFeatures } from "../../configs/Settings";

export function useProfile() {
  const configs: IFeatures = {
    active: currentFeatures.profile,
    path: "/profile",
    icon: Person,
    text: "Perfil",

    avatarMenu: true,
    avatarIndex: 0,

    component: () => <Profile />,
  };

  return configs;
}
