import { Business } from "@mui/icons-material";
import { IFeatures } from "../../utils/interfaces";
import { RegisterOrganization } from "./RegisterOrganization";
import { currentFeatures } from "../../configs/Settings";

export function useRegisterOrganization() {
  const configs: IFeatures = {
    initial: true,
    active: currentFeatures.register,
    path: "/register",
    icon: Business,
    text: "Registrar clínica",

    sideMenu: true,

    component: () => <RegisterOrganization />,
  };

  return configs;
}
