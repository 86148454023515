import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Stack,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { GenericTable } from "../../../../../components/GenericTable/GenericTable";
import { ClinicalEncounterActions } from "./ClinicalEncounterActions";
import dayjs from "dayjs";
import { useOrganizationContext } from "../../../../../contexts/OrganizationContext";

interface IProps {
  title: string;
  data: any;
  openVisualizer: any;
  verifyVisualizer: any;
}

export function ClinicalEncounter({
  title,
  data,
  openVisualizer,
  verifyVisualizer,
}: IProps) {
  const { organizationList } = useOrganizationContext();

  const columns = [
    {
      column: "Organização",
      rowName: "organization",
    },
    {
      column: "Especialidade",
      rowName: "serviceType",
    },
    { column: "Profissional", rowName: "practitioner" },
    { column: "Data", rowName: "date" },
  ];

  const rowData = data
    // .filter((el: any) => el.encounter.status === "completed")
    ?.map((el: any) => {
      const encounter = el?.encounter;
      const questionnaire = el?.questionnaire?.[0]?.resource;

      return {
        appointmentId:
          el?.encounter?.appointment?.[0]?.reference?.split("/")?.[1],
        subject: encounter?.subject?.reference?.split("-")?.[1],
        encounterId: el?.encounter?.id,
        participant: el?.encounter?.participant,
        organizationId: encounter?.serviceProvider.reference.split("-")[1],
        status: el?.encounter?.status,
        organization:
          organizationList?.find((el: any) =>
            el.resource.id.includes(
              encounter?.serviceProvider.reference.split("/")[1]
            )
          )?.resource?.name ||
          encounter?.serviceProvider.reference.split("-")[1],
        serviceType:
          encounter?.serviceType?.find((el: any) =>
            el.reference?.reference?.includes("HealthcareService")
          )?.reference?.display || encounter?.class?.[0]?.coding?.[0]?.display,
        date: dayjs(encounter?.actualPeriod?.start).format("DD/MM/YYYY"),
        questionnarie: questionnaire?.item,
        observations: el?.obs,
        practitioner:
          encounter?.participant
            ?.find((el: any) => el?.actor?.reference?.includes("Practitioner"))
            ?.actor?.display.toUpperCase() ||
          encounter?.participant?.find((el: any) =>
            el?.actor?.reference?.includes("Practitioner")
          )?.actor?.value ||
          encounter?.participant?.find((el: any) =>
            el?.actor?.type?.includes("Practitioner")
          )?.actor?.identifier?.value,
      };
    });
  // ?.filter((e: any) => e?.questionnarie || e?.status === "completed");

  return (
    <Accordion sx={{ width: "100%" }} elevation={5}>
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Stack direction="row" alignItems="center" gap={1}>
          {title}
          <Avatar
            sx={{
              width: 26,
              height: 26,
              fontSize: "14px",
              fontWeight: 600,
              backgroundColor: "primary100.main",
              color: "primary1000.main",
            }}
          >
            {data?.length || "0"}
            {/* {data.filter((el: any) => el.encounter.status === "completed")
              .length || "0"} */}
          </Avatar>
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <GenericTable
          tableColumns={columns}
          tableRows={rowData}
          actions={(rowData: any) => (
            <ClinicalEncounterActions
              verifyVisualizer={verifyVisualizer}
              openVisualizer={openVisualizer}
              row={rowData}
            />
          )}
        />
      </AccordionDetails>
    </Accordion>
  );
}
