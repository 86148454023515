import { ScreenMobile } from "../../components/common/ScreenMobile";
import { Divider, Stack, Typography } from "@mui/material";
import { LineInformation } from "./components/LineInformation";
import { useSessionContext } from "../../contexts/SessionContext";
import { useFormatter } from "../../utils/useFormatter";
import { BorderContent } from "./components/BorderContent";
import { LineInformationWithButton } from "./components/LineInformationWithButton";

export function PersonalInformationMobile({ open, onClose }: any) {
  const { user, userPatient } = useSessionContext();

  return (
    <ScreenMobile title="Informações pessoais" open={open} onClose={onClose}>
      <Stack gap={1} mt={1} width="100%" height="100%" maxHeight="500px">
        <BorderContent>
          <Typography variant="body1" fontWeight={600} color="neutral700.main">
            Dados pessoais
          </Typography>
          <LineInformation label="Nome: " value={`${user.name}`} />
          <Divider sx={{ width: "100%", background: "neutral400.main" }} />
          <LineInformation label="CPF: " value={`${user.username}`} />
          <Divider sx={{ width: "100%", background: "neutral400.main" }} />
          <LineInformation
            label="Data de nascimento: "
            value={`${user.birthDate}`}
            format={useFormatter.formatDate}
          />
          <Divider sx={{ width: "100%", background: "neutral400.main" }} />
          <LineInformation
            label="Gênero: "
            value={`${userPatient.gender}`}
            format={useFormatter.formatGender}
          />
        </BorderContent>

        <BorderContent>
          <Typography variant="body1" fontWeight={600} color="neutral700.main">
            Saúde
          </Typography>
          <LineInformationWithButton label="Peso" value="-" />
          <LineInformationWithButton label="Altura" value="-" />
          <LineInformationWithButton label="Tipo sanguíneo" value="-" />
        </BorderContent>
        <br />
      </Stack>
    </ScreenMobile>
  );
}
