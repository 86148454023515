import React from "react";
import { CommonDialog } from "../../../../components/common/CommonDialog";
import { DialogTitle, Stack } from "@mui/material";
import { FormButtonGroup, FormSelectInput } from "../../../../components/form";
import { useForm } from "react-hook-form";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { PatchPatient } from "../../../../services/fhir/patient/PatchPatient";
import { toast } from "react-toastify";

interface IProps {
  type: "add" | "edit";
  isOpen: boolean;
  handleClose: VoidFunction;
  display: any;
}

export function RaceDialog({ type, isOpen, handleClose, display }: IProps) {
  const { userPatient, fetchUser } = useSessionContext();

  const codesRace = [
    { display: "Branca", value: "01" },
    { display: "Preta", value: "02" },
    { display: "Parda", value: "03" },
    { display: "Amarela", value: "04" },
    { display: "Indígena", value: "05" },
    { display: "Sem informação", value: "99" },
  ];

  const { control, handleSubmit } = useForm();

  const auxDefault = codesRace?.find(
    (el: any) => el.display === display
  )?.value;

  function verifyPath(extensionRace: any) {
    if (!extensionRace) {
      return "/extension";
    }
    if (extensionRace === -1) {
      return "/extension/-";
    } else {
      return `/extension/${extensionRace}`;
    }
  }

  const auxFindExtensionRace = userPatient?.extension?.findIndex(
    (el: any) => el.url === "http://www.saude.gov.br/fhir/StructureDefinition/BRRacaCorEtnia-1.0"
  );

  const onSubmit = async (data: any) => {
    const auxDisplay = codesRace?.find(
      (el: any) => el.value === data.race
    )?.display;

    const path = verifyPath(auxFindExtensionRace);
    const content = {
      url: "http://www.saude.gov.br/fhir/StructureDefinition/BRRacaCorEtnia-1.0",
      valueCodeableConcept: {
        coding: [
          {
            system: "http://www.saude.gov.br/fhir/r4/CodeSystem/BRRacaCor",
            code: data.race,
            display: auxDisplay,
          },
        ],
      },
    };

    try {
      await PatchPatient({
        patientId: userPatient?.id,
        operation: type === "add" ? "add" : "replace",
        path,
        content: !auxFindExtensionRace ? [content] : content,
      });
      await fetchUser();
      toast.success(
        `Raça ou etnia ${
          type === "add" ? "adicionado" : "atualizado"
        } com sucesso!`
      );
      handleClose();
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <CommonDialog isOpen={isOpen} handleClose={handleClose}>
      <DialogTitle>
        {type === "add" ? "Adicionar" : "Atualizar"} Raça ou Etnia
        <Stack marginY={2}>
          <FormSelectInput
            defaultValue={auxDefault}
            name="race"
            label=""
            control={control}
            selectOptions={codesRace}
            required
          />
        </Stack>
        <FormButtonGroup
          goBackButtonText="Cancelar"
          nextButtonText="Salvar"
          onGoBackButtonClick={handleClose}
          onNextButtonClick={handleSubmit(onSubmit)}
        />
      </DialogTitle>
    </CommonDialog>
  );
}
