import { Button, Tooltip, Typography } from "@mui/material";

interface IProps {
  type: string;
}

export function InterventionsTags({ type }: IProps) {
  const tags = [
    {
      name: "CONTROLE ESPECIAL",
      value: "CE",
      color: "#E1E6EF",
      display: "Controle especial",
    },
    {
      name: "TARJA VERMELHA",
      value: "CE",
      color: "#db3f30",
      display: "Controle especial - Tarja vermelha",
    },
    {
      name: "TARJA PRETA",
      value: "CE",
      color: "white",
      background: "black",
      display: "Controle especial - Tarja preta",
    },
    {
      name: "TARJA AMARELA",
      value: "AM",
      color: "#FEE2A9",
      display: "Receita amarela",
    },
    {
      name: "TARJA AZUL",
      value: "AZ",
      color: "#A4CBE8",
      display: "Receita azul",
    },
    {
      name: "SEM TARJA",
      value: "BR",
      color: "white",
      display: "Receita branca",
    },
    {
      name: "EX",
      display: "Exame",
      value: "EX",
      color: "#FDCF72",
    },
    {
      name: "AT",
      value: "AT",
      color: "#009E8C",
      display: "Atestado",
    },
  ];

  const tag = tags.find((el) =>
    type.toUpperCase().includes(el.name.toUpperCase())
  );
  if (!tag) return null;
  return (
    <>
      <Tooltip title={tag.display}>
        <Button
          sx={{
            background: tag.background || tag.color || "white",

            width: "21px",
            minWidth: "21px",
            height: "22px",
            "&:hover": {
              backgroundColor: tag.background || tag.color || "white",
            },
          }}
          variant="contained"
        >
          <Typography
            sx={{ color: tag.background ? tag.color : "black" }}
            variant="body1"
            color="neutral700.main"
          >
            {tag.value || "-"}
          </Typography>
        </Button>
      </Tooltip>
    </>
  );
}
