import React, { useState } from "react";

import { SmallContentBox } from "../../components/common";
import { PublicEmptyLayout } from "../../components/layout";
import { Steps } from "./Types";
import { FormProvider, useForm } from "react-hook-form";
import { useFirebaseService } from "../../utils/useFirebase";
import { toast } from "react-toastify";
import { ProfileSearch } from "../../services/scim";
import { useScimData } from "../../utils/useScimData";
import { RecoverPassword } from "../../services/fhir";
import { ContactForm } from "./components/ContactForm";
import { VerificationCode } from "./components/VerificationCode";
import { ConfirmationMessage } from "./components/ConfirmationMessage";

interface IFormInput {
  credential: string;
  verificationCode: string;
}

const defaultValues = {
  credential: "",
  verificationCode: "",
};

export function Recover() {
  const [page, setPage] = useState(Steps.S1ContactForm);
  const methods = useForm<IFormInput>({ defaultValues });
  const { handleSubmit, control, getValues } = methods;
  const [loading, setLoading] = useState(false);
  const { sendVerificationCode, verifyCode } = useFirebaseService;

  const handleVerifyCode = async () => {
    try {
      const verificationCode = getValues("verificationCode");
      await verifyCode(verificationCode);
      toast.success("Código validado com sucesso!");
      setPage((prev: number) => prev + 1);
    } catch (error) {
      toast.error("Código inválido");
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data: IFormInput) => {
    setLoading(true);
    if (page === Steps.S1ContactForm) {
      try {
        const Scim2UserResponse = await ProfileSearch(
          "application",
          data.credential?.replace(/[.-]/g, "")
        ).then((response) => response?.Resources);

        const phoneNumber = useScimData.getUser(Scim2UserResponse)?.phone;
        if (!phoneNumber) throw new Error("Usuário não encontrado");

        await sendVerificationCode(phoneNumber);
        setPage((prev: number) => prev + 1);
      } catch (err) {
        toast.error(`${err}`);
      } finally {
        setLoading(false);
      }
    }
    if (page === Steps.S2VerificationCode) {
      try {
        await handleVerifyCode();
        await RecoverPassword(
          data.credential.replace(/[.-]/g, ""),
          "application"
        );
      } catch (err) {
        console.log("err", err);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <PublicEmptyLayout>
      <SmallContentBox>
        <FormProvider {...methods}>
          {page === Steps.S1ContactForm && (
            <ContactForm
              onClick={handleSubmit(onSubmit)}
              control={control}
              loading={loading}
            />
          )}

          {page === Steps.S2VerificationCode && (
            <VerificationCode
              onClick={handleSubmit(onSubmit)}
              control={control}
              loading={loading}
            />
          )}

          {page === Steps.S3ConfirmationMessage && <ConfirmationMessage />}
        </FormProvider>
      </SmallContentBox>
    </PublicEmptyLayout>
  );
}
