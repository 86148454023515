import { toast } from "react-toastify";
import { endpoints } from "../../../../configs/Settings";
import { HTTPResponseStatus } from "../../../../utils/enum";

import { useAuth } from "../../../../utils/useAuth";
import { useAxios } from "../../../../utils/useAxios";
import { auxiliarData } from "../../AuxiliarData";
import { APPC_DOC_CONSENT } from "../../../appc/APPC_DOC_CONSENT";

export async function PostDocumentReference(
  objData: any,
  encounter: any,
  refs: any,
  signed?: boolean,
  uuidDefault?: any,
  idValue?: any
) {
  let url = `${endpoints.XDSB_domain}/mhd/fhir`;

  const defaultId =
    idValue ||
    Date.now() +
      Math.floor(Math.random() * 10000)
        .toString()
        .padStart(4, "0");

  const data = auxiliarData.DocumentReference(
    objData,
    encounter,
    refs,
    signed,
    uuidDefault,
    defaultId
  );

  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+xml",
  };

  const response = await useAxios
    .post(url, data, { headers, timeout: 20000 })
    .then((response) => {
      if (
        response.status !== HTTPResponseStatus.Created &&
        response.status !== HTTPResponseStatus.Success
      ) {
        throw new Error(`PostDocumentReference: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("PostDocumentReference ", error);
      // toast.error(`PostDocumentReference Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (signed)
    await APPC_DOC_CONSENT({
      rule: "Permit",
      patient_id: encounter.subject,
      doc_id: uuidDefault,
      idValue: defaultId,
      cnes: objData?.organization?.id?.split("-")?.[1],
      vigency: 90,
    });

  if (response) return response;
}
