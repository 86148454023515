import { Box, Stack } from "@mui/material";
import { DisplayTextField } from "../../../components/common";
import {
  FormButtonGroup,
  FormDateInput,
  FormSelectInput,
  FormTextInput,
} from "../../../components/form";
import { PageSubTitle } from "./PageSubTitle";
import dayjs from "dayjs";
import { useRegex } from "../../../utils/useRegex";
import { useFormatter } from "../../../utils/useFormatter";
import { IPatientData, IRelatedPersonData } from "../../../utils/interfaces";
import { useState } from "react";
import { toast } from "react-toastify";
import { PostPatient } from "../../../services/fhir";
import { useSessionContext } from "../../../contexts/SessionContext";
import { oids } from "../../../configs/Settings";
import { PatchPatient } from "../../../services/fhir/patient/PatchPatient";
import { PostRelatedPerson } from "../../../services/fhir/relatedperson/PostRelatedPerson";
import { useWatch } from "react-hook-form";
import { isMobile } from "react-device-detect";

interface IProps {
  control: any;
  handleSubmit: Function;
  reset: any;
  onBackButtonClick: Function;
  navigateToNextPage: Function;
  setValue: any;
  minorPatient: boolean;
}

export function SummaryDependent({
  control,
  handleSubmit,
  reset,
  onBackButtonClick,
  navigateToNextPage,
  minorPatient,
}: IProps) {
  const [loading, setLoading] = useState<boolean>(false);

  const { user, userPatient, setRefetch } = useSessionContext();

  const nameData = useWatch({ name: "name", control });
  const identifierData = useWatch({ name: "identifier", control });
  const birthDateData = useWatch({ name: "birthdate", control });

  const typeOptionsGender: Array<any> = [
    { display: "Masculino", value: "male" },
    { display: "Feminino", value: "female" },
  ];

  const createPatientMPI = async (data: IPatientData & IRelatedPersonData) => {
    const bodyPatient = {
      name: nameData,
      identifier: identifierData,
      birthDate: dayjs(birthDateData, "DDMMYYYY").format("YYYY-MM-DD"),
      phoneNumber: data?.phoneNumber?.replace(/[^a-zA-Z0-9]/g, ""),
      gender: data?.gender,
    };

    if (data?.patientData) {
      let patientActorData = {
        reference: `Patient/${data.patientData.id}`,
        display: data.patientData.name?.[0]?.text,
      };
      handleRelatedPerson(patientActorData, data);
    } else {
      try {
        const mpiResponse = await PostPatient(bodyPatient);

        if (mpiResponse?.data) {
          toast.success("Paciente criado com sucesso");

          let patientActorData = {
            reference: `Patient/${mpiResponse.data?.id}`,
            display: mpiResponse.data?.name?.[0]?.text,
          };
          handleRelatedPerson(patientActorData, data);
        }
      } catch (err) {
        console.log("Erro ao criar o paciente:", err);
      }
    }
  };

  const handleRelatedPerson = async (patientActorData: any, data: any) => {
    try {
      const responseRelatedPerson = await PostRelatedPerson(
        { ...data, cpfResponsible: user?.username },
        patientActorData
      );
      const relatedPersonId = responseRelatedPerson?.data?.id;

      const auxExistsLink = userPatient?.hasOwnProperty("link");
      const path = !auxExistsLink ? "/link" : "/link/-";

      const content = {
        other: { reference: `RelatedPerson/${relatedPersonId}` },
        type: "seealso",
      };

      try {
        await PatchPatient({
          patientId: `${oids.cpf}-${user.username}`,
          operation: "add",
          path,
          content: !auxExistsLink ? [content] : content,
        });
      } catch (err) {
        console.log("Erro ao atualizar o paciente:", err);
      } finally {
        setLoading(false);
        navigateToNextPage();
        reset();
        setRefetch((prev: any) => !prev);
      }
    } catch (err) {
      console.log("Erro ao criar o RelatedPerson:", err);
      setLoading(false);
    }
  };

  return (
    <Stack
      spacing={isMobile ? 5 : 10}
      width={isMobile ? "90%" : "60%"}
      alignSelf="center"
    >
      <Stack spacing={isMobile ? 2 : 3}>
        <PageSubTitle
          subText="Para finalizar o cadastro, preencha os campos vazios abaixo."
          subTitle="Finalize o cadastro do dependente"
        />
        {minorPatient ? (
          <>
            <Box>
              <FormTextInput
                label="Nome completo"
                placeholder="Nome completo"
                name="name"
                control={control}
                required={true}
                autoFocus
              />
            </Box>
            <Stack
              direction={isMobile ? "column" : "row"}
              spacing={isMobile ? 2 : 3}
            >
              <DisplayTextField
                variant="filled"
                label="CPF"
                value={identifierData || ""}
                mask={useFormatter.formatCPF}
              />
              <FormDateInput
                label="Data de nascimento"
                placeholder="Data de nascimento"
                name="birthdate"
                control={control}
                required={true}
              />
            </Stack>
          </>
        ) : (
          <>
            <DisplayTextField
              variant="filled"
              label="Nome completo"
              value={nameData || ""}
            />
            <Stack
              direction={isMobile ? "column" : "row"}
              spacing={isMobile ? 2 : 3}
            >
              <DisplayTextField
                variant="filled"
                label="CPF"
                value={identifierData || ""}
                mask={useFormatter.formatCPF}
              />
              <DisplayTextField
                variant="filled"
                label="Data de nascimento"
                value={
                  dayjs(birthDateData, "DDMMYYYY").format("DD/MM/YYYY") || ""
                }
              />
            </Stack>
          </>
        )}
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={isMobile ? 2 : 3}
        >
          <FormTextInput
            label="Contato do responsavel"
            placeholder="Número de telefone"
            name="phoneNumber"
            control={control}
            required={true}
            defaultValue={useFormatter.formatPhoneNumber(user?.phone)}
            pattern={useRegex.phoneNumber}
            mask={useFormatter.formatPhoneNumber}
            maxLength={15}
            autoFocus
          />
          <FormSelectInput
            name="gender"
            control={control}
            label="Sexo de nascimento"
            selectOptions={typeOptionsGender}
            required
          />
        </Stack>
      </Stack>
      <FormButtonGroup
        onGoBackButtonClick={() => onBackButtonClick()}
        onNextButtonClick={handleSubmit(createPatientMPI)}
        loading={loading}
      />
    </Stack>
  );
}
