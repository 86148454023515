import { useRef, useState } from "react";
import { useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import { Close, Collections, Warning } from "@mui/icons-material";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import {
  FormButtonGroup,
  FormSelectInput,
  FormTextInput,
} from "../../../components/form";
import { ButtonAddTemplate } from "./ButtonAddTemplate";
import { ViewDocument } from "./ViewDocument";
import { auxiliarPdf } from "../../../services/fhir/AuxiliarPdf";

import { PostAdminQuestionnaireResponse } from "../../../services/fhir/questionnaireresponse/PostAdminQuestionnaireResponse";
import { usePractitionerRoleContext } from "../../../contexts/PractitionerRoleContext";
import { QRCodeCanvas } from "qrcode.react";
import { endpoints } from "../../../configs/Settings";

interface IProps {
  dataRegister: any;
  control: any;
  handleSubmit: any;
  fileBase64: any;
  setFileBase64: any;
  setOpenModal: any;
  reset: any;
  setRefetch: any;
}

export function ConfigureDocument({
  dataRegister,
  control,
  handleSubmit,
  fileBase64,
  setFileBase64,
  setOpenModal,
  reset,
  setRefetch,
}: IProps) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState(false);

  const { userPractitioner } = usePractitionerRoleContext();

  const { organization } = useOrganizationContext();

  const listRecommendations = [
    "O modelo deve estar na extensão JPG ou PNG.",
    "O cabeçalho deve ter no máximo 6cm e o rodapé também deve ter no máximo 6cm de uma folha a4 para não sobrescrever o conteúdo central.",
    <>
      Recomendamos o site{" "}
      <a
        href="https://www.canva.com/pt_br/modelos/?query=template-receitu%C3%A1rio-m%C3%A9dico&pricing=FREE"
        target="_blank"
        rel="noopener noreferrer"
      >
        Canva
      </a>{" "}
      para criação dos modelos personalizados.
    </>,
  ];

  const onSubmit = async (data: any) => {
    setLoading(true);
    const dataCompleted = {
      ...data,
      base64: fileBase64,
      practitioner: userPractitioner?.id,
    };

    const auxData = auxiliarPdf.generateBodyPdf(
      dataCompleted,
      organization?.id
    );

    try {
      await PostAdminQuestionnaireResponse(auxData);
    } catch (err) {
      console.log("err", err);
      return;
    } finally {
      setLoading(false);
    }

    setOpenModal(false);
    setRefetch(true);
    setFileBase64(null);
    reset();
    toast.success("Documento adicionado com sucesso");
  };

  const typeOptionsDocument: Array<any> = [
    { display: "Receita simples", value: "simple" },
    { display: "Receita especial", value: "special" },
    { display: "Procedimentos", value: "procedure" },
    { display: "Relatório médico", value: "certificate" },
    { display: "Solicitação de exame", value: "exam" },
  ].filter((auxType: any) => {
    const existingValues = dataRegister?.map(
      (auxDataReg: any) =>
        auxDataReg?.resource?.item?.[0]?.answer?.[0]?.valueString
    );

    return !existingValues?.some((value: any) => value === auxType.value);
  });

  const watchType = useWatch({ name: "documentType", control });
  const watchName = useWatch({ name: "documentName", control });

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const generateBase64QRCode = () => {
    if (canvasRef.current) {
      const base64 = canvasRef.current.toDataURL("image/png"); // ou 'image/jpeg' para JPEG
      return base64;
    }
  };
  return (
    <Stack width="100%" p="0px 30px 30px 30px">
      <IconButton
        onClick={() => {
          reset();
          setFileBase64(null);
          setOpenModal(false);
        }}
        sx={{ position: "absolute", right: 5, top: 5 }}
      >
        <Close />
      </IconButton>
      <Typography variant="body1" textAlign="center" pb={2}>
        Preencha os campos para adicionar um novo modelo personalizado
      </Typography>

      <div style={{ display: "none" }}>
        <QRCodeCanvas
          ref={canvasRef}
          value={`${endpoints.XDSB_domain}/portal`}
          size={256}
          bgColor="#ffffff"
          fgColor="#000000"
          level="L"
        />
      </div>

      <Stack direction="row" width="100%" spacing={3} mb={2}>
        <Stack width="40%">
          <FormSelectInput
            name="documentType"
            label="Tipo de documento"
            control={control}
            selectOptions={typeOptionsDocument}
            required
          />
        </Stack>
        <Stack width="40%">
          <FormTextInput
            control={control}
            label="Nome do modelo"
            name="documentName"
            maxLength={30}
            required
          />
        </Stack>

        <Stack width="30%">
          <ButtonAddTemplate
            fileInputRef={fileInputRef}
            setFileBase64={setFileBase64}
            auxiliarText="Adicionar modelo"
          />
        </Stack>
      </Stack>
      <Stack height="100%" mb={2}>
        {fileBase64 ? (
          <ViewDocument
            fileBase64={fileBase64}
            qrCodeBase64={generateBase64QRCode()}
          />
        ) : (
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            justifyContent="space-between"
            minHeight="280px"
          >
            <Box
              sx={{
                flex: 1,
                padding: 2,
              }}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                mt={2}
              >
                <Warning sx={{ color: "warning500.main" }} />
                <Typography
                  variant="body1"
                  fontWeight={600}
                  color="neutral700.main"
                  textAlign="center"
                >
                  Recomendações do modelo a inserir
                </Typography>
              </Stack>

              <List sx={{ listStyleType: "disc", paddingLeft: 2 }}>
                {listRecommendations.map((el: any, index: number) => (
                  <ListItem key={index} sx={{ display: "list-item" }}>
                    <ListItemText primary={el} />
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box
              sx={{
                flex: 1,
                padding: 2,
                alignSelf: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Collections sx={{ fontSize: "100px" }} />
              <Typography variant="body1" fontWeight={600}>
                Selecione um modelo timbrado para visualização
              </Typography>
            </Box>
          </Stack>
        )}
      </Stack>
      <FormButtonGroup
        onGoBackButtonClick={() => {
          setFileBase64(null);
          reset();
          setOpenModal(false);
        }}
        onNextButtonClick={handleSubmit(onSubmit)}
        goBackButtonText="Voltar"
        nextButtonText="Salvar"
        disabled={!watchType || !watchName || !fileBase64}
        loading={loading}
        maxWidth
      />
    </Stack>
  );
}
