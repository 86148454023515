import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

import { useSessionContext } from "../../../contexts/SessionContext";

import { toast } from "react-toastify";
import { useLocationContext } from "../../../contexts/LocationContext";
import { Location } from "../../schedule/components/Location";
import { PostLocation } from "../../../services/fhir";
import { Close } from "@mui/icons-material";

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export function CreateLocationDialog({ isOpen, setIsOpen }: IProps) {
  const { access } = useSessionContext();
  const { refetchLocation } = useLocationContext();

  function handleClose() {
    setIsOpen(false);
  }

  async function createLocation(data: any) {
    try {
      await PostLocation(data, access.organizationId);
      refetchLocation();
      toast.success("Localização criada com sucesso!");
    } catch {
      toast.warn(
        "Não foi possível finalizar a operação, por favor tente novamente!"
      );
    } finally {
      handleClose();
    }
  }

  return (
    <Dialog
      open={isOpen}
      // onClose={handleClose}
      PaperProps={{
        sx: { borderRadius: "10px", width: "1000px" },
      }}
      maxWidth="lg"
    >
      <IconButton
        onClick={handleClose}
        sx={{ position: "absolute", right: 5, top: 5 }}
      >
        <Close />
      </IconButton>
      <DialogTitle textAlign="center">Nova localização</DialogTitle>

      <DialogContent>
        <Location
          handleClose={handleClose}
          create={createLocation}
          defaultValues={{
            cep: "",
            serviceOnline: false,
            name: "",
            city: "",
            state: "",
            zone: "",
            street: "",
            number: "",
            complement: "",
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
