import React from "react";
import { Typography } from "@mui/material";

export function ContentBoxTitle({ children }: { children: any }) {
  return (
    <Typography variant="h4" fontWeight={600} textAlign="center">
      {children}
    </Typography>
  );
}
