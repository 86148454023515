import { DialogTitle, Stack } from "@mui/material";
import { CommonDialog } from "../../../components/common/CommonDialog";
import ControlMedication from "../../../components/common/ControlMedication";

interface IProps {
  isOpen: boolean;
  handleClose: VoidFunction;
  data: any;
}

export function StopMedicationDialog({ isOpen, handleClose, data }: IProps) {
  return (
    <CommonDialog isOpen={isOpen} handleClose={handleClose}>
      <DialogTitle>{data?.medication?.concept?.text || ""}</DialogTitle>
      <Stack paddingX={1}>
        <ControlMedication
          stop
          handleClose={handleClose}
          data={{ resource: data }}
          index={0}
        />
      </Stack>
    </CommonDialog>
  );
}
