import { useState } from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { isMobile, isMobileSafari } from "react-device-detect";
import dayjs from "dayjs";

import { ScreenMobile } from "../../components/common/ScreenMobile";
import { PrivateMainLayout } from "../../components/layout";
import { LargeContentBox } from "../../components/common";
import { DataGridPresentation } from "../../components/common/DataGridPresentation";
import { HeaderContentBox } from "../../components/common/HeaderContentBox";
import { DocumentReference } from "fhir/r5";
import { ViewPrescription } from "./components/ViewPrescription";
import { usePatientHealthCareInformationContext } from "../../contexts/PatientHealthCareInformationContext";
import { ExamSolicitationActions } from "./components/ExamSolicitationActions";
import { GetBinary } from "../../services/fhir/patientsummary/Binary/GetBinary";

interface IProps {
  open?: any;
  onClose?: VoidFunction;
}

export default function ExamSolicitation({ open, onClose }: IProps) {
  const {
    documentReferenceExam,
    serviceRequest,
    results,
    setRefetchServiceRequest,
  } = usePatientHealthCareInformationContext();

  const [openInformation, setOpenInformation] = useState<boolean>(false);
  const [dataRow, setDataRow] = useState<any>([]);

  const handleClose = () => {
    setOpenInformation(false);
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "Id", width: 90 },
    { field: "description", headerName: "Exame", minWidth: 300, flex: 1 },
    {
      field: "date",
      headerName: "Data de solicitação",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "specialty",
      headerName: "Especialidade",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "practitioner",
      headerName: "Profissional",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "sign",
      headerName: "Assinado digitalmente",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "document",
      headerName: "Ações",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      renderCell: (e) => <ExamSolicitationActions row={e?.row} />,
    },
  ];

  const rows =
    documentReferenceExam
      ?.filter(
        (filterEl: any) =>
          filterEl?.resource?.category?.[0]?.coding?.[0]?.display ===
          "Solicitação"
      )
      ?.map((el: fhir5.BundleEntry<DocumentReference | any>, index: number) => {
        const auxPractitioner = el?.resource?.contained.find(
          (contained: any) => contained?.resourceType === "Practitioner"
        );
        const auxSpecialty =
          el?.resource?.practiceSetting?.coding?.[0]?.display;

        const auxDescription = serviceRequest?.filter((request: any) => {
          const findRef = el?.resource?.basedOn?.find((basedOn: any) =>
            basedOn?.reference.includes(request.resource.id)
          );
          return findRef;
        });

        const auxResults = results?.find((result: any) => {
          const findRef = auxDescription?.find(
            (service: any) =>
              service?.resource?.id ===
              result?.resource?.basedOn
                ?.find((bas: any) => bas.reference.includes("ServiceRequest"))
                ?.reference?.split("/")?.[1]
          );
          return findRef;
        });

        const auxType = el?.resource?.type?.coding?.[0]?.display || "-";

        const sign =
          el?.resource?.securityLabel?.[0]?.coding?.[0]?.code ===
          "ProofOfOrigin"
            ? "Sim"
            : "Não";

        return {
          id: index,
          type: auxType,
          sign,
          description:
            auxDescription
              ?.map(
                (serviceRequest: any) =>
                  serviceRequest?.resource?.code?.concept?.coding?.[0]?.display
              )
              ?.join(" , ") || "",
          date: dayjs(el?.resource?.date).format("DD/MM/YYYY") || "-",
          specialty: auxSpecialty || "-",

          practitioner:
            auxPractitioner?.name?.[0]?.given?.[0] ||
            auxPractitioner?.identifier?.[0]?.value,
          binaryUrl: el?.resource?.content?.[0]?.attachment?.url || "",
          auxResults,
          fullResource: el?.resource,
        };
      }) || [];

  const base64ToBlob = (base64: any, contentType = "") => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  return isMobile ? (
    <>
      <ScreenMobile
        title="Solicitações de exames"
        open={open}
        onClose={onClose}
      >
        <Stack
          width={{ xs: "92vw", sm: "100%" }}
          overflow="auto"
          minHeight="20vh"
          height="auto"
          maxHeight="70vh"
        >
          <DataGridPresentation
            rows={rows}
            columns={columns}
            rowClick={async (e: any) => {
              try {
                const response = await GetBinary({
                  urlBinary: e?.row?.binaryUrl,
                });
                const blob = base64ToBlob(
                  response?.data,
                  response?.contentType
                );
                const fileUrl = URL.createObjectURL(blob);
                const auxFile = {
                  fileUrl,
                  fileName: "Solicitacoes_de_exames.pdf",
                };

                if (isMobileSafari) {
                  if (auxFile) {
                    const link = document.createElement("a"); // Cria um elemento <a>
                    link.href = auxFile?.fileUrl; // Define o URL do Blob
                    link.download = auxFile?.fileName; // Define o nome do arquivo
                    // document.body.appendChild(link); // Adiciona o link ao documento
                    link.click(); // Simula o clique no link
                    // document.body.removeChild(link); // Remove o link do documento
                    // URL.revokeObjectURL(auxFile?.fileUrl); // Libera a memória do URL Blob
                  } else {
                    alert(
                      "O arquivo ainda está sendo preparado, por favor tente novamente."
                    );
                  }
                } else {
                  window.open(auxFile.fileUrl, "_blank");
                  // setDataRow(e.row);
                  // setOpenInformation(true);
                }
              } catch (err) {
                console.log("err", err);
              }
            }}
          />
        </Stack>
      </ScreenMobile>
      <ViewPrescription
        setRefetch={setRefetchServiceRequest}
        openInformation={openInformation}
        handleClose={handleClose}
        data={dataRow}
      />
    </>
  ) : (
    <PrivateMainLayout>
      <LargeContentBox>
        <Stack width="100%" height="100%" overflow="auto">
          <HeaderContentBox
            choiseSelected={openInformation}
            handleClose={handleClose}
          >
            Solicitações de exames
          </HeaderContentBox>
          <DataGridPresentation
            rows={rows}
            columns={columns}
            rowClick={() => {}}
          />
        </Stack>
      </LargeContentBox>
    </PrivateMainLayout>
  );
}
