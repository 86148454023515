import { useState } from "react";
import { useLocation } from "react-router-dom";

import { PrescriptionButton } from "./PrescriptionButton";
import { PrescriptionDialog } from "./PrescriptionDialog";

import { useFhirData } from "../../utils/useFhirData";
import { EHRRecordsDialog } from "./components/EHRRecordsDialog";
import { PrivateMainLayout } from "../../components/layout";
import { PatientData } from "../ehrrecords/components/PatientData";
import { LargeContentBox } from "../../components/common";
import { Grid } from "@mui/material";
import { QuestionnaireViewer } from "./components/QuestionnaireViewer/QuestionnaireViewer";
import { usePrescriptionAttendenceContext } from "../../contexts/PrescriptionAttendenceContext";

export function ClinicalRegister() {
  const { state } = useLocation();

  const { isOpenPrescription, setIsOpenPrescription } =
    usePrescriptionAttendenceContext();

  const [openPrescription, setOpenPrescription] = useState<boolean>(false);
  const [openSummary, setOpenSummary] = useState<boolean>(false);

  const patientId = useFhirData
    .findParticipant(state?.encounterReference?.participant, "Patient")
    ?.reference?.split("-")?.[1];

  const handleOpenPrescription = () => {
    setOpenSummary(false);
    setOpenPrescription(true);
  };
  const handleClosePrescription = () => {
    setOpenPrescription(false);
    setIsOpenPrescription(false);
  };

  const handleOpenSummary = () => {
    setOpenPrescription(false);
    setOpenSummary(true);
  };

  const handleCloseSummary = () => {
    setOpenSummary(false);
  };

  return (
    <PrivateMainLayout>
      <LargeContentBox>
        <Grid mb={5} container direction="row" rowSpacing={3} columnSpacing={3}>
          <PatientData patientId={patientId} />
        </Grid>
        <QuestionnaireViewer />
        {state?.encounterReference && (
          <>
            <PrescriptionButton
              handleClickPrescription={handleOpenPrescription}
              handleClickSummary={handleOpenSummary}
            />
            <EHRRecordsDialog
              open={openSummary}
              handleClose={handleCloseSummary}
              references={state?.encounterReference}
            />

            <PrescriptionDialog
              references={state?.encounterReference}
              open={isOpenPrescription || openPrescription}
              handleClose={handleClosePrescription}
            />
          </>
        )}
      </LargeContentBox>

      {/* {loading && (
        <Stack
          spacing={2}
          alignItems="center"
          display="flex"
          direction="row"
          justifyContent="center"
        >
          <CircularProgress size={80} />
        </Stack>
      )} */}
    </PrivateMainLayout>
  );
}
