import { Info } from "@mui/icons-material";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import TermText from "./TermText";

export default function TermOfOwner({
  control,
  isAssigned,
}: {
  control?: any;
  isAssigned?: boolean;
}) {
  return (
    <main>
      <FormGroup
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Controller
          name="termOfOwner"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox {...field} checked={field.value} color="primary" />
              }
              label={
                <Typography variant="body1" display="flex" flexDirection="row">
                  Eu li e estou de acordo com os &nbsp;
                  <Typography variant="body1" color="primary700.main">
                    Termos de Uso &nbsp;
                  </Typography>
                  e &nbsp;
                  <Typography variant="body1" color="primary700.main">
                    Política de Privacidade. &nbsp;
                  </Typography>
                </Typography>
              }
            />
          )}
        />
      </FormGroup>
      {isAssigned && (
        <Stack
          direction="row"
          justifyContent="left"
          alignItems="center"
          spacing={2}
          width="100%"
          p={2}
        >
          <Info sx={{ color: "neutral300.main" }} />
          <Typography variant="body2" fontWeight={400} color="neutral600.main">
            Aceite os Termos de Uso e Política de Privacidade para continuar o
            acesso a plataforma e seus serviços, ao recusar os Termos de Uso e
            Política de Privacidade,pode levar a perda de acesso a plataforma e
            seus serviços.
          </Typography>
        </Stack>
      )}
    </main>
  );
}
