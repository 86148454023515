import dayjs, { Dayjs } from "dayjs";
import { DateFilterType } from "./interfaces";

function formatDateToISO(date: Dayjs) {
  return date.format("YYYY-MM-DD");
}

function isDateFilterTypeDay(dateFilterType: DateFilterType) {
  return dateFilterType === "day" ? true : false;
}

function isSameYearAndMonthAsToday(date: Dayjs) {
  return dayjs().isSame(date, "year") && dayjs().isSame(date, "month");
}

function formatToDDMM(date: Dayjs) {
  return date.format("DD/MM");
}

function formatToDDMMYYYY(date: Dayjs) {
  return date.format("DD/MM/YYYY");
}

function formatToHHmm(time: Dayjs) {
  return time.format("HH:mm");
}

const weekDayTranslation: { [key: number]: string } = {
  0: "Domingo",
  1: "Segunda",
  2: "Terça",
  3: "Quarta",
  4: "Quinta",
  5: "Sexta",
  6: "Sábado",
};

function getWeekDayTranslation(date: Dayjs): string {
  return weekDayTranslation[date.day()];
}

function formatDate(value: string) {
  if (value === undefined) return "";

  const formatDate = value.split("-");
  const day = formatDate[2];
  const month = formatDate[1];
  const year = formatDate[0];

  return `${day}/${month}/${year}`;
}

export const useDatetime = {
  formatDateToISO,
  isDateFilterTypeDay,
  isSameYearAndMonthAsToday,
  getWeekDayTranslation,
  formatToDDMM,
  formatToDDMMYYYY,
  formatToHHmm,
  formatDate,
};
