import { Grid, Stack, Typography } from "@mui/material";
import {
  FormAutoComplete,
  FormTextInput,
} from "../../../../../../../components/form";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../../../../components/common";
import { toast } from "react-toastify";
import { PostAllergyIntolerance } from "../../../../../../../services/fhir/patientsummary/allergyintolerance/PostAllergyIntolerance";
import { useSessionContext } from "../../../../../../../contexts/SessionContext";

interface IProps {
  methods: any;
  references: any;
  handleClose: any;
  setRefetch: any;
}
export function CreateAllergiesForm({
  methods,
  references,
  handleClose,
  setRefetch,
}: IProps) {
  const { user } = useSessionContext();
  const { control, handleSubmit } = methods;

  const onSubmit = async (data: any) => {
    if (data && references) {
      try {
        await PostAllergyIntolerance(
          {
            ...data,
            practitionerId: user?.username,
            practitionerName: user?.name,
          },
          references
        );
        setRefetch((prev: boolean) => !prev);
      } catch (err) {
        console.log("PostAllergy", err);
        toast.error("Não foi possível registrar a alergia, tente novamente");
      } finally {
        handleClose();
      }
    }
  };
  const clinicalStatusOptions = [
    {
      value: "active",
      label: "Ativo",
    },
    {
      value: "inactive",
      label: "Inativo",
    },
    {
      value: "resolved",
      label: "Resolvido",
    },
  ];

  const categoryOptions = [
    {
      value: "food",
      label: "Alimento",
    },
    {
      value: "medication",
      label: "Medicamento",
    },
    {
      value: "environment",
      label: "Ambiente",
    },
    {
      value: "biologic",
      label: "Biológico",
    },
  ];

  const criticalityOptions = [
    {
      value: "low",
      label: "Baixo",
    },
    {
      value: "high",
      label: "Alto",
    },
    {
      value: "unable-to-assess",
      label: "Incapaz de avaliar",
    },
  ];

  const verificationStatusOptions = [
    {
      value: "unconfirmed",
      label: "Não confirmado",
    },
    {
      value: "confirmed",
      label: "Confirmado",
    },
    {
      value: "presumed",
      label: "Presumido",
    },
    {
      value: "refuted",
      label: "Refutado",
    },
  ];

  return (
    <Stack width="750px" rowGap={4}>
      <Typography variant="h5" fontWeight={600}>
        Adicionar Alergia
      </Typography>
      <Typography> Inclua e salve os dados da alergia </Typography>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <FormTextInput
            name="substance"
            label="Substancia"
            required
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <FormAutoComplete
            loading={false}
            name="category"
            label="Categoria"
            required
            control={control}
            selectOptions={categoryOptions}
          />
        </Grid>

        <Grid item xs={6}>
          <FormAutoComplete
            loading={false}
            name="criticality"
            label="Criticidade"
            required
            control={control}
            selectOptions={criticalityOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <FormAutoComplete
            loading={false}
            name="clinicalStatus"
            label="Status clínico"
            required
            control={control}
            selectOptions={clinicalStatusOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <FormAutoComplete
            loading={false}
            name="verificationStatus"
            label="Status de verificação"
            required
            control={control}
            selectOptions={verificationStatusOptions}
          />
        </Grid>
      </Grid>

      <Stack
        width="100%"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <SecondaryButton
          onClick={handleClose}
          width="fit-content"
          height="40px"
        >
          Cancelar
        </SecondaryButton>

        <PrimaryButton
          onClick={handleSubmit(onSubmit)}
          width="fit-content"
          height="40px"
        >
          Salvar
        </PrimaryButton>
      </Stack>
    </Stack>
  );
}
