import { Business } from "@mui/icons-material";
import { useSessionContext } from "../../contexts/SessionContext";
import { IFeatures } from "../../utils/interfaces";
import { ProfileClinic } from "./ProfileClinic";
import { currentFeatures } from "../../configs/Settings";
import { useViewContext } from "../../contexts/ViewContext";

export function useProfileClinic() {
  const { access } = useSessionContext();
  const { availableRoles } = useViewContext();

  const configs: IFeatures = {
    active:
      access.type === "professional" &&
      currentFeatures.profileClinic &&
      (availableRoles?.includes("manager") ||
        availableRoles?.includes("owner")),
    path: "/clinic",
    icon: Business,
    text: "Clínica",

    parent: "Configurações",
    subitem: true,

    sideMenu: true,
    sideMenuIndex: 1,

    avatarMenu: true,
    avatarIndex: 1,
    component: () => <ProfileClinic />,
  };

  return configs;
}
