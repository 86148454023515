import { useState } from "react";
import { IFormInput, Steps } from "../../types";
import { FormProvider, useForm } from "react-hook-form";
import { AddDependent } from "../AddDependent";
import { DependentRegister } from "../DependentRegister";
import { SummaryDependent } from "../SummaryDependent";
import { DependentList } from "../DependentList";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { LegalGuardianList } from "../LegalGuardianList";

const defaultValues = {
  name: "",
  identifier: "",
  birthdate: undefined,
  gender: "",
};

export function Form() {
  const { userPatient, guardianRelated } = useSessionContext();
  const [page, setPage] = useState(Steps.S1AddDependent);
  const [minorPatient, setMinorPatient] = useState<boolean>(false);

  const methods = useForm<IFormInput>({ defaultValues });
  const { handleSubmit, control, setValue, reset } = methods;

  const handleNext = () => {
    setPage(Steps.S2DependentRegister);
  };

  return (
    <FormProvider {...methods}>
      {page === Steps.S1AddDependent && (
        <>
          <AddDependent onClick={handleSubmit(handleNext)} />
          {userPatient?.hasOwnProperty("link") && <DependentList />}
          {guardianRelated ? (
            <>
              <LegalGuardianList />
            </>
          ) : (
            <></>
          )}
        </>
      )}
      {page === Steps.S2DependentRegister && (
        <DependentRegister
          control={control}
          handleSubmit={handleSubmit}
          onBackButtonClick={() => setPage(Steps.S1AddDependent)}
          navigateToNextPage={() => setPage(Steps.S3SummaryDependent)}
          setValue={setValue}
          setMinorPatient={setMinorPatient}
        />
      )}
      {page === Steps.S3SummaryDependent && (
        <SummaryDependent
          reset={reset}
          control={control}
          handleSubmit={handleSubmit}
          onBackButtonClick={() => setPage(Steps.S2DependentRegister)}
          navigateToNextPage={() => setPage(Steps.S1AddDependent)}
          setValue={setValue}
          minorPatient={minorPatient}
        />
      )}
    </FormProvider>
  );
}
