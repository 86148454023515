import React, { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Stack, Tab, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { DocumentAccordion } from "../DocumentAccordion";
import { MedicationTab } from "./MedicationTab";
import { PrescriptionItem } from "./PrescriptionItem";
import { useForm } from "react-hook-form";
import { SupplementTab } from "./SupplementTab";
import { SelectProtocolDialog } from "./SelectProtocolDialog";
import { CreateProtocolDialog } from "./CreateProtocolDialog";
import { HerbalMedicineTab } from "./HerbalMedicineTab";

type SelectedTabType = "1" | "2" | "3" | "4";

export function TreatmentAccordion({
  prescriptionItens,
  setPrescriptionItens,
  addItem,
  summary,
}: any) {
  const [selectedTab, setSelectedTab] = useState<SelectedTabType>("1");
  const [selectedItem, setSelectedItem] = useState<number>();
  const [openSelectProtocol, setOpenSelectProtocol] = useState<boolean>(false);
  const [openCreateProtocol, setOpenCreateProtocol] = useState<boolean>(false);

  const methods = useForm();
  const { reset } = methods;

  const handleChangeTab = (e: React.SyntheticEvent, value: SelectedTabType) => {
    reset();
    setSelectedTab(value);
  };

  const editItem = (item: any) => {
    setPrescriptionItens((prevState: any) => {
      const updatedItems = [...prevState];
      const index = updatedItems.findIndex((el, i) => i === selectedItem);
      if (index !== -1) {
        updatedItems[index] = item;
      }

      return updatedItems;
    });
    setSelectedItem(undefined);
  };

  const action = selectedItem === undefined ? addItem : editItem;
  const isEdit = selectedItem !== undefined;

  const medicationArray = prescriptionItens.filter(
    (el: any) => el.type === "medication"
  );
  const supplementArray = prescriptionItens.filter(
    (el: any) => el.type === "supplement"
  );
  const herbalMedicineArray = prescriptionItens.filter(
    (el: any) => el.type === "herbalMedicine"
  );

  return (
    <>
      <DocumentAccordion
        defaultExpaded
        subtitle="Ações e informações de tratamento"
        title="Tratamento"
      >
        {
          <>
            {medicationArray.length === 0 ? (
              <></>
            ) : (
              <>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={1}
                >
                  <Typography
                    variant="body1"
                    color="neutral1000.main"
                    fontWeight={500}
                  >
                    Medicamentos:
                  </Typography>
                </Stack>
                {medicationArray?.map((el: any, index: number) => {
                  const auxIndex = prescriptionItens.indexOf(el);

                  return (
                    <PrescriptionItem
                      key={`medicamento-${index}`}
                      setSelectedTab={setSelectedTab}
                      methods={methods}
                      setPrescriptionItens={setPrescriptionItens}
                      index={index}
                      prescriptionIndex={auxIndex}
                      setSelectetItem={setSelectedItem}
                      selectedItem={selectedItem}
                      item={el}
                    />
                  );
                })}
              </>
            )}
          </>
        }
        {
          <>
            {supplementArray.length === 0 ? (
              <></>
            ) : (
              <>
                {" "}
                <Typography
                  variant="body1"
                  color="neutral1000.main"
                  fontWeight={500}
                  mb={1}
                >
                  Suplementos:{" "}
                </Typography>
                {supplementArray?.map((el: any, index: number) => {
                  const auxIndex = prescriptionItens.indexOf(el);
                  return (
                    <PrescriptionItem
                      key={`suplemento-${index}`}
                      setSelectedTab={setSelectedTab}
                      methods={methods}
                      setPrescriptionItens={setPrescriptionItens}
                      index={index}
                      prescriptionIndex={auxIndex}
                      setSelectetItem={setSelectedItem}
                      selectedItem={selectedItem}
                      item={el}
                    />
                  );
                })}
              </>
            )}
          </>
        }
        {
          <>
            {herbalMedicineArray.length === 0 ? (
              <></>
            ) : (
              <>
                {" "}
                <Typography
                  variant="body1"
                  color="neutral1000.main"
                  fontWeight={500}
                  mb={1}
                >
                  Fitoterápicos:{" "}
                </Typography>
                {herbalMedicineArray?.map((el: any, index: number) => {
                  const auxIndex = prescriptionItens.indexOf(el);
                  return (
                    <PrescriptionItem
                      key={`fitoterapico-${index}`}
                      setSelectedTab={setSelectedTab}
                      methods={methods}
                      setPrescriptionItens={setPrescriptionItens}
                      index={index}
                      prescriptionIndex={auxIndex}
                      setSelectetItem={setSelectedItem}
                      selectedItem={selectedItem}
                      item={el}
                    />
                  );
                })}
              </>
            )}
          </>
        }
        {<></>}
        <Stack>
          <TabContext value={selectedTab}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <TabList onChange={handleChangeTab}>
                <Tab
                  value="1"
                  label="Medicamento"
                  sx={{ textTransform: "none" }}
                />
                <Tab
                  value="2"
                  label="Suplemento"
                  sx={{ textTransform: "none" }}
                />
                <Tab
                  value="3"
                  label="Fitoterápicos"
                  sx={{ textTransform: "none" }}
                />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: 0 }}>
              <MedicationTab
                methods={methods}
                action={action}
                isEdit={isEdit}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ padding: 0 }}>
              <SupplementTab
                methods={methods}
                action={action}
                isEdit={isEdit}
              />
            </TabPanel>
            <TabPanel value="3" sx={{ padding: 0 }}>
              <HerbalMedicineTab
                methods={methods}
                action={action}
                isEdit={isEdit}
              />
            </TabPanel>
          </TabContext>
        </Stack>
      </DocumentAccordion>
      {openSelectProtocol && (
        <SelectProtocolDialog
          isOpen={openSelectProtocol}
          handleClose={() => setOpenSelectProtocol(false)}
          addItem={addItem}
        />
      )}
      {openCreateProtocol && (
        <CreateProtocolDialog
          itens={prescriptionItens}
          isOpen={openCreateProtocol}
          handleClose={() => setOpenCreateProtocol(false)}
        />
      )}
    </>
  );
}
