import { endpoints } from "../../configs/Settings";
import { HTTPResponseStatus } from "../../utils/enum";
import { useAuth } from "../../utils/useAuth";
import { useAxios } from "../../utils/useAxios";

export async function RefreshToken() {
  const url = `${endpoints.token_api}`;
  const data = `grant_type=refresh_token&refresh_token=${localStorage.getItem(
    "refresh_token"
  )}`;
  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getBasicToken(),
    "Content-Type": "application/x-www-form-urlencoded",
  };
  const response = await useAxios
    .post(url, data, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`RefreshToken error: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
  if (response) return response;
}
