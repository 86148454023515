import {
  DialogContent,
  DialogTitle,
  FormControl,
  Stack,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Dialog,
  IconButton,
} from "@mui/material";
import {
  FormAutoComplete,
  FormButtonGroup,
  FormPasswordInput,
} from "../../../../components/form";
import { Controller, useWatch } from "react-hook-form";
import { useState } from "react";
import { useSignatureContext } from "../../../../contexts/SignatureContext";
import { useSignatureControlContext } from "../../../../contexts/SignatureControlContext";
import { Close } from "@mui/icons-material";

interface IProps {
  visualize?: any;
  handleClose: Function;
  isOpen: boolean;
  handleSignature: Function;
  row?: any;
  loading: boolean;
}

const ProviderOptions: Array<any> = [
  { label: "VidaaS", value: "vidaas" },
  { label: "SafeID", value: "safeid" },
];

const LifeTimeOptions: Array<{ label: string; value: number }> = [
  { label: "Assinatura única", value: 0 },
  { label: "1 hora", value: 3600 },
  { label: "4 horas", value: 14400 },
  { label: "8 horas", value: 28800 },
  { label: "12 horas", value: 43200 },
];

export function SignatureDialog({
  visualize,
  handleClose,
  isOpen,
  handleSignature,
  row,
  loading,
}: IProps) {
  const {
    control,
    handleSubmit,
    setSelectedDuration,
    setProvider,
    setProviderPassword,
    provider,
    providerPassword,
    selectedDuration,
  } = useSignatureContext();

  const { setRefetch, sessionInfo } = useSignatureControlContext();
  const [selectedPassProvider, setSelectedPassProvider] = useState(false);

  const providerValue = useWatch({
    name: "provider",
    control,
  });

  const [alterar, setAlterar] = useState<boolean>(false);

  const handleProviderChange = (selectedOption: any) => {
    setSelectedPassProvider(selectedOption?.value === "safeid");
  };

  const onSubmit = async (data: any) => {
    if (row?.sign === "Pendente") {
      if (data?.provider?.value !== provider) {
        localStorage.setItem("selectProvider", data?.provider?.value);
        setProvider(data?.provider?.value);
      }

      if (data?.password && data?.password !== providerPassword) {
        localStorage.setItem("providerPassword", data?.password);
        setProviderPassword(data?.password);
      }

      if (Number(data?.lifeTime) !== selectedDuration) {
        setSelectedDuration(Number(data?.lifeTime));
      }

      await handleSignature(
        data?.provider?.value || provider,
        data?.password || providerPassword,
        data?.lifeTime || selectedDuration
      );
      setRefetch((prev: any) => !prev);
      handleClose();
    }
  };

  function verifyDuration(expiresAt: any) {
    if (!expiresAt?.length) return false;
    const expirationDate = new Date(expiresAt);
    const now = new Date();
    const timeDiff = expirationDate.getTime() - now.getTime();

    if (timeDiff <= 0) return false;
    else return true;
  }

  function verification() {
    if (!providerValue?.value && provider) {
      return !verifyDuration(
        provider === "vidaas"
          ? sessionInfo?.vidaasExpiresAt
          : sessionInfo?.safeidExpiresAt
      );
    } else {
      return !verifyDuration(
        providerValue?.value === "vidaas"
          ? sessionInfo?.vidaasExpiresAt
          : sessionInfo?.safeidExpiresAt
      );
    }
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "10px",
          padding: 2,
          maxHeight: "900px",
        },
      }}
      maxWidth="sm"
      fullWidth
      onClose={() => handleClose()}
      open={isOpen}
    >
      <IconButton
        onClick={() => handleClose(null, "iconButtonClick")}
        sx={{ position: "absolute", right: 5, top: 5 }}
      >
        <Close />
      </IconButton>
      <DialogTitle textAlign="center">
        <Typography variant="h5" fontWeight={600}>
          Assinatura eletrônica
        </Typography>
      </DialogTitle>
      <DialogContent>
        {visualize && (
          <iframe
            title="PDF Viewer"
            src={`data:application/pdf;base64,${visualize}`}
            width="100%"
            height="400px"
          />
        )}

        <Stack
          m={1}
          mb={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {provider &&
          verifyDuration(
            provider === "vidaas"
              ? sessionInfo?.vidaasExpiresAt
              : sessionInfo?.safeidExpiresAt
          ) ? (
            <>
              <Typography
                variant="body1"
                color="neutral700.main"
                textAlign="center"
              >
                Provedor selecionado: {provider}
              </Typography>
              <Stack justifyContent="center">
                <Button onClick={() => setAlterar(true)}>
                  alterar provedor de assinatura
                </Button>
              </Stack>
            </>
          ) : (
            <Stack width="100%" direction="row" justifyContent="center">
              <Typography
                variant="body1"
                color="neutral700.main"
                textAlign="center"
              >
                Selecione o provedor para realizar a assinatura eletrônica.
              </Typography>
            </Stack>
          )}
        </Stack>

        {!alterar &&
        provider &&
        verifyDuration(
          provider === "vidaas"
            ? sessionInfo?.vidaasExpiresAt
            : sessionInfo?.safeidExpiresAt
        ) ? (
          <></>
        ) : (
          <Stack margin="16px 0 32px">
            <FormAutoComplete
              label="Provedores disponíveis"
              name="provider"
              control={control}
              selectOptions={ProviderOptions}
              auxOnChange={handleProviderChange}
              defaultValue={ProviderOptions[0]}
            />

            {!sessionInfo?.safeidExpiresAt?.length &&
              providerValue?.value === "safeid" && (
                <Stack mt={2}>
                  <FormPasswordInput
                    name="password"
                    control={control}
                    label="Senha"
                    required
                  />
                </Stack>
              )}

            {verification() && (
              <Controller
                name="lifeTime"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="lifetime-label">Tempo de sessão</InputLabel>
                    <Select
                      {...field}
                      labelId="lifetime-label"
                      label="Tempo de sessão"
                    >
                      {LifeTimeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            )}
          </Stack>
        )}

        <FormButtonGroup
          disabled={!visualize}
          nextButtonText={"Assinar documento"}
          onGoBackButtonClick={handleClose}
          onNextButtonClick={handleSubmit(onSubmit)}
          loading={loading}
        />
      </DialogContent>
    </Dialog>
  );
}
