import { useEffect, useState } from "react";
import { GetBinary } from "../../../../../services/fhir/patientsummary/Binary/GetBinary";
import { CircularLoading } from "../../../../../components/common";
import { Stack } from "@mui/material";

export function VisualizationDocument({ data }: any) {
  const [base64, setBase64] = useState<any>();

  useEffect(() => {
    async function getDocumentPdf() {
      const response = await GetBinary({ urlBinary: data });
      setBase64(response);
    }
    if (data) {
      getDocumentPdf();
    }
  }, [data]);

  return base64 ? (
    <iframe
      title="PDF Viewer"
      src={`data:${base64?.contentType};base64,${base64?.data}`}
      width="100%"
      height="600px"
    />
  ) : (
    <Stack
      width="100%"
      height="500px"
      justifyContent="center"
      alignItems="center"
    >
      <CircularLoading />
    </Stack>
  );
}
