import { Settings } from "@mui/icons-material";
import { Divider, Stack, Typography } from "@mui/material";
import { PrimaryLink } from "../../components/common";
import { Link } from "react-router-dom";

import logo from "../../assets/fluxmed-logo-horizontal.png";

export function Error() {
  return (
    <Stack minHeight="100vh" justifyContent="center">
      <Stack
        width="100%"
        height="100%"
        alignItems="center"
        px={{ xs: 3, sm: "10%", md: "20%" }}
      >
        <Typography
          fontSize={82}
          textAlign="center"
          sx={{ display: "flex", alignItems: "center" }}
          marginBottom={5}
        >
          4
          <Settings sx={{ fontSize: 82 }} />4
        </Typography>
        <Stack width="100%" paddingBottom={3}>
          <Typography
            fontWeight={700}
            variant="h5"
            marginBottom={{ xs: 1, md: 2 }}
          >
            Ops, você tentou acessar um recurso indisponível
          </Typography>
          <Stack direction="row">
            <Typography variant="subtitle1">• Volte para a</Typography>&ensp;
            <PrimaryLink
              variant="subtitle1"
              link=""
              text="página anterior"
              goBack
            />
          </Stack>
          <Stack direction="row">
            <Typography variant="subtitle1">• Ir para a</Typography>&ensp;
            <PrimaryLink variant="subtitle1" link="/" text="tela de início" />
          </Stack>
        </Stack>
        <Divider flexItem />
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="center"
          spacing={{ xs: 1, md: 2 }}
          alignItems="center"
          paddingTop={3}
          paddingBottom={7}
        >
          <Typography fontSize={16} color="neutral700.main">
            Software Fluxmed v1.0
          </Typography>
          <Typography sx={{ display: { xs: "none", md: "block" } }}>
            •
          </Typography>
          <Link to="#" style={{ textDecoration: "none" }}>
            <Typography fontSize={16} color="neutral700.main">
              Sobre o Fluxmed
            </Typography>
          </Link>
          <Typography sx={{ display: { xs: "none", md: "block" } }}>
            •
          </Typography>
          <Link to="#" style={{ textDecoration: "none" }}>
            <Typography fontSize={16} color="neutral700.main">
              Reportar problema
            </Typography>
          </Link>
        </Stack>
        <img src={logo} width="200px" alt="Logo fluxmed" />
      </Stack>
    </Stack>
  );
}
