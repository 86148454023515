import { Stack } from "@mui/material";
import { SelectFilter } from "../../../../../../schedulemanager/components/SelectFilter";
import { usePractitionerRoleContext } from "../../../../../../../contexts/PractitionerRoleContext";
import { useLocationContext } from "../../../../../../../contexts/LocationContext";
import { useHealthcareServiceContext } from "../../../../../../../contexts/HealthcareServiceContext";
import { useManagerContext } from "../../../../../../../contexts/ManagerContext";

function cleanRepeatedList(list: any) {
  let cleanedList = new Set(list);
  return Array.from(cleanedList);
}

export function FilterOptions() {
  const {
    schedule,
    practitionerFilter,
    setPractitionerFilter,
    locationFilter,
    setLocationFilter,
    healthcareFilter,
    setHealthcareFilter,
    scheduleFilter,
    setScheduleFilter,
  } = useManagerContext();
  const { practitionerRole } = usePractitionerRoleContext();
  const { location } = useLocationContext();
  const { healthcareService } = useHealthcareServiceContext();

  const practitionerOptions = practitionerRole?.map((el: any) => {
    const practitioner = el.resource?.practitioner;
    return { display: practitioner?.display, value: practitioner?.reference };
  });

  const scheduleOptions = schedule?.entry
    ?.filter((e: any) => e?.resource?.active)
    ?.map((el: any, index: number) => ({
      display: el?.resource?.name,
      value: el?.resource.id,
    }));

  const locationOptions = location
    ?.filter((loc: any) => loc?.resource?.status === "active")
    ?.map((el: any, index: number) => ({
      display: el?.resource?.name,
      value: el?.resource.id,
    }));

  const healthcareNames = cleanRepeatedList(
    healthcareService?.map((el: any) => el?.resource.name)
  );
  const healthcareOptions = healthcareNames?.map((el: any) => ({
    display: el,
    value: el,
  }));
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <SelectFilter
        name="Médicos"
        options={practitionerOptions}
        filter={practitionerFilter}
        setFilter={setPractitionerFilter}
      />
      <SelectFilter
        name="Especialidades"
        options={healthcareOptions}
        filter={healthcareFilter}
        setFilter={setHealthcareFilter}
      />

      <SelectFilter
        name="Agendas"
        options={scheduleOptions}
        filter={scheduleFilter}
        setFilter={setScheduleFilter}
      />
      <SelectFilter
        name="Localizações"
        options={locationOptions}
        filter={locationFilter}
        setFilter={setLocationFilter}
      />
    </Stack>
  );
}
