import { useState } from "react";
import { Stack, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { DocumentReference } from "fhir/r5";
import { isMobile, isMobileSafari } from "react-device-detect";
import dayjs from "dayjs";

import { PrivateMainLayout } from "../../components/layout";
import { LargeContentBox } from "../../components/common";
import { ViewMedicalPrescription } from "./components/ViewMedicalPrescription";
import { ScreenMobile } from "../../components/common/ScreenMobile";
import { DataGridPresentation } from "../../components/common/DataGridPresentation";
import { HeaderContentBox } from "../../components/common/HeaderContentBox";
import { usePatientHealthCareInformationContext } from "../../contexts/PatientHealthCareInformationContext";
import MedicalPrescriptionActions from "./components/MedicalPrescriptionActions";
import { Check, Warning } from "@mui/icons-material";
import { GetBinary } from "../../services/fhir/patientsummary/Binary/GetBinary";

interface IProps {
  open?: boolean;
  onClose?: VoidFunction;
}

export default function MedicalPrescription({ open, onClose }: IProps) {
  const { documentReferencePrescription, medicationRequestPrescription } =
    usePatientHealthCareInformationContext();
  const [openInformation, setOpenInformation] = useState<boolean>(false);
  const [dataRow, setDataRow] = useState<any>();

  const base64ToBlob = (base64: any, contentType = "") => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const handleClose = () => {
    setOpenInformation(false);
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "Id", width: 90 },
    {
      field: "description",
      headerName: "Medicamentos",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "type",
      headerName: "Tipo",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "date",
      headerName: "Data de prescrição",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "specialty",
      headerName: "Especialidade",
      minWidth: 150,
      maxWidth: 300,
      flex: 1,
    },
    {
      field: "practitioner",
      headerName: "Profissional",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "sign",
      headerName: "Assinado digitalmente",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "document",
      headerName: "Ações",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => {
        return <MedicalPrescriptionActions row={e.row} />;
      },
    },
  ];

  const rows =
    documentReferencePrescription?.map(
      (el: fhir5.BundleEntry<DocumentReference | any>, index: number) => {
        const auxPractitioner = el?.resource?.contained.find(
          (contained: any) => contained?.resourceType === "Practitioner"
        );
        const auxSpecialty =
          el?.resource?.practiceSetting?.coding?.[0]?.display;

        const auxDescription = medicationRequestPrescription?.filter(
          (request: any) => {
            const findRef = el?.resource?.basedOn?.find((basedOn: any) =>
              basedOn?.reference.includes(request.resource.id)
            );
            return findRef;
          }
        );
        const sign =
          el?.resource?.securityLabel?.[0]?.coding?.[0]?.code ===
          "ProofOfOrigin"
            ? "Sim"
            : "Não";

        const auxType = el?.resource?.type?.coding?.[0]?.display || "-";

        return {
          id: index,
          type: auxType,
          sign,
          description:
            auxDescription
              ?.map(
                (medicationRequestPrescription: any) =>
                  medicationRequestPrescription?.resource?.medication?.concept
                    ?.text
              )
              ?.join(" , ") || "",
          date: dayjs(el?.resource?.date).format("DD/MM/YYYY") || "-",
          specialty: auxSpecialty || "-",
          practitioner:
            auxPractitioner?.name?.[0]?.given?.[0] ||
            auxPractitioner?.identifier?.[0]?.value,
          binaryUrl: el?.resource?.content?.[0]?.attachment?.url || "",
          medication: auxDescription,
        };
      }
    ) || [];

  return isMobile ? (
    <ScreenMobile
      title="Receitas médicas"
      open={open ? open : false}
      onClose={onClose}
    >
      <Stack width="90vw" minHeight="20vh" height="auto" maxHeight="70vh">
        <DataGridPresentation
          rows={rows}
          columns={columns}
          rowClick={async (e: any) => {
            try {
              const response = await GetBinary({
                urlBinary: e?.row?.binaryUrl,
              });
              const blob = base64ToBlob(response?.data, response?.contentType);
              const fileUrl = URL.createObjectURL(blob);
              const auxFile = {
                fileUrl,
                fileName: "Receita_Medica.pdf",
              };

              if (isMobileSafari) {
                if (auxFile) {
                  const link = document.createElement("a"); // Cria um elemento <a>
                  link.href = auxFile?.fileUrl; // Define o URL do Blob
                  link.download = auxFile?.fileName; // Define o nome do arquivo
                  // document.body.appendChild(link); // Adiciona o link ao documento
                  link.click(); // Simula o clique no link
                  // document.body.removeChild(link); // Remove o link do documento
                  // URL.revokeObjectURL(auxFile?.fileUrl); // Libera a memória do URL Blob
                } else {
                  alert(
                    "O arquivo ainda está sendo preparado, por favor tente novamente."
                  );
                }
              } else {
                window.open(auxFile.fileUrl, "_blank");
                // setDataRow(e.row);
                // setOpenInformation(true);
              }
            } catch (err) {
              console.log("err", err);
            }
          }}
        />
      </Stack>
      <ViewMedicalPrescription
        openInformation={openInformation}
        handleClose={() => setOpenInformation(false)}
        data={dataRow}
      />
    </ScreenMobile>
  ) : (
    <PrivateMainLayout>
      <LargeContentBox>
        <Stack width="100%" height="100%" overflow="auto">
          <HeaderContentBox
            choiseSelected={openInformation}
            handleClose={handleClose}
          >
            Receitas médicas
          </HeaderContentBox>

          <DataGridPresentation
            rows={rows}
            columns={columns}
            rowClick={() => {}}
          />
        </Stack>
      </LargeContentBox>
    </PrivateMainLayout>
  );
}
