import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";

import { GridExpandMoreIcon } from "@mui/x-data-grid";

import { PostQuestionnaireResponse } from "../../../../services/fhir/patientsummary/questionnaireResponse/PostQuestionnaireResponse";
import { GetQuestionnaireResponsesByPractitioner } from "../../../../services/fhir/patientsummary/questionnaireResponse/GetQuestionnaireResponse";
import { GetQuestionnaireResponse } from "../../../../services/fhir/patientsummary/questionnaireResponse/GetQuestionnaireResponse";
import { GetQuestionnaire } from "../../../../services/fhir/questionnaire/GetQuestionnaire";
import { useLocation, useNavigate } from "react-router-dom";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { RenderInputFieldsItem } from "./components/Render/RenderInputFieldsItem";
import { usePrescriptionAttendenceContext } from "../../../../contexts/PrescriptionAttendenceContext";
import { useFhirData } from "../../../../utils/useFhirData";
import {
  BundlePatientSummary,
  PatchAppointmentStatus,
} from "../../../../services/fhir";
import { RenderDiagnostics } from "./components/Render/RenderDiagnostics";
import { RenderMedications } from "./components/Render/RenderMedications";
import { BuildQuestionnaireResponse } from "./components/Builds/BuildQuestionnaireResponse";
import { BuildBundleQuestionnaireResponse } from "./components/Builds/BuildBundleQuestionnaireResponse";
import { RenderSupplements } from "./components/Render/RenderSupplements";
import { RenderProcedures } from "./components/Render/RenderProcedures";
import { RenderCondutas } from "./components/Render/RenderCondutas";
import { RenderCondutasPassadas } from "./components/Render/RenderCondutasPassadas";
import { RenderResultadoDeExame } from "./components/Render/RenderResultadosDeExame";
import { endpoints } from "../../../../configs/Settings";

export function QuestionnaireViewer() {
  const {
    isOpenDiagnostic,
    isOpenPrescription,
    setIsOpenDiagnostic,
    setIsOpenPrescription,
    setTabSelected,
  } = usePrescriptionAttendenceContext();
  const { state } = useLocation();
  const { user } = useSessionContext();

  const [file, setFile] = useState<any>();
  const [questionnaire, setQuestionnaire] = useState<any>();
  const [questionnairesResponse, setQuestionnairesResponse] = useState<any>();
  const [
    questionnairesResponsePractitioner,
    setQuestionnairesResponsesPractitioner,
  ] = useState<any>();
  const [patientSummary, setPatientSummary] = useState<any>();
  const [refetch, setRefetch] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const conditionsList = useFhirData.extractPatientSummaryResource(
    patientSummary,
    "Condition"
  );
  const medicationStatement = useFhirData.extractPatientSummaryResource(
    patientSummary,
    "MedicationStatement"
  );

  const medicationSupplement = useFhirData.extractPatientSummaryResource(
    patientSummary,
    "MedicationRequest"
  );

  const procedureRequest = useFhirData.extractPatientSummaryResource(
    patientSummary,
    "MedicationRequest",
    "prescricao"
  );

  const { control, register, unregister, handleSubmit, getValues, setValue } =
    useForm<any>();

  const navigate = useNavigate();

  const SiiS = questionnaire;

  let selectedOption = useWatch({ name: "questionnaire", control });

  const options = [
    { value: 1, display: "SiiS", questionnaire: SiiS },
    { value: 3, display: "Adicionar arquivo", questionnaire: file || {} },
  ];

  const render = selectedOption
    ? options?.find((el: any) => el.value === selectedOption)?.questionnaire
    : options[0]?.questionnaire;

  async function getQuestionnaires() {
    try {
      const fetchQuestionnaire = await GetQuestionnaire({
        questionnaire: endpoints.QUESTIONNARIE_ID,
      });

      setQuestionnaire(fetchQuestionnaire);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchQuestionnairesResponse() {
    try {
      const fetchQuestionnaireResponse = await GetQuestionnaireResponse({
        patient: state.encounterReference.subject,
        questionnaire: "Atendimento",
      });

      setQuestionnairesResponse(fetchQuestionnaireResponse);
    } catch (error) {
      console.log(error);
    }
  }

  async function getPatientSummary() {
    try {
      const fetchPatientSummary = await BundlePatientSummary({
        identifier: state?.encounterReference?.subject,
        entries: [
          "Condition",
          "MedicationStatement",
          "MedicationRequest",
          "DocumentReference",
        ],
      });
      setPatientSummary(fetchPatientSummary);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    async function handleBeforeUnload() {
      try {
        await getQuestionnaires();
        await fetchQuestionnairesResponse();
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    handleBeforeUnload();
  }, []);

  useEffect(() => {
    getPatientSummary();
  }, [
    refetch,
    state?.encounterReference,
    isOpenDiagnostic,
    isOpenPrescription,
  ]);

  const onSubmit = handleSubmit(async (formData) => {
    const questionnaireResponse = BuildQuestionnaireResponse({
      questionnaire: render,
      response: formData,
      user: user,
      state: state,
      questionnaireID: "Atendimento",
      status: "completed",
    });
    const bundle = BuildBundleQuestionnaireResponse(questionnaireResponse);
    try {
      const response = await PostQuestionnaireResponse(questionnaireResponse);
      if (state?.encounterReference?.appointmentId && response?.id) {
        const appointment = await PatchAppointmentStatus({
          appointmentId: state?.encounterReference?.appointmentId,
          status: "fulfilled",
        });
        if (appointment) {
          setRefetch(!refetch);
          setIsOpenDiagnostic(false);
          setIsOpenPrescription(false);
          setTabSelected(0);
        }
      } else {
        throw Error(
          "Não foi possível finalizar esta operação, tente novamente!"
        );
      }
    } catch (err) {
      console.log("Não foi possível finalizar esta operação, tente novamente");
    } finally {
      navigate("/home");
    }
  });

  interface renderQuestionnaireProps {
    questionnaire: any;
  }

  function renderQuestionnaire({ questionnaire }: renderQuestionnaireProps) {
    if (questionnaire?.item && questionnaire?.item?.length > 0) {
      const leftItems = [
        "Anamnese",
        "Exames Físicos",
        "Condutas",
        "Resultado de exames",
      ];

      const renderContents = ["Diagnósticos", "Tratamentos"];

      const leftQuestionnaire = questionnaire?.item?.filter((item: any) => {
        return leftItems.includes(item.text);
      });

      const rightQuestionnaire = questionnaire?.item?.filter((item: any) => {
        return !leftItems.includes(item.text);
      });

      return (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            {leftQuestionnaire?.map((item: any, index: number) => {
              return (
                <>
                  {RenderInputFieldsItem({
                    item,
                    index,
                    control,
                    firstGroup: true,
                  })}
                </>
              );
            })}
          </Grid>
          <Grid item xs={4}>
            {rightQuestionnaire?.map((item: any, index: number) => {
              return (
                <>
                  {item.text === "Diagnósticos" && (
                    <Accordion
                      sx={{
                        border: "1px solid #ccc",
                        boxShadow: "2px 2px 16px 2px rgba(0, 0, 0, 0.2)",
                        position: "initial",
                        marginBottom: "20px",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<GridExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h6">{item.text}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
                          <Button
                            onClick={() => {
                              setTabSelected("2");
                              setIsOpenDiagnostic(true);
                            }}
                            sx={{ width: "100%", marginBottom: 2 }}
                            variant="outlined"
                            size="large"
                          >
                            Adicionar Diagnóstico
                          </Button>
                          <Button
                            onClick={() => getPatientSummary()}
                            sx={{ marginBottom: 2 }}
                            variant="outlined"
                            title="Recarregar Dados"
                          >
                            <CachedIcon />
                          </Button>
                        </Box>
                        <RenderDiagnostics
                          data={conditionsList?.[0]?.entry || []}
                        />
                      </AccordionDetails>
                    </Accordion>
                  )}
                  {item.text === "Tratamentos" && (
                    <>
                      <Accordion
                        sx={{
                          border: "1px solid #ccc",
                          boxShadow: "2px 2px 16px 2px rgba(0, 0, 0, 0.2)",
                          position: "initial",
                          marginBottom: "20px",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<GridExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="h6">{item.text}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box
                            sx={{ display: "flex", gap: 2, marginBottom: 2 }}
                          >
                            <Button
                              onClick={() => setIsOpenPrescription(true)}
                              sx={{ width: "100%", marginBottom: 2 }}
                              variant="outlined"
                              size="large"
                            >
                              Adicionar Tratamento
                            </Button>
                            <Button
                              onClick={() => getPatientSummary()}
                              sx={{ marginBottom: 2 }}
                              variant="outlined"
                              title="Recarregar Dados"
                            >
                              <CachedIcon />
                            </Button>
                          </Box>

                          <RenderMedications
                            data={medicationStatement?.[0]?.entry || []}
                          />
                          <RenderSupplements
                            data={medicationSupplement?.[0]?.entry || []}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </>
                  )}
                  {!renderContents.includes(item.text) &&
                    RenderInputFieldsItem({
                      item,
                      index,
                      control,
                      firstGroup: true,
                    })}
                </>
              );
            })}
          </Grid>
        </Grid>
      );
    }
    return null;
  }

  function renderQuestionnaireWithResponse(questionnaireResponse: any) {
    const leftItems = [
      "Anamnese",
      "Exames Físicos",
      "Condutas",
      "Resultado de exames",
    ];

    const renderContents = ["Diagnósticos", "Tratamentos"];

    const itemResponseConducts = questionnaireResponse?.item?.filter(
      (item: any) => {
        return item.text === "Condutas";
      }
    );

    const leftQuestionnaire = questionnaireResponse?.item?.filter(
      (item: any) => {
        return leftItems.includes(item.text);
      }
    );

    const rightQuestionnaire = questionnaireResponse?.item?.filter(
      (item: any) => {
        return !leftItems.includes(item.text);
      }
    );

    return (
      <Grid container spacing={2}>
        <Grid item xs={7}>
          {leftQuestionnaire?.map((item: any, index: number) => {
            if (item.text === "Condutas")
              return (
                <RenderCondutas unregister={unregister} control={control} />
              );
            if (item.text === "Resultado de exames")
              return (
                <RenderResultadoDeExame
                  control={control}
                  item={item}
                  setValue={setValue}
                  unregister={unregister}
                />
              );
            return (
              <>
                {RenderInputFieldsItem({
                  item,
                  questionnaire: questionnaire,
                  control,
                  index,
                  firstGroup: true,
                  renderWithResponse: true,
                })}
              </>
            );
          })}
        </Grid>
        <Grid item xs={5}>
          {rightQuestionnaire?.map((item: any, index: number) => {
            return (
              <>
                {item.text === "Diagnósticos" && (
                  <Accordion
                    sx={{
                      border: "1px solid #ccc",
                      boxShadow: "2px 2px 16px 2px rgba(0, 0, 0, 0.2)",
                      position: "initial",
                      marginBottom: "20px",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<GridExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h6">{item.text}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
                        <Button
                          onClick={() => {
                            setTabSelected("2");
                            setIsOpenDiagnostic(true);
                          }}
                          sx={{ width: "100%", marginBottom: 2 }}
                          variant="outlined"
                          size="large"
                        >
                          Adicionar Diagnóstico
                        </Button>
                        <Button
                          onClick={() => getPatientSummary()}
                          sx={{ marginBottom: 2 }}
                          variant="outlined"
                          title="Recarregar Dados"
                        >
                          <CachedIcon />
                        </Button>
                      </Box>
                      <RenderDiagnostics
                        data={conditionsList?.[0]?.entry || []}
                      />
                    </AccordionDetails>
                  </Accordion>
                )}
                {item.text === "Tratamentos" && (
                  <>
                    <Accordion
                      sx={{
                        border: "1px solid #ccc",
                        boxShadow: "2px 2px 16px 2px rgba(0, 0, 0, 0.2)",
                        position: "initial",
                        marginBottom: "20px",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<GridExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h6">{item.text}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
                          <Button
                            onClick={() => setIsOpenPrescription(true)}
                            sx={{ width: "100%", marginBottom: 2 }}
                            variant="outlined"
                            size="large"
                          >
                            Adicionar Tratamento
                          </Button>
                          <Button
                            onClick={() => getPatientSummary()}
                            sx={{ marginBottom: 2 }}
                            variant="outlined"
                            title="Recarregar Dados"
                          >
                            <CachedIcon />
                          </Button>
                        </Box>
                        <RenderMedications
                          data={medicationStatement?.[0]?.entry || []}
                        />
                        <RenderSupplements
                          data={medicationSupplement?.[0]?.entry || []}
                        />
                      </AccordionDetails>
                    </Accordion>

                    <RenderCondutasPassadas
                      control={control}
                      itemsResponse={itemResponseConducts}
                    />
                  </>
                )}
                {!renderContents.includes(item.text) &&
                  RenderInputFieldsItem({
                    item,
                    questionnaire: questionnaire,
                    control,
                    index,
                    firstGroup: true,
                  })}
              </>
            );
          })}
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Stack width="100%">
        <form>
          {!loading && (
            <>
              {questionnairesResponse?.entry?.[0]?.resource
                ? renderQuestionnaireWithResponse(
                    questionnairesResponse?.entry?.[0]?.resource
                  )
                : renderQuestionnaire({ questionnaire: questionnaire })}
            </>
          )}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              sx={{ width: "400px", my: 8 }}
              size="large"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
            >
              Finalizar atendimento
            </Button>
          </Box>
        </form>
      </Stack>
    </>
  );
}
