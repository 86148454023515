import { Chip, Stack } from "@mui/material";
import { useSessionContext } from "../../../contexts/SessionContext";
import { usePractitionerRoleContext } from "../../../contexts/PractitionerRoleContext";

export function PractitionerAvatar({ filter }: any) {
  const { access } = useSessionContext();
  const { practitionerRole } = usePractitionerRoleContext();

  const displayAvatar = practitionerRole?.filter((el: any) => {
    const reference = filter?.find(
      (element: any) =>
        element.reference === el.resource?.practitioner?.reference &&
        el.resource?.practitioner?.display &&
        el.resource.id.includes(access?.organizationId)
    );

    return reference;
  });

  return (
    <Stack direction="row">
      {displayAvatar?.map((el: any, index: number) => {
        return (
          <Chip
            key={`avatar-${index}`}
            label={el?.resource?.practitioner?.display}
            size="small"
            sx={{
              color: "neutral700.main",

              fontWeight: 500,
            }}
          />
        );
      })}
    </Stack>
  );
}
