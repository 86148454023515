import React from "react";
import { Box, Stack } from "@mui/material";
import { Header } from "./Header";
import Sidebar from "./Sidebar";
import { MobileBarNavigation } from "./MobileBarNavigation";

export function PrivateMainLayoutMobile({
  children,
  noSidebar = true,
  dashboard = false,
  mobileView = true,
}: {
  children: React.ReactNode;
  noSidebar?: boolean;
  dashboard?: boolean;
  mobileView?: boolean;
}) {
  return (
    <Stack
      direction="column"
      width="100%"
      minHeight="100vh"
      justifyContent="center"
      flex="1"
    >
      <Box width="100%">
        <Header />
      </Box>
      <Stack direction="row" width="100%" flex="1">
        {!noSidebar && <Sidebar />}
        <Stack
          flex="1"
          paddingX={{
            xs: 0,
            sm: mobileView ? 0 : dashboard ? 3 : noSidebar ? "10%" : "5%",
            md: mobileView ? 0 : dashboard ? 3 : noSidebar ? "15%" : "10%",
          }}
          paddingY={0}
          margin={2}
          alignItems="center"
          justifyContent="center"
          sx={{ backgroundColor: "primary100.main" }}
        >
          {children}
        </Stack>
      </Stack>
      <Box
        width="100%"
        height="5vh"
        component="footer"
        position="fixed"
        bottom={0}
        zIndex={1}
      >
        <MobileBarNavigation />
      </Box>
    </Stack>
  );
}
