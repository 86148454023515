import { Typography } from "@mui/material";

interface IProps {
  children: string;
}

export function PageTitle({ children }: IProps) {
  return (
    <Typography
      variant="h5"
      marginBottom={5}
      fontWeight={600}
      textAlign="center"
    >
      {children}
    </Typography>
  );
}
