import { toast } from "react-toastify";

import { endpoints } from "../../../configs/Settings";
import { OAuthApplication } from "../../auth/OAuthApplication";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAxios } from "../../../utils/useAxios";
import { useAuth } from "../../../utils/useAuth";

export async function RecoverPassword(identifier: string, authType?: string) {
  const url = `${endpoints.USERFHIR}/recover-password`;
  const data = {
    payload: identifier,
  };

  const bearerToken =
    authType === "application"
      ? await OAuthApplication()
      : await useAuth.getUserToken();

  const headers = {
    Accept: "*/*",
    Authorization: bearerToken,
    "Content-Type": "application/json",
  };

  const response = await useAxios
    .post(url, data, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`RecoverPassword error: ${response.status}`);
      }
      return { status: HTTPResponseStatus.Success, data: response.data };
    })
    .catch((error) => {
      console.error(error);
      if (error.response.status === HTTPResponseStatus.Conflict) {
        toast.warn("Usuário já existe");
        return { status: HTTPResponseStatus.Conflict };
      }
      return { status: HTTPResponseStatus.InternalError };
    });
  if (response) return response;
}
