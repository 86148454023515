import { Button, Stack } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../../../components/common";
import { ArrowBack } from "@mui/icons-material";

export function ButtonSendPrescription({
  onClick,
  backClick,
  loading,
  text,
  visible,
  textCancel,
  textProtocol,
  onClickSaveProtocol,
  notVisible,
}: any) {
  return (
    <Stack
      width="100%"
      direction="row-reverse"
      justifyContent="space-between"
      marginTop={3}
    >
      <Stack direction="row" gap={4}>
        {visible && (
          <SecondaryButton
            width="250px"
            height="42px"
            onClick={onClickSaveProtocol}
          >
            {textProtocol}
          </SecondaryButton>
        )}
        <PrimaryButton
          width="250px"
          height="42px"
          loading={loading}
          onClick={onClick}
        >
          {text}
        </PrimaryButton>
      </Stack>
      {!notVisible && (
        <Button
          sx={{
            fontSize: 16,
            color: "theme.palette.neutral0.main",
            textTransform: "none",
          }}
          onClick={backClick}
          startIcon={<ArrowBack />}
        >
          {" "}
          {textCancel}
        </Button>
      )}
    </Stack>
  );
}
