import { Add, Description } from "@mui/icons-material";
import { IconButton, TableCell, Tooltip } from "@mui/material";
import { GetMedication } from "../../../../../services/terminology";
import { toast } from "react-toastify";

export function ActionsTablePrescription({ row, setBinaryUrl, addItem }: any) {
  const tags = [
    {
      name: "VERMELHA",
      value: "CE",
      color: "#db3f30",
      display: "Controle especial - Tarja vermelha",
    },
    {
      name: "PRETA",
      value: "CE",
      color: "white",
      background: "black",
      display: "Controle especial - Tarja preta",
    },
    {
      name: "AMARELA",
      value: "AM",
      color: "#FEE2A9",
      display: "Receita amarela",
    },
    {
      name: "AZUL",
      value: "AZ",
      color: "#A4CBE8",
      display: "Receita azul",
    },
    {
      name: "SEM TARJA",
      value: "BR",
      color: "white",
      display: "Receita branca",
    },
    {
      name: "EX",
      display: "Exame",
      value: "EX",
      color: "#FDCF72",
    },
    {
      name: "AT",
      value: "AT",
      color: "#009E8C",
      display: "Atestado",
    },
  ];

  async function GetDataMedication() {
    const response =
      row?.fullResource?.medication?.concept?.coding &&
      (await GetMedication(
        undefined,
        undefined,
        row?.fullResource?.medication?.concept?.coding?.[0]?.code
      ));

    const auxTagValue = response?.data?.entry?.[0]?.resource?.extension?.find(
      (ext: any) =>
        ext.url === "https://fluxmed.com.br/fhir/StructureDefinition/CMEDTarja"
    )?.valueString;

    const auxTag = tags.find((tag: any) =>
      auxTagValue?.toUpperCase().includes(tag.name)
    )?.name;

    const auxPresentation =
      response?.data?.entry?.[0]?.resource?.extension?.find(
        (ext: any) =>
          ext.url ===
          "https://fluxmed.com.br/fhir/StructureDefinition/CMEDApresentacao"
      )?.valueString;

    const auxForm = [
      row?.fullResource?.dosageInstruction?.[0].doseAndRate?.[0]?.type?.text &&
        `${row?.fullResource?.dosageInstruction?.[0].doseAndRate?.[0]?.type?.text} `,
      row?.fullResource?.dosageInstruction?.[0]?.doseAndRate?.[0]?.doseQuantity
        ?.value &&
        `${row?.fullResource?.dosageInstruction?.[0]?.doseAndRate?.[0]?.doseQuantity?.value}`,
      row?.fullResource?.dosageInstruction?.[0]?.timing?.repeat?.frequency &&
        ` a cada ${row?.fullResource?.dosageInstruction?.[0]?.timing?.repeat?.frequency} h `,
      row?.fullResource?.dosageInstruction?.[0]?.timing?.repeat?.period &&
        `por ${row?.fullResource?.dosageInstruction?.[0]?.timing?.repeat?.period} dias`,
    ];

    const auxItem = {
      name: row?.description,
      label: `${row?.description}${auxPresentation ? auxPresentation : ""}`,

      inputValue: {
        ...(row?.fullResource?.medication?.concept?.coding?.[0]?.display && {
          code: {
            coding: [
              {
                code: row?.fullResource?.medication?.concept?.coding?.[0]?.code,
                display:
                  row?.fullResource?.medication?.concept?.coding?.[0]?.display,
                system: "https://fluxmed.com.br/fhir/CodeSystem/CMED",
              },
            ],
          },
          tag: auxTag || "Branca",
        }),
        presentation: auxPresentation,
        name: row?.description,

        label: `${row?.description}${auxPresentation ? auxPresentation : ""}`,
      },
      tag: auxTag || "Branca",
      ...(row?.fullResource?.dosageInstruction?.[0]?.doseAndRate?.[0]
        ?.doseQuantity?.value && {
        quantity: {
          label:
            row?.fullResource?.dosageInstruction?.[0]?.doseAndRate?.[0]
              ?.doseQuantity?.value,
          value:
            row?.fullResource?.dosageInstruction?.[0]?.doseAndRate?.[0]
              ?.doseQuantity?.value,
        },
      }),
      ...(row?.fullResource?.dosageInstruction?.[0]?.timing?.repeat
        ?.frequency && {
        frequency: {
          label: `${row?.fullResource?.dosageInstruction?.[0]?.timing?.repeat?.frequency} h`,
        },
      }),
      formValue: {
        label:
          row?.fullResource?.dosageInstruction?.[0].doseAndRate?.[0]?.type
            ?.text,
      },
      ...(row?.fullResource?.dosageInstruction?.[0]?.timing?.repeat?.period && {
        time: {
          label: `${row?.fullResource?.dosageInstruction?.[0]?.timing?.repeat?.period} dias`,
        },
      }),
      note: row?.fullResource?.note?.[0]?.text || "",
      form: auxForm,
      type: "medication",
      tabValue: "1",
    };

    addItem(auxItem);
    toast.success("Item adicionado na prescrição");
  }

  return (
    <TableCell align="center">
      {row.binaryUrl && (
        <>
          <Tooltip title="Adicionar item na prescrição">
            <IconButton onClick={async () => await GetDataMedication()}>
              <Add />
            </IconButton>
          </Tooltip>
          <Tooltip title="Visualizar receita">
            <IconButton
              onClick={() => {
                setBinaryUrl(row.binaryUrl);
              }}
            >
              <Description />
            </IconButton>
          </Tooltip>
        </>
      )}
    </TableCell>
  );
}
