import { createTheme, responsiveFontSizes } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";

declare module "@mui/material/styles" {
  interface Palette {
    primary100: Palette["primary"];
    primary200: Palette["primary"];
    primary300: Palette["primary"];
    primary400: Palette["primary"];
    primary500: Palette["primary"];
    primary600: Palette["primary"];
    primary700: Palette["primary"];
    primary800: Palette["primary"];
    primary900: Palette["primary"];
    primary1000: Palette["primary"];
    secondary100: Palette["primary"];
    secondary200: Palette["primary"];
    secondary300: Palette["primary"];
    secondary400: Palette["primary"];
    secondary500: Palette["primary"];
    secondary600: Palette["primary"];
    secondary700: Palette["primary"];
    secondary800: Palette["primary"];
    secondary900: Palette["primary"];
    secondary1000: Palette["primary"];
    success100: Palette["primary"];
    success200: Palette["primary"];
    success300: Palette["primary"];
    success400: Palette["primary"];
    success500: Palette["primary"];
    success600: Palette["primary"];
    success700: Palette["primary"];
    success800: Palette["primary"];
    success900: Palette["primary"];
    success1000: Palette["primary"];
    warning100: Palette["primary"];
    warning200: Palette["primary"];
    warning300: Palette["primary"];
    warning400: Palette["primary"];
    warning500: Palette["primary"];
    warning600: Palette["primary"];
    warning700: Palette["primary"];
    warning800: Palette["primary"];
    warning900: Palette["primary"];
    warning1000: Palette["primary"];
    error100: Palette["primary"];
    error200: Palette["primary"];
    error300: Palette["primary"];
    error400: Palette["primary"];
    error500: Palette["primary"];
    error600: Palette["primary"];
    error700: Palette["primary"];
    error800: Palette["primary"];
    error900: Palette["primary"];
    error1000: Palette["primary"];
    neutral0: Palette["primary"];
    neutral100: Palette["primary"];
    neutral200: Palette["primary"];
    neutral300: Palette["primary"];
    neutral400: Palette["primary"];
    neutral500: Palette["primary"];
    neutral600: Palette["primary"];
    neutral700: Palette["primary"];
    neutral800: Palette["primary"];
    neutral900: Palette["primary"];
    neutral1000: Palette["primary"];
  }

  interface PaletteOptions {
    primary100: PaletteOptions["primary"];
    primary200: PaletteOptions["primary"];
    primary300: PaletteOptions["primary"];
    primary400: PaletteOptions["primary"];
    primary500: PaletteOptions["primary"];
    primary600: PaletteOptions["primary"];
    primary700: PaletteOptions["primary"];
    primary800: PaletteOptions["primary"];
    primary900: PaletteOptions["primary"];
    primary1000: PaletteOptions["primary"];
    secondary100: PaletteOptions["primary"];
    secondary200: PaletteOptions["primary"];
    secondary300: PaletteOptions["primary"];
    secondary400: PaletteOptions["primary"];
    secondary500: PaletteOptions["primary"];
    secondary600: PaletteOptions["primary"];
    secondary700: PaletteOptions["primary"];
    secondary800: PaletteOptions["primary"];
    secondary900: PaletteOptions["primary"];
    secondary1000: PaletteOptions["primary"];
    success100: PaletteOptions["primary"];
    success200: PaletteOptions["primary"];
    success300: PaletteOptions["primary"];
    success400: PaletteOptions["primary"];
    success500: PaletteOptions["primary"];
    success600: PaletteOptions["primary"];
    success700: PaletteOptions["primary"];
    success800: PaletteOptions["primary"];
    success900: PaletteOptions["primary"];
    success1000: PaletteOptions["primary"];
    warning100: PaletteOptions["primary"];
    warning200: PaletteOptions["primary"];
    warning300: PaletteOptions["primary"];
    warning400: PaletteOptions["primary"];
    warning500: PaletteOptions["primary"];
    warning600: PaletteOptions["primary"];
    warning700: PaletteOptions["primary"];
    warning800: PaletteOptions["primary"];
    warning900: PaletteOptions["primary"];
    warning1000: PaletteOptions["primary"];
    error100: PaletteOptions["primary"];
    error200: PaletteOptions["primary"];
    error300: PaletteOptions["primary"];
    error400: PaletteOptions["primary"];
    error500: PaletteOptions["primary"];
    error600: PaletteOptions["primary"];
    error700: PaletteOptions["primary"];
    error800: PaletteOptions["primary"];
    error900: PaletteOptions["primary"];
    error1000: PaletteOptions["primary"];
    neutral0: PaletteOptions["primary"];
    neutral100: PaletteOptions["primary"];
    neutral200: PaletteOptions["primary"];
    neutral300: PaletteOptions["primary"];
    neutral400: PaletteOptions["primary"];
    neutral500: PaletteOptions["primary"];
    neutral600: PaletteOptions["primary"];
    neutral700: PaletteOptions["primary"];
    neutral800: PaletteOptions["primary"];
    neutral900: PaletteOptions["primary"];
    neutral1000: PaletteOptions["primary"];
  }
}

let theme = createTheme({
  palette: {
    primary: { main: "#007C6D" },
    secondary: { main: "#296491" },
    primary100: { main: "#E6F7F5" },
    primary200: { main: "#B0E6E0" },
    primary300: { main: "#8ADAD1" },
    primary400: { main: "#54C9BB" },
    primary500: { main: "#33BEAE" },
    primary600: { main: "#00AE9A" },
    primary700: { main: "#009E8C" },
    primary800: { main: "#007C6D" },
    primary900: { main: "#006055" },
    primary1000: { main: "#004941" },
    secondary100: { main: "#EBF4FA" },
    secondary200: { main: "#C2DCEF" },
    secondary300: { main: "#A4CBE8" },
    secondary400: { main: "#7BB3DD" },
    secondary500: { main: "#61A4D6" },
    secondary600: { main: "#3A8DCC" },
    secondary700: { main: "#3580BA" },
    secondary800: { main: "#296491" },
    secondary900: { main: "#204E70" },
    secondary1000: { main: "#183B56" },
    success100: { main: "#EDFDF8" },
    success200: { main: "#D1FAEC" },
    success300: { main: "#A5F3D9" },
    success400: { main: "#6EE7BF" },
    success500: { main: "#36D39F" },
    success600: { main: "#0EA472" },
    success700: { main: "#08875D" },
    success800: { main: "#03724D" },
    success900: { main: "#066042" },
    success1000: { main: "#064C35" },
    warning100: { main: "#FFF8EB" },
    warning200: { main: "#FFF1D6" },
    warning300: { main: "#FEE2A9" },
    warning400: { main: "#FDCF72" },
    warning500: { main: "#FBBB3C" },
    warning600: { main: "#DB7712" },
    warning700: { main: "#B25E09" },
    warning800: { main: "#96530F" },
    warning900: { main: "#7F460D" },
    warning1000: { main: "#663A0F" },
    error100: { main: "#FEF1F2" },
    error200: { main: "#FEE1E3" },
    error300: { main: "#FEC8CC" },
    error400: { main: "#FCA6AD" },
    error500: { main: "#F8727D" },
    error600: { main: "#EF4352" },
    error700: { main: "#E02D3C" },
    error800: { main: "#BA2532" },
    error900: { main: "#981B25" },
    error1000: { main: "#86131D" },
    neutral0: { main: "#FFFFFF" },
    neutral100: { main: "#F8F9FC" },
    neutral200: { main: "#F1F3F9" },
    neutral300: { main: "#E1E6EF" },
    neutral400: { main: "#CBD2E1" },
    neutral500: { main: "#94A0B8" },
    neutral600: { main: "#5F6C85" },
    neutral700: { main: "#3F444D" },
    neutral800: { main: "#23272F" },
    neutral900: { main: "#1B1F27" },
    neutral1000: { main: "#000000" },
  },
  typography: {
    h1: { fontSize: 61 },
    h2: { fontSize: 49 },
    h3: { fontSize: 39 },
    h4: { fontSize: 32 },
    h5: { fontSize: 25 },
    h6: { fontSize: 20 },
    subtitle1: { fontSize: 20 },
    subtitle2: { fontSize: 16 },
    body1: { fontSize: 14 },
    body2: { fontSize: 12 },
    fontFamily: ["Poppins", "Roboto", "sans-serif"].join(","),
  },
});

theme = responsiveFontSizes(theme);

export default theme;
