import { Person } from "@mui/icons-material";
import { useSessionContext } from "../../contexts/SessionContext";
import { IFeatures } from "../../utils/interfaces";
import { currentFeatures } from "../../configs/Settings";
import { useViewContext } from "../../contexts/ViewContext";
import { ManagePractitioners } from "./ManagePractitioners";

export function useManagePractitioners() {
  const { access } = useSessionContext();
  const { availableRoles } = useViewContext();

  const configs: IFeatures = {
    active:
      access.type === "professional" &&
      currentFeatures.profileClinic &&
      (availableRoles?.includes("manager") ||
        availableRoles?.includes("owner")),
    path: "/practitioners",
    text: "Profissionais",
    icon: Person,

    subitem: true,
    parent: "Configurações",

    sideMenu: true,
    sideMenuIndex: 0,

    component: () => <ManagePractitioners />,
  };

  return configs;
}
