import { Grid, Stack, Typography } from "@mui/material";

import { useForm, useWatch } from "react-hook-form";
import { FormSelectInput } from "../../../components/form";

import { RequiredField } from "./RequiredField";
import { AvailableDays } from "./AvailableDays";
import { AvailableTime } from "./AvailableTime";
import { SubmitActions } from "./SubmitActions";
import { useEffect, useState } from "react";
import { GetValueSet } from "../../../services/terminology";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useSessionContext } from "../../../contexts/SessionContext";
import { usePractitionerRoleContext } from "../../../contexts/PractitionerRoleContext";

function cleanRepeatedList(list: any) {
  let cleanedList = new Set(list);
  return Array.from(cleanedList);
}

export function HealthCare({ onNext, setCurrentStep, defaultValues }: any) {
  const { access } = useSessionContext();
  const { practitionerRole, userPractitionerRole } =
    usePractitionerRoleContext();

  const availablePractitioner = access?.roles?.includes("manager")
    ? practitionerRole?.map((el) => el.resource)
    : [userPractitionerRole];

  const availableSpecialites =
    availablePractitioner
      ?.map((el: any) => {
        const specialty = el?.specialty?.map(
          (e: fhir5.CodeableConcept) => e.coding?.[0]?.display
        );
        return specialty;
      })
      .filter((array: any) => array !== undefined) || [];

  const cleanAvailableSpecialites = cleanRepeatedList(
    [].concat.apply([], availableSpecialites)
  );

  const [specialties, setSpecialties] = useState([
    { value: "", display: "Carregando" },
  ]);

  const { control, handleSubmit, register, unregister } = useForm({
    defaultValues,
  });

  const daysofweek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
  const defaultDays = ["Seg", "Ter", "Qua", "Qui", "Sex"];

  let watchDaysChosen = useWatch({ name: "daysOfWeek", control });

  const selectedDays = watchDaysChosen || defaultDays;

  function removeUnselectedDays(object: any, daysToRemove: any) {
    for (const day of daysToRemove) {
      if (object?.hasOwnProperty(day)) {
        delete object[day];
      }
    }
  }

  const onSubmit = async (data: any) => {
    const unselectedDays = daysofweek.filter(
      (el: any) => !selectedDays.includes(el)
    );

    removeUnselectedDays(data?.timeAvailable?.[0], unselectedDays);
    removeUnselectedDays(data?.timeAvailable?.[1], unselectedDays);

    await onNext({ healthcare: data });
  };

  const clickNext = async (data: any) => {
    await onSubmit(data);
    setCurrentStep((prev: number) => prev + 1);
  };

  useEffect(() => {
    async function asyncCall() {
      const EspecialidadeMedicaValueSet = await GetValueSet({
        valueset: "EspecialidadeMedica",
      }).then((response) => {
        if (response?.status === HTTPResponseStatus.Success) {
          return response.data.expansion.contains;
        }
        return [];
      });

      const auxList =
        EspecialidadeMedicaValueSet?.map(
          (item: {
            system: string;
            code: string;
            version: string;
            display: string;
          }) => ({
            value: item.display,
            display: item.display,
          })
        )?.filter((el: any) =>
          cleanAvailableSpecialites.includes(el.display)
        ) || [];

      const otherSpecialties =
        cleanAvailableSpecialites
          ?.filter((e: any) => !auxList?.some((el: any) => el?.display === e))
          ?.map((item: any) => ({
            display: item,
            value: item,
          })) || [];

      setSpecialties([...auxList, ...otherSpecialties]);
    }
    asyncCall();
  }, []);

  return (
    <Stack mb={2} width="100%" spacing={2}>
      <Typography
        variant="h6"
        fontWeight="600"
        textAlign="center"
        padding="40px 0px 20px 0px"
        color="neutral700.main"
      >
        Configure o tipo e horário do serviço de saúde associado à esta agenda.
      </Typography>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        paddingBottom={5}
      >
        <Grid item xs={12}>
          <Typography
            variant="h6"
            fontWeight="600"
            mb={3}
            color="neutral600.main"
          >
            Serviços de saúde <RequiredField />
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <FormSelectInput
            defaultValue=""
            name="healthService"
            label="Serviços de saúde"
            control={control}
            selectOptions={specialties}
            required
            autoFocus
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="h6"
            fontWeight="600"
            mb={2}
            color="neutral600.main"
          >
            Dias e horários disponíveis na semana <RequiredField />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" mb={2}>
            Configure os horários em que este serviço ficará disponível durante
            a semana.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <AvailableDays
            selectedDays={selectedDays}
            control={control}
            register={register}
          />
        </Grid>
        <Grid item xs={12}>
          <AvailableTime
            selectedDays={selectedDays}
            control={control}
            defaultValues={defaultValues}
            unregister={unregister}
          />
        </Grid>
        <Grid item xs={12} mt={5} mb={-5}>
          <SubmitActions
            goBack={() => setCurrentStep((prev: number) => prev - 1)}
            goForward={handleSubmit(clickNext)}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}
