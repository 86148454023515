import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";

export async function AcceptInvite(objData: any) {
  const id = objData.id;
  const data = objData;
  const removeInvited = data?.code?.filter(
    (item: any) => item?.coding?.[0]?.code !== "RL_INVITED"
  );
  data.code = removeInvited;

  const url = `${endpoints.ADMFHIR}/PractitionerRole/${id}`;
  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
  };
  const response = await useAxios
    .put(url, data, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status === 201) {
        throw new Error(`AcceptInvite: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("AcceptInvite ", error);
      // toast.error(`AcceptInvite Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
