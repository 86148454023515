import { toast } from "react-toastify";
import { APPC_POLICY } from "../../../services/appc";
import { usePrivacyContext } from "../../../contexts/PrivacyContext";
import { useSessionContext } from "../../../contexts/SessionContext";
import { Divider, IconButton, Stack, Typography } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../../../components/common";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PrivateMainLayout } from "../../../components/layout";
import { isMobile } from "react-device-detect";
import { Close } from "@mui/icons-material";

export function ConsentManagementMobile() {
  const { user } = useSessionContext();
  const navigate = useNavigate();
  const { isSharing, privacyLoading, setRefetch } = usePrivacyContext();

  const [loading, setLoading] = useState<boolean>(false);

  async function handleChangePolicy(share: boolean) {
    setLoading(true);
    try {
      await APPC_POLICY({
        option: share ? "Opt-in" : "Opt-out",
        patient_id: user.username,
      });
      toast.success("Política de privacidade alterada com sucesso");
      navigate(-1);
    } catch (err) {
      toast.error("Não foi possível alterar a política de privacidade");
      console.log("PrivacyPage", err);
    } finally {
      setRefetch((prev: boolean) => !prev);
      setLoading(false);
    }
  }

  return (
    <PrivateMainLayout
      noPadding={isMobile}
      noSidebar={isMobile}
      mobileView={isMobile}
    >
      <Stack
        width="100%"
        height="100%"
        sx={{ backgroundColor: "neutral0.main" }}
        p={2}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Typography
            variant="subtitle2"
            color="neutral700.main"
            fontWeight={600}
          >
            Privacidade
          </Typography>
          <IconButton onClick={() => navigate("/")}>
            <Close />
          </IconButton>
        </Stack>
        <Divider sx={{ width: "100%", background: "neutral400.main" }} />
        <Stack direction="column" width="100%" gap={1} mt={2}>
          <Typography
            variant="body1"
            maxHeight="50vh"
            overflow="auto"
            textAlign="justify"
          >
            Ao aceitar os termos de uso deste aplicativo e caso você já tenha
            sido atendido em alguma unidade de saúde participante da Plataforma
            Fluxmed, por padrão, seu Registro Eletrônico de Saúde é
            compartilhado automaticamente entre as unidades de saúde. O
            compartilhamento do Registro Eletrônico de Saúde é importante para a
            continuidade do seu cuidado de saúde, ao compartilha-lo, suas
            informações de saúde já estarão disponíveis para os profissionais
            das diferentes unidades de saúde participantes da plataforma
            Fluxmed. Você pode gerenciar quais as unidades de saúde ou as
            pessoas próximas a você poderão ter acesso ao suas informações de
            saúde, poderá ainda ver quem já visualizou seus registros
            eletrônicos de saúde no menu “Compartilhamento” Caso não deseje que
            seu registro de saúde seja compartilhado, selecione a opção “Não
            tenho interesse em compartilhar meu Registro Eletrônico de Saúde”.
            Seu Registro Eletrônico de Saúde é privado. Para que você possa
            compartilhá-lo ou não com outras pessoas ou com estabelecimentos de
            saúde de seu interesse, selecione a opção desejada.
          </Typography>
          <PrimaryButton
            width="100%"
            height="56px"
            disabled={isSharing && !privacyLoading}
            loading={loading && !isSharing}
            onClick={async () => handleChangePolicy(true)}
          >
            Compartilhar
          </PrimaryButton>
          <SecondaryButton
            width="100%"
            height="56px"
            disabled={!isSharing && !privacyLoading}
            onClick={async () => handleChangePolicy(false)}
          >
            Não compartilhar
          </SecondaryButton>
        </Stack>
      </Stack>
    </PrivateMainLayout>
  );
}
