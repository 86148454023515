import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tab,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import { AccordionHeader } from "./AccordionHeader";
import { NextAppointments } from "./NextAppointments";
import { AvailableSlots } from "./AvailableSlolts";
import { AppointmentsHistory } from "./AppointmentsHistory";
import { DateFilter } from "./DateFilter";

import { TabIndex } from "../types";
import { FilterType, IDateLimit } from "../types";
import { GetAppointment, GetSlot } from "../../../services/fhir";
import { useFhirData } from "../../../utils/useFhirData";
import { useDatetime } from "../../../utils/useDatetime";

interface IProps {
  schedule: any;
  id: number;
  activeSchedule: any;
  setActiveSchedules: any;
  setRefetch: Function;
  defaultOpen: boolean;
}

export function AppointmentAccordion({
  schedule,
  id,
  activeSchedule,
  setActiveSchedules,
  setRefetch,
  defaultOpen,
}: IProps) {
  const { filterAppointmentBundle } = useFhirData;
  const { formatDateToISO } = useDatetime;

  const [tabRefetch, setTabRefetch] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState(TabIndex.NextAppointments);
  const [dateFilter, setDateFilter] = useState<Dayjs>(dayjs());
  const [filterType, setFilterType] = useState<FilterType>("month");
  const [dateLimit, setDateLimit] = useState<IDateLimit>({
    minDate: true,
    maxDate: false,
  });
  const [availableSlots, setAvailableSlots] =
    useState<fhir5.Bundle<fhir5.Slot>>();
  const [completedAppointments, setCompletedAppointments] =
    useState<fhir5.Bundle<fhir5.Appointment>>();
  const [completedAppointmentsPatients, setCompletedAppointmentsPatients] =
    useState<fhir5.BundleEntry<fhir5.Patient>[]>();

  const [nextAppointments, setNextAppointments] =
    useState<fhir5.Bundle<fhir5.Appointment>>();

  const [nextAppointmentsPatients, setNextAppointmentsPatients] =
    useState<fhir5.BundleEntry<fhir5.Patient>[]>();

  const handleChangeTab = (event: React.SyntheticEvent, newValue: TabIndex) => {
    setSelectedTab(newValue);
  };
  const [openAccordion, setOpenAccordion] = useState<boolean>(defaultOpen);

  if (
    !activeSchedule.some((item: any) => item.id === schedule?.resource?.id) &&
    (schedule?.resource.active || nextAppointments?.entry?.length)
  )
    setActiveSchedules((prevArray: any) => {
      const active = {
        id: schedule?.resource?.id,
        location: useFhirData.findActor(schedule?.resource?.actor, "Location")
          ?.display,
        healthcare: useFhirData.findActor(
          schedule?.resource?.actor,
          "HealthcareService"
        )?.display,
        practitioner: useFhirData.findActor(
          schedule?.resource?.actor,
          "Practitioner"
        )?.reference,
      };
      if (!prevArray?.some((item: any) => item.id === schedule?.resource?.id)) {
        return [...prevArray, active];
      } else {
        return prevArray;
      }
    });

  useEffect(() => {
    function isFilterTypeDay() {
      return filterType === "day" ? true : false;
    }

    function isSameYearAndMonthAsToday() {
      return (
        dayjs().isSame(dateFilter, "year") &&
        dayjs().isSame(dateFilter, "month")
      );
    }

    async function getAvailableSlots() {
      setLoading(true);
      const startDate =
        isFilterTypeDay() || isSameYearAndMonthAsToday()
          ? formatDateToISO(dateFilter)
          : formatDateToISO(dateFilter.startOf("month"));
      const endDate = isFilterTypeDay()
        ? formatDateToISO(dateFilter)
        : formatDateToISO(dateFilter.endOf("month"));

      const response: fhir5.Bundle<fhir5.Slot> = await GetSlot({
        schedule: schedule.resource.id,
        status: ["free", "busy-unavailable"],
        sort: "start",
        startDate,
        endDate,
      });

      const filteredSlots = response.entry?.filter((el) => {
        if (!el.resource?.end) return false;
        return dayjs(el.resource?.end).isAfter(dayjs());
      });

      setAvailableSlots({ ...response, entry: filteredSlots });
      setLoading(false);
    }

    async function getNextAppointments() {
      setLoading(true);

      const startDate = formatDateToISO(
        isFilterTypeDay() || isSameYearAndMonthAsToday()
          ? dateFilter
          : dateFilter.startOf("month")
      );

      const endDate = formatDateToISO(
        isFilterTypeDay() ? dateFilter : dateFilter.endOf("month")
      );

      const appointmentsBundle = await GetAppointment({
        schedule: schedule?.resource.id,
        startDate,
        endDate,
        status: ["booked"],
        includePatient: true,
        scheduling: true,
        actor: useFhirData.findActor(schedule?.resource?.actor, "Practitioner")
          ?.reference,
      });

      setNextAppointmentsPatients(
        appointmentsBundle?.entry?.filter(
          (el: any) => el?.resource?.resourceType === "Patient"
        )
      );

      const filteredAppointments = filterAppointmentBundle({
        filterRule: "gt",
        date: dayjs(),
        bundle: appointmentsBundle,
      });

      setNextAppointments(filteredAppointments);

      setLoading(false);
    }

    async function getPastAppointments() {
      setLoading(true);

      const appointmentsBundle = await GetAppointment({
        schedule: schedule?.resource.id,
        status: ["booked", "fulfilled"],
        scheduling: true,
        includePatient: true,
        actor: useFhirData.findActor(schedule?.resource?.actor, "Practitioner")
          ?.reference,
      });
      const filteredAppointments = filterAppointmentBundle({
        filterRule: "lt",
        date: dayjs(),
        bundle: appointmentsBundle,
      });
      filteredAppointments?.entry?.sort(
        (a: any, b: any) => b.resource?.id - a.resource?.id
      );

      setCompletedAppointmentsPatients(
        appointmentsBundle?.entry?.filter(
          (el: any) => el?.resource?.resourceType === "Patient"
        )
      );

      setCompletedAppointments(filteredAppointments);
      setLoading(false);
    }

    if (openAccordion) {
      switch (selectedTab) {
        case TabIndex.AvailableSlots:
          getAvailableSlots();
          return;
        case TabIndex.CompletedAppointments:
          getPastAppointments();
          return;
        case TabIndex.NextAppointments:
          getNextAppointments();
          return;
      }
    }
  }, [
    dateFilter,
    filterType,
    selectedTab,
    filterAppointmentBundle,
    schedule?.resource.id,
    tabRefetch,
    openAccordion,
    formatDateToISO,
  ]);

  useEffect(() => {
    setDateFilter(dayjs());
    switch (selectedTab) {
      case TabIndex.AvailableSlots:
        setDateLimit({ minDate: true, maxDate: false });
        return;
      case TabIndex.CompletedAppointments:
        setDateLimit({ minDate: false, maxDate: true });
        return;
      case TabIndex.NextAppointments:
        setDateLimit({ minDate: true, maxDate: false });
        return;
    }
  }, [selectedTab]);

  return (
    <Accordion
      expanded={openAccordion}
      elevation={5}

      // onChange={() => setOpenAccordion((prev) => !prev)}
    >
      <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
        <AccordionHeader
          setRefetch={setRefetch}
          id={id}
          schedule={schedule}
          setOpenAccordion={setOpenAccordion}
        />
      </AccordionSummary>

      <AccordionDetails>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={selectedTab}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <TabList variant="scrollable" onChange={handleChangeTab}>
                <Tab
                  sx={{ textTransform: "none" }}
                  label="Próximas consultas"
                  value={TabIndex.NextAppointments}
                />

                {schedule?.resource.active ? (
                  <Tab
                    sx={{ textTransform: "none" }}
                    label="Horários disponíveis"
                    value={TabIndex.AvailableSlots}
                  />
                ) : (
                  <div />
                )}
                <Tab
                  sx={{ textTransform: "none" }}
                  label="Consultas realizadas"
                  value={TabIndex.CompletedAppointments}
                />
              </TabList>

              <DateFilter
                dateLimit={dateLimit}
                dateFilter={dateFilter}
                setDateFilter={setDateFilter}
                filterType={filterType}
                setFilterType={setFilterType}
              />
            </Box>
            <TabPanel value={TabIndex.NextAppointments}>
              <NextAppointments
                patients={nextAppointmentsPatients}
                data={nextAppointments}
                setTabRefetch={setTabRefetch}
                loading={loading}
              />
            </TabPanel>
            <TabPanel value={TabIndex.AvailableSlots}>
              <AvailableSlots
                data={availableSlots}
                schedule={schedule?.resource as fhir5.Schedule}
                loading={loading}
                setTabRefetch={setTabRefetch}
              />
            </TabPanel>
            <TabPanel value={TabIndex.CompletedAppointments}>
              <AppointmentsHistory
                patients={completedAppointmentsPatients}
                data={completedAppointments}
                loading={loading}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
