import { Typography } from "@mui/material";

import {
  FormButtonGroup,
  FormPasswordInput,
  FormStack,
} from "../../../components/form";
import { ContentBoxTitle } from "../../../components/common";
import { useRegex } from "../../../utils/useRegex";
import { handleKeyPress } from "../../../utils/useKeyPress";
import { IFormInput } from "../types";
import { toast } from "react-toastify";

interface IProps {
  control: any;
  handleSubmit: Function;
  onBackButtonClick: Function;
  navigateToNextPage: Function;
}

export default function SecurityData({
  control,
  handleSubmit,
  onBackButtonClick,
  navigateToNextPage,
}: IProps) {
  const onSubmit = async (data: IFormInput) => {
    if (data.password !== data.passwordConfirm) {
      toast.error("As senhas não coincidem");
      return;
    }
    navigateToNextPage();
  };
  return (
    <form
      onKeyDown={(event) =>
        handleKeyPress(event, false, handleSubmit(onSubmit))
      }
      style={{ height: "100%", width: "100%" }}
    >
      <FormStack>
        <ContentBoxTitle>Crie uma senha</ContentBoxTitle>
        <Typography
          variant="body1"
          mb={2}
          textAlign="center"
          sx={{ color: "neutral600.main" }}
        >
          A senha deve ter mais de 8 caracteres e ser comporta por letras
          (maiúsculas e minúsculas), números e caracteres especiais
        </Typography>

        <FormPasswordInput
          name="password"
          control={control}
          label="Senha"
          required={true}
          pattern={useRegex.password}
          autoFocus
        />
        <FormPasswordInput
          name="passwordConfirm"
          control={control}
          label="Confirmar senha"
          required={true}
          pattern={useRegex.password}
        />
        <FormButtonGroup
          onNextButtonClick={handleSubmit(onSubmit)}
          onGoBackButtonClick={() => onBackButtonClick()}
        />
      </FormStack>
    </form>
  );
}
