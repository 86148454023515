import { DialogTitle, Stack, Typography } from "@mui/material";
import { CommonDialog } from "../../../components/common/CommonDialog";
import { useContractContext } from "../../../contexts/ContractContext";
import { toast } from "react-toastify";
import { FormButtonGroup } from "../../../components/form";
import { PutContract } from "../../../services/fhir/contract/PutContract";
import { Warning } from "@mui/icons-material";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

interface IProps {
  open: boolean;
  setOpen: any;
}

export function CancelPlanDialog({ open, setOpen }: IProps) {
  const { currentContract, setRefetchContract } = useContractContext();
  const resource = currentContract?.[0]?.resource;

  const handleCancelPlan = async () => {
    const replaceStatusResource: any = {
      ...resource,
      status: "cancelled",
      term: resource?.term?.map((t: any) => ({
        ...t,
        applies: {
          ...t.applies,
          end: `${dayjs().toISOString()}`,
        },
      })),
    };

    try {
      const response = await PutContract({
        contractId: `${resource?.id}`,
        resource: replaceStatusResource,
      });
      if (response?.status !== 200) {
        toast.error("Não foi possível cancelar a assinatura");
        return;
      }
    } catch (err) {
      console.log("err", err);
    }
    toast.success("Plano cancelado com sucesso!");
    setRefetchContract((prev: boolean) => !prev);
    setOpen(false);
  };

  return (
    <CommonDialog isOpen={open} handleClose={() => setOpen(false)}>
      <DialogTitle align="center">Cancelar assinatura</DialogTitle>

      <Stack
        alignItems="center"
        justifyContent="center"
        paddingY={2}
        spacing={2}
      >
        <Typography variant="subtitle2">
          Deseja confirmar o cancelamento da sua assinatura?
        </Typography>
        <Stack width="90%" direction="row" spacing={1}>
          <Warning fontSize="medium" sx={{ color: "warning500.main" }} />
          <Typography
            variant="subtitle2"
            color="neutral700.main"
            textAlign="justify"
            fontWeight={400}
          >
            Ao cancelar sua assinatura, você terá 30 dias de acesso apenas de
            leitura aos registros clínicos de seus pacientes. Por favor,
            solicite a extração de dados dos pacientes em{" "}
            <Link to="#">contato@fluxmed.com.br</Link>
          </Typography>
        </Stack>
        <Stack width="90%" direction="row" spacing={1}>
          <Warning fontSize="medium" sx={{ color: "warning500.main" }} />
          <Typography
            variant="subtitle2"
            color="neutral700.main"
            textAlign="justify"
            fontWeight={400}
          >
            Ao cancelar você não poderá criar novas agendas, porém você
            continuará podendo realizar seus atendimentos agendados neste
            período.
          </Typography>
        </Stack>
      </Stack>

      <Stack alignItems="center" justifyContent="center">
        <FormButtonGroup
          onGoBackButtonClick={() => setOpen(false)}
          onNextButtonClick={handleCancelPlan}
          goBackButtonText="Recusar"
          nextButtonText="Confirmar"
        />
      </Stack>
    </CommonDialog>
  );
}
