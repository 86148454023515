import { Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

interface IPrimaryLink {
  text: string;
  link: string;
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "inherit"
    | "overline"
    | "body1"
    | "subtitle1"
    | "subtitle2"
    | "body2";
  goBack?: boolean;
}

export function PrimaryLink({ text, link, variant, goBack }: IPrimaryLink) {
  const navigate = useNavigate();

  return (
    <Link
      to={link}
      style={{ textDecoration: "none" }}
      onClick={() => {
        goBack && navigate(-1);
      }}
    >
      <Typography
        variant={variant || "body1"}
        color="primary"
        sx={{ "&:hover": { textDecoration: "underline" } }}
      >
        {text}
      </Typography>
    </Link>
  );
}
