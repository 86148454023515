import dayjs from "dayjs";
import { Dispatch, SetStateAction } from "react";

import { GenericTable } from "../../../components/GenericTable/GenericTable";
import { AvailableSlotsActions } from "./AvailableSlotsActions";
import { useFormatter } from "../../../utils/useFormatter";
import { useFhirData } from "../../../utils/useFhirData";
import { DataSkeleton } from "../../../components/common";

interface IProps {
  data: fhir5.Bundle<fhir5.Slot> | undefined;
  schedule: fhir5.Schedule;
  loading: boolean;
  setTabRefetch: Dispatch<SetStateAction<boolean>>;
}

const columns = [
  {
    column: "Data",
    rowName: "date",
    width: "100px",
  },
  {
    column: "Hora",
    rowName: "hour",
    width: "75px",
  },
  {
    column: "Disponibilidade",
    rowName: "available",
  },
  {
    column: "Duração",
    rowName: "time",
  },
];

const statusTranslate: { [key: string]: string } = {
  free: "Disponível",
  "busy-unavailable": "Indisponível",
};

export function AvailableSlots({
  data,
  schedule,
  loading,
  setTabRefetch,
}: IProps) {
  const { getScheduleSlotDuration } = useFhirData;
  const { formatDateTimeToHour } = useFormatter;

  if (loading) {
    return <DataSkeleton />;
  }

  const schedulePractitioner = schedule?.actor.filter((el: any) =>
    el.reference.includes("Practitioner")
  );
  const actors = schedule?.actor.filter(
    (el) => !el.reference?.includes("Practitioner")
  );
  const scheduleActors = schedule?.actor;

  const slotDuration = getScheduleSlotDuration(schedule);
  const rows = data?.entry?.map(({ resource }) => {
    const date = dayjs(resource?.start)?.format("DD/MM/YYYY") || "";
    const hour = resource?.start ? formatDateTimeToHour(resource.start) : "";
    const available = resource?.status && statusTranslate[resource?.status];
    const auxUpdateSlot = resource;
    const slotTime = {
      start: resource?.start,
      end: resource?.end,
    };

    return {
      date,
      hour,
      available,
      scheduleActors,
      time: slotDuration,
      auxUpdateSlot,
      actors,
      schedulePractitioner,
      slotTime,
      setTabRefetch,
    };
  });

  return (
    <GenericTable
      tableColumns={columns}
      tableRows={rows}
      actions={(rowData: any) => <AvailableSlotsActions row={rowData} />}
    />
  );
}
