import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { isMobile } from "react-device-detect";

interface IProps {
  rows: Array<object>;
  columns: GridColDef<object>[];
  rowClick: any;
}
export function DataGridPresentation({ rows, columns, rowClick }: IProps) {
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      columnVisibilityModel={{
        id: false,
        document: !isMobile && true,
      }}
      onRowClick={rowClick}
      localeText={{
        noRowsLabel: "Nenhum registro foi encontrado.",
        footerRowSelected: () => ``,
      }}
      slotProps={{
        pagination: {
          labelRowsPerPage: "Linhas por página",
          labelDisplayedRows: (from) =>
            `${from.from} - ${from.to} de ${from.count}`,
        },
      }}
      sx={{
        "& .MuiDataGrid-row:hover": {
          cursor: "pointer",
        },
      }}
    />
  );
}
