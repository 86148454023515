import { Stack } from "@mui/material";
import dayjs from "dayjs";
import { DocumentReference } from "fhir/r5";
import { GenericTable } from "../../../../../components/GenericTable/GenericTable";
import { ActionsTableSolicitations } from "./ActionsTableSolicitations";
import { CircularLoading } from "../../../../../components/common";

export function TableSolicitations({
  solicitation,
  resultsData,
  loading,
  setBinaryUrl,
  addItem,
}: any) {
  const results = resultsData?.[0]?.entry;

  const documentReference = solicitation?.[0]?.entry?.filter(
    (el: any) => el?.resource?.resourceType === "DocumentReference"
  );
  const serviceRequest = solicitation?.[0]?.entry.filter(
    (el: any) => el?.resource?.resourceType === "ServiceRequest"
  );

  const columns = [
    {
      column: "Descrição",
      rowName: "description",
    },
    { column: "Data", rowName: "date" },
    { column: "Especialidade", rowName: "specialty" },
    { column: "Profissional solicitante", rowName: "practitioner" },
    { column: "Status", rowName: "status" },
  ];

  const rowData = documentReference
    ?.map((el: fhir5.BundleEntry<DocumentReference | any>, index: number) => {
      const auxPractitioner = el?.resource?.contained.find(
        (contained: any) => contained?.resourceType === "Practitioner"
      );
      const auxSpecialty = el?.resource?.practiceSetting?.coding?.[0]?.display;

      const auxDescription = serviceRequest?.filter((request: any) => {
        const findRef = el?.resource?.basedOn?.find((basedOn: any) =>
          basedOn?.reference.includes(request?.resource?.id)
        );
        return findRef;
      });

      const auxResults = results?.find((result: any) => {
        const findRef = auxDescription?.find(
          (service: any) =>
            service?.resource?.id ===
            result?.resource?.basedOn
              ?.find((bas: any) => bas.reference.includes("ServiceRequest"))
              ?.reference?.split("/")?.[1]
        );
        return findRef;
      });

      const status = auxResults ? "result" : "active";

      const resultUrl =
        auxResults?.resource?.content?.[0]?.attachment?.url || "";

      return {
        id: index,
        status,
        description:
          auxDescription
            ?.map(
              (serviceRequest: any) =>
                serviceRequest?.resource?.code?.concept?.coding?.[0]?.display
            )
            ?.join(" , ") || "",
        date: dayjs(el?.resource?.date).format("DD/MM/YYYY") || "-",
        specialty: auxSpecialty || "-",
        resultUrl,
        practitioner:
          auxPractitioner?.name?.[0]?.given?.[0] ||
          auxPractitioner?.identifier?.[0]?.value,
        binaryUrl: el?.resource?.content?.[0]?.attachment?.url || "",
        auxResults,
        fullResource: auxDescription?.[0]?.resource,
      };
    })
    ?.sort((a: any, b: any) => {
      const dateA = dayjs(a.date, "DD/MM/YYYY");
      const dateB = dayjs(b.date, "DD/MM/YYYY");
      return dateB.diff(dateA);
    });

  return (
    <Stack width="100%" p={2}>
      {loading ? (
        <CircularLoading />
      ) : (
        <GenericTable
          tableColumns={columns}
          tableRows={rowData}
          actions={(rowData: any) => (
            <ActionsTableSolicitations
              row={rowData}
              setBinaryUrl={setBinaryUrl}
              addItem={addItem}
            />
          )}
        />
      )}
    </Stack>
  );
}
