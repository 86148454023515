import { Stack, Typography } from "@mui/material";

interface IProps {
  header: string;
  content?: string;
  value: string;
}

export function DataPresentation({ header, content, value }: IProps) {
  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="body1" fontWeight={600}>
        {header}
      </Typography>
      <Stack direction="row" spacing={1}>
        {content && (
          <Typography variant="body2" color="neutral700.main" fontWeight={600}>
            {content}
          </Typography>
        )}
        <Typography variant="body2">{value.replaceAll(",", ", ")}</Typography>
      </Stack>
    </Stack>
  );
}
