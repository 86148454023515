import { Stack } from "@mui/material";
import React, { ReactNode } from "react";

export function FormStack({ children }: { children: ReactNode }) {
  return (
    <Stack
      width="100%"
      minHeight="100%"
      flex="1"
      justifyContent="flex-start"
      alignItems="center"
      spacing={4}
    >
      {children}
    </Stack>
  );
}
