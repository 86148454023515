import { Person } from "@mui/icons-material";
import { IFeatures } from "../../utils/interfaces";
import { Settings } from "./Settings";

import { useSessionContext } from "../../contexts/SessionContext";

export function useSettings() {
  const { access } = useSessionContext();
  const configs: IFeatures = {
    active: access?.type === "professional",
    path: "/settings",
    icon: Person,
    text: "Configurações",

    component: () => <Settings />,
  };

  return configs;
}
