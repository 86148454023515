import { toast } from "react-toastify";

import { endpoints } from "../../../configs/Settings";
import { OAuthApplication } from "../../auth/OAuthApplication";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAxios } from "../../../utils/useAxios";
import { auxiliarData } from "../AuxiliarData";
import { useFirebaseService } from "../../../utils/useFirebase";
import { isMobile } from "react-device-detect";

export async function SignUpRegister(objData: any) {
  const url = `${endpoints.SIGNUP}/create`;
  const data = auxiliarData.SignUpRegister(objData);
  const bearerToken = await OAuthApplication();

  const headers = {
    Accept: "*/*",
    Authorization: bearerToken,
    "Content-Type": "application/json",
  };

  const response = await useAxios
    .post(url, data, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Created) {
        throw new Error(`OAuthRequest error: ${response.status}`);
      }
      return { status: HTTPResponseStatus.Created, data: response.data };
    })
    .catch((error) => {
      console.error(error);
      if (error?.response.status === HTTPResponseStatus.Conflict) {
        // toast.warn("Usuário já existe");
        return { status: HTTPResponseStatus.Conflict };
      }
      toast.warn("Problema ao cadastrar usuário, tente novamente mais tarde");
      return { status: HTTPResponseStatus.BadRequest };
    });
  if (response) return response;
}
