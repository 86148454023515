import { ContentPaste, Description } from "@mui/icons-material";
import { IconButton, TableCell, Tooltip } from "@mui/material";
import { useSessionContext } from "../../../../../contexts/SessionContext";

interface IProps {
  row: any;
  handleOpen: any;
  setResponseData: any;
  openVisualizer: any;
  verifyVisualizer: any;
}

export function ObservationEncounterActions({
  row,
  handleOpen,
  setResponseData,
  openVisualizer,
  verifyVisualizer,
}: IProps) {
  const { access } = useSessionContext();

  function isEmptyObject(obj: any): boolean {
    return obj && typeof obj === "object" && Object.keys(obj).length === 0;
  }

  return (
    <TableCell align="center" sx={{ height: "75px" }}>
      {!isEmptyObject(row?.obs) && (
        <Tooltip title="Acessar">
          <IconButton
            onClick={() => {
              handleOpen();
              setResponseData(row?.obs);
            }}
          >
            <Description />
          </IconButton>
        </Tooltip>
      )}

      {verifyVisualizer &&
        verifyVisualizer(row?.encounterId) &&
        access?.type === "professional" && (
          <Tooltip title="Visualizar atendimento">
            <IconButton
              onClick={() => {
                openVisualizer(row?.encounterId);
              }}
            >
              <ContentPaste />
            </IconButton>
          </Tooltip>
        )}
    </TableCell>
  );
}
