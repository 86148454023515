import { PersonSearch } from "@mui/icons-material";
import { IFeatures } from "../../utils/interfaces";

import { useSessionContext } from "../../contexts/SessionContext";
import { Prototype } from "./Prototype";

export function usePrototype() {
  const { access } = useSessionContext();

  const configs: IFeatures = {
    active: access.type === "professional",
    path: "/clinicalregister",
    icon: PersonSearch,
    text: "Registro clínico",
    sideMenu: false,

    // avatarMenuText: "Prototipo",
    component: () => <Prototype />,
  };

  return configs;
}
