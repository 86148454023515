import { Description } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

export function CustomServiceOption({ option }: { option: any }) {
  return (
    <Stack
      width="100%"
      p={1}
      direction="row"
      justifyContent="space-between"
      key={`${option.label}-${option.value}`}
    >
      <Stack direction="row" gap={1} width="80%">
        <Description />
        <Stack>
          <Typography variant="body1" color="neutral1000.main" fontWeight={500}>
            {option.label}
          </Typography>
        </Stack>
      </Stack>

      <Stack width="10%">
        <Typography> Código TUSS: </Typography>
      </Stack>

      {option?.code && (
        <Stack>
          <Typography> {option?.code} </Typography>
        </Stack>
      )}
    </Stack>
  );
}
