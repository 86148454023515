import { Stack, Typography } from "@mui/material";
import { EditableCardRow } from "../../../components/common";

export function CardPassword({ onClick }: { onClick: Function }) {
  return (
    <Stack
      sx={{
        border: "1px solid",
        borderColor: "neutral700.main",
        borderRadius: "15px",
      }}
      width="100%"
      paddingTop={2}
    >
      <Typography
        variant="h5"
        fontWeight="500"
        paddingBottom={3}
        paddingLeft={5}
      >
        Senha
      </Typography>
      <Typography
        variant="body1"
        color="neutral700.main"
        paddingBottom={3}
        paddingLeft={5}
      >
        Uma senha segura ajuda a proteger sua conta no Fluxmed
      </Typography>
      <EditableCardRow
        title="Senha"
        titleContext="********"
        onClick={() => onClick()}
      />
    </Stack>
  );
}
