function uniqByReduce<T>(array: T[]): T[] {
  return array.reduce(
    (acc: T[], cur: T) => (acc.includes(cur) ? acc : [...acc, cur]),
    []
  );
}

export const useArray = {
  uniqByReduce,
};
