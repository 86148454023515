import { useEffect, useState } from "react";
import { Box, Stack, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import dayjs from "dayjs";

import { LargeContentBox, PageTitle } from "../../components/common";
import { PrivateMainLayout } from "../../components/layout";
import { GenericTable } from "../../components/GenericTable/GenericTable";
import { GetAccessHistory } from "../../services/consent";
import { useSessionContext } from "../../contexts/SessionContext";
import { isMobile } from "react-device-detect";

export function AccessHistory() {
  const { user } = useSessionContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState("1");
  const [data, setData] = useState([]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const columns = [
    {
      column: "CPF",
      rowName: "identifier",
    },
    { column: "Nome", rowName: "name" },
    { column: "Organização", rowName: "organization" },
    { column: "Motivo", rowName: "purposeOfUse" },
    { column: "Data", rowName: "date" },
    { column: "Hora", rowName: "hour" },
  ];

  useEffect(() => {
    function displayPurposeOfUse(
      purpose: string | undefined,
      instituicao: boolean | undefined,
      identifier: string | undefined
    ): string {
      switch (purpose) {
        case "PATRQT":
          return "Consentimento individual";
        case "PWATRNY":
          return "Responsável legal";
        case "Emergencia":
          return "Emergência";
        case "Atendimento":
          return instituicao
            ? "Atendimento"
            : identifier === user.username
            ? "Acesso pessoal"
            : "Consentimento individual";
        case "Federacao":
          return "Atendimento na rede de saúde";
        default:
          return purpose || "-";
      }
    }

    async function fecthData() {
      setLoading(true);

      const response = await GetAccessHistory(user.username);
      if (response?.data) {
        const rows = response?.data?.map((item: any) => ({
          identifier: item.sujeito.id || "-",
          name: item.sujeito.nome || "-",
          purposeOfUse: displayPurposeOfUse(
            item.contexto.motivoAcesso,
            item.contexto.instituicao,
            item.sujeito.id
          ),

          date: item.hora && dayjs(item.hora)?.format("DD/MM/YYYY"),
          hour: item.hora && dayjs(item.hora)?.format("HH:mm"),
          organization: item.contexto.instituicao || "-",
        }));
        setData(rows);
      }

      setLoading(false);
    }
    fecthData();
  }, [user.username]);

  return (
    <PrivateMainLayout
      noSidebar={isMobile}
      mobileView={isMobile}
      noPadding={isMobile}
    >
      <LargeContentBox>
        <Stack width="100%">
          <PageTitle>Quem acessou meus registros de saúde?</PageTitle>
          <TabContext value={selectedTab}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <TabList onChange={handleChangeTab}>
                <Tab
                  style={{ display: "flex", alignItems: "center" }}
                  label=" Histórico de acessos"
                  value="1"
                />

                <Tab
                  style={{ display: "flex", alignItems: "center" }}
                  label="Meu histórico"
                  value="2"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <GenericTable
                tableColumns={columns}
                tableRows={data?.filter(
                  (el: any) => el.identifier !== user?.username
                )}
              />
            </TabPanel>
            <TabPanel value="2">
              <GenericTable
                tableColumns={columns}
                tableRows={data?.filter(
                  (el: any) => el.identifier === user?.username
                )}
              />
            </TabPanel>
          </TabContext>
        </Stack>
      </LargeContentBox>
    </PrivateMainLayout>
  );
}
