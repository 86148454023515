import {
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../../../components/common";
import { PostDocumentReference } from "../../../services/fhir/patientsummary/documentreference/PostDocumentReference";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import { Close, Info } from "@mui/icons-material";
import { PostResultJson } from "../../../services/fhir/patientsummary/documentreference/PostResultJson";
import { useEffect } from "react";

export function ConfirmDocumentDialog({
  fileBase64,
  setRefetch,
  isOpenDialog,
  onCloseDialog,
  setRenderButton,
  data,
  encounterReference,
  basedOn,
}: any) {
  const basedOnReference = basedOn?.map((el: any) => ({
    reference: el.reference,
  }));

  const handleConfirm = async () => {
    try {
      await PostResultJson(data, encounterReference, basedOnReference);
      toast.success("Resultado de exame, enviado com sucesso!");
      if (setRenderButton) setRenderButton(false);
      setRefetch((prev: boolean) => !prev);
    } catch (err) {
      console.log("PostDocument", err);
      toast.warn("Não foi possível finalizar a operação, tente novamente");
    } finally {
      onCloseDialog();
    }
  };

  const handleDialogClose = (event: any, reason: string) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      onCloseDialog(event, reason);
    }
  };

  const base64ToBlob = (base64: any, contentType = "") => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const verifyDocSize = (base64String: any, limit: number) => {
    const sizeInBytes = (base64String.length * 3) / 4;

    const limitInBytes = limit * 1024 * 1024;

    return sizeInBytes > limitInBytes;
  };

  async function downloadBase64File() {
    try {
      const blob = base64ToBlob(data?.base64, "application/pdf");
      const fileUrl = URL.createObjectURL(blob);
      const auxFile = {
        fileUrl,
        fileName: "Documento.pdf",
      };

      window.open(auxFile.fileUrl, "_blank");
      // setDataRow(e.row);
      // setOpenInformation(true);
    } catch (err) {
      console.log("err", err);
    }
  }

  useEffect(() => {
    if (verifyDocSize(data?.base64, 1.5)) {
      toast.info(
        "O arquivo selecionado excede o limite de 1.5MB permitido pela plataforma."
      );
      onCloseDialog();
    }
  }, [data]);

  return (
    <Dialog
      open={isOpenDialog}
      onClose={handleDialogClose}
      PaperProps={{
        sx: { padding: "8px 10px" },
      }}
      maxWidth="md"
      fullScreen={isMobile}
    >
      <Stack width={{ xs: "100%", md: "700px" }}>
        <Stack mb={5}>
          <IconButton
            onClick={() => onCloseDialog(null, "iconButtonClick")}
            sx={{ position: "absolute", right: 5, top: 5 }}
          >
            <Close />
          </IconButton>

          <DialogTitle textAlign="center">Adicionar arquivo</DialogTitle>

          {verifyDocSize(fileBase64, 1) ? (
            <Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Info sx={{ color: "#0000a7" }} />
                <Typography textAlign="justify">
                  O arquivo selecionado excede o tamanho limite de
                  pre-visualização.
                </Typography>
                <Typography
                  onClick={() => {
                    downloadBase64File();
                  }}
                  variant={"body1"}
                  color="primary"
                  sx={{
                    "&:hover": {
                      textDecoration: "underline",
                      cursor: "pointer",
                    },
                  }}
                >
                  Clique aqui para visualizar
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <iframe
              title="addFile"
              src={fileBase64}
              style={{
                height: "700px",
                maxHeight: "70vh",
                width: "100%",
              }}
            />
          )}
        </Stack>

        <Stack direction="row" justifyContent="space-between" gap={2} mt={1}>
          <SecondaryButton
            width="250px"
            height="50px"
            onClick={() => onCloseDialog()}
            id="goBackButton"
          >
            Voltar
          </SecondaryButton>

          <PrimaryButton width="250px" height="50px" onClick={handleConfirm}>
            Confirmar
          </PrimaryButton>
        </Stack>
      </Stack>
    </Dialog>
  );
}
