import { ChevronRight } from "@mui/icons-material";
import { Divider, IconButton, Stack, Typography } from "@mui/material";

interface IProps {
  text: string;
  textAuxiliar?: string;
  onClick: any;
  icon: any;
  transparent?: boolean;
  noPadding?: boolean;
}

export function RegisterDataItens({
  text,
  textAuxiliar,
  onClick,
  icon,
  transparent,
  noPadding,
}: IProps) {
  return (
    <Stack width="100%" marginY={noPadding ? 0 : 1}>
      <IconButton
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          paddingX: 0,
          paddingRight: 1,
          gap: 1,
        }}
        onClick={onClick}
      >
        <Stack height="100%">{icon}</Stack>
        <Stack
          flexDirection="column"
          alignSelf="flex-start"
          width="100%"
          gap={1}
        >
          <Typography
            variant="body1"
            color={transparent ? "neutral600.main" : "neutral700.main"}
            fontWeight={600}
            textAlign="left"
          >
            {text}
          </Typography>
          {textAuxiliar && (
            <Typography
              variant="body1"
              color="neutral600.main"
              textAlign="left"
            >
              {textAuxiliar}
            </Typography>
          )}
        </Stack>
        <ChevronRight />
      </IconButton>
      <Divider sx={{ width: "100%", background: "neutral400.main" }} />
    </Stack>
  );
}
