import { Divider, IconButton, Stack, Tooltip } from "@mui/material";
import {
  Description,
  FileDownload,
  RemoveRedEye,
  Share,
} from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import { ScreenMobile } from "../../../components/common/ScreenMobile";
import { CircularLoading, SecondaryButton } from "../../../components/common";

import { useEffect, useState } from "react";
import { GetBinary } from "../../../services/fhir/patientsummary/Binary/GetBinary";
import { IframeVisualization } from "../../medicalprescription/components/IframeVisualization";
import { BtnFileUpload } from "./BtnFileUpload";
import dayjs from "dayjs";
import { useSessionContext } from "../../../contexts/SessionContext";
import { ConfirmDocumentDialog } from "./ConfirmDocumentDialog";
import { IframeDialog } from "../../ehrrecords/components/IframeDialog";

interface IProps {
  openInformation?: boolean;
  handleClose?: VoidFunction;
  data?: any;
  setRefetch: any;
}

export function ViewPrescription({
  openInformation,
  handleClose,
  setRefetch,
  data,
}: IProps) {
  const { user } = useSessionContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [base64, setBase64] = useState<any>();
  const [fileBase64, setFileBase64] = useState<any>();
  const [dialogResults, setDialogResults] = useState<boolean>(false);
  const [renderButton, setRenderButton] = useState<boolean>(true);

  function onCloseDialog() {
    setFileBase64(undefined);
  }

  function downloadBase64File() {
    const linkSource = `data:${base64?.contentType};base64,${base64?.data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = "Solicitção de exame";
    downloadLink.click();
  }

  useEffect(() => {
    async function getDocumentPdf() {
      const response = await GetBinary({ urlBinary: data?.binaryUrl });
      setBase64(response);
    }
    if (data?.binaryUrl) {
      getDocumentPdf();
    }
    setLoading(false);
  }, [data]);

  const docReferenceData = {
    servicePratice: {
      code: data?.fullResource?.practiceSetting?.coding?.[0]?.code,
      system: data?.fullResource?.practiceSetting?.coding?.[0]?.system,
      display: data?.fullResource?.practiceSetting?.coding?.[0]?.display,
    },
    time: {
      start: dayjs().toISOString(),
      end: dayjs().toISOString(),
    },
  };

  const refs = {
    id: data?.fullResource?.context
      ?.find((el: any) => el?.reference?.includes("Encounter"))
      ?.reference?.split("/")?.[1],
    subject: user?.username,
    appointmentId: data?.fullResource?.context
      ?.find((el: any) => el?.reference?.includes("Appointment"))
      ?.reference?.split("/")?.[1],
  };

  return (
    <>
      {isMobile ? (
        <ScreenMobile
          title="Documento"
          open={openInformation ? openInformation : false}
          onClose={handleClose}
        >
          <Stack
            gap={1}
            mt={1}
            width="100%"
            height="100%"
            maxHeight="500px"
            direction="column"
          >
            <Stack minHeight="450px" height="100%" width="100%">
              <IframeVisualization
                data={`data:${base64?.contentType};base64,${base64?.data}`}
              />
            </Stack>
            <SecondaryButton
              width="100%"
              height="42px"
              onClick={() => downloadBase64File()}
            >
              <FileDownload /> Baixar
            </SecondaryButton>

            {!data.auxResults && (
              <>
                {!fileBase64 && renderButton && (
                  <BtnFileUpload setFileBase64={setFileBase64} />
                )}
              </>
            )}

            {data?.auxResults ? (
              <Stack direction="row" alignItems="center">
                Resultado:
                <Stack width="fit-content">
                  <Tooltip title="Resultado de exame">
                    <IconButton onClick={() => setDialogResults(true)}>
                      <Description />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            ) : (
              <Divider
                sx={{
                  width: "100%",
                  background: "neutral400.main",
                  marginTop: 3,
                }}
              />
            )}
          </Stack>
        </ScreenMobile>
      ) : (
        <>
          {base64?.data && !loading ? (
            <IframeVisualization
              data={`data:${base64?.contentType};base64,${base64?.data}`}
            />
          ) : (
            <CircularLoading />
          )}
          {!data.auxResults && (
            <>
              {!fileBase64 && renderButton && (
                <BtnFileUpload setFileBase64={setFileBase64} />
              )}
            </>
          )}
          <Stack width="100%" direction="row" gap={1} margin="24px 0px 16px">
            <SecondaryButton
              width="50%"
              height="42px"
              onClick={() => downloadBase64File()}
            >
              <FileDownload /> Baixar
            </SecondaryButton>

            <SecondaryButton width="50%" height="42px" onClick={() => {}}>
              <Share /> Compartilhar
            </SecondaryButton>
          </Stack>
          {data?.auxResults ? (
            <Stack direction="row" alignItems="center">
              Resultado:
              <Stack width="fit-content">
                <Tooltip title="Resultado de exame">
                  <IconButton onClick={() => setDialogResults(true)}>
                    <Description />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          ) : (
            <Divider
              sx={{
                width: "100%",
                background: "neutral400.main",
                marginTop: 3,
              }}
            />
          )}
        </>
      )}

      {dialogResults && (
        <IframeDialog
          isOpen={dialogResults}
          handleClose={() => setDialogResults(false)}
          data={data?.auxResults?.resource?.content?.[0]?.attachment?.url || ""}
        />
      )}

      {fileBase64 && (
        <ConfirmDocumentDialog
          setRenderButton={setRenderButton}
          setRefetch={setRefetch}
          fileBase64={fileBase64}
          isOpenDialog={fileBase64}
          onCloseDialog={() => onCloseDialog()}
          basedOn={data?.fullResource?.basedOn?.filter((el: any) =>
            el.reference?.includes("ServiceRequest")
          )}
          encounterReference={refs}
          data={{
            ...docReferenceData,
            base64: fileBase64?.split(",")?.[1],
            codeValue: "RESULT",
            codeDisplay: "Resultado de exame",
            categoryValue: "resultado",
            categoryDisplay: "Resultado de exame",
          }}
        />
      )}
    </>
  );
}
