import { Box, Button } from "@mui/material";
import { useFieldArray } from "react-hook-form";
import { RenderInputFieldsItem } from "./components/Render/RenderInputFieldsItem";

const defaultValues = {};

type ITextProps = {
  control: any;
  register: any;
  setValue?: any;
  getValues?: any;
  item: any;
  watchedValues?: any;
};

export default function FieldsArrayItemQuestionnaire({
  control,
  item,
  watchedValues,
}: ITextProps) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  return (
    <>
      {fields.map((component, index) => {
        return (
          <Box key={component.id}>
            {item?.item &&
              item?.item?.map((subItem: any, subItemIndex: number) =>
                RenderInputFieldsItem({
                  item: subItem,
                  index: subItemIndex,
                  key: component.id,
                  control,
                })
              )}

            <Box>
              <Button
                onClick={() => {
                  remove(index);
                  control.unregister(`items.${index}.name`);
                }}
                variant="outlined"
                sx={{ width: "100%", marginBottom: "48px" }}
              >
                Remover
              </Button>
            </Box>
          </Box>
        );
      })}

      {item.repeats && (
        <Button onClick={() => append({ name: "" })} variant="outlined">
          Novo Item
        </Button>
      )}
    </>
  );
}
