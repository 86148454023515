import { Box, Stack } from "@mui/material";
import { OptionsButtons } from "./compontents/OptionsButtons";
import { useManagerContext } from "../../../../../../contexts/ManagerContext";
import { FilterOptions } from "./compontents/FilterOptions";
import { FormDatePicker } from "../../../../../../components/form";
import dayjs from "dayjs";
import { FutureViewer } from "./compontents/FutureViewer";
import { NextAppointmentsDialog } from "../../../../../schedulemanager/components/NextAppointmentsDialog";
import { useState } from "react";
import { PastViewer } from "./compontents/PastViewer";
import { AvailableSlotDialog } from "../../../../../schedulemanager/components/AvailableSlotDialog";
import { SlotsViewer } from "./compontents/SlotsViewer";
import { CreateAppointmentDialog } from "../../../../../schedulemanager/components/creatappointmentdialog";
import { FiltersApply } from "./compontents/FiltersApply";
import { NoScheduleOptions } from "../../../../NoScheduleOptions";

export function ManagerDashboard({ schedule }: any) {
  const { tabValue, setTabValue, control, selectedRow } = useManagerContext();

  const [dialogCancelAppointment, setDialogCancelAppointment] =
    useState<boolean>(false);

  const [availableDialogOpen, setAvailableDialogOpen] =
    useState<boolean>(false);

  const [createAppointmentDialogOpen, setCreateAppointmentDialogOpen] =
    useState<boolean>(false);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "neutral0.main",
          borderRadius: "20px",
          mb: 2,
          padding: "20px 20px",
          pb: 4,
        }}
      >
        {!schedule ? (
          <NoScheduleOptions />
        ) : (
          <>
            <Stack spacing={2}>
              <Stack
                direction="row"
                alignItems="center"
              >
                <OptionsButtons value={tabValue} setValue={setTabValue} />
              </Stack>

              <Stack
                direction="row"
                // justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                <Stack sx={{ maxWidth: "150px" }}>
                  <FormDatePicker
                    defaultValue={dayjs()}
                    name="dia"
                    label="Data"
                    maxDate={tabValue === 2 ? dayjs() : undefined}
                    minDate={tabValue !== 2 ? dayjs() : undefined}
                    control={control}
                  />
                </Stack>
                <FilterOptions />
              </Stack>
            </Stack>
            <FiltersApply />

            {tabValue === 0 && (
              <FutureViewer
                setDialogCancelAppointment={setDialogCancelAppointment}
              />
            )}

            {tabValue === 1 && (
              <SlotsViewer
                setAvailableDialogOpen={setAvailableDialogOpen}
                setCreateAppointmentDialogOpen={setCreateAppointmentDialogOpen}
              />
            )}

            {tabValue === 2 && <PastViewer />}
          </>
        )}

        {createAppointmentDialogOpen && (
          <CreateAppointmentDialog
            isOpen={createAppointmentDialogOpen}
            setModalOpen={setCreateAppointmentDialogOpen}
            row={selectedRow}
            walkin={!selectedRow}
            cpfT={""}
          />
        )}

        {dialogCancelAppointment && selectedRow && (
          <NextAppointmentsDialog
            setModalOpen={setDialogCancelAppointment}
            open={dialogCancelAppointment}
            row={selectedRow}
          />
        )}

        {availableDialogOpen && selectedRow && (
          <AvailableSlotDialog
            setModalOpen={setAvailableDialogOpen}
            open={availableDialogOpen}
            row={selectedRow}
          />
        )}
      </Box>
    </>
  );
}
