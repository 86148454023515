import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { Delete, Edit } from "@mui/icons-material";
import { useState } from "react";
import { toast } from "react-toastify";
import { HTTPResponseStatus } from "../../../../utils/enum";
import { PatchEHRPoster } from "../../../../services/fhir/patientsummary/PatchEHRPoster";
import { ModalConfirm } from "./components/ModalConfirm";
import { GetMedication } from "../../../../services/terminology";

export function TratamentoHistoryAccordion({
  data,
  index,
  reset,
  setEditTreatment,
  setValue,
}: any) {
  const status: any = {
    cancelled: "Cancelado",
    proposed: "Pendente",
    pending: "Pendente",
    arrived: "Confirmado",
    booked: "Confirmado",
    valid: "Ativo",
    expired: "Expirado",
    stopped: "Interrompido",
    active: "Ativo",
    draft: "Solicitado",
    completed: "Finalizado",
    result: "Realizado",
  };

  const statusColor: any = {
    cancelled: "Cancelado",
    proposed: "Pendente",
    pending: "Pendente",
    arrived: "Confirmado",
    booked: "Confirmado",
    valid: "Ativo",
    expired: "Expirado",
    stopped: "Interrompido",
    active: "neutral500.main",
    draft: "#DAA520",
    completed: "#3CB371",
    result: "Realizado",
  };

  const { loadingTreatments, setRefetch } = useMedicalCareContext();
  const [modalConfirmDelete, setModalConfirmDelete] = useState<boolean>(false);
  const [auxDataDelete, setAuxDataDelete] = useState<any>();
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);

  const handleDeleteClick = async (data: any) => {
    setLoadingDelete(true);
    try {
      const response = await PatchEHRPoster({
        resourceType: "MedicationRequest",
        resourceId: data?.id,
        operation: "replace",
        path: "/status",
        value: "entered-in-error",
      });
      if (response && response.status === HTTPResponseStatus.Success) {
        toast.success("Medicamento removido com sucesso!");
      }
    } catch (error) {
      console.error("Delete error:", error);
      toast.error("Um erro ocorreu ao tentar remover registro.");
    } finally {
      setModalConfirmDelete(false);
      setLoadingDelete(false);
    }
    setRefetch(true);
  };

  const handleEditClick = async (editTreatment: any) => {
    setEditTreatment(editTreatment);
    const edit = editTreatment?.fullResource;

    const codeAux = edit?.medication?.concept?.coding?.[0]?.code;

    try {
      const response = await GetMedication(null, undefined, codeAux);
      console.log("response", response?.data?.entry?.[0]?.resource);

      if (editTreatment) {
        setValue("medication", {
          name: edit?.medication?.concept?.text,
          label:
            response?.data?.entry.length === 1
              ? `${
                  response?.data?.entry?.[0]?.resource?.extension?.find(
                    (el: any) => el?.url.includes("CMEDProduto")
                  ).valueString
                } - ${
                  response?.data?.entry?.[0]?.resource?.extension?.find(
                    (el: any) => el?.url.includes("CMEDApresentacao")
                  ).valueString
                }`
              : edit?.medication?.concept?.text,
          value: codeAux,
        });
        setValue(
          "quantity",
          edit?.dosageInstruction?.[0]?.doseAndRate?.[0]?.doseQuantity?.value
        );
        setValue("form", {
          name: edit?.dosageInstruction?.[0]?.doseAndRate?.[0]?.doseQuantity
            ?.unit,
          label:
            edit?.dosageInstruction?.[0]?.doseAndRate?.[0]?.doseQuantity?.unit,
          value:
            edit?.dosageInstruction?.[0]?.doseAndRate?.[0]?.type?.coding?.[0]
              ?.code,
        });
        setValue("frequency", {
          name: `${edit?.dosageInstruction[0].timing.repeat.period} h`,
          label: `${edit?.dosageInstruction[0].timing.repeat.period} h`,
          value: edit?.dosageInstruction[0].timing.repeat.period,
        });
        setValue("time", {
          name: `${edit?.dosageInstruction?.[0]?.timing?.repeat?.duration} dia(s)`,
          label: `${edit?.dosageInstruction?.[0]?.timing?.repeat?.duration} dia(s)`,
          value: edit?.dosageInstruction?.[0]?.timing?.repeat?.duration,
        });
        setValue("note", edit?.note?.[0]?.text);
        setValue("specialControl", editTreatment?.specialControl);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      {loadingTreatments && index === 0 ? (
        <Box
          sx={{
            width: "100%",
            border: "1px solid #ccc",
            borderRadius: 1,
            padding: 2,
          }}
        >
          <Skeleton
            variant="text"
            width={"100%"}
            sx={{ fontSize: "1rem", mb: 2 }}
          />
          <Skeleton variant="text" width={"100%"} sx={{ fontSize: "1rem" }} />
          <Stack direction="row" spacing={12} sx={{ my: 2 }}>
            <Skeleton variant="rounded" width={"25%"} height={32} />
          </Stack>

          <Divider></Divider>
          <Stack direction="row" spacing={2}>
            <Skeleton
              variant="text"
              width={"75%"}
              sx={{ fontSize: "1.2rem" }}
            />
            <Skeleton variant="text" width={"25%"} sx={{ fontSize: "1rem" }} />
          </Stack>
        </Box>
      ) : (
        <>
          {" "}
          <Accordion
            defaultExpanded={true}
            expanded={true}
            sx={{
              boxShadow: "none",
              border: "1px solid #ccc",
              borderRadius: 1,
              borderColor: statusColor?.[data?.status],
            }}
          >
            <AccordionSummary aria-controls="panel1-content" id="panel1-header">
              <Stack width="100%" spacing={1}>
                {data?.description && (
                  <Stack
                    direction="row"
                    width="100%"
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography color={"neutral700.main"} fontWeight={500}>
                      {data?.description}
                    </Typography>
                    <Stack direction="row">
                      {/* <Tooltip title="Editar">
                        <IconButton
                          sx={{ color: "neutral1000.main" }}
                          onClick={() => handleEditClick(data)}
                        >
                          <Edit fontSize="small" />
                        </IconButton>
                      </Tooltip> */}
                      <Tooltip title="Remover">
                        <IconButton
                          onClick={() => {
                            setEditTreatment(null);
                            reset();
                            setAuxDataDelete(data);
                            setModalConfirmDelete(true);
                          }}
                        >
                          <Delete
                            fontSize="small"
                            sx={{ color: "error700.main" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Stack>
                {data?.apresentação && (
                  <Stack direction="row" width="100%" spacing={1}>
                    <Typography fontWeight={500}>Apresentação </Typography>
                    <Typography>{data?.apresentação}</Typography>
                  </Stack>
                )}
              </Stack>
              <Stack spacing={1} mb={2}>
                {data?.dose && (
                  <Stack direction="row" width="100%" spacing={1}>
                    <Typography fontWeight={500}>Posologia: </Typography>
                    <Typography>{data?.dose}</Typography>
                  </Stack>
                )}
              </Stack>
              <Stack direction="row" justifyContent={"space-between"} mb={1}>
                <Stack direction="column">
                  <Typography color={"neutral700.main"} fontSize={12} mx={1}>
                    Status:
                  </Typography>
                  <Chip
                    label={status?.[data?.status]}
                    size="small"
                    variant="outlined"
                    sx={{
                      width: "auto",
                      fontWeight: 500,
                      fontSize: 12,
                      borderRadius: 2,
                      borderColor: statusColor?.[data?.status],
                    }}
                  />
                </Stack>
              </Stack>

              {data?.note ? (
                <Typography
                  style={{
                    backgroundColor: "#eeeeee",
                    color: "#757575",
                    padding: 8,
                    borderRadius: 8,
                    maxWidth: "100%",
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                  }}
                >
                  {data?.note}
                </Typography>
              ) : (
                <></>
              )}
              {/* <Divider sx={{ my: 2 }}></Divider>
        <Stack
          mt={2}
          direction="row"
          width="100%"
          alignItems="center"
          spacing={2}
        >
          {data?.displayInfo?.practitioner && (
            <Typography
              color="neutral700.main"
              sx={{ fontWeight: 500, fontStyle: "italic", fontSize: 12 }}
            >
              Dr. {data?.displayInfo?.practitioner}
            </Typography>
          )}

          {data?.displayInfo?.healthcare && (
            <Chip
              label={data?.displayInfo?.healthcare || "Sem especialidade"}
              size="small"
              sx={{
                color: data?.displayInfo?.healthcare
                  ? "neutral700.main"
                  : "neutral500.main",

                fontWeight: 500,
                backgroundColor: data?.displayInfo?.healthcare ? "" : "#f1f1f1",
              }}
            />
          )}
        </Stack> */}
            </AccordionDetails>
          </Accordion>
        </>
      )}
      {modalConfirmDelete && (
        <ModalConfirm
          data={data}
          open={modalConfirmDelete}
          setModalOpen={setModalConfirmDelete}
          loading={loadingDelete}
          handleDelete={() => handleDeleteClick(auxDataDelete)}
        />
      )}
    </>
  );
}
