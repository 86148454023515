import { Stack, Typography } from "@mui/material";

interface IProps {
  title: string;
  text: string;
}

export function LineInformationScheduleMobile({ title, text }: IProps) {
  return (
    <Stack direction="row" gap={2} padding={1}>
      <Typography variant="body1" fontWeight={600} color="neutral700.main">
        {title}
      </Typography>
      <Typography variant="body1" color="neutral700.main">
        {text}
      </Typography>
    </Stack>
  );
}
