import React from "react";
import { LargeContentBox } from "../../../components/common";
import { Skeleton, Stack } from "@mui/material";

export function LoadingContent() {
  return (
    <LargeContentBox>
      <Stack width="100%" spacing={4}>
        <Skeleton variant="text" sx={{ fontSize: "32px" }} />
        <Skeleton variant="rounded" width="100%" height="100%" />
      </Stack>
    </LargeContentBox>
  );
}
