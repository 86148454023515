import { useState } from "react";
import { GetBinary } from "../../../services/fhir/patientsummary/Binary/GetBinary";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { Article, Download } from "@mui/icons-material";
import { IframeDialog } from "../../ehrrecords/components/IframeDialog";

interface IProps {
  row: any;
}
export function MedicalNoteActions({ row }: IProps) {
  const [base64, setBase64] = useState<any>();
  const [openDocument, setOpenDocument] = useState<boolean>(false);

  async function getDocumentPdf() {
    const response = await GetBinary({ urlBinary: row?.binaryUrl });
    setBase64(response);
    return response;
  }

  async function downloadBase64File() {
    const auxDocument = !base64 ? await getDocumentPdf() : base64;
    const linkSource = `data:${auxDocument?.contentType};base64,${auxDocument?.data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = "Atestado médico";
    downloadLink.click();
  }

  return (
    <Stack width="fit-content">
      <Stack direction="row" spacing={1}>
        <IconButton onClick={() => setOpenDocument(true)}>
          <Tooltip title="Visualizar documento">
            <Article />
          </Tooltip>
        </IconButton>
        <IconButton onClick={() => downloadBase64File()}>
          <Tooltip title="Baixar">
            <Download />
          </Tooltip>
        </IconButton>
      </Stack>
      {openDocument && (
        <IframeDialog
          handleClose={() => setOpenDocument(false)}
          isOpen={openDocument}
          data={row?.binaryUrl}
          title={row?.description}
          loadedBase64={base64}
          setLoaded={setBase64}
        />
      )}
    </Stack>
  );
}
