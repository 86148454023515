import { Share } from "@mui/icons-material";
import { useSessionContext } from "../../contexts/SessionContext";
import { IFeatures } from "../../utils/interfaces";
import { Consents } from "./Consents";

export function useConsent() {
  const { access } = useSessionContext();

  const configs: IFeatures = {
    active: access?.type === "patient",
    text: "Compartilhamentos",
    path: "/consent",
    icon: Share,

    // sideMenu: true,
    sideMenuIndex: 1,

    component: () => <Consents />,
  };

  return configs;
}
