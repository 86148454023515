import { Autorenew, ContentPaste, Info, Edit } from "@mui/icons-material";
import { IconButton, TableCell, Tooltip } from "@mui/material";
import { GetAppointment } from "../../../../../services/fhir";
import { useFhirData } from "../../../../../utils/useFhirData";
import { useSessionContext } from "../../../../../contexts/SessionContext";
import { useOrganizationContext } from "../../../../../contexts/OrganizationContext";
import { useClinicalRegisterContext } from "../../../../../contexts/ClinicalRegisterContext";

interface IProps {
  row: any;
  openVisualizer: any;
  verifyVisualizer: any;
}

export function ClinicalEncounterActions({
  row,
  openVisualizer,
  verifyVisualizer,
}: IProps) {
  const { user } = useSessionContext();
  const { organization } = useOrganizationContext();
  const { verifyEncounter } = useClinicalRegisterContext();

  const practitioner = useFhirData?.findParticipant(
    row?.participant,
    "Practitioner"
  );

  return (
    <TableCell align="center" sx={{ height: "75px" }}>
      {verifyVisualizer && verifyVisualizer(row?.encounterId) ? (
        <>
          <Tooltip title="Visualizar atendimento">
            <IconButton
              onClick={() => {
                openVisualizer(row?.encounterId);
              }}
            >
              <ContentPaste />
            </IconButton>
          </Tooltip>

          {practitioner?.reference?.includes(user?.username) &&
            row?.organizationId === organization?.id?.split("-")?.[1] && (
              <Tooltip title="Editar atendimento">
                <IconButton
                  onClick={async () => {
                    const appointment = await GetAppointment({
                      ehrrunner: row?.subject,
                      _id: row?.appointmentId,
                    });

                    if (appointment?.entry?.[0]?.resource)
                      await verifyEncounter(appointment?.entry?.[0]?.resource);
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
        </>
      ) : (
        <>
          {row?.status !== "completed" && (
            <>
              <Tooltip title="Este atendimento não foi finalizado corretamente. Lembre-se de concluir os atendimentos futuros clicando no botão de finalização.">
                <IconButton onClick={() => {}}>
                  <Info sx={{ color: "#0000a7" }} />
                </IconButton>
              </Tooltip>

              {verifyVisualizer &&
                !verifyVisualizer(row?.encounterId) &&
                practitioner?.reference?.includes(user?.username) &&
                row?.organizationId === organization?.id?.split("-")?.[1] && (
                  <Tooltip title="Retomar atendimento">
                    <IconButton
                      onClick={async () => {
                        const appointment = await GetAppointment({
                          ehrrunner: row?.subject,
                          _id: row?.appointmentId,
                        });

                        if (appointment?.entry?.[0]?.resource)
                          await verifyEncounter(
                            appointment?.entry?.[0]?.resource
                          );
                      }}
                    >
                      <Autorenew />
                    </IconButton>
                  </Tooltip>
                )}
            </>
          )}
        </>
      )}
    </TableCell>
  );
}
