import { Stack, Typography } from "@mui/material";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../../../../../components/common";
import { MedicationTypeTabs } from "./MedicationTypeTabs";
import { PostMedicationStatement } from "../../../../../../../../services/fhir/patientsummary/medicationstatement/PostMedicationStatement";
import { toast } from "react-toastify";
import { useSessionContext } from "../../../../../../../../contexts/SessionContext";
interface IProps {
  methods: any;
  references: any;
  handleClose: any;
  setRefetch: any;
}
export function CreateMedicationStatementForm({
  methods,
  references,
  handleClose,
  setRefetch,
}: IProps) {
  const { control, handleSubmit, setValue } = methods;
  const { user } = useSessionContext();

  const onSubmit = async (data: any) => {
    if (data && references) {
      try {
        await PostMedicationStatement(
          {
            ...data,
            practitionerId: user?.username,
            practitionerName: user?.name,
          },
          references
        );
        setRefetch((prev: boolean) => !prev);
      } catch (err) {
        console.log("PostAllergy", err);
        toast.error("Não foi possível registrar a alergia, tente novamente");
      } finally {
        handleClose();
      }
    }
  };

  return (
    <Stack width="850px" rowGap={4}>
      <Typography variant="h5" fontWeight={600}>
        Adicionar medicamento
      </Typography>
      <Typography>Adicione medicamentos informados pelo paciente</Typography>

      <Stack>
        <MedicationTypeTabs control={control} setValue={setValue} />
      </Stack>
      <Stack
        width="100%"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <SecondaryButton
          onClick={handleClose}
          width="fit-content"
          height="40px"
        >
          Cancelar
        </SecondaryButton>

        <PrimaryButton
          onClick={handleSubmit(onSubmit)}
          width="fit-content"
          height="40px"
        >
          Salvar
        </PrimaryButton>
      </Stack>
    </Stack>
  );
}
