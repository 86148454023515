import { useSessionContext } from "../../contexts/SessionContext";
import { IFeatures } from "../../utils/interfaces";

import { currentFeatures } from "../../configs/Settings";

import { Note } from "@mui/icons-material";
import { EHRRecords } from "./EHRRecords";

export function useEHRRecords() {
  const { access } = useSessionContext();

  const configs: IFeatures = {
    active: !currentFeatures.register,
    path: "/summary",
    icon: Note,
    text: "Registros eletrônicos",

    sideMenu: false,
    avatarMenu: false,

    component: () => <EHRRecords />,
  };

  return configs;
}
