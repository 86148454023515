import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { FormAutoComplete, FormTextInput } from "../../../../components/form";
import { SubmitItemButton } from "./SubmitItemButton";
import { useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CustomMedicationOption } from "./CustomMedicationOption";
import { HelpOutline } from "@mui/icons-material";

interface IProps {
  methods: any;
  isEdit: boolean;
  action: Function;
}

const mockedItens = [
  {
    name: "Ômega 3",
    tag: "BR",
  },
  {
    name: "Probióticos",
    tag: "CE",
  },
  {
    name: "Creatina",
    tag: "AM",
  },
  {
    name: "Vitamina D",
    tag: "AZ",
  },
];

const unit = [
  { value: 1, label: "Capsula(s)" },
  { value: 2, label: "Gota(s)" },
  { value: 3, label: "Comprimido(s)" },
];

export function SupplementTab({ methods, action, isEdit }: IProps) {
  const [isDescriptionOpen, setIsDescriptionOpen] = useState<boolean>(false);
  const { control, reset, handleSubmit, setValue } = methods;

  let supp = useWatch({ name: "supplement", control });

  const supplementOptions = mockedItens?.map((el: any, index: number) => {
    return {
      ...el,
      label: el.name,
      value: index,
    };
  });

  function extrairNumero(string: string) {
    const match = string?.match(/\d+/);
    return match ? Number(match[0]) : null;
  }

  const onSubmit = async (data: any) => {
    try {
      if (!data.supplement) {
        toast.warn("Selecione algum item pra efetuar a prescrição");
        throw new Error("Formulário vazio");
      } else {
        const auxForm = [
          data?.quantity && `${data?.quantity}`,
          data?.form?.label && `${data?.form?.label}`,
          data?.frequency?.label && `a cada ${data?.frequency?.label}`,
          data?.time?.label && `por ${data?.time?.label}`,
        ]
          ?.filter((e: string) => e)
          ?.join(" ");
        const auxItem = {
          name: data?.supplement?.name,
          tag: data?.supplement?.tag,
          inputValue:
            supplementOptions.find(
              (e: any) => e.name === data.supplement.name
            ) ||
            {
              label: data?.supplement,
              value: data?.supplement,
            }.label,
          quantity: data?.quantity,
          frequency: data?.frequency,
          formValue: data?.form,
          time: data?.time,
          note: data?.note,
          form: auxForm,
          type: "supplement",
          tabValue: "2",
        };
        action(auxItem);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsDescriptionOpen(false);
      reset();
    }
  };

  useEffect(() => {
    if (supp) setIsDescriptionOpen(true);
  }, [supp]);

  return (
    <Stack gap={1}>
      <FormAutoComplete
        required
        freeSolo
        name="supplement"
        onInputChange={(e: any) => {
          if (e?.target?.value?.length)
            setValue("supplement", {
              name: e?.target?.value,
              label: e?.target?.value,
              value: e?.target?.value,
              type: "supplement",
            });
        }}
        control={control}
        label="Selecione o suplemento"
        renderOption={(option: any) => (
          <CustomMedicationOption option={option} />
        )}
        selectOptions={supplementOptions}
      />

      {isDescriptionOpen && (
        <>
          <Stack direction={{ md: "row" }} gap={2}>
            <Box width={{ xs: "100%", lg: "60%" }}>
              <FormTextInput
                type="number"
                name="quantity"
                control={control}
                label="Posologia"
              />
            </Box>
            <FormAutoComplete
              freeSolo
              onInputChange={(e: any) => {
                if (e?.target?.value?.length)
                  setValue("form", {
                    name: e?.target?.value,
                    label: e?.target?.value,
                    value: e?.target?.value,
                  });
              }}
              name="form"
              control={control}
              label="Unidade"
              selectOptions={unit}
            />
            <FormAutoComplete
              freeSolo
              onInputChange={(e: any) => {
                if (e?.target?.value?.length) {
                  const debounce = setTimeout(() => {
                    if (extrairNumero(e?.target?.value) !== null)
                      setValue("frequency", {
                        name: `${extrairNumero(e?.target?.value)} h` || "",
                        label: `${extrairNumero(e?.target?.value)} h` || "",
                        value: extrairNumero(e?.target?.value) || "",
                      });
                    else {
                      setValue("frequency", {
                        name: "",
                        label: "",
                        value: "",
                      });
                    }
                  }, 1200);
                  return () => clearTimeout(debounce);
                }
              }}
              name="frequency"
              control={control}
              label="Intervalo de uso em horas"
              selectOptions={[]}
            />
            <FormAutoComplete
              freeSolo
              onInputChange={(e: any) => {
                if (e?.target?.value?.length) {
                  const debounce = setTimeout(() => {
                    if (extrairNumero(e?.target?.value) !== null)
                      setValue("time", {
                        name: `${extrairNumero(e?.target?.value)} dias` || "",
                        label: `${extrairNumero(e?.target?.value)} dias` || "",
                        value: extrairNumero(e?.target?.value) || "",
                      });
                    else {
                      setValue("time", {
                        name: "",
                        label: "",
                        value: "",
                      });
                    }
                  }, 1200);
                  return () => clearTimeout(debounce);
                }
              }}
              name="time"
              control={control}
              label="Tempo de tratamento em dias"
              selectOptions={[]}
            />

            <Tooltip
              title="Para tempo de tratamento indeterminado digite 0"
              placement="left"
              sx={{
                width: "8px",
                "&:hover": { backgroundColor: "transparent" },
              }}
            >
              <IconButton>
                <HelpOutline />
              </IconButton>
            </Tooltip>
          </Stack>
          <Stack>
            <FormTextInput
              multiline
              rows={3}
              label="Observação"
              name="note"
              control={control}
            />
          </Stack>
        </>
      )}

      <SubmitItemButton isEdit={isEdit} action={handleSubmit(onSubmit)} />
    </Stack>
  );
}
