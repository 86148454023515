import { Typography } from "@mui/material";
import { FormSelectInput } from "../../../components/form";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";

export function OrganizationOptions({ control }: any) {
  const { organizationList } = useOrganizationContext();

  const options = organizationList
    ?.filter((org: any) =>
      process.env.REACT_APP_ENV !== "production"
        ? org
        : org?.resource?.qualification?.[0]?.code?.coding?.some((item: any) => {
            return item?.system.startsWith("86");
          })
    )
    ?.map((e: any) => ({
      value: e?.resource?.id,
      display:
        e?.resource?.alias?.[0] !== "null"
          ? e?.resource?.alias?.[0]
          : e?.resource?.name,
    }))
    ?.filter((e: any) => e.value);

  return (
    <>
      <Typography variant="body1" fontWeight={400}>
        Selecione a unidade de saúde com quem você deseja compartilhar suas
        informações de saúde:
      </Typography>

      <FormSelectInput
        name="organization"
        label="Unidade de saúde"
        selectOptions={options || []}
        control={control}
        required
      />
    </>
  );
}
