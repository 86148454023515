import { useState } from "react";
import {
  FormControlLabel,
  IconButton,
  Popover,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import {
  NotificationsOffOutlined,
  NotificationsOutlined,
  NotificationsPausedOutlined,
} from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { NotificationPatch } from "../../services/scim/profile/NotificationPatch";
import { useSessionContext } from "../../contexts/SessionContext";

export function Notifications() {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { user, fetchUser } = useSessionContext();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = async (form: any) => {
    try {
      await NotificationPatch({
        idUser: user?.id,
        operation: "replace",
        valueField: `${form.target.checked}`,
      });
    } catch (err) {
      console.log("err", err);
    } finally {
      fetchUser();
    }

    return;
  };

  const { control } = useForm({});
  return (
    <>
      <IconButton
        aria-label="notifications"
        sx={{ color: "neutral0.main" }}
        onClick={handleClick}
      >
        {user?.notificationAgree === "true" ? (
          <NotificationsOutlined sx={{ fontSize: "32px" }} />
        ) : (
          <NotificationsOffOutlined
            sx={{ fontSize: "32px", color: "neutral200.main" }}
          />
        )}
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            sx: {
              minWidth: "300px",
              paddingX: 2,
              paddingY: 1,
            },
          },
        }}
      >
        <Stack
          direction="row"
          gap={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            Notificações
          </Typography>
          <Controller
            name={"healthPlanInternal"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked={user?.notificationAgree === "true"}
                    onChange={handleSubmit}
                  />
                }
                label={""}
                labelPlacement={"end"}
              />
            )}
          />
        </Stack>
      </Popover>
    </>
  );
}
