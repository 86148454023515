import { Download, Visibility } from "@mui/icons-material";
import { IconButton, TableCell, Tooltip } from "@mui/material";

export function SignedDocsActions({ row, setSignedDocView }: any) {
  function downloadBase64File(base64: string, type: string) {
    const linkSource = `data:application/pdf;base64,${base64}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = type;
    downloadLink.click();
  }

  function handleShowPDF() {
    setSignedDocView(row?.base64);
  }

  return (
    <>
      <TableCell align="center" sx={{ minWidth: "145px" }}>
        <Tooltip title="Visualizar">
          <IconButton onClick={() => handleShowPDF()}>
            <Visibility fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Baixar">
          <IconButton
            onClick={() => downloadBase64File(row?.base64, row?.type)}
          >
            <Download fontSize="small" />
          </IconButton>
        </Tooltip>
      </TableCell>
    </>
  );
}
