import { toast } from "react-toastify";
import { endpoints } from "../../../../configs/Settings";
import { HTTPResponseStatus } from "../../../../utils/enum";

import { useAuth } from "../../../../utils/useAuth";
import { useAxios } from "../../../../utils/useAxios";
import { auxiliarData } from "../../AuxiliarData";

export async function PostAllergyIntolerance(objData: any, encounter: any) {
  let url = `${endpoints.EHRPOSTER}/AllergyIntolerance`;

  const data = auxiliarData.AllergyIntolerance(objData, encounter);
  const headers = {
    Accept: "application/fhir+json",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
  };

  const response = await useAxios
    .post(url, data, { headers, timeout: 20000 })
    .then((response) => {
      if (
        response.status !== HTTPResponseStatus.Created &&
        response.status !== HTTPResponseStatus.Success
      ) {
        throw new Error(`PostMedicationRequest: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("PostAllergyIntolerance ", error);
      // toast.error(`PostAllergyIntolerance Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
