/* eslint-disable react-hooks/exhaustive-deps */

import Features from "./Features";
import { useEffect } from "react";

import { useAuthContext } from "../../contexts/AuthContext";
import { SessionContextProvider } from "../../contexts/SessionContext";
import { toast } from "react-toastify";

export default function LoggedUser() {
  const { parsedTime, logoutUser, getNewToken } = useAuthContext();
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (Date.now() >= parsedTime || !parsedTime) {
        logoutUser();
      } else if (Date.now() >= parsedTime - 15 * 60 * 1000) {
        getNewToken();
        toast.success("Sessão renovada");
      }
    }, 60000);

    return () => clearInterval(intervalId);
  }, [parsedTime]);

  return (
    <SessionContextProvider>
      <Features />
    </SessionContextProvider>
  );
}
