import { Close, Edit } from "@mui/icons-material";
import { Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

type SelectedTabType = "1" | "2" | "3" | "4";
interface IPrescriptionItem {
  item: any;
  index: number;
  prescriptionIndex: number;
  methods: any;
  setSelectedTab: Dispatch<SetStateAction<SelectedTabType>>;
  setPrescriptionItens: Dispatch<SetStateAction<any>>;
  setSelectetItem: any;
  selectedItem: any;
}

export function PrescriptionItem({
  methods,
  item,
  index,
  prescriptionIndex,
  setSelectedTab,
  setSelectetItem,
  selectedItem,
  setPrescriptionItens,
}: IPrescriptionItem) {
  const { setValue, reset } = methods;

  const tags = [
    {
      name: "VERMELHA",
      value: "CE",
      color: "#db3f30",
      display: "Controle especial - Tarja vermelha",
    },
    {
      name: "PRETA",
      value: "CE",
      color: "white",
      background: "black",
      display: "Controle especial - Tarja preta",
    },
    {
      name: "AMARELA",
      value: "AM",
      color: "#FEE2A9",
      display: "Receita amarela",
    },
    {
      name: "AZUL",
      value: "AZ",
      color: "#A4CBE8",
      display: "Receita azul",
    },
    {
      name: "SEM TARJA",
      value: "BR",
      color: "white",
      display: "Receita branca",
    },
    {
      name: "EX",
      display: "Exame",
      value: "EX",
      color: "#FDCF72",
    },
    {
      name: "AT",
      value: "AT",
      color: "#009E8C",
      display: "Atestado",
    },
  ];

  const auxTag = tags.find((el: any) =>
    item.tag?.toUpperCase()?.includes(el.name)
  );

  const removerItem = (index: number) => {
    setSelectetItem(undefined);

    setPrescriptionItens((prev: any) =>
      prev.filter((item: any, i: number) => i !== prescriptionIndex)
    );
  };

  const editarItem = () => {
    reset();
    setSelectedTab(item.tabValue);
    setSelectetItem(prescriptionIndex);

    if (item.type === "medication") {
      setValue("medication", item.inputValue);
      setValue("quantity", item.quantity.value || item.quantity);
      setValue("frequency", item.frequency);
      setValue("note", item.note);
      setValue("time", item.time);
      setValue("form", item.formValue);
    }
    if (item.type === "supplement") {
      setValue("supplement", item.inputValue);
      setValue("quantity", item.quantity.value || item.quantity);
      setValue("frequency", item.frequency);
      setValue("note", item.note);
      setValue("time", item.time);
      setValue("form", item.formValue);
    }
    if (item.type === "herbalMedicine") {
      setValue("herbalMedicine", item.inputValue);
      setValue("quantity", item.quantity.value || item.quantity);
      setValue("frequency", item.frequency);
      setValue("note", item.note);
      setValue("time", item.time);
      setValue("form", item.formValue);
    }
    if (item.type === "procedure") {
      setValue("procedure", item.inputValue);
      setValue("note", item.note);
    }
    if (item.type === "service") {
      setValue("service", item.inputValue);
      item.category && setValue("category", item.category);
      item.note && setValue("note", item.note);
    }
    if (item.type === "certificate") {
      setValue("certificate", item.note);
    }
    if (item.type === "service") {
      setValue("condition", item.condition);
    }
  };

  return (
    <Stack direction="row" mb={1} key={`${index}-${item.name}`}>
      <Stack
        p={1}
        gap={1}
        sx={{
          background: selectedItem === prescriptionIndex ? "#CCC" : "#F1F3F9",
          borderRadius: "5px",
          width: "100%",
        }}
      >
        <Stack direction="row" gap={1}>
          <Typography variant="body1" color="neutral1000.main" fontWeight={500}>
            {index + 1}. {item.name}
          </Typography>

          {item.inputValue?.presentation && (
            <Typography variant="body1" color="neutral700.main">
              {item.inputValue?.presentation}
            </Typography>
          )}

          {item.tag && (
            <Tooltip title={auxTag?.display}>
              <Button
                sx={{
                  background: auxTag?.background || auxTag?.color || "white",

                  width: "21px",
                  minWidth: "21px",
                  height: "22px",
                  "&:hover": {
                    backgroundColor:
                      auxTag?.background || auxTag?.color || "white",
                  },
                }}
                variant="contained"
              >
                <Typography
                  sx={{ color: auxTag?.background ? auxTag?.color : "black" }}
                  variant="body1"
                  color="neutral700.main"
                >
                  {auxTag?.value || "-"}
                </Typography>
              </Button>
            </Tooltip>
          )}
        </Stack>

        {item.formValue?.label && (
          <Typography variant="body1" color="neutral700.main">
            {item.form}
          </Typography>
        )}

        {item?.type === "service" && item?.inputValue?.code && (
          <Typography variant="body1" color="neutral700.main">
            Código TUSS: {item?.inputValue?.code}
          </Typography>
        )}

        {item?.category && (
          <Typography variant="body1" color="neutral700.main">
            Categoria: {item?.category?.label}
          </Typography>
        )}

        {item.condition && (
          <Typography variant="body1" color="neutral700.main">
            Condição: {item?.condition?.label}{" "}
            {!item?.condition?.name &&
              item.condition?.value &&
              `- CID10 ${item.condition?.value}`}
          </Typography>
        )}

        {item.note && (
          <Typography variant="body1" color="neutral700.main">
            {item.note}
          </Typography>
        )}
      </Stack>
      <Stack direction="row" p={1} sx={{ alignItems: "center" }}>
        <IconButton sx={{ color: "black" }}>
          <Edit fontSize="small" onClick={() => editarItem()} />
        </IconButton>
        <IconButton
          sx={{ color: "black" }}
          onClick={() => removerItem(prescriptionIndex)}
        >
          <Close fontSize="small" />
        </IconButton>
      </Stack>
    </Stack>
  );
}
