import { Form } from "./components/form";
import { ContentBox } from "../../components/common";
import { PublicEmptyLayout } from "../../components/layout";
import { isMobile } from "react-device-detect";
import { Stack } from "@mui/material";
export function SignUp() {
  return (
    <PublicEmptyLayout>
      {isMobile ? (
        <Stack
          alignItems="center"
          justifyContent="flex-start"
          width="100%"
          height="100%"
          padding="60px 36px"
          sx={{ backgroundColor: "neutral0.main" }}
        >
          <Form />
        </Stack>
      ) : (
        <ContentBox>
          <Form />
        </ContentBox>
      )}
    </PublicEmptyLayout>
  );
}
