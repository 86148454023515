import { DialogTitle, Stack, Typography } from "@mui/material";

import { PatchSchedule } from "../../../services/fhir";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { CommonDialog } from "../../../components/common/CommonDialog";
import { FormButtonGroup } from "../../../components/form";

export function DeactivateScheduleDialog({
  id,
  deactivateScheduleDialog,
  handleClose,
}: any) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (id: string) => {
    const patchOperation = [
      {
        op: "replace",
        path: "/active",
        value: "false",
      },
    ];

    try {
      setLoading(true);
      await PatchSchedule(id, patchOperation);
      toast.success("Agenda cancelada com sucesso!");
      navigate("/schedule");
    } catch (err) {
      toast.error("Não foi possível cancelar a agenda");
      console.log("err:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CommonDialog isOpen={deactivateScheduleDialog} handleClose={handleClose}>
      <DialogTitle textAlign="center">
        Cancelar permanentemente essa agenda
      </DialogTitle>
      <Stack mb={5}>
        <Typography textAlign="center" variant="body1">
          Os horários já agendados não serão excluídos.
        </Typography>
      </Stack>
      <FormButtonGroup
        onGoBackButtonClick={handleClose}
        onNextButtonClick={() => onSubmit(id)}
        goBackButtonText="Cancelar"
        nextButtonText="Confirmar"
        loading={loading}
      />
    </CommonDialog>
  );
}
