import { Stack } from "@mui/material";

import { useState } from "react";
import { useQuestionnaireContext } from "../../../../contexts/QuestionnaireContext";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { InputText } from "../../../ClinicalRegister/components/QuestionnaireViewer/components/Form";
import { AnamneseHistory } from "./AnamneseHistory";
import { useFhirData } from "../../../../utils/useFhirData";

export function Anamnese() {
  const { questionnaire, loadingResponse, defaultValues } =
    useQuestionnaireContext();
  const { control } = useMedicalCareContext();

  const anamneseItens = questionnaire?.item?.find(
    (el: any) => el.text === "Anamnese"
  );

  const [selectedField, setSelectedField] = useState<any>(
    questionnaire?.item?.[0]?.item?.[0]?.linkId
  );

  return (
    <>
      <Stack width="100%" maxHeight="60vh" overflow="auto">
        <Stack spacing={1}>
          {anamneseItens?.item?.map((input: any, index: number) => {
            if (input?.text === "Queixa principal")
              return (
                <InputText
                  onFocus={() => setSelectedField(input.linkId)}
                  key={index}
                  minRows={4}
                  maxRows={100}
                  multiline
                  autoFocus={index === 0}
                  label={input.text}
                  name={`anamnese.${input.text}`}
                  control={control}
                />
              );

            return (
              <InputText
                onFocus={() => setSelectedField(input.linkId)}
                key={index}
                minRows={4}
                maxRows={100}
                multiline
                label={input.text}
                name={`anamnese.${input.text}`}
                control={control}
                defaultValue={useFhirData?.extractAnswer({
                  questionnaireResponseItems: defaultValues?.[0]?.item,
                  targetId: input.linkId,
                })}
              />
            );
          })}
        </Stack>
      </Stack>
      {!loadingResponse && (
        <Stack
          width="100%"
          minWidth="600px"
          overflow="auto"
          maxHeight="60vh"
          paddingRight={3}
        >
          <AnamneseHistory selectedField={selectedField} />
        </Stack>
      )}
      ;
    </>
  );
}
