import { Typography } from "@mui/material";
import { FormSelectInput } from "../../../components/form";

export function VigencyOptions({ control }: any) {
  const options = [
    { value: 1, display: "1 dia" },
    { value: 7, display: "1 semana" },
    { value: 30, display: "1 mês" },
    { value: 365, display: "1 ano" },
    { value: 1000, display: "Até revogação manual" },
  ];
  return (
    <>
      <Typography variant="body1" fontWeight={400} mt={2}>
        Informe por quanto tempo você deseja compartilhar seu RES:
      </Typography>

      <FormSelectInput
        defaultValue={30}
        name="vigency"
        label="Validade"
        control={control}
        selectOptions={options}
        required
      />
    </>
  );
}
