import {
  Box,
  Button,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { useEffect, useState } from "react";
import { PractitionerDashboard } from "./components/PractitionerDashboard/PractitionerDashboard";
import { SearchPatient } from "../../../../components/schedule/SearchPatient/SearchPatient";
import dayjs from "dayjs";
import { LastAppointmentsTable } from "./components/LastAppointmentsTable";
import { ManagerDashboard } from "./components/ManagerDashboard/ManagerDashboard";
import { ManagerContextProvider } from "../../../../contexts/ManagerContext";
import { GetSchedule } from "../../../../services/fhir";
import { oids } from "../../../../configs/Settings";
import { ModalFirstAccess } from "../ModalFirstAccess";
import { usePractitionerRoleContext } from "../../../../contexts/PractitionerRoleContext";
import { useViewContext } from "../../../../contexts/ViewContext";
import { CalendarMonth, MedicalInformation } from "@mui/icons-material";

export function ProfessionalDashboard() {
  const {
    inviteDialog,
    user,
    access,
    firstAccessDialog,
    setFirstAccessDialog,
  } = useSessionContext();
  const { viewType, setViewType } = useViewContext();
  const { practitioner, userSpecialties } = usePractitionerRoleContext();
  const [loadingVerify, setLoadingVerify] = useState<boolean>(true);
  const [schedule, setSchedule] = useState<any>();
  const [openFirstAccess, setOpenFirstAcccess] = useState<boolean>(!schedule);

  const anotherPractitioner = practitioner?.find(
    (e: any) => !e?.resource?.id.includes(user.username)
  );
  const verifyOwner =
    access?.roles?.includes("manager") && access?.roles?.includes("owner");

  const handleToggleView = (type: "admin" | "user") => {
    setViewType(type);
  };

  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex);
  };

  const formatDate = (date: dayjs.Dayjs | null) => {
    return date
      ? date.format("dddd, D [de] MMMM [de] YYYY")?.charAt(0)?.toUpperCase() +
          date.format("dddd, D [de] MMMM [de] YYYY")?.slice(1)
      : "";
  };

  const handleClose = () => {
    setFirstAccessDialog(false);
    setOpenFirstAcccess(false);
  };

  useEffect(() => {
    async function fectchSchedules() {
      setLoadingVerify(true);

      try {
        const auxActor = `Practitioner/${oids.cpf}-${user.username}`;

        const scheduleResponse = await GetSchedule({
          actor: auxActor,
          organization: `${oids.cnpj}-${access.organizationId}`,
        });
        setSchedule(scheduleResponse?.entry);
      } catch (err) {
        console.log("err");
      } finally {
        setLoadingVerify(false);
      }
    }

    if (access?.organizationId) {
      fectchSchedules();
    }
  }, [access.organizationId, user]);
  return (
    <>
      {inviteDialog || loadingVerify ? (
        <>
          <Box
            sx={{
              width: "100%",
              height: "40vh",
              backgroundColor: "neutral0.main",
              borderRadius: "20px",
              mb: 2,
              padding: "20px 20px",
              pb: 4,
            }}
          >
            <Skeleton variant="rectangular" width="100%" height="100%" />
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "60vh",
              backgroundColor: "neutral0.main",
              borderRadius: "20px",
              mb: 2,
              padding: "20px 20px",
              pb: 4,
            }}
          >
            <Skeleton variant="rectangular" width="100%" height="100%" />
          </Box>
        </>
      ) : (
        <Stack width="100%" spacing={1}>
          <Box
            sx={{
              width: "100%",
              backgroundColor: "neutral0.main",
              borderRadius: "20px",
              mb: 2,
              padding: "20px 20px",
              pb: 4,
            }}
          >
            <Stack p={1} direction="row" justifyContent="space-between">
              <Typography variant="body1" fontSize={25} fontWeight={500}>
                {formatDate(dayjs())}
              </Typography>

              <Stack direction="row" spacing={1} justifyContent="center">
                {access?.roles?.includes("practitioner") && (
                  <Button
                    variant={viewType === "user" ? "contained" : "outlined"}
                    onClick={() => handleToggleView("user")}
                    startIcon={<MedicalInformation />}
                    sx={{ borderRadius: "30px" }}
                  >
                    Visão Profissional
                  </Button>
                )}
                {access?.roles?.includes("manager") && (
                  <Button
                    variant={viewType === "admin" ? "contained" : "outlined"}
                    onClick={() => handleToggleView("admin")}
                    startIcon={<CalendarMonth />}
                    sx={{ borderRadius: "30px" }}
                  >
                    Visão da Clínica
                  </Button>
                )}
              </Stack>
            </Stack>

            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              sx={{ minHeight: "auto" }}
            >
              <Tab label="Encontrar paciente" sx={{ minWidth: 100 }} />
              <Tab
                label={
                  viewType === "admin"
                    ? "Atendimentos recentes"
                    : "Meus pacientes"
                }
                sx={{ minWidth: 100 }}
              />
            </Tabs>

            <Box mt={2}>
              {tabIndex === 0 && (
                <Box>
                  <SearchPatient />
                </Box>
              )}
              {tabIndex === 1 && (
                <Box>
                  <LastAppointmentsTable />
                </Box>
              )}
            </Box>
          </Box>

          {viewType === "user" && (
            <PractitionerDashboard
              schedule={schedule}
              loadingVerify={loadingVerify}
            />
          )}
          {viewType === "admin" && (
            <>
              <ManagerContextProvider>
                <ManagerDashboard schedule={schedule} />
              </ManagerContextProvider>
            </>
          )}

          {!schedule &&
            verifyOwner &&
            !anotherPractitioner &&
            !userSpecialties &&
            firstAccessDialog &&
            !loadingVerify && (
              <ModalFirstAccess
                open={openFirstAccess}
                handleClose={handleClose}
              />
            )}
        </Stack>
      )}
    </>
  );
}
