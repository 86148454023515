import { Stack, Typography } from "@mui/material";
import { FormSelectInput } from "../../../components/form";

export function ScreenFinalizationService({ control }: any) {
  return (
    <>
      <Stack>
        <Typography variant="body1" fontWeight={600} color="neutral700.main">
          Complete os dados com o convênio médico:
        </Typography>

        <Typography variant="body1" color="neutral600.main">
          Use os dados da sua carteirinha pra adionar o convênio:
        </Typography>

        <Stack gap={2} mt={2}>
          <FormSelectInput
            name="serviceNetwork"
            label="Rede de atendimento"
            control={control}
            selectOptions={[]}
          />
          <FormSelectInput
            name="contractType"
            label="Tipo de contrato"
            control={control}
            selectOptions={[]}
          />
        </Stack>
      </Stack>
    </>
  );
}
