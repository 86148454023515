import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";

export async function PatchAlias(organization: any, alias: any) {
  const data = [
    {
      op: "replace",
      path: `/alias`,
      value: [alias],
    },
  ];

  const url = `${endpoints.ADMFHIR}/Organization/${organization.id}`;
  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/json-patch+json",
  };

  const response = await useAxios
    .patch(url, data, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`PatchAlias: ${response.status}`);
      }
      return { data: response.data, status: response.status };
    })
    .catch((error) => {
      console.error("PatchAlias ", error);
      // toast.error(`PatchAlias Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
