import { Grid, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { toast } from "react-toastify";

import { ISlotData } from "../../../../utils/interfaces";
import { useFormatter } from "../../../../utils/useFormatter";
import { FormButtonGroup } from "../../../../components/form";
import { useDict } from "../../../../utils/useDict";
import { PostAppointment, PostSlot } from "../../../../services/fhir";
import { useNavigate } from "react-router-dom";
import { useFhirData } from "../../../../utils/useFhirData";

interface IProps {
  scheduleActors: any;
  control: any;
  setValue: any;
  row: any;
  setModalOpen: any;
  handleSubmit: Function;
  onBackButtonClick: Function;
  setTabRefetch?: Dispatch<SetStateAction<boolean>>;
  walkin?: boolean;
}

export function ConfirmAppointment({
  control,
  row,
  scheduleActors,
  setValue,
  setModalOpen,
  onBackButtonClick,
  handleSubmit,
  setTabRefetch,
  walkin,
}: IProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  let selectedPractitioner = useWatch({ name: "professionalActor", control });
  let healthcare = useWatch({ name: "healthCareServiceActor", control });
  let location = useWatch({ name: "locationActor", control });
  let patient = useWatch({ name: "patientActor", control });
  let type = useWatch({ name: "typeService", control });
  let time = useWatch({ name: "slotInfo", control });
  let timeStart = useWatch({ name: "timeStart", control });
  let timeEnd = useWatch({ name: "timeEnd", control });

  const displayActors = [location, healthcare, selectedPractitioner, patient];

  const watchReferenceResponsible = useWatch({
    name: "auxObjReference",
    control,
  });

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      if (walkin) {
        const slotData: ISlotData = {
          type: data?.typeService,
          scheduleId: data?.schedule,
          start: dayjs(timeStart)?.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          end: dayjs(timeEnd)?.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        };
        const createdSlot = await PostSlot(slotData);

        await PostAppointment({
          ...data,
          slotInfo: {
            id: createdSlot?.id,
            start: createdSlot?.start,
            end: createdSlot?.end,
          },
        });
      } else {
        await PostAppointment(data);
      }

      toast.success(
        "Vaga preenchida com sucesso, visualize em próximas consultas."
      );

      if (setTabRefetch) {
        setTabRefetch((prev: boolean) => !prev);
      } else {
        navigate("/");
      }
    } catch (err) {
      // toast.error("Não foi possível preencher a vaga!");
      if (setTabRefetch) {
        setTabRefetch((prev: boolean) => !prev);
      }
    } finally {
      setLoading(false);
      setModalOpen(false);
    }
  };
  const { formatNameDisplay } = useFormatter;

  useEffect(() => {
    if (row) {
      setValue("slotInfo", {
        id: row?.auxUpdateSlot.id,
        start: row?.slotTime.start,
        end: row?.slotTime.end,
      });
      setValue("auxUpdateSlot", row?.auxUpdateSlot);
    }
    if (scheduleActors) {
      setValue(
        "professionalActor",
        useFhirData.findActor(scheduleActors, "Practitioner/")
      );
      setValue(
        "healthCareServiceActor",
        useFhirData.findActor(scheduleActors, "HealthcareService")
      );

      setValue(
        "locationActor",
        useFhirData.findActor(scheduleActors, "Location")
      );
    }
  }, [row, setValue, scheduleActors]);

  return (
    <Stack
      width="100%"
      height="100%"
      flex="1"
      justifyContent="center"
      alignItems="center"
      spacing={4}
    >
      <Typography
        variant="h6"
        color="neutral700.main"
        textAlign="center"
        m={{ xs: 2, md: 5 }}
      >
        Você solicitou o agendamento de uma consulta com as seguintes
        características:
      </Typography>

      <Grid
        container
        justifyContent="space-between"
        m="16px 40px 36px 40px"
        rowSpacing={1}
        rowGap={4}
      >
        <Grid item xs={6}>
          <Stack direction="column" spacing={1}>
            <Typography variant="subtitle1" fontWeight={600}>
              Data e hora
            </Typography>
            <Stack direction="row" spacing={1}>
              <Typography
                variant="subtitle2"
                color="neutral700.main"
                fontWeight={600}
              >
                Dia: {dayjs(time?.start || timeStart).format("DD/MM/YYYY")}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography
                variant="subtitle2"
                color="neutral700.main"
                fontWeight={600}
              >
                Hora:{" "}
                {useFormatter.formatDateTimeToHour(time?.start || timeStart)}
              </Typography>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={6}>
          <Stack direction="column" spacing={1}>
            <Typography variant="subtitle1" fontWeight={600}>
              Tipo
            </Typography>
            <Stack direction="row" spacing={1}>
              <Typography
                variant="subtitle2"
                color="neutral700.main"
                fontWeight={600}
              >
                {useDict.appointmentType[type]}
              </Typography>
            </Stack>
          </Stack>
        </Grid>

        {displayActors?.map((el: any, index: number) => {
          return (
            <Grid key={`${el}.${index}`} item xs={6}>
              <Stack direction="column" spacing={1}>
                <Typography variant="subtitle1" fontWeight={600}>
                  {useDict.actor[el?.reference?.split("/")?.[0]] || "-"}
                </Typography>
                <Stack direction="row" spacing={1}>
                  <Typography
                    variant="subtitle2"
                    color="neutral700.main"
                    fontWeight={600}
                  >
                    {formatNameDisplay(el?.display)}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          );
        })}

        {watchReferenceResponsible?.ownerName && (
          <Grid key="Responsável" item xs={6}>
            <Stack direction="column" spacing={1}>
              <Typography variant="subtitle1" fontWeight={600}>
                Responsável
              </Typography>
              <Stack direction="row" spacing={1}>
                <Typography
                  variant="subtitle2"
                  color="neutral700.main"
                  fontWeight={600}
                >
                  {formatNameDisplay(watchReferenceResponsible?.ownerName)}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        )}
      </Grid>
      <FormButtonGroup
        disabled={!scheduleActors}
        nextButtonText="Finalizar"
        onGoBackButtonClick={() => onBackButtonClick()}
        onNextButtonClick={handleSubmit(onSubmit)}
        loading={loading}
      />
    </Stack>
  );
}
