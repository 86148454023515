import { CircularProgress, Stack, Typography } from "@mui/material";

interface IDetailsRow {
  field: string;
  value: any;
  minWidth?: string;
  loading?: boolean;
}

export function DetailsRow({ field, value, minWidth, loading }: IDetailsRow) {
  return (
    <Stack direction="row" alignItems="start" spacing={1}>
      <Typography
        variant="body1"
        color="neutral700.main"
        minWidth={minWidth || "20%"}
        textAlign="left"
        sx={{ wordBreak: "break-word" }}
      >
        {field}
      </Typography>

      {loading ? (
        <CircularProgress size={18} />
      ) : (
        <Typography
          variant="body1"
          color="neutral700.main"
          textAlign="left"
          fontWeight={500}
          sx={{ wordBreak: "break-word" }}
        >
          {value}
        </Typography>
      )}
    </Stack>
  );
}
