const appointmentType: { [key: string]: string } = {
  CHECKUP: "Checkup",
  EMERGENCY: "Emergência",
  FOLLOWUP: "Retorno",
  ROUTINE: "Rotina",
  WALKIN: "Encaixe",
  FIRSTAPPOINTMENT: "Primeiro atendimento",
};

const signedDocsType: { [key: string]: string } = {
  Receituário: "Receita médica",
  Solicitação: "Solicitação de exame",
  Procedimento: "Procidimento",
  "Atestado Médico": "Relatório",
};

const protocolStatus: { [key: string]: string } = {
  "in-progress": "Desenvolvimento",
  amended: "Alterado",
  stopped: "Suspenso",
  completed: "Completo",
};

const category: { [key: string]: string } = {
  food: "Alimento",
  medication: "Medicamento",
  environment: "Ambiente",
  biologic: "Biológico",
  laboratoryprocedure: "Laboratorial",
  imaging: "Imagem",
  counselling: "Aconselhamento",
  education: "Educação",
  surgicalprocedure: "Procedimento cirúrgico",
};

const verificationStatus: { [key: string]: string } = {
  unconfirmed: "Não confirmado",
  confirmed: "Confirmado",
  presumed: "Presumido",
  refuted: "Refutado",
  provisional: "Provisório",
};

const clinicalStatus: { [key: string]: string } = {
  active: "Ativo",
  inactive: "Inativo",
  resolved: "Resolvido",
  recurrence: "Recorrente",
  remission: "Remissão",
  unknown: "Desconhecido",
};

const criticality: { [key: string]: string } = {
  low: "Baixo",
  high: "Alto",
  "unable-to-assess": "Incapaz de avaliar",
  recurrence: "Recorrente",
  remission: "Remissão",
  unknown: "Desconhecido",
};

const roles: { [key: string]: string } = {
  practitioner: "Profissional de saúde",
  manager: "Secretariado",
  owner: "Proprietário",
  invited: "Convidado",
};

const actor: { [key: string]: string } = {
  Practitioner: "Profissional",
  HealthcareService: "Serviço de saúde",
  Patient: "Paciente",
  Location: "Clínica",
};

export const useDict = {
  appointmentType,
  roles,
  actor,
  criticality,
  category,
  verificationStatus,
  signedDocsType,
  clinicalStatus,
  protocolStatus,
};
