import { Add, Edit } from "@mui/icons-material";
import { IFeatures } from "../../utils/interfaces";

import { useSessionContext } from "../../contexts/SessionContext";

import { CreateProtocol } from "./CreateProtocol";
import { useLocation } from "react-router-dom";

export function useCreateProtocol() {
  const { state } = useLocation();
  const { access } = useSessionContext();
  const configs: IFeatures = {
    active: access.type === "professional",
    path: "/createprotocol",
    icon: state?.id ? Edit : Add,
    text: state?.id ? "Editar protocolo" : "Criar protocolo",

    subitem: true,
    parent: "Protocolos clínicos",

    sideMenu: true,
    sideMenuIndex: 0,

    component: () => <CreateProtocol />,
  };

  return configs;
}
