import { toast } from "react-toastify";
import { endpoints, oids } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { IPractitionerRoleData } from "../../../utils/interfaces";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";
import { auxiliarData } from "../AuxiliarData";

export async function PutPractitionerRole(objData: IPractitionerRoleData) {
  const cnpj = objData.organizationId.split("-")?.[1];
  const cpf = objData.practitionerId.split("-")[1];

  const url = `${endpoints.ADMFHIR}/PractitionerRole/${cnpj}-${oids.cpf}-${cpf}`;
  const data = auxiliarData.PractitionerRole(objData);
  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
  };
  const response = await useAxios
    .put(url, data, { headers, timeout: 20000 })
    .then((response) => {
      if (
        response.status !== HTTPResponseStatus.Created &&
        response.status !== HTTPResponseStatus.Success
      ) {
        throw new Error(`PutPractitionerRole: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("PutPractitionerRole ", error);
      // toast.error(`PutPractitionerRole Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
