import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { Box, Stack } from "@mui/material";

import {
  FormButtonGroup,
  FormSelectInput,
  FormTimeInput,
} from "../../../../components/form";
import { useFhirData } from "../../../../utils/useFhirData";
import { GetSchedule } from "../../../../services/fhir";

import { oids } from "../../../../configs/Settings";
import { useOrganizationContext } from "../../../../contexts/OrganizationContext";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { usePractitionerRoleContext } from "../../../../contexts/PractitionerRoleContext";
import dayjs from "dayjs";
import { toast } from "react-toastify";

export function SelectAppointmentActors({
  control,
  setModalOpen,
  handleSubmit,
  setValue,
  navigateToNextPage,
  setScheduleActors,
}: any) {
  const { access, user } = useSessionContext();
  const { practitioner } = usePractitionerRoleContext();
  const [loading, setLoading] = useState<boolean>(false);
  const { organization } = useOrganizationContext();
  const auxActor =
    !access?.roles?.includes("manager") &&
    `Practitioner/${oids.cpf}-${user.username}`;

  let selectedPractitioner = useWatch({ name: "practitioner", control });

  const [schedule, setSchedule] = useState<any>();
  const scheduleOptions = schedule?.filter((sc: any) =>
    useFhirData
      .findActor(sc.resource.actor, "Practitioner")
      ?.reference.includes(
        auxActor ? user?.username : selectedPractitioner || user?.username
      )
  );

  const onSubmit = async (data: any) => {
    try {
      const selectedSchedule = scheduleOptions?.find(
        (e: any) => e.resource?.id === data?.schedule
      );

      setScheduleActors(selectedSchedule?.resource?.actor);

      if (!selectedSchedule) {
        toast.warn("É necessário selecionar uma agenda!");
        throw new Error("");
      }
      const duration = selectedSchedule?.resource?.extension?.find((el: any) =>
        el?.url.includes("slot-duration")
      )?.valueDuration?.value;

      setValue("timeEnd", dayjs(data?.timeStart).add(duration, "minutes"));

      navigateToNextPage();
    } catch (err) {
      console.log("Erro", err);
    }
  };

  useEffect(() => {
    async function fetchSchedules() {
      const teste = await GetSchedule({
        active: true,
        organization: organization?.id,
        ...(auxActor && { actor: auxActor }),
      });
      if (teste?.entry) setSchedule(teste?.entry);
      setLoading(false);
    }
    fetchSchedules();
  }, []);

  return (
    <Stack spacing={2}>
      {!loading && <></>}
      <Box p={1}>
        <FormSelectInput
          name="practitioner"
          defaultValue={
            practitioner?.find((e) => e.resource?.id?.includes(user.username))
              ?.resource?.id || ""
          }
          control={control}
          label="Profissional"
          selectOptions={
            practitioner
              ?.filter((prac) =>
                auxActor ? prac?.resource?.id?.includes(user?.username) : true
              )
              ?.map((e) => {
                return {
                  display: e?.resource?.name?.[0]?.text,
                  value: e?.resource?.id,
                };
              }) || []
          }
          required
        />
      </Box>

      <Box p={1}>
        {schedule && (
          <FormSelectInput
            name="schedule"
            control={control}
            label="Agenda"
            defaultValue={
              schedule?.find((e: any) =>
                useFhirData
                  .findActor(e?.resource?.actor, "Practitioner")
                  ?.reference.includes(user?.username)
              )?.resource?.id
            }
            selectOptions={
              scheduleOptions?.map((e: any) => {
                return {
                  display:
                    `${e?.resource.name} - ${
                      useFhirData.findActor(
                        e?.resource?.actor,
                        "HealthcareService"
                      )?.display
                    }` || "",
                  value: e.resource?.id || "",
                };
              }) || []
            }
            required
          />
        )}
      </Box>

      <Box p={1}>
        <FormTimeInput
          required
          name="timeStart"
          label="Horário"
          control={control}
          defaultValue={dayjs()}
          validate={(e: any) => !isNaN(e) || e === null}
        />
      </Box>

      <FormButtonGroup
        goBackButtonText="Fechar"
        onGoBackButtonClick={() => {
          setModalOpen(false);
        }}
        onNextButtonClick={handleSubmit(onSubmit)}
        loading={loading}
      />
    </Stack>
  );
}
