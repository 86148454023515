import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";

import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";
import { auxiliarData } from "../AuxiliarData";

type EntryValue =
  | "AllergyIntolerance"
  | "MedicationRequest"
  | "Procedure"
  | "Condition"
  | "Observation"
  | "DocumentReference"
  | "ServiceRequest"
  | "MedicationStatement";

interface IPatientSummary {
  identifier: string;
  entries?: EntryValue[];
  purposeofuse?: string;
}

export async function BundlePatientSummary({
  identifier,
  entries,
  purposeofuse,
}: IPatientSummary) {
  let url = `${endpoints.MHD}`;
  const data = auxiliarData.PatientSummary(identifier, entries);
  const subjectId = await useAuth.getSubjectId();
  const organizationId = await useAuth.getOrganizationId();

  const headers = {
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
    Accept: "application/fhir+json",
    "x-user-token": await useAuth.getXUserToken(),
    ...(subjectId && { "subject-id": subjectId }),
    ...(organizationId && { "organization-id": organizationId }),
    purposeofuse: purposeofuse || "Atendimento",
  };

  const response = await useAxios
    .post(url, data, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`BundlePatientSummary: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      if (error?.response.status === HTTPResponseStatus.Forbidden) {
        return { status: HTTPResponseStatus.Forbidden };
      }

      console.error("BundlePatientSummary ", error);
      // toast.error(`BundlePatientSummary Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
