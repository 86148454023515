import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";

type SlotStatusType =
  | "busy"
  | "free"
  | "busy-unavailable"
  | "busy-tentative"
  | "entered-in-error";

interface IProps {
  id?: string;
  schedule?: string;
  startDate?: string;
  endDate?: string;
  sort?: string;
  status?: SlotStatusType[];
  includeLocation?: boolean;
  scheduleActive?: boolean;
}

/**
 * @param id slot id
 * @param schedule id
 * @param startDate YYYY-MM-DD, inclusive
 * @param endDate YYYY-MM-DD, inclusive
 * @param sort slot field, to sort in reverse use the character -, e.g. -start
 * @param status busy | free | busy-unavailable | busy-tentative | entered-in-error
 * @param includeLocation boolean
 * @param scheduleActive boolean
 * @returns Bundle
 */
export async function GetSlot({
  id,
  schedule,
  startDate,
  endDate,
  sort,
  status,
  includeLocation,
  scheduleActive,
}: IProps) {
  let url = `${endpoints.WORKFLOWFHIR}/Slot`;
  if (id) url = `${url}/${id}`;
  else url = `${url}?schedule=${schedule}&_count=1000`;
  if (startDate) url = `${url}&start=ge${startDate}`;
  if (endDate) url = `${url}&start=le${endDate}`;
  if (sort) url = `${url}&_sort=${sort}`;
  if (status) url = `${url}&status=${status.join(",")}`;
  if (includeLocation)
    url = `${url}&_include=Slot:schedule&_include:iterate=Schedule:actor:Location`;
  if (scheduleActive) url = `${url}&schedule.active=${scheduleActive}`;

  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
  };
  const response = await useAxios
    .get(url, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`Get Slot: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("GetSlot ", error);
      // toast.error(`GetSlot Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
