import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Stack,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

import dayjs from "dayjs";
import { GenericTable } from "../../../../../components/GenericTable/GenericTable";
import { useDatetime } from "../../../../../utils/useDatetime";
import { useFhirData } from "../../../../../utils/useFhirData";
import { DiagnosisActions } from "./DiagnosisActions";
import { useSessionContext } from "../../../../../contexts/SessionContext";

export function Diagnosis({
  data,
  title,
  openVisualizer,
  verifyVisualizer,
}: any) {
  const { formatToDDMMYYYY } = useDatetime;

  const { access } = useSessionContext();

  const columns = [
    {
      column: "Descrição",
      rowName: "description",
    },
    {
      column: "CID10",
      rowName: "param",
    },
    { column: "Profissional", rowName: "practitioner" },
    { column: "Informante", rowName: "informant" },
    { column: "Data", rowName: "date" },
  ];

  const rowData = data?.[0]?.entry?.map((el: any) => {
    return {
      encounterId: el?.resource?.encounter?.reference?.split("/")?.[1],
      param: el?.resource?.code?.coding?.[0]?.code,
      description: el?.resource?.code?.coding?.[0]?.display || "",
      date: formatToDDMMYYYY(dayjs(el?.resource?.recordedDate)),
      practitioner: useFhirData.findParticipant(
        el?.resource?.participant,
        "Practitioner"
      )?.display,
      informant: useFhirData.findParticipant(
        el?.resource?.participant,
        "Patient"
      )
        ? "Paciente"
        : "Profissional",
    };
  });

  return (
    <Accordion sx={{ width: "100%" }} elevation={5}>
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Stack direction="row" alignItems="center" gap={1}>
          {title}
          <Avatar
            sx={{
              width: 26,
              height: 26,
              fontSize: "14px",
              fontWeight: 600,
              backgroundColor: "primary100.main",
              color: "primary1000.main",
            }}
          >
            {rowData?.length || "0"}
          </Avatar>
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <GenericTable
          tableColumns={columns}
          tableRows={rowData}
          actions={
            access?.type === "patient"
              ? undefined
              : (rowData: any) => (
                  <DiagnosisActions
                    row={rowData}
                    openVisualizer={openVisualizer}
                    verifyVisualizer={verifyVisualizer}
                  />
                )
          }
        />
      </AccordionDetails>
    </Accordion>
  );
}
