import { Place } from "@mui/icons-material";
import { IFeatures } from "../../utils/interfaces";

import { useSessionContext } from "../../contexts/SessionContext";
import { ManageLocation } from "./ManageLocation";

export function useManageLocation() {
  const { access } = useSessionContext();
  const configs: IFeatures = {
    active: access?.type === "professional",
    path: "/locations",
    icon: Place,
    text: "Localizações",
    parent: "Configurações",
    sideMenu: true,
    subitem: true,
    component: () => <ManageLocation />,
  };

  return configs;
}
