import { Divider, Stack, Typography } from "@mui/material";

import { oids } from "../../../configs/Settings";
import { CardRow } from "../../../components/common";
import { useFormatter } from "../../../utils/useFormatter";
import { useFhirData } from "../../../utils/useFhirData";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";

export function CardInfo() {
  const { organization } = useOrganizationContext();
  const { findIdentifier } = useFhirData;
  const { formatCNPJ, formatNameDisplay, formatCPF } = useFormatter;

  const auxAddress = organization?.contact?.find(
    (el: fhir5.ExtendedContactDetail) => el.address
  )?.address;
  const city = auxAddress?.city || "";
  const state = auxAddress?.state || "";
  const postalCode = auxAddress?.postalCode || "";

  const line = auxAddress?.line?.join(", ") || "";

  const address =
    [line, city, state, postalCode]?.filter((el: any) => el)?.join(" , ") ||
    "-";

  const extension = organization?.extension?.find((el: any) =>
    el?.url.includes("OrganizationManager")
  )?.extension;

  return (
    <Stack
      sx={{
        border: "1px solid",
        borderColor: "neutral700.main",
        borderRadius: "15px",
      }}
      width="100%"
      paddingTop={2}
    >
      <Typography
        variant="h5"
        fontWeight="500"
        paddingBottom={3}
        paddingLeft={5}
      >
        Informações básicas
      </Typography>
      <CardRow
        title="Responsável"
        titleContext={formatNameDisplay(
          extension?.find((extension: any) => extension.url === "name")
            ?.valueString || "-"
        )}
      />

      <Divider sx={{ marginLeft: 5 }} />
      <CardRow
        title="CPF"
        titleContext={formatCPF(
          extension?.find((extension: any) => extension.url === "id")
            ?.valueIdentifier?.value || "-"
        )}
      />
      <Divider sx={{ marginLeft: 5 }} />
      <CardRow
        title="CNPJ"
        titleContext={
          formatCNPJ(
            findIdentifier(organization?.identifier, `urn:oid:${oids.cnpj}`)
          ) || "-"
        }
      />
      <Divider sx={{ marginLeft: 5 }} />
      <CardRow
        title="Razão social"
        titleContext={useFormatter.formatNameDisplay(organization?.name || "")}
      />
      <Divider sx={{ marginLeft: 5 }} />
      <CardRow
        title="CNAE principal"
        titleContext={
          organization?.type?.[0]?.coding?.find(
            (code: fhir5.Coding) => code.system === "cnaePrincipal"
          )?.display ||
          organization?.qualification?.[0]?.code?.coding?.find(
            (el: any) => el?.use === "official" || el?.system.includes("86")
          )?.display ||
          "-"
        }
      />
      <Divider sx={{ marginLeft: 5 }} />
      <CardRow title="Endereço principal" titleContext={address} />
      <Divider sx={{ marginLeft: 5 }} />
    </Stack>
  );
}
