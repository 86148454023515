import {
  Document,
  Image,
  Link,
  Page,
  StyleSheet,
  Text,
} from "@react-pdf/renderer";
import { MedicationSimpleRecipe } from "./MedicationSimpleRecipe";
import { MedicationSpecialControl } from "./MedicationSpecialControl";
import { ExamSolicitation } from "./ExamSolicitation";
import { MedicalCertificate } from "./MedicalCertificate";
import { ProcedureRecipe } from "./ProcedureRecipe";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#f9f9f9",
    width: "100%",
    padding: "180px 40px",
  },
  link: {
    color: "blue",
    textDecoration: "underline",
  },
});

export function PDFRender({
  qrCodeSrc,
  dataPdf,
  content,
  setBlob,
  blob,
  dataRegister,
  gender,
  uuid,
  idValue,
  linkDocument,
}: any) {
  const filterSimple =
    dataRegister?.filter(
      (auxSimple: any) =>
        auxSimple?.resource?.item?.[0]?.answer?.[0]?.valueString === "simple"
    ) || [];

  const filterSpecial =
    dataRegister?.filter(
      (auxSimple: any) =>
        auxSimple?.resource?.item?.[0]?.answer?.[0]?.valueString === "special"
    ) || [];

  const filterProcedure =
    dataRegister?.filter(
      (auxSimple: any) =>
        auxSimple?.resource?.item?.[0]?.answer?.[0]?.valueString === "procedure"
    ) || [];

  const filterExam =
    dataRegister?.filter(
      (auxSimple: any) =>
        auxSimple?.resource?.item?.[0]?.answer?.[0]?.valueString === "exam"
    ) || [];

  const filterCertificate =
    dataRegister?.filter(
      (auxSimple: any) =>
        auxSimple?.resource?.item?.[0]?.answer?.[0]?.valueString ===
        "certificate"
    ) || [];

  const generateBase64QRCode = (id: any) => {
    const canvas = document.getElementById(id)?.querySelector("canvas");
    if (canvas) {
      const base64 = canvas.toDataURL("image/png");
      return base64;
    }
  };

  return (
    <>
      {qrCodeSrc && dataPdf && dataRegister && uuid && content?.type && (
        <Document
          onRender={(props) => {
            if (!blob && props) {
              setBlob((prev: any) => ({
                ...prev,
                [content?.type]: {
                  blob: props?.blob,
                  info: content?.data,
                  uuid,
                  idValue,
                },
              }));
            }
          }}
          style={{ width: "100%" }}
        >
          {content?.type === "simple" && (
            <Page wrap={true} size="A4" style={styles.page}>
              <MedicationSimpleRecipe
                gender={gender}
                filterMedication={content?.data}
                dataPdf={dataPdf}
                documentHasBackground={filterSimple.length ? true : false}
                imgBase64={`data:${
                  filterSimple?.[0]?.resource?.item?.[2]?.answer?.[0]
                    ?.valueAttachment?.contentType
                };base64,${
                  filterSimple?.[0]?.resource?.item?.[2]?.answer?.[0]
                    ?.valueAttachment?.data ?? ""
                }`}
              />
            </Page>
          )}
          {content?.type.includes("special") && (
            <MedicationSpecialControl
              gender={gender}
              filterSpecialMedication={content?.data}
              dataPdf={dataPdf}
              documentHasBackground={filterSpecial.length ? true : false}
              imgBase64={`data:${
                filterSpecial?.[0]?.resource?.item?.[2]?.answer?.[0]
                  ?.valueAttachment?.contentType
              };base64,${
                filterSpecial?.[0]?.resource?.item?.[2]?.answer?.[0]
                  ?.valueAttachment?.data ?? ""
              }`}
            />
          )}
          {content?.type.includes("procedure") && (
            <Page wrap={true} size="A4" style={styles.page}>
              <ProcedureRecipe
                gender={gender}
                filterProcedure={content?.data}
                dataPdf={dataPdf}
                documentHasBackground={filterProcedure.length ? true : false}
                imgBase64={`data:${
                  filterProcedure?.[0]?.resource?.item?.[2]?.answer?.[0]
                    ?.valueAttachment?.contentType
                };base64,${
                  filterProcedure?.[0]?.resource?.item?.[2]?.answer?.[0]
                    ?.valueAttachment?.data ?? ""
                }`}
              />
            </Page>
          )}
          {content?.type === "exam" && (
            <Page wrap={true} size="A4" style={styles.page}>
              <ExamSolicitation
                gender={gender}
                filterExam={content?.data}
                dataPdf={dataPdf}
                documentHasBackground={filterExam.length ? true : false}
                imgBase64={`data:${
                  filterExam?.[0]?.resource?.item?.[2]?.answer?.[0]
                    ?.valueAttachment?.contentType
                };base64,${
                  filterExam?.[0]?.resource?.item?.[2]?.answer?.[0]
                    ?.valueAttachment?.data ?? ""
                }`}
              />
            </Page>
          )}
          {content?.type.includes("certificate") && (
            <Page wrap={true} size="A4" style={styles.page}>
              <MedicalCertificate
                gender={gender}
                filterMedicalCertificate={content?.data}
                dataPdf={dataPdf}
                documentHasBackground={filterCertificate.length ? true : false}
                imgBase64={`data:${
                  filterCertificate?.[0]?.resource?.item?.[2]?.answer?.[0]
                    ?.valueAttachment?.contentType
                };base64,${
                  filterCertificate?.[0]?.resource?.item?.[2]?.answer?.[0]
                    ?.valueAttachment?.data ?? ""
                }`}
              />
            </Page>
          )}

          <Page
            wrap={true}
            size="A4"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: 40,
              paddingHorizontal: 20,
            }}
          >
            <Text
              style={{
                fontSize: 18,
                fontWeight: "bold",
                marginBottom: 10,
                textAlign: "center",
              }}
            >
              Validar Documento
            </Text>

            <Text
              style={{ fontSize: 12, textAlign: "center", marginBottom: 5 }}
            >
              Escaneie o QR code abaixo e realize a validação do documento.
            </Text>

            <Text
              style={{ fontSize: 12, textAlign: "center", marginBottom: 20 }}
            >
              Ou clique no link abaixo para abrir o documento diretamente.
            </Text>

            <Image
              src={generateBase64QRCode(qrCodeSrc)}
              style={{
                maxWidth: "90px",
                maxHeight: "90px",
                marginBottom: 15,
              }}
            />

            <Link
              src={linkDocument}
              style={{
                fontSize: 12,
                color: "blue",
                textDecoration: "underline",
              }}
            >
              Clique aqui para validar o documento
            </Link>
          </Page>
        </Document>
      )}
    </>
  );
}
