import { DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useSessionContext } from "../../../contexts/SessionContext";
import { useNavigate } from "react-router-dom";
import { FormButtonGroup } from "../../../components/form";
import { CommonDialog } from "../../../components/common/CommonDialog";

export function WarningSpecialtyDialog({ handleClose, open }: any) {
  const { loading } = useSessionContext();
  const { access } = useSessionContext();

  const navigate = useNavigate();

  return (
    <CommonDialog handleClose={handleClose} isOpen={open}>
      <DialogTitle textAlign="center"> Criar agenda </DialogTitle>
      <DialogContent>
        <Stack
          width="100%"
          height="100%"
          flex="1"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          {access?.roles?.includes("manager") ? (
            <Typography
              variant="body1"
              color="neutral700.main"
              textAlign="center"
              m={{ xs: 2, md: 5 }}
            >
              Para prosseguir com a criação de agendas, é necessário que ao
              menos um profissional esteja configurado no sistema. Configure-se
              ou convide um profissional de saúde para completar essa etapa.
            </Typography>
          ) : (
            <Typography
              variant="body1"
              color="neutral700.main"
              textAlign="center"
              m={{ xs: 2, md: 5 }}
            >
              Para prosseguir com a criação de agendas, é necessário completar o
              cadastro de suas informações profissionais.
            </Typography>
          )}
        </Stack>

        <Stack
          minWidth="100%"
          mt={3}
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          {access?.roles?.includes("manager") ? (
            <FormButtonGroup
              onGoBackButtonClick={() => navigate("/professional-register")}
              onNextButtonClick={() => navigate("/practitioners")}
              goBackButtonText="Configurar"
              nextButtonText="Convidar"
              loading={loading}
            />
          ) : (
            <FormButtonGroup
              onGoBackButtonClick={() => navigate(-1)}
              onNextButtonClick={() => navigate("/professional-register")}
              goBackButtonText="Voltar"
              nextButtonText="Completar cadastro"
              loading={loading}
            />
          )}
        </Stack>
      </DialogContent>
    </CommonDialog>
  );
}
