import { Stack, Typography } from "@mui/material";

interface ICardRow {
  title: string;
  titleContext: any;
}

export function CardRow({ title, titleContext }: ICardRow) {
  return (
    <Stack
      direction="row"
      alignItems="start"
      sx={{ paddingY: 3, paddingLeft: 5, paddingRight: 3 }}
      spacing={1}
    >
      <Typography
        variant="body1"
        color="neutral700.main"
        width="20%"
        textAlign="left"
        sx={{ wordBreak: "break-word" }}
      >
        {title}:
      </Typography>
      <Typography
        variant="body1"
        color="neutral1000.main"
        width="80%"
        textAlign="left"
        fontWeight={500}
        sx={{ wordBreak: "break-word" }}
      >
        {titleContext}
      </Typography>
    </Stack>
  );
}
