import { Stack, Typography } from "@mui/material";
import { useFormatter } from "../../../utils/useFormatter";
import { Place, VideoCameraFront } from "@mui/icons-material";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";

interface IProps {
  location: fhir5.Location;
}

export function HeaderScheduling({ location }: IProps) {
  function isExtensionVirtualService(extension: fhir5.Extension) {
    return (
      extension.url ===
        "https://fluxmed.com.br/fhir/StructureDefinition/virtual-service" &&
      extension.valueBoolean === true
    );
  }

  function isVirtualService(location: fhir5.Location) {
    return !!location?.extension?.find(isExtensionVirtualService);
  }

  const { organizationList } = useOrganizationContext();
  const findOrganization = organizationList?.find(
    (org) =>
      org?.resource?.id ===
      location?.managingOrganization?.reference?.split("/")?.[1]
  );

  return (
    <Stack width="100%" height="138px" borderRadius="5px">
      <Stack direction="row" height="100%" gap={2}>
        <Stack width="80%" direction="column" justifyContent="center">
          <Typography
            variant="subtitle2"
            fontWeight={700}
            color="neutral700.main"
          >
            {useFormatter.formatNameDisplay(
              findOrganization?.resource?.alias?.[0] ||
                findOrganization?.resource?.name ||
                "-"
            )}
          </Typography>

          <Stack direction="row" alignContent="center" width="100%" spacing={1}>
            {!isVirtualService(location) ? (
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Place fontSize="small" sx={{ marginLeft: "-3px" }} />
                <Typography
                  display="flex"
                  alignItems="center"
                  variant="body1"
                  color="neutral700.main"
                >
                  {location?.address?.line?.join(", ")}
                </Typography>
              </Stack>
            ) : (
              <>
                <VideoCameraFront sx={{ mr: 2 }} />
                <Typography variant="body1" color="neutral700.main">
                  Remoto
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
