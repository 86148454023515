export default function TermText({ termOf }: { termOf: string }) {
  return termOf !== "owner" ? (
    <main>
      <h3 style={{ textAlign: "center" }}>
        TERMOS DE USO DA PLATAFORMA FLUXMED E PROTEÇÃO DE DADOS PESSOAIS
        (“Termo”)
      </h3>

      <h4>1. INTRODUÇÃO</h4>

      <ol>
        <li>
          <strong style={{ fontWeight: "600" }}>Este Termo</strong> é oferecido
          pela{" "}
          <strong style={{ fontWeight: "600" }}>
            Core Consultoria e Serviços Ltda.
          </strong>
          , empresa de tecnologia com sede em Brasília/DF, inscrita no CNPJ nº
          05.490.544/0001-00 que, de agora em diante, será referida como{" "}
          <i>“Core Consulting”</i> ou, simplesmente, <i>“nós”</i>.
        </li>

        <li>
          <strong style={{ fontWeight: "600" }}>A Core Consulting</strong> é a
          única e exclusiva proprietária das marcas e dos domínios associados à
          denominada <i>“Fluxmed”</i>, <i>“fluxmed.com.br”</i>,{" "}
          <i>“fluxmed.cloud”</i> e <i>“coreconsulting.com.br”</i>. Assim, ela é
          a desenvolvedora e proprietária de todos os direitos materiais e
          imateriais da <i>“Plataforma Fluxmed”</i> ou simplesmente{" "}
          <i>“Fluxmed”</i>.
        </li>

        <li>
          <i>“Fluxmed”</i> é uma plataforma de desenvolvimento de serviços
          digitais de saúde, em constante evolução tecnológica, incluindo e
          disponibilizando novas funcionalidades com frequência. Isso implica em
          constante atualização deste{" "}
          <strong style={{ fontWeight: "600" }}>Termo</strong>, que estará
          sempre disponível na <i>Plataforma</i> para a leitura, atualização e
          compreensão pelo usuário da <i>Plataforma Fluxmed</i>, ou seja,{" "}
          <strong style={{ fontWeight: "600" }}>VOCÊ</strong> (ou o seu
          representante legal).
        </li>

        <li>
          <strong style={{ fontWeight: "600" }}>VOCÊ</strong> (ou seu
          representante legal), usuário da <i>Plataforma Fluxmed</i>, é um
          paciente do estabelecimento de saúde que contratou a{" "}
          <i>Plataforma Fluxmed</i> para, por meio dela, prestar a{" "}
          <strong style={{ fontWeight: "600" }}>VOCÊ</strong> um serviço de
          saúde. <strong style={{ fontWeight: "600" }}>VOCÊ</strong> também é um
          titular de dados pessoais, consumidor que, por meio deste{" "}
          <strong style={{ fontWeight: "600" }}>Termo</strong>, manifesta a sua
          vontade livre, informada e inequívoca e aceita as condições para
          utilizar a <i>Plataforma Fluxmed</i> por meio de{" "}
          <strong style={{ fontWeight: "600" }}>Aplicativo</strong> ou pela{" "}
          <strong style={{ fontWeight: "600" }}>web</strong>.
        </li>
      </ol>

      <h4 style={{ fontWeight: "600" }}>2. OBJETIVO E ACEITAÇÃO</h4>

      <ol>
        <li>
          Este Termo tem como{" "}
          <strong style={{ fontWeight: "600" }}>OBJETIVO</strong> cumprir com o
          dever de informar <strong style={{ fontWeight: "600" }}>VOCÊ</strong>{" "}
          sobre as indicações e limitações de uso, as finalidades, o canal de
          atendimento para dúvidas técnicas, seus direitos e obrigações
          relacionados à utilização da <i>Plataforma Fluxmed</i>.
        </li>

        <li>
          Além disso, por meio deste Termo queremos mostrar nosso compromisso
          com a <i>Lei Geral de Proteção de Dados Pessoais</i> (Lei nº
          13.709/2018 – <i>“LGPD”</i>) e reforçar nosso dever com a
          transparência e a prestação de contas ao descrever: o ciclo de
          tratamento de seus dados pessoais e dados pessoais sensíveis de saúde,
          a base legal adotada, os tratamentos realizados pela{" "}
          <strong style={{ fontWeight: "600" }}>Core Consulting</strong> e nossa
          atuação como{" "}
          <strong style={{ fontWeight: "600" }}>Agente de Tratamento</strong>{" "}
          com relação ao estabelecimento de saúde que{" "}
          <strong style={{ fontWeight: "600" }}>VOCÊ</strong> vai interagir por
          meio da <i>Plataforma Fluxmed</i>.
        </li>

        <li>
          Através da leitura deste Termo,{" "}
          <strong style={{ fontWeight: "600" }}>VOCÊ</strong> também vai
          entender e precisa concordar que a aceitação dele é obrigatória para
          utilizar a <i>Plataforma Fluxmed</i>. Se{" "}
          <strong style={{ fontWeight: "600" }}>VOCÊ</strong> não concordar com
          qualquer item deste Termo,{" "}
          <strong style={{ fontWeight: "600" }}>VOCÊ</strong> não poderá
          utilizar a <i>Plataforma Fluxmed</i> porque este Termo é de adesão.
        </li>

        <li>
          Desde já, <strong style={{ fontWeight: "600" }}>VOCÊ</strong> precisa
          saber que poderá revogar o seu consentimento a qualquer momento e sem
          nenhum custo. Aliás, a <i>Plataforma Fluxmed</i> permite que{" "}
          <strong style={{ fontWeight: "600" }}>VOCÊ</strong> faça a gestão de
          seu consentimento. Isso significa que{" "}
          <strong style={{ fontWeight: "600" }}>VOCÊ</strong> poderá gerenciar
          com quem e com quais estabelecimentos de saúde{" "}
          <strong style={{ fontWeight: "600" }}>VOCÊ</strong> permite que seus
          dados de saúde sejam compartilhados e visualizados.
        </li>

        <li>
          A <i>Plataforma Fluxmed</i> foi desenvolvida com a premissa de que{" "}
          <strong style={{ fontWeight: "600" }}>VOCÊ</strong> é o protagonista
          de sua saúde e titular de seus dados pessoais.
        </li>

        <li>
          Assim que <strong style={{ fontWeight: "600" }}>VOCÊ</strong> realiza
          o seu cadastro na plataforma,{" "}
          <strong style={{ fontWeight: "600" }}>VOCÊ</strong> precisa saber que{" "}
          <strong style={{ fontWeight: "600" }}>VOCÊ</strong> recebe um perfil
          de usuário com visão de paciente, as funcionalidades são centradas em{" "}
          <strong style={{ fontWeight: "600" }}>VOCÊ</strong>. Quando{" "}
          <strong style={{ fontWeight: "600" }}>VOCÊ</strong> for atendido em
          uma ou mais de uma organização de saúde, que também utiliza a
          plataforma, fique ciente de que a organização de saúde passa a ser
          controladora das suas informações de saúde.
        </li>
      </ol>

      <h4 style={{ fontWeight: "600" }}>
        3. CONSENTIMENTO DE ACESSO A INFORMAÇÕES PELOS PAIS OU REPRESENTANTES
        LEGAIS
      </h4>

      <ol>
        <li>
          Se <strong style={{ fontWeight: "600" }}>VOCÊ</strong> é pai, mãe ou
          representante legal de um menor, pode informar seu dependente
          utilizando a função <i>“Minha Família”</i> na plataforma{" "}
          <strong style={{ fontWeight: "600" }}>Fluxmed</strong>. Na primeira
          vez que o menor for atendido por um médico que utiliza a plataforma, o
          vínculo será validado pelo profissional.
        </li>

        <li>
          As informações clínicas e documentos do menor (como receitas médicas,
          solicitações de exames ou relatórios médicos) coletados no momento do
          atendimento são fornecidos pelo responsável legal no uso da{" "}
          <strong style={{ fontWeight: "600" }}>Plataforma Fluxmed</strong>.
        </li>

        <li>
          O tratamento de dados dos menores será realizado da mesma forma que o
          dos maiores de idade ou plenamente capazes civilmente.
        </li>

        <li>
          O controlador de dados realizará esforços razoáveis para verificar que
          o consentimento foi dado pelo responsável pela criança ou adolescente.
        </li>

        <li>
          Para dúvidas sobre esta cláusula, entre em contato pelo e-mail:{" "}
          <a href="mailto:contato@fluxmed.com.br">contato@fluxmed.com.br</a>.
        </li>
      </ol>

      <h4 style={{ fontWeight: "600" }}>
        4. DESCRIÇÃO DAS FUNCIONALIDADES DA PLATAFORMA FLUXMED E LIMITAÇÕES DE
        USO
      </h4>

      <ol>
        <li>
          A <strong style={{ fontWeight: "600" }}>“Fluxmed”</strong> está
          hospedada em nuvem no Brasil e possui funcionalidades como:
          armazenamento do prontuário eletrônico, agendamento de consultas,
          emissão de documentos médicos eletrônicos, realização de teleconsultas
          e visualização do histórico de acessos às suas informações de saúde.
        </li>

        <li>
          Em casos de{" "}
          <strong style={{ fontWeight: "600" }}>urgência ou emergência</strong>,
          procure atendimento presencial.
        </li>

        <li>
          A <strong style={{ fontWeight: "600" }}>Core Consulting</strong> não é
          responsável pelo serviço de saúde prestado pelo estabelecimento ou
          profissional de saúde.{" "}
          <strong style={{ fontWeight: "600" }}>VOCÊ</strong> deverá buscar seus
          direitos diretamente com o estabelecimento de saúde.
        </li>

        <li>
          A base legal para tratar suas informações de saúde é o{" "}
          <i>consentimento</i> (art. 11, da{" "}
          <strong style={{ fontWeight: "600" }}>LGPD</strong>).
        </li>

        <li>
          A <strong style={{ fontWeight: "600" }}>Fluxmed</strong> compromete-se
          a respeitar sua privacidade e proteger seus dados pessoais, atuando
          como operadora de dados.{" "}
          <strong style={{ fontWeight: "600" }}>VOCÊ</strong> deve saber que,
          conforme a <strong style={{ fontWeight: "600" }}>LGPD</strong>, é
          proibido o compartilhamento de dados de saúde para obtenção de
          vantagem econômica, exceto em casos específicos.
        </li>
      </ol>

      <h4 style={{ fontWeight: "600" }}>
        5. COMO SUAS INFORMAÇÕES DE SAÚDE SÃO OPERADAS PELA FLUXMED
      </h4>

      <ol>
        <li>
          A Plataforma <strong style={{ fontWeight: "600" }}>Fluxmed</strong>{" "}
          opera as suas informações de saúde como <i>“usuário-paciente”</i> e
          permite o acesso por profissionais de saúde de uma organização{" "}
          <i>“usuário-autorizado”</i> em seu benefício.
        </li>

        <li>
          Para se cadastrar, <strong style={{ fontWeight: "600" }}>VOCÊ</strong>{" "}
          precisará inserir dados de identificação como nome completo, data de
          nascimento, CPF, e-mail e número de celular. Esses dados são validados
          para garantir a sua segurança.
        </li>

        <li>
          Não forneça informações imprecisas e proteja sua senha. Use os
          mecanismos de consentimento disponíveis para permitir que outras
          pessoas acessem suas informações de saúde.
        </li>

        <li>
          Após o cadastro, <strong style={{ fontWeight: "600" }}>VOCÊ</strong>{" "}
          poderá utilizar as funcionalidades da plataforma e gerenciar seus
          dados de saúde.
        </li>

        <li>
          Para dúvidas técnicas, entre em contato pelo e-mail:{" "}
          <a href="mailto:contato@fluxmed.com.br">contato@fluxmed.com.br</a>.
        </li>
      </ol>

      <h4 style={{ fontWeight: "600" }}>6. TEMPO DE ARMAZENAMENTO</h4>
      <ol>
        <li>
          A <strong style={{ fontWeight: "600" }}>Core Consulting</strong> não é
          obrigada a conservar seus dados pessoais; o estabelecimento de saúde é
          responsável por essa conservação.
        </li>
      </ol>

      <h4 style={{ fontWeight: "600" }}>7. MEDIDAS DE SEGURANÇA</h4>
      <ol>
        <li>
          Leia este Termo com atenção antes de realizar o cadastro na Plataforma
          Fluxmed.
        </li>
        <li>
          Após o cadastro, seus dados de{" "}
          <strong style={{ fontWeight: "600" }}>login</strong> e{" "}
          <strong style={{ fontWeight: "600" }}>senha</strong> são pessoais. Não
          os revele a ninguém.
        </li>
        <li>
          <strong style={{ fontWeight: "600" }}>VOCÊ</strong> é o responsável
          pelos seus dados de cadastro. A{" "}
          <strong style={{ fontWeight: "600" }}>Core Consulting</strong> não
          será responsável por garantir a sua identidade.
        </li>
        <li>
          Todos os acessos à Plataforma Fluxmed são rastreáveis e podem ser
          visualizados por <strong style={{ fontWeight: "600" }}>VOCÊ</strong>.
        </li>
      </ol>

      <h4 style={{ fontWeight: "600" }}>
        8. INSTRUÇÕES FINAIS PARA O BOM USO DO APLICATIVO E LIMITES
      </h4>
      <ol>
        <li>
          Ao utilizar a Plataforma Fluxmed,{" "}
          <strong style={{ fontWeight: "600" }}>VOCÊ</strong> reconhece que a
          disponibilidade e o funcionamento estão sujeitos a interrupções devido
          a fatores fora do controle da{" "}
          <strong style={{ fontWeight: "600" }}>Core Consulting</strong>.
        </li>
        <li>
          Em casos de interrupções, siga as instruções fornecidas na plataforma
          e entre em contato com o{" "}
          <strong style={{ fontWeight: "600" }}>suporte técnico</strong>.
        </li>
        <li>
          Para urgência ou emergência, procure{" "}
          <strong style={{ fontWeight: "600" }}>atendimento presencial</strong>.
        </li>
      </ol>
    </main>
  ) : (
    <main>
      <h3 style={{ textAlign: "center" }}>
        TERMOS DE USO DA PLATAFORMA FLUXMED
      </h3>

      <section style={{ marginBottom: "20px" }}>
        <h4>1. CONSIDERAÇÕES INICIAIS</h4>
        <p style={{ fontSize: "16px", marginBottom: "10px" }}>
          1.1. O presente documento contém os termos e as condições de uso da
          Plataforma Fluxmed, que pertence à CORE CONSULTORIA E SERVIÇOS LTDA.,
          CNPJ/MF sob o nº 05.490.544/0001-00, situada no SIG QUADRA 1, 385,
          sala 404, Zona Industrial, CEP: 70.610-410, Brasília/DF (Core
          Consulting). Este documento é de adesão por parte do Contratante
          identificada por meio da Plataforma Fluxmed no momento de criação de
          seu cadastro (Contratante).
        </p>
        <p style={{ fontSize: "16px", marginBottom: "10px" }}>
          1.2. A Contratante e a Core Consulting, sempre que em conjunto
          referidas, para efeitos deste documento de adesão, serão designadas
          como “Partes” ou, individualmente, como “Parte”, e concordam, em
          boa-fé e com livre e espontânea vontade, o quanto segue.
        </p>
        <p style={{ fontSize: "16px", marginBottom: "10px" }}>
          1.3. Preliminarmente, considerando que:
        </p>
        <ul
          style={{ marginLeft: "20px", fontSize: "16px", marginBottom: "10px" }}
        >
          <li>
            1.3.1. A Contratante é uma organização de saúde que exerce
            atividades de atenção à saúde humana. O CNPJ e CNAE da Contratante
            será verificado junto à Receita Federal no momento da realização do
            cadastro na plataforma.
          </li>
          <li>
            1.3.2. A Core Consulting é uma empresa de tecnologia que exerce
            atividades de tecnologia da informação voltadas para o
            desenvolvimento de programas de computador sob encomenda. É a
            detentora da marca “Fluxmed”, conforme certificado de registro de
            marca emitido pelo Instituto Nacional da Propriedade Industrial
            (“INPI”), n. 922355134, bem como de todos os direitos materiais e
            imateriais a ela relacionados.
          </li>
          <li>
            1.3.3. A Core Consulting é a desenvolvedora e detentora de todos os
            direitos de propriedade material e imaterial relacionados ao
            software que compreende a Plataforma Interoperável de
            Desenvolvimento de Serviços Digitais para a Saúde (“Plataforma”)
            denominada “Plataforma Fluxmed”.
          </li>
          <li>
            1.3.4. A Plataforma consiste em 03 (três) funcionalidades,
            desenvolvidas em conformidade com a Lei Geral de Proteção de Dados
            Pessoais (Lei n. 13.709/2018 – “LGPD”):
          </li>
        </ul>
        <ul
          style={{ marginLeft: "40px", fontSize: "16px", marginBottom: "10px" }}
        >
          <li>
            1.3.4.1. Fluxmed Siis: ideal para, por exemplo, consultórios
            individuais, pequenas clínicas, credenciados ou não de planos de
            saúde. Permite o registro clínico, agendamento para pacientes e
            profissionais de saúde, teleatendimento, prescrições eletrônicas
            CFM/ICP Brasil. Consiste em um Registro Eletrônico de Saúde (SRES)
            do paciente, atendendo aos padrões de representação, terminologia e
            interoperabilidade de forma integrada em padrão HL7 FHIR.
          </li>
          <li>
            1.3.4.2. Fluxmed API´s: plataforma de interoperabilidade HL7 FHIR
            cujo público-alvo são os interessados em utilizar os recursos da
            Plataforma em produtos ou sistemas de terceiros ou próprios,
            permitindo o acesso padronizado HL7 FHIR a uma moderna plataforma de
            desenvolvimento para serviços digitais de saúde, que entrega valor
            agregado, agendamentos, registros clínicos, MPI, gestão de
            terminologias e data lake de informações clínicas, e ainda,
            interoperabilidade escalável. Trata-se de serviço acessório que
            estará sujeito às condições de termo de uso específico com preço
            específico.
          </li>
          <li>
            1.3.4.3. Fluxmed Analítico: permite o tratamento de dados já
            ingeridos e processados na Plataforma e, consequentemente a gestão
            de saúde populacional, administrativas e do cuidado a partir do da
            base de dados com informações clínicas e administrativas
            normalizadas, é possível criar painéis analíticos anonimizados,
            alinhados às necessidades de gestão de saúde populacional e muito
            mais, com segurança e sigilo. Trata-se de serviço acessório que
            estará sujeito às condições de termo de uso específico com preço
            específico.
          </li>
        </ul>
        <p style={{ fontSize: "16px", marginBottom: "10px" }}>
          1.3.5. Em resumo, a Plataforma consiste em um software que realiza
          funções de comunicação e arquivamento de dados de saúde (prontuário
          eletrônico, agendamento, transmissão de dados e comunicação). Assim
          sendo, a Plataforma não oferece funcionalidades relacionadas a
          prevenção, diagnóstico, tratamento, reabilitação ou anticoncepção e,
          desta forma, ela não se enquadra nas regras descritas na Resolução da
          Diretoria Colegiada (RDC) nº 185 de 22 de outubro de 2001 e na RDC nº
          657/2022, ambas da Agência Nacional de Vigilância Sanitária (Anvisa).
          Portanto, a Plataforma não é considerada um software como dispositivo
          médico (Software as a Medical Device – SaMD) e não precisa ser
          regularizada junto a Gerência de Tecnologia em Equipamentos
          (GQUIP/GGTPS/ANVISA).
        </p>
        <p style={{ fontSize: "16px", marginBottom: "10px" }}>
          1.3.6. A Contratante tem o interesse de contratar e usar a Plataforma
          Fluxmed, hospedado e acessível centralmente via internet/nuvem
          localizada no Brasil, na modalidade de Software como Serviço (Software
          as a Service – SaaS), acompanhada de serviços prestados pela Core
          Consulting. A Contratante será exclusivamente responsável pela gestão
          da informação clínica e todas as ações e atividades relacionadas à
          prestação de serviços de saúde e pela atuação de seus profissionais da
          saúde, que devem estar a ela vinculados.
        </p>
      </section>

      <section style={{ marginBottom: "20px" }}>
        <h4>1.4. Declarações das Partes</h4>
        <p style={{ fontSize: "16px", marginBottom: "10px" }}>
          As Partes declaram que concordam e aceitam, em boa-fé e livre vontade,
          os Termos e Condições de Uso da Plataforma Fluxmed, conforme o
          presente documento, que mutuamente outorgam e aceitam.
        </p>
      </section>

      <h2 style={{ color: "#333", fontSize: "24px" }}>2. OBJETO</h2>
      <p style={{ fontSize: "16px", lineHeight: "1.5" }}>
        2.1. Este documento tem a finalidade de determinar as condições e os
        termos de uso da Plataforma Fluxmed e as obrigações das Partes cujo
        objeto compreende:
      </p>
      <ul style={{ fontSize: "16px", lineHeight: "1.5", paddingLeft: "20px" }}>
        <li>
          2.1.1. A adesão, por parte da Contratante, de uso do software
          denominado Fluxmed da Core Consulting.
        </li>
      </ul>
      <p style={{ fontSize: "16px", lineHeight: "1.5" }}>
        2.2. Incluídos no preço de subscrição, os serviços atrelados ao objeto
        do Documento e prestados pela Core Consulting serão os seguintes:
      </p>
      <ul style={{ fontSize: "16px", lineHeight: "1.5", paddingLeft: "20px" }}>
        <li>
          2.2.1. <strong>Suporte Técnico e Manutenção:</strong> Assistência para
          resolver questões técnicas para que o software funcione de maneira
          otimizada. Isso inclui atualizações regulares para corrigir bugs e
          melhorar a segurança e a disponibilidade. Por padrão as atualizações
          de software referente a correção de bugs, melhoria de segurança e
          disponibilidade serão realizados pela monitoração ativa da plataforma.
          Será disponibilizado um canal digital para abertura de chamados de
          suporte técnico. Será realizada triagem de criticidade do chamado com
          resposta ao cliente em até 24 horas. De acordo com a criticidade,
          alta, média ou baixa, será informado o prazo de ajuste, que pode ser
          de até 5 dias úteis.
        </li>
        <li>
          2.2.2. <strong>Instalação e Configuração:</strong> Ajuda na instalação
          do software e na configuração inicial para assegurar que o software
          esteja pronto para uso conforme as necessidades específicas do usuário
          da Contratante. Os manuais do usuário serão disponibilizados na
          própria Plataforma e integrarão o presente documento.
        </li>
        <li>
          2.2.3. <strong>Customização:</strong> se desejado pela Contratante,
          serviços que adaptam o software às necessidades específicas da
          Contratante, incluindo o desenvolvimento de funcionalidades
          personalizadas ou integrações com outros sistemas, não estão inclusos
          no preço desta subscrição. Para customizações, entre em contato com a
          Core Consulting através do site.
        </li>
        <li>
          2.2.4. <strong>Backup e Recuperação de Dados:</strong> a Plataforma é
          hospedada centralmente via internet/nuvem. O backup da Plataforma será
          automatizado com recursos da nuvem. A Plataforma oferece mecanismos
          para que a Contratante realize backups de seus registros e é a
          responsável por isso.
        </li>
        <li>
          2.2.5. <strong>Atualizações e Upgrades:</strong> Fornecimento de novas
          versões do software para garantir acesso contínuo às últimas
          funcionalidades, melhorias de desempenho e correções de segurança. É
          possível que novas funcionalidades resultem em ajuste no preço.
        </li>
      </ul>
      <p style={{ fontSize: "16px", lineHeight: "1.5" }}>
        2.3. As Partes declaram e reconhecem que o paciente é o protagonista de
        sua própria saúde e o titular de seus dados pessoais e dados pessoais
        sensíveis, podendo revogar, ou não, o consentimento de acesso às suas
        informações.
      </p>

      <h2 style={{ color: "#333", fontSize: "24px" }}>3. RESPONSABILIDADES</h2>
      <p style={{ fontSize: "16px", lineHeight: "1.5" }}>
        3.1. A Core Consulting é responsável por:
      </p>
      <ul style={{ fontSize: "16px", lineHeight: "1.5", paddingLeft: "20px" }}>
        <li>
          3.1.1. Observar com os termos e usos deste documento, manter a
          disponibilidade e qualidade da Plataforma.
        </li>
        <li>3.1.2. Manter atualizados os Manuais de usuário.</li>
        <li>
          3.1.3. Investir em constantes evoluções tecnológicas em prol da saúde
          digital.
        </li>
        <li>
          3.1.4. No caso de cancelamento ou extinção do Documento, por qualquer
          motivo, a Core Consulting obriga-se a manter a Plataforma disponível à
          Contratante, com acesso apenas de leitura de dados, pelo período de 30
          (trinta) dias para que a Contratante possa proceder com a observância
          da Lei n. 13.787/2018 que versa sobre a digitalização e a utilização
          de sistemas informatizados para a guarda, o armazenamento e o manuseio
          de prontuário de paciente.
        </li>
        <li>
          3.1.5. Notificar a Contratante no caso de incidente de segurança em
          até 48 (quarenta e oito) horas do conhecimento do incidente e prestar
          os esclarecimentos necessários e relacionados ao incidente à
          Contratante.
        </li>
        <li>
          3.1.6. Manter a emissão tempestiva de Notas Fiscais eletrônicas,
          referentes à subscrição de uso da Contratante.
        </li>
        <li>
          3.1.7. Informar por e-mail a Contratante sobre problemas de pagamento
          mantendo o uso da Plataforma Fluxmed por 10 dias após a informação do
          problema do pagamento.
        </li>
      </ul>
      <p style={{ fontSize: "16px", lineHeight: "1.5" }}>
        3.2. Além das obrigações previstas neste instrumento, e na legislação
        aplicável, a Contratante se compromete, ainda, a:
      </p>
      <ul style={{ fontSize: "16px", lineHeight: "1.5", paddingLeft: "20px" }}>
        <li>
          3.2.1. Efetuar todos os pagamentos da subscrição tempestivamente.
        </li>
        <li>
          3.2.2. Garantir e manter uma conexão de internet com velocidade e
          estabilidade suficientes para suportar o desempenho eficaz da
          Plataforma.
        </li>
        <li>
          3.2.3. Observar as instruções para a utilização da Plataforma e
          Manuais de usuário.
        </li>
        <li>
          3.2.4. Não realizar modificações, atualizações ou alterações no
          software (Plataforma) sem a prévia aprovação por escrito da Core
          Consulting. Qualquer modificação desautorizada pode levar a problemas
          de compatibilidade ou funcionamento e resultar em violação das
          condições de uso do software, isentando a Core Consulting de qualquer
          responsabilidade.
        </li>
        <li>
          3.2.5. A Contratante está proibida de sublicenciar, alugar ou
          emprestar o uso software (Plataforma) a terceiros.
        </li>
        <li>
          3.2.6. Em caso de instabilidades, falhas ou qualquer outro problema
          técnico com o software (Plataforma), a Contratante se obriga a abrir
          chamados de suporte técnico imediatamente ao conhecimento do problema.
        </li>
      </ul>
      <h3>4. LIMITAÇÃO DE RESPONSABILIDADE E FORÇA MAIOR</h3>
      <p>
        <strong>4.1. Limitação de Responsabilidade e Força Maior.</strong> As
        Partes declaram e concordam que a responsabilidade total da Core
        Consulting, sob qualquer circunstância decorrente ou relacionada ao
        objeto deste documento, se comprovada será limitada à natureza da
        atuação econômica da Core Consulting e suas responsabilidades. Para não
        haver dúvidas, em nenhuma hipótese a Core Consulting será
        responsabilizada por danos sofridos pelo paciente da Contratante
        relacionados à prestação de serviço de saúde pela Contratante.
      </p>
      <p>
        <strong>4.1.1. Força Maior:</strong> Nenhuma das partes será responsável
        por falhas ou atrasos no desempenho de suas obrigações sob este
        documento devido a eventos de força maior, que incluem, mas não se
        limitam a desastres naturais, guerras, atos de terrorismo, greves,
        falhas ou atrasos de Internet, falhas de energia, e intervenções
        governamentais. Em tais circunstâncias, os prazos para o desempenho
        serão prorrogados por um período de tempo razoável, considerando a
        duração do evento de força maior. A seguir encontram-se exemplos que
        isentam a Core Consulting de qualquer responsabilidade perante a
        Contratante:
      </p>
      <ul>
        <li>
          <strong>
            4.1.1.1. Falhas na internet contratada pela contratante:
          </strong>{" "}
          a empresa de tecnologia não pode ser responsabilizada por problemas de
          conectividade que ocorram devido a falhas na internet contratada pela
          Contratante;
        </li>
        <li>
          <strong>4.1.1.2. Manutenção programada:</strong> interrupções
          agendadas para manutenção ou atualizações do sistema, sobre as quais a
          Contratante foi previamente notificada;
        </li>
        <li>
          <strong>4.1.1.3. Desastres naturais:</strong> eventos fora do controle
          da empresa, como terremotos, furacões, enchentes ou outros desastres
          naturais que afetem a infraestrutura necessária para o serviço;
        </li>
        <li>
          <strong>4.1.1.4. Ataques cibernéticos de terceiros:</strong> ataques
          por hackers ou outras entidades maliciosas que não sejam resultado de
          negligência por parte da Core Consulting;
        </li>
        <li>
          <strong>4.1.1.5. Falhas de equipamentos da Contratante:</strong>{" "}
          problemas causados por hardware obsoleto ou mal mantido pela
          Contratante, que afetem a operação do SaaS;
        </li>
        <li>
          <strong>
            4.1.1.6. Alterações não autorizadas pela Core Consulting:
          </strong>{" "}
          modificações feitas pela Contratante na configuração ou no uso da
          plataforma sem consentimento ou aprovação da Core Consulting;
        </li>
        <li>
          <strong>
            4.1.1.7. Conflitos de software causados por terceiros:
          </strong>{" "}
          interferências ou incompatibilidades causadas por software ou hardware
          de terceiros não fornecidos pela Core Consulting;
        </li>
        <li>
          <strong>4.1.1.8. Limitações tecnológicas conhecidas:</strong>{" "}
          restrições ou limitações tecnológicas da plataforma que são conhecidas
          e documentadas e sobre as quais a Contratante foi informada;
        </li>
        <li>
          <strong>4.1.1.9. Uso inadequado da plataforma:</strong> uso do
          software de maneira contrária às instruções fornecidas ou às práticas
          recomendadas estabelecidas pela Core Consulting;
        </li>
        <li>
          <strong>4.1.1.10. Força maior:</strong> qualquer outro evento fora do
          controle razoável da Core Consulting, como greves, tumultos, guerra,
          pandemias, ou intervenções governamentais que impeçam a continuidade
          dos serviços;
        </li>
      </ul>
      <p>
        <strong>4.2.</strong> Quando comprovado que o problema técnico reside na
        nuvem, a Core Consulting não será responsável por eventos como
        instabilidade ou até mesmo indisponibilidade da Plataforma.
      </p>

      <h3>5. VIGÊNCIA E PREÇO DOS SERVIÇOS</h3>
      <p>
        <strong>5.1.</strong> A vigência da subscrição de uso e o preço serão
        disponibilizados online pelos recursos da Plataforma Fluxmed e poderão
        ser ajustados ao longo da evolução tecnológica das funcionalidades. Os
        pagamentos serão antecipados. No caso de extinção antecipada da
        subscrição, prevalecerá o preço contratado e nenhum estorno será
        efetuado por parte da Core Consulting.
      </p>

      <h3>6. RESCISÃO</h3>
      <p>
        <strong>6.1.</strong> A subscrição poderá ser cancelada pela Contratante
        a qualquer momento pelos mecanismos oferecidos pela Plataforma Fluxmed.
      </p>
      <p>
        <strong>6.2.</strong> A Core Consulting poderá cancelar a subscrição por
        justo motivo, devendo notificar a Contratante com 10 (dez) dias de
        antecedência. Caso for detectado o uso indevido da Plataforma Fluxmed,
        por parte da Contratante, a Core Consulting notificará a Contratante e
        suspenderá imediatamente a subscrição até que seja corrigido.
      </p>

      <h3>7. CONFIDENCIALIDADE</h3>
      <p>
        <strong>7.1.</strong> As Partes se comprometem a guardar o mais absoluto
        sigilo das informações fornecidas pela outra parte para a consecução do
        objeto deste Documento, consideradas de valor inestimável para ambas,
        comprometendo-se a as usarem-nas apenas para os fins específicos a que
        se refere a presente contratação. A parte que não cumprir esta obrigação
        será responsável por eventuais danos causados que venham a ser apurados,
        oportunamente.
      </p>
      <p>
        <strong>7.2.</strong> As Partes, neste ato, obrigam-se, por si, seus
        representantes, prepostos, empregados e/ou subcontratados a tratar com
        absoluto sigilo e confidencialidade toda e qualquer informação, dados
        econômicos ou técnicos, desenhos, projetos, procedimentos, manuais,
        disponibilizados por ambas durante a vigência do presente documento, não
        podendo, em nenhuma hipótese, revelá-los a terceiros e/ou divulgá-los
        sob qualquer forma e pretexto, ou utilizá-los em proveito próprio ou de
        terceiros para fins estranhos ao do presente documento, salvo mediante
        expressa autorização por escrito dos demais interessados.
      </p>
      <p>
        <strong>7.3.</strong> As partes adotarão rigorosas medidas de proteção
        das informações confidenciais da outra Parte para evitar que elas sejam
        de qualquer modo divulgadas, reveladas, publicadas, vendidas, cedidas ou
        de qualquer outra forma transferidas pela Parte Receptora, seus
        representantes, prepostos, empregados e/ou subcontratados.
      </p>
      <p>
        <strong>7.4.</strong> A Contratante é a única responsável pela origem
        das informações por ela prestadas para a Core Consulting para a
        consecução dos Serviços ora contratados e neste ato expressamente
        declara que o conteúdo da base de vidas e das informações nela contidas
        e que serão fornecidas à Core Consulting não viola qualquer lei ou
        qualquer direito de terceiros, incluindo, sem limitação, direitos de
        propriedade intelectual e direitos autorais, isentando e indenizando a
        Core Consulting, a qualquer tempo, mesmo após a expiração ou término
        deste documento por qualquer motivo, de qualquer responsabilidade que
        decorra da informação equivocada ou falsa dos dados fornecidos à Core
        Consulting.
      </p>
      <p>
        <strong>7.4.1.</strong> A Core Consulting está isenta de
        responsabilidade pelo conteúdo de toda e qualquer informação, dados
        pessoais, econômicos ou técnicos relacionados ao beneficiário,
        disponibilizados pela Contratante, a qual é responsável pela origem e
        exatidão das informações que disponibilizará à Core Consulting para a
        realização dos serviços objeto do presente instrumento.
      </p>
      <p>
        <strong>7.5.</strong> A Core Consulting expressamente compromete-se a
        adotar as medidas técnicas e organizacionais necessárias para garantir a
        segurança dos dados pessoais dos aderentes da Contratante e evitar a sua
        alteração, perda e acesso não autorizados, dada a natureza pessoal dos
        dados armazenados e os riscos a que estão expostos, quer da ação humana
        ou o ambiente físico ou eletrônico em que estejam armazenados.
      </p>
      <p>
        <strong>7.6.</strong> A Contratante está ciente, ainda, de que o
        prontuário, guias, informativos enviados pela Core Consulting aos
        beneficiários são documentos não compartilháveis, cuja propriedade
        pertence exclusivamente ao beneficiário, sendo que Core Consulting
        reserva o direito de não fornecer cópia à Contratante, em hipótese
        alguma, sob pena de violação do sigilo e privacidade do beneficiário,
        exceto mediante o consentimento inequívoco, nos termos da Lei.
      </p>
      <p>
        <strong>7.7.</strong> As obrigações relativas à confidencialidade das
        informações técnicas e de negócio, trocadas entre as Partes para a
        execução deste Documento, perdurarão pelo prazo de 02 (dois) anos após a
        extinção deste Documento, exceto no que se tratar de segredo comercial e
        industrial, e direitos personalíssimos de indivíduos, como dados
        pessoais sensíveis.
      </p>
      <h3>8. PRIVACIDADE E PROTEÇÃO DE DADOS PESSOAIS</h3>
      <p>
        8.1. Relativamente à Lei Geral de Proteção de Dados Pessoais (Lei
        13.709/2018 – LGPD), sem prejuízo das demais disposições dispostas neste
        instrumento, as Partes declaram o seguinte:
      </p>
      <ul>
        <li>
          <strong>8.1.1.</strong> As Partes protegem a confidencialidade de
          dados pessoais e dados sensíveis que lhe são confiados pelos titulares
          desses dados em razão deste documento. Declaram, inclusive em nome de
          seus empregados, cooperados, prepostos e subcontratados que cumprem a
          legislação brasileira sobre privacidade, disposta na Constituição
          Federal, o Código de Defesa do Consumidor, o Código Civil, a Lei Geral
          de Proteção de Dados “LGPD” (Lei Federal nº 13.709/2018), o Marco
          Civil da Internet (Lei Federal nº 12.965/2014), seu decreto
          regulamentador (Decreto nº 8.771/2016) e demais normas setoriais ou
          gerais sobre proteção de dados pessoais. Para isso, vêm implementando
          medidas de segurança, técnicas e administrativas, aptas a proteger
          dados pessoais e dados sensíveis contra acessos não autorizados e de
          situações acidentais, ou qualquer forma de tratamento inadequado,
          necessárias ao cumprimento da Lei Geral de Proteção de Dados (Lei nº
          13.709/2018).
        </li>
        <li>
          <strong>8.1.2.</strong> Regras de boas práticas e de governança
          garantem que o tratamento de dados pessoais e sensíveis seja lícito,
          leal, limitado às finalidades autorizadas a que se destina e
          respeitando-se os princípios da transparência, necessidade,
          proporcionalidade e não-discriminação. A coleta de dados pessoais e
          dados sensíveis para tratamento é realizada pelas Partes com base em
          medidas necessárias para assegurar a exatidão, integridade,
          confidencialidade, e anonimização, bem como garantir o respeito à
          liberdade, privacidade, inviolabilidade da intimidade, imagem, enfim,
          todos os direitos e liberdades dos titulares, inclusive o exercício do
          direito de solicitar acesso, correção e eliminação de dados pessoais e
          sensíveis armazenados em banco de dados e sistemas digitais, exceto
          quando a conservação permitida por lei ou obrigação regulatória (art.
          16. I da LGPD)
        </li>
        <li>
          <strong>8.1.3.</strong> As Partes deverão possuir política de
          privacidade, diretrizes de segurança da informação e mecanismos de
          mitigação de riscos, com vistas a garantir a segurança do tratamento
          de dados pessoais e dados pessoais sensíveis, por exemplo, relatório
          de impacto à proteção de dados pessoais (art. 5º, XVII da LGPD), a fim
          de evitar incidentes de segurança como vazamento de informações,
          inclusive causados internamente, com evidências acessíveis de que tal
          conteúdo é comunicado e disseminado para todos seus aderentes.
        </li>
        <li>
          <strong>8.1.4.</strong> Para fins deste Documento a Core Consulting
          atua como “Operador de dados”, nos termos da LGPD e a Contratante atua
          como “Controlador de dados”, nos termos da LGPD.
          <ul>
            <li>
              <strong>8.1.4.1.</strong> A Core Consulting responderá
              solidariamente com a Contratante pelos danos causados pelo
              tratamento quando ficar comprovado que ela descumpriu as
              obrigações da legislação de proteção de dados ou quando não tiver
              seguido as instruções lícitas da Contratante, salvo nos casos de
              exclusão previstos no art. 43 da LGPD.
            </li>
            <li>
              <strong>8.1.4.2.</strong> A Contratante responderá solidariamente
              com a Core Consulting pelos danos causados ao titular pelo
              tratamento que estiverem diretamente envolvidos, salvo nos casos
              de exclusão previstos no art. 43 da LGPD.
            </li>
          </ul>
        </li>
      </ul>

      <h3>9. DISPOSIÇÕES GERAIS E FINAIS</h3>
      <ul>
        <li>
          <strong>9.1.</strong> O presente documento é firmado por pessoas
          inteiramente distintas, não podendo vir a ser caracterizado como
          qualquer forma de sociedade ou associação, sendo certo que as Partes
          não terão qualquer outro tipo de relação, a não ser o decorrente da
          relação regida por este Documento.
        </li>
        <li>
          <strong>9.2.</strong> Cada Parte será totalmente responsável por suas
          obrigações trabalhistas, fiscais ou civis, contraídas, inexistindo
          qualquer forma de vínculo trabalhista entre as Partes, e outras
          pessoas jurídicas de qualquer forma relacionadas com este Documento.
        </li>
        <li>
          <strong>9.3.</strong> As Partes comprometem-se a:
          <ul>
            <li>
              <strong>9.3.1.</strong> Agir dentro das leis e regulamentos
              aplicáveis e obedecer aos mais estritos e rigorosos conceitos e
              princípios da ética, moralidade e boa-fé na condução dos negócios
              conjuntos, incluindo, mas não se limitando, a evitar relações,
              contatos e/ou parcerias comerciais com quaisquer agentes que por
              qualquer meio sabidamente participem ou tenham participado em
              atividades ilícitas, de qualquer espécie;
            </li>
            <li>
              <strong>9.3.2.</strong> Possuir todas as autorizações e licenças
              para operar seu negócio da forma como atualmente é operado e
              manter, durante o período de vigência deste Documento, todas as
              aprovações, permissões, registros e autorizações governamentais ou
              não governamentais.
            </li>
          </ul>
        </li>
      </ul>
    </main>
  );
}
