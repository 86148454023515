import {
  Stack,
  Step,
  StepButton,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
} from "@mui/material";
import { ReactElement } from "react";
import {
  Accessibility,
  Check,
  LocalHospital,
  NoteAlt,
} from "@mui/icons-material";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import VideoConferencia from "../../../../components/videoConferencia/VideoConferencia";
import { useLocation } from "react-router-dom";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { useFhirData } from "../../../../utils/useFhirData";

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage: "linear-gradient(  #009E8C 50%, #009E8C 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage: "linear-gradient(  #009E8C 50%, #009E8C 100%)",
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: ReactElement } = {
    1: <Accessibility />,
    2: <LocalHospital />,
    3: <NoteAlt />,
    4: <Check />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
}));

export function StepArea() {
  const { activeStep, setActiveStep } = useMedicalCareContext();
  const steps = ["Atendimento", "Intervenções", "Documentos e assinatura"];

  const { state } = useLocation();
  const { user } = useSessionContext();

  const { clinicalLocation } = useMedicalCareContext();

  return (
    <Stack
      direction="row"
      pl={5}
      spacing={1}
      alignItems="center"
      sx={{
        width: "100%",
        height: "fit-content",
      }}
    >
      <Stack
        sx={{
          width: "100%",
          height: "fit-content",
        }}
      >
        <Stepper
          sx={{ height: "fit-content" }}
          nonLinear
          // alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps?.map((label, index) => (
            <Step key={label}>
              <StepButton onClick={() => setActiveStep(index)}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Stack>

      {useFhirData.virtualService(clinicalLocation) && (
        <Stack width="240px">
          <VideoConferencia
            roomName={`Teleconsulta-${state?.encounterReference?.appointmentId}`}
            displayName={user.name}
            email={user?.email?.[0]}
          />
        </Stack>
      )}
    </Stack>
  );
}
