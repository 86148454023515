import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ChevronLeft, ChevronRightTwoTone } from "@mui/icons-material";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";

import { useDatetime } from "../../../utils/useDatetime";
import { useArray } from "../../../utils/useArray";
import { DataSkeleton, NoDataWarning } from "../../../components/common";
import { ConfirmAppointmentDialog } from "./creatappointmentdialog/ConfirmAppointmentDialog";
import { isMobile } from "react-device-detect";

interface IProps {
  slots: fhir5.BundleEntry<fhir5.Slot>[] | undefined;
  loading: boolean;
  location: fhir5.Location;
  setRefetchSlots: Dispatch<SetStateAction<boolean>>;
}

const DAYS_PER_PAGE = isMobile ? 3 : 4;

export function DisponibilityTab({
  slots,
  loading,
  location,
  setRefetchSlots,
}: IProps) {
  const [isConfirmAppointmentOpen, setIsConfirmAppointmentOpen] =
    useState<boolean>(false);

  const [scheduleId, setScheduleId] = useState("");
  const [selectedSlot, setSelectedSlot] = useState<fhir5.Slot>();

  const [page, setPage] = useState(0);
  const { uniqByReduce } = useArray;
  const { getWeekDayTranslation, formatToDDMM, formatToHHmm } = useDatetime;

  const filteredSlots: fhir5.BundleEntry<fhir5.Slot>[] | undefined =
    slots?.filter(
      (el) =>
        el.resource?.start &&
        dayjs(el.resource.start).isAfter(dayjs(), "minute")
    );

  const startDate = filteredSlots?.map((el: fhir5.BundleEntry<fhir5.Slot>) => {
    return el.resource?.start.split("T")?.[0];
  });

  const dates = startDate && uniqByReduce(startDate);

  useEffect(() => {
    setPage(0);
  }, [slots]);

  return (
    <>
      <Stack
        sx={{ paddingBottom: "40px" }}
        spacing={1}
        width="100%"
        direction="row"
        alignItems="flex-start"
        justifyContent="center"
      >
        <IconButton
          onClick={() => setPage((prev) => prev - DAYS_PER_PAGE)}
          disabled={
            dates === undefined ||
            dates?.length === 0 ||
            page - DAYS_PER_PAGE < 0
          }
        >
          <ChevronLeft fontSize="small" />
        </IconButton>

        {dates !== undefined && dates.length !== 0 ? (
          <Stack
            width="100%"
            spacing={2}
            direction="row"
            flex={1}
            sx={{
              justifyContent: "center",
            }}
          >
            {dates
              .sort((a, b) => {
                let dateA = dayjs(a);
                let dateB = dayjs(b);
                return dateA.isAfter(dateB) ? 1 : -1;
              })
              .slice(page, page + DAYS_PER_PAGE)
              .map((el) => {
                const date: Dayjs = dayjs(el);
                return (
                  <Stack
                    maxWidth="fit-content"
                    rowGap={1}
                    key={`date-column-${el}`}
                  >
                    <Typography
                      textAlign="center"
                      width="75px"
                      textTransform="capitalize"
                    >
                      {getWeekDayTranslation(date)}
                    </Typography>
                    <Typography
                      textAlign="center"
                      width="75px"
                      textTransform="capitalize"
                    >
                      {formatToDDMM(date)}
                    </Typography>
                    {filteredSlots
                      ?.filter(
                        (el) =>
                          el.resource?.start &&
                          dayjs(el.resource.start).isSame(date, "day")
                      )
                      .sort((a, b) => {
                        let dateA = dayjs(a.resource?.start);
                        let dateB = dayjs(b.resource?.start);
                        return dateA.isAfter(dateB) ? 1 : -1;
                      })
                      .map((el) => (
                        <Button
                          key={el?.resource?.id}
                          onClick={() => {
                            setIsConfirmAppointmentOpen(true);
                            setScheduleId(
                              el?.resource?.schedule?.reference?.split(
                                "/"
                              )[1] || ""
                            );
                            setSelectedSlot(el.resource);
                          }}
                          sx={{
                            backgroundColor: "primary300.main",
                            color: "black",
                            "&:hover": {
                              backgroundColor: "primary200.main",
                            },
                            borderRadius: "10px",
                            boxShadow: "0 1px 4px rgba(0,0,0,0.25)",
                          }}
                        >
                          {formatToHHmm(dayjs(el.resource?.start))}
                        </Button>
                      ))}
                  </Stack>
                );
              })}
          </Stack>
        ) : loading ? (
          <DataSkeleton />
        ) : (
          <NoDataWarning />
        )}

        <IconButton
          onClick={() => setPage((prev) => prev + DAYS_PER_PAGE)}
          disabled={
            dates === undefined ||
            dates?.length === 0 ||
            (!!dates?.length && page + DAYS_PER_PAGE >= dates?.length)
          }
        >
          <ChevronRightTwoTone fontSize="small" />
        </IconButton>
      </Stack>
      {isConfirmAppointmentOpen && (
        <ConfirmAppointmentDialog
          isOpen={isConfirmAppointmentOpen}
          setIsOpen={setIsConfirmAppointmentOpen}
          location={location}
          scheduleId={scheduleId}
          slot={selectedSlot}
          setRefetchSlots={setRefetchSlots}
        />
      )}
    </>
  );
}
