import { Stack, Typography } from "@mui/material";

interface IProps {
  subText: string;
  subTitle?: string;
  align?: "left" | "center" | "end";
}

export function PageSubTitle({ subText, subTitle, align }: IProps) {
  return (
    <>
      {!subTitle ? (
        <Stack sx={{ textAlign: `${align}` }}>
          <Typography variant="body1" color="neutral700.main">
            {subText}
          </Typography>
        </Stack>
      ) : (
        <Stack sx={{ textAlign: "start" }} paddingTop={2}>
          <Typography
            variant="subtitle2"
            color="neutral700.main"
            fontWeight={600}
          >
            {subTitle}
          </Typography>
          <Typography variant="body1" color="neutral700.main">
            {subText}
          </Typography>
        </Stack>
      )}
    </>
  );
}
