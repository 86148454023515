import { CalendarToday, PersonAdd } from "@mui/icons-material";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import {
  DateCalendar,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/pt-br";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { DateFilterType } from "../../utils/interfaces";
import { useFhirData } from "../../utils/useFhirData";
import { NoDataWarning } from "../common/NoDataWarning";
import { AppointmentDetails } from "./AppointmentDetails";
import { CardSchedule } from "./CardSchedule";
import DateFilter from "./DateFilter";
import { DataSkeleton } from "../common/DataSkeleton";
import { CreateAppointmentDialog } from "../../pages/schedulemanager/components/creatappointmentdialog";
import { usePractitionerRoleContext } from "../../contexts/PractitionerRoleContext";
import { isMobile } from "react-device-detect";
import { ScheduleCardsMobile } from "./ScheduleCardsMobile";
import { CommonDialog } from "../common/CommonDialog";
import { useSessionContext } from "../../contexts/SessionContext";
import VideoConferencia from "../videoConferencia/VideoConferencia";
import { useLocationContext } from "../../contexts/LocationContext";
import { SecondaryButton } from "../common";
import { useNavigate } from "react-router-dom";

interface IProps {
  bundle: any | undefined;
  hasActiveSchedule?: any;
  relatedPatient?: any;
  dateFilter: Dayjs;
  setDateFilter: Dispatch<SetStateAction<Dayjs>>;
  filterType: DateFilterType;
  setFilterType: Dispatch<SetStateAction<DateFilterType>>;
  setRefetch: Dispatch<SetStateAction<boolean>>;
  refetch: boolean;
  loading: boolean;
}

export function Schedule({
  hasActiveSchedule,
  bundle,
  relatedPatient,
  dateFilter,
  filterType,
  setDateFilter,
  setFilterType,
  loading,
  setRefetch,
  refetch,
}: IProps) {
  const [createAppointmentDialogOpen, setCreateAppointmentDialogOpen] =
    useState<boolean>(false);
  const { user, access, userPatient } = useSessionContext();
  const { userPractitioner } = usePractitionerRoleContext();
  const { location, setIdsOrganizations } = useLocationContext();
  const { extractAppointmentData } = useFhirData;

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openDatePickerMobile, setOpenDatePickerMobile] = useState(false);
  const [dateFieldValue, setDateFieldValue] = useState(dayjs());
  const [selectedCard, setSelectedCard] = useState(0);
  const navigate = useNavigate();

  const [openAppointmentDetails, setOpenAppointmentDetails] = useState(false);

  const isAppointmentBundleEntry = (
    entry: fhir5.BundleEntry<fhir5.Appointment | fhir5.Patient | fhir5.Location>
  ): entry is fhir5.BundleEntry<fhir5.Appointment> =>
    entry.resource?.resourceType === "Appointment";

  const isPatientBundleEntry = (entry: any): any => {
    return entry.resource?.resourceType === "Patient";
  };

  const isLocationBundleEntry = (
    entry: fhir5.BundleEntry<fhir5.Appointment | fhir5.Patient | fhir5.Location>
  ): entry is fhir5.BundleEntry<fhir5.Location> =>
    entry.resource?.resourceType === "Location";

  const patientsBundle = bundle?.entry?.filter(isPatientBundleEntry);

  const locationsBundle = bundle?.entry?.filter(isLocationBundleEntry);

  const appointmentsBundle = bundle?.entry
    ?.filter(isAppointmentBundleEntry)
    ?.filter((el: any) =>
      access?.type === "professional"
        ? true
        : el.resource?.end && new Date(el.resource?.end) > new Date(Date.now())
    );

  const locationReferences = appointmentsBundle
    ?.map((appointment: any) => appointment?.resource?.participant)
    .flat()
    .filter((participant: any) =>
      participant?.actor?.reference?.startsWith("Location/")
    )
    .map((participant: any) =>
      participant?.actor?.reference?.replace("Location/", "")
    )
    .filter(
      (reference: any, index: number, references: any) =>
        references.indexOf(reference) === index
    )
    .join(",");

  const selectedCardData = [
    appointmentsBundle?.[selectedCard]?.resource,
    locationsBundle?.filter((locationEl: any) => {
      const selectedAppointment = appointmentsBundle?.[selectedCard];
      if (selectedAppointment) {
        return (
          locationEl.resource?.id ===
          appointmentsBundle[
            selectedCard
          ]?.resource?.participant?.[1].actor?.reference?.split("/")[1]
        );
      }
      return false;
    })?.[0]?.resource,
  ].filter((el) => el !== undefined);

  const selectedCardTeleconsulting = useFhirData.virtualService(
    location?.find((loc: any) =>
      loc?.resource?.id.includes(selectedCardData?.[1]?.id)
    )?.resource
  );

  useEffect(() => {
    setSelectedCard(0);
  }, [dateFilter]);

  useEffect(() => {
    setSelectedCard(0);
  }, [refetch]);

  useEffect(() => {
    setDateFilter(dayjs(dateFieldValue));
  }, [dateFieldValue]);

  useEffect(() => {
    if (access?.type === "patient")
      setIdsOrganizations(`${locationReferences}`);
  }, [locationReferences, setIdsOrganizations, access]);

  return (
    <>
      {isMobile ? (
        <Stack
          direction="column"
          sx={{
            // overflowY: "auto",
            width: { xs: "78vw", sm: "87vw", lg: "100vw" },
          }}
          height="100%"
          gap={1}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography>Consultas agendadas</Typography>
            <Stack direction="row" gap={1}>
              <Typography>{dateFieldValue.format("MMM YYYY")}</Typography>
              <IconButton
                sx={{ height: 22, width: 22 }}
                onClick={() => setOpenDatePickerMobile((prev) => !prev)}
              >
                <CalendarToday
                  sx={{
                    color: "neutral700.main",
                  }}
                />
              </IconButton>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="pt-br"
              >
                <MobileDatePicker
                  open={openDatePickerMobile}
                  format="MM/YYYY"
                  views={["month", "year"]}
                  onOpen={() => setOpenDatePickerMobile(true)}
                  onClose={() => setOpenDatePickerMobile(false)}
                  sx={{ display: "none" }}
                  value={dateFieldValue}
                  slotProps={{ dialog: { fullScreen: true } }}
                  onChange={(e) => setDateFieldValue(dayjs(e))}
                />
              </LocalizationProvider>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            overflow="auto"
            justifyContent="center"
            sx={{
              width: "100%",
              "&::-webkit-scrollbar": {
                height: "0.5em",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "primary700.main",
              },
            }}
            gap={1}
          >
            {loading ? (
              <DataSkeleton />
            ) : appointmentsBundle?.length !== 0 &&
              appointmentsBundle !== undefined ? (
              appointmentsBundle.map((el: any, index: number) => {
                const dependente = relatedPatient?.find(
                  (e: any) =>
                    e.id ===
                    el.resource?.participant?.[0].actor?.reference?.split(
                      "/"
                    )[1]
                );
                const patient = patientsBundle?.find(
                  (patientEl: any) =>
                    patientEl.resource?.id ===
                      el.resource?.participant?.[0].actor?.reference?.split(
                        "/"
                      )[1] && patientEl?.resource?.name
                );

                const location = locationsBundle?.filter(
                  (locationEl: any) =>
                    locationEl.resource?.id ===
                    el.resource?.participant?.[1].actor?.reference?.split(
                      "/"
                    )[1]
                );

                const auxPatient =
                  access?.type === "patient"
                    ? patient || dependente || userPatient
                    : patient;
                return (
                  <ScheduleCardsMobile
                    data={extractAppointmentData(el, auxPatient, location?.[0])}
                    selected={selectedCardData}
                    setRefetch={setRefetch}
                    onClick={() => setSelectedCard(index)}
                  />
                );
              })
            ) : (
              <Stack marginTop={1} alignItems="center" spacing={2}>
                <NoDataWarning message="Não há consultas agendadas para exibir" />
                <SecondaryButton
                  width="100%"
                  height="42px"
                  disabled={false}
                  onClick={() => navigate("/schedulingsearch")}
                >
                  Agendar uma consulta
                </SecondaryButton>
              </Stack>
            )}
          </Stack>
        </Stack>
      ) : (
        <Stack
          direction={{ sx: "column", md: "row" }}
          spacing={3}
          width="100%"
          maxHeight={access?.type === "patient" ? "55vh" : "100%"}
          sx={{ alignItems: "flex-start" }}
        >
          <Stack direction="column" width="100%" spacing={1}>
            <Stack height={access.type === "professional" ? "360px" : "52vh"}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Typography
                  sx={{ color: "neutral1000.main" }}
                  variant="h5"
                  fontWeight={600}
                  textAlign="center"
                >
                  Consultas agendadas
                </Typography>
              </Stack>
              {openDatePicker && (
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="pt-br"
                >
                  <DateCalendar
                    sx={{
                      position: "absolute",
                      backgroundColor: "neutral0.main",
                      zIndex: "1000",
                      marginLeft: { lg: "110px", xs: "0px" },
                    }}
                    value={dateFilter}
                    onMonthChange={(e) => {
                      setFilterType("day");
                    }}
                    onChange={(e) => {
                      if (e) setDateFilter(e);
                      setFilterType("day");
                      setOpenDatePicker(false);
                    }}
                    minDate={dayjs()}
                  />
                </LocalizationProvider>
              )}
              <DateFilter
                dateFilter={dateFilter}
                setDateFilter={setDateFilter}
                filterType={filterType}
                setFilterType={setFilterType}
              />
              <Stack
                padding="0px 5px 0px 0px"
                mb={1}
                sx={{
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    width: "0.5em",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "primary700.main",
                  },
                }}
              >
                {loading ? (
                  <DataSkeleton />
                ) : appointmentsBundle?.length !== 0 &&
                  appointmentsBundle !== undefined ? (
                  appointmentsBundle.map((el: any, index: number) => {
                    const dependente = relatedPatient?.find(
                      (e: any) =>
                        e.id ===
                        el.resource?.participant?.[0].actor?.reference?.split(
                          "/"
                        )[1]
                    );
                    const patient = patientsBundle?.find(
                      (patientEl: any) =>
                        patientEl.resource?.id ===
                          el.resource?.participant?.[0].actor?.reference?.split(
                            "/"
                          )[1] && patientEl?.resource?.name
                    );

                    const locationId = locationsBundle?.filter(
                      (locationEl: any) =>
                        locationEl.resource?.id ===
                        el.resource?.participant?.[1].actor?.reference?.split(
                          "/"
                        )[1]
                    );

                    const locationTeleconsulting = useFhirData.virtualService(
                      location?.find((loc: any) =>
                        loc?.resource?.id.includes(
                          locationId?.[0]?.resource?.id
                        )
                      )?.resource
                    );
                    const auxPatient =
                      access?.type === "patient"
                        ? patient || dependente || userPatient
                        : patient;

                    return (
                      <CardSchedule
                        key={index}
                        data={extractAppointmentData(
                          el,
                          auxPatient,
                          location?.[0]
                        )}
                        locationTeleconsulting={locationTeleconsulting}
                        selected={selectedCard}
                        setOpenAppointmentDetails={setOpenAppointmentDetails}
                        index={index}
                        onClick={() => {
                          setSelectedCard(index);
                        }}
                      />
                    );
                  })
                ) : (
                  <>
                    {access?.type === "patient" ? (
                      <Stack alignItems="center" spacing={2}>
                        <NoDataWarning message="Não há consultas agendadas para exibir" />
                        <SecondaryButton
                          width="auto"
                          height="50px"
                          disabled={false}
                          onClick={() => navigate("/schedulingsearch")}
                        >
                          Agendar uma consulta
                        </SecondaryButton>
                      </Stack>
                    ) : (
                      <>
                        <NoDataWarning />
                      </>
                    )}
                  </>
                )}
              </Stack>

              {access?.type === "patient" &&
                selectedCardData?.[0]?.id &&
                selectedCardTeleconsulting && (
                  <VideoConferencia
                    roomName={`Teleconsulta-${selectedCardData?.[0]?.id}`}
                    displayName={user.name}
                    email={user?.email?.[0]}
                  />
                )}
            </Stack>

            <Stack spacing={1}>
              {userPractitioner ? (
                <Stack spacing={1}>
                  <>
                    {hasActiveSchedule ? (
                      <>
                        <Button
                          sx={{
                            gap: 1,
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 3,
                            fontSize: 16,
                            textTransform: "none",
                            color: "neutral0.main",
                            backgroundColor: "primary700.main",
                            ":hover": {
                              backgroundColor: "primary800.main",
                            },
                          }}
                          onClick={() => setCreateAppointmentDialogOpen(true)}
                        >
                          <PersonAdd /> Realizar encaixe
                        </Button>
                      </>
                    ) : (
                      <SecondaryButton
                        width="auto"
                        height="50px"
                        disabled={false}
                        onClick={() => navigate("/newschedule")}
                      >
                        Crie uma agenda
                      </SecondaryButton>
                    )}
                  </>
                </Stack>
              ) : (
                <Stack height="38px" />
              )}
              {createAppointmentDialogOpen && (
                <CreateAppointmentDialog
                  isOpen={createAppointmentDialogOpen}
                  setModalOpen={setCreateAppointmentDialogOpen}
                  setRefetchDashboard={setRefetch}
                  cpfT={""}
                  walkin
                />
              )}
            </Stack>
          </Stack>
          {access.type === "professional" ? (
            <Stack direction="column" width="100%" justifyContent="center">
              <AppointmentDetails
                data={selectedCardData}
                patient={patientsBundle?.find(
                  (patientEl: fhir5.BundleEntry<fhir5.Patient> | undefined) =>
                    patientEl?.resource?.id ===
                      appointmentsBundle?.[
                        selectedCard
                      ]?.resource?.participant?.[0].actor?.reference?.split(
                        "/"
                      )[1] && patientEl?.resource?.name
                )}
                setRefetch={setRefetch}
              />
            </Stack>
          ) : (
            <CommonDialog
              isOpen={openAppointmentDetails}
              handleClose={() => setOpenAppointmentDetails(false)}
            >
              <Stack mt={3}>
                <AppointmentDetails
                  data={selectedCardData}
                  patient={patientsBundle?.find(
                    (patientEl: fhir5.BundleEntry<fhir5.Patient> | undefined) =>
                      patientEl?.resource?.id ===
                        appointmentsBundle?.[
                          selectedCard
                        ]?.resource?.participant?.[0].actor?.reference?.split(
                          "/"
                        )[1] && patientEl?.resource?.name
                  )}
                  setRefetch={setRefetch}
                />
              </Stack>
            </CommonDialog>
          )}
        </Stack>
      )}
    </>
  );
}
