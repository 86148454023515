import React from "react";
import { Done } from "@mui/icons-material";
import { Typography, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { PrimaryButton } from "../../../components/common";

export function ConfirmationMessage() {
  const navigate = useNavigate();
  return (
    <Stack
      height="100%"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <Done
        sx={{
          fontSize: "80px",
          color: "primary800.main",
          marginBottom: 4,
        }}
      />
      <Typography variant="h5" marginBottom={4} textAlign="center">
        Senha atualizada
      </Typography>
      <Typography
        variant="body1"
        sx={{ color: "neutral1000.main" }}
        textAlign="center"
        marginBottom={7}
      >
        Senha atualizada com sucesso.
      </Typography>
      <PrimaryButton
        onClick={() => navigate("/signin")}
        width="100%"
        height="52px"
      >
        Acessar Fluxmed
      </PrimaryButton>
    </Stack>
  );
}
