import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";

interface IProps {
  columns: {
    column: string;
    width?: string;
  }[];
  actions: any;
}

export function GenericTableHeader({ columns, actions }: IProps) {
  return (
    <TableHead>
      <TableRow>
        {isMobile ? (
          <>
            {actions && (
              <TableCell align="center" width="auto">
                <Typography
                  sx={{ color: "neutral1000.main" }}
                  fontWeight={600}
                  textAlign="center"
                >
                  Ações
                </Typography>
              </TableCell>
            )}

            {columns?.map((el: any, index: number) => (
              <TableCell
                key={`tableHeaderCell-${index}`}
                align="center"
                width={el.width ? el.width : "auto"}
              >
                <Typography
                  sx={{ color: "neutral1000.main" }}
                  fontWeight={600}
                  textAlign="center"
                >
                  {el.column}
                </Typography>
              </TableCell>
            ))}
          </>
        ) : (
          <>
            {columns?.map((el: any, index: number) => (
              <TableCell
                key={`tableHeaderCell-${index}`}
                align="center"
                width={el.width ? el.width : "auto"}
              >
                <Typography
                  sx={{ color: "neutral1000.main" }}
                  fontWeight={600}
                  textAlign="center"
                >
                  {el.column}
                </Typography>
              </TableCell>
            ))}

            {actions && (
              <TableCell align="center">
                <Typography
                  sx={{ color: "neutral1000.main" }}
                  fontWeight={600}
                  textAlign="center"
                >
                  Ações
                </Typography>
              </TableCell>
            )}
          </>
        )}
      </TableRow>
    </TableHead>
  );
}
