function deepCopy(oldObj: any): any {
  let newObj: any = oldObj;

  if (oldObj && typeof oldObj === "object") {
    newObj =
      Object.prototype.toString.call(oldObj) === "[object Array]" ? [] : {};

    for (const i in oldObj) {
      if (oldObj.hasOwnProperty(i)) {
        newObj[i] = deepCopy(oldObj[i]);
      }
    }
  }

  return newObj;
}

function addDays({ date, days }: any) {
  const auxDate = new Date(date);
  auxDate.setDate(auxDate.getDate() + days);
  return auxDate;
}

export const appc_utils = {
  addDays,
  deepCopy,
};
