import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { GetContract } from "../services/fhir/contract/GetContract";
import { useOrganizationContext } from "./OrganizationContext";

interface ContractContextValue {
  currentContract: fhir5.BundleEntry<fhir5.Contract>[] | undefined;
  inactiveContracts: fhir5.BundleEntry<fhir5.Contract>[] | undefined;
  setRefetchContract: any;
  setRefetchInactiveContracts: any;
}

export const ContractContext = createContext<ContractContextValue>(null!);

export function ContractContextProvider({ children }: { children: ReactNode }) {
  const { organization } = useOrganizationContext();
  const [currentContract, setCurrentContract] = useState<
    fhir5.BundleEntry<fhir5.Contract>[] | undefined
  >();
  const [inactiveContracts, setInactiveContracts] = useState<
    fhir5.BundleEntry<fhir5.Contract>[] | undefined
  >();

  const [refetchContract, setRefetchContract] = useState<boolean>(false);
  const [refetchInactiveContracts, setRefetchInactiveContracts] =
    useState<boolean>(false);

  useEffect(() => {
    async function fetchInactiveContracts() {
      try {
        const response = await GetContract({
          organization: organization?.id,
          count: 100,
          sort: true,
          status: "cancelled",
        });
        setInactiveContracts(response?.entry);
      } catch (err) {
        console.log("err", err);
      }
    }
    if (organization?.id !== undefined) {
      fetchInactiveContracts();
    }
    setRefetchInactiveContracts(false);
  }, [organization?.id, refetchInactiveContracts]);

  useEffect(() => {
    async function fetchCurrentContract() {
      try {
        const response = await GetContract({
          organization: organization?.id,
          count: 1,
          sort: true,
          status: "executed",
        });
        setCurrentContract(response?.entry);
      } catch (err) {
        console.log("err", err);
      }
    }
    if (organization?.id !== undefined) {
      fetchCurrentContract();
    }
    setRefetchContract(false);
  }, [organization?.id, refetchContract]);

  const contextValue = {
    currentContract,
    inactiveContracts,
    setRefetchContract,
    setRefetchInactiveContracts,
  };

  return (
    <ContractContext.Provider value={contextValue}>
      {children}
    </ContractContext.Provider>
  );
}

export function useContractContext() {
  return useContext(ContractContext);
}
