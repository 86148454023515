import { Avatar, Box, Tab, Tabs } from "@mui/material";
import { ReactNode, SyntheticEvent, useState } from "react";
import { MedicalNotes } from "./components/MedicalNotes";
import { Solicitations } from "./components/Solicitations";
import { NoteReport } from "./components/NoteReport";
import { IframeDialog } from "../../IframeDialog";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ maxHeight: "60vh", pt: 2, overflow: "auto" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export function PatientDocs({
  medicalNoteData,
  solicitationData,
  resultsData,
  NoteReportData,
  setRefetch,
  sign,
}: any) {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const [open, setOpen] = useState<boolean>(false);
  const [binaryUrl, setBinaryUrl] = useState<string>("");

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setBinaryUrl("");
    setOpen(false);
  };

  const medicalNote = medicalNoteData?.filter((e: any) => {
    return sign
      ? e?.resource?.securityLabel?.[0]?.coding?.[0]?.code === "ProofOfOrigin"
      : e?.resource?.resourceType === "DocumentReference" &&
          e?.resource?.securityLabel?.[0]?.coding?.[0]?.code !==
            "ProofOfOrigin";
  });

  const solicitation = solicitationData?.filter((e: any) => {
    return sign
      ? e?.resource?.securityLabel?.[0]?.coding?.[0]?.code ===
          "ProofOfOrigin" &&
          e?.resource?.category?.[0]?.coding?.[0]?.display === "Solicitação"
      : e?.resource?.resourceType === "DocumentReference" &&
          e?.resource?.securityLabel?.[0]?.coding?.[0]?.code !==
            "ProofOfOrigin" &&
          e?.resource?.category?.[0]?.coding?.[0]?.display === "Solicitação";
  });

  const noteReport = NoteReportData?.filter((e: any) => {
    return sign
      ? e?.resource?.securityLabel?.[0]?.coding?.[0]?.code === "ProofOfOrigin"
      : e?.resource?.resourceType === "DocumentReference" &&
          e?.resource?.securityLabel?.[0]?.coding?.[0]?.code !==
            "ProofOfOrigin";
  });

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
        >
          <Tab
            label={
              <Box style={{ display: "flex", alignItems: "center" }}>
                Receitas médicas
                <Avatar
                  sx={{
                    width: 26,
                    marginLeft: 1,
                    height: 26,
                    fontSize: "14px",
                    fontWeight: 600,
                    backgroundColor: "primary100.main",
                    color: "primary1000.main",
                  }}
                >
                  {medicalNote?.length || "0"}
                </Avatar>
              </Box>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Box style={{ display: "flex", alignItems: "center" }}>
                Solicitações de exame
                <Avatar
                  sx={{
                    width: 26,
                    marginLeft: 1,
                    height: 26,
                    fontSize: "14px",
                    fontWeight: 600,
                    backgroundColor: "primary100.main",
                    color: "primary1000.main",
                  }}
                >
                  {solicitation?.length || "0"}
                </Avatar>
              </Box>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <Box style={{ display: "flex", alignItems: "center" }}>
                Relatórios e atestados
                <Avatar
                  sx={{
                    width: 26,
                    marginLeft: 1,
                    height: 26,
                    fontSize: "14px",
                    fontWeight: 600,
                    backgroundColor: "primary100.main",
                    color: "primary1000.main",
                  }}
                >
                  {noteReport?.length || "0"}
                </Avatar>
              </Box>
            }
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>

      <CustomTabPanel value={selectedTab} index={0}>
        <MedicalNotes
          sign={sign}
          data={medicalNoteData}
          handleOpen={handleOpen}
          setBinaryUrl={setBinaryUrl}
        />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={1}>
        <Solicitations
          setRefetch={setRefetch}
          sign={sign}
          handleOpen={handleOpen}
          setBinaryUrl={setBinaryUrl}
          data={solicitationData}
          resultsData={resultsData}
        />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={2}>
        <NoteReport
          sign={sign}
          data={NoteReportData}
          handleOpen={handleOpen}
          setBinaryUrl={setBinaryUrl}
        />
      </CustomTabPanel>

      {open && (
        <IframeDialog
          setRefetchSummary={setRefetch}
          handleClose={handleClose}
          isOpen={open}
          data={binaryUrl}
        />
      )}
    </>
  );
}
