import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { toast } from "react-toastify";

import { FormInputValidationNumber } from "./forminputinvalidationnumber";
import { ContentBoxTitle } from "../../../components/common";
import { useFirebaseService } from "../../../utils/useFirebase";
import { handleKeyPress } from "../../../utils/useKeyPress";
import { FormButtonGroup, FormStack } from "../../../components/form";
import { IFormInput } from "../types";

interface IProps {
  control: any;
  getValues: any;
  handleSubmit: Function;
  onBackButtonClick: Function;
  navigateToNextPage: Function;
}

const SEND_CODE_TIMER = 60;

export function ConfirmationCode({
  control,
  handleSubmit,
  navigateToNextPage,
  getValues,
  onBackButtonClick,
}: IProps) {
  const { verifyCode, sendVerificationCode } = useFirebaseService;
  const [counter, setCounter] = useState(SEND_CODE_TIMER);
  const [loading, setLoading] = useState(false);
  const numberPhone = getValues("phoneNumber").replace(
    /(\d{2})(\d{5})(\d{4})/,
    "($1) $2-$3"
  );

  const resendCode = () => {
    sendVerificationCode(getValues("phoneNumber"));
    setCounter(SEND_CODE_TIMER);
  };

  const IS_BUTTON_DISABLED = counter !== 0;

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter > 0) {
        setCounter((prevCounter) => prevCounter - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [counter]);

  const onSubmit = async (data: IFormInput) => {
    setLoading(true);
    try {
      await verifyCode(data.firebaseCode);
      toast.success("Código validado com sucesso!");
      navigateToNextPage();
    } catch (error) {
      toast.error("Código inválido");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      onKeyDown={(event) =>
        handleKeyPress(event, loading, handleSubmit(onSubmit))
      }
      style={{ height: "100%", width: "100%" }}
    >
      <FormStack>
        <ContentBoxTitle>Confirme seu número de contato</ContentBoxTitle>
        <Typography
          variant="body1"
          mb={2}
          sx={{ color: "neutral600.main" }}
          textAlign="center"
        >
          Finalize seu cadastro através do código que enviamos para{" "}
          <strong> {numberPhone} </strong> e ative sua conta.
        </Typography>
        <FormInputValidationNumber
          name="firebaseCode"
          control={control}
          label=""
          pattern={[1 - 9]}
          autoFocus
        />
        <FormButtonGroup
          onNextButtonClick={handleSubmit(onSubmit)}
          onGoBackButtonClick={() => onBackButtonClick()}
          loading={loading}
        />
        <Box display="flex" flexDirection="row" gap={2}>
          <Button
            disabled={IS_BUTTON_DISABLED}
            variant="text"
            sx={{ color: "neutral1000.main", textTransform: "none" }}
            onClick={() => resendCode()}
            id="recaptcha-button"
          >
            {IS_BUTTON_DISABLED
              ? `Você pode reenviar o código em ${counter}`
              : "Reenviar código"}
          </Button>
        </Box>
      </FormStack>
    </form>
  );
}
