import { GenericTimeline } from "../GenericTimeline";
import { Stack } from "@mui/material";

import { CondutasAccordion } from "./CondutasAccordion";
import { useQuestionnaireContext } from "../../../../contexts/QuestionnaireContext";
import dayjs from "dayjs";

// import { AnamneseHistoryAccordion } from "./AnamneseHistoryAccordion";

const style: any = {
  Realizada: "#007C6D",
  Concluído: "#007C6D",
  Parcialmente: "#FBBB3C",
  "Não realizada": "#EF4352",
  Falhou: "#EF4352",
};

export function CondutasHistory({ selectedField }: any) {
  const { historyValues } = useQuestionnaireContext();

  const responseEntries = historyValues?.map((el: any) => el?.resource);

  const condutasReponses = responseEntries?.map((el: any) => {
    const awnserDate = el?.authored;
    const filterItens = el?.item
      ?.filter((e: any) => e?.text === "Condutas")?.[0]
      ?.item?.filter((subItem: any) => {
        return (
          subItem?.item?.[1]?.answer?.[0]?.valueCoding &&
          subItem?.item?.[0]?.answer
        );
      });
    const displayReferences = el?.item?.filter(
      (e: any) => e?.linkId === "displayReferences"
    )?.[0]?.item;
    const displayInfo = {
      healthcare: displayReferences?.[0]?.answer?.[0]?.valueString || "",
      practitioner: displayReferences?.[1]?.answer?.[0].valueString || "",
    };
    return { awnserDate, filterItens, displayInfo };
  });

  const verifyResponse = condutasReponses?.filter(
    (el: any) => el.filterItens?.length
  );

  return (
    <Stack spacing={1}>
      <GenericTimeline
        data={verifyResponse}
        dotColor={style}
        component={(data: any, index: number) => (
          <CondutasAccordion style={style} data={data} index={index} />
        )}
      />
    </Stack>
  );
}
