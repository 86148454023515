import { ArrowDropDown } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState } from "react";

export function SelectFilter({ name, options, setFilter, filter }: any) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <>
      <Button
        sx={{ color: "neutral700.main", textTransform: "none", height: "80%" }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {name} <ArrowDropDown />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options?.map((el: any, index: number) => {
          return (
            <MenuItem key={`${name}-${index}`}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filter?.[el.value]}
                    onChange={handleChange}
                    name={el.value}
                  />
                }
                label={el.display}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
