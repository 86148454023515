import { Routes, Route, Outlet } from "react-router-dom";
import { HashRouter } from "react-router-dom";

import LoggedUser from "./components/LoggedUser";
import {
  SignIn,
  SignUp,
  Recover,
  NewPassword,
  ValidateDocument,
} from "../pages";
import { AuthContextProvider } from "../contexts/AuthContext";

export default function Navigation() {
  return (
    <HashRouter>
      <Routes>
        {/* Public Routes */}
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/recover" element={<Recover />} />
        <Route path="/validateDocument/:id" element={<ValidateDocument />} />
        {/* TODO: Restringir accesso apenas via link e não via url */}
        <Route path="/newpassword" element={<NewPassword />} />
        {/* Private Routes */}
        <Route
          path="/"
          element={
            <AuthContextProvider>
              <Outlet />
            </AuthContextProvider>
          }
        >
          <Route index element={<LoggedUser />} />
          <Route path="/:id" element={<LoggedUser />} />
        </Route>
      </Routes>
    </HashRouter>
  );
}
