import { Pages } from "@mui/icons-material";
import { IFeatures } from "../../utils/interfaces";
import { useSessionContext } from "../../contexts/SessionContext";
import MedicalPrescription from "./MedicalPrescription";

export function useMedicalPrescription() {
  const { access } = useSessionContext();
  const configs: IFeatures = {
    initial: false,
    active: access.type === "patient",
    path: "/medicalprescription",
    text: "Receitas médicas",
    icon: Pages,

    sideMenu: false,
    sideMenuIndex: 0,

    component: () => <MedicalPrescription />,
  };

  return configs;
}
