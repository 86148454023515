import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { Dispatch, SetStateAction } from "react";

import { useSessionContext } from "../../contexts/SessionContext";
import { DateFilterType } from "../../utils/interfaces";

interface IProps {
  dateFilter: Dayjs;
  setDateFilter: Dispatch<SetStateAction<Dayjs>>;
  filterType: DateFilterType;
  setFilterType: Dispatch<SetStateAction<DateFilterType>>;
}

export default function DateFilter({
  dateFilter,
  setDateFilter,
  filterType,
  setFilterType,
}: IProps) {
  const { access } = useSessionContext();
  const formatType = filterType === "day" ? "DD/MM/YYYY" : "MMMM YYYY";

  function subtractDate(filter?: DateFilterType) {
    return dayjs(dateFilter).subtract(1, filter || filterType);
  }

  function addDate(filter?: DateFilterType) {
    return dayjs(dateFilter).add(1, filter || filterType);
  }

  function handleChangeDate(operation: "subtract" | "add") {
    let auxFilterType = filterType;
    if (access.type !== "professional") {
      setFilterType("month");
      filterType = "month";
    }
    switch (operation) {
      case "subtract":
        setDateFilter(subtractDate(auxFilterType));
        return;
      case "add":
        setDateFilter(addDate(auxFilterType));
        return;
    }
  }

  return (
    <Stack direction="row" justifyContent="space-between">
      <Button
        variant="text"
        disabled={subtractDate().isBefore(dayjs(), filterType)}
        sx={{
          border: "1px solid",
          borderColor: "primary400.main",
          minWidth: "24px",
          height: "24px",
        }}
        onClick={() => handleChangeDate("subtract")}
      >
        <ChevronLeft />
      </Button>
      <Typography
        variant="subtitle2"
        fontWeight={600}
        sx={{ color: "neutral700.main", textTransform: "capitalize" }}
      >
        {dayjs(dateFilter).format(formatType)}
      </Typography>
      <Button
        variant="text"
        sx={{
          border: "1px solid",
          borderColor: "primary400.main",
          minWidth: "24px",
          height: "24px",
        }}
        onClick={() => handleChangeDate("add")}
      >
        <ChevronRight />
      </Button>
    </Stack>
  );
}
