import { Stack, Typography } from "@mui/material";

export function SectionEditDialog({ title, description, children }: any) {
  return (
    <Stack width="100%">
      <Typography variant="h6" fontWeight="600" mb={1} color="neutral600.main">
        {title}
      </Typography>
      <Typography variant="body1">{description}</Typography>
      {children}
    </Stack>
  );
}
