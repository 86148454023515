import { FileUpload } from "@mui/icons-material";
import { Button, Typography, styled } from "@mui/material";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export function BtnFileUpload({ setFileBase64 }: any) {
  const uploadImage = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setFileBase64(base64);
  };

  const convertToBase64 = (file: File) => {
    if (file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    }
  };

  return (
    <Button
      sx={{
        width: "100%",
        height: { xs: "40px", sm: "56px" },
        textTransform: "lowercase",
        borderRadius: 3,
      }}
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<FileUpload />}
      onChange={(e: any) => uploadImage(e)}
    >
      <Typography
        variant="body1"
        fontWeight={500}
        sx={{
          "&::first-letter": {
            textTransform: "uppercase",
          },
        }}
      >
        Enviar resultado de exame
      </Typography>
      <VisuallyHiddenInput accept=".pdf" type="file" />
    </Button>
  );
}
