import { Controller } from "react-hook-form";
import { FormInputProps } from "../types";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

interface IProps {
  selectOptions: {
    display: any;
    value: any;
  }[];
  defaultValue?: any;
  readOnly?: boolean;
}

type TypeMergedInterfaces = FormInputProps & IProps;

export function FormSelectInput({
  name,
  control,
  label,
  required,
  readOnly,
  selectOptions,
  defaultValue,
}: TypeMergedInterfaces) {
  return (
    <FormControl
      variant="filled"
      sx={{
        "& .MuiFilledInput-root": { backgroundColor: "neutral100.main" },
      }}
      fullWidth
    >
      <InputLabel id="select-small-label">{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{
          required: required,
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <Select
              readOnly={readOnly}
              value={value || ""}
              label={label}
              onChange={onChange}
              error={error !== undefined}
              fullWidth
            >
              {selectOptions.map((element: any, index: any) => {
                return (
                  <MenuItem key={index} value={element.value}>
                    {element.display}
                  </MenuItem>
                );
              })}
            </Select>
          );
        }}
      />
    </FormControl>
  );
}
