import React from "react";
import { Stack, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";

interface IProps {
  title: string;
  description: string;
  icon?: boolean;
}

export function ReadOnlyField({ title, description, icon }: IProps) {
  return (
    <Stack direction="column">
      <Stack direction="row">
        <Typography variant="body1" color="neutral1000.main" fontWeight={500}>
          {title}
        </Typography>
        {icon && <WarningIcon sx={{ color: "warning500.main" }} />}
      </Stack>

      <Typography variant="body1" color="neutral700.main">
        {description}
      </Typography>
    </Stack>
  );
}
