import { Stack } from "@mui/material";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { AnthropometricFields } from "./AnthropometricFields";
import { AnthropometricHistory } from "./AnthropometricHistory";
import { useQuestionnaireContext } from "../../../../contexts/QuestionnaireContext";

export function Antropometricos() {
  const { loadingResponse } = useQuestionnaireContext();
  const { patient } = useMedicalCareContext();
  return (
    <>
      <Stack width="100%" maxHeight="60vh" overflow="auto">
        <AnthropometricFields gender={patient?.gender} />
      </Stack>
      {!loadingResponse && (
        <Stack
          width="100%"
          minWidth="600px"
          overflow="auto"
          maxHeight="60vh"
          paddingRight={3}
        >
          <AnthropometricHistory />
        </Stack>
      )}
    </>
  );
}
