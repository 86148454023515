import { Stack } from "@mui/material";
import { GenericTable } from "../../../../../components/GenericTable/GenericTable";
import { PrimaryButton } from "../../../../../components/common";
import { Add } from "@mui/icons-material";
import dayjs from "dayjs";
import { useDatetime } from "../../../../../utils/useDatetime";

interface IProps {
  statementData: any;
  medicationRequestData: any;
  openDialog: any;
}

export function MedicationsTab({
  medicationRequestData,
  statementData,
  openDialog,
}: IProps) {
  const { formatToDDMMYYYY } = useDatetime;

  const columns = [
    {
      column: "Medicamentos",
      rowName: "description",
    },
    { column: "Informante", rowName: "informant" },

    { column: "Status", rowName: "status" },
  ];

  const medicationRequest = medicationRequestData?.filter(
    (el: any) => el?.resource?.resourceType === "MedicationRequest"
  );

  const documentReference = medicationRequestData?.filter(
    (el: any) => el?.resource?.resourceType === "DocumentReference"
  );

  const statementRows = statementData?.map((el: any, index: number) => {
    const description = el.resource?.medication?.concept?.text || "";
    return {
      description,
      informant: "Paciente",
      status: "-",
    };
  });

  const medicationRequestRows = medicationRequest
    ?.map((el: any, index: number) => {
      const docRef = documentReference?.find((doc: any) => {
        const findRef = doc?.resource?.basedOn?.find((basedOn: any) =>
          basedOn?.reference.includes(el.resource.id)
        );
        return findRef;
      });

      const auxPractitioner = docRef?.resource?.contained.find(
        (contained: any) => contained?.resourceType === "Practitioner"
      );

      const status =
        el?.resource?.status === "active" ? "valid" : el?.resource?.status;

      const description = el.resource?.medication?.concept?.text || "";
      const date = el?.resource?.effectiveDosePeriod?.start
        ? formatToDDMMYYYY(dayjs(el?.resource?.effectiveDosePeriod?.start))
        : "-";

      return {
        status,
        description,
        date,
        informant:
          auxPractitioner?.name?.[0]?.given?.[0] ||
          auxPractitioner?.identifier?.[0]?.value,
      };
    })
    ?.sort((a: any, b: any) => {
      const dateA = dayjs(a.date, "DD/MM/YYYY");
      const dateB = dayjs(b.date, "DD/MM/YYYY");
      return dateB.diff(dateA);
    });

  return (
    <>
      <GenericTable
        tableColumns={columns}
        tableRows={[...statementRows, ...medicationRequestRows]}
      />
      <Stack direction="row" justifyContent="right" mt={4}>
        <PrimaryButton onClick={openDialog} width="300px" height="42px">
          <Add /> Adicionar medicamento
        </PrimaryButton>
      </Stack>
    </>
  );
}
