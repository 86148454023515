import base64 from "base-64";
import { endpoints } from "../configs/Settings";

async function getBasicToken() {
  try {
    const credetials = base64.encode(
      `${endpoints.portalConsumerKey}:${endpoints.portalConsumerSecret}`
    );
    return `Basic ${credetials}`;
  } catch (err) {
    console.log(err);
  }
}

async function getApplicationToken() {
  try {
    const application_token = localStorage.getItem("application_token");
    return `Bearer ${application_token}`;
  } catch (err) {
    console.log(err);
  }
}

async function getUserToken() {
  try {
    const token = localStorage.getItem("token");
    return `Bearer ${token}`;
  } catch (err) {
    console.log(err);
  }
}

async function getXUserToken() {
  try {
    const access_token = localStorage.getItem("token");
    return access_token;
  } catch (err) {
    console.log(err);
  }
}

async function getSubjectId() {
  try {
    const subjectId = localStorage.getItem("username");
    return subjectId;
  } catch (err) {
    console.log(err);
  }
}

async function getOrganizationId() {
  try {
    const organizationId = localStorage.getItem("organizationId");
    return organizationId;
  } catch (err) {
    console.log(err);
  }
}

export const useAuth = {
  getBasicToken,
  getApplicationToken,
  getUserToken,
  getSubjectId,
  getOrganizationId,
  getXUserToken,
};
