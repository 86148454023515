import { Stack, Step, StepLabel, Stepper } from "@mui/material";
import { ScreenMobile } from "../../components/common/ScreenMobile";
import { useState } from "react";
import { FormButtonGroup } from "../../components/form";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ScreenChoiseService } from "./components/ScreenChoiseService";
import { ScreenChoiseOperator } from "./components/ScreenChoiseOperator";
import { ScreenFinalizationService } from "./components/ScreenFinalizationService";
import { ScreenHealthInsurance } from "./components/ScreenHealthInsurance";

const enum Pages {
  S1ChoiseService,
  S2OperatorService,
  S3FinalizationService,
  S4HealthInsurance,
}

export function PaymentScreenMobile({ open, onClose }: any) {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [page, setPage] = useState<number>(Pages.S1ChoiseService);

  const methods = useForm({});

  const { handleSubmit, control } = methods;

  const steps = [1, 2, 3];

  const onSubmit = (data: any) => {
    console.log("data:", data);
  };

  const pageDisplay = (page: number) => {
    switch (true) {
      case page === Pages.S1ChoiseService:
        return <ScreenChoiseService control={control} />;
      case page === Pages.S2OperatorService:
        return <ScreenChoiseOperator control={control} />;
      case page === Pages.S3FinalizationService:
        return <ScreenFinalizationService control={control} />;
      case page === Pages.S4HealthInsurance:
        return <ScreenHealthInsurance control={control} />;
    }
  };

  return (
    <ScreenMobile title="Convênio médico" open={open} onClose={onClose}>
      <Stack mt={2} gap={2} width="100%">
        <Stepper activeStep={currentStep} sx={{ width: "100%" }}>
          {steps.map((key, value) => (
            <Step key={value}>
              <StepLabel></StepLabel>
            </Step>
          ))}
        </Stepper>

        {pageDisplay(page)}

        <FormButtonGroup
          onGoBackButtonClick={() => {
            currentStep > 0 ? setCurrentStep(currentStep - 1) : navigate(-1);
          }}
          onNextButtonClick={() => {
            setCurrentStep(currentStep + 1);
            setPage(page + 1);
            handleSubmit(onSubmit);
          }}
          goBackButtonText={currentStep === 0 ? "Cancelar" : "Voltar"}
          nextButtonText={currentStep > 2 ? "Finalizar" : "Próximo"}
        />
      </Stack>
    </ScreenMobile>
  );
}
