import { Paper, Box, styled } from "@mui/material";

interface IProps {
  children: any;
}

const CustomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.neutral0.main,
  width: "100%",
  alignItems: "center",
  maxWidth: "100%",
  height: "fit-content",
  borderRadius: "30px",
  [theme.breakpoints.down("md")]: {
    padding: "0%",
  },
}));

export function SmallContentBox({ children }: IProps) {
  return (
    <CustomBox>
      <Paper
        sx={{
          display: "grid",
          // gridRowGap: "20px",
          width: "100%",
          padding: { xs: "10% 10%", sm: "10% 25%" },
          justifyItems: "center",
          minHeight: { xs: "100vh", sm: "600px" },
          borderRadius: { xs: "0px", sm: "30px" },
        }}
      >
        {children}
      </Paper>
    </CustomBox>
  );
}
