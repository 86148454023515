import { Paper, Table, TableContainer, TablePagination } from "@mui/material";

import { useEffect, useState } from "react";
import { NoDataWarning } from "../common/NoDataWarning";
import { GenericTableHeader } from "./GenericTableHeader";
import { GenericTableBody } from "./GenericTableBody";

interface IProps {
  tableColumns: any;
  tableRows: any;
  actions?: any;
  isTooltip?: any;
}

export function GenericTable({
  tableColumns,
  tableRows,
  actions,
  isTooltip,
}: IProps) {
  const [currentPage, setCurrentPage] = useState(0);
  const [itensPerPage, setItensPerPage] = useState(5);

  const handleChangePage = (event: any, newPage: any) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setItensPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const startIndex = currentPage * itensPerPage;
  const endIndex = startIndex + itensPerPage;

  // useEffect(() => {
  //   setCurrentPage(0);
  // }, [tableRows]);

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          width: "100%",
          maxWidth: { xs: "80vw", md: "90vw", lg: "100vw" },
        }}
      >
        <Table
          stickyHeader
          sx={{
            width: "100%",
          }}
          aria-label="simple table"
        >
          {tableRows?.length ? (
            <>
              <GenericTableHeader columns={tableColumns} actions={actions} />
              <GenericTableBody
                rowPath={tableColumns?.map((el: any) => el.rowName)}
                rows={tableRows}
                startIndex={startIndex}
                endIndex={endIndex}
                actions={actions}
                isTooltip={isTooltip}
              />
            </>
          ) : (
            <NoDataWarning />
          )}
        </Table>
      </TableContainer>

      {tableRows?.length ? (
        <TablePagination
          labelRowsPerPage="Exibir"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count}`
          }
          rowsPerPageOptions={[1, 3, 5, 10, 20, 50, 100]}
          component="div"
          count={tableRows?.length}
          page={currentPage}
          onPageChange={handleChangePage}
          rowsPerPage={itensPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        <></>
      )}
    </>
  );
}
