import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { GenericTable } from "../../../components/GenericTable/GenericTable";
import { useSessionContext } from "../../../contexts/SessionContext";
import { isMobile } from "react-device-detect";
import { useFormatter } from "../../../utils/useFormatter";
import dayjs from "dayjs";
import { DependentListActions } from "./DependentListActions";
import { Check, Warning } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { GetConsents } from "../../../services/consent";

const translateSex: any = {
  male: "Masculino",
  female: "Feminino",
};

export function DependentList() {
  const { patientRelated, user } = useSessionContext();

  const [received, setReceived] = useState([]);

  const columns = [
    { column: "Nome", rowName: "name" },
    { column: "CPF", rowName: "identifier" },
    { column: "Data de nascimento", rowName: "birthDate" },
    { column: "Sexo de nascimento", rowName: "gender" },
    { column: "Verificado", rowName: "active" },
    { column: "Status", rowName: "ends" },
  ];

  const mobileColumns = [
    { column: "Nome", rowName: "name" },
    { column: "CPF", rowName: "identifier" },
  ];

  const rows = patientRelated
    ?.map((el: any) => ({
      name: useFormatter.formatNameDisplay(el?.name?.[0]?.text),
      identifier: useFormatter.formatCPF(el?.identifier?.[0]?.value || ""),
      birthDate: dayjs(el?.birthDate).format("DD/MM/YYYY"),
      gender: translateSex[el?.gender || "unknown"],
      active: el?.isVerify ? (
        <Tooltip title="Verificação realizada">
          <Check color="primary" />
        </Tooltip>
      ) : (
        <>
          {el?.period?.start ? (
            <Tooltip title="Verificação pendente">
              <Warning sx={{ color: "warning500.main" }} />
            </Tooltip>
          ) : (
            <> - </>
          )}
        </>
      ),
      ends: el?.period?.end ? "Inativo" : "Ativo",
      start: el?.period?.start,
      end: el?.period?.end,
      relatedId: el?.relatedId,
      verify: el?.isVerify,
    }))
    ?.sort((a: any, b: any) => a?.name?.localeCompare(b?.name))
    ?.sort((a: any, b: any) => b?.verify - a?.verify);

  useEffect(() => {
    async function fetchData() {
      try {
        const receivedResponse = await GetConsents({
          identifier: user.username,
          type: "received",
        });
        setReceived(
          receivedResponse?.data
            ?.filter(
              (el: any) =>
                el?.recurso?.id !== user.username && el?.recurso?.nome
            )
            ?.map((el: any) => ({
              id: el?.recurso?.id,
              purpose: el?.contexto?.motivoAcesso,
            }))
        );
      } catch (err) {
        console.log("Fetch consents", err);
      } finally {
      }
    }
    fetchData();
  }, [user.username]);

  return (
    <Stack width="100%">
      <Accordion
        defaultExpanded
        disableGutters
        sx={{
          border: "1px solid #ccc",
          boxShadow: "1px 1px 8px 1px rgba(0, 0, 0, 0.2)",
          position: "initial",
          marginBottom: "20px",
        }}
      >
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Lista de dependentes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <GenericTable
            tableColumns={isMobile ? mobileColumns : columns}
            tableRows={rows}
            actions={(rowData: any) => (
              <DependentListActions row={rowData} received={received} />
            )}
          />
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}
