import { Stack } from "@mui/material";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";

import { useQuestionnaireContext } from "../../../../contexts/QuestionnaireContext";
import { SocialFields } from "./SocialFields";
import { SocialHistory } from "./SocialHistory";

export function SocialHabits() {
  const { loadingResponse } = useQuestionnaireContext();
  const { patient } = useMedicalCareContext();
  return (
    <>
      <Stack width="100%" maxHeight="60vh" overflow="auto">
        <SocialFields gender={patient?.gender} />
      </Stack>
      {!loadingResponse && (
        <Stack
          width="100%"
          minWidth="600px"
          overflow="auto"
          maxHeight="60vh"
          paddingRight={3}
        >
          <SocialHistory />
        </Stack>
      )}
    </>
  );
}
