import { Stack } from "@mui/material";

export function BorderContent({ children }: any) {
  return (
    <Stack
      sx={{
        border: "1px solid",
        borderColor: "neutral400.main",
        borderRadius: "5px",
        width: "100%",
        padding: "16px",
      }}
    >
      {children}
    </Stack>
  );
}
