import { PrivacyItens } from "./components/PrivacyItens";
import { ScreenMobile } from "../../components/common/ScreenMobile";
import { useNavigate } from "react-router-dom";

export function PrivacyMenuMobile({ open, onClose }: any) {
  const navigate = useNavigate();
  return (
    <>
      <ScreenMobile title="Privacidade" open={open} onClose={onClose}>
        <PrivacyItens
          text="Gestão de consentimento"
          textAuxiliar="Aqui você pode fazer a autogestão de consentimento para uso de seus dados."
          onClick={() => navigate("/privacy")}
        />
        <PrivacyItens
          text="Aviso de Privacidade Fluxmed"
          textAuxiliar="Aqui você encontra informações sobre como o Nav utiliza seus dados."
          onClick={() => {}}
        />
        <PrivacyItens
          text="Política de privacidade Fluxmed"
          textAuxiliar="Aqui você encontra informações sobre como a Fluxmed utiliza e protege seus dados. Além disso, aqui vc consegue acessar nosso Portal de Relacionamento com o Titular para que possa exercer seus direitos frente à LGPD."
          onClick={() => {}}
        />
      </ScreenMobile>
    </>
  );
}
