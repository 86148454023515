import { DialogActions, DialogTitle, Stack, Typography } from "@mui/material";

import { useSessionContext } from "../../contexts/SessionContext";
import { CommonDialog } from "../common/CommonDialog";
import { DeletePracititionerRole } from "../../services/fhir/practitionerrole/DeletePractitionerRole";
import { oids } from "../../configs/Settings";
import { AcceptInvite } from "../../services/fhir/practitionerrole/AcceptInvite";
import { GetPractitionerRole } from "../../services/fhir";

import { useViewContext } from "../../contexts/ViewContext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FormButtonGroup } from "../form";

export function AlertInviteDialog() {
  const {
    inviteDialog,
    setInviteDialog,
    roles,
    user,
    setAccess,
    getRolesArray,
  } = useSessionContext();
  const { fetchUserRoles } = useSessionContext();
  const { setViewDialogIsOpen } = useViewContext();

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const invite = roles?.find((el: any) => el.isInvited);

  const handleConfirmInvite = async () => {
    setLoading(true);
    try {
      const data = await GetPractitionerRole({
        id: `${invite?.organization?.reference?.split("-")?.[1]}-${oids.cpf}-${
          user.username
        }`,
      });
      await AcceptInvite(data);
      setViewDialogIsOpen(false);
      localStorage.setItem(
        "organizationId",
        invite?.organization?.reference?.split("-")?.[1]
      );
      setAccess({
        type: "professional",
        organizationId: invite?.organization?.reference?.split("-")?.[1],
        roles: getRolesArray(invite),
      });
      await fetchUserRoles();
      setLoading(false);
      toast.success("Convite aceito");
      const auxRole = data?.code?.find(
        (item: any) => item?.coding?.[0]?.code === "RL_PRACTITIONER"
      );
      if (auxRole) setStep((prev) => prev + 1);
      else setInviteDialog(false);
    } catch (err) {
      toast.error("Não foi possível finalizar a operação");
    }
  };

  const handleCancelInvite = async () => {
    await DeletePracititionerRole(
      `${invite?.organization?.reference?.split("-")?.[1]}-${oids.cpf}-${user.username}`
    );
    setInviteDialog(false);
    await fetchUserRoles();
  };

  return (
    <CommonDialog
      handleClose={() => setInviteDialog(false)}
      isOpen={inviteDialog}
    >
      <DialogTitle textAlign="center" mt={2}>
        Convite
      </DialogTitle>

      {step === 0 && (
        <>
          <Typography textAlign="center" variant="body1">
            {`A Clinica ${invite?.organization?.display} te enviou um convite, deseja fazer parte desta organização?`}
          </Typography>

          <DialogActions>
            <Stack width="100%" direction="row" justifyContent="space-between">
              <FormButtonGroup
                onGoBackButtonClick={handleCancelInvite}
                onNextButtonClick={handleConfirmInvite}
                goBackButtonText="Recusar"
                nextButtonText="Aceitar"
                loading={loading}
              />
            </Stack>
          </DialogActions>
        </>
      )}

      {step === 1 && (
        <>
          <Typography textAlign="center" variant="body1">
            {`Você aceitou o convite`}
          </Typography>

          <Typography textAlign="center" variant="body1">
            Deseja cadastrar informações profissionais?
          </Typography>

          <DialogActions>
            <Stack width="100%" direction="row" justifyContent="space-between">
              <FormButtonGroup
                onGoBackButtonClick={() => setInviteDialog(false)}
                onNextButtonClick={() => {
                  setInviteDialog(false);
                  navigate("/professional-register");
                }}
                goBackButtonText="Fechar"
                nextButtonText="Aceitar"
                loading={loading}
              />
            </Stack>
          </DialogActions>
        </>
      )}
    </CommonDialog>
  );
}
