import { Stack } from "@mui/material";
import { Header } from "../Header/Header";
import { RegisterArea } from "../RegisterArea/RegisterArea";
import { Footer } from "../Footer/Footer";
import { KeyPressShortCutAreas } from "../KeyPressShortcuts/KeyPressShortcut";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { useQuestionnaireContext } from "../../../../contexts/QuestionnaireContext";
import { CircularLoading } from "../../../../components/common";
import { StepArea } from "../StepArea/StepArea";
// import { usePractitionerRoleContext } from "../../../../contexts/PractitionerRoleContext";

export function ClinicalVisualization() {
  const { loadingAppointment } = useMedicalCareContext();
  const { loadingQuestionnaire, loadingResponse } = useQuestionnaireContext();
  // const { userPractitionerRole } = usePractitionerRoleContext();

  // const isMediciner = userPractitionerRole?.identifier?.[0]?.system === "CRM";

  return !loadingAppointment && !loadingQuestionnaire && !loadingResponse ? (
    <>
      <Stack width="100%" spacing={3}>
        <>
          <StepArea />

          <Header />

          <RegisterArea />
          <Footer />
        </>
      </Stack>
      <KeyPressShortCutAreas />
    </>
  ) : (
    <CircularLoading />
  );
}
