import { DialogContent, Stack, Typography } from "@mui/material";
import { FormButtonGroup, FormTextInput } from "../../../../components/form";
import { useRegex } from "../../../../utils/useRegex";
import { useFormatter } from "../../../../utils/useFormatter";
import { Control } from "react-hook-form";

interface IFormInput {
  phone: string;
  confirmationCode: string;
}

interface IProps {
  control: Control<IFormInput | any>;
  loading: boolean;
  disabledConfirmButton: boolean;
  onCancelButtonClick: Function;
  onConfirmButtonClick: Function;
}

export default function PhoneFormContent({
  control,
  loading,
  disabledConfirmButton,
  onCancelButtonClick,
  onConfirmButtonClick,
}: IProps) {
  const { formatPhoneNumber } = useFormatter;
  const { phoneNumber } = useRegex;
  return (
    <DialogContent>
      <Stack spacing={2}>
        <Typography
          textAlign="center"
          color="neutral600.main"
          variant="body1"
          sx={{ px: 4 }}
        >
          Enviaremos um SMS com um código para validarmos o seu número de
          telefone
        </Typography>
        <FormTextInput
          name="phone"
          label="Telefone"
          control={control}
          pattern={phoneNumber}
          maxLength={16}
          mask={formatPhoneNumber}
          autoFocus={true}
        />
      </Stack>
      <Stack
        minWidth="100%"
        mt={3}
        direction="row"
        justifyContent="space-between"
        spacing={2}
      >
        <FormButtonGroup
          onGoBackButtonClick={() => onCancelButtonClick()}
          onNextButtonClick={() => onConfirmButtonClick()}
          goBackButtonText="Cancelar"
          nextButtonText="Confirmar"
          loading={loading}
          disabled={disabledConfirmButton}
        />
      </Stack>
    </DialogContent>
  );
}
