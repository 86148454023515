import { Divider, Stack } from "@mui/material";

import { useEffect, useState } from "react";
import { PDFRender } from "./PDFRender";
import { PDFViewer } from "@react-pdf/renderer";
import { useInterventionsContext } from "../../../contexts/InterventionsContext";
import { endpoints, oids } from "../../../configs/Settings";
import { GetAdminQuestionnaireResponse } from "../../../services/fhir/questionnaireresponse/GetAdminQuestionnaireResponse";
import { useSessionContext } from "../../../contexts/SessionContext";
import { QRCodeCanvas } from "qrcode.react";
import * as uuid from "uuid";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";
import base64 from "base-64";

interface IProps {
  setBase64?: any;
  dataPdf: any;
  refact?: any;
}

export function DocumentVisualization({ setBase64, dataPdf, refact }: IProps) {
  const [blob, setBlob] = useState<any>();
  const { selectedInterventions } = useInterventionsContext();
  const { user, userPatient } = useSessionContext();
  const [dataRegister, setDataRegister] = useState<any[]>([]);
  const [loading, setLoading] = useState(true); // Novo estado de loading
  const { organization } = useOrganizationContext();

  // const tags = [
  //   {
  //     name: "VERMELHA",
  //     value: "CE",
  //     color: "#db3f30",
  //     display: "Controle especial - Tarja vermelha",
  //   },
  //   {
  //     name: "PRETA",
  //     value: "CE",
  //     color: "white",
  //     background: "black",
  //     display: "Controle especial - Tarja preta",
  //   },
  //   {
  //     name: "AMARELA",
  //     value: "AM",
  //     color: "#FEE2A9",
  //     display: "Receita amarela",
  //   },
  //   {
  //     name: "AZUL",
  //     value: "AZ",
  //     color: "#A4CBE8",
  //     display: "Receita azul",
  //   },
  //   {
  //     name: "SEM TARJA",
  //     value: "BR",
  //     color: "white",
  //     display: "Receita branca",
  //   },
  //   {
  //     name: "EX",
  //     display: "Exame",
  //     value: "EX",
  //     color: "#FDCF72",
  //   },
  //   {
  //     name: "AT",
  //     value: "AT",
  //     color: "#009E8C",
  //     display: "Atestado",
  //   },
  // ];

  const pdfList = [
    {
      type: "simple",
      data: selectedInterventions.medicationSimple?.filter((e: any) => {
        if (refact) {
          return e?.refact && !e?.sign; // Filtra os itens que têm 'refact' e que não têm 'sign'
        }
        return !e?.sign;
      }),
      uuid: uuid.v1(),
      idValue:
        Date.now() +
        Math.floor(Math.random() * 10000)
          .toString()
          .padStart(4, "0"),
    },
    {
      type: "exam",
      data: selectedInterventions.service?.filter((e: any) => {
        if (refact) {
          return e?.refact && !e?.sign; // Filtra os itens que têm 'refact' e que não têm 'sign'
        }
        return !e?.sign;
      }),
      uuid: uuid.v1(),
      idValue:
        Date.now() +
        Math.floor(Math.random() * 10000)
          .toString()
          .padStart(4, "0"),
    },
    {
      type: "procedure",
      data: selectedInterventions.procedure?.filter((e: any) => {
        if (refact) {
          return e?.refact && !e?.sign; // Filtra os itens que têm 'refact' e que não têm 'sign'
        }
        return !e?.sign;
      }),
      uuid: uuid.v1(),
      idValue:
        Date.now() +
        Math.floor(Math.random() * 10000)
          .toString()
          .padStart(4, "0"),
    },
  ];

  const blobToBase64 = (blob: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    async function generateBase64() {
      if (blob) {
        const convertToBase64 = Object?.keys(blob)?.map(async (el: any) => {
          return {
            [el]: {
              base64: await blobToBase64(blob?.[el]?.blob),
              info: blob?.[el]?.info,
              type: el,
              uuid: blob?.[el]?.uuid,
              idValue: blob?.[el]?.idValue,
            },
          };
        });
        if (convertToBase64) {
          const base64Result = await Promise.all(convertToBase64);
          setBase64(base64Result);
        }
      }
    }
    if (blob && !loading) generateBase64();
    else setBase64(undefined);
  }, [blob, setBase64, loading]);

  useEffect(() => {
    async function getQuestionnaireResponse() {
      try {
        const response = await GetAdminQuestionnaireResponse({
          questionnaire: "customPdf",
          author:
            dataPdf?.[0]?.location?.managingOrganization?.reference.split(
              "/"
            )?.[1],
          source: `Practitioner/${oids.cpf}-${user.username}`,
        });
        setDataRegister(response?.entry || []);
      } catch (err) {
        console.log("err", err);
      } finally {
        setLoading(false); // Desativa o estado de loading quando a chamada termina
      }
    }
    if (dataPdf?.[0]?.location?.managingOrganization) {
      getQuestionnaireResponse();
    }
  }, [dataPdf?.[0]?.location?.managingOrganization]);

  return (
    <Stack width="100%" spacing={2}>
      {!loading && (
        <>
          {pdfList
            ?.filter((e: any) => e?.data?.length)
            ?.map((el: any, index: number, array: any) => {
              const qrCodeEncodedValue = base64.encode(
                JSON.stringify({
                  organization: organization?.id?.split("-")?.[1],
                  patientId: dataPdf?.[0]?.patientCPF,
                  uuid: el?.uuid,
                  idValue: el?.idValue,
                })
              );

              const linkDocument = `${endpoints?.XDSB_domain}/portal/#/validateDocument/${qrCodeEncodedValue}`;
              return (
                <Stack width="100%" alignItems="center" key={index}>
                  {el?.uuid && (
                    <>
                      <div style={{ display: "none" }} id={`qrcode-${index}`}>
                        <QRCodeCanvas
                          value={linkDocument}
                          size={256}
                          bgColor="#ffffff"
                          fgColor="#000000"
                          level="L"
                        />
                      </div>

                      <PDFViewer
                        style={{ width: "1100px", height: "1150px" }}
                        showToolbar={false}
                      >
                        <PDFRender
                          linkDocument={linkDocument}
                          qrCodeSrc={`qrcode-${index}`}
                          uuid={el?.uuid}
                          dataPdf={dataPdf}
                          idValue={el?.idValue}
                          content={el}
                          blob={blob}
                          setBlob={setBlob}
                          dataRegister={dataRegister}
                          gender={userPatient?.gender}
                        />
                      </PDFViewer>
                    </>
                  )}

                  <Divider
                    sx={{
                      width: "100%",
                      background: "neutral600.main",
                      paddingY: 1,
                    }}
                  />
                </Stack>
              );
            })}

          {selectedInterventions.certificates
            ?.filter((e: any) => !e?.sign)
            ?.map((el: any, index: number, array: any) => {
              const auxContent = { type: `certificate-${index}`, data: [el] };
              const certificateUuid = uuid.v1();
              const certificateIdValue =
                Date.now() +
                Math.floor(Math.random() * 10000)
                  .toString()
                  .padStart(4, "0");

              const uuidValue = certificateUuid;

              const qrCodeEncodedValue = base64.encode(
                JSON.stringify({
                  organization: organization?.id?.split("-")?.[1],
                  patientId: dataPdf?.[0]?.patientCPF,
                  uuid: uuidValue,
                  idValue: certificateIdValue,
                })
              );

              const linkDocument = `${endpoints?.XDSB_domain}/portal/#/validateDocument/${qrCodeEncodedValue}`;
              return (
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  key={`certificate-${index}`}
                >
                  <div style={{ display: "none" }} id={`certificate-${index}`}>
                    <QRCodeCanvas
                      value={linkDocument}
                      size={256}
                      bgColor="#ffffff"
                      fgColor="#000000"
                      level="L"
                    />
                  </div>
                  <PDFViewer
                    style={{ width: "1100px", height: "1150px" }}
                    showToolbar={false}
                  >
                    <PDFRender
                      linkDocument={linkDocument}
                      qrCodeSrc={`certificate-${index}`}
                      uuid={uuidValue}
                      idValue={certificateIdValue}
                      gender={userPatient?.gender}
                      index={index}
                      dataPdf={dataPdf}
                      content={auxContent}
                      blob={blob}
                      setBlob={setBlob}
                      dataRegister={dataRegister}
                    />
                  </PDFViewer>
                  <Divider
                    sx={{
                      width: "100%",
                      background: "neutral600.main",
                      paddingY: 1,
                    }}
                  />
                </Stack>
              );
            })}

          {selectedInterventions.medicationSpecial
            ?.filter((e: any) => {
              if (refact) {
                return e?.refact && !e?.sign; // Filtra os itens que têm 'refact' e que não têm 'sign'
              }
              return !e?.sign;
            })
            ?.map((el: any, index: number, array: any) => {
              const specialUuid = uuid.v1();
              const specialIdValue =
                Date.now() +
                Math.floor(Math.random() * 10000)
                  .toString()
                  .padStart(4, "0");

              const auxContent = { type: `special-${index}`, data: [el] };
              const uuidValue = specialUuid;

              const qrCodeEncodedValue = base64.encode(
                JSON.stringify({
                  organization: organization?.id?.split("-")?.[1],
                  patientId: dataPdf?.[0]?.patientCPF,
                  uuid: uuidValue,
                  idValue: specialIdValue,
                })
              );

              const linkDocument = `${endpoints?.XDSB_domain}/portal/#/validateDocument/${qrCodeEncodedValue}`;
              return (
                <Stack
                  width="100%"
                  alignItems="center"
                  key={`special-${index}`}
                >
                  <div style={{ display: "none" }} id={`special-${index}`}>
                    <QRCodeCanvas
                      value={linkDocument}
                      size={256}
                      bgColor="#ffffff"
                      fgColor="#000000"
                      level="L"
                    />
                  </div>
                  <PDFViewer
                    style={{ width: "1100px", height: "1150px" }}
                    showToolbar={false}
                  >
                    <PDFRender
                      linkDocument={linkDocument}
                      qrCodeSrc={`special-${index}`}
                      uuid={uuidValue}
                      idValue={specialIdValue}
                      index={index}
                      dataPdf={dataPdf}
                      content={auxContent}
                      blob={blob}
                      setBlob={setBlob}
                      dataRegister={dataRegister}
                      gender={userPatient?.gender}
                    />
                  </PDFViewer>

                  <Divider
                    sx={{
                      width: "100%",
                      background: "neutral600.main",
                      paddingY: 1,
                    }}
                  />
                </Stack>
              );
            })}
        </>
      )}
    </Stack>
  );
}
