import { GenericTimeline } from "../GenericTimeline";
import { Stack } from "@mui/material";
import { TratamentoHistoryAccordion } from "./TratamentoHistoryAccordion";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { useFhirData } from "../../../../utils/useFhirData";

interface IProps {
  reset: any;
  filter: any;
  setEditTreatment: (data: any) => void;
  setValue: any;
}

export function TratamentoHistory({
  reset,
  setEditTreatment,
  setValue,
}: IProps) {
  const { summaryData, loadingTreatments } = useMedicalCareContext();

  const medicationRequest = useFhirData.extractPatientSummaryResource(
    summaryData,
    "MedicationRequest"
  );

  let auxData = medicationRequest?.[0]?.entry
    ?.filter((el: any) => !el?.resource?.requester)
    ?.map((el: any, index: number) => {
      const description = el.resource?.medication?.concept?.text || "";
      const dosage = el?.resource?.dosageInstruction?.[0];
      const auxType = dosage?.doseAndRate?.[0]?.type?.text;
      const auxQtd = dosage?.doseAndRate?.[0]?.doseQuantity;
      const auxUnit = auxQtd?.unit;
      const auxUnitValue = auxQtd?.value;

      const auxTiming = dosage?.timing?.repeat;
      const auxDuration = auxTiming?.duration;
      const auxPeriod = auxTiming?.period;
      const auxFrequency = auxTiming?.frequency;

      return {
        fullResource: el.resource,
        id: el?.resource?.id,
        awnserDate: el?.resource?.effectiveDosePeriod?.start,
        description,
        substance: el?.resource?.medication?.concept?.coding?.[0]?.display,
        dose: `${
          auxQtd?.unit && auxQtd?.value && auxType
            ? `${auxUnitValue && `${auxUnitValue}`} ${auxUnit && `${auxUnit}`}`
            : ""
        } ${
          auxPeriod
            ? `${auxPeriod && `a cada ${auxPeriod} h`} ${
                auxDuration
                  ? `durante ${auxDuration} dia(s)`
                  : "por tempo indeterminado"
              }`
            : ""
        }`,
        status: el?.resource?.status,
        note: el?.resource?.note?.[0]?.text,
      };
    });

  if (loadingTreatments) {
    auxData?.unshift({ loadingData: loadingTreatments });
  }

  return (
    <Stack>
      {/* <SearchBar /> */}

      <GenericTimeline
        data={auxData}
        component={(data: any, index: number) => (
          <TratamentoHistoryAccordion
            reset={reset}
            data={data}
            index={index}
            setEditTreatment={setEditTreatment}
            setValue={setValue}
          />
        )}
      />
    </Stack>
  );
}
