import { Stack } from "@mui/material";
import { SelectFilter } from "./SelectFilter";
import { SearchField } from "./SearchField";

import { useSessionContext } from "../../../contexts/SessionContext";
import { useLocationContext } from "../../../contexts/LocationContext";
import { useHealthcareServiceContext } from "../../../contexts/HealthcareServiceContext";
import { usePractitionerRoleContext } from "../../../contexts/PractitionerRoleContext";

function cleanRepeatedList(list: any) {
  let cleanedList = new Set(list);
  return Array.from(cleanedList);
}

export function ScheduleFilter({
  setScheduleFilter,
  scheduleFilter,
  setPractitionerFilter,
  practitionerFilter,
  setHealthcareFilter,
  healthcareFilter,
  locationFilter,
  setLocationFilter,
  setSearchField,
  schedule,
  active,
}: any) {
  const { practitionerRole } = usePractitionerRoleContext();
  const { healthcareService } = useHealthcareServiceContext();
  const { location } = useLocationContext();

  const { access } = useSessionContext();

  // Cria objeto de options para cada filtro
  const scheduleOptions = schedule
    ?.filter((e: any) =>
      active.some((item: any) => item.id === e?.resource?.id)
    )
    ?.map((el: any, index: number) => ({
      display: el?.resource?.name,
      // R4 Version  display:
      //   el?.resource.extension?.find((el: any) =>
      //     el.url.includes("scheduleName")
      //   )?.valueString || el?.resource.id,
      // value: el?.resource.id,
      value: el?.resource.id,
    }));

  const locationOptions = location
    ?.filter((e: any) =>
      active.some((item: any) => item.location === e?.resource?.name)
    )
    .map((el: any, index: number) => ({
      display: el?.resource?.name,
      value: el?.resource.id,
    }));

  const practitionerOptions = practitionerRole
    ?.filter((e: any) =>
      active.some(
        (item: any) => item.practitioner === e?.resource?.practitioner.reference
      )
    )
    .map((el: any) => {
      const practitioner = el.resource?.practitioner;
      return { display: practitioner?.display, value: practitioner?.reference };
    });

  const healthcareNames = cleanRepeatedList(
    healthcareService?.map((el: any) => el?.resource.name)
  );
  const healthcareOptions = healthcareNames
    ?.filter((e: any) => active.some((item: any) => item.healthcare === e))
    .map((el: any) => ({
      display: el,
      value: el,
    }));

  const filterVisualization = access?.roles?.includes("manager") ? true : false;

  return (
    <Stack
      width="fit-content"
      direction="row"
      alignItems="baseline"
      spacing={2}
    >
      <SearchField setSearchField={setSearchField} />
      {/* <PractitionerAvatar /> */}
      <SelectFilter
        name="Agendas"
        options={scheduleOptions}
        filter={scheduleFilter}
        setFilter={setScheduleFilter}
      />
      <SelectFilter
        name="Localizações"
        options={locationOptions}
        filter={locationFilter}
        setFilter={setLocationFilter}
      />
      {filterVisualization && (
        <SelectFilter
          name="Profissionais"
          options={practitionerOptions}
          filter={practitionerFilter}
          setFilter={setPractitionerFilter}
        />
      )}
      <SelectFilter
        name="Serviços"
        options={healthcareOptions}
        filter={healthcareFilter}
        setFilter={setHealthcareFilter}
      />
    </Stack>
  );
}
