import { Grid, Stack, Typography } from "@mui/material";

import { useForm, useWatch } from "react-hook-form";
import { FormCheckBox, FormTextInput } from "../../../components/form";

import { useSessionContext } from "../../../contexts/SessionContext";
import { SubmitActions } from "./SubmitActions";
import { RequiredField } from "./RequiredField";
import { useEffect, useRef, useState } from "react";
import { SelectLocation } from "./SelectLocation";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";
import { GetAddressByCep } from "../../../services/viaCep/GetCep";
import { useFormatter } from "../../../utils/useFormatter";

export function Location({
  onNext,
  defaultValues,
  setCurrentStep,
  create,
  handleClose,
}: any) {
  const { roles } = useSessionContext();
  const { organization } = useOrganizationContext();
  const { control, handleSubmit, getValues, setValue, setError, clearErrors } =
    useForm({
      defaultValues,
    });

  const locationFilled = useWatch({ name: "locationFilled", control });

  const [locationSelected, setLocationSelected] = useState(
    locationFilled ?? false
  );

  const watchedFieldAddress = useWatch({ name: "addressForm", control });
  const watchedFieldName = useWatch({ name: "nameCNPJ", control });
  const watchedServiceOnline = useWatch({ name: "serviceOnline", control });

  const fieldsAddressForm: Array<string> = [
    "city",
    "state",
    "cep",
    "zone",
    "street",
    "number",
    "complement",
  ];

  const numberInputRef = useRef<HTMLInputElement>(null);
  const [numberFocus, setNumberFocus] = useState(false);
  const nameEqualCNPJ = (roles: any) => {
    const nameOrganization =
      organization?.alias?.[0] !== "null"
        ? organization?.alias?.[0]
        : organization?.name;

    if (getValues("nameCNPJ") === true) {
      setValue("nameCNPJ", true);
      setValue("name", nameOrganization);
    } else {
      setValue("nameCNPJ", false);
      setValue("name", ""); //TODO verificar bug troca checkbox endereço
    }
  };

  const addressEqualCNPJ = (roles: any) => {
    const addressOrganization = organization?.contact?.find(
      (el: any) => el.address
    )?.address;

    const city = addressOrganization?.city || "";
    const line = addressOrganization?.line || "";
    const postalCode = addressOrganization?.postalCode || "";
    const state = addressOrganization?.state || "";

    const fieldsAddress: Array<string> = fieldsAddressForm;

    const valuesOrganization: Array<string> = [
      city,
      state,
      postalCode,
      line?.[0],
      line?.[1],
      line?.[2],
      line?.[3],
    ];

    if (getValues("addressForm") === true) {
      fieldsAddress.map((el, index) =>
        setValue(`${el}`, valuesOrganization[index])
      );
    } else {
      fieldsAddress.map((el) => setValue(`${el}`, ""));
    }
  };

  const addressEqualPostalCode = async (data: any) => {
    const cep = data?.replace(/[^0-9]/g, "");

    if (cep?.length === 8) {
      const addressResponse = await GetAddressByCep(cep);

      const city = addressResponse?.localidade || "";
      const postalCode = addressResponse?.cep || "";
      const state = addressResponse?.uf || "";
      const street = addressResponse?.logradouro || "";
      const zone = addressResponse?.bairro || "";
      const complement = addressResponse?.complemento || "";

      const fieldsAddress: Array<string> = fieldsAddressForm;

      const valuesAddressPostalCode: Array<string> = [
        city,
        state,
        postalCode,
        zone,
        street,
        "",
        complement,
      ];

      if (addressResponse?.erro) {
        setError("cep", {
          type: "custom",
          message: "CEP Inválido ou não encontrado! digite um CEP válido",
        });
        setNumberFocus(false);
      } else {
        fieldsAddress.map((el, index) =>
          setValue(`${el}`, valuesAddressPostalCode[index])
        );
        setNumberFocus(true);
      }
    } else {
      setError("cep", {
        type: "custom",
        message: "CEP Inválido! digite um CEP válido",
      });
      setNumberFocus(false);
    }
  };

  const onSubmit = async (data: any) => {
    if (create) {
      await create(data);
    } else {
      await onNext({ location: data });
      setCurrentStep((prev: number) => prev + 1);
    }
  };

  useEffect(() => {
    nameEqualCNPJ(roles);
    addressEqualCNPJ(roles);
  }, [watchedFieldName, watchedFieldAddress]);

  useEffect(() => {
    if (watchedServiceOnline === true) {
      fieldsAddressForm.map((el) => setValue(`${el}`, ""));
      setValue("addressForm", false);
    }
  }, [watchedServiceOnline]);

  useEffect(() => {
    if (numberFocus && numberInputRef.current) {
      numberInputRef.current.focus();
    }
  }, [numberFocus]);

  if (!locationSelected && !create) {
    return (
      <SelectLocation
        setLocationSelected={setLocationSelected}
        setCurrentStep={setCurrentStep}
        handleSubmit={handleSubmit(onSubmit)}
        onSubmit={onSubmit}
        setValue={setValue}
        getValues={getValues}
      />
    );
  }

  return (
    <Stack width="100%" spacing={2} mb={2}>
      {!create && (
        <Typography
          variant="h6"
          fontWeight="600"
          color="neutral700.main"
          textAlign="center"
          padding="40px 0px 0px 0px"
        >
          Adicione a localização.
        </Typography>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1">
            Deseja criar uma localização do tipo teleatendimento?
          </Typography>
          <FormCheckBox
            name="serviceOnline"
            control={control}
            label="Sim"
            checked={watchedServiceOnline}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" fontWeight="600" color="neutral600.main">
            Nome <RequiredField />
          </Typography>
          <Typography variant="body1" fontWeight={400}>
            Esse nome será exibido nas buscas e aparecerá como nome da
            clínica/filial
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormCheckBox
            name="nameCNPJ"
            control={control}
            defaultValue={false}
            label="Mesmo nome usado no CNPJ"
            checked={watchedFieldName}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextInput
            name="name"
            label=""
            placeholder="Nome usado para essa localização"
            control={control}
            required
            autoFocus
            hiddenLabel={true}
          />
        </Grid>
      </Grid>
      {!watchedServiceOnline && (
        <Grid container spacing={2} paddingTop={2}>
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight="600" color="neutral600.main">
              Endereço
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormCheckBox
              name="addressForm"
              control={control}
              defaultValue={false}
              label="Mesmo endereço do CNPJ"
              checked={watchedFieldAddress}
            />
          </Grid>
          <Grid item lg={2} sm={12}>
            <FormTextInput
              onBlur={() => addressEqualPostalCode(getValues()?.cep)}
              onFocus={() => clearErrors("cep")}
              name="cep"
              label="CEP"
              control={control}
              variant="filled"
              mask={useFormatter.formatCEP}
            />
          </Grid>
          <Grid item lg={4} sm={12}>
            <FormTextInput
              name="city"
              label="Cidade"
              control={control}
              variant="filled"
              required
            />
          </Grid>
          <Grid item lg={2} sm={12}>
            <FormTextInput
              name="state"
              label="Estado"
              control={control}
              variant="filled"
              required
            />
          </Grid>
          <Grid item lg={4} sm={12}>
            <FormTextInput
              name="zone"
              label="Bairro"
              control={control}
              variant="filled"
              required
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <FormTextInput
              name="street"
              label="Endereço"
              control={control}
              variant="filled"
              required
            />
          </Grid>
          <Grid item lg={2} sm={12}>
            <FormTextInput
              inputRef={numberInputRef}
              name="number"
              label="Número"
              control={control}
              variant="filled"
              required
            />
          </Grid>
          <Grid item lg={4} sm={12}>
            <FormTextInput
              name="complement"
              label="Complemento"
              control={control}
              variant="filled"
              required
            />
          </Grid>
        </Grid>
      )}
      <SubmitActions
        goBack={() => {
          if (create && handleClose) {
            handleClose();
          } else {
            setLocationSelected(false);
          }
        }}
        goForward={handleSubmit(onSubmit)}
      />
    </Stack>
  );
}
