import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";

const InputSelect = function (props: any) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue}
      rules={props.rules}
      render={({ field }) => (
        <FormControl fullWidth>
          <InputLabel id={props.linkId}>{props.text}</InputLabel>
          <Select
            labelId={`${props.linkId}-${props.text}`}
            id={`${props.linkId}-${props.text}`}
            label={props.text}
            {...field}
          >
            {props?.answerOption?.map((option: any, index: number) => (
              <MenuItem key={index} value={option.valueCoding.display}>
                {option.valueCoding.display}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default InputSelect;
