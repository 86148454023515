import {
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TableCell,
  Tooltip,
} from "@mui/material";
import { Add, Block, Close, Edit, Map } from "@mui/icons-material";
import { CommonDialog } from "../../../components/common/CommonDialog";
import { isMobile } from "react-device-detect";
import { useState } from "react";
import { FormButtonGroup, FormTextInput } from "../../../components/form";
import { useForm, useWatch } from "react-hook-form";
import { useFormatter } from "../../../utils/useFormatter";
import { InativateConfirmDialog } from "./InativateConfirmDialog";
import { PatchLocation } from "../../../services/fhir/location/PatchLocation";
import { useLocationContext } from "../../../contexts/LocationContext";
import { toast } from "react-toastify";

export function ManageLocationActions({ row }: any) {
  const auxData = row?.address?.line;
  const { refetchLocation } = useLocationContext();

  const { control, handleSubmit, setError } = useForm({
    defaultValues: {
      name: row?.name,
      cep: row?.address?.postalCode || "",
      city: row?.address?.city || "",
      state: row?.address?.state || "",
      zone: row?.address?.district || "",
      street: auxData?.[0] || "",
      number: auxData?.[1] || "",
      complement: auxData?.[2] || "",
    },
  });

  const watchStreet = useWatch({ name: "street", control });
  const watchNumber = useWatch({ name: "number", control });
  const watchCEP = useWatch({ name: "cep", control });

  const onSubmit = async (data: any) => {
    const regexCEP = /^\d{2}\.\d{3}-\d{3}$/;
    if (watchCEP !== "" && !regexCEP.test(watchCEP)) {
      setError("cep", {
        type: "custom",
        message: "",
      });
      return;
    }

    if (watchStreet !== "" && watchNumber === "") {
      setError("number", {
        type: "custom",
        message: "",
      });
      return;
    } else if (watchStreet === "" && watchNumber !== "") {
      setError("street", {
        type: "custom",
        message: "",
      });
      return;
    }

    const auxLine = [data.street, data.number, data.complement].filter(
      (el) => el !== ""
    );

    const auxContent = [
      {
        line: auxLine,
        city: data.city,
        district: data.zone,
        state: data.state,
        postalCode: data.cep,
      },
    ];
  };

  const [editName, setEditName] = useState<boolean>(false);
  function handleCloseName() {
    setEditName(false);
  }

  const [isOpen, setIsOpen] = useState<boolean>(false);
  function handleClose() {
    setIsOpen(false);
  }
  return (
    <>
      <TableCell align="center">
        {/* <Tooltip title="Alterar Nome">
          <IconButton onClick={() => setEditName(true)}>
            <Edit />
          </IconButton>
        </Tooltip>

        {row?.type === "Presencial" && (
          <Tooltip title="Alterar endereço">
            <IconButton onClick={() => setIsOpen(true)}>
              <Map />
            </IconButton>
          </Tooltip>
        )} */}

        <Tooltip title={row?.status === "active" ? "Desativar" : "Ativar"}>
          <IconButton
            onClick={async () => {
              if (row?.status === "active") {
                setIsOpen(true);
              } else {
                try {
                  await PatchLocation({
                    id: row?.id,
                    operation: "replace",
                    path: "/status",
                    content: "active",
                  });
                  refetchLocation();
                  toast.success("Localização ativada com sucesso!");
                } catch (err) {
                  toast.warn(
                    "Não foi possível realizar a operação, por favor tente novamente!"
                  );
                  console.log("err", err);
                }
              }
            }}
          >
            {row?.status === "active" ? (
              <Close sx={{ color: "red" }} />
            ) : (
              <Add />
            )}
          </IconButton>
        </Tooltip>
      </TableCell>

      {isOpen && (
        <InativateConfirmDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          locationId={row?.id}
        />
      )}

      {/* {isOpen && (
        <CommonDialog
          handleClose={handleClose}
          isOpen={isOpen}
          mobileView={isMobile}
        >
          <DialogTitle textAlign="center">Editar endereço</DialogTitle>

          <DialogContent>
            <Grid container spacing={2} paddingTop={2}>
              <Grid item lg={6} sm={12}>
                <FormTextInput
                  name="cep"
                  label="CEP"
                  control={control}
                  variant="filled"
                  mask={useFormatter.formatCEP}
                />
              </Grid>
              <Grid item lg={6} sm={12}>
                <FormTextInput
                  name="city"
                  label="Cidade"
                  control={control}
                  variant="filled"
                />
              </Grid>
              <Grid item lg={6} sm={12}>
                <FormTextInput
                  name="state"
                  label="Estado"
                  control={control}
                  variant="filled"
                />
              </Grid>
              <Grid item lg={6} sm={12}>
                <FormTextInput
                  name="zone"
                  label="Bairro"
                  control={control}
                  variant="filled"
                />
              </Grid>

              <Grid item lg={12} sm={12}>
                <FormTextInput
                  name="street"
                  label="Endereço"
                  control={control}
                  variant="filled"
                />
              </Grid>
              <Grid item lg={6} sm={12}>
                <FormTextInput
                  name="number"
                  label="Número"
                  control={control}
                  variant="filled"
                />
              </Grid>
              <Grid item lg={6} sm={12} mb={2}>
                <FormTextInput
                  name="complement"
                  label="Complemento"
                  control={control}
                  variant="filled"
                />
              </Grid>
              <Stack width="100%" ml={2}>
                <FormButtonGroup
                  goBackButtonText="Cancelar"
                  nextButtonText="Confirmar"
                  onGoBackButtonClick={handleClose}
                  onNextButtonClick={handleSubmit(onSubmit)}
                />
              </Stack>
            </Grid>
          </DialogContent>
        </CommonDialog>
      )}

      {editName && (
        <CommonDialog
          handleClose={handleCloseName}
          isOpen={editName}
          mobileView={isMobile}
        >
          <DialogTitle textAlign="center">Editar nome</DialogTitle>

          <DialogContent>
            <Grid container spacing={2} paddingTop={2}>
              <Grid item xs={12} lg={12} sm={12}>
                <FormTextInput
                  name="name"
                  label="Nome"
                  control={control}
                  variant="filled"
                />
              </Grid>

              <Stack width="100%" mt={2} ml={2}>
                <FormButtonGroup
                  goBackButtonText="Cancelar"
                  nextButtonText="Confirmar"
                  onGoBackButtonClick={handleClose}
                  onNextButtonClick={handleSubmit(onSubmit)}
                />
              </Stack>
            </Grid>
          </DialogContent>
        </CommonDialog>
      )} */}
    </>
  );
}
