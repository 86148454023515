import { useEffect, useState } from "react";
import { useSessionContext } from "../../../contexts/SessionContext";
import {
  GetAppointment,
  GetSchedule,
  PatchSchedule,
} from "../../../services/fhir";
import { oids } from "../../../configs/Settings";
import { AppointmentAccordion } from "../../schedulemanager/components/AppointmentAccordion";
import { Button, Checkbox, Stack, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CircularLoading } from "../../../components/common";

interface IProps {
  chosenPractitionerId: any;
}

export function ManageInativateSchedule({ chosenPractitionerId }: IProps) {
  const [schedule, setSchedule] = useState<fhir5.BundleEntry<fhir5.Schedule>[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const { access, user } = useSessionContext();
  const [refetch, setRefetch] = useState<boolean>(false);
  const [activeSchedule, setActiveSchedules] = useState<[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchSchedules() {
      setLoading(true);
      const auxActor =
        !access?.roles?.includes("manager") &&
        `Practitioner/${oids.cpf}-${user.username}`;
      const bundleEntry: fhir5.BundleEntry<fhir5.Schedule>[] = [];
      Promise.all([
        GetSchedule({
          active: true,
          organization: `${oids.cnpj}-${access.organizationId}`,
          ...(auxActor && { actor: auxActor }),
        }).then((response: fhir5.Bundle<fhir5.Schedule>) => {
          if (response?.entry) {
            bundleEntry.push(...response.entry);
          }
        }),
      ]).then(() => {
        setSchedule(bundleEntry);
        setLoading(false);
      });
    }
    fetchSchedules();
  }, [refetch, access?.roles, user.username]);

  const displaySchedules = schedule?.filter((el: any) => {
    return el?.resource?.actor?.some((actor: any) =>
      actor?.reference?.endsWith(chosenPractitionerId)
    );
  });

  const idSchedule = displaySchedules.map((el: any) => el?.resource?.id);

  const onSubmit = async (idSchedules: string[]) => {
    const patchOperation = [
      {
        op: "replace",
        path: "/active",
        value: "false",
      },
    ];

    try {
      setLoading(true);
      await Promise.all(
        idSchedules.map((id) => PatchSchedule(id, patchOperation))
      );
      toast.success("Agenda(s) cancelada(s) com sucesso!");
      setRefetch(!refetch); // Refetch schedules after successful patch
    } catch (err) {
      toast.error("Não foi possível cancelar a(s) agenda(s)");
      console.log("err:", err);
    } finally {
      setLoading(false);
    }
  };

  //console.log("chosenPractitionerId: ", chosenPractitionerId);

  return (
    <>
      {loading ? (
        <CircularLoading />
      ) : (
        <>
          {displaySchedules?.length ? (
            <>
              <Typography
                variant="subtitle2"
                fontWeight="400"
                color="neutral700.main"
                textAlign="center"
                m={{ xs: 2, md: 5 }}
              >
                Este profissional possui as seguintes agendas ativas:
              </Typography>
              {displaySchedules.map((element: any, index: number) => (
                <AppointmentAccordion
                  key={index}
                  defaultOpen={index === 0}
                  setRefetch={setRefetch}
                  schedule={element}
                  activeSchedule={activeSchedule}
                  setActiveSchedules={setActiveSchedules}
                  id={element?.resource?.id}
                />
              ))}

              <Button
                variant="text"
                sx={{
                  textTransform: "none",
                  width: "fit-content",
                  padding: "8px",
                  alignSelf: "center",
                }}
                onClick={() => onSubmit(idSchedule)}
              >
                <Typography variant="subtitle2" fontWeight="400">
                  Ok, cancelar agendas!
                </Typography>
              </Button>
            </>
          ) : (
            <>
              <Typography
                variant="subtitle2"
                fontWeight="400"
                color="neutral700.main"
                textAlign="center"
                m={{ xs: 2, md: 5 }}
              >
                Este profissional não possui agendas ativas!
              </Typography>
            </>
          )}
        </>
      )}
    </>
  );
}
