import { useState } from "react";
import { InativatePractitionerDialog } from "./InativatePractitionerDialog";
import { DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { FormButtonGroup } from "../../../components/form";
import { DeletePracititionerRole } from "../../../services/fhir/practitionerrole/DeletePractitionerRole";
import { oids } from "../../../configs/Settings";
import { usePractitionerRoleContext } from "../../../contexts/PractitionerRoleContext";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";
import { toast } from "react-toastify";

interface IProps {
  handleClose: Function;
  isOpen: boolean;
  row: any;
  type?: string;
}

export function ProfessionalDialogAction({
  handleClose,
  isOpen,
  row,
  type = "Concluir operação",
}: IProps) {
  const { organization } = useOrganizationContext();
  const { refetchPractitionerRole } = usePractitionerRoleContext();
  const [loading, setLoading] = useState<boolean>(false);

  const auxTitle: any = {
    cancelInvite: "Cancelar convite",
    deletePractitioner: "Remover profissional",
  };

  const auxSubtitle: any = {
    cancelInvite: "Deseja confirmar o cancelamento do convite?",
    deletePractitioner: "Deseja remover o profissional da organização?",
  };

  const handleCancelInvite = async () => {
    setLoading(true);
    try {
      await DeletePracititionerRole(
        `${organization?.id?.split("-")?.[1]}-${oids.cpf}-${
          row?.practitionerId?.split("-")?.[1]
        }`
      );
    } catch (err) {
      console.log("err", err);
      toast.error("Não foi possível cancelar o convite, tente novamente!");
    }
    setLoading(false);
    handleClose();
    toast.success("Convite cancelado com sucesso!");
    refetchPractitionerRole();
  };

  return (
    <InativatePractitionerDialog
      handleClose={handleClose}
      isOpen={isOpen}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle textAlign="center"> {auxTitle[type]} </DialogTitle>
      <DialogContent>
        <Typography
          mt={2}
          textAlign="center"
          variant="subtitle2"
          fontWeight={600}
        >
          Profissional: {row?.practitionerName}
        </Typography>
        <Typography
          variant="subtitle2"
          textAlign="center"
          color="neutral700.main"
          fontWeight="400"
          marginX={{ xs: 2, md: 5 }}
        >
          {auxSubtitle[type]}
        </Typography>

        <Stack display="flex" mt={3} direction="row">
          <FormButtonGroup
            onGoBackButtonClick={handleClose}
            onNextButtonClick={handleCancelInvite}
            goBackButtonText="Cancelar"
            nextButtonText="Confirmar"
            loading={loading}
          />
        </Stack>
      </DialogContent>
    </InativatePractitionerDialog>
  );
}
