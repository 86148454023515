import { Search } from "@mui/icons-material";
import {
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
} from "@mui/material";

export function SearchField({ setSearchField }: any) {
  return (
    <FormControl sx={{ width: "180px" }} variant="filled">
      <FilledInput
        id="filled-adornment-password"
        onChange={(e) => setSearchField(e.target.value.toLowerCase())}
        type="text"
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" edge="end">
              <Search />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}
