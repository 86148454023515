import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSessionContext } from "./SessionContext";
import { currentFeatures } from "../configs/Settings";
import { useOrganizationContext } from "./OrganizationContext";
import { isMobile } from "react-device-detect";
import ViewDialog from "../components/modals/ViewDialog";

interface ViewContextValue {
  sidebarIsOpen: boolean;
  setSidebarIsOpen: Dispatch<SetStateAction<boolean>>;
  viewDialogIsOpen: boolean;
  setViewDialogIsOpen: Dispatch<SetStateAction<boolean>>;
  changeView: Function;
  availableRoles: any;
  viewType: string;
  setViewType: any;
}

export const ViewContext = createContext<ViewContextValue>(null!);

export function ViewContextProvider({ children }: { children: ReactNode }) {
  const { access, roles, setAccess, getRolesArray } = useSessionContext();
  const { refetchOrganization, resetStates } = useOrganizationContext();
  const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(true);
  const [viewDialogIsOpen, setViewDialogIsOpen] = useState<boolean>(false);
  const { person } = useSessionContext();

  const [viewType, setViewType] = useState<"admin" | "user">("user");

  useEffect(() => {
    if (access) {
      if (access.type === null && !currentFeatures.register && !isMobile) {
        setViewDialogIsOpen(true);
      }
      if (isMobile && access.type === null) {
        setAccess({ type: "patient" });
      }
      if (access?.type === "professional" && access.roles?.length && access) {
        changeVisionDashboard();
        refetchOrganization();
        resetStates();
      }
    }
  }, [access]);

  function changeVisionDashboard() {
    if (access?.roles?.includes("practitioner")) {
      setViewType("user");
      return;
    } else if (access?.roles?.includes("manager")) {
      setViewType("admin");
      return;
    }
  }

  function changeView() {
    if (roles?.length === 0) {
      setAccess({ type: "patient" });
      return;
    }
    if (roles?.length === 1) {
      setAccess({
        type: "professional",
        organizationId: roles?.[0]?.organization?.reference?.split("-")?.[1],
        roles: getRolesArray(roles[0]),
      });
      return;
    }
    localStorage.setItem(
      "organizationId",
      roles?.[0]?.organization?.reference?.split("-")?.[1]
    );
    setViewDialogIsOpen(true);
  }

  const contextValue = {
    sidebarIsOpen,
    setSidebarIsOpen,
    viewDialogIsOpen,
    setViewDialogIsOpen,
    changeView,
    availableRoles: access?.roles,
    viewType,
    setViewType,
  };

  return (
    <ViewContext.Provider value={contextValue}>
      {person?.active && <ViewDialog />}
      {children}
    </ViewContext.Provider>
  );
}

export function useViewContext() {
  return useContext(ViewContext);
}
