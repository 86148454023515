import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { GetPolicy } from "../services/consent";
import { useSessionContext } from "./SessionContext";

interface PrivacyContextValue {
  isSharing: any;
  setRefetch: any;
  privacyLoading: any;
}

export const PrivacyContext = createContext<PrivacyContextValue>(null!);

export function PrivacyContextProvider({ children }: { children: ReactNode }) {
  const { user } = useSessionContext();
  const [isSharing, setIsSharing] = useState<boolean>();
  const [refetch, setRefetch] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    setLoading(true);
    async function fetchPolicy() {
      const policyResponse = await GetPolicy(user.username);
      if (policyResponse?.politicaRES) {
        setIsSharing(
          policyResponse?.politicaRES?.toLowerCase()?.includes("opt-in")
        );
      }

      setLoading(false);
    }
    if (user.username) fetchPolicy();
  }, [user.username, refetch]);

  const contextValue = {
    isSharing,
    setRefetch,
    privacyLoading: loading,
  };

  return (
    <PrivacyContext.Provider value={contextValue}>
      {children}
    </PrivacyContext.Provider>
  );
}

export function usePrivacyContext() {
  return useContext(PrivacyContext);
}
