import { Add, Close, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { FormButtonGroup } from "../../../../components/form";
import { useCustomizationContext } from "../../../../contexts/CustomizationContext";

interface FormValues {
  itens: any[];
}

export function NewItemDialog({ isOpen, handleClose, specialty }: any) {
  const { handleAddItem } = useCustomizationContext();
  const methods = useForm<FormValues>({
    defaultValues: { itens: [" "] },
  });
  const { handleSubmit, control } = methods;

  const handleDialogClose = (event: any, reason: string) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      handleClose(event, reason);
    }
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "itens",
  });

  const onSubmit = (data: any) => {
    data?.itens?.map((e: any) => handleAddItem(specialty, e));
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          borderRadius: "5px",
          padding: 4,
          minWidth: "600px",
        },
      }}
      maxWidth="xl"
      onClose={handleDialogClose}
    >
      <IconButton
        onClick={() => handleClose(null, "iconButtonClick")}
        sx={{ position: "absolute", right: 5, top: 5 }}
      >
        <Close />
      </IconButton>

      <DialogTitle textAlign="center">Personalizar atendimento</DialogTitle>

      <Typography
        variant="subtitle2"
        color="neutral700.main"
        fontSize={16}
        fontWeight={400}
        mb={2}
      >
        Inclua os itens que deseja avaliar no exame físico de sua especialidade
      </Typography>

      <Stack spacing={1} sx={{ maxHeight: "400px", overflow: "auto" }}>
        {fields.map((field, index) => (
          <Box
            key={field.id}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              mb: 2,
              width: "100%",
            }}
          >
            <Controller
              name={`itens.${index}`}
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Item" variant="filled" fullWidth />
              )}
            />

            {fields.length !== 1 && (
              <IconButton color="error" onClick={() => remove(index)}>
                <Delete />
              </IconButton>
            )}
          </Box>
        ))}

        <Stack direction="row" justifyContent="flex-end">
          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={() => append("")}
          >
            Novo item
          </Button>
        </Stack>
      </Stack>

      <DialogActions>
        <FormButtonGroup
          onGoBackButtonClick={handleClose}
          onNextButtonClick={handleSubmit(onSubmit)}
          goBackButtonText="Fechar"
          nextButtonText="Confirmar"
        />
      </DialogActions>
    </Dialog>
  );
}
