import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";

import { useSessionContext } from "../../../../contexts/SessionContext";
import { useOrganizationContext } from "../../../../contexts/OrganizationContext";
import {
  FormAutoComplete,
  FormButtonGroup,
  FormMultipleAutoComplete,
  FormTextInput,
} from "../../../../components/form";
import { Controller, useForm } from "react-hook-form";

import { toast } from "react-toastify";
import { PostPrescriptionProtocol } from "../../../../services/fhir";
import { ReactNode, SyntheticEvent, useEffect, useState } from "react";
import { InputText } from "../../../ClinicalRegister/components/QuestionnaireViewer/components/Form";
import { PutPrescriptionProtocol } from "../../../../services/fhir/prescriptionprotocol/PutPrescriptionProtocol";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CustomCID10Option } from "./CustomCID10Option";
import { GetCodeSystemLookup } from "../../../../services/terminology/GetCodeSystemLookup";
import { GetValueSet } from "../../../../services/terminology";
import { Close } from "@mui/icons-material";
import { usePractitionerRoleContext } from "../../../../contexts/PractitionerRoleContext";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            height: "fit-content",
            maxHeight: "30vh",
            pt: 2,
            overflow: "auto",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type FormValues = {
  name?: string;
  description?: string;
  referencias?: string;
  condition?: any;
  [key: `itens.${string}-${string}-${string}`]: any; // permite chaves dinâmicas
  status?: any;
};

export function CreateProtocolDialog({ id, handleClose, isOpen, itens }: any) {
  const { id: pageId } = useParams();
  const { userPractitioner } = usePractitionerRoleContext();
  const { user } = useSessionContext();
  const { organization } = useOrganizationContext();
  const { state } = useLocation();
  const { control, handleSubmit, setValue } = useForm<FormValues>({
    defaultValues: {
      name: state?.defaultItens?.display || "",
      description: state?.defaultItens?.description || "",
      referencias: state?.defaultItens?.references || "",
      condition: state?.defaultItens?.condition?.map((el: any) => {
        return {
          ...el.valueCoding,
          value: el?.valueCoding?.code,
          label: `${el?.valueCoding?.code} - ${el?.valueCoding?.display}`,
        };
      }),
    },
  });
  const navigate = useNavigate();

  const auxMedication = itens?.filter(
    (item: any) => item.type === "medication"
  );
  const auxSupplement = itens?.filter(
    (item: any) => item.type === "supplement"
  );
  const auxHerbalMedicine = itens?.filter(
    (item: any) => item.type === "herbalMedicine"
  );
  const auxProcedure = itens?.filter((item: any) => item.type === "procedure");
  const auxService = itens?.filter((item: any) => item.type === "service");
  const auxCertificate = itens?.filter(
    (item: any) => item.type === "certificate"
  );

  const references = {
    organizationId: organization?.id,
    practitionerId: user?.username,
  };

  const tags = [
    {
      name: "VERMELHA",
      value: "CE",
      color: "#db3f30",
      display: "Controle especial - Tarja vermelha",
    },
    {
      name: "PRETA",
      value: "CE",
      color: "white",
      background: "black",
      display: "Controle especial - Tarja preta",
    },
    {
      name: "AMARELA",
      value: "AM",
      color: "#FEE2A9",
      display: "Receita amarela",
    },
    {
      name: "AZUL",
      value: "AZ",
      color: "#A4CBE8",
      display: "Receita azul",
    },
    {
      name: "SEM TARJA",
      value: "BR",
      color: "white",
      display: "Receita branca",
    },
    {
      name: "EX",
      display: "Exame",
      value: "EX",
      color: "#FDCF72",
    },
    {
      name: "AT",
      value: "AT",
      color: "#009E8C",
      display: "Atestado",
    },
  ];

  const [statusValue, setStatusValue] = useState(
    state?.defaultItens?.status || "in-progress"
  );

  const statusOptions = [
    { value: "in-progress", display: "Desenvolvimento" },
    { value: "stopped", display: "Suspenso" },
    { value: "completed", display: "Completo" },
  ];

  const handleChangeStatus = (value?: any) => {
    setStatusValue(value);
  };

  const onSubmit = async (data: any) => {
    const selectedItens = itens?.filter(
      (item: any) =>
        data?.itens?.[`${item?.type}-${item?.name}-${item?.label}`] !== false
    );

    if (!selectedItens?.length) {
      toast.warn(
        "Nenhum item foi selecionado! Por favor, verifique a seleção e tente novamente"
      );
      return;
    }
    const medicationArray = selectedItens?.filter(
      (el: any) => el.type !== "service" && el.type !== "certificate"
    );

    const serviceArray = selectedItens?.filter(
      (el: any) => el.type === "service"
    );
    const certificateArray = selectedItens?.filter(
      (el: any) => el.type === "certificate"
    );

    const auxObj = {
      nomeProtocolo: data.name,
      conditions: data?.codition,
      medicamentos: medicationArray?.length ? medicationArray : undefined,
      exames: serviceArray?.length ? serviceArray : undefined,
      atestados: certificateArray?.length ? certificateArray : undefined,
      description: data.description,
      referencias: data.referencias,
      condition: data.condition,
      status: statusValue,
      subject: user?.username,
    };

    if (id) {
      try {
        const response = await PutPrescriptionProtocol({
          id,
          objData: auxObj,
          author: organization?.id,
          subject: {
            reference: `Practitioner/${userPractitioner?.id}`,
          },
        });
        toast.success("Protocolo editado com sucesso!");
        navigate("/protocolsettings");
      } catch (err) {
        console.log("err", err);
        toast.warn("Não foi possível finalizar esta operação, tente novamente");
      }
    } else {
      try {
        await PostPrescriptionProtocol(auxObj, references);

        toast.success("Protocolo criado com sucesso!");
        if (pageId === "createprotocol") navigate("/protocolsettings");
      } catch (err) {
        toast.warn("Não foi possível finalizar esta operação, tente novamente");
      } finally {
        handleClose();
      }
    }
  };

  const auxTratamentos = [
    ...(auxMedication || []),
    ...(auxSupplement || []),
    ...(auxHerbalMedicine || []),
  ];

  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const auxTabs = [
    ...(auxMedication?.length ||
    auxSupplement?.length ||
    auxHerbalMedicine?.length
      ? ["Tratamentos"]
      : []),
    ...(auxService?.length ? ["Exames"] : []),
    ...(auxProcedure?.length ? ["Procedimentos"] : []),
    ...(auxCertificate?.length ? ["Relatórios"] : []),
  ];

  const [loading, setLoading] = useState<boolean>(false);
  const [conditionOptions, setConditionOptions] = useState<any>();
  const [searchCid10, setSearchCid10] = useState<any>();
  const [searchCondition, setSearchCondition] = useState<any>();

  const conditionList =
    searchCid10 ||
    conditionOptions?.expansion?.contains?.map((el: any, index: number) => ({
      ...el,
      label: `${el?.code} - ${el?.display}`,
      value: el?.code,
    })) ||
    [];

  useEffect(() => {
    async function fetchConditions() {
      setSearchCid10(undefined);
      const valueSetReponse = await GetValueSet({
        valueset: "CID10",
        version: "r4",
        filter: searchCondition,
      });
      setConditionOptions(valueSetReponse?.data);
      if (
        searchCondition?.length < 7 &&
        !valueSetReponse?.data?.expansion?.contains
      ) {
        const codeSystemResponse = await GetCodeSystemLookup({
          codeSystem: "https://fluxmed.com.br/fhir/r4/CodeSystem/CID10",
          filter: searchCondition?.toUpperCase(),
        });
        const auxDisplay = codeSystemResponse?.parameter?.find(
          (e: any) => e.name === "display"
        )?.valueString;

        if (auxDisplay) {
          setSearchCid10([
            {
              valueCoding: {
                code: searchCondition?.toUpperCase(),
                label: `${searchCondition?.toUpperCase()} - ${auxDisplay}  `,
                name: auxDisplay,
                display: auxDisplay,
                value: searchCondition?.toUpperCase(),
              },
              value: searchCondition?.toUpperCase(),
              label: `${searchCondition?.toUpperCase()} - ${auxDisplay}  `,
            },
          ]);
        }
      }

      setLoading(false);
    }

    const debounce = setTimeout(() => {
      setLoading(true);
      fetchConditions();
    }, 500);
    return () => clearTimeout(debounce);
  }, [searchCondition]);

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: { borderRadius: "5px", padding: 4 },
      }}
      maxWidth="lg"
      onClose={(event, reason) => handleClose()}
    >
      <IconButton
        onClick={() => handleClose()}
        sx={{ position: "absolute", right: 5, top: 5 }}
      >
        <Close />
      </IconButton>
      <Stack width="950px" spacing={4}>
        <Typography
          textAlign="center"
          variant="h6"
          color="neutral1000.main"
          fontWeight={500}
        >
          {id ? "Editar protocolo" : "Criar protocolo"}
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Stack width="100%">
            <FormTextInput
              name="name"
              label="Nome do protocolo"
              defaultValue={state?.defaultItens?.display}
              required
              control={control}
            />
          </Stack>
          <Stack>
            <FormControl
              required
              variant="filled"
              hiddenLabel
              fullWidth
              size="small"
              sx={{
                "& .MuiFilledInput-root": {
                  width: "200px",

                  ":&focus": { backgroundColor: "primary100.main" },
                },
              }}
            >
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                required
                sx={{ height: "50px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={statusValue}
                label="Status"
              >
                {statusOptions
                  ?.filter((e: any) =>
                    !state?.defaultItens
                      ? e?.value !== "amended" && e?.value !== "stopped"
                      : true
                  )
                  ?.map((e: any, index: number) => (
                    <MenuItem
                      value={e?.value}
                      key={e?.value}
                      onClick={() => handleChangeStatus(e?.value)}
                    >
                      {e?.display}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>

        <FormMultipleAutoComplete
          freeSolo
          variant="filled"
          // loading={loading}
          name="condition"
          label="Diagnósticos associados"
          control={control}
          onInputChange={(e: any) => {
            if (e?.target?.value?.length) {
              setSearchCondition(e?.target?.value);
            } else {
              setSearchCondition("");
            }
          }}
          renderOption={(option: any) => <CustomCID10Option option={option} />}
          selectOptions={conditionList}
          autoFocus
        />

        <InputText
          minRows={2}
          maxRows={100}
          multiline
          label="Descrição"
          defaultValue={state?.defaultItens?.description}
          name="description"
          control={control}
        />

        <Stack spacing={2}>
          <Box sx={{ borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {auxTabs?.map((e: any, i: number) => {
                return <Tab label={e} {...a11yProps(i)} />;
              })}
            </Tabs>

            {auxTabs?.map((e: any, i: number) => {
              return (
                <CustomTabPanel value={tabValue} index={i}>
                  {e === "Tratamentos" && (
                    <>
                      {auxTratamentos?.map((el: any, index: number) => {
                        const auxTag = tags.find((tag: any) =>
                          el.tag?.toUpperCase()?.includes(tag.name)
                        );
                        return (
                          <Stack
                            direction="row"
                            width="100%"
                            mb={1}
                            key={`${index}`}
                          >
                            <Controller
                              name={`itens.${el?.type}-${el?.name}-${el?.label}`}
                              control={control}
                              render={({
                                field: { onChange, ref, ...field },
                              }) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      inputRef={ref}
                                      onChange={onChange}
                                      defaultChecked
                                      {...field}
                                    />
                                  }
                                  label=""
                                />
                              )}
                            />

                            <Stack
                              p={1}
                              gap={1}
                              sx={{
                                borderRadius: "5px",
                                width: "100%",
                              }}
                            >
                              <Stack direction="row" gap={1}>
                                <Typography
                                  variant="body1"
                                  color="neutral1000.main"
                                  fontWeight={500}
                                >
                                  {index + 1}. {el.name}
                                </Typography>

                                {el.inputValue?.presentation && (
                                  <Typography
                                    variant="body1"
                                    color="neutral700.main"
                                  >
                                    {el.inputValue?.presentation}
                                  </Typography>
                                )}

                                {el.tag && (
                                  <Tooltip title={auxTag?.display}>
                                    <Button
                                      sx={{
                                        background:
                                          auxTag?.background ||
                                          auxTag?.color ||
                                          "white",

                                        width: "21px",
                                        minWidth: "21px",
                                        height: "22px",
                                        "&:hover": {
                                          backgroundColor:
                                            auxTag?.background ||
                                            auxTag?.color ||
                                            "white",
                                        },
                                      }}
                                      variant="contained"
                                    >
                                      <Typography
                                        sx={{
                                          color: auxTag?.background
                                            ? auxTag?.color
                                            : "black",
                                        }}
                                        variant="body1"
                                        color="neutral700.main"
                                      >
                                        {auxTag?.value || "-"}
                                      </Typography>
                                    </Button>
                                  </Tooltip>
                                )}
                              </Stack>

                              {el.formValue?.label && (
                                <Typography
                                  variant="body1"
                                  color="neutral700.main"
                                >
                                  {el.form}
                                </Typography>
                              )}

                              {el?.type === "service" &&
                                el?.inputValue?.code && (
                                  <Typography
                                    variant="body1"
                                    color="neutral700.main"
                                  >
                                    Código TUSS: {el?.inputValue?.code}
                                  </Typography>
                                )}

                              {el?.category && (
                                <Typography
                                  variant="body1"
                                  color="neutral700.main"
                                >
                                  Categoria: {el?.category?.label}
                                </Typography>
                              )}

                              {el.condition && (
                                <Typography
                                  variant="body1"
                                  color="neutral700.main"
                                >
                                  Condição: {el?.condition?.label}{" "}
                                  {!el?.condition?.name &&
                                    el.condition?.value &&
                                    `- CID10 ${el.condition?.value}`}
                                </Typography>
                              )}

                              {el.note && (
                                <Typography
                                  variant="body1"
                                  color="neutral700.main"
                                >
                                  {el.note}
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                        );
                      })}
                    </>
                  )}

                  {e === "Exames" && (
                    <>
                      {auxService?.map((el: any, index: number) => {
                        const auxTag = tags.find((tag: any) =>
                          el.tag?.toUpperCase()?.includes(tag.name)
                        );
                        return (
                          <Stack
                            direction="row"
                            width="100%"
                            mb={1}
                            key={`${index}`}
                          >
                            <Controller
                              name={`itens.${el?.type}-${el?.name}-${el?.label}`}
                              control={control}
                              render={({
                                field: { onChange, ref, ...field },
                              }) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      inputRef={ref}
                                      onChange={onChange}
                                      defaultChecked
                                      {...field}
                                    />
                                  }
                                  label=""
                                />
                              )}
                            />
                            <Stack
                              p={1}
                              gap={1}
                              sx={{
                                borderRadius: "5px",
                                width: "100%",
                              }}
                            >
                              <Stack direction="row" gap={1}>
                                <Typography
                                  variant="body1"
                                  color="neutral1000.main"
                                  fontWeight={500}
                                >
                                  {index + 1}. {el.name}
                                </Typography>

                                {el.inputValue?.presentation && (
                                  <Typography
                                    variant="body1"
                                    color="neutral700.main"
                                  >
                                    {el.inputValue?.presentation}
                                  </Typography>
                                )}

                                {el.tag && (
                                  <Tooltip title={auxTag?.display}>
                                    <Button
                                      sx={{
                                        background:
                                          auxTag?.background ||
                                          auxTag?.color ||
                                          "white",

                                        width: "21px",
                                        minWidth: "21px",
                                        height: "22px",
                                        "&:hover": {
                                          backgroundColor:
                                            auxTag?.background ||
                                            auxTag?.color ||
                                            "white",
                                        },
                                      }}
                                      variant="contained"
                                    >
                                      <Typography
                                        sx={{
                                          color: auxTag?.background
                                            ? auxTag?.color
                                            : "black",
                                        }}
                                        variant="body1"
                                        color="neutral700.main"
                                      >
                                        {auxTag?.value || "-"}
                                      </Typography>
                                    </Button>
                                  </Tooltip>
                                )}
                              </Stack>

                              {el.formValue?.label && (
                                <Typography
                                  variant="body1"
                                  color="neutral700.main"
                                >
                                  {el.form}
                                </Typography>
                              )}

                              {el?.type === "service" &&
                                el?.inputValue?.code && (
                                  <Typography
                                    variant="body1"
                                    color="neutral700.main"
                                  >
                                    Código TUSS: {el?.inputValue?.code}
                                  </Typography>
                                )}

                              {el?.category && (
                                <Typography
                                  variant="body1"
                                  color="neutral700.main"
                                >
                                  Categoria: {el?.category?.label}
                                </Typography>
                              )}

                              {el.condition && (
                                <Typography
                                  variant="body1"
                                  color="neutral700.main"
                                >
                                  Condição: {el?.condition?.label}{" "}
                                  {!el?.condition?.name &&
                                    el.condition?.value &&
                                    `- CID10 ${el.condition?.value}`}
                                </Typography>
                              )}

                              {el.note && (
                                <Typography
                                  variant="body1"
                                  color="neutral700.main"
                                >
                                  {el.note}
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                        );
                      })}
                    </>
                  )}

                  {e === "Procedimentos" && (
                    <>
                      {auxProcedure?.map((el: any, index: number) => {
                        const auxTag = tags.find((tag: any) =>
                          el.tag?.toUpperCase()?.includes(tag.name)
                        );
                        return (
                          <Stack
                            direction="row"
                            width="100%"
                            mb={1}
                            key={`${index}`}
                          >
                            <Controller
                              name={`itens.${el?.type}-${el?.name}-${el?.label}`}
                              control={control}
                              render={({
                                field: { onChange, ref, ...field },
                              }) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      inputRef={ref}
                                      onChange={onChange}
                                      defaultChecked
                                      {...field}
                                    />
                                  }
                                  label=""
                                />
                              )}
                            />
                            <Stack
                              p={1}
                              gap={1}
                              sx={{
                                borderRadius: "5px",
                                width: "100%",
                              }}
                            >
                              <Stack direction="row" gap={1}>
                                <Typography
                                  variant="body1"
                                  color="neutral1000.main"
                                  fontWeight={500}
                                >
                                  {index + 1}. {el.name}
                                </Typography>

                                {el.inputValue?.presentation && (
                                  <Typography
                                    variant="body1"
                                    color="neutral700.main"
                                  >
                                    {el.inputValue?.presentation}
                                  </Typography>
                                )}

                                {el.tag && (
                                  <Tooltip title={auxTag?.display}>
                                    <Button
                                      sx={{
                                        background:
                                          auxTag?.background ||
                                          auxTag?.color ||
                                          "white",

                                        width: "21px",
                                        minWidth: "21px",
                                        height: "22px",
                                        "&:hover": {
                                          backgroundColor:
                                            auxTag?.background ||
                                            auxTag?.color ||
                                            "white",
                                        },
                                      }}
                                      variant="contained"
                                    >
                                      <Typography
                                        sx={{
                                          color: auxTag?.background
                                            ? auxTag?.color
                                            : "black",
                                        }}
                                        variant="body1"
                                        color="neutral700.main"
                                      >
                                        {auxTag?.value || "-"}
                                      </Typography>
                                    </Button>
                                  </Tooltip>
                                )}
                              </Stack>

                              {el.formValue?.label && (
                                <Typography
                                  variant="body1"
                                  color="neutral700.main"
                                >
                                  {el.form}
                                </Typography>
                              )}

                              {el?.type === "service" &&
                                el?.inputValue?.code && (
                                  <Typography
                                    variant="body1"
                                    color="neutral700.main"
                                  >
                                    Código TUSS: {el?.inputValue?.code}
                                  </Typography>
                                )}

                              {el?.category && (
                                <Typography
                                  variant="body1"
                                  color="neutral700.main"
                                >
                                  Categoria: {el?.category?.label}
                                </Typography>
                              )}

                              {el.condition && (
                                <Typography
                                  variant="body1"
                                  color="neutral700.main"
                                >
                                  Condição: {el?.condition?.label}{" "}
                                  {!el?.condition?.name &&
                                    el.condition?.value &&
                                    `- CID10 ${el.condition?.value}`}
                                </Typography>
                              )}

                              {el.note && (
                                <Typography
                                  variant="body1"
                                  color="neutral700.main"
                                >
                                  {el.note}
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                        );
                      })}
                    </>
                  )}

                  {e === "Relatórios" && (
                    <>
                      {auxCertificate?.map((el: any, index: number) => {
                        const auxTag = tags.find((tag: any) =>
                          el.tag?.toUpperCase()?.includes(tag.name)
                        );
                        return (
                          <Stack
                            direction="row"
                            width="100%"
                            mb={1}
                            key={`${index}`}
                          >
                            <Controller
                              name={`itens.${el?.type}-${el?.name}-${el?.label}`}
                              control={control}
                              render={({
                                field: { onChange, ref, ...field },
                              }) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      inputRef={ref}
                                      onChange={onChange}
                                      defaultChecked
                                      {...field}
                                    />
                                  }
                                  label=""
                                />
                              )}
                            />
                            <Stack
                              p={1}
                              gap={1}
                              sx={{
                                borderRadius: "5px",
                                width: "100%",
                              }}
                            >
                              <Stack direction="row" gap={1}>
                                <Typography
                                  variant="body1"
                                  color="neutral1000.main"
                                  fontWeight={500}
                                >
                                  {index + 1}. {el.name}
                                </Typography>

                                {el.inputValue?.presentation && (
                                  <Typography
                                    variant="body1"
                                    color="neutral700.main"
                                  >
                                    {el.inputValue?.presentation}
                                  </Typography>
                                )}

                                {el.tag && (
                                  <Tooltip title={auxTag?.display}>
                                    <Button
                                      sx={{
                                        background:
                                          auxTag?.background ||
                                          auxTag?.color ||
                                          "white",

                                        width: "21px",
                                        minWidth: "21px",
                                        height: "22px",
                                        "&:hover": {
                                          backgroundColor:
                                            auxTag?.background ||
                                            auxTag?.color ||
                                            "white",
                                        },
                                      }}
                                      variant="contained"
                                    >
                                      <Typography
                                        sx={{
                                          color: auxTag?.background
                                            ? auxTag?.color
                                            : "black",
                                        }}
                                        variant="body1"
                                        color="neutral700.main"
                                      >
                                        {auxTag?.value || "-"}
                                      </Typography>
                                    </Button>
                                  </Tooltip>
                                )}
                              </Stack>

                              {el.formValue?.label && (
                                <Typography
                                  variant="body1"
                                  color="neutral700.main"
                                >
                                  {el.form}
                                </Typography>
                              )}

                              {el?.type === "service" &&
                                el?.inputValue?.code && (
                                  <Typography
                                    variant="body1"
                                    color="neutral700.main"
                                  >
                                    Código TUSS: {el?.inputValue?.code}
                                  </Typography>
                                )}

                              {el?.category && (
                                <Typography
                                  variant="body1"
                                  color="neutral700.main"
                                >
                                  Categoria: {el?.category?.label}
                                </Typography>
                              )}

                              {el.condition && (
                                <Typography
                                  variant="body1"
                                  color="neutral700.main"
                                >
                                  Condição: {el?.condition?.label}{" "}
                                  {!el?.condition?.name &&
                                    el.condition?.value &&
                                    `- CID10 ${el.condition?.value}`}
                                </Typography>
                              )}

                              {el.note && (
                                <Typography
                                  variant="body1"
                                  color="neutral700.main"
                                >
                                  {el.note}
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                        );
                      })}
                    </>
                  )}
                </CustomTabPanel>
              );
            })}
          </Box>
        </Stack>

        <InputText
          minRows={2}
          maxRows={100}
          multiline
          defaultValue={state?.defaultItens?.references}
          label="Referências"
          name="referencias"
          control={control}
        />

        <FormButtonGroup
          onGoBackButtonClick={handleClose}
          onNextButtonClick={handleSubmit(onSubmit)}
          goBackButtonText="Cancelar"
          nextButtonText="Salvar"
          loading={false}
        />
      </Stack>
    </Dialog>
  );
}
