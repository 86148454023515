import { Person } from "@mui/icons-material";
import { IFeatures } from "../../utils/interfaces";
import { ScheduleSettings } from "./scheduleSettings";

import { useSessionContext } from "../../contexts/SessionContext";

export function useScheduleSettings() {
  const { access } = useSessionContext();
  const configs: IFeatures = {
    active: access?.type === "professional",
    path: "/scheduleSettings",
    icon: Person,
    text: "Configurações Agenda",

    component: () => <ScheduleSettings />,
  };

  return configs;
}
