import { useLocation } from "react-router-dom";
import { LargeContentBox, PageTitle } from "../../components/common";
import { PrivateMainLayout } from "../../components/layout";
import { CreateProtocolContent } from "./CreateProtocolContent";
import { Stack } from "@mui/material";

export function CreateProtocol() {
  const { state } = useLocation();
  return (
    <PrivateMainLayout>
      <LargeContentBox>
        <Stack sx={{ width: "100%" }}>
          <PageTitle>
            {state?.defaultItens
              ? state?.defaultItens?.display
              : "Criar protocolo"}
          </PageTitle>
          <CreateProtocolContent
            id={state?.id}
            defaultItens={state?.defaultItens}
          />
        </Stack>
      </LargeContentBox>
    </PrivateMainLayout>
  );
}
