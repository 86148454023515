import { isMobile } from "react-device-detect";
import { PrivateMainLayout } from "../../components/layout";
import { CircularLoading, LargeContentBox } from "../../components/common";
import { useEffect, useState } from "react";
import { ManageProtocol } from "./components/ManageProtocol";
import { GetPrescriptionProtocol } from "../../services/fhir";
import { endpoints } from "../../configs/Settings";
import { useOrganizationContext } from "../../contexts/OrganizationContext";

export function ManagerProtocol() {
  const [protocol, setProtocol] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [refetch, setRefetch] = useState<boolean>(false);

  const { organization } = useOrganizationContext();

  useEffect(() => {
    async function fetchProtocol() {
      setLoading(true);
      try {
        const response = await GetPrescriptionProtocol({
          questionnaire: endpoints.PROTOCOL_ID,
          author: organization?.id,
        });

        setProtocol(response?.entry);
      } catch (err) {
        console.log("fetchProtocol", err);
      } finally {
        setLoading(false);
      }
    }
    fetchProtocol();
  }, [organization, refetch]);

  return (
    <PrivateMainLayout noSidebar={isMobile} mobileView={isMobile}>
      <LargeContentBox>
        {loading ? (
          <CircularLoading />
        ) : (
          <ManageProtocol data={protocol} setRefetch={setRefetch} />
        )}
      </LargeContentBox>
    </PrivateMainLayout>
  );
}
