import { useState, SetStateAction, Dispatch } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { DialogContent, DialogTitle, Stack } from "@mui/material";

import { CommonDialog } from "../../../components/common/CommonDialog";
import { FormButtonGroup, FormTextInput } from "../../../components/form";

import { useSessionContext } from "../../../contexts/SessionContext";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useRegex } from "../../../utils/useRegex";
import { UpdateEmail } from "../../../services/fhir/user/UpdateEmail";
import { isMobile } from "react-device-detect";

interface IFormInput {
  email: string;
}

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export default function EditEmailDialog({ isOpen, setIsOpen }: IProps) {
  const [loading, setLoading] = useState(false);
  const { user, fetchUser } = useSessionContext();

  const defaultValues = {
    email: user?.email?.[0],
  };

  const methods = useForm<IFormInput>({ defaultValues });

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
    reset,
  } = methods;

  const onSubmit = async (data: IFormInput) => {
    setLoading(true);
    try {
      const response = await UpdateEmail({
        email: data.email,
        identifier: user.username,
      });
      if (response?.status === HTTPResponseStatus.Success) {
        await fetchUser();
        toast.success(
          "Foi enviado com sucesso um e-mail para confirmar a alteração!"
        );
        setIsOpen(false);
        return;
      }
      toast.error(
        "Houve um erro na atualização do e-mail, tente novamente mais tarde"
      );
    } catch (error) {
      toast.error(
        "Houve um erro na atualização do e-mail, tente novamente mais tarde"
      );
    } finally {
      setLoading(false);
    }
  };

  function handleClose() {
    setIsOpen(false);
    reset(defaultValues);
  }

  return (
    <CommonDialog
      handleClose={handleClose}
      isOpen={isOpen}
      mobileView={isMobile}
    >
      <DialogTitle textAlign="center">Editar e-mail</DialogTitle>
      <DialogContent>
        <Stack pt={1}>
          <FormTextInput
            name="email"
            label="E-mail"
            control={control}
            pattern={useRegex.email}
            autoFocus={true}
          />
        </Stack>
        <Stack
          minWidth="100%"
          mt={3}
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <FormButtonGroup
            onGoBackButtonClick={() => setIsOpen(false)}
            onNextButtonClick={handleSubmit(onSubmit)}
            goBackButtonText="Cancelar"
            nextButtonText="Salvar"
            loading={loading}
            disabled={!isDirty || !isValid}
          />
        </Stack>
      </DialogContent>
    </CommonDialog>
  );
}
