import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Stack, Tab, Tooltip, Typography } from "@mui/material";
import {
  CheckCircle,
  HighlightOffRounded,
  MailOutline,
} from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import { PrivateMainLayout } from "../../components/layout";
import { useDict } from "../../utils/useDict";

import {
  LargeContentBox,
  PageTitle,
  PrimaryButton,
  SecondaryButton,
} from "../../components/common";
import { GenericTable } from "../../components/GenericTable/GenericTable";
import { ManagePractitionerActions } from "./components/ManagePractitionersActions";
import { InviteDialog } from "./components/InviteDialog";
import { usePractitionerRoleContext } from "../../contexts/PractitionerRoleContext";
import { useFormatter } from "../../utils/useFormatter";
import { useContractContext } from "../../contexts/ContractContext";

export function ManagePractitioners() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("1");
  const { practitionerRole, practitioner } = usePractitionerRoleContext();

  const { currentContract } = useContractContext();

  const { formatNameDisplay } = useFormatter;

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const columns = [
    {
      column: "Profissional",
      rowName: "practitionerName",
    },
    { column: "Número no conselho de classe", rowName: "classId" },
    { column: "Atividade", rowName: "displayRoles" },
    { column: "Especialidade", rowName: "speciality" },
  ];

  const rows = practitionerRole?.map(
    (el: fhir5.BundleEntry<fhir5.PractitionerRole>) => {
      const practitionerReference = practitioner?.find(
        (e: fhir5.BundleEntry<fhir5.Practitioner>) =>
          e?.resource?.id
            ? el?.resource?.practitioner?.reference?.includes(e?.resource?.id)
            : false
      );

      const classId = useFormatter.formatConselho(
        el?.resource?.identifier?.[0]?.value || ""
      );
      const speciality =
        el?.resource?.specialty
          ?.map(
            (element: fhir5.CodeableConcept) => element?.coding?.[0]?.display
          )
          ?.join(", ") || "-";
      const displayRoles =
        el?.resource?.code
          ?.map(
            (role: fhir5.CodeableConcept) =>
              useDict.roles[role?.coding?.[0]?.system || ""]
          )
          ?.filter((el: any) => el !== undefined)
          ?.join(", ") || "-";

      const roles = el?.resource?.code?.map(
        (el: fhir5.CodeableConcept) => el?.coding?.[0]?.system
      );

      const situation =
        el?.resource?.active === false
          ? "Inativo"
          : displayRoles?.includes("Convidado")
          ? "Convidado"
          : "Ativo";
      return {
        practitionerName: formatNameDisplay(
          practitionerReference?.resource?.name?.[0]?.text || "-"
        ),
        practitionerId: practitionerReference?.resource?.id || "-",
        classId,
        displayRoles: displayRoles?.replace(/\bConvidado\b(, )?/g, ""),
        roles,
        speciality,
        situation,
      };
    }
  );

  // const countPractitioner =
  //   practitionerRole && currentContract
  //     ? parseInt(
  //         currentContract?.[0]?.resource?.term?.[0]?.identifier?.value || ""
  //       ) -
  //       practitionerRole?.filter((el: any) => el?.resource?.active !== false)
  //         ?.length
  //     : 0;
  // const limitPractitioners =
  //   (practitionerRole &&
  //     practitionerRole?.filter((el: any) => el?.resource?.active !== false)
  //       ?.length >=
  //       parseInt(
  //         currentContract?.[0]?.resource?.term?.[0]?.identifier?.value || ""
  //       )) ||
  //   false;

  return (
    <PrivateMainLayout>
      <LargeContentBox>
        <Stack width="100%">
          <PageTitle>Lista de profissionais</PageTitle>

          <TabContext value={selectedTab}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <TabList onChange={handleChangeTab}>
                <Tab
                  style={{ display: "flex", alignItems: "center" }}
                  label={
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <CheckCircle sx={{ marginRight: 1 }} />
                      Ativos
                    </Box>
                  }
                  value="1"
                />

                <Tab
                  style={{ display: "flex", alignItems: "center" }}
                  label={
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <MailOutline sx={{ marginRight: 1 }} />
                      Convidados
                    </Box>
                  }
                  value="2"
                />
                <Tab
                  style={{ display: "flex", alignItems: "center" }}
                  label={
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <HighlightOffRounded sx={{ marginRight: 1 }} />
                      Inativos
                    </Box>
                  }
                  value="3"
                />
              </TabList>

              {/* {!currentContract ? (
                <Typography variant="body1">
                  Verifique a validade do seu contrato para adicionar
                  profissionais
                </Typography>
              ) : countPractitioner > 0 ? (
                <Typography variant="body1">
                  {countPractitioner === 1
                    ? `Ainda há ${countPractitioner} vaga disponível`
                    : `Ainda há ${countPractitioner} vagas disponíveis`}
                </Typography>
              ) : (
                <Typography variant="body1">
                  Limite de profissionais atingido
                </Typography>
              )} */}
            </Box>
            <TabPanel value="1">
              <GenericTable
                tableColumns={columns}
                tableRows={rows?.filter((el: any) => el?.situation === "Ativo")}
                actions={(rowData: any) => (
                  <ManagePractitionerActions row={rowData} />
                )}
              />
            </TabPanel>
            <TabPanel value="2">
              <GenericTable
                tableColumns={columns}
                tableRows={rows?.filter(
                  (el: any) => el?.situation === "Convidado"
                )}
                actions={(rowData: any) => (
                  <ManagePractitionerActions row={rowData} />
                )}
              />
            </TabPanel>
            <TabPanel value="3">
              <GenericTable
                tableColumns={columns}
                tableRows={rows?.filter(
                  (el: any) => el?.situation === "Inativo"
                )}
                actions={(rowData: any) => (
                  <ManagePractitionerActions row={rowData} />
                )}
              />
            </TabPanel>
          </TabContext>
        </Stack>

        <Stack width="100%" direction="row" justifyContent="space-between">
          <SecondaryButton
            onClick={() => navigate(-1)}
            width="180px"
            height="50px"
          >
            Voltar
          </SecondaryButton>

          <PrimaryButton
            width="fit-content"
            height="50px"
            onClick={() => setIsOpen(true)}
            // disabled={!currentContract ? true : limitPractitioners}
          >
            Convidar profissional
          </PrimaryButton>
        </Stack>

        {isOpen && (
          <InviteDialog
            practitioners={practitionerRole}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        )}
      </LargeContentBox>
    </PrivateMainLayout>
  );
}
