import {
  Document,
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#f9f9f9",
    width: "100%",
    padding: "180px 40px",
  },
  textFooter: {
    fontSize: 12,
    fontFamily: "Helvetica-Bold",
    textTransform: "uppercase",
  },
  headerMedication: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 100,
    textTransform: "uppercase",
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
  },
  contentMedication: {
    flexGrow: 1,
    justifyContent: "flex-start",
  },
  footerContainer: {
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    height: 120,
    padding: "5px 40px",
  },
  text: {
    fontSize: 12,
    fontFamily: "Helvetica-Bold",
    textTransform: "uppercase",
    padding: "5px 0px",
  },
  textResponse: {
    fontSize: 12,
    fontFamily: "Helvetica",
    textTransform: "uppercase",
  },
  box: {
    marginBottom: 10,
  },
  pageBackground: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    minWidth: "100%",
    minHeight: "100%",
    objectFit: "cover",
    zIndex: -1,
  },
});

export function ViewDocument({ fileBase64, qrCodeBase64 }: any) {
  const auxMedication = [
    {
      name: "Medicamento 1",
      label: "Medicamento 1",
      inputValue: {
        name: "Medicamento 1",
        value: "Medicamento 1",
        label: "Medicamento 1",
      },
      formValue: {},
      note: "observação 1",
      form: "",
      type: "medication",
      tabValue: "1",
    },
    {
      name: "Medicamento 2",
      label: "Medicamento 2",
      inputValue: {
        name: "Medicamento 2",
        value: "Medicamento 2",
        label: "Medicamento 2",
      },
      formValue: {},
      note: "observação 2",
      form: "",
      type: "medication",
      tabValue: "1",
    },
    {
      name: "Medicamento 3",
      label: "Medicamento 3",
      inputValue: {
        name: "Medicamento 3",
        value: "Medicamento 3",
        label: "Medicamento 3",
      },
      formValue: {},
      note: "observação 3",
      form: "",
      type: "medication",
      tabValue: "1",
    },
    {
      name: "Medicamento 4",
      label: "Medicamento 4",
      inputValue: {
        name: "Medicamento 4",
        value: "Medicamento 4",
        label: "Medicamento 4",
      },
      formValue: {},
      note: "observação 4",
      form: "",
      type: "medication",
      tabValue: "1",
    },
    {
      name: "Medicamento 5",
      label: "Medicamento 5",
      inputValue: {
        name: "Medicamento 5",
        value: "Medicamento 5",
        label: "Medicamento 5",
      },
      formValue: {},
      note: "observação 5",
      form: "",
      type: "medication",
      tabValue: "1",
    },
    {
      name: "Medicamento 6",
      label: "Medicamento 6",
      inputValue: {
        name: "Medicamento 6",
        value: "Medicamento 6",
        label: "Medicamento 6",
      },
      formValue: {},
      note: "observação 6",
      form: "",
      type: "medication",
      tabValue: "1",
    },
  ];

  const renderMedications = (medications: any[]) =>
    medications.map((el: any, index: number) => (
      <View key={index} style={styles.box}>
        <Text style={styles.text}>
          {index + 1}.<Text style={styles.textResponse}> {el.label} </Text>
        </Text>
        {el.form && (
          <Text style={styles.text}>
            Modo de Usar:
            <Text style={styles.textResponse}> {el.form} </Text>
          </Text>
        )}
        {el.note && (
          <Text style={styles.text}>
            Obs:
            <Text style={styles.textResponse}> {el.note} </Text>
          </Text>
        )}
      </View>
    ));

  return (
    <PDFViewer
      showToolbar={false}
      style={{
        width: "100%",
        height: "100%",
        minHeight: 580,
        border: "1px solid #ccc",
      }}
    >
      <Document>
        <Page size="A4" style={styles.page}>
          {fileBase64 && (
            <Image src={fileBase64} style={styles.pageBackground} fixed />
          )}

          <View style={styles.headerMedication}>
            <Text></Text>
          </View>
          <View style={styles.contentMedication}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Text style={styles.text}>
                Dr.{" "}
                <Text style={styles.textResponse}>
                  nome do profissional de saude
                </Text>
              </Text>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <Text style={styles.textFooter}>
                  CRM:
                  <Text style={styles.textResponse}>00000000-UF</Text>
                </Text>
              </View>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Text style={styles.text}>
                Paciente:
                <Text style={styles.textResponse}>Nome do paciente</Text>
              </Text>
              <Text style={styles.text}>
                CPF:
                <Text style={styles.textResponse}>000.000.000-00</Text>
              </Text>
            </View>

            <Text style={styles.text}>Prescrição:</Text>
            {renderMedications(auxMedication)}

            <View style={styles.footerContainer} fixed>
              <Text></Text>
            </View>
          </View>
        </Page>

        {/* Abaixo um exemplo de uma página com o QRCode para pré visualização */}

        {/* <Page
          wrap={true}
          size="A4"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 40,
            paddingHorizontal: 20,
          }}
        >
          <Text
            style={{
              fontSize: 18,
              fontWeight: "bold",
              marginBottom: 10,
              textAlign: "center",
            }}
          >
            Visualizar Documento
          </Text>

          <Text style={{ fontSize: 12, textAlign: "center", marginBottom: 5 }}>
            Escaneie o QR code abaixo para acessar informações adicionais.
          </Text>

          <Text style={{ fontSize: 12, textAlign: "center", marginBottom: 20 }}>
            Ou clique no link para abrir o documento diretamente.
          </Text>

          {qrCodeBase64 && (
            <Image
              src={qrCodeBase64}
              style={{
                maxWidth: "90px",
                maxHeight: "90px",
                marginBottom: 15,
              }}
            />
          )}

          <Link
            src={`${endpoints.XDSB_domain}/portal`}
            style={{
              fontSize: 12,
              color: "blue",
              textDecoration: "underline",
            }}
          >
            Clique aqui para visualizar o documento
          </Link>
        </Page> */}
      </Document>
    </PDFViewer>
  );
}
