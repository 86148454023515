import { IconButton, TableCell, Tooltip } from "@mui/material";
import { Assignment } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useViewContext } from "../../../contexts/ViewContext";

export function AppointmentsHistoryActions({ row }: any) {
  const navigate = useNavigate();
  const patientId = row?.patientId;
  const { availableRoles } = useViewContext();

  return (
    <TableCell align="center">
      {availableRoles?.includes("practitioner") && (
        <Tooltip title="Acessar registro">
          <IconButton
            onClick={() => {
              if (patientId)
                navigate("/summary", {
                  state: {
                    patientId: row?.patientId?.split("-")?.[1],
                  },
                });
            }}
          >
            <Assignment />
          </IconButton>
        </Tooltip>
      )}
    </TableCell>
  );
}
