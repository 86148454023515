import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";

export async function DeletePrescriptionProtocol(id: any) {
  let url = `${endpoints.ADMFHIR}/QuestionnaireResponse/${id}`;

  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
  };

  const response = await useAxios
    .delete(url, { headers, timeout: 8000 })
    .then((response) => {
      if (
        response.status !== HTTPResponseStatus.Created &&
        response.status !== HTTPResponseStatus.Success
      ) {
        throw new Error(`PostQuestionnaireResponse: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("DeletePrescriptionProtocol ", error);
      toast.error(
        `Não foi possivel deletar o protocolo, tente novamente mais tarde, ou sinalize ao support: ${error?.response?.status}`
      );
      throw new Error();
    });
  if (response) return response;
}
