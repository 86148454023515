import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";

import { EditScheduleDialog } from "./EditScheduleDialog";
import { DeactivateScheduleDialog } from "./DeactivateScheduleDialog";
import { MoreVert } from "@mui/icons-material";

export function OptionsEditScheduleDialog({ scheduleData }: any) {
  const [editScheduleDialog, setEditScheduleDialog] = useState(false);
  const [deactivateScheduleDialog, setDeactivateScheduleDialog] =
    useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <MenuItem
          onClick={() => {
            setEditScheduleDialog(true);
            handleClose();
          }}
        >
          Editar agenda
        </MenuItem>*/}
        <MenuItem
          onClick={() => {
            setDeactivateScheduleDialog(true);
            // setRefetch((prev: boolean) => !prev);
            handleClose();
          }}
        >
          Cancelar agenda
        </MenuItem>
      </Menu>
      {editScheduleDialog && (
        <EditScheduleDialog
          scheduleData={scheduleData}
          editScheduleDialog={editScheduleDialog}
          handleClose={() => setEditScheduleDialog(false)}
        />
      )}
      {deactivateScheduleDialog && (
        <DeactivateScheduleDialog
          id={scheduleData?.id}
          deactivateScheduleDialog={deactivateScheduleDialog}
          handleClose={() => setDeactivateScheduleDialog((prev) => !prev)}
        />
      )}
    </>
  );
}
