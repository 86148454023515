import { useState, SetStateAction, Dispatch, useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { DialogContent, DialogTitle, Stack } from "@mui/material";

import { CommonDialog } from "../../../components/common/CommonDialog";
import { FormButtonGroup } from "../../../components/form";

import { useSessionContext } from "../../../contexts/SessionContext";
import { HTTPResponseStatus } from "../../../utils/enum";
import { GetValueSet } from "../../../services/terminology";
import { ISpecialty } from "../../professionalregister/Types";
import ProfessionalForm from "../../professionalregister/components/ProfessionalForm";
import {
  GetPractitionerRole,
  UpdatePractitionerRole,
} from "../../../services/fhir";
import { oids } from "../../../configs/Settings";
import { isMobile } from "react-device-detect";
import { usePractitionerRoleContext } from "../../../contexts/PractitionerRoleContext";
export interface IFormInput {
  classe: number | undefined;
  uf: string;
  speciality: string;
  number: string;
}

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  seletedRole: any;
  setSelectedRole: any;
}

export default function EditPractitionerRoleDialog({
  isOpen,
  setIsOpen,
  seletedRole,
  setSelectedRole,
}: IProps) {
  const { user, fetchUserRoles } = useSessionContext();
  const { refetchPractitionerRole } = usePractitionerRoleContext();
  const [loading, setLoading] = useState(false);

  const options = [
    {
      display: "CRM - Conselho Regional de Medicina",
      value: "CRM",
      descricao: "Medicina",
      code: 1,
    },
    {
      display: "COREN - Conselho Regional de Enfermagem",
      value: "COREN",
      descricao: "Enfermagem",
      code: 2,
    },
    {
      display: "CRO - Conselho Regional de Odontologia",
      value: "CRO",
      descricao: "Odontologia",
      code: 3,
    },
    {
      display:
        "CREFITO - Conselho Regional de Fisioterapia e Terapia Ocupacional",
      value: "CREFITO",
      descricao: "Fisioterapia e Terapia Ocupacional",
      code: 4,
    },
    {
      display: "CRP - Conselho Regional de Psicologia",
      value: "CRP",
      descricao: "Psicologia",
      code: 5,
    },
    {
      display: "CRF - Conselho Regional de Farmácia",
      value: "CRF",
      descricao: "Farmácia",
      code: 6,
    },
    {
      display: "CRN - Conselho Regional de Nutricionistas",
      value: "CRN",
      descricao: "Nutrição",
      code: 7,
    },
    {
      display: "CRBM - Conselho Regional de Biomedicina",
      value: "CRBM",
      descricao: "Biomedicina",
      code: 8,
    },
    {
      display: "CREFONO - Conselho Regional de Fonoaudiologia",
      value: "CREFONO",
      descricao: "Fonoaudiologia",
      code: 9,
    },
    {
      display: "CREF - Conselho Regional de Educação Física",
      value: "CREF",
      descricao: "Educação Física",
      code: 10,
    },
  ];

  const [specialties, setSpecialties] = useState([
    { value: "", label: "Carregando" },
  ]);

  const classId = {
    value: seletedRole?.classId?.split("/")[1].slice(2),
    uf: seletedRole?.classId?.split("/")[1].slice(0, 2),
  };

  const defaultValues = {
    number: classId?.value,
    classe: seletedRole?.classId?.split("/")?.[0],
    uf: { label: classId.uf, value: classId.uf },
    speciality:
      seletedRole?.classId?.split("/")?.[0] === "CRM" &&
      seletedRole?.specialty?.map((el: any) => {
        return {
          value: el?.coding[0]?.code,
          label: el?.coding[0]?.display,
        };
      }),
  };

  const methods = useForm({ defaultValues });

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
    reset,
  } = methods;

  function handleClose() {
    setIsOpen(false);
    setSelectedRole();
    reset(defaultValues);
  }

  const onSubmit = async (data: any) => {
    setLoading(true);

    try {
      const practitionerRole = await GetPractitionerRole({
        id: `${seletedRole?.id.split("-")[0]}-${oids.cpf}-${user.username}`,
      });
      await UpdatePractitionerRole(practitionerRole, {
        conselho: data?.classe,
        numero: data?.number,
        uf: data?.uf,
        speciality:
          data?.classe === "CRM"
            ? data?.speciality
            : options?.find((e: any) => e.value === data?.classe),
      });
      await fetchUserRoles();
      handleClose();
      toast.success("Registro profissional editado com sucesso!");
      refetchPractitionerRole();
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function asyncCall() {
      setLoading(true);
      const EspecialidadeMedicaValueSet = await GetValueSet({
        valueset: "EspecialidadeMedica",
      }).then((response) => {
        if (response?.status === HTTPResponseStatus.Success) {
          return response.data.expansion.contains;
        }
        return [];
      });

      setSpecialties(
        EspecialidadeMedicaValueSet.map((item: ISpecialty) => ({
          value: item?.code,
          label: item?.display,
        }))
      );
      setLoading(false);
    }
    asyncCall();
  }, []);

  return (
    <CommonDialog
      handleClose={handleClose}
      isOpen={isOpen}
      mobileView={isMobile}
    >
      <DialogTitle textAlign="center">
        Editar informações profissionais
      </DialogTitle>
      <DialogContent>
        <ProfessionalForm
          edit
          onNextButtonClick={handleSubmit(() => {})}
          control={control}
          specialties={specialties}
          loading={loading}
        />

        <Stack
          minWidth="100%"
          mt={3}
          direction="row"
          justifyContent={"space-between"}
          spacing={2}
        >
          <FormButtonGroup
            onGoBackButtonClick={handleClose}
            onNextButtonClick={handleSubmit(onSubmit)}
            goBackButtonText="Cancelar"
            nextButtonText="Salvar"
            loading={loading}
            disabled={!isDirty || !isValid}
          />
        </Stack>
      </DialogContent>
    </CommonDialog>
  );
}
