import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

import dayjs from "dayjs";
import { GenericTable } from "../../../../../components/GenericTable/GenericTable";
import { useDatetime } from "../../../../../utils/useDatetime";
import { PrescriptionsActions } from "./PrescriptionsActions";
import { ReactNode, SyntheticEvent, useState } from "react";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ maxHeight: "60vh", pt: 2, overflow: "auto" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export function Prescriptions({
  data,
  documentReference,
  title,
  handleOpen,
  setBinaryUrl,
  openVisualizer,
  verifyVisualizer,
}: any) {
  const { formatToDDMMYYYY } = useDatetime;
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const prescriptionData = data
    ?.filter((el: any) => el?.resource?.requester)
    ?.map((el: any, index: number) => {
      const docRef = documentReference?.find((doc: any) => {
        const findRef = doc?.resource?.basedOn?.find((basedOn: any) =>
          basedOn?.reference.includes(el.resource.id)
        );
        return findRef;
      });

      const informationSource = el?.resource?.informationSource?.find(
        (source: any) => source?.reference?.includes("Practitioner")
      )?.display;
      const auxPractitioner = docRef?.resource?.contained?.find(
        (contained: any) => contained?.resourceType === "Practitioner"
      );

      const auxSpecialty =
        docRef?.resource?.practiceSetting?.coding?.[0]?.display;

      const status =
        el?.resource?.status === "active" ? "valid" : el?.resource?.status;

      const description = el.resource?.medication?.concept?.text || "";
      const date = el?.resource?.effectiveDosePeriod?.start
        ? formatToDDMMYYYY(dayjs(el?.resource?.effectiveDosePeriod?.start))
        : "-";
      const binaryUrl = docRef?.resource?.content?.[0]?.attachment?.url || "";

      const dosage = el?.resource?.dosageInstruction?.[0];
      const auxType = dosage?.doseAndRate?.[0]?.type?.text;
      const auxQtd = dosage?.doseAndRate?.[0]?.doseQuantity;
      const auxUnit = auxQtd?.unit;
      const auxUnitValue = auxQtd?.value;

      const auxTiming = dosage?.timing?.repeat;
      const auxDuration = auxTiming?.duration;
      const auxPeriod = auxTiming?.period;

      const sign =
        el?.resource?.securityLabel?.[0]?.coding?.[0]?.code === "ProofOfOrigin";

      return {
        encounterId: el?.resource?.encounter?.reference?.split("/")?.[1],
        status,
        description,
        date,
        sign,
        dose: `${
          auxQtd?.unit && auxQtd?.value && auxType
            ? `${auxUnitValue && `${auxUnitValue}`} ${auxUnit && `${auxUnit}`}`
            : ""
        } ${
          auxPeriod
            ? `${auxPeriod && `a cada ${auxPeriod} h`} ${
                auxDuration
                  ? `durante ${auxDuration} dia(s)`
                  : "por tempo indeterminado"
              }`
            : "-"
        }`,
        specialty: auxSpecialty || "-",
        binaryUrl,
        requesterId: el?.resource?.requester?.reference,
        practitioner:
          informationSource ||
          auxPractitioner?.name?.[0]?.given?.[0] ||
          auxPractitioner?.identifier?.[0]?.value ||
          el?.resource?.requester?.reference?.display ||
          el?.resource?.requester?.reference?.split("-")?.[2],
      };
    })
    // ?.filter((row: any) => (selectedTab === 0 ? row?.binaryUrl : true))
    ?.sort((a: any, b: any) => {
      const dateA = dayjs(a.date, "DD/MM/YYYY");
      const dateB = dayjs(b.date, "DD/MM/YYYY");
      return dateB.diff(dateA);
    });

  const columns = [
    {
      column: "Medicamentos",
      rowName: "description",
    },
    { column: "Posologia", rowName: "dose" },
    { column: "Profissional", rowName: "practitioner" },
    { column: "Data", rowName: "date" },
    { column: "Status", rowName: "status" },
  ];

  const declaratedData = data
    ?.filter((el: any) => !el?.resource?.requester)
    ?.map((el: any, index: number) => {
      const docRef = documentReference?.find((doc: any) => {
        const findRef = doc?.resource?.basedOn?.find((basedOn: any) =>
          basedOn?.reference.includes(el.resource.id)
        );
        return findRef;
      });

      const informationSource = el?.resource?.informationSource?.find(
        (source: any) => source?.reference?.includes("Practitioner")
      )?.display;
      const auxPractitioner = docRef?.resource?.contained?.find(
        (contained: any) => contained?.resourceType === "Practitioner"
      );

      const auxSpecialty =
        docRef?.resource?.practiceSetting?.coding?.[0]?.display;

      const status =
        el?.resource?.status === "active" ? "valid" : el?.resource?.status;

      const description = el.resource?.medication?.concept?.text || "";
      const date = el?.resource?.effectiveDosePeriod?.start
        ? formatToDDMMYYYY(dayjs(el?.resource?.effectiveDosePeriod?.start))
        : "-";
      const binaryUrl = docRef?.resource?.content?.[0]?.attachment?.url || "";

      const dosage = el?.resource?.dosageInstruction?.[0];
      const auxType = dosage?.doseAndRate?.[0]?.type?.text;
      const auxQtd = dosage?.doseAndRate?.[0]?.doseQuantity;
      const auxUnit = auxQtd?.unit;
      const auxUnitValue = auxQtd?.value;

      const auxTiming = dosage?.timing?.repeat;
      const auxDuration = auxTiming?.duration;
      const auxPeriod = auxTiming?.period;

      return {
        status,
        description,
        date,
        dose: `${
          auxQtd?.unit && auxQtd?.value && auxType
            ? `${auxUnitValue && `${auxUnitValue}`} ${auxUnit && `${auxUnit}`}`
            : ""
        } ${
          auxPeriod
            ? `${auxPeriod && `a cada ${auxPeriod} h`} ${
                auxDuration
                  ? `durante ${auxDuration} dia(s)`
                  : "por tempo indeterminado"
              }`
            : "-"
        }`,
        specialty: auxSpecialty || "-",
        binaryUrl,
        requesterId: el?.resource?.requester?.reference,
        practitioner:
          informationSource ||
          auxPractitioner?.name?.[0]?.given?.[0] ||
          auxPractitioner?.identifier?.[0]?.value ||
          el?.resource?.requester?.reference?.display ||
          el?.resource?.requester?.reference?.split("-")?.[2],
      };
    })
    ?.sort((a: any, b: any) => {
      const dateA = dayjs(a.date, "DD/MM/YYYY");
      const dateB = dayjs(b.date, "DD/MM/YYYY");
      return dateB.diff(dateA);
    });

  return (
    <Accordion sx={{ width: "100%" }} elevation={5}>
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Stack direction="row" alignItems="center" gap={1}>
          {title}
          <Avatar
            sx={{
              width: 26,
              height: 26,
              fontSize: "14px",
              fontWeight: 600,
              backgroundColor: "primary100.main",
              color: "primary1000.main",
            }}
          >
            {data?.length || "0"}
          </Avatar>
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
          >
            <Tab
              label={
                <Box style={{ display: "flex", alignItems: "center" }}>
                  Prescritos
                  <Avatar
                    sx={{
                      width: 26,
                      marginLeft: 1,
                      height: 26,
                      fontSize: "14px",
                      fontWeight: 600,
                      backgroundColor: "primary100.main",
                      color: "primary1000.main",
                    }}
                  >
                    {prescriptionData?.length || "0"}
                  </Avatar>
                </Box>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Box style={{ display: "flex", alignItems: "center" }}>
                  Declarados pelo paciente
                  <Avatar
                    sx={{
                      width: 26,
                      marginLeft: 1,
                      height: 26,
                      fontSize: "14px",
                      fontWeight: 600,
                      backgroundColor: "primary100.main",
                      color: "primary1000.main",
                    }}
                  >
                    {declaratedData?.length || "0"}
                  </Avatar>
                </Box>
              }
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>

        <CustomTabPanel value={selectedTab} index={0}>
          <GenericTable
            tableColumns={columns}
            tableRows={prescriptionData}
            actions={(rowData: any) => (
              <PrescriptionsActions
                verifyVisualizer={verifyVisualizer}
                openVisualizer={openVisualizer}
                row={rowData}
                handleOpen={handleOpen}
                setBinaryUrl={setBinaryUrl}
              />
            )}
          />
        </CustomTabPanel>

        <CustomTabPanel value={selectedTab} index={1}>
          <GenericTable
            tableColumns={columns?.filter(
              (e: any) => e?.rowName !== "practitioner"
            )}
            tableRows={declaratedData}
          />
        </CustomTabPanel>
      </AccordionDetails>
    </Accordion>
  );
}
