import {
  CircularProgress,
  IconButton,
  TableCell,
  Tooltip,
} from "@mui/material";
import {
  ContentPaste,
  Autorenew,
  Description,
  Edit,
  FileDownload,
} from "@mui/icons-material";
import { isAndroid, isMobile, isMobileSafari } from "react-device-detect";
import { GetBinary } from "../../../../../services/fhir/patientsummary/Binary/GetBinary";
import { usePractitionerRoleContext } from "../../../../../contexts/PractitionerRoleContext";
import { PostDocumentReference } from "../../../../../services/fhir/patientsummary/documentreference/PostDocumentReference";
import { useSessionContext } from "../../../../../contexts/SessionContext";
import { SignatureDialog } from "../../../../medicaldocumentation/components/prescription/SignatureDialog";
import { useState } from "react";
import { DocumentVisualization } from "../../../../medicaldocumentation/components/DocumentVisualization";
import { GetMedication } from "../../../../../services/terminology";
import { useInterventionsContext } from "../../../../../contexts/InterventionsContext";
import { GetAppointment, GetPatient } from "../../../../../services/fhir";
import { useFhirData } from "../../../../../utils/useFhirData";
import { useLocationContext } from "../../../../../contexts/LocationContext";
import { Base64SignPDF } from "../../../../../services/dsig";
import { toast } from "react-toastify";

const tags = [
  {
    name: "VERMELHA",
    value: "CE",
    color: "#db3f30",
    display: "Controle especial - Tarja vermelha",
  },
  {
    name: "PRETA",
    value: "CE",
    color: "white",
    background: "black",
    display: "Controle especial - Tarja preta",
  },
  {
    name: "AMARELA",
    value: "AM",
    color: "#FEE2A9",
    display: "Receita amarela",
  },
  {
    name: "AZUL",
    value: "AZ",
    color: "#A4CBE8",
    display: "Receita azul",
  },
  {
    name: "SEM TARJA",
    value: "BR",
    color: "white",
    display: "Receita branca",
  },
  {
    name: "EX",
    display: "Exame",
    value: "EX",
    color: "#FDCF72",
  },
  {
    name: "AT",
    value: "AT",
    color: "#009E8C",
    display: "Atestado",
  },
];

export function PrescriptionsActions({
  row,
  handleOpen,
  setBinaryUrl,
  setRefetch,
  openVisualizer,
  verifyVisualizer,
}: any) {
  const { access, user } = useSessionContext();
  const { userPractitionerRole } = usePractitionerRoleContext();
  const { location } = useLocationContext();

  const [patient, setPatient] = useState<any>();
  const [locationData, setLocationData] = useState<any>();

  const { action, selectedInterventions, setInterventionsList } =
    useInterventionsContext();

  const [originalDocument, setOriginalDocument] = useState<any>();
  const [base64, setBase64] = useState<any>();

  const [signatureDialogOpen, setSignatureDialogOpen] =
    useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [item, setItem] = useState<any>([]);

  const auxOrganization = row?.fullResource?.author
    ?.find((el: any) => el?.reference?.includes("Organization"))
    ?.reference?.split("/")?.[1];

  const auxPractitioner = row?.fullResource?.author
    ?.find((el: any) => el?.reference?.includes("Practitioner"))
    ?.reference?.split("/")?.[1];

  const auxReplaces = `identifier=${row?.fullResource?.identifier?.[1]?.system}%7C${row?.fullResource?.identifier?.[1]?.value}`;

  const replaces = row?.fullResource?.id;

  const objData = {
    organization: {
      id: auxOrganization,
      alias: row?.fullResource?.contained?.[1]?.alias?.[0],
      name: row?.fullResource?.contained?.[1]?.name,
    },
    practitioner: {
      id: auxPractitioner,
      name: row?.fullResource?.contained?.[0]?.name?.[0]?.given?.[0],
    },
    servicePratice: row?.fullResource?.practiceSetting?.coding?.[0],
    time: {
      start: row?.fullResource?.period?.start,
      end: row?.fullResource?.period?.end,
    },
    relatesTo: auxReplaces,
    codeValue: row?.fullResource?.type?.coding?.[0]?.code,
    codeDisplay: row?.fullResource?.type?.coding?.[0]?.display,
    categoryValue: row?.fullResource?.category?.[0]?.coding?.[0]?.code,
    categoryDisplay: row?.fullResource?.category?.[0]?.coding?.[0]?.display,
  };

  const basedOn = row?.fullResource?.basedOn
    ?.map(
      (el: any) =>
        `<fhir:basedOn>
    <fhir:reference value="${el?.reference}"/>
    </fhir:basedOn>
    `
    )
    ?.join("");

  const encounter = {
    id: row?.fullResource?.context
      ?.find((el: any) => el?.reference?.includes("Encounter"))
      ?.reference?.split("/")?.[1],

    appointmentId: row?.fullResource?.context
      ?.find((el: any) => el?.reference?.includes("Appointment"))
      ?.reference?.split("/")?.[1],
    requester: {
      reference: `PractitionerRole/${userPractitionerRole?.id}`,
      display: row?.fullResource?.contained?.[0]?.name?.[0]?.given?.[0],
    },
    subject: row?.fullResource?.subject?.reference?.split("-")?.[1],
  };

  const base64ToBlob = (base64: any, contentType = "") => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  async function downloadBase64File() {
    try {
      const response = await GetBinary({
        urlBinary: row?.binaryUrl,
      });
      const blob = base64ToBlob(response?.data, response?.contentType);
      const fileUrl = URL.createObjectURL(blob);
      const auxFile = {
        fileUrl,
        fileName: "Receita.pdf",
      };

      if (isMobileSafari || !isAndroid) {
        if (auxFile) {
          const link = document.createElement("a"); // Cria um elemento <a>
          link.href = auxFile?.fileUrl; // Define o URL do Blob
          link.download = auxFile?.fileName; // Define o nome do arquivo
          // document.body.appendChild(link); // Adiciona o link ao documento
          link.click(); // Simula o clique no link
          // document.body.removeChild(link); // Remove o link do documento
          // URL.revokeObjectURL(auxFile?.fileUrl); // Libera a memória do URL Blob
        } else {
          alert(
            "O arquivo ainda está sendo preparado, por favor tente novamente."
          );
        }
      } else {
        window.open(auxFile.fileUrl, "_blank");
        // setDataRow(e.row);
        // setOpenInformation(true);
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  const handleSignature = async (
    provider: "vidaas" | "safeid",
    password?: string,
    lifeTime?: number
  ) => {
    try {
      setLoading(true);
      toast.warn("Aguardando verificação e segurança do seu provedor!");
      const signResponse = await Base64SignPDF({
        provider: provider,
        ...(provider === "safeid" && {
          userPassword: password,
        }),
        pdfBase64: base64
          ? base64?.[0]?.[`special-0`]?.base64?.split(",")?.[1] ||
            base64?.[0]?.simple?.base64?.split(",")?.[1]
          : originalDocument,
        cpfCnpj: user?.username,
        lifeTime: lifeTime ?? 0,
      });

      if (!signResponse?.signedPdfBase64) throw new Error("");
      const auxUuid =
        base64?.[0]?.[`special-0`]?.uuid || base64?.[0]?.simple?.uuid;

      await PostDocumentReference(
        {
          ...objData,
          base64: signResponse?.signedPdfBase64,
        },
        encounter,
        basedOn,
        true,
        auxUuid
      );
      toast.success("Assinatura realizada!");
    } catch (error) {
      toast.warn(
        "Não foi possível realizar a assinatura, por favor tente novamente!"
      );

      console.error("HandleSignature error:", error);
    } finally {
      setLoading(false);
      setRefetch((prev: boolean) => !prev);
    }
  };

  async function generateItem() {
    const patient = await GetPatient({ identifier: encounter?.subject });
    setPatient(patient?.data);

    const appointment = await GetAppointment({
      ehrrunner: encounter?.subject,
      _id: encounter?.appointmentId,
    });

    const locationReference = useFhirData.findParticipant(
      appointment?.entry?.[0]?.resource?.participant,
      "Location"
    );

    const selectedLocation = location?.find((el: any) =>
      el?.resource?.id?.includes(locationReference?.reference.split("/")[1])
    )?.resource;

    setLocationData(selectedLocation);

    const medicamentos = await row?.fullMedicationResource?.map(
      async (e: any) => {
        const response =
          e.resource?.medication?.concept?.coding &&
          (await GetMedication(
            undefined,
            undefined,
            e.resource?.medication?.concept?.coding?.[0]?.code
          ));

        const auxTagValue =
          response?.data?.entry?.[0]?.resource?.extension?.find(
            (ext: any) =>
              ext.url ===
              "https://fluxmed.com.br/fhir/StructureDefinition/CMEDTarja"
          )?.valueString;

        const auxTag = tags.find((tag: any) =>
          auxTagValue?.toUpperCase().includes(tag.name)
        )?.name;

        const auxPresentation =
          response?.data?.entry?.[0]?.resource?.extension?.find(
            (ext: any) =>
              ext.url ===
              "https://fluxmed.com.br/fhir/StructureDefinition/CMEDApresentacao"
          )?.valueString;

        const auxForm = [
          e.resource?.dosageInstruction?.[0]?.doseAndRate?.[0]?.doseQuantity
            ?.value &&
            `${row?.fullMedicationResource?.[0]?.resource?.dosageInstruction?.[0]?.doseAndRate?.[0]?.doseQuantity?.value}`,
          e.resource?.dosageInstruction?.[0].doseAndRate?.[0]?.type?.text &&
            `${row?.fullMedicationResource?.[0]?.resource?.dosageInstruction?.[0].doseAndRate?.[0]?.type?.text}`,
          e.resource?.dosageInstruction?.[0]?.timing?.repeat?.frequency &&
            `a cada ${row?.fullMedicationResource?.[0]?.resource?.dosageInstruction?.[0]?.timing?.repeat?.frequency} h`,
          e.resource?.dosageInstruction?.[0]?.timing?.repeat?.period &&
            `por ${row?.fullMedicationResource?.[0]?.resource?.dosageInstruction?.[0]?.timing?.repeat?.period} dias`,
        ]
          ?.filter((e: string) => e)
          ?.join(" ");

        const auxItem = {
          name: e.resource?.medication?.concept?.text,
          label: `${e.resource?.medication?.concept.text} ${
            auxPresentation ? `- ${auxPresentation}` : ""
          }`,

          inputValue: {
            ...(row?.fullMedicationResource?.[0]?.medication?.concept
              ?.coding?.[0]?.display && {
              code: {
                coding: [
                  {
                    code: e.resource?.medication?.concept?.coding?.[0]?.code,
                    display:
                      e.resource?.medication?.concept?.coding?.[0]?.display,
                    system: "https://fluxmed.com.br/fhir/CodeSystem/CMED",
                  },
                ],
              },
              tag: auxTag || "Branca",
            }),
            presentation: auxPresentation,
            name: e.resource?.medication?.concept?.text,
            label: `${e.resource?.medication?.concept.text} ${
              auxPresentation ? `- ${auxPresentation}` : ""
            }`,
          },
          refact: true,
          tag: auxTag || "Branca",
          ...(row?.fullMedicationResource?.[0]?.resource?.dosageInstruction?.[0]
            ?.doseAndRate?.[0]?.doseQuantity?.value && {
            quantity: {
              label:
                e.resource?.dosageInstruction?.[0]?.doseAndRate?.[0]
                  ?.doseQuantity?.value,
              value:
                e.resource?.dosageInstruction?.[0]?.doseAndRate?.[0]
                  ?.doseQuantity?.value,
            },
          }),
          ...(row?.fullMedicationResource?.[0]?.resource?.dosageInstruction?.[0]
            ?.timing?.repeat?.frequency && {
            frequency: {
              label: `${row?.fullMedicationResource?.[0]?.resource?.dosageInstruction?.[0]?.timing?.repeat?.frequency} h`,
            },
          }),
          formValue: {
            label:
              e.resource?.dosageInstruction?.[0].doseAndRate?.[0]?.type?.text,
          },
          ...(row?.fullMedicationResource?.[0]?.resource?.dosageInstruction?.[0]
            ?.timing?.repeat?.period && {
            time: {
              label: `${row?.fullMedicationResource?.[0]?.resource?.dosageInstruction?.[0]?.timing?.repeat?.period} dias`,
            },
          }),
          note: e.resource?.note?.[0]?.text || "",
          form: auxForm,
          type: "medication",
          tabValue: "1",
        };

        return auxItem;
      }
    );

    const results = await Promise.all(medicamentos);

    setItem(results);
    results?.map((e: any) => action(e));
    setLoading(false);
  }

  return (
    <TableCell align="center" sx={{ minWidth: "120px" }}>
      {row?.binaryUrl && (
        <>
          {!isMobile && (
            <Tooltip title="Visualizar receita">
              <IconButton
                onClick={() => {
                  setBinaryUrl(row?.binaryUrl);
                  handleOpen();
                }}
              >
                <Description />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Baixar">
            <IconButton
              onClick={() => {
                downloadBase64File();
              }}
            >
              <FileDownload />
            </IconButton>
          </Tooltip>

          {verifyVisualizer &&
            verifyVisualizer(row?.encounterId) &&
            access?.type === "professional" && (
              <Tooltip title="Visualizar atendimento">
                <IconButton
                  onClick={() => {
                    openVisualizer(row?.encounterId);
                  }}
                >
                  <ContentPaste />
                </IconButton>
              </Tooltip>
            )}

          {access?.type === "professional" &&
            userPractitionerRole?.id ===
              row?.fullMedicationResource?.[0]?.resource?.requester?.reference?.split(
                "/"
              )?.[1] && (
              <Tooltip
                title={!row?.sign ? "Assinar documento" : "Reemitir documento"}
              >
                <IconButton
                  onClick={async () => {
                    setLoading(true);
                    const response = await GetBinary({
                      urlBinary: row?.binaryUrl,
                    });
                    setOriginalDocument(response?.data);
                    await generateItem();
                    setSignatureDialogOpen(true);
                  }}
                >
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : !row?.sign ? (
                    <Edit />
                  ) : (
                    <Autorenew />
                  )}
                </IconButton>
              </Tooltip>
            )}
        </>
      )}

      {item?.length && !loading && patient ? (
        <>
          <div style={{ display: "none" }}>
            <DocumentVisualization
              refact
              setBase64={setBase64}
              dataPdf={[
                {
                  patientCPF: patient?.id?.split("-")?.[1],
                  patientData: patient?.name?.[0]?.text || "",

                  professionalData: userPractitionerRole,
                  location: locationData,
                },
              ]}
            />
          </div>
        </>
      ) : (
        <></>
      )}

      {signatureDialogOpen && originalDocument ? (
        <SignatureDialog
          visualize={
            base64 && item
              ? base64?.[0]?.[`special-0`]?.base64?.split(",")?.[1] ||
                base64?.[0]?.simple?.base64?.split(",")?.[1]
              : originalDocument
          }
          isOpen={signatureDialogOpen}
          handleClose={() => {
            setSignatureDialogOpen(false);
            const indexes = item?.map((e: any) =>
              selectedInterventions?.all.indexOf(e)
            );

            setInterventionsList(
              selectedInterventions?.all?.filter(
                (e: any, index: number) => !indexes?.includes(index)
              )
            );

            setItem([]);
          }}
          handleSignature={handleSignature}
          row={{ sign: "Pendente" }}
          loading={loading}
        />
      ) : (
        <></>
      )}
    </TableCell>
  );
}
