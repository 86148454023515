import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Popover,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSignatureControlContext } from "../../../../contexts/SignatureControlContext";
import { Close, Security, VerifiedUser } from "@mui/icons-material";
import { NoDataWarning } from "../../../common";

interface StatusButtonProps {
  isActive: boolean;
}

export function SignatureControls() {
  const { sessionInfo, loading, error, handleResetSession } =
    useSignatureControlContext();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [resetting, setResetting] = useState<"vidaas" | "safeid" | null>(null);
  const [vidaasCountdown, setVidaasCountdown] = useState("");
  const [safeidCountdown, setSafeidCountdown] = useState("");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = useCallback(
    async (provider: "vidaas" | "safeid") => {
      setResetting(provider);
      await handleResetSession(provider);
      setResetting(null);
      handleClose();
    },
    [handleResetSession]
  );

  const open = Boolean(anchorEl);
  const id = open ? "dsig-session-popover" : undefined;

  const calculateCountdown = (expiresAt: string) => {
    const expirationDate = new Date(expiresAt);
    const now = new Date();
    const timeDiff = expirationDate.getTime() - now.getTime();

    if (timeDiff <= 0) return "Sessão expirada";

    const secondsRemaining = Math.floor(timeDiff / 1000);
    const h = Math.floor(secondsRemaining / 3600);
    const m = Math.floor((secondsRemaining % 3600) / 60);
    const s = Math.floor(secondsRemaining % 60);

    const hDisplay = h > 0 ? `${h}h ` : "";
    const mDisplay = m > 0 ? `${m}m ` : "";
    const sDisplay = s > 0 ? `${s}s` : "";

    return `${hDisplay}${mDisplay}${sDisplay}`.trim();
  };

  useEffect(() => {
    const vidaasInterval = setInterval(() => {
      if (sessionInfo?.vidaasExpiresAt) {
        setVidaasCountdown(calculateCountdown(sessionInfo.vidaasExpiresAt));
      }
    }, 1000);

    const safeidInterval = setInterval(() => {
      if (sessionInfo?.safeidExpiresAt) {
        setSafeidCountdown(calculateCountdown(sessionInfo.safeidExpiresAt));
      }
    }, 1000);

    return () => {
      clearInterval(vidaasInterval);
      clearInterval(safeidInterval);
    };
  }, [sessionInfo?.vidaasExpiresAt, sessionInfo?.safeidExpiresAt]);

  const isSessionActive = Boolean(
    sessionInfo && (sessionInfo.vidaasExpiresAt || sessionInfo.safeidExpiresAt)
  );

  useEffect(() => {
    async function cleanSession() {
      localStorage?.setItem("cleanSignature", "true");
      if (sessionInfo?.vidaasExpiresAt?.length)
        await handleResetSession("vidaas");
      if (sessionInfo?.safeidExpiresAt?.length)
        await handleResetSession("safeid");
    }
    if (!localStorage.getItem("cleanSignature") && sessionInfo) cleanSession();
  }, []);
  if (loading) {
    return <CircularProgress size={24} />;
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClick}
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: 2,
          border: `1px solid #009E8C`,
          color: "#009E8C",
          ":hover": {
            backgroundColor: "#FFFFFF",
            color: "#007C6D",
          },
          ":disabled": {
            border: `1px solid`,
            borderColor: "#E1E6EF",
            color: "#5F6C85",
            backgroundColor: "#E1E6EF",
          },
        }}
      >
        <Stack sx={{ alignItems: "center" }} direction="row" spacing={1}>
          <Stack>{isSessionActive ? <VerifiedUser /> : <Security />}</Stack>
          <Stack>Assinatura digital</Stack>
        </Stack>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Stack spacing={2} p={2} sx={{ minWidth: "350px" }}>
          {(!sessionInfo?.vidaasExpiresAt ||
            vidaasCountdown === "Sessão expirada") &&
          (!sessionInfo?.safeidExpiresAt ||
            safeidCountdown === "Sessão expirada") ? (
            <Stack>
              <NoDataWarning
                message="Você não possui tokens de assinatura ativos no momento!"
                justifyX
              />
            </Stack>
          ) : (
            <>
              <Typography variant="h6" gutterBottom>
                Tokens de assinatura
              </Typography>
              {sessionInfo?.vidaasExpiresAt &&
                vidaasCountdown !== "Sessão expirada" && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle2">VIDaaS: </Typography>
                    <Typography variant="subtitle2">
                      {vidaasCountdown}
                    </Typography>
                    <IconButton onClick={() => handleReset("vidaas")}>
                      <Tooltip title="Finalizar sessão">
                        <Close sx={{ color: "red" }} />
                      </Tooltip>
                    </IconButton>
                  </Stack>
                )}

              {sessionInfo?.safeidExpiresAt &&
                safeidCountdown !== "Sessão expirada" && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle2">SafeID: </Typography>
                    <Typography variant="subtitle2">
                      {safeidCountdown}
                    </Typography>
                    <IconButton onClick={() => handleReset("safeid")}>
                      <Tooltip title="Finalizar sessão">
                        <Close sx={{ color: "red" }} />
                      </Tooltip>
                    </IconButton>
                  </Stack>
                )}
            </>
          )}
        </Stack>
      </Popover>
    </>
  );
}
