import { DialogContent, Stack, Typography } from "@mui/material";
import { FormButtonGroup } from "../../../../components/form";

interface IProps {
  onCancelButtonClick: Function;
  onSendButtonClick: Function;
  loading: boolean;
}

export default function InfoContent({
  onCancelButtonClick,
  onSendButtonClick,
  loading,
}: IProps) {
  return (
    <DialogContent>
      <Typography>
        Para editar a senha, é preciso confirmar sua identidade por meio de um
        código de verificação que será recebeido pelo seu telefone e, após isso,
        será enviado para o seu e-mail um link para edição da senha
      </Typography>
      <Stack
        minWidth="100%"
        mt={3}
        direction="row"
        justifyContent="space-between"
        spacing={2}
      >
        <FormButtonGroup
          onGoBackButtonClick={() => onCancelButtonClick()}
          onNextButtonClick={() => onSendButtonClick()}
          goBackButtonText="Cancelar"
          nextButtonText="Enviar"
          loading={loading}
        />
      </Stack>
    </DialogContent>
  );
}
