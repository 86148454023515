import { endpoints } from "../../../configs/Settings";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";
import { auxiliarData } from "../AuxiliarData";
import { HTTPResponseStatus } from "../../../utils/enum";
import * as uuid from "uuid";
import { toast } from "react-toastify";

export async function PostHealthcareService(objData: any, access: any) {
  const uuidValue = uuid.v1();
  const url = `${endpoints.WORKFLOWFHIR}/HealthcareService/${uuidValue}`;

  const data = auxiliarData.HealthcareService(objData, access, uuidValue);
  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
  };
  const response = await useAxios
    .put(url, data, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Created) {
        throw new Error(`PutHealthcareService: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("PostHealthcareService ", error);
      // toast.error(`PostHealthcareService Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
