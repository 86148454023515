import { toast } from "react-toastify";
import { endpoints, oids } from "../../../../configs/Settings";
import { HTTPResponseStatus } from "../../../../utils/enum";
import { useAuth } from "../../../../utils/useAuth";
import { useAxios } from "../../../../utils/useAxios";

interface formatFields {
  patient: number;
  questionnaire?: string;
}

export async function GetQuestionnaireResponse({
  patient,
  questionnaire,
}: formatFields) {
  const url = `${endpoints.MHD}/QuestionnaireResponse?subject=Patient/${oids.cpf}-${patient}&questionnaire=${questionnaire}&_sort=-_lastUpdated&_count=250`;
  const subjectId = await useAuth.getSubjectId();
  const organizationId = await useAuth.getOrganizationId();
  const headers = {
    Accept: "application/fhir+json",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
    ...(subjectId && { "subject-id": subjectId }),
    ...(organizationId && { "organization-id": organizationId }),
  };
  const response = await useAxios
    .get(url, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`Get QuestionnaireResponse: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("GetQuestionnaire ", error);
      // toast.error(`GetQuestionnaire Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}

export async function GetQuestionnairesResponse({ patient }: { patient: any }) {
  const url = `${endpoints.MHD}/QuestionnaireResponse?subject=Patient/${oids.cpf}-${patient}`;
  const subjectId = await useAuth.getSubjectId();
  const organizationId = await useAuth.getOrganizationId();
  const headers = {
    Accept: "application/fhir+json",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
    // "x-user-token": await useAuth.getXUserToken(),
    // "organization-id": "05490544000100",
    ...(subjectId && { "subject-id": subjectId }),
    ...(organizationId && { "organization-id": organizationId }),
  };
  const response = await useAxios
    .get(url, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`Get QuestionnaireResponse: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("GetQuestionnaire ", error);
      // toast.error(`GetQuestionnaire Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}

export async function GetQuestionnaireResponsesByPractitioner({
  practitioner,
}: any) {
  const url = `${endpoints.MHD}/QuestionnaireResponse?author=Practitioner/${oids.cpf}-${practitioner}&subject:Patient.identifier=urn:oid:${oids.cpf}%7C${practitioner}`;
  const subjectId = await useAuth.getSubjectId();
  const organizationId = await useAuth.getOrganizationId();
  const headers = {
    Accept: "application/fhir+json",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
    // "x-user-token": await useAuth.getXUserToken(),
    // "organization-id": "05490544000100",
    ...(subjectId && { "subject-id": subjectId }),
    ...(organizationId && { "organization-id": organizationId }),
  };
  const response = await useAxios
    .get(url, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`Get QuestionnaireResponse: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("GetQuestionnaireResponsesByPractitioner ", error);
      // toast.error(
      //   `GetQuestionnaireResponsesByPractitioner Error: ${error?.response?.status}`
      // );
      throw new Error();
    });
  if (response) return response;
}
