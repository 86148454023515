/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";

import dayjs from "dayjs";
import { Box, Stack, Typography } from "@mui/material";

import {
  FormAutoComplete,
  FormDatePicker,
  FormRadioButton,
  FormSelectInput,
  FormTextInput,
  FormTimeInput,
} from "../../../components/form";
import { RequiredField } from "./RequiredField";
import { SubmitActions } from "./SubmitActions";
import { useFormatter } from "../../../utils/useFormatter";
import { useSessionContext } from "../../../contexts/SessionContext";
import { usePractitionerRoleContext } from "../../../contexts/PractitionerRoleContext";

export function ScheduleSettings({
  onNext,
  defaultValues,
  setCurrentStep,
  healthcareInfo,
}: any) {
  const { practitionerRole } = usePractitionerRoleContext();
  const { access } = useSessionContext();

  const [timeSchedule, setTimeSchedule] = useState(0);

  const { control, handleSubmit, getValues, setValue } = useForm({
    defaultValues,
  });
  let watchdatastart = useWatch({ name: "dataStart", control });

  let watchDurationTime = useWatch({ name: "durationTimeDefault", control });

  let watchdataend = useWatch({ name: "dataEnd", control });

  const optionsDurationDefault = [
    { display: "15 Minutos", value: "00:15" },
    { display: "30 Minutos", value: "00:30" },
    { display: "1 Hora", value: "01:00" },
    { display: "Personalizar", value: "personDuration" },
  ];

  const daysWarningSchedule = [
    { display: "Agenda completa", value: timeSchedule },
    { display: "1 semana", value: 7 },
    { display: "2 semanas", value: 14 },
    { display: "3 semanas", value: 21 },
    { display: "1 mês", value: 30 },
    { display: "2 meses", value: 60 },
    { display: "3 meses", value: 90 },
    { display: "6 meses", value: 180 },
  ].filter((element) => element.value <= timeSchedule);

  const practitionerOptions =
    practitionerRole
      ?.filter((element: fhir5.BundleEntry<fhir5.PractitionerRole>) => {
        const auxSpecialty = element.resource?.specialty?.map(
          (e: fhir5.CodeableConcept) => e.coding?.[0]?.display
        );
        return (
          element?.resource?.practitioner?.display &&
          auxSpecialty?.includes(healthcareInfo)
        );
      })
      ?.map((el: fhir5.BundleEntry<fhir5.PractitionerRole>) => {
        return {
          label: useFormatter.formatNameDisplay(
            el.resource?.practitioner?.display || ""
          ),
          value: el.resource?.practitioner?.reference || "",
        };
      }) || [];

  const isDateInFuture = (date: any) => {
    if (!date) {
      return false;
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date > today;
  };

  const onSubmit = async (data: any) => {
    await onNext({ schedule: data }); // Envia os dados do local para o controle pai
    setCurrentStep((prev: number) => prev + 1);
  };

  useEffect(() => {
    if (watchdatastart === null || watchdataend === null) {
      setTimeSchedule(0);
      return;
    }

    const initialDate = new Date(watchdatastart);
    const endDate = new Date(watchdataend);
    const difference_in_time = initialDate.getTime() - endDate.getTime();
    const days =
      parseInt(Math.abs(difference_in_time / (1000 * 3600 * 24)).toFixed(0)) +
      1;

    setTimeSchedule(days);
  }, [watchdatastart, watchdataend]);

  useEffect(() => {
    if (getValues("dataEnd") < getValues("dataStart"))
      setValue("dataEnd", null);
  }, [getValues("dataStart")]);

  return (
    <Stack width="100%" spacing={4}>
      <Typography
        variant="h6"
        fontWeight="600"
        textAlign="center"
        p="40px 0px 24px 0px"
        color="neutral700.main"
      >
        Adicione as configurações e horários que deseja para esta agenda.
      </Typography>
      <Stack spacing={1}>
        <Typography variant="h6" fontWeight="600" color="neutral600.main">
          Nome da agenda <RequiredField />
        </Typography>
        <Typography variant="body1" paddingBottom={2}>
          Escolha um nome para esta agenda.
        </Typography>

        <FormTextInput
          name="scheduleName"
          placeholder="Ex: Sala 01, Consultório 01"
          label="Nome"
          control={control}
          autoFocus
          required
        />
      </Stack>
      <Stack spacing={1}>
        <Typography
          variant="h6"
          fontWeight="600"
          mb={1}
          color="neutral600.main"
        >
          Duração da agenda <RequiredField />
        </Typography>
        <Typography variant="body2" paddingBottom={2}>
          Intervalo de tempo de existência desta agenda.
        </Typography>

        <Stack direction="row" width="100%" spacing={2}>
          <FormDatePicker
            name="dataStart"
            label="Início da agenda"
            control={control}
            minDate={dayjs()}
            required
          />

          <FormDatePicker
            name="dataEnd"
            label="Fim da agenda"
            control={control}
            required
            minDate={useWatch({ name: "dataStart", control })}
            validate={isDateInFuture}
            readonly={isNaN(watchdatastart) || watchdatastart === null}
          />
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Typography
          variant="h6"
          fontWeight="600"
          mb={1}
          color="neutral600.main"
        >
          Duração padrão de cada consulta
        </Typography>
        <Typography variant="body2" paddingBottom={2}>
          Intervalo de tempo disponível para cada agendamento de consulta.
        </Typography>
        <Box width="50%">
          <FormRadioButton
            name="durationTimeDefault"
            label=""
            defaultValue="00:30"
            control={control}
            rowOrientation={true}
            selectOptions={optionsDurationDefault}
          />
          {watchDurationTime === "personDuration" && (
            <FormTimeInput
              name="personDurationTime"
              label=""
              control={control}
              defaultValue={dayjs("2000-01-01T01:30").format("HH:mm")}
              validate={(e: any) => !isNaN(e)}
              required
            />
          )}
        </Box>
      </Stack>
      <Stack spacing={1}>
        <Typography variant="h6" fontWeight="600" color="neutral600.main">
          Antecedência de liberação de vagas
        </Typography>
        <Typography variant="body2" paddingBottom={2}>
          Antecedência em que as vagas para agendamento de consultas serão
          liberadas para os pacientes.
        </Typography>
        <FormSelectInput
          name="advanceWarning"
          label="Período em que as vagas devem ser liberadas"
          control={control}
          defaultValue={timeSchedule}
          selectOptions={daysWarningSchedule}
        />
      </Stack>

      {access?.roles?.includes("manager") && (
        <Stack spacing={1}>
          <Typography variant="h6" fontWeight="600" color="neutral600.main">
            Profissionais associados a essa agenda
          </Typography>
          <FormAutoComplete
            name="professionalActor"
            label="Digite o nome, e-mail ou CPF do profissional"
            control={control}
            selectOptions={practitionerOptions}
          />
        </Stack>
      )}

      <SubmitActions
        goBack={() => {
          onNext();
          setCurrentStep((prev: number) => prev - 1);
        }}
        goForward={handleSubmit(onSubmit)}
      />
    </Stack>
  );
}
