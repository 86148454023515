import { WhatsApp } from "@mui/icons-material";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useFormatter } from "../../../../../utils/useFormatter";
import { GenericTable } from "../../../../../components/GenericTable/GenericTable";
import { PatientSearchActions } from "../../../../patientsearch/components/PatientSearchActions";
import {
  CircularLoading,
  NoDataWarning,
} from "../../../../../components/common";
import { useEffect, useState } from "react";
import { GetAppointment } from "../../../../../services/fhir";
import { oids } from "../../../../../configs/Settings";
import { useSessionContext } from "../../../../../contexts/SessionContext";
import { useLocationContext } from "../../../../../contexts/LocationContext";
import { useViewContext } from "../../../../../contexts/ViewContext";

export function LastAppointmentsTable() {
  const { user } = useSessionContext();
  const { viewType } = useViewContext();
  const { location } = useLocationContext();
  const locationId = location
    ?.map(
      (element: fhir5.BundleEntry<fhir5.Location>) =>
        `Location/${element.resource?.id}`
    )
    ?.join(",");

  const [lastPatients, setLastPatients] =
    useState<fhir5.BundleEntry<fhir5.Patient>[]>();

  const [loading, setLoading] = useState<boolean>(false);

  const columns = [
    { column: "Nome", rowName: "name" },
    { column: "CPF", rowName: "identifier" },
    { column: "Data de nascimento", rowName: "birthDate" },
    { column: "Sexo de nascimento", rowName: "gender" },
    { column: "Telefone", rowName: "contact" },
  ];

  const rows = lastPatients?.map((el: any) => {
    const identifier = useFormatter.formatCPF(el.resource?.id.split("-")[1]);
    const name =
      useFormatter.formatNameDisplay(el.resource?.name?.[0]?.text) || "-";
    const birthDate =
      el.resource?.birthDate &&
      `${useFormatter.formatDate(
        el.resource?.birthDate
      )} - ${useFormatter.formatAge(el.resource?.birthDate)} anos`;
    const gender = useFormatter.formatGender(el.resource?.gender) || "-";

    const contact = el?.resource?.telecom;

    const phone = contact?.find((e: any) => e.system === "phone")?.value;

    return {
      identifier,
      name,
      birthDate,
      ...(phone && {
        contact: (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
          >
            <Typography mt={0.5}>
              {useFormatter.formatPhoneNumber(phone || "")}
            </Typography>
            <Tooltip title={useFormatter.formatPhoneNumber(phone || "")}>
              <IconButton
                color="primary"
                onClick={() =>
                  window.open(
                    `https://web.whatsapp.com/send?phone=+55${phone}`,
                    "_blank"
                  )
                }
              >
                <WhatsApp />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
      }),
      gender,
    };
  });

  useEffect(() => {
    async function fetchAppointments() {
      setLoading(true);
      try {
        const patientOrganizationResponse = await GetAppointment({
          // organization: access.organizationId,
          ...(viewType === "user" && {
            professionalActor: `Practitioner/${oids.cpf}-${user.username}`,
          }),

          actor: locationId,
          scheduling: true,
          status: ["fulfilled", "booked"],
          includePatient: true,
        });

        const responsePatient = patientOrganizationResponse?.entry?.filter(
          (
            el:
              | fhir5.BundleEntry<fhir5.Patient>
              | fhir5.BundleEntry<fhir5.Appointment>
          ) => el.resource?.resourceType === "Patient"
        );

        setLastPatients(responsePatient);
      } catch (err) {
        console.log("fetchAppointemntsError", err);
      } finally {
        setLoading(false);
      }
    }
    if (locationId) {
      fetchAppointments();
    }
  }, [user, locationId]);

  return (
    <Stack>
      {loading ? (
        <CircularLoading />
      ) : (
        <>
          {rows?.length ? (
            <GenericTable
              tableColumns={columns}
              tableRows={rows?.reverse()}
              actions={(rowData: any) => <PatientSearchActions row={rowData} />}
            />
          ) : (
            <NoDataWarning message="Nenhum atendimento recente foi encontrado" />
          )}
        </>
      )}
    </Stack>
  );
}
