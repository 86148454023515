import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSessionContext } from "./SessionContext";
import { GetAppointment } from "../services/fhir";
import { oids } from "../configs/Settings";
import { useLocationContext } from "./LocationContext";

interface PatientOrganizationContextValue {
  patientOrganization: fhir5.BundleEntry<fhir5.Patient>[] | undefined;
  refetchPatientOrganization: Function;
  patientOrganizationLoading: boolean;
}

export const PatientOrganizationContext =
  createContext<PatientOrganizationContextValue>(null!);

export function PatientOrganizationContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { access, user } = useSessionContext();
  const { location } = useLocationContext();
  const [patientOrganization, setPatientOrganization] =
    useState<fhir5.BundleEntry<fhir5.Patient>[]>();
  const [refetch, setRefetch] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  function refetchPatientOrganization() {
    setRefetch(!refetch);
  }

  useEffect(() => {
    setLoading(true);
    async function fetchPatientOrganization() {
      const locationId = location
        ?.map(
          (element: fhir5.BundleEntry<fhir5.Location>) => element.resource?.id
        )
        ?.join(",");

      const patientOrganizationResponse = await GetAppointment({
        // organization: access.organizationId,
        professionalActor: `Practitioner/${oids.cpf}-${user.username}`,
        actor: locationId,
        scheduling: true,
        status: ["fulfilled"],
        includePatient: true,
      });

      const responsePatient = patientOrganizationResponse?.entry?.filter(
        (
          el:
            | fhir5.BundleEntry<fhir5.Patient>
            | fhir5.BundleEntry<fhir5.Appointment>
        ) => el.resource?.resourceType === "Patient"
      );

      setPatientOrganization(responsePatient);
    }
    try {
      access.type === "professional" && location && fetchPatientOrganization();
    } catch (err) {
      console.log("PatientOrganization", err);
    } finally {
      setLoading(false);
    }
  }, [access?.organizationId, refetch]);

  const contextValue = {
    patientOrganization,
    refetchPatientOrganization,
    patientOrganizationLoading: loading,
  };

  return (
    <PatientOrganizationContext.Provider value={contextValue}>
      {children}
    </PatientOrganizationContext.Provider>
  );
}

export function usePatientOrganizationContext() {
  return useContext(PatientOrganizationContext);
}
