import { Stack, Typography } from "@mui/material";
import {
  LargeContentBox,
  PageTitle,
  PrimaryButton,
  SecondaryButton,
} from "../../components/common";
import { PrivateMainLayout } from "../../components/layout";
import { APPC_POLICY } from "../../services/appc";
import { useSessionContext } from "../../contexts/SessionContext";
import { usePrivacyContext } from "../../contexts/PrivacyContext";
import { useState } from "react";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import { ConsentManagementMobile } from "../privacymenumobile/components/ConsentManagementMobile";

export function Privacy() {
  const { user } = useSessionContext();
  const { isSharing, privacyLoading, setRefetch } = usePrivacyContext();

  const [loading, setLoading] = useState<boolean>(false);

  async function handleChangePolicy(share: boolean) {
    setLoading(true);
    try {
      await APPC_POLICY({
        option: share ? "Opt-in" : "Opt-out",
        patient_id: user.username,
      });
      toast.success("Política de privacidade alterada com sucesso");
    } catch (err) {
      toast.error("Não foi possível alterar a política de privacidade");
      console.log("PrivacyPage", err);
    } finally {
      setRefetch((prev: boolean) => !prev);
      setLoading(false);
    }
  }
  return (
    <>
      {isMobile ? (
        <ConsentManagementMobile />
      ) : (
        <PrivateMainLayout noSidebar={isMobile} mobileView={isMobile}>
          <LargeContentBox>
            <PageTitle> Privacidade </PageTitle>
            <Stack spacing={1}>
              <Typography fontSize={18} textAlign="justify">
                Ao aceitar os termos de uso deste aplicativo e caso você já
                tenha sido atendido em alguma unidade de saúde participante da
                Plataforma Fluxmed, por padrão, seu Registro Eletrônico de Saúde
                é compartilhado automaticamente entre as unidades de saúde.
              </Typography>

              <Typography fontSize={18} textAlign="justify">
                O compartilhamento do Registro Eletrônico de Saúde é importante
                para a continuidade do seu cuidado de saúde, ao compartilha-lo,
                suas informações de saúde já estarão disponíveis para os
                profissionais das diferentes unidades de saúde participantes da
                plataforma Fluxmed.
              </Typography>

              <Typography fontSize={18} textAlign="justify">
                Você pode gerenciar quais as unidades de saúde ou as pessoas
                próximas a você poderão ter acesso ao suas informações de saúde,
                poderá ainda ver quem já visualizou seus registros eletrônicos
                de saúde no menu “Compartilhamento”
              </Typography>

              <Typography
                fontSize={18}
                textAlign="justify"
                sx={{ width: "100%" }}
              >
                Caso não deseje que seu registro de saúde seja compartilhado,
                selecione a opção “Não tenho interesse em compartilhar meu
                Registro Eletrônico de Saúde”.
              </Typography>

              <Typography
                fontSize={18}
                textAlign="justify"
                sx={{ width: "100%" }}
              >
                Seu Registro Eletrônico de Saúde é privado. Para que você possa
                compartilhá-lo ou não com outras pessoas ou com estabelecimentos
                de saúde de seu interesse, selecione a opção desejada.
              </Typography>
            </Stack>
            <Stack
              mt={4}
              width="100%"
              direction={{ xs: "column", md: "row-reverse" }}
              spacing={{ xs: 2, lg: 4 }}
              justifyContent="space-between"
            >
              <PrimaryButton
                width="100%"
                height="50px"
                disabled={isSharing && !privacyLoading}
                loading={loading && !isSharing}
                onClick={async () => handleChangePolicy(true)}
              >
                Compartilhar meu Registro Eletrônico de Saúde
              </PrimaryButton>

              <SecondaryButton
                width="100%"
                height="50px"
                disabled={!isSharing && !privacyLoading}
                onClick={async () => handleChangePolicy(false)}
              >
                Não compartilhar meu Registro Eletrônico de Saúde"
              </SecondaryButton>
            </Stack>
          </LargeContentBox>
        </PrivateMainLayout>
      )}
    </>
  );
}
