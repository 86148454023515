import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";

export async function GetPrescriptionProtocol({
  questionnaire,
  author,
  id,
}: any) {
  let url = `${endpoints.ADMFHIR}/QuestionnaireResponse?questionnaire=${questionnaire}&author=Organization/${author}&_sort=-_lastUpdated&_count=150`;
  if (id) url = `${url}&_id=${id}`;
  const bearerToken = await useAuth.getUserToken();
  const headers = {
    Accept: "*/*",
    Authorization: bearerToken,
    "Content-Type": "application/fhir+json",
  };
  const response = await useAxios
    .get(url, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`GetQuestionnaireResponse: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("GetPrescriptionProtocol ", error);
      // toast.error(`GetPrescriptionProtocol Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
