import { Stack, Typography } from "@mui/material";
import { FormAutoComplete } from "../../../components/form";

export function ScreenChoiseOperator({ control }: any) {
  return (
    <>
      <Stack>
        <Typography variant="body1" fontWeight={600} color="neutral700.main">
          Complete os dados com o convênio médico:
        </Typography>

        <Typography variant="body1" color="neutral600.main">
          Use os dados da sua carteirinha pra adionar o convênio:
        </Typography>
      </Stack>

      <FormAutoComplete
        name="healthOperator"
        label="Nome da operadora"
        control={control}
        selectOptions={[]}
      />
    </>
  );
}
