import { Add, Delete } from "@mui/icons-material";
import {
  Chip,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import {
  InputDate,
  InputText,
} from "../../../ClinicalRegister/components/QuestionnaireViewer/components/Form";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";

export function ResultadosHistory({ name, values, dateRef }: any) {
  const { control, pastExams, setPastExams, unregister } =
    useMedicalCareContext();

  const handleAddInput2 = () => {
    // Adiciona um novo input ao estado do array

    const newInput = {
      nameId: `pastExames.${name}.${pastExams?.length}.name`,
      valueId: `pastExames.${name}.${pastExams?.length}.value`,
      dateId: `pastExames.${name}.${pastExams?.length}.date`,
    };
    setPastExams([...pastExams, newInput]);
  };

  return (
    <Stack>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent={"center"}
        sx={{
          width: "100%",
          mb: 2,
          boxShadow: "1px 1px 16px 1px rgba(0, 0, 0, 0.1)",
          padding: 1,
          borderRadius: "8px",
        }}
      >
        <Grid item direction="row" xs={2} spacing={2}>
          <Stack minWidth="80px">
            <Typography
              variant="body1"
              fontWeight={500}
              sx={{
                padding: 1,
                height: "fit-content",
                width: "fit-content",
              }}
            >
              {name}
            </Typography>
          </Stack>
        </Grid>
        <Grid
          item
          direction="row"
          xs={9}
          spacing={2}
          overflow="auto"
          sx={{
            "&::-webkit-scrollbar": {
              height: "0.5em",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#ccc",
              // borderRadius: "8px",
            },
            // "&::-webkit-scrollbar-track": {
            //   backgroundColor: "primary100.main",
            //   // borderRadius: "8px",
            // },
            paddingX: "8px",
          }}
        >
          <Stack
            direction="row"
            spacing={4}
            sx={{
              maxWidth: "100%",
            }}
          >
            {values?.map((result: any, index: number) => {
              const dateResponse = result?.item?.[2]?.answer?.[0]?.valueDate;
              return (
                <Tooltip
                  title={
                    <Stack
                      direction="row"
                      width="100%"
                      alignItems="center"
                      spacing={2}
                    >
                      {result?.displayInfo?.practitioner && (
                        <Typography
                          color="neutral700.main"
                          sx={{
                            fontWeight: 500,
                            fontStyle: "italic",
                            fontSize: 12,
                          }}
                        >
                          Dr. {result?.displayInfo?.practitioner}
                        </Typography>
                      )}

                      {result?.displayInfo?.healthcare && (
                        <Chip
                          label={
                            result?.displayInfo?.healthcare ||
                            "Sem especialidade"
                          }
                          size="small"
                          sx={{
                            color: result?.displayInfo?.healthcare
                              ? "neutral700.main"
                              : "neutral500.main",

                            fontWeight: 500,
                            backgroundColor: result?.displayInfo?.healthcare
                              ? ""
                              : "#f1f1f1",
                          }}
                        />
                      )}
                    </Stack>
                  }
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -14],
                          },
                        },
                      ],
                    },
                    tooltip: {
                      sx: {
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        borderRadius: "8px",
                        border: "0.5px solid #A9A9A9",
                        backdropFilter: "blur(4px)",
                        boxShadow: "4px 4px 16px 4px rgba(0, 0, 0, 0.1)",
                        color: "white",
                        fontSize: "1rem",
                      },
                    },
                    arrow: {
                      sx: {
                        color: "rgba(255, 255, 255, 1)",
                      },
                    },
                  }}
                >
                  <Stack
                    alignItems="center"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 0.5,
                      backgroundColor: "#F5F5F5",
                      border: "0.5px solid #A9A9A9",
                      borderRadius: "12px",
                      padding: 0.5,
                    }}
                  >
                    <Chip
                      label={
                        dateResponse
                          ? dayjs(dateResponse)
                              ?.format("DD MMM YYYY")
                              ?.toUpperCase()
                          : "-"
                      }
                      size="small"
                      sx={{
                        backgroundColor: "#A9A9A9",
                        width: "100%",
                        // minWidth: "100px",
                        color: "white",
                        borderRadius: "8px",
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    />
                    <Typography
                      sx={{
                        color: "#696969",
                        fontSize: "12px",
                        paddingX: 1,
                        fontWeight: 500,
                      }}
                      justifyContent="center"
                    >
                      {result?.item?.[1]?.answer?.[0]?.valueString || "-"}
                    </Typography>
                  </Stack>
                </Tooltip>
              );
            })}
          </Stack>
        </Grid>

        <Grid item xs={1} sx={{ display: "flex", justifyContent: "center" }}>
          <Tooltip title="Adicionar resultado">
            <IconButton onClick={handleAddInput2}>
              <Add />
            </IconButton>
          </Tooltip>
        </Grid>
        <Stack>
          {pastExams?.map((input: any, index: any) => (
            <>
              {input?.nameId && input?.nameId?.includes(name) && (
                <Stack
                  direction="row"
                  spacing={1}
                  padding={1}
                  alignItems="center"
                  justifyContent="left"
                >
                  <InputText
                    key={input?.valueId}
                    label="Valor do exame"
                    name={input.valueId}
                    control={control}
                    variant="filled"
                    sx={{ mb: 4, width: "300px" }}
                  />

                  <InputDate
                    key={input?.dateId}
                    label="Data de realização"
                    name={input?.dateId}
                    defaultValue={dayjs(dateRef)}
                    control={control}
                  />
                  <IconButton
                    onClick={() => {
                      setPastExams((prevItems: any) => {
                        const updatedItems = [...prevItems]; // Faz uma cópia do array
                        updatedItems[index] = ""; // Altera o item específico
                        return updatedItems; // Retorna o novo array modificado
                      });
                      unregister(`pastExames.${name}.${index}`);
                    }}
                  >
                    <Delete sx={{ color: "error600.main" }} />
                  </IconButton>
                </Stack>
              )}
            </>
          ))}
        </Stack>
      </Grid>
    </Stack>
  );
}
