import { DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { CommonDialog } from "../../../../components/common/CommonDialog";
import { FormButtonGroup } from "../../../../components/form";
import { useState } from "react";
import { useConsentContext } from "../../../../contexts/ConsentContext";
import { VigencyOptions } from "../VigencyOptions";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { APPC_CONSENT } from "../../../../services/appc";
import { useSessionContext } from "../../../../contexts/SessionContext";

export function RenewConsentDialog({ setRefetch }: any) {
  const { user } = useSessionContext();
  const {
    renewDialog,
    setRenewDialog,
    subjectName,
    consentType,
    subjectId,
    vigency,
    resetStates,
  } = useConsentContext();

  const defaultValues = { vigency: 30 };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, reset } = methods;
  const [loading, setLoading] = useState<boolean>(false);

  const handleClose = () => {
    reset(defaultValues);
    resetStates();
    setRenewDialog(false);
  };

  const vigencyValue = vigency === 1000 ? null : vigency;

  const handleConfirm = async () => {
    try {
      if (consentType === "cpf") {
        await APPC_CONSENT({
          rule: "Permit",
          patient_id: user.username,
          subject_id: [subjectId],
          cnes: null,
          vigency: vigencyValue,
        });
      } else
        await APPC_CONSENT({
          rule: "Permit",
          patient_id: user.username,
          subject_id: null,
          cnes: [subjectId.split("-")[1]],
          vigency: vigencyValue,
        });
      toast.success(`Consentimento concedido com sucesso! `);
    } catch {
      toast.error(`Não foi possível finalizar a operação.`);
    } finally {
      handleClose();
      setRefetch((prev: boolean) => !prev);
    }
  };
  return (
    <CommonDialog handleClose={handleClose} isOpen={renewDialog}>
      <DialogTitle textAlign="center">
        Criar novo consetimento
        <Typography
          mt={2}
          textAlign="justify"
          variant="body1"
          color="neutral700.main"
        >
          O consentimento atual não é mais válido. Para que você possa gerenciar
          esse acesso ao RES é necessário criar um novo consentimento. Deseja
          permitir novamente o acesso de <b> {subjectName} </b>ao RES?
        </Typography>
      </DialogTitle>
      <DialogContent>
        <VigencyOptions control={control} />
        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          mt={2}
        >
          <FormButtonGroup
            onGoBackButtonClick={handleClose}
            onNextButtonClick={handleConfirm}
            goBackButtonText="Cancelar"
            nextButtonText="Confirmar"
            loading={loading}
          />
        </Stack>
      </DialogContent>
    </CommonDialog>
  );
}
