import { PrivateMainLayout } from "../../components/layout";
import { LargeContentBox, PageTitle } from "../../components/common";

import { isMobile } from "react-device-detect";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Business, Person, PictureAsPdf, Place } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useViewContext } from "../../contexts/ViewContext";

export function Settings() {
  const navigate = useNavigate();
  const { availableRoles } = useViewContext();

  const cards = [
    {
      name: "Clínica",
      icon: <Business fontSize="large" sx={{ color: "primary800.main" }} />,
      description:
        "Informações básicas e opções de gerenciamento da sua clínica",
      path: "/clinic",
      active: true,
    },
    {
      name: "Profissionais",
      icon: <Person fontSize="large" sx={{ color: "primary800.main" }} />,
      description: "Gerencie os profissionais de sua clínica",
      path: "/practitioners",
      active: true,
    },
    {
      name: "Localizações",
      icon: <Place fontSize="large" sx={{ color: "primary800.main" }} />,
      description: "Gerencie as localicades de sua clínica",
      path: "/locations",
      active: true,
    },
    {
      name: "Documentos",
      icon: <PictureAsPdf fontSize="large" sx={{ color: "primary800.main" }} />,
      description: "Personalize os documentos emitidos da clínica",
      path: "/pdf",
      active:
        availableRoles?.includes("manager") ||
        availableRoles?.includes("owner"),
    },
  ];

  return (
    <PrivateMainLayout noSidebar={isMobile} mobileView={isMobile}>
      <LargeContentBox>
        <Stack sx={{ width: "100%" }}>
          <PageTitle>Configurações</PageTitle>
          <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Grid container spacing={4}>
              {cards
                ?.filter((e: any) => e?.active)
                ?.map((card, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card
                      sx={{ height: "100%", borderRadius: "8px" }}
                      onClick={() => navigate(card?.path)}
                      elevation={5}
                    >
                      {" "}
                      {/* Garante que o card ocupe toda a altura */}
                      <CardActionArea
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          "&:hover": {
                            backgroundColor: "primary100.main",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: "24px",
                          }}
                        >
                          <Box sx={{ marginBottom: "-8px" }}>
                            {" "}
                            {/* Reduz espaço entre ícone e nome */}
                            {card.icon}
                          </Box>
                        </Box>
                        <CardContent sx={{ flexGrow: 1 }}>
                          {" "}
                          {/* FlexGrow garante que este conteúdo ocupe o espaço */}
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            align="center"
                          >
                            {card.name}
                          </Typography>
                          <Typography
                            textAlign="center"
                            variant="body1"
                            color="neutral700.main"
                            fontWeight={500}
                            mb={2}
                          >
                            {card.description}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Stack>
      </LargeContentBox>
    </PrivateMainLayout>
  );
}
