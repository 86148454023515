import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import { FormRadioButton, FormTextInput } from "../form";
import CheckboxOptions from "../../pages/home/components/CheckboxOptions";
import { useFormatter } from "../../utils/useFormatter";
import { EHRPosterPatchStatus } from "../../services/fhir/patientsummary/EHRPosterPatchStatus";

interface IProps {
  data: any;
  index?: number;
  stop?: boolean;
  handleClose?: Function;
}

const unit = [
  {
    value: "GRAN",
    label: "Grânulos",
    inputType: "number",
  },
  {
    value: "COM",
    label: "Comprimido",
    inputType: "number",
  },
  {
    value: "CAP",
    label: "Cápsula",
    inputType: "number",
  },
  {
    value: "SOL OR",
    label: "Solução Oral",
  },
  {
    value: "SOL INJ",
    label: "Solução Injetável",
  },
  {
    value: "SOL",
    label: "Solução",
  },

  {
    value: "SUSP",
    label: "Suspensão",
  },
  {
    value: "POM",
    label: "Pomada",
  },
  {
    value: "GEL",
    label: "Gel",
  },
  {
    value: "XAR",
    label: "Xarope",
  },
  {
    value: "INJ",
    label: "Injetável",
  },
  {
    value: "COL",
    label: "Colírio",
  },
  {
    value: "AER",
    label: "Aerossol",
  },
  {
    value: "SUP",
    label: "Supositório",
    inputType: "number",
  },
  {
    value: "AMP",
    label: "Ampola",
    inputType: "number",
  },
  {
    value: "CTG",
    label: "Cartucho",
    inputType: "number",
  },
  {
    value: "FLD",
    label: "Fluido",
  },
  {
    value: "CJ  ",
    label: "Conjunto",
    inputType: "number",
  },
  {
    value: "ENV",
    label: "Envelope ou Sachê",
  },
  {
    value: "BISN",
    label: "Bisnaga",
    inputType: "number",
  },
  {
    value: "DISP",
    label: "Dispositivo",
  },
  {
    value: "BALA",
    label: "Bala",
    inputType: "number",
  },
  {
    value: "LAQ",
    label: "Laca",
  },
];

export default function ControlMedication({
  data,
  index,
  stop,
  handleClose,
}: IProps) {
  const methods = useForm();
  const { handleSubmit, control } = methods;

  const watchStatusChoise = useWatch({
    name: "status",
    control,
  });

  const name = data?.resource?.medication?.concept?.text || "";
  const dosageUnity =
    data?.resource?.dosageInstruction?.[0]?.doseAndRate?.[0]?.doseQuantity
      ?.unit || "";
  const dosageQuantity =
    data?.resource?.dosageInstruction?.[0]?.doseAndRate?.[0]?.doseQuantity
      ?.value || "";

  const frequency =
    data?.resource?.dosageInstruction?.[0]?.timing?.repeat?.frequency || "";
  const period =
    data?.resource?.dosageInstruction?.[0]?.timing?.repeat?.period || "";
  const periodUnit =
    data?.resource?.dosageInstruction?.[0]?.timing?.repeat?.periodUnit || "";
  const duration =
    data?.resource?.dosageInstruction?.[0]?.timing?.repeat?.duration || "";
  const durationUnit =
    data?.resource?.dosageInstruction?.[0]?.timing?.repeat?.durationUnit || "";

  const optionsChoise = [
    { display: "Sim, começei a tomar", value: "active" },
    { display: "Terminei de tomar", value: "completed" },
    { display: "Parei de tomar", value: "stopped" },
  ];

  const onSubmit = async (dataItens: any) => {
    try {
      await EHRPosterPatchStatus({
        resourceType: data?.resource?.resourceType,
        resourceId: data?.resource?.id,
        status: dataItens.status,
      });
      handleClose && handleClose();
      toast.success("Escolha registrada");
    } catch (err) {
      console.log("error:", err);
    }
  };

  return (
    <Accordion
      sx={{
        width: "100%",
        border: !stop ? "1px solid" : "none",
        borderColor: "neutral200.main",
        borderRadius: 1,
        ...(stop && {
          boxShadow: "none",
        }),
      }}
      defaultExpanded={index === 0 ? true : false}
    >
      {!stop && (
        <AccordionSummary
          expandIcon={!stop && <GridExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {`${useFormatter.formatNameDisplay(name)}`}
        </AccordionSummary>
      )}

      <AccordionDetails>
        <Typography variant="body1">
          {dosageUnity && dosageQuantity
            ? `${dosageUnity}. (${dosageQuantity}un)`
            : ""}
        </Typography>
        <>
          <Typography variant="body1" color="neutral700.main">
            {frequency && period && periodUnit && duration && durationUnit
              ? ` ${frequency} ${
                  unit.find(
                    (el: any) =>
                      el.value ===
                      data?.resource?.dosageInstruction?.[0]?.doseAndRate?.[0]
                        ?.type?.coding?.[0]?.code
                  )?.label || ""
                }
                
             a cada ${period}${periodUnit} durante ${duration}${durationUnit} `
              : ""}
          </Typography>

          {data?.resource?.status === "draft" ||
          data?.resource?.status === "active" ? (
            <>
              <Stack width="100%" direction="column">
                <FormRadioButton
                  name="status"
                  label=""
                  defaultValue={!stop && data?.resource?.status}
                  control={control}
                  rowOrientation={false}
                  selectOptions={optionsChoise?.filter((e: any) =>
                    stop ? e?.value !== "active" : true
                  )}
                />
                {watchStatusChoise === "stopped" && (
                  <FormTextInput
                    multiline
                    rows={3}
                    label="Qual motivo?"
                    name="note"
                    control={control}
                  />
                )}
                {/* <CheckboxOptions
                  name="notificationChoise"
                  label="Deseja ativar notificações?"
                  control={control}
                  checked={watchNotificationChoise}
                /> */}
              </Stack>
              <Stack mt={1} direction="row" width="100%" justifyContent="right">
                <Button onClick={handleSubmit(onSubmit)}>Enviar</Button>
              </Stack>
            </>
          ) : (
            <Stack width="100%">
              <Alert variant="outlined" severity="info">
                Controle de medicação encerrada
              </Alert>
            </Stack>
          )}
        </>
      </AccordionDetails>
    </Accordion>
  );
}
