import { Box, Tab, Tabs } from "@mui/material";
interface IProps {
  tabValue: number;
  handleTab: any;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function TabDocumentsChoise({ tabValue, handleTab }: IProps) {
  return (
    <Box sx={{ width: "90%" }}>
      <Tabs
        value={tabValue}
        onChange={handleTab}
        aria-label="basic tabs example"
      >
        <Tab label="Solicitações de exames" {...a11yProps(0)} />
        <Tab label="Prescrições" {...a11yProps(1)} />
        <Tab label="Relatórios" {...a11yProps(2)} />
      </Tabs>
    </Box>
  );
}
