import { Chip, Stack } from "@mui/material";
import { useManagerContext } from "../../../../../../../contexts/ManagerContext";
import { usePractitionerRoleContext } from "../../../../../../../contexts/PractitionerRoleContext";
import { useHealthcareServiceContext } from "../../../../../../../contexts/HealthcareServiceContext";
import { useLocationContext } from "../../../../../../../contexts/LocationContext";

function cleanRepeatedList(list: any) {
  let cleanedList = new Set(list);
  return Array.from(cleanedList);
}

export function FiltersApply() {
  const {
    schedule,
    isScheduleFilterApply,
    isHealthcareFilterApply,
    isPractitionerFilterApply,
    isLocationFilterApply,
    selectedDate,
    activeHealthcareFilter,
    activeLocationFilter,
    activePractitionerFilter,
    activeScheduleFilter,
    handleRemoveHealthcareFilter,
    handleRemoveLocationFilter,
    handleRemovePractitionerFilter,
    handleRemoveScheduleFilter,
  } = useManagerContext();

  const { practitionerRole } = usePractitionerRoleContext();
  const { healthcareService } = useHealthcareServiceContext();
  const { location } = useLocationContext();

  const practitionerOptions = practitionerRole?.map((el: any) => {
    const practitioner = el.resource?.practitioner;
    return { display: practitioner?.display, value: practitioner?.reference };
  });

  const scheduleOptions = schedule?.entry
    ?.filter((e: any) => e?.resource?.active)
    ?.map((el: any, index: number) => ({
      display: el?.resource?.name,
      value: el?.resource.id,
    }));

  const locationOptions = location
    ?.filter((loc: any) => loc?.resource?.status === "active")
    ?.map((el: any, index: number) => ({
      display: el?.resource?.name,
      value: el?.resource.id,
    }));

  const healthcareNames = cleanRepeatedList(
    healthcareService?.map((el: any) => el?.resource.name)
  );
  const healthcareOptions = healthcareNames?.map((el: any) => ({
    display: el,
    value: el,
  }));
  return (
    <Stack mt={1}>
      {(isScheduleFilterApply ||
        isHealthcareFilterApply ||
        isPractitionerFilterApply ||
        isLocationFilterApply ||
        selectedDate) && (
        <>
          <Stack direction="row" spacing={2} alignItems="center">
            {/* <Chip
                    label={formatDate(selectedDate)} // Nome do profissional
                    style={{ margin: "4px", backgroundColor: "#f1f1f1" }} // Espaçamento entre os chips
                  /> */}
            {activePractitionerFilter &&
              activePractitionerFilter?.length > 0 &&
              activePractitionerFilter?.map((filter: any, index: number) => {
                const practitoner = practitionerOptions?.find(
                  (e: any) => filter === e.value
                );

                return (
                  <Chip
                    key={index}
                    label={practitoner?.display} // Nome do profissional
                    onDelete={() => handleRemovePractitionerFilter(filter)} // Função de remover o filtro
                    style={{ margin: "4px", backgroundColor: "#f1f1f1" }} // Espaçamento entre os chips
                  />
                );
              })}
            {activeLocationFilter &&
              activeLocationFilter.length > 0 &&
              activeLocationFilter.map((filter: any, index: number) => {
                const location = locationOptions?.find(
                  (e: any) => filter === e.value
                );

                return (
                  <Chip
                    key={index}
                    label={location?.display} // Nome do profissional
                    onDelete={() => handleRemoveLocationFilter(filter)} // Função de remover o filtro
                    style={{ margin: "4px", backgroundColor: "#f1f1f1" }} // Espaçamento entre os chips
                  />
                );
              })}
            {activeScheduleFilter &&
              activeScheduleFilter.length > 0 &&
              activeScheduleFilter.map((filter: any, index: number) => {
                const schedule = scheduleOptions?.find(
                  (e: any) => filter === e.value
                );

                return (
                  <Chip
                    key={index}
                    label={schedule?.display} // Nome do profissional
                    onDelete={() => handleRemoveScheduleFilter(filter)} // Função de remover o filtro
                    style={{ margin: "4px", backgroundColor: "#f1f1f1" }} // Espaçamento entre os chips
                  />
                );
              })}
            {activeHealthcareFilter &&
              activeHealthcareFilter.length > 0 &&
              activeHealthcareFilter.map((filter: any, index: number) => {
                const healthcare = healthcareOptions?.find(
                  (e: any) => filter === e.value
                );

                return (
                  <Chip
                    key={index}
                    label={healthcare?.display} // Nome do profissional
                    onDelete={() => handleRemoveHealthcareFilter(filter)} // Função de remover o filtro
                    style={{ margin: "4px", backgroundColor: "#f1f1f1" }} // Espaçamento entre os chips
                  />
                );
              })}
          </Stack>
        </>
      )}
    </Stack>
  );
}
