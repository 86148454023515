import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";
import { OAuthApplication } from "../../auth";

interface IProps {
  orgIds?: string;
  content?: string;
  count?: number;
  applicationAuth?: boolean;
}

export async function GetOrganizations({
  orgIds,
  count,
  content,
  applicationAuth,
}: IProps) {
  let url = `${endpoints.ADMFHIR}/Organization`;
  if (orgIds) url = `${url}/${orgIds}`;
  if (content) url = `${url}?_content=${content}`;
  if (count) url = `${url}&_count=${count}`;

  const headers = {
    Accept: "*/*",
    Authorization: applicationAuth
      ? await OAuthApplication()
      : await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
  };
  const response = await useAxios
    .get(url, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`GetOrganizations: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("GetOrganizations ", error);
      // toast.error(`GetOrganizations Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
