import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import check from "../../../assets/check.svg";
import { ContentBoxTitle, PrimaryButton } from "../../../components/common";
import { FormStack } from "../../../components/form";

export default function ActiveAccount() {
  const navigate = useNavigate();
  return (
    <FormStack>
      <img src={check} alt="Confirmação" />
      <ContentBoxTitle>Sua conta foi ativada</ContentBoxTitle>
      <Typography
        variant="body1"
        textAlign="center"
        sx={{ color: "neutral600.main" }}
      >
        Confirmamos suas informações e sua conta foi criada com sucesso!
      </Typography>
      <PrimaryButton
        width="70%"
        height="52px"
        onClick={() => navigate("/signin")}
      >
        Acessar Fluxmed
      </PrimaryButton>
    </FormStack>
  );
}
