import React from "react";
import { Stack } from "@mui/material";
import { isMobile } from "react-device-detect";

export function PublicEmptyLayout({ children }: { children: React.ReactNode }) {
  return (
    <Stack
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "primary100.main",
        height: "100vh",
      }}
      paddingX={{
        xs: isMobile ? 0 : 3,
        sm: isMobile ? "0%" : "15%",
        md: isMobile ? "0%" : "25%",
      }}
    >
      {children}
    </Stack>
  );
}
