import { TextField, styled } from "@mui/material";

interface IProps {
  label: string;
  value: string | number;
  mask?: any;
  variant?: any;
}

export const DisplayCustom = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      border: `2px solid ${theme.palette.primary700.main}`,
    },
  },
}));

export function DisplayTextField({ label, value, mask, variant }: IProps) {
  return (
    <DisplayCustom
      variant={variant}
      label={label}
      defaultValue={mask ? mask(value) : value}
      InputProps={{
        readOnly: true,
      }}
      fullWidth
    />
  );
}
