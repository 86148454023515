import { styled } from "@mui/material";
import { LoadingButton } from "@mui/lab";

interface IProps {
  children: React.ReactNode;
  width: string | object;
  maxWidth?: string;
  height: string;
  disabled?: boolean;
  onClick(): any;
  loading?: boolean;
  endIcon?: JSX.Element | any;
  startIcon?: JSX.Element | any;
  onlyIcon?: JSX.Element | any;
}

export const CustomButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary700.main,
  borderRadius: 10,
  fontSize: theme.typography.subtitle2.fontSize,
  color: theme.palette.neutral0.main,
  textTransform: "none",
  ":hover": {
    backgroundColor: theme.palette.primary800.main,
  },
  ":disabled": {
    border: `1px solid`,
    borderColor: theme.palette.neutral300.main,
    color: theme.palette.neutral600.main,
    backgroundColor: theme.palette.neutral300.main,
  },
}));

export function PrimaryButton({
  children,
  width,
  maxWidth,
  height,
  disabled,
  loading,
  onClick,
  endIcon,
  startIcon,
  onlyIcon,
}: IProps) {
  return (
    <CustomButton
      variant="contained"
      sx={{ width, height, maxWidth }}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      endIcon={endIcon}
      startIcon={startIcon}
    >
      {onlyIcon ? onlyIcon : children}
    </CustomButton>
  );
}
