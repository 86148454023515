import { endpoints } from "../../configs/Settings";
import { useAuth } from "../../utils/useAuth";
import { useAxios } from "../../utils/useAxios";

interface IRevokeProps {
  cpf: number;

  provider: "vidaas" | "safeid";
}

export async function RevokeSignToken({ cpf, provider }: IRevokeProps) {
  const url = `${endpoints.DSIG}/resetUserSession`;
  const data = { userName: cpf, provider };
  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
  };
  const response = await useAxios
    .post(url, data, {
      headers,
      timeout: 8000,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
  if (response) return response;
}
