type pattern = { [key: string]: RegExp };

const useRegex: pattern = {
  loginEntry:
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$|^\d{11}$|^\(\d{2}\) \d{5}-\d{4}$|^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
  cpf: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
  cnpj: /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/,
  phoneNumber: /^\(\d{2}\) \d{5}-\d{4}$/,
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,5}$/,
  number: /^\d+$/,
};

export { useRegex };
