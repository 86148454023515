import { endpoints } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";
import { OAuthApplication } from "../../auth";

export async function Validate(credentials: any, applicationAuth?: boolean) {
  const url = `${endpoints.ADMFHIR}`;
  const data = credentials;
  const headers = {
    Accept: "*/*",
    Authorization: applicationAuth
      ? await OAuthApplication()
      : await useAuth.getUserToken(),
    "Content-Type": "application/json",
  };
  const response = await useAxios
    .post(url, data, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Created) {
        throw new Error(`Validate Organization: ${response.status}`);
      }
      return { status: HTTPResponseStatus.Created, data: response.data };
    })
    .catch((error) => {
      if (error?.response?.status === HTTPResponseStatus.BadRequest) {
        return {
          status: HTTPResponseStatus.BadRequest,
          data: "CNPJ inválido",
        };
      }
      if (error?.response?.status === HTTPResponseStatus.Conflict) {
        if (
          error?.response?.data?.detail?.includes("Organization already exists")
        ) {
          return {
            status: HTTPResponseStatus.Conflict,
            data: "Organização já cadastrada na plataforma",
          };
        }
        if (
          error?.response?.data?.detail?.includes(
            "The main CNAE of the CNPJ must be from a health area root"
          )
        ) {
          return {
            status: HTTPResponseStatus.BadRequest,
            data: "O CNAE principal do CNPJ deve ser da área de saúde",
          };
        }
        if (
          error?.response?.data?.detail?.includes(
            "The CPF of the logged in user must be the same as that of one of the partners"
          )
        ) {
          return {
            status: HTTPResponseStatus.BadRequest,
            data: "O CPF do usuário não coincide com nenhum sócio do CNPJ inserido",
          };
        }
      }
      return {
        status: error?.response?.status,
        data: "Houve um problema interno. Por favor, tente novamente mais tarde",
      };
    });
  if (response) return response;
}
