import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { FormButtonGroup, FormTextInput } from "../../../components/form";
import { useForm, useWatch } from "react-hook-form";
import { CommonDialog } from "../../../components/common/CommonDialog";
import { HTTPResponseStatus } from "../../../utils/enum";
import { toast } from "react-toastify";
import { EditOrganization } from "../../../services/fhir";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";
import { PatchAlias } from "../../../services/fhir/organization/PatchAlias";

interface IformInput {
  alias: string;
}

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  displayName: string;
  organization: any;
}

export function EditAliasDialog({
  isOpen,
  setIsOpen,
  displayName,
  organization,
}: IProps) {
  const defaultValues: IformInput = {
    alias: displayName,
  };
  const { refetchOrganization } = useOrganizationContext();
  const [loading, setLoading] = useState<boolean>(false);

  const methods = useForm<IformInput>({ defaultValues });
  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
    reset,
  } = methods;

  const fieldCnes = useWatch({ control, name: "alias" });

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const response = await PatchAlias(organization, data.alias);
      if (response?.status === HTTPResponseStatus.Success) {
        refetchOrganization();
        toast.success("Alteração realizada com sucesso");
        setIsOpen(false);
        return;
      }
      toast.error(
        "Houve um erro na atualização do nome de apresentação, tente novamente mais tarde"
      );
    } catch (error) {
      toast.error(
        "Houve um erro na atualização do nome de apresentação, tente novamente mais tarde"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
    }
  }, [isOpen]);

  return (
    <CommonDialog handleClose={handleClose} isOpen={isOpen}>
      <DialogTitle textAlign="center">
        Editar nome de apresentação
        <Typography variant="body1" color="neutral700.main">
          Este nome será exibido para os pacientes
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack pt={1}>
          <FormTextInput
            name="alias"
            label="Nome de apresentação"
            control={control}
            autoFocus={true}
          />
          <Stack
            width="100%"
            mt={3}
            direction="row"
            justifyContent="space-between"
          >
            <FormButtonGroup
              onGoBackButtonClick={handleClose}
              onNextButtonClick={handleSubmit(onSubmit)}
              goBackButtonText="Cancelar"
              nextButtonText="Salvar"
              loading={loading}
              disabled={!isDirty || !isValid || !fieldCnes?.length}
            />
          </Stack>
        </Stack>
      </DialogContent>
    </CommonDialog>
  );
}
