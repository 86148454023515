import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Dialog,
  Link,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { ReactNode, SyntheticEvent, useEffect, useState } from "react";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { FormButtonGroup } from "../../../components/form";
import { DeletePrescriptionProtocol } from "../../../services/fhir/prescriptionprotocol/DeletePrescriptionProtocol";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { GetProtocolVersion } from "../../../services/fhir/prescriptionprotocol/GetProtocolVersion";
import { CircularLoading } from "../../../components/common";
import { useDict } from "../../../utils/useDict";
import { usePractitionerRoleContext } from "../../../contexts/PractitionerRoleContext";
import { useFormatter } from "../../../utils/useFormatter";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ maxHeight: "40vh", pt: 2, overflow: "auto" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

export function ManageProtocolDialog({
  id,
  handleClose,
  setRefetch,
  isOpen,
  type,
}: any) {
  const [loading, setLoading] = useState<boolean>(false);
  const [displayProtocol, setDisplayProtocol] = useState<any>();
  const [selectedVersion, setSelectedVersion] = useState<any>();

  const { practitioner } = usePractitionerRoleContext();

  function findAnswer({ questionnaireResponseItens, targetId }: any) {
    return (
      questionnaireResponseItens?.find((item: any) => item.linkId === targetId)
        ?.answer?.[0] ||
      questionnaireResponseItens?.find((item: any) => item.linkId === targetId)
        ?.item
    );
  }

  function generateMedication(item: any) {
    //console.log("Select:", auxType);
    const medicationName = findAnswer({
      questionnaireResponseItens: item,
      targetId: "8543714373691",
    })?.valueString;

    const presentation = findAnswer({
      questionnaireResponseItens: item,
      targetId: "6273182343815",
    })?.valueString;

    const medicationValue = findAnswer({
      questionnaireResponseItens: item,
      targetId: "5252423656882",
    })?.valueString;

    const substance = findAnswer({
      questionnaireResponseItens: item,
      targetId: "2043756634085",
    })?.valueString;

    const auxTag = findAnswer({
      questionnaireResponseItens: item,
      targetId: "7570117070815",
    })?.valueString;

    const auxType = findAnswer({
      questionnaireResponseItens: item,
      targetId: "2794386593077",
    })?.valueString;

    const auxFrequency = findAnswer({
      questionnaireResponseItens: item,
      targetId: "1265328124778",
      targetText: "Frequency",
    })?.valueString?.split(" ")?.[0];

    const auxPeriod = findAnswer({
      questionnaireResponseItens: item,
      targetId: "6391319168670",
      targetText: "Period",
    })?.valueString?.split(" ")?.[0];

    const auxQuantity = findAnswer({
      questionnaireResponseItens: item,
      targetId: "4145930684259",
    })?.valueString;

    const medicationForm = findAnswer({
      questionnaireResponseItens: item,
      targetId: "4027816497232",
    })?.valueString;

    const medicationNote = findAnswer({
      questionnaireResponseItens: item,
      targetId: "5201559249537",
    })?.valueString;

    const auxForm = [
      auxQuantity && `${auxQuantity}`,
      medicationForm && `${medicationForm}`,
      auxFrequency && `a cada ${auxFrequency} h`,
      auxPeriod && `por ${auxPeriod} dias`,
    ]
      ?.filter((e: string) => e)
      ?.join(" ");

    return {
      name: medicationName,
      label: `${medicationName}${
        auxType === "medication"
          ? `${presentation ? ` - ${presentation}` : ""}`
          : ``
      }`,

      inputValue: {
        ...(substance && {
          code: {
            coding: [
              {
                code: medicationValue,
                display: substance,
                system: "https://fluxmed.com.br/fhir/CodeSystem/CMED",
              },
            ],
          },
          tag: auxTag,
        }),
        presentation: presentation,
        name: medicationName,

        label: `${medicationName}${
          auxType === "medication"
            ? `${presentation ? ` - ${presentation}` : ""}`
            : ``
        }`,
      },
      tag: auxTag,
      ...(auxQuantity && {
        quantity: { label: auxQuantity, value: auxQuantity },
      }),
      ...(auxFrequency && {
        frequency: { label: `${auxFrequency} h` },
      }),
      formValue: { label: medicationForm },
      ...(auxPeriod && {
        time: { label: `${auxPeriod} dias` },
      }),
      note: medicationNote,
      form: auxForm,
      type: auxType,
      tabValue:
        auxType === "medication"
          ? "1"
          : auxType === "supplement"
          ? "2"
          : auxType === "procedure"
          ? "1"
          : "3",
    };
  }

  function generateService(item: any) {
    const serviceName = findAnswer({
      questionnaireResponseItens: item,
      targetId: "8772001052232",
    })?.valueString;

    const auxCondition = findAnswer({
      questionnaireResponseItens: item,
      targetId: "9571768447124",
    });

    const conditionValue = findAnswer({
      questionnaireResponseItens: auxCondition,
      targetId: "4923176744451",
    })?.valueString;

    const conditionName = findAnswer({
      questionnaireResponseItens: auxCondition,
      targetId: "8170939802572",
    })?.valueString;

    const serviceValue = findAnswer({
      questionnaireResponseItens: item,
      targetId: "379415603318",
    })?.valueString;

    const auxCategory = findAnswer({
      questionnaireResponseItens: item,
      targetId: "1383313118296",
    })?.valueString;

    const serviceNote = findAnswer({
      questionnaireResponseItens: item,
      targetId: "3523489955010",
    })?.valueString;

    return {
      type: "service",
      tabValue: "2",
      inputValue: {
        ...(serviceValue && {
          code: serviceValue,
          system:
            "https://fluxmed.com.br/fhir/r5/CodeSystem/TUSSProcedimentosEventos",
        }),
        display: serviceName,
        name: serviceName,
        value: serviceName,
        label: serviceName,
      },
      name: serviceName,
      tag: "EX",
      form: "",
      note: serviceNote,
      condition: {
        code: conditionValue,
        display: conditionName,
        label: conditionName,
        system: "https://fluxmed.com.br/fhir/r4/CodeSystem/CID10",
        value: conditionValue,
      },
      category: { label: auxCategory },
    };
  }

  function generateCertificate(item: any) {
    const certificate = findAnswer({
      questionnaireResponseItens: item,
      targetId: "8744868479001",
    })?.valueString;

    return {
      tabValue: "3",
      type: "certificate",
      name: "Atestado",
      tag: "AT",
      form: "",
      note: certificate,
    };
  }

  const options = displayProtocol?.map((el: any, index: number) => {
    const auxDisplay = findAnswer({
      questionnaireResponseItens: el?.resource?.item,
      targetId: "438586562091",
    })?.valueString;

    const description = findAnswer({
      questionnaireResponseItens: el?.resource?.item,
      targetId: "description",
    })?.valueString;

    const references = findAnswer({
      questionnaireResponseItens: el?.resource?.item,
      targetId: "referencias",
    })?.valueString;

    const itens = el?.resource?.item?.filter(
      (item: any) => item.linkId !== "438586562091"
    );

    const condition = el?.resource?.item?.find(
      (item: any) => item.linkId === "condition"
    )?.answer;

    const auxItens = itens?.map((prescriptionItem: any) => {
      if (prescriptionItem?.linkId === "2631266001552") {
        return generateMedication(prescriptionItem.item);
      }
      if (prescriptionItem?.linkId === "5455138012969") {
        return generateService(prescriptionItem.item);
      }
      if (prescriptionItem?.linkId === "7695792866978") {
        return generateCertificate(prescriptionItem.item);
      }

      return {};
    });

    const auxPracitioner = practitioner?.find(
      (practitioners: any) =>
        practitioners?.resource?.id ===
        el?.resource?.source?.reference?.split("/")?.pop()
    )?.resource?.name?.[0]?.text;

    const auxLastEditor = practitioner?.find(
      (practitioners: any) =>
        practitioners?.resource?.id ===
        el?.resource?.subject?.reference?.split("/")?.pop()
    )?.resource?.name?.[0]?.text;

    return {
      status: el?.resource?.status,
      display: auxDisplay || "-",
      value: index + 1,
      itens: auxItens,
      subject: auxLastEditor || auxPracitioner,
      description,
      references,
      condition,
    };
  });

  const practitioners = options
    ?.map((e: any) => `Dr. ${useFormatter.formatNameDisplay(e?.subject)}`)
    ?.reduce((acc: string[], subject: any) => {
      if (!acc.includes(subject)) {
        acc.push(subject);
      }
      return acc;
    }, []);

  const tags = [
    {
      name: "VERMELHA",
      value: "CE",
      color: "#db3f30",
      display: "Controle especial - Tarja vermelha",
    },
    {
      name: "PRETA",
      value: "CE",
      color: "white",
      background: "black",
      display: "Controle especial - Tarja preta",
    },
    {
      name: "AMARELA",
      value: "AM",
      color: "#FEE2A9",
      display: "Receita amarela",
    },
    {
      name: "AZUL",
      value: "AZ",
      color: "#A4CBE8",
      display: "Receita azul",
    },
    {
      name: "SEM TARJA",
      value: "BR",
      color: "white",
      display: "Receita branca",
    },
    {
      name: "EX",
      display: "Exame",
      value: "EX",
      color: "#FDCF72",
    },
    {
      name: "AT",
      value: "AT",
      color: "#009E8C",
      display: "Atestado",
    },
  ];

  const auxMedication = options?.[selectedVersion]?.itens?.filter(
    (item: any) => item.type === "medication"
  );
  const auxSupplement = options?.[selectedVersion]?.itens?.filter(
    (item: any) => item.type === "supplement"
  );
  const auxHerbalMedicine = options?.[selectedVersion]?.itens?.filter(
    (item: any) => item.type === "herbalMedicine"
  );
  const auxProcedure = options?.[selectedVersion]?.itens?.filter(
    (item: any) => item.type === "procedure"
  );
  const auxService = options?.[selectedVersion]?.itens?.filter(
    (item: any) => item.type === "service"
  );
  const auxCertificate = options?.[selectedVersion]?.itens?.filter(
    (item: any) => item.type === "certificate"
  );

  const auxTratamentos = [
    ...(auxMedication || []),
    ...(auxSupplement || []),
    ...(auxHerbalMedicine || []),
  ];

  const auxDescription = options?.[selectedVersion]?.description;
  const auxReferences = options?.[selectedVersion]?.references;

  const navigate = useNavigate();

  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const handleDelete = async (id: any) => {
    const response = await DeletePrescriptionProtocol(id);

    if (response?.issue[0].details.coding[0].code === "SUCCESSFUL_DELETE") {
      toast.success("Protocolo removido com sucesso");
      handleClose();
    }
    setRefetch((prev: any) => !prev);
  };

  const auxTabs = [
    ...(auxMedication?.length ||
    auxSupplement?.length ||
    auxHerbalMedicine?.length
      ? ["Tratamentos"]
      : []),
    ...(auxService?.length ? ["Exames"] : []),
    ...(auxProcedure?.length ? ["Procedimentos"] : []),
    ...(auxCertificate?.length ? ["Relatórios"] : []),
  ];

  useEffect(() => {
    async function getProtocolVersion() {
      setLoading(true);
      try {
        const version = await GetProtocolVersion({
          id,
        });

        setDisplayProtocol(version?.entry?.reverse());
        setSelectedVersion(version?.entry?.length - 1);
      } catch (err) {
        console.log("err", err);
      } finally {
        setLoading(false);
      }
    }
    getProtocolVersion();
  }, [id]);

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: { borderRadius: "5px", padding: 4 },
      }}
      maxWidth="lg"
      onClose={(event, reason) => handleClose()}
    >
      <Stack width="950px" spacing={4}>
        {loading ? (
          <CircularLoading />
        ) : (
          <>
            <Typography
              textAlign="center"
              variant="h6"
              color="neutral1000.main"
              fontWeight={500}
            >
              {type === "delete"
                ? `Remover Protocolo`
                : `${options?.[selectedVersion]?.display}`}
            </Typography>

            {displayProtocol?.length > 1 ? (
              <Breadcrumbs aria-label="breadcrumb">
                {[...Array(displayProtocol?.length)]?.map((_, index) => {
                  console.log("v", index === selectedVersion);

                  return (
                    <Link
                      key={index}
                      color="inherit"
                      href="#"
                      sx={{
                        color:
                          index === selectedVersion ? "#007C6D" : "primary",
                      }}
                      onClick={(e: any) => {
                        e.preventDefault();
                        setSelectedVersion(index);
                      }}
                    >
                      Versão {index + 1}
                    </Link>
                  );
                })}
              </Breadcrumbs>
            ) : (
              <></>
            )}

            {type === "delete" && (
              <Typography
                textAlign="center"
                variant="body1"
                color="neutral1000.main"
                fontWeight={500}
              >
                {options?.[selectedVersion]?.display}
              </Typography>
            )}

            <Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  variant="body1"
                  color="neutral1000.main"
                  fontWeight={500}
                >
                  Status:
                </Typography>

                <Typography variant="body1">
                  {useDict.protocolStatus[options?.[selectedVersion]?.status]}
                </Typography>
              </Stack>
            </Stack>

            {options?.[selectedVersion]?.condition?.length ? (
              <Stack
                direction="row"
                spacing={2}
                mr={1}
                alignItems="center"
                sx={{ overflow: "auto" }}
              >
                <Typography
                  variant="body1"
                  color="neutral1000.main"
                  fontWeight={500}
                >
                  Diagnosticos associados
                </Typography>
                {options?.[selectedVersion]?.condition?.map(
                  (e: any, index: number) => (
                    <Chip
                      key={index}
                      label={`${e?.valueCoding?.code} - ${e?.valueCoding?.display}`} // Nome do profissional
                      color="primary" // Cor do chip
                      style={{ margin: "4px" }} // Espaçamento entre os chips
                    />
                  )
                )}
              </Stack>
            ) : (
              <></>
            )}

            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography
                variant="body1"
                color="neutral1000.main"
                fontWeight={500}
              >
                Autor da versão:
              </Typography>

              <Chip
                label={` Dr.
                ${useFormatter.formatNameDisplay(
                  options?.[selectedVersion]?.subject
                )}`} // Nome do profissional
                // Cor do chip
                style={{ margin: "4px" }} // Espaçamento entre os chips
              />
            </Stack>

            <Stack
              direction="row"
              spacing={1}
              mr={1}
              alignItems="center"
              sx={{ overflow: "auto" }}
            >
              <Typography
                variant="body1"
                color="neutral1000.main"
                fontWeight={500}
              >
                Profissionais envolvidos:{" "}
              </Typography>
              <Stack>{practitioners?.join(" , ")}</Stack>
            </Stack>

            <Stack mt={4} spacing={1} sx={{ overflow: "auto" }}>
              <Box sx={{ borderColor: "divider" }}>
                <Tabs
                  value={tabValue}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  {auxTabs?.map((e: any, i: number) => {
                    return <Tab label={e} {...a11yProps(i)} />;
                  })}
                </Tabs>

                {auxTabs?.map((e: any, i: number) => {
                  return (
                    <CustomTabPanel value={tabValue} index={i}>
                      {e === "Tratamentos" && (
                        <>
                          {auxTratamentos?.map((el: any, index: number) => {
                            const auxTag = tags.find((tag: any) =>
                              el.tag?.toUpperCase()?.includes(tag.name)
                            );
                            return (
                              <Stack
                                direction="row"
                                width="100%"
                                mb={1}
                                key={`${index}`}
                              >
                                <Stack
                                  p={1}
                                  gap={1}
                                  sx={{
                                    borderRadius: "5px",
                                    width: "100%",
                                  }}
                                >
                                  <Stack direction="row" gap={1}>
                                    <Typography
                                      variant="body1"
                                      color="neutral1000.main"
                                      fontWeight={500}
                                    >
                                      {index + 1}. {el.name}
                                    </Typography>

                                    {el.inputValue?.presentation && (
                                      <Typography
                                        variant="body1"
                                        color="neutral700.main"
                                      >
                                        {el.inputValue?.presentation}
                                      </Typography>
                                    )}

                                    {el.tag && (
                                      <Tooltip title={auxTag?.display}>
                                        <Button
                                          sx={{
                                            background:
                                              auxTag?.background ||
                                              auxTag?.color ||
                                              "white",

                                            width: "21px",
                                            minWidth: "21px",
                                            height: "22px",
                                            "&:hover": {
                                              backgroundColor:
                                                auxTag?.background ||
                                                auxTag?.color ||
                                                "white",
                                            },
                                          }}
                                          variant="contained"
                                        >
                                          <Typography
                                            sx={{
                                              color: auxTag?.background
                                                ? auxTag?.color
                                                : "black",
                                            }}
                                            variant="body1"
                                            color="neutral700.main"
                                          >
                                            {auxTag?.value || "-"}
                                          </Typography>
                                        </Button>
                                      </Tooltip>
                                    )}
                                  </Stack>

                                  {el.formValue?.label && (
                                    <Typography
                                      variant="body1"
                                      color="neutral700.main"
                                    >
                                      {el.form}
                                    </Typography>
                                  )}

                                  {el?.type === "service" &&
                                    el?.inputValue?.code && (
                                      <Typography
                                        variant="body1"
                                        color="neutral700.main"
                                      >
                                        Código TUSS: {el?.inputValue?.code}
                                      </Typography>
                                    )}

                                  {el?.category && (
                                    <Typography
                                      variant="body1"
                                      color="neutral700.main"
                                    >
                                      Categoria: {el?.category?.label}
                                    </Typography>
                                  )}

                                  {el.condition && (
                                    <Typography
                                      variant="body1"
                                      color="neutral700.main"
                                    >
                                      Condição: {el?.condition?.label}{" "}
                                      {!el?.condition?.name &&
                                        el.condition?.value &&
                                        `- CID10 ${el.condition?.value}`}
                                    </Typography>
                                  )}

                                  {el.note && (
                                    <Typography
                                      variant="body1"
                                      color="neutral700.main"
                                    >
                                      {el.note}
                                    </Typography>
                                  )}
                                </Stack>
                              </Stack>
                            );
                          })}
                        </>
                      )}

                      {e === "Exames" && (
                        <>
                          {auxService?.map((el: any, index: number) => {
                            const auxTag = tags.find((tag: any) =>
                              el.tag?.toUpperCase()?.includes(tag.name)
                            );
                            return (
                              <Stack
                                direction="row"
                                width="100%"
                                mb={1}
                                key={`${index}`}
                              >
                                <Stack
                                  p={1}
                                  gap={1}
                                  sx={{
                                    borderRadius: "5px",
                                    width: "100%",
                                  }}
                                >
                                  <Stack direction="row" gap={1}>
                                    <Typography
                                      variant="body1"
                                      color="neutral1000.main"
                                      fontWeight={500}
                                    >
                                      {index + 1}. {el.name}
                                    </Typography>

                                    {el.inputValue?.presentation && (
                                      <Typography
                                        variant="body1"
                                        color="neutral700.main"
                                      >
                                        {el.inputValue?.presentation}
                                      </Typography>
                                    )}

                                    {el.tag && (
                                      <Tooltip title={auxTag?.display}>
                                        <Button
                                          sx={{
                                            background:
                                              auxTag?.background ||
                                              auxTag?.color ||
                                              "white",

                                            width: "21px",
                                            minWidth: "21px",
                                            height: "22px",
                                            "&:hover": {
                                              backgroundColor:
                                                auxTag?.background ||
                                                auxTag?.color ||
                                                "white",
                                            },
                                          }}
                                          variant="contained"
                                        >
                                          <Typography
                                            sx={{
                                              color: auxTag?.background
                                                ? auxTag?.color
                                                : "black",
                                            }}
                                            variant="body1"
                                            color="neutral700.main"
                                          >
                                            {auxTag?.value || "-"}
                                          </Typography>
                                        </Button>
                                      </Tooltip>
                                    )}
                                  </Stack>

                                  {el.formValue?.label && (
                                    <Typography
                                      variant="body1"
                                      color="neutral700.main"
                                    >
                                      {el.form}
                                    </Typography>
                                  )}

                                  {el?.type === "service" &&
                                    el?.inputValue?.code && (
                                      <Typography
                                        variant="body1"
                                        color="neutral700.main"
                                      >
                                        Código TUSS: {el?.inputValue?.code}
                                      </Typography>
                                    )}

                                  {el?.category && (
                                    <Typography
                                      variant="body1"
                                      color="neutral700.main"
                                    >
                                      Categoria: {el?.category?.label}
                                    </Typography>
                                  )}

                                  {el.condition && (
                                    <Typography
                                      variant="body1"
                                      color="neutral700.main"
                                    >
                                      Condição: {el?.condition?.label}{" "}
                                      {!el?.condition?.name &&
                                        el.condition?.value &&
                                        `- CID10 ${el.condition?.value}`}
                                    </Typography>
                                  )}

                                  {el.note && (
                                    <Typography
                                      variant="body1"
                                      color="neutral700.main"
                                    >
                                      {el.note}
                                    </Typography>
                                  )}
                                </Stack>
                              </Stack>
                            );
                          })}
                        </>
                      )}

                      {e === "Procedimentos" && (
                        <>
                          {auxProcedure?.map((el: any, index: number) => {
                            const auxTag = tags.find((tag: any) =>
                              el.tag?.toUpperCase()?.includes(tag.name)
                            );
                            return (
                              <Stack
                                direction="row"
                                width="100%"
                                mb={1}
                                key={`${index}`}
                              >
                                <Stack
                                  p={1}
                                  gap={1}
                                  sx={{
                                    borderRadius: "5px",
                                    width: "100%",
                                  }}
                                >
                                  <Stack direction="row" gap={1}>
                                    <Typography
                                      variant="body1"
                                      color="neutral1000.main"
                                      fontWeight={500}
                                    >
                                      {index + 1}. {el.name}
                                    </Typography>

                                    {el.inputValue?.presentation && (
                                      <Typography
                                        variant="body1"
                                        color="neutral700.main"
                                      >
                                        {el.inputValue?.presentation}
                                      </Typography>
                                    )}

                                    {el.tag && (
                                      <Tooltip title={auxTag?.display}>
                                        <Button
                                          sx={{
                                            background:
                                              auxTag?.background ||
                                              auxTag?.color ||
                                              "white",

                                            width: "21px",
                                            minWidth: "21px",
                                            height: "22px",
                                            "&:hover": {
                                              backgroundColor:
                                                auxTag?.background ||
                                                auxTag?.color ||
                                                "white",
                                            },
                                          }}
                                          variant="contained"
                                        >
                                          <Typography
                                            sx={{
                                              color: auxTag?.background
                                                ? auxTag?.color
                                                : "black",
                                            }}
                                            variant="body1"
                                            color="neutral700.main"
                                          >
                                            {auxTag?.value || "-"}
                                          </Typography>
                                        </Button>
                                      </Tooltip>
                                    )}
                                  </Stack>

                                  {el.formValue?.label && (
                                    <Typography
                                      variant="body1"
                                      color="neutral700.main"
                                    >
                                      {el.form}
                                    </Typography>
                                  )}

                                  {el?.type === "service" &&
                                    el?.inputValue?.code && (
                                      <Typography
                                        variant="body1"
                                        color="neutral700.main"
                                      >
                                        Código TUSS: {el?.inputValue?.code}
                                      </Typography>
                                    )}

                                  {el?.category && (
                                    <Typography
                                      variant="body1"
                                      color="neutral700.main"
                                    >
                                      Categoria: {el?.category?.label}
                                    </Typography>
                                  )}

                                  {el.condition && (
                                    <Typography
                                      variant="body1"
                                      color="neutral700.main"
                                    >
                                      Condição: {el?.condition?.label}{" "}
                                      {!el?.condition?.name &&
                                        el.condition?.value &&
                                        `- CID10 ${el.condition?.value}`}
                                    </Typography>
                                  )}

                                  {el.note && (
                                    <Typography
                                      variant="body1"
                                      color="neutral700.main"
                                    >
                                      {el.note}
                                    </Typography>
                                  )}
                                </Stack>
                              </Stack>
                            );
                          })}
                        </>
                      )}

                      {e === "Relatórios" && (
                        <>
                          {auxCertificate?.map((el: any, index: number) => {
                            const auxTag = tags.find((tag: any) =>
                              el.tag?.toUpperCase()?.includes(tag.name)
                            );
                            return (
                              <Stack
                                direction="row"
                                width="100%"
                                mb={1}
                                key={`${index}`}
                              >
                                <Stack
                                  p={1}
                                  gap={1}
                                  sx={{
                                    borderRadius: "5px",
                                    width: "100%",
                                  }}
                                >
                                  <Stack direction="row" gap={1}>
                                    <Typography
                                      variant="body1"
                                      color="neutral1000.main"
                                      fontWeight={500}
                                    >
                                      {index + 1}. {el.name}
                                    </Typography>

                                    {el.inputValue?.presentation && (
                                      <Typography
                                        variant="body1"
                                        color="neutral700.main"
                                      >
                                        {el.inputValue?.presentation}
                                      </Typography>
                                    )}

                                    {el.tag && (
                                      <Tooltip title={auxTag?.display}>
                                        <Button
                                          sx={{
                                            background:
                                              auxTag?.background ||
                                              auxTag?.color ||
                                              "white",

                                            width: "21px",
                                            minWidth: "21px",
                                            height: "22px",
                                            "&:hover": {
                                              backgroundColor:
                                                auxTag?.background ||
                                                auxTag?.color ||
                                                "white",
                                            },
                                          }}
                                          variant="contained"
                                        >
                                          <Typography
                                            sx={{
                                              color: auxTag?.background
                                                ? auxTag?.color
                                                : "black",
                                            }}
                                            variant="body1"
                                            color="neutral700.main"
                                          >
                                            {auxTag?.value || "-"}
                                          </Typography>
                                        </Button>
                                      </Tooltip>
                                    )}
                                  </Stack>

                                  {el.formValue?.label && (
                                    <Typography
                                      variant="body1"
                                      color="neutral700.main"
                                    >
                                      {el.form}
                                    </Typography>
                                  )}

                                  {el?.type === "service" &&
                                    el?.inputValue?.code && (
                                      <Typography
                                        variant="body1"
                                        color="neutral700.main"
                                      >
                                        Código TUSS: {el?.inputValue?.code}
                                      </Typography>
                                    )}

                                  {el?.category && (
                                    <Typography
                                      variant="body1"
                                      color="neutral700.main"
                                    >
                                      Categoria: {el?.category?.label}
                                    </Typography>
                                  )}

                                  {el.condition && (
                                    <Typography
                                      variant="body1"
                                      color="neutral700.main"
                                    >
                                      Condição: {el?.condition?.label}{" "}
                                      {!el?.condition?.name &&
                                        el.condition?.value &&
                                        `- CID10 ${el.condition?.value}`}
                                    </Typography>
                                  )}

                                  {el.note && (
                                    <Typography
                                      variant="body1"
                                      color="neutral700.main"
                                    >
                                      {el.note}
                                    </Typography>
                                  )}
                                </Stack>
                              </Stack>
                            );
                          })}
                        </>
                      )}
                    </CustomTabPanel>
                  );
                })}
              </Box>

              {auxDescription ? (
                <Accordion
                  sx={{
                    border: "1px solid #ccc",
                    position: "initial",
                    marginBottom: "20px",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">Descrição</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
                      {auxDescription}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ) : (
                <></>
              )}

              {auxReferences ? (
                <Accordion
                  sx={{
                    border: "1px solid #ccc",
                    position: "initial",
                    marginBottom: "20px",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">Referencias</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
                      {auxReferences}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ) : (
                <></>
              )}
            </Stack>

            {type === "delete" ? (
              <FormButtonGroup
                onGoBackButtonClick={handleClose}
                onNextButtonClick={() => {
                  handleDelete(id);
                }}
                goBackButtonText="Cancelar"
                nextButtonText="Confirmar"
                loading={false}
              />
            ) : (
              <FormButtonGroup
                onGoBackButtonClick={handleClose}
                onNextButtonClick={() =>
                  navigate("/createprotocol", {
                    state: {
                      id,
                      defaultItens: options?.[selectedVersion],
                    },
                  })
                }
                goBackButtonText="Fechar"
                nextButtonText="Editar"
                loading={false}
              />
            )}
          </>
        )}
      </Stack>
    </Dialog>
  );
}
