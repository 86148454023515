import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import {
  NoDataWarning,
} from "../../../../../../../components/common";
import { useEffect, useState } from "react";
import { useSessionContext } from "../../../../../../../contexts/SessionContext";
import { useFormatter } from "../../../../../../../utils/useFormatter";
import { useLocationContext } from "../../../../../../../contexts/LocationContext";
import { useViewContext } from "../../../../../../../contexts/ViewContext";
import { useDatetime } from "../../../../../../../utils/useDatetime";
import { useManagerContext } from "../../../../../../../contexts/ManagerContext";
import { useFhirData } from "../../../../../../../utils/useFhirData";
import dayjs from "dayjs";
import { GetAppointment } from "../../../../../../../services/fhir";
import {
  AccessTime,
  Description,
  Event,
  PersonOutline,
  Phone,
  WhatsApp,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function cleanRepeatedList(list: any) {
  let cleanedList = new Set(list);
  return Array.from(cleanedList);
}

export function PastViewer() {
  const { displaySchedules, selectedDate } = useManagerContext();

  const { access } = useSessionContext();
  const { availableRoles } = useViewContext();
  const { formatDate } = useDatetime;

  const { location } = useLocationContext();
  const { formatNameDisplay } = useFormatter;
  const [pastAppointment, setPastAppointment] = useState<any>();
  const [patients, setPatients] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const { formatDateToISO } = useDatetime;

  const schedulesIds = displaySchedules
    ?.map((e: any) => e.resource?.id)
    ?.join(",");

  const practitioners = cleanRepeatedList(
    displaySchedules?.map(
      (e: any) =>
        useFhirData.findActor(e?.resource?.actor, "Practitioner")?.reference
    )
  )?.join(",");

  const navigate = useNavigate();

  const { formatDateTimeToHour } = useFormatter;

  const [page, setPage] = useState(1);
  // Número de itens por página
  const itemsPerPage = 6;

  // Função para mudar de página
  const handleChangePage = (event: any, value: any) => {
    setPage(value);
  };

  // Cálculo dos itens que serão exibidos com base na página
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = pastAppointment?.slice(startIndex, endIndex);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const startDate = formatDateToISO(selectedDate);

        if (schedulesIds) {
          const pastAppointmentResponse = await GetAppointment({
            schedule: schedulesIds,
            status: ["booked", "fulfilled"],
            startDate: startDate,
            includePatient: true,
            scheduling: true,
            professionalActor: practitioners,
          });

          const auxPastAppoitments = pastAppointmentResponse?.entry?.filter(
            (e: any) => {
              if (e.resource?.resourceType === "Appointment") {
                const appointmentDate = dayjs(e.resource?.end); // Data do compromisso

                // Filtro por dia

                return (
                  appointmentDate.isSame(selectedDate, "day") &&
                  (e.resource?.status === "fulfilled" ||
                    dayjs(e.resource?.end).isBefore(dayjs()))
                );

                // Filtro por mês
              }
              return false; // Não inclui no resultado se não for um Appointment
            }
          );

          const auxPastPatients = pastAppointmentResponse?.entry?.filter(
            (e: any) => e.resource?.resourceType === "Patient"
          );

          if (auxPastAppoitments?.length === 0) {
            setPastAppointment(undefined);
          } else {
            setPastAppointment(auxPastAppoitments);
            setPatients(auxPastPatients);
          }
        } else {
          setPastAppointment(undefined);
          setPatients(undefined);
        }
      } catch (err) {
        console.log("err", err);
      } finally {
        setLoading(false);
      }
    }
    if (access?.organizationId) fetchData();
  }, [
    access?.organizationId,
    selectedDate,

    formatDateToISO,
    displaySchedules,
    schedulesIds,
    practitioners,
  ]);

  return (
    <Box mt={3}>
      <Grid container spacing={2}>
        {paginatedData?.map((e: any, index: number) => {
          const link = `https://teleatendimento.fluxmed.com.br/Teleconsulta-${e?.resource?.id}`;
          const healthcare = useFhirData.findParticipant(
            e?.resource?.participant,
            "HealthcareService"
          );
          const patient = useFhirData.findParticipant(
            e?.resource?.participant,
            "Patient"
          );
          const appointmentLocation = useFhirData.findParticipant(
            e?.resource?.participant,
            "Location"
          );
          const auxVirtualService = useFhirData.virtualService(
            location?.find((loc: any) =>
              loc?.resource?.id.includes(
                appointmentLocation?.reference?.split("/")?.[1]
              )
            )?.resource
          );

          const auxLocation = location?.find((loc: any) =>
            loc?.resource?.id.includes(
              appointmentLocation?.reference?.split("/")?.[1]
            )
          )?.resource;

          const address = auxLocation?.address?.line?.join(", ");

          const findPatient = patients?.find((e: any) =>
            patient?.reference.includes(e?.resource?.id)
          )?.resource;
          const patientId = e?.reference?.participant?.split("-")?.[1];
          const auxSchedule = displaySchedules?.find((el: any) => {
            const auxScheduleHealthCare = useFhirData.findActor(
              el?.resource?.actor,
              "HealthcareService"
            );
            return auxScheduleHealthCare?.reference === healthcare?.reference;
          });
          const practitioner = useFhirData.findParticipant(
            e?.resource?.participant,
            "Practitioner"
          )?.display;
          const date = dayjs(e?.resource?.start)?.format("DD/MM/YYYY") || "";
          const hour = e?.resource?.start
            ? formatDateTimeToHour(e?.resource.start)
            : "";
          const lembreteMessage = auxVirtualService
            ? `Olá ${findPatient?.name?.[0]?.text}. Você possui uma teleconsulta agendada para ${date} - ${hour} com Dr. ${practitioner} na especialidade de ${healthcare}. Para acessar a videoconferênciar por favor acesse o link: ${link}. Acesse https://app.fluxmed.com.br, e faça seu cadastro para acessar seus registros eletrônicos de saúde, agendar consultas e muito mais.`
            : `Olá ${findPatient?.name?.[0]?.text}. Você possui uma teleconsulta agendada para ${date} - ${hour} com Dr. ${practitioner} na especialidade de ${healthcare}. Endereço: ${address}. Acesse https://app.fluxmed.com.br, e faça seu cadastro para acessar seus registros eletrônicos de saúde, agendar consultas e muito mais.`;
          const phone = findPatient?.telecom?.find(
            (e: any) => e.system === "phone"
          )?.value;

          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  border: "1px solid",
                  borderColor: "neutral100.main",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  padding: 0.5,
                  backgroundColor: "neutral0.main",
                }}
              >
                <CardContent sx={{ paddingBottom: 0 }}>
                  <Stack spacing={1}>
                    <Stack mb={2} direction="row" alignItems="center" flex={1}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        width="100%"
                      >
                        <Typography fontSize={20} variant="body1">
                          {date}
                        </Typography>

                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          pl={3}
                        >
                          <AccessTime />
                          <Typography fontSize={20} variant="body1">
                            {hour}
                          </Typography>
                        </Stack>

                        <Stack
                          width="100%"
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Typography variant="body1" color="text.secondary">
                            {healthcare?.display}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>

                    <Stack direction="row" alignItems="center" spacing={1}>
                      <PersonOutline sx={{ color: "primary.main", mr: 1 }} />

                      <Typography fontSize={18} variant="body1">
                        {formatNameDisplay(findPatient?.name?.[0]?.text)}
                      </Typography>
                    </Stack>

                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Event sx={{ color: "primary.main", mr: 1 }} />

                      <Typography fontSize={18} variant="body1">
                        {dayjs(findPatient?.birthDate).isValid()
                          ? `${formatDate(
                              findPatient?.birthDate
                            )} - ${useFormatter.formatAge(
                              findPatient?.birthDate
                            )} anos`
                          : findPatient?.birthDate}
                      </Typography>
                    </Stack>

                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Phone sx={{ color: "primary.main", mr: 1 }} />

                      <Typography fontSize={18} variant="body1">
                        {useFormatter.formatPhoneNumber(phone || "")}
                      </Typography>
                    </Stack>

                    <Divider sx={{ my: 2 }}></Divider>
                    {/* <Stack width="fit-content">
                    <Chip
                      label={healthcare?.display}
                      size="small"
                      sx={{
                        color: "neutral700.main",
                        fontWeight: 500,
                        fontStyle: "italic",
                        fontSize: 16,

                        // backgroundColor: "#1B1F27",
                      }}
                    />
                  </Stack> */}

                    <Typography
                      color="neutral700.main"
                      sx={{
                        fontWeight: 500,
                        fontStyle: "italic",
                        fontSize: 16,
                      }}
                    >
                      Dr. {practitioner}
                    </Typography>

                    {!auxVirtualService ? (
                      <Typography variant="body1" color="text.secondary">
                        {address}
                      </Typography>
                    ) : (
                      <Typography variant="body1" color="text.secondary">
                        Atendimento remoto
                      </Typography>
                    )}
                  </Stack>
                </CardContent>

                <CardActions
                  sx={{ justifyContent: "space-between", alignItems: "center" }}
                >
                  {availableRoles?.includes("practitioner") && (
                    <Button
                      startIcon={<Description />}
                      onClick={() => {
                        if (findPatient?.id?.split("-")?.[1])
                          navigate("/summary", {
                            state: {
                              patientId: findPatient?.id?.split("-")?.[1],
                              appointment: e?.resource,
                            },
                          });
                      }}
                    >
                      Registros clínicos
                    </Button>
                  )}

                  <Button
                    startIcon={<WhatsApp />}
                    onClick={() =>
                      window.open(
                        `https://web.whatsapp.com/send?phone=+55${phone}`,
                        "_blank"
                      )
                    }
                  >
                    Entrar em contato
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        }) || <NoDataWarning />}
      </Grid>

      {paginatedData && (
        <Pagination
          count={Math.ceil(pastAppointment?.length / itemsPerPage)} // Número total de páginas
          page={page} // Página atual
          onChange={handleChangePage} // Função de mudança de página
          sx={{ display: "flex", justifyContent: "center", marginTop: "16px" }}
        />
      )}
    </Box>
  );
}
