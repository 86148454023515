type Observations = "55284-4" | "8302-2" | "29463-7";

export const lastEncounterObservationsData = ({
  encounters,
  observations,
}: {
  encounters: any;
  observations?: Observations[];
}) => {
  const lastObservations: any[] = [];

  encounters?.forEach((encounter: any) => {
    const encounterId = encounter?.encounter?.resource?.id;
    const status = encounter?.encounter?.resource?.status;
    const date = encounter?.encounter?.resource?.actualPeriod.start;
    const practitioner = {
      professional: encounter?.encounter?.resource?.participant?.find(
        (participant: any) =>
          participant?.actor?.reference?.includes("Practitioner")
      )?.actor?.display,
      healthcareService:
        encounter?.encounter?.resource?.serviceType?.[0]?.reference?.display,
    };

    encounter?.records?.forEach((observation: any) => {
      const dateObservation = observation?.resource?.effectiveDateTime;
      observations?.forEach((typeObservation: Observations) => {
        let valueObservation =
          observation?.resource?.code?.coding?.[0]?.code === typeObservation
            ? {
                name: observation?.resource?.code?.text,
                value: observation?.resource?.valueQuantity?.value,
                unit: observation?.resource?.valueQuantity?.unit,
              }
            : null;

        if (
          observation?.resource?.component &&
          observation?.resource?.code?.coding?.[0]?.code === typeObservation
        ) {
          valueObservation = {
            name: observation?.resource?.code?.text,
            value: [
              observation?.resource?.component?.[0]?.valueQuantity?.value,
              observation?.resource?.component?.[1]?.valueQuantity?.value,
            ],
            unit: [observation?.resource?.component?.[0]?.valueQuantity?.unit],
          };
        }

        if (valueObservation) {
          const existingObservationIndex = lastObservations.findIndex(
            (obs) => obs.typeObservation === typeObservation
          );
          if (
            existingObservationIndex === -1 ||
            new Date(dateObservation) >
              new Date(
                lastObservations[existingObservationIndex].dateObservation
              )
          ) {
            const newObservation = {
              typeObservation,
              encounterId,
              practitioner,
              status,
              date,
              dateObservation,
              ...valueObservation,
            };

            if (existingObservationIndex === -1) {
              lastObservations.push(newObservation);
            } else {
              lastObservations[existingObservationIndex] = newObservation;
            }
          }
        }
      });
    });
  });

  return lastObservations;
};
