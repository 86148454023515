import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Stack, Typography } from "@mui/material";
import { HelpOutline } from "@mui/icons-material";

import { ContentBoxTitle } from "../../../components/common";
import {
  FormButtonGroup,
  FormStack,
  FormTextInput,
} from "../../../components/form";
import { useSessionContext } from "../../../contexts/SessionContext";
import { useRegex } from "../../../utils/useRegex";
import { Validate } from "../../../services/fhir";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useFormatter } from "../../../utils/useFormatter";
import { IFormInput } from "../types";
import { handleKeyPress } from "../../../utils/useKeyPress";
import { ReviewSubscription } from "./ReviewSubscription";
import { oids } from "../../../configs/Settings";
import logoHeader from "../../../assets/fluxmed-logo-horizontal.png";

interface IProps {
  getValue: any;
  control: any;
  handleSubmit: Function;
  navigateToNextPage: Function;
  setValue: any;
}

export function FormSubscription({
  getValue,
  handleSubmit,
  control,
  navigateToNextPage,
  setValue,
}: IProps) {
  const { user } = useSessionContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<number>(0);

  const onSubmit = async (data: IFormInput) => {
    setLoading(true);
    try {
      const validation = await Validate({
        cpf: user.username,
        cnpj: `${data.cnpjValue.replace(/[^a-zA-Z0-9]/g, "")}`,
      });
      if (validation?.status === HTTPResponseStatus.Conflict) {
        toast.warn(validation?.data);
        navigate("/");
        return;
      }
      if (validation?.status !== HTTPResponseStatus.Created) {
        toast.error(validation?.data);
        return;
      }
      setValue("organization", validation.data);

      localStorage.setItem(
        "organizationValue",
        `${oids.cnpj}-${data.cnpjValue.replace(/[^a-zA-Z0-9]/g, "")}`
      );

      setStep((prev: number) => prev + 1);
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {step === 0 && (
        <form
          onKeyDown={(event) =>
            handleKeyPress(event, false, handleSubmit(onSubmit))
          }
          style={{ height: "50%", width: "50%" }}
        >
          <FormStack>
            <img
              src={logoHeader}
              alt="Logo fluxmed"
              width={185}
              height="auto"
            />
            <ContentBoxTitle>Registrar clínica</ContentBoxTitle>
            <Typography variant="body1" textAlign="center">
              Insira o CNPJ da sua organização de saúde
            </Typography>
            <Stack width="100%">
              <Stack
                sx={{
                  display: "grid",
                  gridTemplateColumns: "100% 20px",
                }}
                width="100%"
                gap={2}
                alignItems="center"
              >
                <FormTextInput
                  name="cnpjValue"
                  control={control}
                  label="CNPJ"
                  required
                  pattern={useRegex.cnpj}
                  mask={useFormatter.formatCNPJ}
                />
              </Stack>
              <Stack width="100%" mt={3}>
                <FormButtonGroup
                  onGoBackButtonClick={() => navigate(-1)}
                  onNextButtonClick={handleSubmit(onSubmit)}
                  goBackButtonText="Voltar"
                  nextButtonText="Próximo"
                  loading={loading}
                />
              </Stack>
            </Stack>
          </FormStack>
        </form>
      )}
      {step === 1 && (
        <ReviewSubscription
          organization={getValue("organization")}
          onBackButtonClick={() => setStep((prev: number) => prev - 1)}
          onNextButtonClick={navigateToNextPage}
        />
      )}
    </>
  );
}
