import { AccordionSummary, Accordion, AccordionDetails } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useState } from "react";
import { GenericTable } from "../../../components/GenericTable/GenericTable";
import { ConsentActions } from "./ConsentActions";
import dayjs from "dayjs";

interface IConsentAccordion {
  defaultOpen?: boolean;
  title: string;
  type: string;
  selectedTab: string;
  data: any;
}

export function ConsentAccordion({
  defaultOpen,
  title,
  type,
  data,
  selectedTab,
}: IConsentAccordion) {
  const [openAccordion, setOpenAccordion] = useState<boolean>(
    defaultOpen || false
  );

  const columns =
    selectedTab === "3"
      ? [
          {
            column: "Documento",
            rowName: "identifier",
          },
          {
            column: "Profissional",
            rowName: "name",
          },
          { column: "Data", rowName: "date" },
          { column: "Hora", rowName: "hour" },
          { column: "Validade", rowName: "vigency" },
        ]
      : [
          {
            column: selectedTab === "1" ? "CPF" : "CNES ou CPNJ",
            rowName: "identifier",
          },
          { column: "Nome", rowName: "name" },

          { column: "Data", rowName: "date" },
          { column: "Hora", rowName: "hour" },
          { column: "Validade", rowName: "vigency" },
        ];

  return (
    <Accordion
      sx={{ width: "100%" }}
      expanded={openAccordion}
      onChange={() => setOpenAccordion((prev: boolean) => !prev)}
    >
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {title}
      </AccordionSummary>

      <AccordionDetails>
        <GenericTable
          tableColumns={
            type === "granted"
              ? columns
              : [
                  ...columns,
                  {
                    column: "Status",
                    rowName: "status",
                  },
                ]
          }
          tableRows={data?.sort((a: any, b: any) => {
            const dateA = dayjs(a?.fullDate);
            const dateB = dayjs(b?.fullDate);
            return dateB.diff(dateA);
          })}
          actions={(rowData: any) => (
            <ConsentActions row={rowData} type={type} />
          )}
        />
      </AccordionDetails>
    </Accordion>
  );
}
