import { Box, Typography } from "@mui/material";
import { PrimaryButton } from "../../../components/common"; // Certifique-se de que o caminho de importação está correto
import { endpoints } from "../../../configs/Settings";

interface IProps {
  paymentLink: string;
}

const voltarPortal = () => {
  window.location.href = `${endpoints.XDSB_domain}/portal/`;
};

export function PaymentStep({ paymentLink }: IProps) {
  return (
    <Box sx={{ width: "100%", maxWidth: "900px", margin: "0 auto" }}>
      <Typography
        variant="h6"
        align="center"
        gutterBottom
        sx={{ marginTop: "32px" }}
      >
        Realizar pagamento
      </Typography>
      <Box
        component="iframe"
        src={paymentLink}
        width="100%"
        height="600px"
        frameBorder="0"
        sx={{ border: "none", marginTop: "16px" }}
      />
      {/* Usando Box com display flex para centralizar o botão */}
      <Box
        sx={{ display: "flex", justifyContent: "center", paddingTop: "40px" }}
      >
        <PrimaryButton width="200px" height="40px" onClick={voltarPortal}>
          Acessar fluxmed
        </PrimaryButton>
      </Box>
    </Box>
  );
}
