import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { GetLocation } from "../services/fhir";
import { useSessionContext } from "./SessionContext";

interface LocationContextValue {
  location: fhir5.BundleEntry<fhir5.Location>[] | undefined;
  refetchLocation: Function;
  locationLoading: boolean;
  setIdsOrganizations: Dispatch<SetStateAction<string>>;
}

export const LocationContext = createContext<LocationContextValue>(null!);

export function LocationContextProvider({ children }: { children: ReactNode }) {
  const { access } = useSessionContext();

  const [location, setLocation] =
    useState<fhir5.BundleEntry<fhir5.Location>[]>();
  const [refetch, setRefetch] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [idsOrganizations, setIdsOrganizations] = useState<string>("");

  function refetchLocation() {
    setRefetch(!refetch);
  }

  useEffect(() => {
    setLoading(true);
    async function fetchLocation() {
      const typeSearch =
        access?.type === "patient" && idsOrganizations
          ? { ids: idsOrganizations }
          : { organization: access?.organizationId };
      const locationResponse = await GetLocation(typeSearch);
      setLocation(locationResponse?.entry);
    }
    try {
      if (access?.type && (idsOrganizations || access?.organizationId))
        fetchLocation();
    } catch (err) {
      console.log("Location", err);
    } finally {
      setLoading(false);
    }
  }, [access, refetch, idsOrganizations]);

  const contextValue = {
    location,
    refetchLocation,
    locationLoading: loading,
    setIdsOrganizations,
  };

  return (
    <LocationContext.Provider value={contextValue}>
      {children}
    </LocationContext.Provider>
  );
}

export function useLocationContext() {
  return useContext(LocationContext);
}
