import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSessionContext } from "../../../../../contexts/SessionContext";
import dayjs from "dayjs";
import { GetDocumentReference } from "../../../../../services/fhir/patientsummary/documentreference/GetDocumentReference";
import { BundlePatientSummary } from "../../../../../services/fhir";
import { useFhirData } from "../../../../../utils/useFhirData";
import imgMedication from "../../../assets/medicationAux.svg";
import { ControlPrescriṕtions } from "../../ControlPrescriptions";
import { isMobile } from "react-device-detect";

export function ControlMedications({ open, setOpen }: any) {
  const { user } = useSessionContext();
  const methods = useForm({});
  const { control } = methods;
  const [refetchMedication, setRefetchMedication] = useState<boolean>(false);
  const [loadingPrescription, setLoadingPrescription] =
    useState<boolean>(false);
  const [medicationRequest, setMedicationRequest] = useState<any>();

  const medicationData = medicationRequest
    // ?.filter((medication: any) =>
    //   dayjs(medication?.resource?.effectiveDosePeriod?.end).isAfter(dayjs())
    // )
    ?.map((el: any) => ({
      type: "medication",
      name: `${el?.resource?.medication?.concept?.text} ` || "",

      date: dayjs(el?.resource?.effectiveDosePeriod?.start).format(
        "DD/MM/YYYY"
      ),
      fullResource: el?.resource,
    }));

  useEffect(() => {
    setLoadingPrescription(true);
    async function fetchPrescriptions() {
      const response = await GetDocumentReference({
        patient: user.username,
        category: "prescricao",
      });

      const bundleResponse = await BundlePatientSummary({
        identifier: user?.username,
        entries: ["MedicationRequest"],
      });

      const declaratedMedications =
        useFhirData
          .extractPatientSummaryResource(
            bundleResponse,
            "MedicationRequest"
          )?.[0]
          ?.entry?.filter((el: any) => el?.resource?.informationSource) || [];

      const medicationRequest =
        response?.entry?.filter(
          (el: any) => el?.resource?.resourceType === "MedicationRequest"
        ) || [];

      setMedicationRequest([...declaratedMedications, ...medicationRequest]);
      setLoadingPrescription(false);
    }
    fetchPrescriptions();
  }, [user.username, refetchMedication]);
  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "10px",
          padding: 2,
          maxWidth: "700px",
          height: isMobile ? "100%" : "fit-content",
          maxHeight: isMobile ? "none" : "600px",
        },
      }}
      fullScreen={isMobile}
      maxWidth="sm"
      fullWidth
      onClose={() => setOpen(false)}
      open={open}
    >
      <IconButton
        onClick={() => setOpen(false)}
        sx={{ position: "absolute", right: 5, top: 5 }}
      >
        <Close />
      </IconButton>
      <DialogTitle textAlign="left">Controle de medicamentos</DialogTitle>
      <DialogContent>
        <ControlPrescriṕtions
          image={imgMedication}
          text="Meus Medicamentos"
          CardType="medication"
          control={control}
          data={medicationData}
          setRefetch={setRefetchMedication}
          loading={loadingPrescription}
        />
      </DialogContent>
    </Dialog>
  );
}
