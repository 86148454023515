import { NotesRounded, Person, Settings } from "@mui/icons-material";
import { IFeatures } from "../../utils/interfaces";

import { useSessionContext } from "../../contexts/SessionContext";
import { ManagerProtocol } from "./ManagerProtocol";

export function useManageProtocol() {
  const { access } = useSessionContext();
  const configs: IFeatures = {
    active: access.type === "professional",
    path: "/manageprotocol",
    icon: Settings,
    text: "Gerenciar protocolos",

    subitem: true,
    parent: "Protocolos clínicos",

    sideMenu: true,
    sideMenuIndex: 0,

    component: () => <ManagerProtocol />,
  };

  return configs;
}
