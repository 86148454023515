import { Controller } from "react-hook-form";
import VerificationInput from "react-verification-input";

import "./stylecodeverification.css";
import { FormInputProps } from "../../../../components/types";

export function FormInputValidationNumber({
  name,
  control,
  label,
  required,
  pattern,
  autoFocus,
}: FormInputProps) {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required,
        pattern,
      }}
      render={({ field: { onChange, value } }) => (
        <VerificationInput
          classNames={{
            container: "container",
            character: "character",
            characterSelected: "characterSelected",
            characterInactive: "characterInactive",
          }}
          autoFocus={autoFocus}
          placeholder=""
          validChars="0-9"
          inputProps={{ inputMode: "numeric" }}
          length={6}
          onChange={onChange}
          value={value}
        />
      )}
    />
  );
}
