import React, { useEffect, useState } from "react";
// import { Steps } from "../../Types";
import { FormProvider, useForm } from "react-hook-form";
import { SmallContentBox } from "../../../../components/common";
import ProfessionalForm from "../ProfessionalForm";
import { GetValueSet } from "../../../../services/terminology";
import { HTTPResponseStatus } from "../../../../utils/enum";
import { IFormInput, ISpecialty } from "../../Types";

const defaultValues = {
  crm: undefined,
  uf: "",
  especiality: "",
};

export function Form() {
  const methods = useForm<IFormInput>({ defaultValues });
  const { handleSubmit, control } = methods;
  const [loading, setLoading] = useState(false);
  const [specialties, setSpecialties] = useState([
    { value: "", label: "Carregando" },
  ]);

  useEffect(() => {
    async function asyncCall() {
      setLoading(true);
      const EspecialidadeMedicaValueSet = await GetValueSet({
        valueset: "EspecialidadeMedica",
      }).then((response) => {
        if (response?.status === HTTPResponseStatus.Success) {
          return response.data.expansion.contains;
        }
        return [];
      });

      setSpecialties(
        EspecialidadeMedicaValueSet.map((item: ISpecialty) => ({
          value: item.code,
          label: item.display,
        }))
      );
      setLoading(false);
    }
    asyncCall();
  }, []);

  return (
    <FormProvider {...methods}>
      <form style={{ height: "100%", width: "100%" }}>
        <SmallContentBox>
          <ProfessionalForm
            onNextButtonClick={handleSubmit}
            control={control}
            specialties={specialties}
            loading={loading}
          />
        </SmallContentBox>
      </form>
    </FormProvider>
  );
}
