import { NoteAdd, Person } from "@mui/icons-material";
import { Fab, Stack, Tooltip } from "@mui/material";
import { ReactEventHandler } from "react";

export function PrescriptionButton({
  handleClickPrescription,
  handleClickSummary,
}: {
  handleClickPrescription: ReactEventHandler;
  handleClickSummary: ReactEventHandler;
}) {
  return (
    <Stack
      sx={{
        position: "fixed",
        top: "20%",
        right: 16,
        zIndex: 9999,
        width: "fit-content",
        height: "100%",
      }}
      spacing={6}
    >
      <Tooltip title="Paciente">
        <Fab color="primary" aria-label="add" onClick={handleClickSummary}>
          <Person />
        </Fab>
      </Tooltip>

      <Tooltip title="Abrir prescrição">
        <Fab color="primary" aria-label="add" onClick={handleClickPrescription}>
          <NoteAdd />
        </Fab>
      </Tooltip>
    </Stack>
  );
}
