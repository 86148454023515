import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { GenericTable } from "../../../components/GenericTable/GenericTable";
import { LayoutPdfActions } from "./LayoutPdfActions";

import { FormButtonGroup } from "../../../components/form";
import { useNavigate } from "react-router-dom";
import { usePractitionerRoleContext } from "../../../contexts/PractitionerRoleContext";
import { useState } from "react";
import { Close, Warning } from "@mui/icons-material";
import { CommonDialog } from "../../../components/common/CommonDialog";
import { DeleteQuestionnaireResponse } from "../../../services/fhir/questionnaireresponse/DeleteQuestionnaireResponse";
import { toast } from "react-toastify";

interface IProps {
  dataRegister: any;
  setOpenModal: any;
  loading: any;
  setLoading: any;
  setRefetch: any;
}

const translateTypeDocument: any = {
  simple: "Receita simples",
  special: "Receita especial",
  procedure: "Procedimentos",
  exam: "Solicitação de exame",
  certificate: "Relatório",
};

export function DocumentsRegistered({
  dataRegister,
  setOpenModal,
  loading,
  setLoading,
  setRefetch,
}: IProps) {
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const { practitioner } = usePractitionerRoleContext();

  const [idDelete, setIdDelete] = useState("");
  const [base64, setBase64] = useState({
    contentType: undefined,
    data: undefined,
  });

  const listRecommendations = [
    "Para inserir um novo documento clique no botão inferior direito Adicionar modelo",
    "O modelo deve estar na extensão JPG ou PNG.",
    "O cabeçalho deve ter no máximo 6cm e o rodapé também deve ter no máximo 6cm de uma folha a4 para não sobrescrever o conteúdo central.",
    <>
      Recomendamos o site{" "}
      <a
        href="https://www.canva.com/pt_br/modelos/?query=template-receitu%C3%A1rio-m%C3%A9dico&pricing=FREE"
        target="_blank"
        rel="noopener noreferrer"
      >
        Canva
      </a>{" "}
      para criação dos modelos personalizados.
    </>,
  ];

  const columns = [
    {
      column: "Tipo de documento",
      rowName: "type",
    },
    { column: "Nome", rowName: "name" },
    { column: "Profissional", rowName: "practitioner" },
  ];

  const rows =
    dataRegister?.map((el: any) => {
      return {
        type: translateTypeDocument[
          el?.resource?.item.filter(
            (aux: any) => aux.linkId === "documentType"
          )?.[0]?.answer?.[0].valueString
        ],
        name: el?.resource?.item.filter(
          (aux: any) => aux.linkId === "documentName"
        )?.[0]?.answer?.[0].valueString,
        practitioner: practitioner?.filter(
          (auxEl: any) =>
            auxEl?.resource?.id ===
            el?.resource?.source?.reference?.split("/")?.[1]
        )?.[0]?.resource?.name?.[0]?.text,
        id: el?.resource?.id,
        base64: {
          contentType: el?.resource?.item.filter(
            (aux: any) => aux.linkId === "documentBase64"
          )?.[0]?.answer?.[0].valueAttachment?.contentType,
          data: el?.resource?.item.filter(
            (aux: any) => aux.linkId === "documentBase64"
          )?.[0]?.answer?.[0].valueAttachment?.data,
        },
      };
    }) || [];

  const onSubmit = async (id: string) => {
    try {
      await DeleteQuestionnaireResponse(id);
    } catch (err) {
      console.log("err", err);
      return;
    }
    toast.success("Documento removido com sucesso!");
    setRefetch(true);
    setModalDelete(false);
  };

  return (
    <Stack width="100%" height="100%">
      <Stack flex={1} mb={4}>
        <Typography variant="body1" color="neutral700.main" textAlign="center">
          Visualize, adicione ou remova modelos personalizados de documentos.
        </Typography>

        <Stack width="100%" mt={5}>
          {loading ? (
            <Skeleton variant="rectangular" height={400} />
          ) : rows.length ? (
            <GenericTable
              tableColumns={columns}
              tableRows={rows}
              actions={(row: any) => (
                <LayoutPdfActions
                  row={row}
                  setModalOpen={setModalOpen}
                  setModalDelete={setModalDelete}
                  setBase64={setBase64}
                  setIdDelete={setIdDelete}
                />
              )}
            />
          ) : (
            <Stack width="100%" height="100%" minHeight={500}>
              {/* <NoDataWarning /> */}
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                mt={2}
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  borderRadius={2}
                  sx={{ border: "1px solid", borderColor: "neutral400.main" }}
                  width="60%"
                  p={2}
                >
                  <Warning sx={{ color: "warning500.main" }} />
                  <Typography
                    variant="body1"
                    fontWeight={600}
                    color="neutral700.main"
                    textAlign="center"
                  >
                    Você ainda não possui modelos
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  width="60%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <List sx={{ listStyleType: "disc", paddingLeft: 2 }}>
                    {listRecommendations.map((el: any, index: number) => (
                      <ListItem key={index} sx={{ display: "list-item" }}>
                        <ListItemText primary={el} />
                      </ListItem>
                    ))}
                  </List>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
        <Stack width="100%" direction="row-reverse" mt={2}>
          <FormButtonGroup
            onGoBackButtonClick={() => navigate(-1)}
            onNextButtonClick={() => setOpenModal(true)}
            goBackButtonText="Voltar"
            nextButtonText="Adicionar modelo"
            maxWidth
          />
        </Stack>
      </Stack>
      {modalOpen && (
        <Dialog
          maxWidth="sm"
          fullWidth
          onClose={() => setModalOpen(false)}
          open={modalOpen}
          PaperProps={{
            sx: {
              borderRadius: "10px",
              padding: 2,
              maxHeight: "100vh",
            },
          }}
        >
          <IconButton
            onClick={() => setModalOpen(false)}
            sx={{ position: "absolute", right: 5, top: 5 }}
          >
            <Close />
          </IconButton>
          <DialogContent sx={{ padding: 0 }}>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={`data:${base64.contentType};base64,${base64?.data}`}
                alt="Documento"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {modalDelete && (
        <CommonDialog
          handleClose={() => setModalDelete(false)}
          isOpen={modalDelete}
        >
          <DialogTitle fontWeight={600} textAlign="center">
            Excluir documento
          </DialogTitle>
          <DialogContent>
            <Typography textAlign="center" variant="subtitle2">
              Deseja confirmar a operação?
            </Typography>
            <Stack
              minWidth="100%"
              mt={3}
              direction="row"
              justifyContent="space-between"
              spacing={2}
            >
              <FormButtonGroup
                onGoBackButtonClick={() => setModalDelete(false)}
                onNextButtonClick={() => onSubmit(idDelete)}
                goBackButtonText="Não"
                nextButtonText="Sim"
                loading={loading}
              />
            </Stack>
          </DialogContent>
        </CommonDialog>
      )}
    </Stack>
  );
}
