import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useFhirData } from "../../../../../../utils/useFhirData";
import { useDatetime } from "../../../../../../utils/useDatetime";
import { useDict } from "../../../../../../utils/useDict";
import { NoDataWarning } from "../../../../../../components/common";
import { useEffect, useState } from "react";
import { usePrescriptionAttendenceContext } from "../../../../../../contexts/PrescriptionAttendenceContext";

interface IProps {
  data: any;
}

export function RenderDiagnostics({ data }: IProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(3);

  const handleChangePage = (event: any, newPage: any) => {
    setCurrentPage(newPage);
  };

  const handleChangeItemsPerPage = (event: any) => {
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const { setTabSelected, setIsOpenDiagnostic, setDiagnosticValue } =
    usePrescriptionAttendenceContext();

  const startIndex = (currentPage - 1) * itemsPerPage;
  const selectedItems = data.slice(startIndex, startIndex + itemsPerPage);

  return (
    <>
      {data && data?.length > 0 ? (
        <>
          {selectedItems?.map((element: any) => {
            const code = element?.resource?.code?.coding?.[0]?.code;
            const description =
              element?.resource?.code?.coding?.[0]?.display || "";
            const clinicalStatus =
              useDict.clinicalStatus[
                element?.resource?.clinicalStatus?.coding?.[0]?.code
              ] || "";
            const verificationStatus =
              useDict.verificationStatus[
                element?.resource?.verificationStatus?.coding?.[0]?.code
              ] || "";
            const date = useDatetime.formatToDDMMYYYY(
              dayjs(element?.resource?.recordedDate)
            );
            const practitioner = useFhirData.findParticipant(
              element?.resource?.participant,
              "Practitioner"
            )?.display;
            const informant = useFhirData.findParticipant(
              element?.resource?.participant,
              "Patient"
            )
              ? "Paciente"
              : "Profissional";

            return (
              <Accordion
                sx={{
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                    boxShadow: "1px 1px 4px 1px rgba(0, 0, 0, 0.1)",
                  },
                  boxShadow: "none",
                  border: "0.2px solid rgba(0, 0, 0, 0.2)",
                  borderRadius: "16px",
                  marginBottom: 2,
                }}
              >
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        gap: 2,
                        marginBottom: 1,
                      }}
                    >
                      <Typography
                        align="center"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                        }}
                      >
                        {description}
                      </Typography>
                      <Chip
                        label={code}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Chip
                        label={clinicalStatus}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                          width: "100%",
                        }}
                        onClick={() => {
                          setTabSelected("2");
                          setDiagnosticValue({
                            label: description,
                            code: code,
                          });
                          setIsOpenDiagnostic(true);
                        }}
                      />
                      <Chip
                        label={verificationStatus}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                          width: "100%",
                        }}
                        onClick={() => {
                          setTabSelected("2");
                          setDiagnosticValue({
                            label: description,
                            code: code,
                          });
                          setIsOpenDiagnostic(true);
                        }}
                      />
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails></AccordionDetails>
              </Accordion>
            );
          })}
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>
              {" "}
              Mostrando {startIndex + 1} -{" "}
              {Math.min(startIndex + itemsPerPage, data.length)} de{" "}
              {data.length} itens{" "}
            </Typography>
            <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
              <InputLabel>Items por página</InputLabel>
              <Select
                value={itemsPerPage}
                onChange={handleChangeItemsPerPage}
                label="Items por página"
              >
                {[3, 5, 10].map((count) => (
                  <MenuItem key={count} value={count}>
                    {count}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Pagination
            count={Math.ceil(data.length / itemsPerPage)}
            page={currentPage}
            onChange={handleChangePage}
            sx={{ mt: 2, display: "flex", justifyContent: "center" }}
          />
        </>
      ) : (
        <>
          <NoDataWarning />
        </>
      )}
    </>
  );
}
