import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { IPatchOperation } from "../../../utils/interfaces";
import { auxiliarData } from "../AuxiliarData";
import { useAuth } from "../../../utils/useAuth";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAxios } from "../../../utils/useAxios";
import { OAuthApplication } from "../../auth";

interface IProps {
  resourceType: string;
  resourceId: string;
  operation: "add" | "replace";
  path: string;
  value: string | any;
  applicationAuth?: boolean;
}

export async function PatchEHRPoster({
  resourceType,
  resourceId,
  path,
  operation,
  value,
  applicationAuth,
}: IProps) {
  const url = `${endpoints.EHRPOSTER}/${resourceType}/${resourceId}`;

  let operations: IPatchOperation[] = [];

  if (value)
    operations.push({
      op: operation,
      path: path,
      value: value,
    });

  const data = auxiliarData.Patch({
    operations,
  });
  const headers = {
    Accept: "application/fhir+json",
    Authorization: applicationAuth
      ? await OAuthApplication()
      : await useAuth.getUserToken(),
    "Content-Type": "application/json-patch+json",
  };

  const response = await useAxios
    .patch(url, data, { headers, timeout: 15000 })
    .then((response: any) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`PatchEHRPoster: ${response.status}`);
      }
      return { data: response.data, status: response.status };
    })
    .catch((error: any) => {
      console.error("PatchEHRPoster Error", error);
      // toast.error(`PatchEHRPoster Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
