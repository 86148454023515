import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useSessionContext } from "../../../contexts/SessionContext";
import { EditableCardRow } from "../../../components/common";
import { AddressDialog } from "./AddressDialog";

export function CardAddress() {
  const { userPatient } = useSessionContext();
  const [addressDialogOpen, setAddressDialogOpen] = useState<boolean>(false);

  const auxAddress = userPatient?.address?.[0];

  const city = auxAddress?.city || "";
  const district = auxAddress?.district || "";
  const state = auxAddress?.state || "";
  const postalCode = auxAddress?.postalCode || "";
  const line = auxAddress?.line?.join(", ") || "";

  const address =
    [line, district, city, state, postalCode]
      ?.filter((el: any) => el)
      ?.join(" , ") || "-";

  return (
    <Stack
      sx={{
        border: "1px solid",
        borderColor: "neutral700.main",
        borderRadius: "15px",
      }}
      width="100%"
      paddingTop={2}
    >
      <Typography
        variant="h5"
        fontWeight="500"
        paddingBottom={3}
        paddingLeft={5}
      >
        Endereço
      </Typography>

      <EditableCardRow
        title="Endereço"
        titleContext={address}
        type={userPatient?.address ? "edit" : "add"}
        onClick={() => setAddressDialogOpen(true)}
      />
      {addressDialogOpen && (
        <AddressDialog
          type={userPatient?.address ? "edit" : "add"}
          isOpen={addressDialogOpen}
          setIsOpen={setAddressDialogOpen}
        />
      )}
    </Stack>
  );
}
