import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";
import { HTTPResponseStatus } from "../../../utils/enum";

export async function UpdatePractitionerRole(objData: any, updateData: any) {
  const id = objData.id;
  const data = objData;

  data.code = [
    ...(data.code || []),
    !data.code?.find((el: any) => el.coding[0].system === "practitioner") && {
      coding: [
        {
          system: "practitioner",
          code: "RL_PRACTITIONER",
        },
      ],
    },
  ];

  data.identifier = [
    {
      system: updateData?.conselho,
      value: `${updateData?.conselho}/${updateData.uf.value}${updateData.numero}`,
    },
  ];

  const specialties =
    updateData?.conselho === "CRM"
      ? updateData?.speciality?.map((el: any) => ({
          coding: [
            {
              system: "crm",
              code: el.value,
              display: el.label,
            },
          ],
        }))
      : [
          {
            coding: [
              {
                system: "conselhodeclasse",
                code: updateData?.speciality?.code,
                display: updateData?.speciality?.descricao,
              },
            ],
          },
        ];

  data.specialty = specialties;

  const url = `${endpoints.ADMFHIR}/PractitionerRole/${id}`;
  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
  };
  const response = await useAxios
    .put(url, data, { headers, timeout: 20000 })
    .then((response) => {
      if (
        response.status !== HTTPResponseStatus.Success &&
        response.status !== HTTPResponseStatus.Created
      ) {
        throw new Error(`UpdatePractitionerRole: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("UpdatePractitionerRole ", error);
      // toast.error(`UpdatePractitionerRole Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
