import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Stack,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { GenericTable } from "../../../../../components/GenericTable/GenericTable";
import { ObservationEncounterActions } from "./ObservationEncounterActions";
import { useState } from "react";
import { ObservationEncounterVisualizer } from "./ObservationEncounterVisualizer";
import dayjs from "dayjs";
import { useOrganizationContext } from "../../../../../contexts/OrganizationContext";

interface IProps {
  title: string;
  data: any;
  openVisualizer: any;
  verifyVisualizer: any;
}

export function ObservationsEncounter({
  title,
  data,
  openVisualizer,
  verifyVisualizer,
}: IProps) {
  const { organizationList } = useOrganizationContext();
  const [open, setOpen] = useState<boolean>(false);
  const [responseData, setResponseData] = useState();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      column: "Organização",
      rowName: "organization",
    },
    {
      column: "Especialidade",
      rowName: "serviceType",
    },
    { column: "Profissional", rowName: "practitioner" },
    { column: "Data", rowName: "date" },
  ];

  const rowData = data
    // .filter((el: any) => el.encounter.status === "completed")
    ?.filter((el: any) => el?.obs?.length)
    ?.map((el: any) => {
      const encounter = el.encounter;

      return {
        encounterId: el?.encounter?.id,
        organization:
          organizationList?.find((el: any) =>
            el.resource.id.includes(
              encounter?.serviceProvider.reference.split("/")[1]
            )
          )?.resource?.name ||
          encounter?.serviceProvider.reference.split("-")[1],
        serviceType:
          encounter?.serviceType?.find((el: any) =>
            el.reference?.reference?.includes("HealthcareService")
          )?.reference?.display || encounter?.class?.[0]?.coding?.[0]?.display,
        date: dayjs(encounter?.actualPeriod?.start).format("DD/MM/YYYY"),
        obs: el?.obs,
        practitioner:
          encounter?.participant
            ?.find((el: any) => el?.actor?.reference?.includes("Practitioner"))
            ?.actor?.display.toUpperCase() ||
          encounter?.participant?.find((el: any) =>
            el?.actor?.reference?.includes("Practitioner")
          )?.actor?.value ||
          encounter?.participant?.find((el: any) =>
            el?.actor?.type?.includes("Practitioner")
          )?.actor?.identifier?.value,
      };
    });

  return (
    <Accordion sx={{ width: "100%" }} elevation={5}>
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Stack direction="row" alignItems="center" gap={1}>
          {title}
          <Avatar
            sx={{
              width: 26,
              height: 26,
              fontSize: "14px",
              fontWeight: 600,
              backgroundColor: "primary100.main",
              color: "primary1000.main",
            }}
          >
            {rowData?.length || "0"}
            {/* {data.filter((el: any) => el.encounter.status === "completed")
              .length || "0"} */}
          </Avatar>
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <GenericTable
          tableColumns={columns}
          tableRows={rowData}
          actions={(rowData: any) => (
            <ObservationEncounterActions
              verifyVisualizer={verifyVisualizer}
              row={rowData}
              openVisualizer={openVisualizer}
              handleOpen={handleOpen}
              setResponseData={setResponseData}
            />
          )}
        />
      </AccordionDetails>

      {open && (
        <ObservationEncounterVisualizer
          data={responseData}
          handleClose={handleClose}
          isOpen={open}
        />
      )}
    </Accordion>
  );
}
