import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { GetSchedule } from "../services/fhir";
import { oids } from "../configs/Settings";
import { useSessionContext } from "./SessionContext";
import { usePractitionerRoleContext } from "./PractitionerRoleContext";
import { useLocationContext } from "./LocationContext";
import { useHealthcareServiceContext } from "./HealthcareServiceContext";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";

interface State {
  [key: string]: boolean;
}

interface ManagerContextValue {
  loading: boolean;
  setRefetch: any;
  schedule: any;
  tabValue: any;
  setTabValue: any;
  control: any;
  setValue: any;

  displaySchedules: any;
  selectedDate: any;

  selectedRow: any;
  setSelectedRow: any;

  locationFilter: any;
  setLocationFilter: any;
  activeLocationFilter: any;
  handleRemoveLocationFilter: any;
  isLocationFilterApply: any;

  scheduleFilter: any;
  setScheduleFilter: any;
  activeScheduleFilter: any;
  handleRemoveScheduleFilter: any;
  isScheduleFilterApply: any;

  healthcareFilter: any;
  setHealthcareFilter: any;
  activeHealthcareFilter: any;
  handleRemoveHealthcareFilter: any;
  isHealthcareFilterApply: any;

  practitionerFilter: any;
  setPractitionerFilter: any;
  activePractitionerFilter: any;
  handleRemovePractitionerFilter: any;
  isPractitionerFilterApply: any;
}

export const ManagerContext = createContext<ManagerContextValue>(null!);

export function ManagerContextProvider({ children }: { children: ReactNode }) {
  const { access } = useSessionContext();

  const [refetch, setRefetch] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState(0);
  const [schedule, setSchedule] = useState<any>();

  const methods = useForm({
    defaultValues: {
      dia: dayjs(),
    },
  });
  const { control, setValue, watch } = methods;

  const [selectedRow, setSelectedRow] = useState<any>();

  const [locationFilter, setLocationFilter] = useState<State>({});
  const [scheduleFilter, setScheduleFilter] = useState<State>({});
  const [healthcareFilter, setHealthcareFilter] = useState<State>({});
  const [practitionerFilter, setPractitionerFilter] = useState<State>({});

  const activePractitionerFilter = Object.keys(practitionerFilter).filter(
    (key) => practitionerFilter[key]
  );

  const activeLocationFilter = Object.keys(locationFilter).filter(
    (key) => locationFilter[key]
  );

  const activeHealthcareFilter = Object.keys(healthcareFilter).filter(
    (key) => healthcareFilter[key]
  );

  const activeScheduleFilter = Object.keys(scheduleFilter).filter(
    (key) => scheduleFilter[key]
  );

  const handleRemoveScheduleFilter = (filter: string) => {
    setScheduleFilter((prevState) => ({
      ...prevState,
      [filter]: false, // Desabilita o filtro
    }));
  };

  const handleRemovePractitionerFilter = (filter: string) => {
    setPractitionerFilter((prevState) => ({
      ...prevState,
      [filter]: false, // Desabilita o filtro
    }));
  };

  const handleRemoveHealthcareFilter = (filter: string) => {
    setHealthcareFilter((prevState) => ({
      ...prevState,
      [filter]: false, // Desabilita o filtro
    }));
  };

  const handleRemoveLocationFilter = (filter: string) => {
    setLocationFilter((prevState) => ({
      ...prevState,
      [filter]: false, // Desabilita o filtro
    }));
  };

  const isScheduleFilterApply =
    scheduleFilter && Object.values(scheduleFilter).includes(true);

  const isPractitionerFilterApply =
    practitionerFilter && Object.values(practitionerFilter).includes(true);

  const isHealthcareFilterApply =
    healthcareFilter && Object.values(healthcareFilter).includes(true);

  const isLocationFilterApply =
    locationFilter && Object.values(locationFilter).includes(true);

  const displaySchedules = schedule?.entry?.filter((el: any) => {
    const practitioners = el?.resource.actor?.filter((actor: any) =>
      actor.reference.includes("Practitioner/")
    );
    const practitionerReferences = practitioners?.map(
      (actor: any) => actor.reference
    );
    const healthcareDisplay = el?.resource.actor?.find((actor: any) =>
      actor.reference.includes("HealthcareService")
    )?.display;
    const locationReference = el?.resource.actor
      ?.find((actor: any) => actor.reference.includes("Location"))
      ?.reference?.split("/")[1];

    const verifyActive = tabValue === 2 ? true : el?.resource?.active;

    return (
      (scheduleFilter?.[el?.resource?.id] || !isScheduleFilterApply) &&
      (practitionerReferences.some(
        (reference: any) => practitionerFilter?.[reference]
      ) ||
        !isPractitionerFilterApply) &&
      (healthcareFilter?.[healthcareDisplay] || !isHealthcareFilterApply) &&
      (locationFilter?.[locationReference] || !isLocationFilterApply) &&
      verifyActive
    );
  });

  const selectedDate = watch("dia");

  if (selectedDate.isBefore(dayjs()) && tabValue !== 2)
    setValue("dia", dayjs());

  if (selectedDate.isAfter(dayjs()) && tabValue === 2) setValue("dia", dayjs());

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);

        const scheduleResponse = await GetSchedule({
          // active: true,

          organization: `${oids.cnpj}-${access.organizationId}`,
        });
        setSchedule(scheduleResponse);
      } catch (err) {
        console.log("err");
      } finally {
        setLoading(false);
      }
    }
    if (access?.organizationId) fetchData();
  }, [access?.organizationId, refetch]);

  const contextValue = {
    loading,
    setRefetch,
    schedule,
    tabValue,
    setTabValue,
    control,
    setValue,

    displaySchedules,
    selectedDate,

    selectedRow,
    setSelectedRow,

    locationFilter,
    setLocationFilter,
    activeLocationFilter,
    handleRemoveLocationFilter,
    isLocationFilterApply,

    practitionerFilter,
    setPractitionerFilter,
    activePractitionerFilter,
    handleRemovePractitionerFilter,
    isPractitionerFilterApply,

    scheduleFilter,
    setScheduleFilter,
    activeScheduleFilter,
    handleRemoveScheduleFilter,
    isScheduleFilterApply,

    healthcareFilter,
    setHealthcareFilter,
    activeHealthcareFilter,
    handleRemoveHealthcareFilter,
    isHealthcareFilterApply,
  };

  return (
    <ManagerContext.Provider value={contextValue}>
      {children}
    </ManagerContext.Provider>
  );
}

export function useManagerContext() {
  return useContext(ManagerContext);
}
