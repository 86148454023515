import { Stack } from "@mui/material";
import { SecondaryButton } from "../../../../components/common";
import { Restore } from "@mui/icons-material";
import { useState } from "react";
import { PreviousDocumentsDialog } from "../previousDocuments/PreviousDocumentsDialog";

export function AuxiliarPrescriptionButtons({ addItem }: any) {
  const [previousDocumentDialog, setPreviousDocumentDialog] =
    useState<boolean>(false);

  return (
    <Stack direction="row" spacing={3} justifyContent="flex-end" mb={2}>
      <SecondaryButton
        width="270px"
        height="42px"
        onClick={() => setPreviousDocumentDialog(true)}
      >
        <Restore sx={{ marginRight: "12px" }} />
        Documentos anteriores
      </SecondaryButton>
      {previousDocumentDialog && (
        <PreviousDocumentsDialog
          open={previousDocumentDialog}
          handleClose={() => setPreviousDocumentDialog(false)}
          addItem={addItem}
        />
      )}
    </Stack>
  );
}
