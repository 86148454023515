import { ArrowForward } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";

type IProps = {
  label: string;
  value: string;
  format?: any;
  onClick: any;
};

export function LineInformationWithButton({
  label,
  value,
  format,
  onClick,
}: IProps) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="column" margin="16px 0px" gap={1}>
        <Typography variant="body2" fontWeight={600} color="neutral700.main">
          {label}
        </Typography>
        <Typography variant="body2" color="neutral700.main">
          {format ? format(value) : value}
        </Typography>
      </Stack>
      <Button
        variant="text"
        sx={{ textTransform: "capitalize" }}
        onClick={onClick}
        endIcon={<ArrowForward />}
      >
        Editar senha
      </Button>
    </Stack>
  );
}
