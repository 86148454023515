import { oids } from "../../../../configs/Settings";
import * as uuid from "uuid";
import { useFhirData } from "../../../../utils/useFhirData";
import dayjs from "dayjs";

export const resultExamPhysical = (resultData: any) => {
  if (resultData?.value === "alterado") {
    return {
      code: "alterado",
      display: "Alterado",
      system: "urn:uuid:35a306cd-8fcb-405f-8dfb-63501d0a5815",
    };
  }
  if (resultData?.value === "not_evaluated" || !resultData?.value) {
    return {
      code: "not_evaluated",
      display: "Não avaliado",
      system: "urn:uuid:not_evaluated",
    };
  }
  if (resultData?.value === "normal") {
    return {
      code: "normal",
      display: "Normal",
      system: "urn:uuid:35a306cd-8fcb-405f-8dfb-63501d0a5815",
    };
  }
};

export const resultConducts = (
  resultConductsData: any,
  oldConductsData: any
) => {
  const status = (status: number) => {
    const statusMap: { [key: number]: { code: string; display: string } } = {
      1: { code: "concluded", display: "Realizada" },
      2: { code: "partially", display: "Parcialmente" },
      3: { code: "failed", display: "Não realizada" },
    };

    return statusMap[status]
      ? {
          valueCoding: {
            ...statusMap[status],
            system: "urn:uuid:0dd760d6-b46c-43fe-9ecc-77c80cce397a",
          },
        }
      : null;
  };

  const createItem = (linkId: string, text: string, answer: any[]) => ({
    linkId,
    text,
    answer,
  });

  const createConductItem = (
    conduct: any,
    statusAnswer?: any,
    nota?: string
  ) => {
    const items = [
      createItem("3cccab72-a2ed-46e9-ebce-a1c6532e4bca", "Conduta", [
        { valueString: conduct },
      ]),
      statusAnswer &&
        createItem("2e202c22-d5bc-4243-870e-d342cb9c1b8d", "Status", [
          statusAnswer,
        ]),
      nota &&
        createItem("3013e032-4b24-46fa-879a-29e775153a72", "Nota", [
          { valueString: nota },
        ]),
    ].filter(Boolean);

    return {
      linkId: "a6f78af6-6fd9-49a7-e606-491065403d2d",
      text: "Conduta",
      item: items,
    };
  };

  const oldConducts = oldConductsData
    ? Object.entries(oldConductsData)
        .map(([conduct, conducts]: [string, any]) => {
          if (conducts?.status) {
            const statusAnswer = status(conducts.status);
            const conductValue = conduct?.split("_")?.[1]?.split(";")?.[0];
            return createConductItem(conductValue, statusAnswer, conducts.nota);
          }
          return null;
        })
        .filter(Boolean)
    : [];
  const newConducts = resultConductsData
    ? resultConductsData
        .map((conduct: any) => (conduct ? createConductItem(conduct) : null))
        .filter(Boolean) // Remove valores nulos
    : [];

  return [...newConducts, ...oldConducts];
};

export const resultProblems = (
  resultProblemsData: any,
  oldProblemsData: any
) => {
  const status = (status: number) => {
    const statusMap: { [key: number]: { code: string; display: string } } = {
      1: { code: "concluded", display: "Melhora" },
      2: { code: "partially", display: "Estável" },
      3: { code: "failed", display: "Piora" },
    };

    return statusMap[status]
      ? {
          valueCoding: {
            ...statusMap[status],
            system: "urn:uuid:0dd760d6-b46c-43fe-9ecc-77c80cce397a",
          },
        }
      : null;
  };

  const createItem = (linkId: string, text: string, answer: any[]) => ({
    linkId,
    text,
    answer,
  });

  const createProblemItem = (
    problem: any,
    statusAnswer?: any,
    nota?: string
  ) => {
    const items = [
      createItem("4f96c740-f739-4758-8324-b5fd7893fcbd", "Problema", [
        { valueString: problem },
      ]),
      statusAnswer &&
        createItem("2972b2f4-a634-4277-8200-96ce9111dc3d", "Status", [
          statusAnswer,
        ]),
      nota &&
        createItem("91b753c5-b71c-4a66-86eb-c24c91205a26", "Nota", [
          { valueString: nota },
        ]),
    ].filter(Boolean);

    return {
      linkId: "8e2c9b72-bcee-4c0f-ecb2-8e4fc2866cd4",
      text: "Problema",
      item: items,
    };
  };

  const oldProblems = oldProblemsData
    ? Object.entries(oldProblemsData)
        .map(([problem, problems]: [string, any]) => {
          if (problems?.status) {
            const statusAnswer = status(problems.status);
            const problemValue = problem?.split("_")?.[1]?.split(";")?.[0];
            return createProblemItem(problemValue, statusAnswer, problems.nota);
          }
          return null;
        })
        .filter(Boolean)
    : [];
  const newProblems = resultProblemsData
    ? resultProblemsData
        .map((problem: any) => (problem ? createProblemItem(problem) : null))
        .filter(Boolean)
    : [];

  return [...newProblems, ...oldProblems];
};

export const resultExams = (
  resultExamResultsData: any,
  oldExamResultsData: any
) => {
  const createItem = (linkId: string, text: string, answer: any[]) => ({
    linkId,
    text,
    answer,
  });

  const createExamResultsItem = (name: any, resultValue: any, date: any) => {
    const items = [
      createItem("801d90b0-0fd1-410f-89e5-d26087895039", "Nome do exame", [
        { valueString: name },
      ]),
      createItem("4a58a481-b19a-41a5-9865-d3e0821b1005", "Valor do resultado", [
        { valueString: resultValue },
      ]),
      createItem("91b753c5-b71c-4a66-86eb-c24c91205a26", "Data de realização", [
        { valueDate: date },
      ]),
    ].filter(Boolean);

    return {
      linkId: "b7206ad2-06a0-4b3f-8529-ca1be08ce24b",
      text: "Exame",
      item: items,
    };
  };

  const oldExamResults = oldExamResultsData
    ? Object.entries(oldExamResultsData)
        ?.map(([name, values]: [string, any]) => {
          const examValues = values?.filter(
            (value: any) => value !== undefined
          );

          return examValues
            ? examValues?.map((examValues: any) => {
                return createExamResultsItem(
                  name,
                  examValues?.value,
                  examValues?.date
                );
              })
            : null;
        })
        ?.filter(Boolean)
        ?.flat()
    : [];

  const newExamResults = resultExamResultsData
    ? resultExamResultsData
        .map((examResult: any) =>
          examResult
            ? createExamResultsItem(
                examResult?.name,
                examResult?.value,
                examResult?.date
              )
            : null
        )
        .filter(Boolean)
    : [];

  return [...newExamResults, ...oldExamResults];
};

export const resultTreatmentPlans = (
  resultTreatmentPlansData: any,
  oldTreatmentPlansData: any
) => {
  const status = (status: number) => {
    const statusMap: { [key: number]: { code: string; display: string } } = {
      1: { code: "concluded", display: "Concluído" },
      2: { code: "partially", display: "Parcialmente" },
      3: { code: "failed", display: "Falhou" },
    };

    return statusMap[status]
      ? {
          valueCoding: {
            ...statusMap[status],
            system: "urn:uuid:0dd760d6-b46c-43fe-9ecc-77c80cce397a",
          },
        }
      : null;
  };

  const typeTreatment = (type: number) => {
    const typeMap: { [key: number]: { code: string; display: string } } = {
      1: { code: "long-term", display: "Longo Prazo" },
      2: { code: "short-term", display: "Curto Prazo" },
    };

    return typeMap[type]
      ? {
          valueCoding: {
            ...typeMap[type],
            system: "urn:uuid:0dd760d6-b46c-43fe-9ecc-77c80cce397a",
          },
        }
      : null;
  };

  const typeTreatmentNumber = (type: string) => {
    const typeMap: { [key: string]: { code: string; display: string } } = {
      "long-term": { code: "long-term", display: "Longo Prazo" },
      "short-term": { code: "short-term", display: "Curto Prazo" },
    };

    return typeMap[type]
      ? {
          valueCoding: {
            ...typeMap[type],
            system: "urn:uuid:0dd760d6-b46c-43fe-9ecc-77c80cce397a",
          },
        }
      : null;
  };

  const createItem = (linkId: string, text: string, answer: any[]) => ({
    linkId,
    text,
    answer,
  });

  const createTreatmentPlansItem = (
    treatmentPlan: any,
    typeAnswer?: any,
    statusAnswer?: any,
    nota?: string
  ) => {
    const items = [
      createItem(
        "477c970e-a76b-4fe9-89d4-65cd19481b7b",
        "Plano de tratamento",
        [{ valueString: treatmentPlan }]
      ),
      typeAnswer &&
        createItem("a55cf057-e01e-423c-d27c-7f37b6e63e21", "Prazo do Plano", [
          typeAnswer,
        ]),
      statusAnswer &&
        createItem("ba000da7-3e8e-4b03-faf2-4089aeb2852d", "Status", [
          statusAnswer,
        ]),
      nota &&
        createItem("c04e0f73-2548-4457-a723-a7a403797688", "Nota", [
          { valueString: nota },
        ]),
    ].filter(Boolean);

    return {
      linkId: "4e1679c2-3e1d-444d-d438-28bdbeb1e0ee",
      text: "Planos de tratamento",
      item: items,
    };
  };

  const oldTreatmentPlans = oldTreatmentPlansData
    ? Object.entries(oldTreatmentPlansData)
        .map(([treatmentPlan, treatmentPlanValues]: [string, any]) => {
          if (treatmentPlanValues?.status) {
            const prazo = treatmentPlan?.split(";")?.[1];
            const statusAnswer = status(treatmentPlanValues?.status);
            const typeAnswer = typeTreatmentNumber(prazo);
            const treatmentPlanOld = treatmentPlan
              ?.split("_")?.[1]
              ?.split(";")?.[0];

            return createTreatmentPlansItem(
              treatmentPlanOld,
              typeAnswer,
              statusAnswer,
              treatmentPlanValues?.nota
            );
          }
          return null;
        })
        .filter(Boolean)
    : [];
  const newTreatmentPlans = resultTreatmentPlansData
    ? resultTreatmentPlansData
        .map((treatmentPlan: any) => {
          if (treatmentPlan?.prazo && treatmentPlan?.plano) {
            const typeAnswer = typeTreatment(treatmentPlan?.prazo);
            const treatmentPlanNew = treatmentPlan?.plano;

            return createTreatmentPlansItem(treatmentPlanNew, typeAnswer);
          }
          return null;
        })
        .filter(Boolean)
    : [];
  return [...newTreatmentPlans, ...oldTreatmentPlans];
};

export const resultAllergy = (summary: any, encounterId: any) => {
  function extractAllergyResponses(allergyIntolerance: any) {
    return {
      substancia: allergyIntolerance.code?.text || " ",
      categoria: allergyIntolerance.category?.[0] || " ",
      criticidade: allergyIntolerance.criticality || " ",
      statusClinico:
        allergyIntolerance.clinicalStatus?.coding?.[0]?.code || " ",
      statusVerificacao:
        allergyIntolerance.verificationStatus?.coding?.[0]?.code || " ",
    };
  }

  function generateFHIRResponse(values: {
    substancia: string;
    categoria: string;
    criticidade: string;
    statusClinico: string;
    statusVerificacao?: string;
  }) {
    return {
      linkId: "539012453621",
      text: "Alergia",
      item: [
        {
          linkId: "876324478314",
          text: "Substancia",
          answer: [
            {
              valueString: values.substancia,
            },
          ],
        },
        {
          linkId: "988743238473",
          text: "Categoria",
          answer: [
            {
              valueString: values.categoria,
            },
          ],
        },
        {
          linkId: "622366631631",
          text: "Criticidade",
          answer: [
            {
              valueString: values.criticidade,
            },
          ],
        },
        {
          linkId: "131639643283",
          text: "Status Clínico",
          answer: [
            {
              valueString: values.statusClinico,
            },
          ],
        },
        ...(values.statusVerificacao
          ? [
              {
                linkId: "279325660888",
                text: "Status de Verificação",
                answer: [
                  {
                    valueString: values.statusVerificacao,
                  },
                ],
              },
            ]
          : []),
      ],
    };
  }

  const allergys = useFhirData
    .extractPatientSummaryResource(summary, "AllergyIntolerance")?.[0]
    ?.entry?.map((e: any) => e?.resource)
    ?.filter((el: any) => el?.encounter?.reference?.includes(encounterId))
    ?.map((e: any) => generateFHIRResponse(extractAllergyResponses(e)));

  return allergys;
};

export const resultCondition = (summary: any, encounterId: any) => {
  function extractConditionResponses(condition: any) {
    return {
      description: condition.code?.coding?.[0]?.display || " ",
      cid10: condition.code?.coding?.[0]?.code || " ",
      statusClinico: condition.clinicalStatus?.coding?.[0]?.code || " ",
      statusVerificacao: condition.verificationStatus?.coding?.[0]?.code || " ",
    };
  }

  function generateFHIRResponse(values: {
    description: string;
    cid10: string;

    statusClinico: string;
    statusVerificacao?: string;
  }) {
    return {
      linkId: "240329226746",
      text: "Condição",
      item: [
        {
          linkId: "344867967705",
          text: "Descrição",
          answer: [
            {
              valueString: values.description || "",
            },
          ],
        },
        {
          linkId: "404802749518",
          text: "CID10",
          answer: [
            {
              valueString: values.cid10 || "",
            },
          ],
        },
        {
          linkId: "674720118357",
          text: "Status de Verificação",
          answer: [
            {
              valueString: values.statusVerificacao || "",
            },
          ],
        },
        {
          linkId: "988892884320",
          text: "Status Clínico",
          answer: [
            {
              valueString: values?.statusClinico || "",
            },
          ],
        },
      ],
    };
  }

  const conditions = useFhirData
    .extractPatientSummaryResource(summary, "Condition")?.[0]
    ?.entry?.map((e: any) => e?.resource)
    ?.filter((el: any) => el?.encounter?.reference?.includes(encounterId))
    ?.map((e: any) => generateFHIRResponse(extractConditionResponses(e)));

  return conditions;
};

export const resultObservation = (summary: any, encounterId: any) => {
  const translateCode: any = {
    wk: "semanas",
    mo: "meses",
  };
  const verifyValue = (el: any) => {
    if (el?.code?.coding?.[0]?.code === "55284-4") {
      return `${el?.component?.[0]?.valueQuantity?.value || "-"}/${
        el?.component?.[1]?.valueQuantity?.value || "-"
      } mmHg`;
    }

    if (el?.hasOwnProperty("valueCodeableConcept")) {
      return `${
        el?.valueCodeableConcept?.coding?.[0]?.display ||
        el?.valueCodeableConcept?.text
      }`;
    }

    if (el?.hasOwnProperty("valueQuantity")) {
      const value = el?.valueQuantity?.value;
      const code = el?.valueQuantity?.code;

      if (value !== undefined && code !== undefined) {
        return `${value} ${translateCode[code] || code}`;
      }
      if (value !== undefined) {
        return `${value}`;
      }
      if (code !== undefined) {
        return `${translateCode[code] || code}`;
      }
    }
    if (el?.hasOwnProperty("valueDateTime")) {
      return dayjs(el?.valueDateTime).format("DD/MM/YYYY");
    }

    return "-";
  };

  function generateFHIRResponse(values: {
    description: string;
    result: string;
  }) {
    return {
      linkId: "240329226746",
      text: "Observação",
      item: [
        {
          linkId: "344867967705",
          text: "Descrição",
          answer: [
            {
              valueString: values.description || "",
            },
          ],
        },
        {
          linkId: "404802749518",
          text: "Valor",
          answer: [
            {
              valueString: values.result || "",
            },
          ],
        },
      ],
    };
  }

  const observations = useFhirData
    .extractPatientSummaryResource(summary, "Observation")?.[0]
    ?.entry?.map((e: any) => e?.resource)
    ?.filter((el: any) => el?.encounter?.reference?.includes(encounterId))
    ?.map((observation: any) => {
      return generateFHIRResponse({
        description:
          observation?.code?.text ||
          observation?.code?.coding?.[0]?.display ||
          "",
        result: verifyValue(observation),
      });
    });

  return observations;
};

export const resultMedication = (summary: any, encounterId: any) => {
  function extractMedicationInfo(medicationRequest: any) {
    const medicationName = medicationRequest?.medication?.concept?.text || "";
    const dosageInstruction = medicationRequest?.dosageInstruction?.[0];
    const doseAndRate = dosageInstruction?.doseAndRate?.[0];
    const doseQuantity = doseAndRate?.doseQuantity;

    const quantity = doseQuantity?.value || 0;
    const form = doseQuantity?.unit || "";

    const timing = dosageInstruction?.timing?.repeat;
    const intervalHours = timing?.periodUnit === "h" ? timing?.period : null;
    const intervalDays = timing?.durationUnit === "d" ? timing?.duration : null;

    return {
      description: medicationName,
      quantity,
      form,
      intervalHours,
      intervalDays,
    };
  }

  function generateFHIRResponse(values: {
    description: string;
    form: string;
    quantity: any;
    intervalHours: any;
    intervalDays: any;
  }) {
    return {
      linkId: "240329226746",
      text: "Medicamento",
      item: [
        {
          linkId: "344867967705",
          text: "Descrição",
          answer: [
            {
              valueString: values.description || "",
            },
          ],
        },
        {
          linkId: "404802749518",
          text: "Quantidade",
          answer: [
            {
              valueString: values.quantity || "",
            },
          ],
        },
        {
          linkId: "404802749518",
          text: "Forma",
          answer: [
            {
              valueString: values.form || "",
            },
          ],
        },
        {
          linkId: "404802749518",
          text: "Intervalo em horas",
          answer: [
            {
              valueString: `${values.intervalHours || ""}`,
            },
          ],
        },
        {
          linkId: "404802749518",
          text: "Intervalo em dias",
          answer: [
            {
              valueString: `${values.intervalDays || ""}`,
            },
          ],
        },
      ],
    };
  }

  const medicationRequest = useFhirData
    .extractPatientSummaryResource(summary, "MedicationRequest")?.[0]
    ?.entry?.map((e: any) => e?.resource)
    ?.filter(
      (el: any) =>
        !el?.requester && el?.encounter?.reference?.includes(encounterId)
    )
    ?.map((e: any) => generateFHIRResponse(extractMedicationInfo(e)));

  return medicationRequest;
};

export const resultPrescripiton = (summary: any, encounterId: any) => {
  function extractMedicationInfo(medicationRequest: any) {
    const medicationName = medicationRequest?.medication?.concept?.text || "";
    const dosageInstruction = medicationRequest?.dosageInstruction?.[0];
    const doseAndRate = dosageInstruction?.doseAndRate?.[0];
    const doseQuantity = doseAndRate?.doseQuantity;

    const quantity = doseQuantity?.value || 0;
    const form = doseQuantity?.unit || "";

    const timing = dosageInstruction?.timing?.repeat;
    const intervalHours = timing?.periodUnit === "h" ? timing?.period : null;
    const intervalDays = timing?.durationUnit === "d" ? timing?.duration : null;

    return {
      description: medicationName,
      quantity,
      form,
      intervalHours,
      intervalDays,
    };
  }

  function generateFHIRResponse(values: {
    description: string;
    form: string;
    quantity: any;
    intervalHours: any;
    intervalDays: any;
  }) {
    return {
      linkId: "240329226746",
      text: "Medicamento",
      item: [
        {
          linkId: "344867967705",
          text: "Descrição",
          answer: [
            {
              valueString: values.description || "",
            },
          ],
        },
        {
          linkId: "404802749518",
          text: "Quantidade",
          answer: [
            {
              valueString: values.quantity || "",
            },
          ],
        },
        {
          linkId: "404802749518",
          text: "Forma",
          answer: [
            {
              valueString: values.form || "",
            },
          ],
        },
        {
          linkId: "404802749518",
          text: "Intervalo em horas",
          answer: [
            {
              valueString: `${values.intervalHours || ""}`,
            },
          ],
        },
        {
          linkId: "404802749518",
          text: "Intervalo em dias",
          answer: [
            {
              valueString: `${values.intervalDays || ""}`,
            },
          ],
        },
      ],
    };
  }

  const medicationRequest = useFhirData
    .extractPatientSummaryResource(summary, "MedicationRequest")?.[0]
    ?.entry?.map((e: any) => e?.resource)
    ?.filter(
      (el: any) =>
        el?.requester && el?.encounter?.reference?.includes(encounterId)
    )
    ?.map((e: any) => generateFHIRResponse(extractMedicationInfo(e)));

  return medicationRequest;
};

export const resultSolicitation = (summary: any, encounterId: any) => {
  function extractServiceRequestInfo(serviceRequest: any) {
    const tussCode = serviceRequest?.code?.concept?.coding?.[0]?.code || "";

    const examDescription =
      serviceRequest?.code?.concept?.coding?.[0]?.display || "";

    const reasonCID10 =
      serviceRequest?.reason?.[0]?.concept?.coding?.[0]?.code || "";

    const reasonCID10Description =
      serviceRequest?.reason?.[0]?.concept?.coding?.[0]?.display || "";

    return {
      description: tussCode
        ? `${examDescription} - ${tussCode}`
        : examDescription,
      cid10:
        reasonCID10 && reasonCID10Description
          ? `${reasonCID10} - ${reasonCID10Description}`
          : "",
    };
  }

  function generateFHIRResponse(values: {
    description: string;
    cid10: string;
  }) {
    return {
      linkId: "240329226746",
      text: "Exame",
      item: [
        {
          linkId: "344867967705",
          text: "Descrição",
          answer: [
            {
              valueString: values.description || "",
            },
          ],
        },
        {
          linkId: "404802749518",
          text: "Condição associada",
          answer: [
            {
              valueString: values.cid10 || "",
            },
          ],
        },
      ],
    };
  }

  const serviceRequest = useFhirData
    .extractPatientSummaryResource(summary, "ServiceRequest")?.[0]
    ?.entry?.map((e: any) => e?.resource)
    ?.filter(
      (el: any) =>
        el?.encounter?.reference?.includes(encounterId) &&
        el?.category?.[0]?.text !== "procedure"
    )
    ?.map((e: any) => generateFHIRResponse(extractServiceRequestInfo(e)));

  return serviceRequest;
};

export const resultProcedure = (summary: any, encounterId: any) => {
  function extractServiceRequestInfo(serviceRequest: any) {
    const procedureDescription =
      serviceRequest?.code?.concept?.coding?.[0]?.display || "";

    return {
      description: procedureDescription,
    };
  }

  function generateFHIRResponse(values: { description: string }) {
    return {
      linkId: "240329226746",
      text: "Procedimento",
      item: [
        {
          linkId: "344867967705",
          text: "Descrição",
          answer: [
            {
              valueString: values.description || "",
            },
          ],
        },
      ],
    };
  }

  const serviceRequest = useFhirData
    .extractPatientSummaryResource(summary, "ServiceRequest")?.[0]
    ?.entry?.map((e: any) => e?.resource)
    ?.filter(
      (el: any) =>
        el?.encounter?.reference?.includes(encounterId) &&
        el?.category?.[0]?.text === "procedure"
    )
    ?.map((e: any) => generateFHIRResponse(extractServiceRequestInfo(e)));

  return serviceRequest;
};

export const resultAnamnesis = (data: any) => {
  const createItem = (linkId: string, text: string, answer: any[]) => ({
    linkId,
    text,
    answer,
  });

  const items = [
    createItem("403c334f-0b30-4123-99ff-8c85fe654595", "Queixa principal", [
      { valueString: data?.anamnese?.["Queixa principal"] },
    ]),
    createItem(
      "7219a846-a9ea-4054-fd41-f8b6415e8fba",
      "História da doença atual",
      [{ valueString: data?.anamnese?.["História da doença atual"] }]
    ),
    createItem(
      "34b02bf5-e48d-470a-bebd-bd62690d028d",
      "História patológica pregressa",
      [{ valueString: data?.anamnese?.["História patológica pregressa"] }]
    ),
    createItem(
      "3fc1c008-1d08-4e0d-de9a-57d4b86a3431",
      "História pessoal e social",
      [{ valueString: data?.anamnese?.["História pessoal e social"] }]
    ),
    createItem("f6a7b8d0-1a0a-4c5e-9c9e-1b0b2c3d4e5f", "História familiar", [
      { valueString: data?.anamnese?.["História familiar"] },
    ]),
  ].filter(Boolean);

  return items;
};

export const resultAuthorReferences = (data: any) => {
  const createItem = (linkId: string, text: string, answer: any[]) => ({
    linkId,
    text,
    answer,
  });

  const items = [
    createItem("healthcareDisplay", "Especialidade", [
      {
        valueString: `${data?.author?.healthcare}`,
      },
    ]),
    createItem("practitionerName", "Nome do Profissional", [
      {
        valueString: `${data?.author?.practitioner?.display}`,
      },
    ]),
  ].filter(Boolean);

  return items;
};

export const BuildQuestionnaireAnswers = (
  data: any,
  summary: any,
  encounterId: any
) => {
  const AnamnesesAnswers = {
    linkId: "2376f9ec-1fc9-429e-8c31-fcd93ceae35c",
    text: "Anamnese",
    item: resultAnamnesis(data),
  };

  const ExamsPhysicalAnswers = {
    linkId: "73ecfd8a-a435-4fda-98d6-beb333cc0de4",
    text: "Exames Físicos",
    item: data?.exams
      ? Object.keys(data?.exams)?.map((el: any, index: number) => {
          const auxValues = data?.exams?.[el];
          const itens = Object.keys(auxValues);
          return {
            linkId:
              el === "Geral"
                ? "19944617-03c7-4049-cf63-77ad8659b274"
                : `${uuid.v1()}-${index}`,
            text: el,
            item: itens?.map((it: any) => {
              return {
                text: it?.replace(/ {2}/g, ". "),
                linkId: `${uuid.v1()}-${index}`,
                answer: [
                  {
                    item: [
                      {
                        linkId: "2c4f6d0b-d0de-45d9-cf8a-d947947a7bfd",
                        text: "Se alterado",
                        answer: [
                          {
                            valueString: `${data.exams?.[el]?.[it]?.alterado}`,
                          },
                        ],
                      },
                    ],
                    valueCoding: resultExamPhysical(data.exams?.[el]?.[it]),
                  },
                ],
              };
            }),
          };
        })
      : [],
  };

  const ConductsAnswers = {
    linkId: "38adde2c-f083-435f-8a1f-fc9cc6fa740a",
    text: "Condutas",
    item: [...resultConducts(data?.New_Conduta, data?.CondutasPassadas)],
  };

  const ExamsResultsAnswers = {
    linkId: "0f1542f5-101c-49d7-e131-a6ca99f402dc",
    text: "Resultado de exames",
    item: [resultExams(data?.newExames, data?.pastExames)],
  };

  const ProblemListAnswers = {
    linkId: "f075e9cc-c1c4-44ac-db18-035cbc5453d4",
    text: "Lista de Problemas",
    item: [...resultProblems(data?.New_Problema, data?.ProblemasPassados)],
  };

  const TreatmentsPlansAnswers = {
    linkId: "4e1679c2-3e1d-444d-d438-28bdbeb1e0ee",
    text: "Planos de tratamento",
    item: [...resultTreatmentPlans(data?.newPlan, data?.CarePlans)],
  };

  const allergyAnswers = {
    linkId: "AllergyIntolerance",
    text: "Alergias",
    item: resultAllergy(summary, encounterId),
  };

  const conditionAnswers = {
    linkId: "Condition",
    text: "Condições",
    item: resultCondition(summary, encounterId),
  };

  const observationAnswers = {
    linkId: "Oberservation",
    text: "Observações",
    item: resultObservation(summary, encounterId),
  };

  const medicationsAnswers = {
    linkId: "Medications",
    text: "Medicamentos Declarados",
    item: resultMedication(summary, encounterId),
  };

  const presciprtionAnswers = {
    linkId: "Prescriptions",
    text: "Prescrições",
    item: resultPrescripiton(summary, encounterId),
  };

  const solicitationAnswers = {
    linkId: "ExamsSolicitation",
    text: "Solicitações de exame",
    item: resultSolicitation(summary, encounterId),
  };

  console.log("sl", solicitationAnswers);

  const procedureAnswers = {
    linkId: "procedureSolicitation",
    text: "Solicitações de procedimentos",
    item: resultProcedure(summary, encounterId),
  };

  const DiagnosticsAnswers = {
    linkId: "9643300d-7320-4935-80a0-cf5ce1369b80",
    text: "Diagnósticos",
    item: [
      {
        linkId: "f60c0380-3239-436a-9b4f-32f1eeda5ffb",
        text: "Diagnóstico",
        item: [
          {
            linkId: "70331526-9ba6-458a-a6ef-c5f951beea4e",
            text: "CID-10 Diagnóstico",
          },
          {
            linkId: "6105f0dd-a7eb-4866-81dd-1ed0c643ad85",
            text: "Status",
          },
        ],
      },
    ],
  };

  const TreatmentsAnswers = {
    linkId: "a3597d9a-cbc1-4e11-b9b8-ea303f2a2d7e",
    text: "Tratamentos",
    item: [
      {
        linkId: "8c21644f-567a-439d-db5b-2c2d342c63b2",
        text: "Tratamento",
        item: [
          {
            linkId: "82fde5de-9151-402c-e6f1-2a28db055a40",
            text: "Nome ",
          },
          {
            linkId: "dd3a7b57-5106-4a62-8ae4-6cd1b96b533a",
            text: "Tipo de tratamento",
          },
          {
            linkId: "13b5ee6b-9953-44f1-9e2c-45a8addf0e06",
            text: "Status",
          },
          {
            linkId: "95d7e91b-460a-438f-8d95-dbe4568f4741",
            text: "Uso\n",
          },
        ],
      },
    ],
  };

  const AuthorReferencesAnswers = {
    linkId: "displayReferences",
    text: "Referências do atendimento",
    item: [...resultAuthorReferences(data)],
  };

  return [
    AnamnesesAnswers,
    ExamsPhysicalAnswers,
    ConductsAnswers,
    ExamsResultsAnswers,
    ProblemListAnswers,
    TreatmentsPlansAnswers,
    allergyAnswers,
    conditionAnswers,
    observationAnswers,
    medicationsAnswers,
    presciprtionAnswers,
    solicitationAnswers,
    procedureAnswers,
    AuthorReferencesAnswers,
  ];
};

export const BuildQuestionnaireResponse = ({
  questionnaireRef,
  status,
  patient,
  encounter,
  practitioner,
  answer,
  protocols,
}: {
  questionnaireRef: string;
  status:
    | "booked"
    | "completed"
    | "entered-in-error"
    | "in-progress"
    | "stopped"
    | "unknown";
  patient: string;
  encounter: string;
  practitioner: string;
  answer: any[];
  protocols: any[];
}) => {
  const QuestionnaireResponse = {
    resourceType: "QuestionnaireResponse",
    questionnaire: `Questionnaire/${questionnaireRef}`,
    status: status,
    authored: new Date(),
    author: {
      reference: `Practitioner/${oids.cpf}-${practitioner}`,
    },
    subject: {
      reference: `Patient?identifier=urn:oid:${oids.cpf}|${patient}`,
    },
    encounter: { reference: `Encounter/${encounter}` },
    item: answer,
    ...(protocols?.length && {
      extension: [
        {
          url: "https://fluxmed.com.br/fhir/StructureDefinition/appliedprotocol",
          valueReference: protocols?.map((e: any) => {
            return {
              reference: `QuestionnaireResponse/${e?.reference}`,
              display: e?.display,
            };
          }),
        },
      ],
    }),
  };

  return QuestionnaireResponse;
};
