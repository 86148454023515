import { ThemeProvider, CssBaseline } from "@mui/material";
import Navigation from "./navigation/Navigation";
import theme from "./configs/Theme";
import { ToastContainer } from "react-toastify";
import dayjs from "dayjs";

require("dayjs/locale/pt-br");

function App() {
  dayjs.locale("pt-br");
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Navigation />
        <ToastContainer />
      </ThemeProvider>
      <div id="recaptcha-container"></div>
    </>
  );
}

export default App;
