import { DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { CommonDialog } from "../../../components/common/CommonDialog";
import { Dispatch, SetStateAction, useState } from "react";

import {
  ChangePractitionerRoleActive,
  GetPractitionerRole,
} from "../../../services/fhir";
import { useSessionContext } from "../../../contexts/SessionContext";
import { oids } from "../../../configs/Settings";
import { toast } from "react-toastify";
import { FormButtonGroup } from "../../../components/form";
import { usePractitionerRoleContext } from "../../../contexts/PractitionerRoleContext";
import { ManageInativateSchedule } from "./ManageInativateSchedule";
import { InativatePractitionerDialog } from "./InativatePractitionerDialog";

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  practitionerId: any;
}

export function InativateConfirmDialog({
  isOpen,
  setIsOpen,
  practitionerId,
}: IProps) {
  const { access } = useSessionContext();
  const { refetchPractitionerRole } = usePractitionerRoleContext();
  const [loading, setLoading] = useState(false);

  const handleChangeActive = async (id: any, active: boolean) => {
    setLoading(true);
    try {
      const practitionerRoleResponse = await GetPractitionerRole({
        id: `${access.organizationId}-${oids.cpf}-${id}`,
      });
      if (practitionerRoleResponse) {
        await ChangePractitionerRoleActive(practitionerRoleResponse, active);
        refetchPractitionerRole();
      }
      toast.success("O profissional selecionado foi desativado");
    } catch (err) {
      toast.error("Não foi possível concluir a operação.");
      console.log("err", err);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  function handleClose() {
    setIsOpen(false);
  }

  return (
    <InativatePractitionerDialog
      handleClose={handleClose}
      isOpen={isOpen}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle textAlign="center"> Inativar profissional </DialogTitle>
      <DialogContent>
        <Stack
          minWidth="100%"
          height="100%"
          flex="1"
          justifyContent="center"
          spacing={2}
        >
          <ManageInativateSchedule
            chosenPractitionerId={practitionerId}
          ></ManageInativateSchedule>
        </Stack>
        <Typography
          variant="subtitle2"
          textAlign="center"
          color="neutral700.main"
          fontWeight="400"
          m={{ xs: 2, md: 5 }}
        >
          Após a confirmação o profissional selecionado será inativado e as suas
          agendas canceladas
        </Typography>
        <Stack display="flex" mt={3} direction="row">
          <FormButtonGroup
            onGoBackButtonClick={() => setIsOpen(false)}
            onNextButtonClick={async () =>
              await handleChangeActive(practitionerId, false)
            }
            goBackButtonText="Cancelar"
            nextButtonText="Confirmar"
            loading={loading}
          />
        </Stack>
      </DialogContent>
    </InativatePractitionerDialog>
  );
}
