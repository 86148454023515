import { Stack, Typography } from "@mui/material";

import { usePractitionerRoleContext } from "../../../contexts/PractitionerRoleContext";

export function IdentificationFields({ description, healthcare }: any) {
  return (
    <Stack mt={0.5}>
      <Stack direction="row" width="100%" spacing={2}>
        <Typography width="100px" fontWeight={500}>
          Profissional:
        </Typography>
        <Typography> {description || ""} </Typography>
      </Stack>

      <Stack direction="row" width="100%" spacing={2}>
        <Typography width="100px" fontWeight={500}>
          Especialidade
        </Typography>
        <Typography> {healthcare || ""} </Typography>
      </Stack>
    </Stack>
  );
}
