import { Divider, Stack, Typography } from "@mui/material";
import { useSessionContext } from "../../../contexts/SessionContext";
import { CardRow, EditableCardRow } from "../../../components/common";
import { useFormatter } from "../../../utils/useFormatter";
import { RaceDialog } from "./RaceDialog/RaceDialog";
import { useState } from "react";

export function CardInfo() {
  const { user, userPatient } = useSessionContext();
  const { formatAge } = useFormatter;

  const [raceDialog, setRaceDialog] = useState<boolean>(false);

  const raceExistsOnExtension = userPatient?.extension?.find(
    (el: any) =>
      el.url ===
      "http://www.saude.gov.br/fhir/StructureDefinition/BRRacaCorEtnia-1.0"
  );

  const displayNameRace =
    raceExistsOnExtension?.valueCodeableConcept?.coding?.[0]?.display;

  return (
    <Stack
      sx={{
        border: "1px solid",
        borderColor: "neutral700.main",
        borderRadius: "15px",
      }}
      width="100%"
      paddingTop={2}
    >
      <Typography
        variant="h5"
        fontWeight="500"
        paddingBottom={3}
        paddingLeft={5}
      >
        Informações básicas
      </Typography>
      <CardRow
        title="Nome"
        titleContext={useFormatter.formatNameDisplay(user.name)}
      />
      <Divider sx={{ marginLeft: 5 }} />
      <CardRow
        title="CPF"
        titleContext={useFormatter.formatCPF(user.username)}
      />
      <Divider sx={{ marginLeft: 5 }} />
      <CardRow
        title="Data de nascimento"
        titleContext={
          useFormatter.formatDate(user.birthDate) +
          " - " +
          formatAge(user.birthDate) +
          " anos"
        }
      />
      <Divider sx={{ marginLeft: 5 }} />
      <CardRow
        title="Sexo biológico"
        titleContext={useFormatter.formatGender(userPatient?.gender)}
      />
      <EditableCardRow
        type={!raceExistsOnExtension ? "add" : "edit"}
        title="Raça ou etnia"
        titleContext={displayNameRace || "-"}
        onClick={() => setRaceDialog(true)}
      />
      {raceDialog && (
        <RaceDialog
          type={!raceExistsOnExtension ? "add" : "edit"}
          isOpen={raceDialog}
          handleClose={() => setRaceDialog(false)}
          display={displayNameRace}
        />
      )}
    </Stack>
  );
}
