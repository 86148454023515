import { Description } from "@mui/icons-material";
import { IconButton, TableCell, Tooltip } from "@mui/material";

export function ActionsTableCertificate({ row, setBinaryUrl }: any) {
  return (
    <TableCell align="center">
      {row.binaryUrl && (
        <Tooltip title="Visualizar documento">
          <IconButton
            onClick={() => {
              setBinaryUrl(row.binaryUrl);
            }}
          >
            <Description />
          </IconButton>
        </Tooltip>
      )}
    </TableCell>
  );
}
