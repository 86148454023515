import { SubscriptionCard } from "./SubscriptionCard";

interface IPaymentOption {
  selectedPlan: any;
}

interface IProps {
  PaymentOptionsArray: IPaymentOption[];
  setPaymentLink: (link: string) => void;
  handleNext: () => void;
}

export function SubscriptionContent({
  PaymentOptionsArray,
  setPaymentLink,
  handleNext,
}: IProps) {
  return (
    <>
      {PaymentOptionsArray && PaymentOptionsArray.length > 0 ? (
        PaymentOptionsArray?.sort((a, b) => {
          const amountA =
            a?.selectedPlan?.auto_recurring?.transaction_amount ?? 0;
          const amountB =
            b?.selectedPlan?.auto_recurring?.transaction_amount ?? 0;
          return amountA - amountB;
        })?.map((el, index) => (
          <SubscriptionCard
            key={index}
            selectedPlan={el?.selectedPlan}
            setPaymentLink={setPaymentLink}
            handleNext={handleNext}
          />
        ))
      ) : (
        <p>Nenhuma opção de pagamento disponível.</p>
      )}
    </>
  );
}
