import { Bundle } from "../../../../types";

//Vai para o component de Build QuestionnaireResponse
export const BuildBundleQuestionnaireResponse = function (questionnaireResponse: any) {
  const bundle: Bundle = {
    resourceType: "Bundle",
    type: "transaction",
    meta: [
      {
        profile: [
          "http://fluxmed.com.br/fhir/StructureDefinition/QuestionnaireResponseBundle-1.0",
        ],
      },
    ],
    entry: [
      {
        resource: {
          ...questionnaireResponse,
        },
        request: {
          method: "POST",
          url: "QuestionnaireResponse",
        },
      },
    ],
  };
  return bundle;
};
