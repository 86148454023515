import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const InputTextArea = function (props: any) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue}
      rules={props.rules}
      render={({ field }) => (
        <TextField
          multiline
          rows={4}
          {...field}
          label={props.label}
          sx={{
            width: "100%",
            mb: 4,
            ...props.sx,
          }}
        />
      )}
    />
  );
};

export default InputTextArea;
