import { useState } from "react";
import { DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { toast } from "react-toastify";

import { CommonDialog } from "../../../components/common/CommonDialog";

import { PatchSlot } from "../../../services/fhir";
import { FormButtonGroup } from "../../../components/form";

export function AvailableSlotDialog({ setModalOpen, open, row }: any) {
  const [loading, setLoading] = useState(false);

  const updateAppointment = async (data: any) => {
    setLoading(true);
    try {
      const status =
        data.auxUpdateSlot.status === "free" ? "busy-unavailable" : "free";
      await PatchSlot({ slotId: data.auxUpdateSlot.id, status });

      row.setTabRefetch((prev: boolean) => !prev);
      toast.success("Disponibilidade alterada");
    } catch (err) {
      toast.error("Não foi possivel alterar disponibilidade!");
    } finally {
      setLoading(false);
      setModalOpen(false);
    }
  };

  return (
    <CommonDialog handleClose={() => setModalOpen(false)} isOpen={open}>
      <DialogTitle textAlign="center">
        <Typography variant="h5" fontWeight={600}>
          Alterar disponibilidade
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Stack
          height="100%"
          direction="column"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2">
            Deseja confirmar a operação?
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-around"
            width="100%"
            mt={3}
          >
            <FormButtonGroup
              onGoBackButtonClick={() => setModalOpen(false)}
              onNextButtonClick={() => updateAppointment(row)}
              goBackButtonText="Não"
              nextButtonText="Sim"
              loading={loading}
            />
          </Stack>
        </Stack>
      </DialogContent>
    </CommonDialog>
  );
}
