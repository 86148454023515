import { FamilyRestroom, Medication, Share } from "@mui/icons-material";
import {
  CardActionArea,
  Grid,
  Stack,
  Card,
  Box,
  CardContent,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ControlMedications } from "./ControlMedications";

export function MoreOptionsWeb() {
  const [open, setOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const cards = [
    {
      name: "Minha família",
      icon: (
        <FamilyRestroom fontSize="large" sx={{ color: "primary800.main" }} />
      ),
      onClick: (e: any) => {
        e.preventDefault();
        navigate("/myfamily");
      },
    },
    {
      name: "Compartilhamentos",
      icon: <Share fontSize="large" sx={{ color: "primary800.main" }} />,
      onClick: (e: any) => {
        e.preventDefault();
        navigate("/consent");
      },
    },
    {
      name: "Medicamentos",
      icon: <Medication fontSize="large" sx={{ color: "primary800.main" }} />,
      onClick: (e: any) => {
        e.preventDefault();
        setOpen(true);
      },
    },
  ];

  return (
    <Stack sx={{ height: "100%" }}>
      <Grid container columnSpacing="12px" sx={{ height: "100%" }}>
        {cards.map((card, index) => (
          <Grid
            item
            xs={4}
            key={index}
            sx={{
              height: "100%",
              p: "2px",
              boxShadow: "1px 1px 16px 1px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Card
              sx={{
                height: "100%",
                borderRadius: "8px",
              }}
              onClick={card?.onClick}
            >
              <CardActionArea
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center", // Centraliza verticalmente
                  alignItems: "center", // Centraliza horizontalmente
                  height: "100%",
                  textAlign: "center", // Garante alinhamento de texto
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 1,
                  }}
                >
                  <Box sx={{ marginBottom: "-8px" }}>{card.icon}</Box>
                </Box>
                <CardContent>
                  <Typography
                    gutterBottom
                    component="div"
                    align="center"
                    fontSize="20px"
                    fontWeight={400}
                  >
                    {card.name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>

      {open && <ControlMedications open={open} setOpen={setOpen} />}
    </Stack>
  );
}
