import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { Controller } from "react-hook-form";

const InputCheckbox = function (props: any) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      render={({ field }) => (
        <FormGroup>
          <FormControlLabel {...field} control={<Checkbox />} label={props?.label} />
        </FormGroup>
      )}
    />
  );
};

export default InputCheckbox;
