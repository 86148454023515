import { Stack } from "@mui/material";
import { GenericTable } from "../../../../../components/GenericTable/GenericTable";
import { PrimaryButton } from "../../../../../components/common";
import { Add } from "@mui/icons-material";
import dayjs from "dayjs";
import { useDatetime } from "../../../../../utils/useDatetime";
import { useDict } from "../../../../../utils/useDict";

interface IProps {
  data: any;
  openDialog: any;
}

export function AllergiesTab({ data, openDialog }: IProps) {
  const { formatToDDMMYYYY } = useDatetime;
  const columns = [
    { column: "Subtancia", rowName: "substance" },
    { column: "Categoria", rowName: "category" },
    { column: "Status de verificação", rowName: "verificationStatus" },
    { column: "Status clínico", rowName: "clinicalStatus" },
    { column: "Criticidade", rowName: "criticality" },
    { column: "Data", rowName: "date" },
  ];

  const rows = data?.map((el: any) => ({
    criticality: useDict.criticality[el?.resource?.criticality],
    category: useDict.category[el?.resource?.category?.[0]] || "-",
    verificationStatus:
      useDict.verificationStatus[
        el?.resource?.verificationStatus?.coding?.[0]?.code
      ] || "",
    clinicalStatus:
      useDict.clinicalStatus[el?.resource?.clinicalStatus?.coding?.[0]?.code] ||
      "",
    substance: el.resource?.code?.text,
    date: formatToDDMMYYYY(dayjs(el?.resource?.recordedDate)),
  }));

  return (
    <>
      <GenericTable tableColumns={columns} tableRows={rows} />
      <Stack direction="row" justifyContent="right" mt={4}>
        <PrimaryButton onClick={openDialog} width="200px" height="42px">
          <Add /> Adicionar alergia
        </PrimaryButton>
      </Stack>
    </>
  );
}
