import { Grid, Stack, Typography } from "@mui/material";
import { PresentationHeader } from "./PresentationHeader";
import { DataPresentation } from "./DataPresentation";
import { useFormatter } from "../../../utils/useFormatter";
import dayjs from "dayjs";
import { SubmitActions } from "./SubmitActions";

export function ConfirmationScreen({
  location,
  healthcare,
  schedule,
  setCurrentStep,
  onNext,
  loading,
}: any) {
  const address = [
    location?.city && `${location?.city}`,
    location?.state && `${location?.state}`,
    location?.cep && `${location?.cep}`,
    location?.number && `${location?.number}`,
    location?.street && `${location?.street}`,
    location?.complement && `${location?.complement}`,
  ];

  const auxiliarTimeAvailable: any = Object?.values(healthcare?.timeAvailable);

  const formatAntecedencyDays = (day: number): string => {
    const daysFormatter = [
      { display: "1 semana", value: 7 },
      { display: "2 semanas", value: 14 },
      { display: "3 semanas", value: 21 },
      { display: "1 mês", value: 30 },
      { display: "2 meses", value: 60 },
      { display: "3 meses", value: 90 },
      { display: "6 meses", value: 180 },
      { display: "Agenda completa", value: day },
    ].find((element) => element.value === day);
    return daysFormatter?.display || "";
  };

  const formatTimeDefault = (): string => {
    return schedule?.durationTimeDefault === "personDuration"
      ? dayjs(schedule?.personDurationTime).format("HH:mm")
      : schedule?.durationTimeDefault;
  };

  return (
    <Stack width="100%">
      <Typography
        variant="h6"
        fontWeight="600"
        textAlign="center"
        color="neutral700.main"
        mb={3}
        mt={5}
      >
        Resumo das configurações criadas para a agenda
      </Typography>

      <PresentationHeader order="1" text="Localização" />
      <Grid container justifyContent="space-between" m="16px 40px 36px 40px">
        <Grid item xs={6}>
          <DataPresentation header="Nome" value={`${location?.name}`} />
        </Grid>
        <Grid item xs={6}>
          <DataPresentation
            header={location.serviceOnline ? "Tipo" : "Endereço"}
            value={
              location.serviceOnline
                ? "Teleconsulta"
                : `${address?.filter((el: any) => el?.length)?.join(",")}`
            }
          />
        </Grid>
      </Grid>

      <PresentationHeader order="2" text="Serviço de saúde" />
      <Grid
        container
        justifyContent="space-between"
        m="16px 40px 36px 40px"
        rowSpacing={1}
      >
        <Grid item xs={6}>
          <DataPresentation
            header="Serviços de saúde"
            value={`${healthcare.healthService}`}
          />
          <Stack mt={2}>
            <DataPresentation
              header="Dias disponíveis na semana"
              value={`${healthcare.daysOfWeek}`}
            />
          </Stack>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body1" fontWeight={600}>
            Horários disponíveis na semana
          </Typography>
          <Stack direction="column" width="100%" spacing={1}>
            {healthcare.daysOfWeek.map((el: any, index: number) => (
              <Grid container key={`${el}.${index}`}>
                <Grid item xs={2}>
                  <Typography
                    variant="body2"
                    color="neutral700.main"
                    fontWeight={600}
                  >
                    {`${el}:`}
                  </Typography>
                </Grid>

                {auxiliarTimeAvailable?.map(
                  (auxTime: any, auxIndex: number) => {
                    return (
                      <Grid item xs={3} key={`${auxTime}.${auxIndex}`}>
                        <Stack direction="row" spacing={2}>
                          <Typography variant="body2">
                            {useFormatter.formatTime(auxTime[el]?.timeStart)} -
                            {useFormatter.formatTime(auxTime[el]?.timeEnd)}
                          </Typography>
                        </Stack>
                      </Grid>
                    );
                  }
                )}
              </Grid>
            ))}
          </Stack>
        </Grid>
      </Grid>

      <PresentationHeader order="3" text="Configurações" />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        m="16px 40px 36px 40px"
      >
        <Grid item xs={6}>
          <DataPresentation
            header="Nome da agenda"
            value={`${schedule?.scheduleName}`}
          />
        </Grid>

        <Grid item xs={6}>
          <DataPresentation
            header="Duração padrão de cada consulta"
            value={formatTimeDefault()}
          />
        </Grid>

        {schedule?.advanceWarning ? (
          <Grid item xs={6} mt={2}>
            <DataPresentation
              header="Antecedência de liberação de vagas"
              value={`${formatAntecedencyDays(schedule?.advanceWarning)}`}
            />
          </Grid>
        ) : (
          <> </>
        )}
        {schedule?.professionalActor ? (
          <Grid item xs={6} mt={2}>
            <Typography variant="body1" fontWeight={600} mb={1}>
              Profissional associado a essa agenda
            </Typography>

            <Typography variant="body2">
              {schedule?.professionalActor?.label}
            </Typography>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={6} mt={2}>
          <DataPresentation
            header="Duração da agenda"
            value={`${dayjs(schedule?.dataStart).format(
              "DD/MM/YYYY"
            )} até ${dayjs(schedule?.dataEnd).format("DD/MM/YYYY")}`}
          />
        </Grid>
      </Grid>

      <SubmitActions
        goBack={() => {
          setCurrentStep((prev: number) => prev - 1);
        }}
        goForward={async () => {
          await onNext();
          setCurrentStep((prev: number) => prev + 1);
        }}
        loading={loading}
      />
    </Stack>
  );
}
