import { Controller } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, TimeField } from "@mui/x-date-pickers";

import { FormInputProps } from "../types";

interface ITimeProps extends FormInputProps {
  validate?: any;
  hiddenLabel?: boolean;
}

export function FormTimeInput({
  name,
  control,
  label,
  required,
  mask,
  defaultValue,
  validate,
  hiddenLabel,
}: ITimeProps) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required,
        validate,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimeField
            sx={{
              "& .MuiFilledInput-root": { backgroundColor: "neutral100.main" },
            }}
            slotProps={{
              textField: {
                error: !!error,
                placeholder: "--:--",
                variant: "filled",
              },
            }}
            label={label}
            value={mask ? mask(value) : value}
            onChange={onChange}
            format="HH:mm"
            fullWidth
            hiddenLabel={hiddenLabel || false}
          />
        </LocalizationProvider>
      )}
    />
  );
}
