import React from "react";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import check from "../../../assets/check.svg";
import { ContentBoxTitle, PrimaryButton } from "../../../components/common";

export function ConfirmationMessage() {
  const navigate = useNavigate();

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      width="100%"
      spacing={4}
    >
      <img src={check} alt="Confirmação" />
      <ContentBoxTitle>
        Solicitação de recuperação de senha enviada com sucesso!
      </ContentBoxTitle>
      <Typography
        variant="body1"
        textAlign="center"
        sx={{ color: "neutral600.main" }}
      >
        O link para recuperação de senha foi enviado para o endereço de email
        associado à sua conta.
      </Typography>
      <PrimaryButton
        width="70%"
        height="52px"
        onClick={() => navigate("/signin")}
      >
        Acessar Fluxmed
      </PrimaryButton>
    </Stack>
  );
}
