import { Stack, Typography } from "@mui/material";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";

interface IProps {
  location: fhir5.Location;
  organization?: any;
}

function isExtensionVirtualService(extension: fhir5.Extension) {
  return (
    extension.url ===
      "https://fluxmed.com.br/fhir/StructureDefinition/virtual-service" &&
    extension.valueBoolean === true
  );
}

function isVirtualService(location: fhir5.Location) {
  return !!location?.extension?.find(isExtensionVirtualService);
}

export function LocationInfo({ location, organization }: IProps) {
  return (
    <Stack sx={{ width: "100%", gap: "20px" }}>
      <Typography
        sx={{ color: "neutral1000.main", wordBreak: "break-word" }}
        variant="h5"
        fontWeight={600}
      >
        {organization?.resource?.alias?.[0] ||
          organization?.resource?.name ||
          "-"}
      </Typography>

      <Stack width="100%" direction="row" alignItems="start" gap="20px">
        <Typography variant="body1" color="neutral700.main" textAlign="left">
          Atendimento
        </Typography>
        <Typography
          variant="body1"
          color="neutral1000.main"
          flex={1}
          fontWeight={500}
        >
          {isVirtualService(location) ? "Remoto" : "Presencial"}
        </Typography>
      </Stack>
      {!isVirtualService(location) && (
        <Stack width="100%" direction="row" alignItems="start" gap="20px">
          <Typography
            variant="body1"
            color="neutral700.main"
            textAlign="left"
            sx={{
              wordBreak: "break-word",
              textTransform: "capitalize",
            }}
          >
            Localização
          </Typography>
          <Typography
            variant="body1"
            color="neutral1000.main"
            flex={1}
            fontWeight={500}
            sx={{
              wordBreak: "break-word",
              textTransform: "capitalize",
            }}
          >
            {/* TODO: Criar função geradora de endereço */}
            {location.address?.line?.join(", ")}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
