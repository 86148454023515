import { Dispatch, SetStateAction } from "react";
import { CommonDialog } from "../../../../components/common/CommonDialog";
import { isMobile } from "react-device-detect";
import { DialogContent, DialogTitle } from "@mui/material";
import { BodyAddress } from "./BodyAddress";

interface IProps {
  type: "edit" | "add";
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export function AddressDialog({ type, isOpen, setIsOpen }: IProps) {
  function handleClose() {
    setIsOpen(false);
  }

  return (
    <CommonDialog
      handleClose={handleClose}
      isOpen={isOpen}
      mobileView={isMobile}
    >
      <DialogTitle textAlign="center">
        {type === "edit" ? "Editar endereço" : "Adicionar endereço"}
      </DialogTitle>

      <DialogContent>
        <BodyAddress type={type} handleClose={handleClose} />
      </DialogContent>
    </CommonDialog>
  );
}
