import { Dispatch, SetStateAction } from "react";
import { DisponibilityTab } from "../../schedulingsearch/components/DisponibilityTab";
import { NoDataWarning } from "../../../components/common";

interface IProps {
  slots: fhir5.BundleEntry<fhir5.Slot>[] | undefined;
  loading: boolean;
  location: fhir5.Location;
  setRefetchSlots: Dispatch<SetStateAction<boolean>>;
}

export function SlotsMobile({
  slots,
  loading,
  location,
  setRefetchSlots,
}: IProps) {
  return (
    <>
      {slots ? (
        <DisponibilityTab
          slots={slots}
          loading={loading}
          location={location}
          setRefetchSlots={setRefetchSlots}
        />
      ) : (
        <NoDataWarning />
      )}
    </>
  );
}
