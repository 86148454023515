import { Button, Stack } from "@mui/material";
import { MedicationForm } from "../../Prototype/components/MedicationForm";
import { Add, Edit } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useInterventionsContext } from "../../../contexts/InterventionsContext";

export function Tratamentos({
  tabValueMedication,
  handleChangeMedication,
  control,
  setValue,
  clean,
  setClean,
  handleSubmit,
  action,
  isEdit,
}: any) {
  const { medicationOptions } = useInterventionsContext();

  const onSubmit = async (data: any) => {
    try {
      if (!data.medication) {
        toast.warn("Selecione algum item pra efetuar a prescrição");
        throw new Error("Formulário vazio");
      } else {
        const auxForm = [
          data?.quantity && `${data?.quantity}`,
          data?.form?.label && `${data?.form?.label}`,
          data?.frequency?.label && `a cada ${data?.frequency?.label}`,
          data?.time?.label && `por ${data?.time?.label}`,
        ]
          ?.filter((e: string) => e)
          ?.join(" ");

        const auxItem = {
          name: data?.medication?.name,
          label: data?.medication?.label,
          tag: data?.specialControl
            ? "Controle especial"
            : data?.medication?.tag,
          inputValue: medicationOptions?.find(
            (e: any) => e?.value === data?.medication?.value
          ) || {
            name: data.medication.name,
            value: data.medication.name,
            label: data.medication.name,
          },
          quantity: data?.quantity,
          frequency: data?.frequency,
          formValue: data?.form,
          time: data?.time,
          note: data?.note,
          form: auxForm,
          type: "medication",
          code: data?.medication?.code,
          tabValue: `${tabValueMedication}`,
        };

        action(auxItem);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setClean(true);
    }
  };

  return (
    <>
      <Stack width="100%" maxHeight="60vh" overflow="auto">
        <MedicationForm
          tabValue={tabValueMedication}
          handleChange={handleChangeMedication}
          render={!clean}
          control={control}
          setValue={setValue}
        />

        <Stack
          width="100%"
          direction="row"
          mt={2}
          mb={2}
          justifyContent="right"
        >
          <Button
            sx={{ width: "fit-content", border: "1px solid #007C6D" }}
            onClick={handleSubmit(onSubmit)}
            variant="text"
          >
            {isEdit ? <Edit /> : <Add />}
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
