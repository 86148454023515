import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";

export async function EditOrganization(organization: any, cnes: any) {
  const cnesIndex = organization?.identifier?.findIndex(
    (el: any) => el.system === "cnes"
  );

  const data =
    cnesIndex === -1
      ? [
          {
            op: "add",
            path: "/identifier/-",
            value: {
              system: "cnes",
              value: cnes,
            },
          },
        ]
      : [
          {
            op: "replace",
            path: `/identifier/${cnesIndex}/value`,
            value: cnes,
          },
        ];

  const url = `${endpoints.ADMFHIR}/Organization/${organization.id}`;
  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/json-patch+json",
  };

  const response = await useAxios
    .patch(url, data, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`Edit Organization: ${response.status}`);
      }
      return { data: response.data, status: response.status };
    })
    .catch((error) => {
      console.error("EditOrganization ", error);
      // toast.error(`EditOrganization Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
