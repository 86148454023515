import { Stack } from "@mui/material";
import React from "react";

export function HeaderStack({ children }: { children: React.ReactNode }) {
  return (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      direction="row"
      minWidth="100%"
      paddingX="50px"
      paddingY={1}
      sx={{
        backgroundColor: "primary800.main",
      }}
    >
      {children}
    </Stack>
  );
}
