import { Controller } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import "dayjs/locale/pt-br";

import { FormInputProps } from "../types";
import { Dayjs } from "dayjs";

interface IDateProps extends FormInputProps {
  minDate?: Dayjs;
  maxDate?: Dayjs;
  defaultValue?: Dayjs;
  readonly?: boolean;
  validate?: any;
  auxOnChange?: Function;
}

export function FormDatePicker({
  name,
  control,
  label,
  required,
  minDate,
  maxDate,
  readonly,
  defaultValue,
  validate,
  auxOnChange,
}: IDateProps) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required,
        validate,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
          <DatePicker
            sx={{
              "& .MuiFilledInput-root": { backgroundColor: "neutral100.main" },
            }}
            readOnly={!!readonly}
            label={label}
            onChange={(data) => {
              onChange(data);
              if (typeof auxOnChange === "function") auxOnChange();
            }}
            format="DD/MM/YYYY"
            minDate={minDate}
            maxDate={maxDate}
            value={value ?? ""}
            slotProps={{
              textField: {
                error: !!error,
                variant: "filled",
                fullWidth: true,
              },
            }}
          />
        </LocalizationProvider>
      )}
    />
  );
}
