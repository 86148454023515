import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const InputText = function (props: any) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue}
      rules={props.rules}
      render={({ field }) => (
        <TextField
          {...field}
          {...props}
          multiline={props?.multiline ? true : false}
          minRows={props.minRows}
          maxRows={props.maxRows}
          rows={props.rows}
          variant="filled"
          sx={{
            width: "100%",
            ...props.sx,
          }}
          onFocus={(e) => {
            if (props.onFocus !== undefined) props.onFocus(e);
          }}
          onChange={(e) => {
            if (props.handleChange !== undefined) props.handleChange(e);
            field.onChange(e);
          }}
        />
      )}
    />
  );
};

export default InputText;
