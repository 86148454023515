import { Dialog, IconButton, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import {
  ReactElement,
  ReactEventHandler,
  Ref,
  SyntheticEvent,
  forwardRef,
} from "react";
import { PrescriptionContent } from "../medicaldocumentation/components/PrescriptionContent";
import { Close } from "@mui/icons-material";
import { PrescriptionContentWithoutPDF } from "../medicaldocumentation/components/PrescriptionContentWithoutPDF";
import { usePrescriptionAttendenceContext } from "../../contexts/PrescriptionAttendenceContext";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export function PrescriptionDialog({
  open,
  handleClose,
  references,
}: {
  open: boolean;
  handleClose: ReactEventHandler;
  references: any;
}) {
  const { setIsOpenPrescription } = usePrescriptionAttendenceContext();

  const closeWithRefresh = (event: SyntheticEvent<Element, Event>) => {
    setIsOpenPrescription(false); //Refresh based context and useEffect QuestionnaireViewer.tsx
    handleClose(event);
  };

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      onClose={closeWithRefresh}
      aria-describedby="alert-dialog-slide-description"
    >
      <IconButton
        onClick={closeWithRefresh}
        sx={{ position: "absolute", right: 5, top: 5 }}
      >
        <Close />
      </IconButton>
      <PrescriptionContentWithoutPDF
        handleClose={closeWithRefresh}
        references={references}
      />
    </Dialog>
  );
}
