import { useLocation } from "react-router-dom";
import { PrivateMainLayout } from "../../components/layout";

import { PrescriptionContent } from "./components/PrescriptionContent";

export function MedicalDocumentation() {
  const { state } = useLocation();
  return (
    <PrivateMainLayout>
      <PrescriptionContent references={state?.encounterReference} />
    </PrivateMainLayout>
  );
}
