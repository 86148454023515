import hash from "object-hash";
import base64 from "base-64";
import * as uuid from "uuid";

import * as xml2js from "xml2js";

import { toast } from "react-toastify";

import { endpoints, oids } from "../../configs/Settings";
import { HTTPResponseStatus } from "../../utils/enum";
import { useAuth } from "../../utils/useAuth";
import { useAxios } from "../../utils/useAxios";
import { auxiliarData } from "./AuxliarData";
import { appc_utils } from "./appc_utils";
import dayjs from "dayjs";

export async function APPC_CONSENT({
  rule,
  patient_id,
  subject_id,
  cnes,
  vigency,
  purposeofuse,
}: any) {
  let url = `${endpoints.APPC}`;

  const appc_doc = appc_utils.deepCopy(auxiliarData.appc_consent);

  const rootVal = `${oids.cpf}`;
  // Realizar validação para oid

  const extensionVal = patient_id;

  const resource_patient_id = [
    {
      Resource: {
        ResourceMatch: {
          $: {
            MatchId: "urn:hl7-org:v3:function:II-equal",
          },
          AttributeValue: [
            {
              $: {
                DataType: "urn:hl7-org:v3#II",
              },
              "hl7:InstanceIdentifier": [
                {
                  $: {
                    root: rootVal,
                    extension: extensionVal,
                  },
                },
              ],
            },
          ],
          ResourceAttributeDesignator: [
            {
              $: {
                DataType: "urn:hl7-org:v3#II",
                AttributeId: "urn:ihe:iti:ser:2016:patient-id",
              },
            },
          ],
        },
      },
    },
  ];

  const subjects = purposeofuse
    ? [
        {
          Subject: [
            {
              SubjectMatch: {
                $: {
                  MatchId: "urn:hl7-org:v3:function:II-equal",
                },
                AttributeValue: [
                  {
                    $: {
                      DataType: "urn:hl7-org:v3#II",
                    },
                    "hl7:InstanceIdentifier": [
                      {
                        $: {
                          extension: cnes || subject_id,
                          root: cnes ? oids.organization : rootVal,
                        },
                      },
                    ],
                  },
                ],
                SubjectAttributeDesignator: [
                  {
                    $: {
                      AttributeId: cnes
                        ? "urn:oasis:names:tc:xspa:1.0:subject:organization-id"
                        : "urn:oasis:names:tc:xacml:1.0:subject:subject-id",
                      DataType: "http://www.w3.org/2001/XMLSchema#anyURI",
                    },
                  },
                ],
              },
            },
            {
              SubjectMatch: {
                $: {
                  MatchId: "urn:hl7-org:v3:function:CV-equal",
                },
                AttributeValue: [
                  {
                    $: {
                      DataType: "urn:hl7-org:v3#CV",
                    },
                    "hl7:CodedValue": [
                      {
                        $: {
                          code: purposeofuse,
                          codeSystem: "2.16.840.1.113883.1.11.20448",
                        },
                      },
                    ],
                  },
                ],
                SubjectAttributeDesignator: [
                  {
                    $: {
                      AttributeId:
                        "urn:oasis:names:tc:xspa:1.0:subject:purposeofuse",
                      DataType: "urn:hl7-org:v3#CV",
                    },
                  },
                ],
              },
            },
          ],
        },
      ]
    : [
        {
          Subject: [
            {
              SubjectMatch: {
                $: {
                  MatchId: "urn:hl7-org:v3:function:II-equal",
                },
                AttributeValue: [
                  {
                    $: {
                      DataType: "urn:hl7-org:v3#II",
                    },
                    "hl7:InstanceIdentifier": [
                      {
                        $: {
                          extension: cnes || subject_id,
                          root: cnes ? oids.organization : rootVal,
                        },
                      },
                    ],
                  },
                ],
                SubjectAttributeDesignator: [
                  {
                    $: {
                      AttributeId: cnes
                        ? "urn:oasis:names:tc:xspa:1.0:subject:organization-id"
                        : "urn:oasis:names:tc:xacml:1.0:subject:subject-id",
                      DataType: "http://www.w3.org/2001/XMLSchema#anyURI",
                    },
                  },
                ],
              },
            },
          ],
        },
      ];

  const environments = [
    {
      Environment: [
        {
          EnvironmentMatch: {
            $: {
              MatchId:
                "urn:oasis:names:tc:xacml:1.0:function:dateTime-less-than-or-equal",
            },
            AttributeValue: {
              $: {
                DataType: "http://www.w3.org/2001/XMLSchema#dateTime",
              },
              _: `${new Date(Date.now()).toISOString()}`,
            },
            EnvironmentAttributeDesignator: [
              {
                $: {
                  DataType: "http://www.w3.org/2001/XMLSchema#dateTime",
                  AttributeId:
                    "urn:oasis:names:tc:xacml:1.0:environment:current-dateTime",
                },
              },
            ],
          },
        },
        {
          EnvironmentMatch: {
            $: {
              MatchId:
                "urn:oasis:names:tc:xacml:1.0:function:dateTime-greater-than-or-equal",
            },
            AttributeValue: {
              $: {
                DataType: "http://www.w3.org/2001/XMLSchema#dateTime",
              },
              _: `${
                rule === "Permit" && vigency
                  ? appc_utils
                      .addDays({
                        date: new Date(Date.now()).toISOString(),
                        days: Number(vigency),
                      })
                      .toISOString()
                  : ""
              }`,
            },
            EnvironmentAttributeDesignator: [
              {
                $: {
                  DataType: "http://www.w3.org/2001/XMLSchema#dateTime",
                  AttributeId:
                    "urn:oasis:names:tc:xacml:1.0:environment:current-dateTime",
                },
              },
            ],
          },
        },
      ],
    },
  ];

  appc_doc.PolicySet.Target[0].Subjects[0] = subjects;
  // appc_doc.PolicySet.Target[0].Subjects[0] = subjectVect;
  appc_doc.PolicySet.Target[0].Resources[0] = resource_patient_id;
  appc_doc.PolicySet.Target[0].Environments[0] = environments;

  const appc_uuid = `urn:uuid:${uuid.v1()}`;

  appc_doc.PolicySet.$.PolicySetId = appc_uuid;
  appc_doc.PolicySet.Description = "";
  appc_doc.PolicySet.Policy[0].$.PolicyId = `urn:uuid:${uuid.v1()}`;
  appc_doc.PolicySet.Policy[0].Rule[0].$.RuleId = `urn:uuid:${uuid.v1()}`;
  appc_doc.PolicySet.Policy[0].Rule[0].$.Effect = rule;

  const builder = new xml2js.Builder();
  let xml = builder.buildObject(appc_doc);

  const content_id_doc = uuid.v1();
  xml = xml.replace(
    "<REPLACE_ME/>",
    `<urn:Document id="doc1"><inc:Include href="cid:${content_id_doc}" xmlns:inc="http://www.w3.org/2004/08/xop/include"/></urn:Document>`
  );
  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
    "X-User-token": await useAuth.getXUserToken(),
    "Content-Type": "application/fhir+xml",
  };

  let newBody = auxiliarData.appc_consent_request_fhir;

  const currentDateTime = dayjs();
  const currentDateTimeISO: string = currentDateTime.toISOString();

  const randomValue = Math.random() * 9999;

  // Gere um UUID para documentManifest
  const documentManifestUUID = uuid.v1({
    msecs: parseFloat(`${currentDateTime.valueOf() + randomValue}`),
  });

  // Gere um UUID para documentReference
  const documentReferenceUUID = uuid.v1({
    msecs: parseFloat(`${currentDateTime.valueOf() + randomValue}`),
  });

  const encodedDocument = base64.encode(xml);

  const hashh = hash(encodedDocument);
  const docsize = hashh.length;

  const organizationId = cnes;
  const organizationOID = oids.organization;

  const substValues = [
    rootVal,
    patient_id,
    documentManifestUUID,
    organizationOID,
    organizationId,
    currentDateTime.valueOf(),
    currentDateTimeISO,
    documentReferenceUUID,
    docsize,
    hashh,
    encodedDocument,
  ];

  for (let i = 11; i > 0; i -= 1) {
    const ra = new RegExp(`(${"\\$"}${i})+`, "g");
    newBody = String(newBody).replace(ra, substValues[i - 1]);
  }

  const response = await useAxios
    .post(url, newBody, { headers, timeout: 20000 })
    .then((response) => {
      if (
        response.status !== HTTPResponseStatus.Created &&
        response.status !== HTTPResponseStatus.Success
      ) {
        throw new Error(`APPC_REQUEST: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("APPC_CONSENT ", error);
      // toast.error(`APPC_CONSENT Erro: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
