import { toast } from "react-toastify";
import { endpoints } from "../../../../configs/Settings";
import { HTTPResponseStatus } from "../../../../utils/enum";
import { useAxios } from "../../../../utils/useAxios";
import { OAuthApplication } from "../../../auth";

interface formatFields {
  urlBinary?: string;
  organizationId?: string;
  subjectId?: string;
}

export async function GetBinaryDocument({
  urlBinary,
  organizationId,
  subjectId,
}: formatFields) {
  const url = `${endpoints.MHD}/1.0.1/Binary/${urlBinary}`;

  const bearerToken = await OAuthApplication();

  const headers = {
    Accept: "application/fhir+json",
    Authorization: bearerToken,
    "Content-Type": "application/fhir+json",
    ...(organizationId && {
      "organization-id": organizationId,
    }),
    ...(subjectId && {
      "subject-id": subjectId,
    }),

    purposeofuse: "PATRQT",
  };
  const response = await useAxios
    .get(url, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`GetBinaryDocument: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("GetBinaryDocument ", error);
      // toast.error(`GetBinaryDocument Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
