export enum Steps {
  S1AddDependent,
  S2DependentRegister,
  S3SummaryDependent,
}

export interface IFormInput {
  firstName: string;
  surname: string;
  identifier: string;
  birthdate: Date;
  gender: string;
}
