import { Box, Button, IconButton, Stack, Tooltip } from "@mui/material";
import { FormAutoComplete, FormTextInput } from "../../../../components/form";
import { CustomMedicationOption } from "./CustomMedicationOption";
import { SubmitItemButton } from "./SubmitItemButton";
import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import { GetMedication } from "../../../../services/terminology";
import { HelpOutline, MoreHoriz, Publish } from "@mui/icons-material";

const unit = [
  {
    value: "GRAN",
    label: "Grânulo(s)",
    inputType: "number",
  },
  {
    value: "COM",
    label: "Comprimido(s)",
    inputType: "number",
  },
  {
    value: "CAP",
    label: "Cápsula(s)",
    inputType: "number",
  },
  {
    value: "SOL OR",
    label: "Solução Oral",
  },
  {
    value: "SOL INJ",
    label: "Solução Injetável",
  },
  {
    value: "SOL",
    label: "Solução",
  },

  {
    value: "SUSP",
    label: "Suspensão",
  },
  {
    value: "POM",
    label: "Pomada(s)",
  },
  {
    value: "GEL",
    label: "Gel",
  },
  {
    value: "XAR",
    label: "Xarope(s)",
  },
  {
    value: "INJ",
    label: "Injetável",
  },
  {
    value: "COL",
    label: "Colírio(s)",
  },
  {
    value: "AER",
    label: "Aerossol",
  },
  {
    value: "SUP",
    label: "Supositório",
    inputType: "number",
  },
  {
    value: "AMP",
    label: "Ampola",
    inputType: "number",
  },
  {
    value: "CTG",
    label: "Cartucho",
    inputType: "number",
  },
  {
    value: "FLD",
    label: "Fluido",
  },
  {
    value: "CJ  ",
    label: "Conjunto",
    inputType: "number",
  },
  {
    value: "ENV",
    label: "Envelope ou Sachê",
  },
  {
    value: "BISN",
    label: "Bisnaga",
    inputType: "number",
  },
  {
    value: "DISP",
    label: "Dispositivo(s)",
  },
  {
    value: "BALA",
    label: "Bala",
    inputType: "number",
  },
  {
    value: "LAQ",
    label: "Laca",
  },
];

export function MedicationSearchTabs({
  selectedTab,
  control,
  isEdit,
  handleSubmit,
  action,
  reset,
  setValue,
}: any) {
  let selectedMedication = useWatch({ name: "medication", control });
  let selectedForm = useWatch({ name: "form", control });

  const [isDescriptionOpen, setIsDescriptionOpen] = useState<boolean>(false);
  const [customMedication, setCustomMedication] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [medication, setMedication] = useState([]);

  function extrairNumero(string: string) {
    const match = string?.match(/\d+/);
    return match ? Number(match[0]) : null;
  }

  const medicationOptions =
    medication?.map((el: any, index: number) => {
      const item = {
        class:
          el?.extension?.find(
            (ext: any) =>
              ext.url ===
              "https://fluxmed.com.br/fhir/StructureDefinition/CMEDClasseTerapeutica"
          )?.valueString || "-",
        type:
          el?.extension?.find(
            (ext: any) =>
              ext.url ===
              "https://fluxmed.com.br/fhir/StructureDefinition/CMEDTipoProduto"
          )?.valueString || "-",
        description:
          el?.extension?.find(
            (ext: any) =>
              ext.url ===
              "https://fluxmed.com.br/fhir/StructureDefinition/CMEDSubstancia"
          )?.valueString || "-",
        presentation:
          el?.extension?.find(
            (ext: any) =>
              ext.url ===
              "https://fluxmed.com.br/fhir/StructureDefinition/CMEDApresentacao"
          )?.valueString || "-",
        tag:
          el?.extension?.find(
            (ext: any) =>
              ext.url ===
              "https://fluxmed.com.br/fhir/StructureDefinition/CMEDTarja"
          )?.valueString || "-",
        name:
          el?.extension?.find(
            (ext: any) =>
              ext.url ===
              "https://fluxmed.com.br/fhir/StructureDefinition/CMEDProduto"
          )?.valueString || "-",
        value: el?.code?.coding?.[0]?.code,
        code: el?.code,
      };
      return { ...item, label: `${item?.name} - ${item.presentation}` };
    }) || [];

  const onSubmit = async (data: any) => {
    try {
      if (!data.medication) {
        toast.warn("Selecione algum item pra efetuar a prescrição");
        throw new Error("Formulário vazio");
      } else {
        const auxForm = [
          data?.quantity && `${data?.quantity}`,
          data?.form?.label && `${data?.form?.label}`,
          data?.frequency?.label && `a cada ${data?.frequency?.label}`,
          data?.time?.label && `por ${data?.time?.label}`,
        ]
          ?.filter((e: string) => e)
          ?.join(" ");
        const auxItem = {
          name: data?.medication?.name,
          label: data?.medication?.label,
          tag: data?.medication?.tag,
          inputValue: medicationOptions.find(
            (e: any) => e.value === data.medication.value
          ) || {
            name: data.medication.name,
            value: data.medication.name,
            label: data.medication.name,
          },
          quantity: data?.quantity,
          frequency: data?.frequency,
          formValue: data?.form,
          time: data?.time,
          note: data?.note,
          form: auxForm,
          type: "medication",
          code: data?.medication?.code,
          tabValue: "1",
        };

        action(auxItem);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setCustomMedication(false);
      setIsDescriptionOpen(false);
      reset();
    }
  };

  function verityInputType() {
    const option = unit.find((el: any) => el.value === selectedForm?.value);
    return option?.inputType || "text";
  }

  useEffect(() => {
    async function fetchMedication() {
      const medicationResponse = await GetMedication(
        selectedTab,
        selectedMedication?.name
      );

      if (medicationResponse?.data?.entry) {
        setMedication(
          medicationResponse?.data?.entry?.map((el: any) => el.resource)
        );
      }

      setLoading(false);
    }

    if (selectedMedication?.code) setCustomMedication(false);

    const presentationForm = unit?.find((el: any) =>
      selectedMedication?.label?.includes(el.value)
    );
    if (presentationForm && !customMedication) {
      setValue("form", presentationForm);
    }

    if (selectedMedication && !isDescriptionOpen) setIsDescriptionOpen(true);

    const debounce = setTimeout(() => {
      setLoading(true);
      fetchMedication();
    }, 500);
    return () => clearTimeout(debounce);
  }, [selectedMedication, isDescriptionOpen, setValue]);

  return (
    <Stack gap={1}>
      <FormAutoComplete
        required
        loading={loading}
        name="medication"
        freeSolo
        control={control}
        onInputChange={(e: any) => {
          if (e?.target?.value?.length)
            setValue("medication", {
              name: e?.target?.value,
              label: e?.target?.value,
              value: e?.target?.value,
              type: "medication",
            });
        }}
        label="Selecione o medicamento"
        renderOption={(option: any) => (
          <CustomMedicationOption option={option} />
        )}
        noOptionsText={
          <Button
            sx={{ width: "fit-content", textTransform: "none" }}
            onClick={() => setCustomMedication(true)}
            endIcon={<Publish />}
          >
            Adicionar novo medicamento
          </Button>
        }
        selectOptions={!loading ? medicationOptions : []}
      />

      {isDescriptionOpen && (
        <>
          <Stack direction={{ md: "row" }} gap={2}>
            <Box width={{ xs: "100%", lg: "60%" }}>
              <FormTextInput
                type="number"
                // freeSolo
                // onInputChange={(e: any) => {
                //   if (e?.target?.value?.length)
                //     setValue("quantity", {
                //       name: e?.target?.value,
                //       label: e?.target?.value,
                //       value: e?.target?.value,
                //     });
                // }}
                name="quantity"
                control={control}
                label="Posologia"
                // selectOptions={[]}
              />
            </Box>
            <FormAutoComplete
              onInputChange={(e: any) => {
                if (e?.target?.value?.length)
                  setValue("form", {
                    name: e?.target?.value,
                    label: e?.target?.value,
                    value: e?.target?.value,
                  });
              }}
              name="form"
              control={control}
              label="Forma"
              selectOptions={unit}
            />
            <FormAutoComplete
              freeSolo
              onInputChange={(e: any) => {
                if (e?.target?.value?.length) {
                  const debounce = setTimeout(() => {
                    if (extrairNumero(e?.target?.value) !== null)
                      setValue("frequency", {
                        name: `${extrairNumero(e?.target?.value)} h` || "",
                        label: `${extrairNumero(e?.target?.value)} h` || "",
                        value: extrairNumero(e?.target?.value) || "",
                      });
                    else {
                      setValue("frequency", {
                        name: "",
                        label: "",
                        value: "",
                      });
                    }
                  }, 1200);
                  return () => clearTimeout(debounce);
                }
              }}
              name="frequency"
              control={control}
              label="Intervalo de uso em horas"
              selectOptions={[]}
            />
            <FormAutoComplete
              name="time"
              freeSolo
              onInputChange={(e: any) => {
                if (e?.target?.value?.length) {
                  const debounce = setTimeout(() => {
                    if (extrairNumero(e?.target?.value) !== null)
                      setValue("time", {
                        name: `${extrairNumero(e?.target?.value)} dias` || "",
                        label: `${extrairNumero(e?.target?.value)} dias` || "",
                        value: extrairNumero(e?.target?.value),
                      });
                    else {
                      setValue("time", {
                        name: "",
                        label: "",
                        value: "",
                      });
                    }
                  }, 1200);
                  return () => clearTimeout(debounce);
                }
              }}
              control={control}
              label="Tempo de tratamento em dias"
              selectOptions={[]}
            />

            <Tooltip
              title="Para tempo de tratamento indeterminado digite 0"
              placement="left"
              sx={{
                width: "8px",
                "&:hover": { backgroundColor: "transparent" },
              }}
            >
              <IconButton>
                <HelpOutline />
              </IconButton>
            </Tooltip>
          </Stack>
          <Stack>
            <FormTextInput
              multiline
              rows={3}
              label="Observação"
              name="note"
              control={control}
            />
          </Stack>
        </>
      )}

      <SubmitItemButton isEdit={isEdit} action={handleSubmit(onSubmit)} />
    </Stack>
  );
}
