import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useDatetime } from "../../../../../../utils/useDatetime";
import { DateFilterType } from "../../../../../../utils/interfaces";
import { oids } from "../../../../../../configs/Settings";
import { GetAppointment, GetSchedule } from "../../../../../../services/fhir";
import { useLocationContext } from "../../../../../../contexts/LocationContext";
import { useSessionContext } from "../../../../../../contexts/SessionContext";
import { Schedule } from "../../../../../../components/schedule";
import { NoScheduleOptions } from "../../../../NoScheduleOptions";
import {
  CircularLoading,
  LargeContentBox,
} from "../../../../../../components/common";

export function PractitionerDashboard({ schedule, loadingVerify }: any) {
  const { user, access } = useSessionContext();

  const { formatDateToISO, isDateFilterTypeDay, isSameYearAndMonthAsToday } =
    useDatetime;

  const { location, locationLoading } = useLocationContext();

  const [refetch, setRefetch] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dateFilter, setDateFilter] = useState<Dayjs>(dayjs());
  const [filterType, setFilterType] = useState<DateFilterType>("day");
  const [appointments, setAppointments] =
    useState<fhir5.Bundle<fhir5.Appointment>>();

  useEffect(() => {
    async function fetchAppointments() {
      setLoading(true);

      const locationId = location
        ?.map(
          (element: fhir5.BundleEntry<fhir5.Location>) => element.resource?.id
        )
        ?.join(",");

      const professionalActor = `Practitioner/${oids.cpf}-${user.username}`;

      const startDate =
        isDateFilterTypeDay(filterType) || isSameYearAndMonthAsToday(dateFilter)
          ? formatDateToISO(dateFilter)
          : formatDateToISO(dateFilter.startOf("month"));

      const endDate = isDateFilterTypeDay(filterType)
        ? formatDateToISO(dateFilter)
        : formatDateToISO(dateFilter.endOf("month"));

      const appointmentResponse = await GetAppointment({
        scheduling: true,
        includePatient: true,
        includeLocation: true,
        status: ["booked"],
        actor: locationId,
        startDate,
        endDate,
        sort: "date",
        professionalActor: professionalActor,
      });

      setAppointments(appointmentResponse);
      setLoading(false);
    }

    if (!locationLoading && location !== undefined) {
      fetchAppointments();
    }
  }, [
    dateFilter,
    filterType,
    user.username,
    access.roles,
    formatDateToISO,
    isDateFilterTypeDay,
    isSameYearAndMonthAsToday,
    location,
    locationLoading,
    refetch,
  ]);

  if (loadingVerify) {
    return (
      <LargeContentBox>
        {" "}
        <CircularLoading />{" "}
      </LargeContentBox>
    );
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "neutral0.main",
          borderRadius: "20px",
          mb: 2,
          padding: "20px 20px",
          pb: 4,
        }}
      >
        {!schedule ? (
          <NoScheduleOptions />
        ) : (
          <Schedule
            hasActiveSchedule={schedule?.find((e: any) => e?.resource?.active)}
            bundle={appointments}
            dateFilter={dateFilter}
            filterType={filterType}
            loading={loading}
            setDateFilter={setDateFilter}
            setFilterType={setFilterType}
            setRefetch={setRefetch}
            refetch={refetch}
          />
        )}
      </Box>
    </>
  );
}
