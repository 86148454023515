import { toast } from "react-toastify";
import { endpoints } from "../../configs/Settings";
import { HTTPResponseStatus } from "../../utils/enum";
import { useAxios } from "../../utils/useAxios";

export async function GetAddressByCep(postalCode: any) {
  const url = `${endpoints.ViaCep}`;

  const headers = {
    Accept: "*/*",
    "Content-Type": "application/json",
  };
  const response = await useAxios
    .get(`${url}/ws/${postalCode}/json`, { headers, timeout: 20000 })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("GetAddressByCep ERROR", error);
      toast.error(
        `Erro ao tentar encontrar o Cep, verifique o CEP ou tente novamente: ${error?.message}`
      );
      return {
        error: true,
      };
    });
  if (response) return response;
}
