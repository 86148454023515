import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import dayjs from "dayjs";
import { useFhirData } from "../../../utils/useFhirData";
import { useFormatter } from "../../../utils/useFormatter";

const styles = StyleSheet.create({
  headerExam: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 100,
    textTransform: "uppercase",
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
  },
  contentExam: {
    flexGrow: 1,
    justifyContent: "flex-start",
  },
  footerExam: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "1px",
  },
  titleExam: {
    fontSize: 22,
    width: "100%",
    fontFamily: "Helvetica-Bold",
    textAlign: "center",
    textTransform: "uppercase",
  },
  text: {
    fontSize: 12,
    fontFamily: "Helvetica-Bold",
    textTransform: "uppercase",
    padding: "5px 0px",
  },
  textFooter: {
    fontSize: 12,
    fontFamily: "Helvetica-Bold",
    textTransform: "uppercase",
  },
  textResponse: {
    fontSize: 12,
    fontFamily: "Helvetica",
    textTransform: "uppercase",
  },
  box: {
    marginBottom: 10,
  },
  footerContainer: {
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    height: 120,
    padding: "5px 40px",
  },
  pageBackground: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    minWidth: "100%",
    minHeight: "100%",
    zIndex: -1,
  },
});

export function ExamSolicitation({
  filterExam,
  dataPdf,
  documentHasBackground,
  imgBase64,
  gender,
}: any) {
  const crm = dataPdf?.[0]?.professionalData?.identifier?.[0]?.value
    .split("/")[1]
    .slice(2);

  const ufCrm = dataPdf?.[0]?.professionalData?.identifier?.[0]?.value
    .split("/")[1]
    .slice(0, 2);

  const renderExams = (exams: any[]) => (
    <View
      style={{
        width: "100%",
        backgroundColor: documentHasBackground ? "transparent" : "#e6e6e6",
      }}
    >
      {exams.map((el: any, index: number) => (
        <View key={index} style={styles.box} wrap={false}>
          <Text style={[styles.text, { padding: "2px 0px" }]}>
            {index + 1}.<Text style={styles.textResponse}> {el?.name} </Text>
          </Text>
          {el.note && (
            <Text style={styles.text}>
              OBS:
              <Text style={styles.textResponse}> {el?.note} </Text>
            </Text>
          )}
        </View>
      ))}
    </View>
  );

  const renderFooter = () => (
    <View style={styles.footerContainer} fixed>
      <View
        style={[styles.footerExam, { opacity: documentHasBackground ? 0 : 1 }]}
      >
        <Text style={styles.textFooter}>
          Nome do(a) Médico(a):
          <Text style={styles.textResponse}>
            {dataPdf?.[0]?.professionalData?.practitioner.display || ""}
          </Text>
        </Text>
        <View style={{ display: "flex", flexDirection: "row", gap: 5 }}>
          <Text style={styles.textFooter}>
            CRM: <Text style={styles.textResponse}>{crm || ""}</Text>
          </Text>
          <Text style={styles.textFooter}>
            UF: <Text style={styles.textResponse}>{ufCrm || ""}</Text>
          </Text>
        </View>

        <Text style={styles.textFooter}>
          Local de Atendimento:
          <Text style={styles.textResponse}>
            {dataPdf?.[0]?.location?.alias?.[0] || "-"}
          </Text>
        </Text>
        <Text style={styles.textFooter}>
          Endereço:
          <Text style={styles.textResponse}>
            {useFhirData.formatAddress(dataPdf?.[0].location)}
          </Text>
        </Text>
        <Text style={styles.textFooter}>
          Data de Emissão:
          <Text style={styles.textResponse}>
            {dayjs().format("DD/MM/YYYY")}
          </Text>
        </Text>
      </View>
    </View>
  );

  return (
    <>
      {imgBase64 && (
        <Image src={imgBase64} style={styles.pageBackground} fixed />
      )}

      <View style={styles.headerExam} fixed>
        <Text style={styles.titleExam}>
          {documentHasBackground ? "" : "Solicitação de Exame"}
        </Text>
      </View>

      <View style={styles.contentExam}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text style={styles.text}>
            {gender === "male" ? "dr." : "dra."}{" "}
            <Text style={styles.textResponse}>
              {dataPdf?.[0]?.professionalData?.practitioner.display || ""}
            </Text>
          </Text>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={styles.textFooter}>
              CRM:
              <Text style={styles.textResponse}>{`${crm}-${ufCrm}` || ""}</Text>
            </Text>
          </View>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text style={styles.text}>
            Paciente:
            <Text style={styles.textResponse}>
              {dataPdf?.[0]?.patientData || ""}
            </Text>
          </Text>
          <Text style={styles.text}>
            CPF:
            <Text style={styles.textResponse}>
              {useFormatter.formatCPF(dataPdf?.[0]?.patientCPF)}
            </Text>
          </Text>
        </View>

        {documentHasBackground && (
          <View
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              marginTop: "5px",
            }}
          >
            <Text style={styles.textFooter}>
              DATA:
              <Text style={styles.textResponse}>
                {dayjs().format("DD/MM/YYYY")}
              </Text>
            </Text>
          </View>
        )}

        <View style={{ marginBottom: 10 }}>
          <Text style={styles.text}>Indicação Clínica:</Text>
          {filterExam
            ?.filter((ex: any) => ex?.condition)
            ?.map((el: any, index: number) => (
              <Text
                key={index}
                style={[styles.textResponse, { padding: "2px 0px" }]}
              >
                {el?.condition?.label}
                {el?.condition?.value && ` - ${el?.condition?.value}`}
              </Text>
            ))}
        </View>

        <View style={{ marginBottom: 10 }}>
          <Text style={styles.text}>Prescrição:</Text>
          {renderExams(filterExam)}
        </View>
      </View>

      {renderFooter()}
    </>
  );
}
