import { toast } from "react-toastify";
import { endpoints } from "../../configs/Settings";
import { HTTPResponseStatus } from "../../utils/enum";
import { useAxios } from "../../utils/useAxios";

export async function SendEmail(to: any, message: any, organization: any) {
  const url = `${endpoints.Brevo}`;

  const data = {
    sender: {
      name: "Fluxmed",
      email: "nao-responda@fluxmed.com.br",
    },
    to: [
      {
        email: to.email,
        name: to.name,
      },
    ],
    subject: "Convite Fluxmed",
    htmlContent: `<html>
    <head> </head>
    
    <body><p>Olá
    
    </p> Você recebeu um convite para fazer parte da clínica ${organization} na plataforma Fluxmed.   </p>

    <p> ${message || ""} <p>

    <p> Realize o login ou cadastre-se na plataforma Fluxmed para aceitar o convite.  </p>

    <a href=${endpoints.XDSB_domain}/portal> Acessar Fluxmed  </a>
    
    </body>
    
    </html>`,
  };
  const headers = {
    Accept: "*/*",
    "api-key": `${endpoints.BrevoApiKey}`,
    "Content-Type": "application/json",
  };
  const response = await useAxios
    .post(url, data, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Created) {
        throw new Error(`SendEmail : ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("SendEmail ", error);
      // toast.error(`SendEmail Erro: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
