import { endpoints, oids } from "../../../configs/Settings";
import { useAxios } from "../../../utils/useAxios";
import { HTTPResponseStatus } from "../../../utils/enum";

import { useAuth } from "../../../utils/useAuth";
import { toast } from "react-toastify";
import { OAuthApplication } from "../../auth";

interface IProps {
  identifier?: string;
  name?: string;
  gender?: string;
  birthDate?: string;
  authType?: string;
}

export async function GetPatient({
  identifier,
  name,
  gender,
  birthDate,
  authType,
}: IProps) {
  let url = `${endpoints.MPIFHIR}/Patient`;
  if (identifier) url = `${url}/${oids.cpf}-${identifier}`;
  if (name) url = `${url}?name=${name}`;
  if (gender) url = `${url}&gender=${gender}`;
  if (birthDate) url = `${url}&birthdate=${birthDate}`;

  const headers = {
    Authorization:
      authType === "application"
        ? await OAuthApplication()
        : await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
    Accept: "application/fhir+json",
  };
  const response = await useAxios
    .get(url, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`DeletePatient: ${response.status}`);
      }
      return { status: HTTPResponseStatus.Success, data: response.data };
    })
    .catch((error) => {
      console.error("GetPatient ", error);
      // toast.error(`GetPatient Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
