import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormLabel } from "@mui/material";
import { Controller } from "react-hook-form";

const InputRadio = function (props: any) {
  return (
    <Controller
      name={props?.name}
      control={props?.control}
      rules={props?.rules}
      defaultValue={props?.defaultValue}
      render={({ field }) => (
        <>
          <FormLabel component="legend">{props?.label}</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            aria-label="position"
            sx={{ width: "100%", mb: 4, ...props?.sx }}
            {...field}
            value={field?.value ?? ''}
          >
            {props?.answerOption?.map((element: any, index: number) => {
              return (
                <FormControlLabel
                  key={index}
                  value={element?.valueCoding?.id}
                  control={<Radio />}
                  label={element?.valueCoding?.display}
                />
              );
            })}
          </RadioGroup>
        </>
      )}
    />
  );
};

export default InputRadio;
