import { Avatar, Divider, Grid, Typography } from "@mui/material";

interface IProps {
  order: string;
  text: string;
}

export function PresentationHeader({ order, text }: IProps) {
  return (
    <Grid container direction="row" alignItems="center" spacing={3}>
      <Grid item xs="auto" ml={-1}>
        <Avatar
          sx={{
            width: 25,
            height: 25,
            fontSize: 12,
            fontWeight: 600,
            backgroundColor: "neutral500.main",
          }}
        >
          {order}
        </Avatar>
      </Grid>
      <Grid item xs="auto">
        <Typography
          variant="body1"
          fontWeight={600}
          width="100%"
          sx={{ whiteSpace: "nowrap" }}
        >
          {text}
        </Typography>
      </Grid>
      <Grid item xs>
        <Divider
          sx={{
            backgroundColor: "neutral500.main",
            width: "100%",
            height: "1px",
          }}
        />
      </Grid>
    </Grid>
  );
}
