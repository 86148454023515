import { Stack } from "@mui/material";
import { GenericTable } from "../../components/GenericTable/GenericTable";
import { translateDocuments } from "./components/prescription/PrescriptionAuxiliarTranslation";
import { PrescriptionActions } from "./components/prescription/PrescriptionActions";
import { CircularLoading } from "../../components/common";

export function SignAndDownloadDocument({ base64 }: any) {
  const columns = [
    { column: "Tipo de documento", rowName: "type" },
    { column: "Descrição", rowName: "description" },
  ];
  return (
    <Stack mb={2}>
      {base64?.length ? (
        <GenericTable
          tableRows={base64?.map((el: any, index: number) => {
            const findKey = el?.[`${Object.keys(el)?.[0]}`];

            return {
              type: translateDocuments[findKey?.type.split("-")[0]] || "",
              description:
                findKey?.info?.map((el: any) => el.name).join(", ") ||
                findKey?.info?.[0]?.label ||
                findKey?.info?.[0]?.note ||
                "",
              base64: findKey?.base64,
            };
          })}
          tableColumns={columns}
          actions={(rowData: any) => (
            <PrescriptionActions row={rowData} setLoading={() => {}} />
          )}
        />
      ) : (
        <CircularLoading />
      )}
    </Stack>
  );
}
