import { Clear, FileUpload } from "@mui/icons-material";
import { IconButton, Stack, styled, Tooltip, Typography } from "@mui/material";
import { useState } from "react";

interface IProps {
  fileInputRef: any;
  setFileBase64: any;
  auxiliarText: string;
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export function ButtonAddTemplate({
  fileInputRef,
  setFileBase64,
  auxiliarText,
}: IProps) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [fileInfo, setFileInfo] = useState<string | null>(null);

  const handleClickUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const convertToBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const clearFile = () => {
    setFileBase64(null);
    setFileInfo(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const uploadImage = async (e: any) => {
    const file = e.target.files[0];

    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      if (file.size > 5 * 1024 * 1024) {
        setErrorMessage(
          "O arquivo é muito grande. O tamanho máximo permitido é 5 MB."
        );
        return;
      }

      try {
        const base64 = await convertToBase64(file);
        setFileBase64(base64);
        setErrorMessage(null);
        setFileInfo(
          `${file.name} (${(file.size / 1024 / 1024).toFixed(2)} MB)`
        );
      } catch (dimensionError) {
        setErrorMessage(dimensionError as string);
      }
    } else {
      setErrorMessage(
        "Formato de arquivo não suportado. Por favor, envie um arquivo PNG ou JPG."
      );
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="flex-start" alignItems="center">
        <IconButton onClick={handleClickUpload} sx={{ ml: -1, gap: 1 }}>
          <Tooltip title="Adicionar arquivo">
            <FileUpload sx={{ color: "primary700.main" }} />
          </Tooltip>
          <VisuallyHiddenInput
            ref={fileInputRef}
            type="file"
            accept=".png, .jpg, .jpeg"
            onChange={(e: any) => uploadImage(e)}
          />
          <Typography variant="body1" color="neutral700.main" fontWeight={600}>
            {auxiliarText}
          </Typography>
        </IconButton>
      </Stack>

      {fileInfo && (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          mt={-1}
        >
          <Typography variant="body2" color="success.main">
            {fileInfo}
          </Typography>
          <Tooltip title="Remover template">
            <IconButton onClick={clearFile} size="small">
              <Clear fontSize="small" color="error" />
            </IconButton>
          </Tooltip>
        </Stack>
      )}

      <Stack>
        {errorMessage && (
          <Typography variant="body2" color="error" textAlign="left">
            {errorMessage}
          </Typography>
        )}
      </Stack>
    </>
  );
}
