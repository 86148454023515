import React, { ReactNode, useRef } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

interface IProps {
  title: string;
  subtitle: string;
  children: ReactNode;
  defaultExpaded?: boolean;
}

export function DocumentAccordion({
  title,
  subtitle,
  children,
  defaultExpaded,
}: IProps) {
  const ref = useRef<HTMLHeadingElement>(null);

  return (
    <Accordion defaultExpanded={defaultExpaded} elevation={2}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Stack
          direction="row"
          sx={{
            width: "100%",
            alignItems: "center",
          }}
          justifyContent={{ xs: "left", lg: "space-between" }}
          spacing={{ xs: "4px", lg: 2 }}
        >
          <Typography ref={ref}>{title}</Typography>
          <Typography
            textAlign={{ sx: "left", lg: "center" }}
            color="neutral600.main"
          >
            {subtitle}
          </Typography>
          <Box
            width={{ xs: "0%", lg: ref.current ? ref.current.offsetWidth : 0 }}
          />
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
