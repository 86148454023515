import { Stack } from "@mui/material";
import dayjs from "dayjs";
import { GenericTable } from "../../../../../components/GenericTable/GenericTable";
import { ActionsTableCertificate } from "./ActionsTableCertificate";
import { CircularLoading } from "../../../../../components/common";

export function TableExamSolicitation({
  certificate,
  loading,
  setBinaryUrl,
}: any) {
  const columns = [
    { column: "Profissional", rowName: "practitioner" },

    { column: "Data", rowName: "date" },
    {
      column: "Especialidade",
      rowName: "specialty",
    },
  ];

  const rowData =
    certificate?.[0]?.entry?.map(
      (el: fhir5.BundleEntry<any>, index: number) => {
        const auxPractitioner = el?.resource?.contained?.find(
          (contained: any) => contained?.resourceType === "Practitioner"
        );

        const auxSpecialty =
          el?.resource?.practiceSetting?.coding?.[0]?.display;

        return {
          practitioner:
            auxPractitioner?.name?.[0]?.given?.[0] ||
            auxPractitioner?.identifier?.[0]?.value,
          date: dayjs(el?.resource?.date).format("DD/MM/YYYY") || "-",
          specialty: auxSpecialty || "-",

          binaryUrl: el?.resource?.content?.[0]?.attachment?.url || "",
          note: el,
        };
      }
    ) || [];

  return (
    <Stack width="100%" p={2}>
      {loading ? (
        <CircularLoading />
      ) : (
        <GenericTable
          tableColumns={columns}
          tableRows={rowData}
          actions={(rowData: any) => (
            <ActionsTableCertificate
              row={rowData}
              setBinaryUrl={setBinaryUrl}
            />
          )}
        />
      )}
    </Stack>
  );
}
