import { CommonDialog } from "../../../../components/common/CommonDialog";
import { DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { FormButtonGroup } from "../../../../components/form";

interface IProps {
  data: any;
  setModalOpen: any;
  open: boolean;
  loading: boolean;
  handleDelete: any;
}
export function ModalConfirm({
  data,
  setModalOpen,
  loading,
  open,
  handleDelete,
}: IProps) {
  return (
    <CommonDialog handleClose={() => setModalOpen(false)} isOpen={open}>
      <DialogTitle textAlign="center">
        <Typography variant="h5" fontWeight={600}>
          {data?.description} {`${data?.cid10 ? `- ${data?.cid10}` : ""}   `}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Stack
          height="100%"
          direction="column"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2">
            Gostaria de confirmar a exclusão deste registro?
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-around"
            width="100%"
            mt={3}
          >
            <FormButtonGroup
              onGoBackButtonClick={() => setModalOpen(false)}
              onNextButtonClick={() => {
                handleDelete();
              }}
              goBackButtonText="Não"
              nextButtonText="Sim"
              loading={loading}
            />
          </Stack>
        </Stack>
      </DialogContent>
    </CommonDialog>
  );
}
