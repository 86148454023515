import { Divider } from "@mui/material";
import { ScreenMobile } from "../common/ScreenMobile";
import { LineInformationScheduleMobile } from "./LineInformationScheduleMobile";
import { FormButtonGroup } from "../form";
import { useFormatter } from "../../utils/useFormatter";
import dayjs from "dayjs";
import { NoDataWarning } from "../common";
import { useState } from "react";
import { useLocationContext } from "../../contexts/LocationContext";

interface IProps {
  open: boolean;
  onClose: VoidFunction;
  setScheduleCancelDialog: any;
  isVirtual: boolean;
  data: any;
}

export function ScreenDetailsScheduleMobile({
  open,
  onClose,
  setScheduleCancelDialog,
  data,
  isVirtual,
}: IProps) {
  const { location } = useLocationContext();

  const locationAddress = location
    ?.find((loc: any) => loc?.resource?.id.includes(data?.locationId.id))
    ?.resource?.address?.line?.join(", ");

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <ScreenMobile
      title={`Consulta de ${data?.healthcare}`}
      open={open}
      onClose={onClose}
    >
      {loading ? (
        <NoDataWarning />
      ) : (
        <>
          <LineInformationScheduleMobile
            title="Clínica"
            text={`${useFormatter.formatNameDisplay(data?.location)}`}
          />
          <LineInformationScheduleMobile
            title="Data e horário"
            text={`${dayjs(data?.startTime).format("DD/MM - HH:mm")} horas`}
          />
          <LineInformationScheduleMobile
            title="Profissional"
            text={`${data?.practitioner}`}
          />
          <LineInformationScheduleMobile
            title="Tipo de atendimento"
            text={isVirtual ? `Remoto` : "Presencial"}
          />
          {!isVirtual && locationAddress && (
            <LineInformationScheduleMobile
              title="Localização"
              text={locationAddress}
            />
          )}

          <Divider
            sx={{ mb: 2, width: "100%", background: "neutral400.main" }}
          />

          <LineInformationScheduleMobile title="Última visita" text={``} />
          <FormButtonGroup
            onGoBackButtonClick={onClose}
            onNextButtonClick={setScheduleCancelDialog}
            goBackButtonText="Voltar"
            nextButtonText="Cancelar consulta"
            direction="column-reverse"
          />
        </>
      )}
    </ScreenMobile>
  );
}
