import { Link } from "react-router-dom";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";

export default function OptionsMenuItem({
  title,
  link,
  Icon,
  action,
}: {
  title: string;
  link: string;
  Icon: any;
  action?: any;
}) {
  return (
    <Link to={link} style={{ textDecoration: "none" }} onClick={action}>
      <MenuItem sx={{ "&:hover": { backgroundColor: "primary200.main" } }}>
        <ListItemIcon>
          <Icon sx={{ fontSize: "24px", color: "primary1000.main" }} />
        </ListItemIcon>
        <ListItemText sx={{ color: "neutral1000.main" }}>{title}</ListItemText>
      </MenuItem>
    </Link>
  );
}
