import { Add, Close, Description } from "@mui/icons-material";
import { IconButton, TableCell, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ActivateConsentDialog } from "./ActivateConsentDialog";

interface IProps {
  row: any;
  received: any;
}

export function DependentListActions({ row, received }: IProps) {
  const navigate = useNavigate();

  const auxConsentType = received?.find(
    (el: any) => el?.id === row?.identifier?.replace(/[^a-zA-Z0-9]/g, "")
  );

  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
  const [type, setType] = useState("");

  return (
    <>
      <TableCell align="center">
        {row?.verify && !row?.end && auxConsentType && (
          <Tooltip title="Acessar Registros ">
            <IconButton
              onClick={() => {
                navigate("/summary", {
                  state: {
                    patientId: row?.identifier?.replace(/[^a-zA-Z0-9]/g, ""),
                    purpose: auxConsentType?.purpose,
                  },
                });
              }}
            >
              <Description />
            </IconButton>
          </Tooltip>
        )}

        {row?.start ? (
          <Tooltip title="Desativar">
            <IconButton
              onClick={() => {
                setConfirmDialog(true);
                setType("Remove");
              }}
            >
              <Close sx={{ color: "error600.main" }} />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Ativar ">
            <IconButton
              onClick={() => {
                setConfirmDialog(true);
                setType("Add");
              }}
            >
              <Add />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>

      {confirmDialog && type && (
        <ActivateConsentDialog
          isOpen={confirmDialog}
          row={row}
          setType={setType}
          setIsOpen={setConfirmDialog}
          type={type}
        />
      )}
    </>
  );
}
