import { Typography } from "@mui/material";

const style: any = {
  text: {
    cancelled: "Cancelado",
    proposed: "Pendente",
    pending: "Pendente",
    arrived: "Confirmado",
    booked: "Confirmado",
    valid: "Ativo",
    expired: "Expirado",
    stopped: "Interrompido",
    active: "Solicitado",
    draft: "Solicitado",
    completed: "Finalizado",
    result: "Realizado",
  },
  backgroundColor: {
    cancelled: "error400.main",
    stopped: "neutral300.main",
    proposed: "neutral300.main",
    pending: "neutral300.main",
    arrived: "primary300.main",
    booked: "primary300.main",
    result: "primary300.main",
    valid: "primary300.main",
    expired: "neutral300.main",
    draft: "warning300.main",
    active: "warning300.main",
    completed: "none",
  },
};

export function StatusRow({ status }: any) {
  return (
    <Typography
      sx={{
        color: "neutral1000.main",
        bgcolor: style.backgroundColor[status],
        p: "3px",
        borderRadius: "20px",
      }}
      fontWeight={400}
      textAlign="center"
    >
      {style.text[status]}
    </Typography>
  );
}
