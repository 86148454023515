import { Box, Tab, Tabs } from "@mui/material";
import { ReactNode, SyntheticEvent, useEffect, useState } from "react";
import { MedicationFields } from "./MedicationFields";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ maxHeight: "60vh", pt: 2, overflow: "auto" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function MedicationForm({
  control,
  setValue,
  render,
  tabValue,
  handleChange,
  editTreatment,
}: any) {
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Medicamento" {...a11yProps(0)} />
          <Tab label="Suplemento" {...a11yProps(1)} />
          <Tab label="Fitoterapico" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={tabValue} index={0}>
        <MedicationFields
          render={render}
          control={control}
          setValue={setValue}
          name="medicamento"
          editTreatment={editTreatment}
        />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <MedicationFields
          render={render}
          control={control}
          setValue={setValue}
          name="suplemento"
          editTreatment={editTreatment}
        />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        <MedicationFields
          render={render}
          control={control}
          setValue={setValue}
          name="fitoterápico"
          editTreatment={editTreatment}
        />
      </CustomTabPanel>
    </>
  );
}
