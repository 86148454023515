import { Close, ContentPaste, Edit } from "@mui/icons-material";
import { IconButton, TableCell, Tooltip } from "@mui/material";
import { useState } from "react";
import { ManageProtocolDialog } from "./ManageProtocolDialog";
import { useNavigate } from "react-router-dom";

export function ManageProtocolActions({ row, data, setRefetch }: any) {
  const [open, setOpen] = useState<boolean>(false);
  const [type, setType] = useState("");

  const navigate = useNavigate();

  const edit = data?.filter((e: any) => e?.resource?.id === row?.id);

  function findAnswer({ questionnaireResponseItens, targetId }: any) {
    return (
      questionnaireResponseItens?.find((item: any) => item.linkId === targetId)
        ?.answer?.[0] ||
      questionnaireResponseItens?.find((item: any) => item.linkId === targetId)
        ?.item
    );
  }

  function generateMedication(item: any) {
    //console.log("Select:", auxType);
    const medicationName = findAnswer({
      questionnaireResponseItens: item,
      targetId: "8543714373691",
    })?.valueString;

    const presentation = findAnswer({
      questionnaireResponseItens: item,
      targetId: "6273182343815",
    })?.valueString;

    const medicationValue = findAnswer({
      questionnaireResponseItens: item,
      targetId: "5252423656882",
    })?.valueString;

    const substance = findAnswer({
      questionnaireResponseItens: item,
      targetId: "2043756634085",
    })?.valueString;

    const auxTag = findAnswer({
      questionnaireResponseItens: item,
      targetId: "7570117070815",
    })?.valueString;

    const auxType = findAnswer({
      questionnaireResponseItens: item,
      targetId: "2794386593077",
    })?.valueString;

    const auxFrequency = findAnswer({
      questionnaireResponseItens: item,
      targetId: "1265328124778",
      targetText: "Frequency",
    })?.valueString?.split(" ")?.[0];

    const auxPeriod = findAnswer({
      questionnaireResponseItens: item,
      targetId: "6391319168670",
      targetText: "Period",
    })?.valueString?.split(" ")?.[0];

    const auxQuantity = findAnswer({
      questionnaireResponseItens: item,
      targetId: "4145930684259",
    })?.valueString;

    const medicationForm = findAnswer({
      questionnaireResponseItens: item,
      targetId: "4027816497232",
    })?.valueString;

    const medicationNote = findAnswer({
      questionnaireResponseItens: item,
      targetId: "5201559249537",
    })?.valueString;

    const auxForm = [
      auxQuantity && `${auxQuantity}`,
      medicationForm && `${medicationForm}`,
      auxFrequency && `a cada ${auxFrequency} h`,
      auxPeriod && `por ${auxPeriod} dias`,
    ]
      ?.filter((e: string) => e)
      ?.join(" ");

    return {
      name: medicationName,
      label: `${medicationName}${
        auxType === "medication"
          ? `${presentation ? ` - ${presentation}` : ""}`
          : ``
      }`,

      inputValue: {
        ...(substance && {
          code: {
            coding: [
              {
                code: medicationValue,
                display: substance,
                system: "https://fluxmed.com.br/fhir/CodeSystem/CMED",
              },
            ],
          },
          tag: auxTag,
        }),
        presentation: presentation,
        name: medicationName,

        label: `${medicationName}${
          auxType === "medication"
            ? `${presentation ? ` - ${presentation}` : ""}`
            : ``
        }`,
      },
      tag: auxTag,
      ...(auxQuantity && {
        quantity: { label: auxQuantity, value: auxQuantity },
      }),
      ...(auxFrequency && {
        frequency: { label: `${auxFrequency} h` },
      }),
      formValue: { label: medicationForm },
      ...(auxPeriod && {
        time: { label: `${auxPeriod} dias` },
      }),
      note: medicationNote,
      form: auxForm,
      type: auxType,
      tabValue:
        auxType === "medication"
          ? "1"
          : auxType === "supplement"
          ? "2"
          : auxType === "procedure"
          ? "1"
          : "3",
    };
  }

  function generateService(item: any) {
    const serviceName = findAnswer({
      questionnaireResponseItens: item,
      targetId: "8772001052232",
    })?.valueString;

    const auxCondition = findAnswer({
      questionnaireResponseItens: item,
      targetId: "9571768447124",
    });

    const conditionValue = findAnswer({
      questionnaireResponseItens: auxCondition,
      targetId: "4923176744451",
    })?.valueString;

    const conditionName = findAnswer({
      questionnaireResponseItens: auxCondition,
      targetId: "8170939802572",
    })?.valueString;

    const serviceValue = findAnswer({
      questionnaireResponseItens: item,
      targetId: "379415603318",
    })?.valueString;

    const auxCategory = findAnswer({
      questionnaireResponseItens: item,
      targetId: "1383313118296",
    })?.valueString;

    const serviceNote = findAnswer({
      questionnaireResponseItens: item,
      targetId: "3523489955010",
    })?.valueString;

    return {
      type: "service",
      tabValue: "2",
      inputValue: {
        ...(serviceValue && {
          code: serviceValue,
          system:
            "https://fluxmed.com.br/fhir/r5/CodeSystem/TUSSProcedimentosEventos",
        }),
        display: serviceName,
        name: serviceName,
        value: serviceName,
        label: serviceName,
      },
      name: serviceName,
      tag: "EX",
      form: "",
      note: serviceNote,
      condition: {
        code: conditionValue,
        display: conditionName,
        label: conditionName,
        system: "https://fluxmed.com.br/fhir/r4/CodeSystem/CID10",
        value: conditionValue,
      },
      category: { label: auxCategory },
    };
  }

  function generateCertificate(item: any) {
    const certificate = findAnswer({
      questionnaireResponseItens: item,
      targetId: "8744868479001",
    })?.valueString;

    return {
      tabValue: "3",
      type: "certificate",
      name: "Atestado",
      tag: "AT",
      form: "",
      note: certificate,
    };
  }

  const defaultItens = edit?.map((el: any, index: number) => {
    const auxDisplay = findAnswer({
      questionnaireResponseItens: el?.resource?.item,
      targetId: "438586562091",
    })?.valueString;

    const condition = el?.resource?.item?.find(
      (item: any) => item.linkId === "condition"
    )?.answer;

    const description = findAnswer({
      questionnaireResponseItens: el?.resource?.item,
      targetId: "description",
    })?.valueString;

    const references = findAnswer({
      questionnaireResponseItens: el?.resource?.item,
      targetId: "referencias",
    })?.valueString;

    const itens = el?.resource?.item?.filter(
      (item: any) => item.linkId !== "438586562091"
    );

    const auxItens = itens?.map((prescriptionItem: any) => {
      if (prescriptionItem?.linkId === "2631266001552") {
        return generateMedication(prescriptionItem.item);
      }
      if (prescriptionItem?.linkId === "5455138012969") {
        return generateService(prescriptionItem.item);
      }
      if (prescriptionItem?.linkId === "7695792866978") {
        return generateCertificate(prescriptionItem.item);
      }

      return {};
    });

    return {
      status: el?.resource?.status,
      display: auxDisplay || "-",
      value: index + 1,
      itens: auxItens?.filter((e: any) => e?.name),
      description,
      references,
      condition,
    };
  });
  return (
    <TableCell align="center">
      <Tooltip title="Visualizar">
        <IconButton
          onClick={() => {
            setOpen(true);
            setType("view");
          }}
        >
          <ContentPaste />
        </IconButton>
      </Tooltip>

      <Tooltip title="Editar">
        <IconButton
          onClick={() => {
            navigate("/createprotocol", {
              state: {
                id: row?.id,
                defaultItens: defaultItens?.[0],
              },
            });
          }}
        >
          <Edit />
        </IconButton>
      </Tooltip>

      <Tooltip title="Excluir protocolo">
        <IconButton
          onClick={() => {
            setOpen(true);
            setType("delete");
          }}
        >
          <Close sx={{ color: "red" }} />
        </IconButton>
      </Tooltip>

      {open && (
        <ManageProtocolDialog
          id={row?.id}
          type={type}
          setRefetch={setRefetch}
          isOpen={open}
          handleClose={() => {
            setOpen(false);
            setType("");
          }}
        />
      )}
    </TableCell>
  );
}
