import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { AddNewValue } from "../AddNewValue";
import { StatusAndNote } from "../StatusAndNote";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { CondutasHistory } from "./CondutasHistory";
import { useQuestionnaireContext } from "../../../../contexts/QuestionnaireContext";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import dayjs from "dayjs";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { InfoOutlined } from "@mui/icons-material";
import { useFhirData } from "../../../../utils/useFhirData";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function Condutas() {
  const { openDialog, condutas, setCondutas, setValue } =
    useMedicalCareContext();
  const { questionnaire, response, defaultValues } = useQuestionnaireContext();

  const { user } = useSessionContext();

  const { state } = useLocation();
  const dataConducts = defaultValues?.[2]?.item;

  const condutasItems = questionnaire?.item?.find(
    (el: any) => el.text === "Condutas"
  );

  const responseEntries = response?.entry?.map((el: any) => el?.resource);

  const condutasReponses = responseEntries?.map((el: any) => {
    const awnserDate = el?.authored;
    const filterItens = el?.item
      ?.filter((e: any) => e?.text === "Condutas")?.[0]
      ?.item?.filter((subItem: any) => {
        return subItem?.item?.[0]?.answer;
      });
    const displayReferences = el?.item?.filter(
      (e: any) => e?.linkId === "displayReferences"
    )?.[0]?.item;
    const displayInfo = {
      encounterId: el?.encounter?.reference?.split("/")?.[1],
      healthcare: displayReferences?.[0]?.answer?.[0]?.valueString || "",
      practitioner: displayReferences?.[1]?.answer?.[0].valueString || "",
      identifier: el?.author?.reference?.split("-")?.[1],
    };
    return { awnserDate, filterItens, displayInfo };
  });

  const flatOpenItens = condutasReponses
    ?.filter((el: any) => el?.filterItens?.length)
    ?.flatMap((item: any) => {
      // map para adicionar informações de profissional, healthcare e data ao item de cada resposta
      const auxItem = item?.filterItens?.map((response: any) => {
        return {
          ...response,
          displayInfo: item?.displayInfo,
          awnserDate: item?.awnserDate,
        };
      });

      return auxItem;
    });

  const auxVerify = flatOpenItens?.filter((e: any) => {
    const value = e?.item?.[0]?.answer?.[0]?.valueString;
    const status = e?.item?.[1]?.answer?.[0]?.valueCoding;
    const auxVerifyEncounter =
      e?.displayInfo?.encounterId === state?.encounterReference?.id;

    const auxDuplicate = flatOpenItens?.some((el: any) => {
      const valueToCompare = el?.item?.[0]?.answer?.[0]?.valueString;
      const statusToCompare = el?.item?.[1]?.answer?.[0]?.valueCoding;
      return statusToCompare && valueToCompare.includes(value);
    });

    if (auxVerifyEncounter && status) {
      return true;
    } else {
      return !auxDuplicate && !status && !auxVerifyEncounter;
    }
  });

  return (
    <>
      <Stack
        width="100%"
        maxHeight="60vh"
        overflow="auto"
        spacing={1}
        sx={{ padding: 2 }}
      >
        <AddNewValue
          item={condutasItems?.item?.[0]?.item}
          lista={condutas}
          setLista={setCondutas}
        />
        <Divider orientation="horizontal" />
        <Stack direction="row" gap={1} alignItems="center">
          <Typography
            variant="body1"
            sx={{ my: 2, fontWeight: 500, fontSize: 16, color: "#555" }}
          >
            Lista de condutas em aberto
          </Typography>
          <Chip
            label={auxVerify?.length}
            size={"small"}
            sx={{ fontWeight: 500, fontSize: 12 }}
          />
        </Stack>
        {auxVerify?.map((el: any, index: number) => {
          return (
            <Accordion
              defaultExpanded={index === 0}
              sx={{
                width: "100%",
                boxShadow: "1px 1px 16px 1px rgba(0, 0, 0, 0.1)",
                position: "initial",
                border: "1px solid",
                borderColor: "neutral200.main",
                borderRadius: 1,
              }}
            >
              <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Stack
                  direction="row"
                  width="100%"
                  justifyContent="space-between"
                >
                  {el?.item?.[0]?.answer?.[0]?.valueString.length > 100 ? (
                    <Tooltip
                      placement="right"
                      title={el?.item?.[0]?.answer?.[0]?.valueString}
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -90],
                              },
                            },
                          ],
                        },
                      }}
                    >
                      <Typography
                        fontWeight={500}
                        sx={{
                          color: "#555",
                          wordBreak: "break-word",
                        }}
                      >
                        {el?.item?.[0]?.answer?.[0]?.valueString.substring(
                          0,
                          100
                        )}
                        ...
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography
                      fontWeight={500}
                      sx={{
                        color: "#555",
                        wordBreak: "break-word",
                      }}
                    >
                      {el?.item?.[0]?.answer?.[0]?.valueString}
                    </Typography>
                  )}

                  <Stack direction="row" spacing={2} mr={1}>
                    {el?.displayInfo?.healthcare && (
                      <Chip
                        label={
                          el?.displayInfo?.healthcare || "Sem especialidade"
                        }
                        size="small"
                        sx={{
                          color: el?.displayInfo?.healthcare
                            ? "neutral700.main"
                            : "neutral500.main",

                          fontWeight: 500,
                          backgroundColor: el?.displayInfo?.healthcare
                            ? ""
                            : "#f1f1f1",
                          width: "fit-content",
                        }}
                      />
                    )}
                    <Chip
                      label={dayjs(el?.awnserDate)
                        ?.format("DD MMM YYYY")
                        ?.toUpperCase()}
                      size="small"
                      sx={{ fontWeight: 500, fontSize: 12, color: "#555" }}
                    />
                  </Stack>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                {el?.displayInfo?.identifier === user?.username ? (
                  <StatusAndNote
                    index={index}
                    type="CondutasPassadas"
                    answer={el?.item?.[0]?.answer?.[0]?.valueString}
                  />
                ) : (
                  <Stack
                    direction="row"
                    //bgcolor="primary200.main"
                    // bgcolor={bgColor ? `${bgColor}` : "transparent"}
                    alignItems="center"
                    spacing={1}
                    width="100%"
                    height="60px"
                    justifyContent="left"
                  >
                    <InfoOutlined />
                    <Typography variant="subtitle2" fontWeight={400}>
                      Conduta adicionada por outro profissional
                    </Typography>
                  </Stack>
                )}
              </AccordionDetails>

              <Stack
                sx={{ padding: 2 }}
                direction="row"
                width="100%"
                alignItems="center"
              >
                {el?.displayInfo?.practitioner && (
                  <Typography
                    color="neutral700.main"
                    sx={{ fontWeight: 500, fontStyle: "italic", fontSize: 12 }}
                  >
                    Dr. {el?.displayInfo?.practitioner}
                  </Typography>
                )}
              </Stack>
            </Accordion>
          );
        })}
      </Stack>

      {!openDialog ? (
        <Stack
          width="100%"
          minWidth="600px"
          overflow="auto"
          height="60vh"
          paddingRight={3}
        >
          <CondutasHistory filter={""} />
        </Stack>
      ) : (
        <></>
      )}
    </>
  );
}
