import { Add, Delete } from "@mui/icons-material";
import { Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { InputDate, InputText } from "../Form";

export function RenderResultsList({
  control,
  values,
  setValue,
  name,
  dateRef,
  unregister,
}: any) {
  const [inputs, setInputs] = useState<any>([]);

  const handleAddInput = () => {
    // Adiciona um novo input ao estado do array
    setValue(
      `801d90b0-0fd1-410f-89e5-d26087895039_${name}_newresult_${inputs.length}`,
      name
    );
    const newInput = {
      valueId: `4a58a481-b19a-41a5-9865-d3e0821b1005_${name}_newresult_${inputs.length}`,
      dateId: `7296a61f-f41c-4325-9518-be203663594a_${name}_newresult_${inputs.length}`,
    };
    setInputs([...inputs, newInput]);
  };

  return (
    <Stack mt={1}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item direction="row" xs={3} spacing={2}>
          <Stack minWidth="120px">
            <Typography
              variant="body1"
              fontWeight={500}
              sx={{
                padding: 1,
                background: "#009E8C",
                borderRadius: "5px",
                height: "fit-content",
                width: "fit-content",
              }}
            >
              {name}
            </Typography>
          </Stack>

          {/* <Stack sx={{ minWidth: "fit-content" }}>
            <Typography> Data de realização </Typography>
            <Typography> Valor </Typography>
          </Stack> */}
        </Grid>

        <Grid item direction="row" xs={8} spacing={1} overflow="auto">
          <Stack justifyContent="space-between" direction="row">
            <Stack direction="row" spacing={1} sx={{ maxWidth: "100%" }}>
              {values?.map((res: any, index: number) => {
                return (
                  <Stack alignItems="center" sx={{ minWidth: "150px" }}>
                    <Typography justifyContent="center">
                      {res?.item?.[2]?.answer?.[0]?.valueDate
                        ? dayjs(res?.item?.[2]?.answer?.[0]?.valueDate)
                            ?.format("DD-MMM-YYYY")
                            ?.toUpperCase()
                        : "-"}
                    </Typography>
                    <Typography justifyContent="center">
                      {res?.item?.[1]?.answer?.[0]?.valueString || "-"}
                    </Typography>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={1}>
          <Tooltip title="Adicionar resultado">
            <IconButton onClick={handleAddInput}>
              <Add />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      {inputs?.map((input: any, index: any) => (
        <Stack direction="row" spacing={1} padding={1} alignItems="center">
          {/* <InputText
            key={input?.nameId}
            handleChange={(e: any) => setValue(input?.nameId, e.target.value)}
            label="Nome do exame"
            name={input.nameId}
            control={control}
            sx={{ mb: 4, width: "300px" }}
          /> */}
          <InputText
            key={input?.valueId}
            handleChange={(e: any) => setValue(input?.valueId, e.target.value)}
            label="Valor do exame"
            name={input.valueId}
            control={control}
            sx={{ mb: 4, width: "300px" }}
          />

          <InputDate
            key={input?.dateId}
            label="Data de realização"
            name={input?.dateId}
            defaultValue={dayjs(dateRef)}
            control={control}
          />
          <IconButton
            onClick={() => {
              setInputs(inputs?.filter((e: any, i: number) => index !== i));
              unregister(input.nameId);
              unregister(input.valueId);
              unregister(input?.dateId);
            }}
          >
            <Delete sx={{ color: "error600.main" }} />
          </IconButton>
        </Stack>
      ))}
    </Stack>
  );
}
