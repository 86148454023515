import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { RenderInputFieldsItem } from "./RenderInputFieldsItem";
import FieldsArrayItemQuestionnaire from "../../FieldsArrayItemQuestionnaire";

export type RenderGroupItemProps = {
  item: any;
  text?: string;
  control: any;
  index?: number;
  key?: any;
  firstGroup?: boolean;
  renderBoxGroup?: boolean;
  questionnaire?: any;
  itemQuestionnaire?: any;
};
export function RenderGroupItem({
  item,
  questionnaire,
  itemQuestionnaire,
  control,
  text,
  index,
  key,
  firstGroup,
  renderBoxGroup,
}: RenderGroupItemProps) {
  return (
    <Stack sx={{ marginBottom: 2 }}>
      {renderBoxGroup ? (
        <AccordionDetails>
          {item?.item &&
            item?.item?.map((subItem: any, subItemIndex: number) =>
              RenderInputFieldsItem({
                item: subItem,
                index: subItemIndex,
                key,
                questionnaire: questionnaire,
                text: subItem.text,
                control,
              })
            )}

          {itemQuestionnaire?.repeats && (
            <FieldsArrayItemQuestionnaire
              control={control}
              register={control.register}
              item={itemQuestionnaire}
            />
          )}

          {item?.repeats && (
            <FieldsArrayItemQuestionnaire
              control={control}
              register={control.register}
              item={item}
            />
          )}
        </AccordionDetails>
      ) : (
        <Accordion
          sx={
            firstGroup
              ? {
                  border: "1px solid #ccc",
                  boxShadow: "2px 2px 16px 2px rgba(0, 0, 0, 0.2)",
                  position: "initial",
                }
              : {
                  border: "0.2px solid rgba(0, 0, 0, 0.2)",
                  boxShadow: "none",
                  position: "initial",
                }
          }
        >
          <AccordionSummary
            expandIcon={<GridExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant={firstGroup ? "h6" : "body1"}>
              {item.text}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {item?.item &&
              item?.item?.map((subItem: any, subItemIndex: number) =>
                RenderInputFieldsItem({
                  item: subItem,
                  index: subItemIndex,
                  key,
                  questionnaire: questionnaire,
                  text: subItem.text,
                  control,
                })
              )}

            {itemQuestionnaire?.repeats && (
              <FieldsArrayItemQuestionnaire
                control={control}
                register={control.register}
                item={itemQuestionnaire}
              />
            )}

            {item?.repeats && (
              <FieldsArrayItemQuestionnaire
                control={control}
                register={control.register}
                item={item}
              />
            )}
          </AccordionDetails>
        </Accordion>
      )}
    </Stack>
  );
}
