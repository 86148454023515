import { Stack } from "@mui/material";

import { FormTextInput } from "../../../../components/form";
import { SubmitItemButton } from "./SubmitItemButton";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

interface IProps {
  action: any;
  methods: any;
  isEdit: boolean;
}

export function MedicalCertificate({ action, methods, isEdit }: IProps) {
  const { control, handleSubmit, reset } = methods;
  const [renderField, setRenderField] = useState<boolean>(false);

  const onSubmit = async (data: any) => {
    try {
      if (!data?.certificate) {
        toast.warn("Selecione um exame para realizar a solicitação");
        throw new Error("Campo vazio");
      } else {
        const auxItem = {
          tabValue: "3",
          type: "certificate",
          name: "Atestado",
          tag: "AT",
          form: "",
          note: data?.certificate,
        };
        action(auxItem);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      if (data?.certificate) setRenderField(false);
      reset();
    }
  };

  useEffect(() => {
    setRenderField(true);
  }, [renderField]);

  return (
    <>
      {renderField && (
        <Stack>
          <FormTextInput
            multiline
            rows={5}
            label="Atestado"
            name="certificate"
            control={control}
          />
        </Stack>
      )}
      <SubmitItemButton isEdit={isEdit} action={handleSubmit(onSubmit)} />
    </>
  );
}
