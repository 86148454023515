import { Avatar, Checkbox, Stack } from "@mui/material";
import { Controller } from "react-hook-form";

export function AvailableDays({ selectedDays, control, register }: any) {
  const daysofweek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];

  return (
    <Stack direction="row" spacing={3} marginY={2}>
      {daysofweek.map((el, index) => (
        <Controller
          key={`daysOfWeek${index}`}
          name="daysOfWeek"
          control={control}
          render={({ field: { value } }) => (
            <Checkbox
              {...register("daysOfWeek", value)}
              checked={selectedDays?.includes(el)}
              value={el}
              icon={
                <Avatar
                  sx={{
                    bgcolor: "neutral400.main",
                    color: "neutral1000.main",
                  }}
                >
                  {daysofweek[index].charAt(0)}
                </Avatar>
              }
              checkedIcon={
                <Avatar
                  sx={{
                    bgcolor: "primary700.main",
                    color: "neutral0.main",
                  }}
                >
                  {daysofweek[index].charAt(0)}
                </Avatar>
              }
              sx={{ width: 30, height: 30 }}
            />
          )}
        />
      ))}
    </Stack>
  );
}
