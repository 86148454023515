import { endpoints } from "../../../configs/Settings";
import { useAxios } from "../../../utils/useAxios";
import { HTTPResponseStatus } from "../../../utils/enum";

import { useAuth } from "../../../utils/useAuth";
import { toast } from "react-toastify";

interface IProps {
  id?: string;
  includePatient?: boolean;
  patient?: string;
}

export async function GetRelatedPerson({
  id,
  includePatient,
  patient,
}: IProps) {
  let url = `${endpoints.ADMFHIR}/RelatedPerson`;
  if (id) url = `${url}?_id=${id}`;
  if (patient) url = `${url}?patient=${patient}`;
  if (includePatient) url = `${url}&_include=RelatedPerson:patient`;

  const headers = {
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
    Accept: "application/fhir+json",
  };
  const response = await useAxios
    .get(url, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`GetRelatedPerson: ${response.status}`);
      }
      return { status: HTTPResponseStatus.Success, data: response.data };
    })
    .catch((error) => {
      console.error("GetRelatedPerson ", error);
      // toast.error(`GetRelatedPerson Error: ${error?.response?.status}`);
      // throw new Error();
    });
  if (response) return response;
}
