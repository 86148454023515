import { Pages } from "@mui/icons-material";
import { IFeatures } from "../../utils/interfaces";
import { Home } from "./Home";
import { currentFeatures } from "../../configs/Settings";

export function useHome() {
  const configs: IFeatures = {
    initial: true,
    active: currentFeatures.home,
    path: "/home",
    text: "Painel de controle",
    icon: Pages,

    sideMenu: true,
    sideMenuIndex: 0,

    component: () => <Home />,
  };

  return configs;
}
