import { Dayjs } from "dayjs";
export enum TabIndex {
  NextAppointments = "1",
  AvailableSlots = "2",
  CompletedAppointments = "3",
}

export interface IDateLimit {
  minDate: boolean;
  maxDate: boolean;
}

export enum Steps {
  S1SelectPatient,
  S2CreateResponsible,
  S3CreatePatient,
  S4ConfirmRelationship,
  S5SelectAppointmentActors,
  S6ConfirmAppointment,
}

export interface IEditScheduleDialog {
  scheduleName: string;
  dataStart: Dayjs;
  dataEnd: Dayjs;
}

export type FilterType = "month" | "day";
