import {
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { CommonDialog } from "../../../../components/common/CommonDialog";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { GetSchedule, PostAppointment } from "../../../../services/fhir";
import dayjs from "dayjs";
import { useFhirData } from "../../../../utils/useFhirData";
import { toast } from "react-toastify";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { oids } from "../../../../configs/Settings";
import { FormButtonGroup, FormSelectInput } from "../../../../components/form";
import { isMobile } from "react-device-detect";
import { useForm } from "react-hook-form";
import { useFormatter } from "../../../../utils/useFormatter";
import { useNavigate } from "react-router-dom";

interface Iprops {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  location: fhir5.Location;
  scheduleId: string;
  slot: any;
  setRefetchSlots: Dispatch<SetStateAction<boolean>>;
}

export function ConfirmAppointmentDialog({
  isOpen,
  setIsOpen,
  location,
  scheduleId,
  slot,
  setRefetchSlots,
}: Iprops) {
  const navigate = useNavigate();
  const { user, userPatient, patientRelated } = useSessionContext();
  const [loading, setLoading] = useState(false);
  const [practitioner, setPractitioner] = useState<fhir5.Reference>();
  const [healthcareService, setHealthcareService] = useState<fhir5.Reference>();
  const [locationActor, setLocationActor] = useState<fhir5.Reference>();
  const { control, watch } = useForm<any>();
  const selectedPatient = watch("patients");

  const auxAddress = location?.address?.line
    ? location.address.line.join(" ,")
    : [
        location?.address?.city,
        location?.address?.state,
        location?.address?.postalCode,
      ]
        .filter((el) => el)
        .join(", ");

  function handleClose() {
    setIsOpen(false);
  }

  function isExtensionVirtualService(extension: fhir5.Extension) {
    return (
      extension.url ===
        "https://fluxmed.com.br/fhir/StructureDefinition/virtual-service" &&
      extension.valueBoolean === true
    );
  }

  function isVirtualService(location: fhir5.Location) {
    return !!location?.extension?.find(isExtensionVirtualService);
  }

  const DependentList =
    patientRelated
      ?.map((el: fhir5.Patient | any) => ({
        name: el?.name?.[0]?.text,
        identifier: useFormatter.formatCPF(el?.identifier?.[0]?.value || ""),
        isValid: !el?.period?.end,
      }))
      ?.filter((related: any) => related?.isValid) || [];

  const PatientOptions: Array<any> = [
    { display: user.name, value: user.username },
    ...DependentList?.map((dependent: any) => ({
      display: dependent.name,
      value: dependent.identifier,
    })),
  ];

  const onSubmit = async () => {
    setLoading(true);
    const selectedOption = PatientOptions?.find(
      (option) => option.value === selectedPatient
    );
    const data = {
      cpf: selectedOption?.value.replace(/[.-]/g, "") || user?.username,
      typeService: "CHECKUP",
      healthCareServiceActor: healthcareService,
      professionalActor: practitioner,
      patientActor: {
        reference: `Patient/${oids.cpf}-${
          selectedOption?.value?.replace(/[.-]/g, "") || user?.username
        }`,
        display: selectedOption?.display || user?.name,
      },
      locationActor,
      slotInfo: {
        id: slot.id,
        start: slot.start,
        end: slot.end,
      },
    };
    try {
      await PostAppointment(data);
      navigate("/");
      toast.success("Consulta marcada com sucesso!");
      setRefetchSlots((prev) => !prev);
      handleClose();
    } catch (err) {
      toast.error("Não foi possível preencher a vaga!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function asyncCall() {
      const schedule = await GetSchedule({ id: scheduleId });
      setHealthcareService(
        useFhirData.findActor(schedule.actor, "HealthcareService")
      );
      setPractitioner(useFhirData.findActor(schedule.actor, "Practitioner"));
      setLocationActor(useFhirData.findActor(schedule.actor, "Location"));
    }
    if (scheduleId) asyncCall();
  }, []);

  return (
    <CommonDialog
      handleClose={handleClose}
      isOpen={isOpen}
      mobileView={isMobile}
    >
      <DialogTitle textAlign="left">Confirmar agendamento</DialogTitle>
      <DialogContent>
        <DialogContentText
          paddingBottom={{ xs: 2, md: 3 }}
          color="neutral700.main"
        >
          Você solicitou o agendamento de uma consulta com as seguintes
          caracteristicas:
        </DialogContentText>
        {DependentList?.length ? (
          <>
            <Stack marginBottom={4}>
              <FormSelectInput
                name="patients"
                control={control}
                label="Nome do paciente"
                selectOptions={PatientOptions}
                required
                defaultValue={user?.username}
              />
            </Stack>
          </>
        ) : (
          <></>
        )}
        <Stack>
          <Stack
            minWidth="100%"
            justifyContent="left"
            spacing={{ xs: 4, md: 19 }}
            direction="row"
          >
            <Stack>
              <Typography
                variant="body1"
                fontWeight={600}
                color="neutral700.main"
                paddingBottom={1}
              >
                Data e hora
              </Typography>
              <Typography variant="body1" color="neutral700.main">
                Dia: {dayjs(slot.start).format("DD/MM/YYYY")}
              </Typography>
              <Typography variant="body1" color="neutral700.main">
                Horário: {dayjs(slot.start).format("HH:mm")}
              </Typography>
            </Stack>
            <Stack>
              <Typography
                variant="body1"
                fontWeight={600}
                color="neutral700.main"
                paddingBottom={1}
              >
                Serviço de saúde:
              </Typography>
              <Typography variant="body1" color="neutral700.main">
                {healthcareService?.display}
              </Typography>
            </Stack>
          </Stack>

          <Stack
            minWidth="100%"
            justifyContent="left"
            spacing={{ xs: 1, md: 19 }}
            direction={{ xs: "column", md: "row" }}
            paddingTop={{ xs: 1, md: 3 }}
          >
            <Stack>
              <Typography
                variant="body1"
                fontWeight={600}
                color="neutral700.main"
                paddingBottom={1}
              >
                Clínica:
              </Typography>
              <Typography variant="body1" color="neutral700.main">
                {location.name}
              </Typography>
            </Stack>
            <Stack>
              <Typography
                variant="body1"
                fontWeight={600}
                color="neutral700.main"
                paddingBottom={1}
              >
                Localização:
              </Typography>
              <Typography variant="body1" color="neutral700.main">
                {auxAddress}
              </Typography>
            </Stack>
          </Stack>

          <Stack
            minWidth="100%"
            justifyContent="left"
            spacing={{ xs: 1, md: 19 }}
            direction={{ xs: "column", md: "row" }}
            paddingTop={{ xs: 1, md: 3 }}
          >
            <Stack>
              <Typography
                variant="body1"
                fontWeight={600}
                color="neutral700.main"
                paddingBottom={1}
              >
                Tipo de atendimento:
              </Typography>
              <Typography variant="body1" color="neutral700.main">
                {isVirtualService(location) ? "Remoto" : "Presencial"}
              </Typography>
            </Stack>
            <Stack>
              <Typography
                variant="body1"
                fontWeight={600}
                color="neutral700.main"
                paddingBottom={1}
              >
                Profissional:
              </Typography>
              <Typography variant="body1" color="neutral700.main">
                {practitioner?.display}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          minWidth="100%"
          mt={3}
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <FormButtonGroup
            onGoBackButtonClick={() => setIsOpen(false)}
            onNextButtonClick={onSubmit}
            goBackButtonText="Cancelar"
            nextButtonText="Confirmar"
            loading={loading}
          />
        </Stack>
      </DialogContent>
    </CommonDialog>
  );
}
