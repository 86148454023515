import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Fade, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useLoggedUserContext } from "../../../../contexts/LoggedUserContext";
import { IFeatures } from "../../../../utils/interfaces";
import { SideBarItem } from "./SideBarItem";
import { Link, useLocation } from "react-router-dom";
import { useSideBarContext } from "../../../../contexts/SideBarContext";

export function SideBarSection({ data, isOpen }: any) {
  const { pathname } = useLocation();
  const { sideBarItens } = useLoggedUserContext();
  const { handleExpand, expandedSections } = useSideBarContext();

  const subItens = sideBarItens
    ?.filter((e: IFeatures) => e.parent === data.text)
    ?.sort((a: any, b: any) => a?.sideMenuIndex - b?.sideMenuIndex);

  const activeGroup = subItens?.some(
    (subItemBar: IFeatures) =>
      subItemBar?.path === pathname && subItemBar?.parent === data.text
  );

  return (
    <>
      <Tooltip
        placement="right"
        title={data.text}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -14],
                },
              },
            ],
          },
        }}
      >
        <Stack
          onClick={(event) => {
            handleExpand(data.text, expandedSections?.[data.text] || false);
          }}
          sx={{
            cursor: "pointer",
            backgroundColor: activeGroup
              ? "primary200.main"
              : expandedSections?.[data.text]
              ? "neutral200.main"
              : "transparent",
            borderRadius: "8px",
          }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mx={1}
          spacing={1}
          px={1.2}
          ml={data?.subitem && isOpen ? 5 : 1}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <data.icon
              sx={{
                color: "neutral700.main",
                width: data?.subitem && "18px",
              }}
            />

            <Fade in={isOpen} appear={false}>
              <Link
                to={data.path}
                key={`sidebar-link-${data.text}`}
                style={{ textDecoration: "none" }}
              >
                <Typography
                  color={"neutral700.main"}
                  variant="body1"
                  fontWeight={500}
                  sx={{ color: "neutral700.main" }}
                  noWrap
                >
                  {data.text}
                </Typography>
              </Link>
            </Fade>
          </Stack>
          <IconButton
            onClick={() => {
              handleExpand(data.text, expandedSections?.[data.text] || false);
            }}
          >
            {expandedSections?.[data.text] ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Stack>
      </Tooltip>
      {expandedSections?.[data.text] && (
        <>
          {subItens?.map((el: IFeatures, index: number) => (
            <SideBarItem
              key={`sec-item-${index}`}
              data={el}
              isOpen={isOpen}
              selected={el.path === pathname}
            />
          ))}
        </>
      )}
    </>
  );
}
