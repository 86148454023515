import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller } from "react-hook-form";

const InputDateTime = function (props: any) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue}
      rules={props.rules}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            sx={{
              "& .MuiFilledInput-root": { backgroundColor: "neutral100.main" },
              mb: 4,
              ...props.sx,
            }}
            slotProps={{
              textField: {
                variant: "filled",
              },
            }}
            {...field}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default InputDateTime;
