import { Divider, Stack, Typography } from "@mui/material";
import { CardRow, EditableCardRow } from "../../../components/common";
import { useContractContext } from "../../../contexts/ContractContext";

import { useOrganizationContext } from "../../../contexts/OrganizationContext";

import dayjs from "dayjs";
import { useState } from "react";
import { DetailsPlanDialog } from "./DetailsPlanDialog";
import { CancelPlanDialog } from "./CancelPlanDialog";
import { endpoints } from "../../../configs/Settings";

export function CardSubscription() {
  const { currentContract } = useContractContext();
  const { organization } = useOrganizationContext();
  const [openEditPlan, setOpenEditPlan] = useState<boolean>(false);

  const [openDetailsContract, setOpenDetailsContract] = useState<boolean>();

  const resource = currentContract?.[0]?.resource;

  const currentPlan = resource?.term?.[0].text || "-";
  const financialResponsible =
    organization?.extension?.find((el) =>
      el.url?.includes("OrganizationManager")
    )?.extension?.[1]?.valueString || "-";
  const nextPaymentDueDate = resource?.extension?.find((el) =>
    el?.url.includes("nextPaymentDueDate")
  )?.valueDate;

  const formattedDate = nextPaymentDueDate
    ? dayjs(nextPaymentDueDate).format("DD/MM/YYYY")
    : "-";

  return (
    <Stack
      id="signature"
      sx={{
        border: "1px solid",
        borderColor: "neutral700.main",
        borderRadius: "15px",
      }}
      width="100%"
      paddingTop={2}
    >
      <Typography
        variant="h5"
        fontWeight="500"
        paddingBottom={3}
        paddingLeft={5}
      >
        Assinatura
      </Typography>
      <EditableCardRow
        title="Plano atual"
        type="details"
        titleContext={currentPlan}
        onClick={() => setOpenDetailsContract(true)}
      />
      <Divider sx={{ marginLeft: 5 }} />
      <CardRow
        title="Responsável financeiro"
        titleContext={financialResponsible}
      />
      <Divider sx={{ marginLeft: 5 }} />
      {currentContract?.length ? (
        <EditableCardRow
          title="Próximo pagamento"
          auxText="Cancelar assinatura"
          colorButton="error700.main"
          onClick={() => setOpenEditPlan(!openEditPlan)}
          titleContext={formattedDate}
        />
      ) : (
        <EditableCardRow
          title="Próximo pagamento"
          auxText="Renovar Assinatura"
          onClick={() => {
            localStorage.setItem("operation", "renewPlan");
            localStorage.setItem("organizationValue", `${organization?.id}`);
            window.location.replace(`${endpoints.REGISTER}`);
          }}
          titleContext={formattedDate}
        />
      )}

      <Divider sx={{ marginLeft: 5 }} />
      {openDetailsContract && (
        <DetailsPlanDialog
          open={openDetailsContract}
          handleClose={() => setOpenDetailsContract(false)}
        />
      )}
      {openEditPlan && (
        <CancelPlanDialog open={openEditPlan} setOpen={setOpenEditPlan} />
      )}
    </Stack>
  );
}
