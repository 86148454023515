import { Stack, Typography } from "@mui/material";

interface ICardDescription {
  title: string;
  description: string;
  titleSize?: string;
}

export function CardDescription({
  title,
  description,
  titleSize,
}: ICardDescription) {
  return (
    <Stack direction="row" alignItems="start" spacing={2}>
      <Typography
        variant="body1"
        color="neutral700.main"
        width={titleSize || "20%"}
        textAlign="left"
        sx={{ wordBreak: "break-word", textTransform: "capitalize" }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        color="neutral1000.main"
        flex={1}
        textAlign="left"
        fontWeight={500}
        sx={{ wordBreak: "break-word", textTransform: "capitalize" }}
      >
        {description}
      </Typography>
    </Stack>
  );
}
