import { Button, Stack } from "@mui/material";
import { AuxiliarPrescriptionButtons } from "./prescription/AuxiliarPrescriptionButtons";
import { DocumentAccordion } from "./DocumentAccordion";
import { PatientInfo } from "./patientinfo/PatientInfo";
import { Footer } from "./Footer";
import { useFhirData } from "../../../utils/useFhirData";
import { useEffect, useState } from "react";
import { BundlePatientSummary } from "../../../services/fhir";
import { TreatmentAccordion } from "./prescription/TreatmentAccordion";
import { ProcedureAccordion } from "./prescription/ProcedureAccordion";
import { Add } from "@mui/icons-material";
import { SelectProtocolDialog } from "./prescription/SelectProtocolDialog";
import { usePrescriptionAttendenceContext } from "../../../contexts/PrescriptionAttendenceContext";

interface IProps {
  prescriptionItens: Array<any>;
  setPrescriptionItens: any;
  references: any;
  patient: fhir5.Patient | undefined;
  appointment: any;
  setDataPdf: any;
}

export function MedicalPrescription({
  prescriptionItens,
  setPrescriptionItens,
  references,
  patient,
  appointment,
  setDataPdf,
}: IProps) {
  const [openSelectProtocol, setOpenSelectProtocol] = useState<boolean>(false);
  const patientRef = useFhirData.findParticipant(
    appointment?.participant,
    "Patient"
  );
  const patientName = patient?.name?.[0]?.text || patientRef?.display || "-";
  const { expandedInfoPatient } = usePrescriptionAttendenceContext();

  const addItem = (item: any) => {
    setPrescriptionItens((prev: any) => [...prev, item]);
  };

  const [patientSummary, setPatientSummary] = useState<any>();
  const [refetch, setRefetch] = useState<boolean>(false);

  useEffect(() => {
    async function fetchResources() {
      const patientSummaryResponse = await BundlePatientSummary({
        identifier: references?.subject,
      });

      setPatientSummary(patientSummaryResponse);
    }
    fetchResources();
  }, [refetch, references]);

  return (
    <Stack direction="column" spacing={2} mb={2}>
      <AuxiliarPrescriptionButtons
        auxPrescription={prescriptionItens}
        addItem={addItem}
      />
      <DocumentAccordion
        title="Paciente"
        subtitle="Informações do paciente"
        defaultExpaded={expandedInfoPatient}
      >
        <PatientInfo
          patientSummary={patientSummary}
          setRefetch={setRefetch}
          references={references}
          patientData={patient}
          patientReference={patientRef}
        />
      </DocumentAccordion>
      <Button
        sx={{ width: "fit-content", textTransform: "none" }}
        startIcon={<Add />}
        onClick={() => {
          setOpenSelectProtocol(true);
        }}
      >
        Selecionar protocolo salvo
      </Button>

      <TreatmentAccordion
        prescriptionItens={prescriptionItens}
        setPrescriptionItens={setPrescriptionItens}
        addItem={addItem}
        summary={patientSummary}
      />
      <ProcedureAccordion
        prescriptionItens={prescriptionItens}
        setPrescriptionItens={setPrescriptionItens}
        addItem={addItem}
        summary={patientSummary}
      />
      <Footer
        patientName={patientName}
        participant={appointment?.participant}
        auxPrescription={prescriptionItens}
        setDataPdf={setDataPdf}
      />
      {openSelectProtocol && (
        <SelectProtocolDialog
          isOpen={openSelectProtocol}
          handleClose={() => setOpenSelectProtocol(false)}
          addItem={addItem}
        />
      )}
    </Stack>
  );
}
