import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { GetQuestionnaire } from "../services/fhir/questionnaire/GetQuestionnaire";
import { endpoints } from "../configs/Settings";
import {
  GetQuestionnaireResponse,
  GetQuestionnairesResponse,
} from "../services/fhir/patientsummary/questionnaireResponse/GetQuestionnaireResponse";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";

interface QuestionnaireContextValue {
  questionnaire: any;
  loadingQuestionnaire: boolean;
  questionnairesResponse: any;
  loadingResponse: boolean;
  response: any;
  historyValues: any;
  defaultValues: any;
  questionnaireDefualt: any;
}

export const QuestionnaireContext = createContext<QuestionnaireContextValue>(
  null!
);

export function QuestionnaireContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [questionnaire, setQuestionnaire] = useState<any>();
  const [response, setResponse] = useState<any>();
  const [questionnairesResponse, setQuestionnairesResponse] = useState<any>();
  const { state } = useLocation();

  const questionnaireDefualt = response?.entry?.find(
    (attendance: any) =>
      attendance.resource?.encounter?.reference?.split("/").pop() ===
      state?.encounterReference?.id
  );

  const defaultValues = questionnaireDefualt?.resource?.item;

  const historyValues = response?.entry
    ?.filter(
      (attendance: any) =>
        attendance.resource?.encounter?.reference?.split("/").pop() !==
        state?.encounterReference?.id
    )
    ?.sort((a: any, b: any) => {
      const dateA = dayjs(a?.resource?.authored);
      const dateB = dayjs(b?.resource?.authored);

      return dateB.diff(dateA);
    })
    ?.filter((attendance: any) =>
      defaultValues
        ? dayjs(attendance?.resource?.authored).isBefore(
            questionnaireDefualt?.resource?.authored
          )
        : true
    );

  const [loadingQuestionnaire, setLoadingQuestionnaire] =
    useState<boolean>(true);
  const [loadingResponse, setLoadingResponse] = useState<boolean>(true);

  useEffect(() => {
    async function getQuestionnaire() {
      setLoadingQuestionnaire(true);
      try {
        const fetchQuestionnaire = await GetQuestionnaire({
          questionnaire: endpoints.QUESTIONNARIE_ID,
        });

        setQuestionnaire(fetchQuestionnaire);
      } catch (error) {
        setQuestionnaire(undefined);
        console.log(error);
      } finally {
        setLoadingQuestionnaire(false);
      }
    }

    if (!questionnaire) getQuestionnaire();
  }, []);

  useEffect(() => {
    async function fetchQuestionnairesResponse() {
      setLoadingResponse(true);
      try {
        const fetchQuestionnaireResponse = await GetQuestionnaireResponse({
          patient: state?.encounterReference?.subject,
          questionnaire: endpoints.QUESTIONNARIE_ID,
        });

        const fetchQuestionnairesResponse = await GetQuestionnairesResponse({
          patient: state?.encounterReference?.subject,
        });

        setResponse(fetchQuestionnaireResponse);
        setQuestionnairesResponse(fetchQuestionnairesResponse);
      } catch (error) {
        setResponse(undefined);
        setQuestionnairesResponse(undefined);

        console.log(error);
      } finally {
        setLoadingResponse(false);
      }
    }
    if (state?.encounterReference?.subject) fetchQuestionnairesResponse();
  }, [state?.encounterReference?.subject]);

  const contextValue = {
    response,
    historyValues,
    defaultValues,
    questionnaire,
    questionnairesResponse,
    loadingQuestionnaire,
    questionnaireDefualt,
    loadingResponse,
  };

  return (
    <QuestionnaireContext.Provider value={contextValue}>
      {children}
    </QuestionnaireContext.Provider>
  );
}

export function useQuestionnaireContext() {
  return useContext(QuestionnaireContext);
}
