import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";
import { auxiliarData } from "../AuxiliarData";
import { GetPrescriptionProtocol } from "./GetPrescriptionProtocol";

export async function PutPrescriptionProtocol({
  id,
  objData,
  author,
  subject,
}: any) {
  const originalResource = await GetPrescriptionProtocol({
    questionnaire: endpoints.PROTOCOL_ID,
    author: author,
    id,
  });

  const data = originalResource?.entry?.[0]?.resource;
  const itens = auxiliarData.ProtocolResponse({
    objData: objData,
    id: id,
  });

  data.item = itens;

  data.status = objData?.status;

  data.subject = subject;

  const url = `${endpoints.ADMFHIR}/QuestionnaireResponse/${id}`;
  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
  };

  const response = await useAxios
    .put(url, data, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status === 201) {
        throw new Error(`PutPrescriptionProtocol: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("PutPrescriptionProtocol ", error);
      toast.error(`PutPrescriptionProtocol Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
