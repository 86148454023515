import { Controller } from "react-hook-form";
import { FormInputProps } from "../types";
import { Checkbox, FormControlLabel } from "@mui/material";

export function FormCheckBox({
  name,
  control,
  label,
  required,
  checked,
}: FormInputProps) {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field: { onChange, ref, ...field } }) => (
        <FormControlLabel
          control={
            <Checkbox
              inputRef={ref}
              onChange={onChange}
              checked={checked ?? false}
              {...field}
            />
          }
          label={label}
        />
      )}
    />
  );
}
