import React, { useState } from "react";

import { ContentBox } from "../../components/common";
import { PublicEmptyLayout } from "../../components/layout";
import { PasswordData } from "./components/PasswordData";
import { ConfirmationMessage } from "./components/ConfirmationMessage";

export function NewPassword() {
  const [page, setPage] = useState(0);
  return (
    <PublicEmptyLayout>
      <ContentBox>
        <>
          {page === 0 && <PasswordData onClick={setPage} />}
          {page === 1 && <ConfirmationMessage />}
        </>
      </ContentBox>
    </PublicEmptyLayout>
  );
}
