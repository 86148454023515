import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { useContractContext } from "../../../contexts/ContractContext";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";

interface IProps {
  id?: string;
  contractId?: string;
  contracts?: string;
  status?: string;
}

export async function GetPaymentNotice({
  id,
  contractId,
  contracts,
  status,
}: IProps) {
  let url = `${endpoints.ADMFHIR}/PaymentNotice`;
  if (id) url = `${url}/${id}`;
  if (contractId) url = `${url}?request=Contract/${contractId}`;
  if (contracts) url = `${url}?request=${contracts}`;
  if (status) url = `${url}&status=${status}`;

  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
  };
  const response = await useAxios
    .get(url, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`GetPaymentNotice: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("GetPaymentNotice ", error);
      // toast.error(`GetPaymentNotice Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
