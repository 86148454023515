import { Paper, Box, styled } from "@mui/material";

interface IProps {
  children: any;
}

const CustomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.neutral0.main,
  width: "100%",
  alignItems: "center",
  maxWidth: "100%",
  height: "fit-content",
  borderRadius: "30px",
  [theme.breakpoints.down("md")]: {
    padding: "0%",
  },
  [theme.breakpoints.up("xs")]: {
    height: "100%",
  },
}));

export function LargeContentBox({ children }: IProps) {
  return (
    <CustomBox>
      <Paper
        sx={{
          display: "grid",
          width: "100%",
          height: "100%",
          padding: "5% 5%",
          justifyItems: "center",
          minHeight: "600px",
          borderRadius: "20px",
        }}
      >
        {children}
      </Paper>
    </CustomBox>
  );
}
