import { Grid } from "@mui/material";
import {
  FormAutoComplete,
  FormTextInput,
} from "../../../../../components/form";
import { CustomCID10Option } from "../../../../medicaldocumentation/components/prescription/CustomCID10Option";
import { useEffect, useState } from "react";
import { GetValueSet } from "../../../../../services/terminology";
import { GetCodeSystemLookup } from "../../../../../services/terminology/GetCodeSystemLookup";

const clinicalStatusOptions = [
  {
    value: "active",
    label: "Ativo",
  },
  {
    value: "inactive",
    label: "Inativo",
  },
  {
    value: "recurrence",
    label: "Recorrente",
  },
  {
    value: "resolved",
    label: "Resolvido",
  },
  {
    value: "remission",
    label: "Remissão",
  },
  {
    value: "unknown",
    label: "Desconhecido",
  },
];

const verificationStatusOptions = [
  {
    value: "unconfirmed",
    label: "Não confirmado",
  },
  {
    value: "confirmed",
    label: "Confirmado",
  },
  {
    value: "provisional",
    label: "Provisório",
  },
  {
    value: "refuted",
    label: "Refutado",
  },
];

export function ConditionForm({ control, setValue, editCondition }: any) {
  const [loading, setLoading] = useState<boolean>(false);
  const [conditionOptions, setConditionOptions] = useState<any>();
  const [searchCid10, setSearchCid10] = useState<any>();
  const [searchCondition, setSearchCondition] = useState<any>();

  const conditionList =
    searchCid10 ||
    conditionOptions?.expansion?.contains?.map((el: any, index: number) => ({
      ...el,
      label: `${el?.code} - ${el?.display}`,
      value: el?.code,
    })) ||
    [];

  useEffect(() => {
    async function fetchConditions() {
      setSearchCid10(undefined);
      const valueSetReponse = await GetValueSet({
        valueset: "CID10",
        version: "r4",
        filter: searchCondition,
      });
      setConditionOptions(valueSetReponse?.data);
      if (
        searchCondition?.length < 7 &&
        !valueSetReponse?.data?.expansion?.contains
      ) {
        const codeSystemResponse = await GetCodeSystemLookup({
          codeSystem: "https://fluxmed.com.br/fhir/r4/CodeSystem/CID10",
          filter: searchCondition?.toUpperCase(),
        });
        const auxDisplay = codeSystemResponse?.parameter?.find(
          (e: any) => e.name === "display"
        )?.valueString;

        if (auxDisplay) {
          setSearchCid10([
            {
              code: searchCondition?.toUpperCase(),
              label: `${searchCondition?.toUpperCase()} - ${auxDisplay}  `,
              name: auxDisplay,
              display: auxDisplay,
              value: searchCondition?.toUpperCase(),
            },
          ]);
        }
      }

      setLoading(false);
    }

    const debounce = setTimeout(() => {
      setLoading(true);
      fetchConditions();
    }, 500);
    return () => clearTimeout(debounce);
  }, [searchCondition]);

  useEffect(() => {
    if (editCondition) {
      setValue("condition.id", editCondition?.id);
      setValue("condition.condition", {
        display: editCondition?.description,
        code: editCondition?.fullResource?.code?.coding?.[0]?.code,
        system: editCondition?.fullResource?.code?.coding?.[0]?.system,
        label: `${editCondition?.cid10} - ${editCondition?.description}`,
      });
      setValue("condition.clinicalStatus", {
        code: editCondition?.fullResource?.clinicalStatus?.coding?.[0]?.code,
        label: editCondition?.clinicalStatus,
        // value: editCondition?.clinicalStatus,
      });
      setValue("condition.verificationStatus", {
        code: editCondition?.fullResource?.verificationStatus?.coding?.[0]
          ?.code,
        label: editCondition?.verificationStatus,
      });
      setValue("condition.note", editCondition?.fullResource?.note?.[0]?.text);
    }
  }, [editCondition, setValue]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={12}>
        <FormAutoComplete
          required
          freeSolo
          loading={loading}
          name="condition.condition"
          label="Selecionar condição"
          control={control}
          onInputChange={(e: any) => {
            if (e?.target?.value?.length) {
              setValue("condition", {
                display: e?.target.value,
                name: e?.target?.value,
                label: e?.target?.value,
                value: e?.target?.value,
              });
              setSearchCondition(e?.target?.value);
            } else {
              setSearchCondition("");
            }
          }}
          renderOption={(option: any) => <CustomCID10Option option={option} />}
          selectOptions={conditionList}
          defaultValue={editCondition?.description || ""}
          autoFocus
        />
      </Grid>
      <Grid item xs={6}>
        <FormAutoComplete
          loading={false}
          name="condition.verificationStatus"
          label="Status de verificação"
          required
          control={control}
          selectOptions={verificationStatusOptions}
          defaultValue={editCondition?.verificationStatus || ""}
        />
      </Grid>

      <Grid item xs={6}>
        <FormAutoComplete
          loading={false}
          name="condition.clinicalStatus"
          label="Status clínico"
          required
          control={control}
          selectOptions={clinicalStatusOptions}
          defaultValue={editCondition?.clinicalStatus || ""}
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextInput
          multiline
          rows={3}
          label="Observação"
          name="condition.note"
          control={control}
          defaultValue={editCondition?.fullResource?.note?.[0]?.text || ""}
        />
      </Grid>
    </Grid>
  );
}
