import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Grid,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";

import { Close, ExpandMore } from "@mui/icons-material";

import { usePractitionerRoleContext } from "../../../contexts/PractitionerRoleContext";

import dayjs from "dayjs";
import { useSessionContext } from "../../../contexts/SessionContext";
import { useState } from "react";
import { GenericTable } from "../../../components/GenericTable/GenericTable";
import {
  translateDocuments,
  translateTypes,
} from "../../medicaldocumentation/components/prescription/PrescriptionAuxiliarTranslation";
import { PrescriptionActions } from "../../medicaldocumentation/components/prescription/PrescriptionActions";

import { DocumentVisualization } from "../../medicaldocumentation/components/DocumentVisualization";
import { useInterventionsContext } from "../../../contexts/InterventionsContext";
import { useMedicalCareContext } from "../../../contexts/MedicalCareContext";
import { SignedDocsActions } from "../../medicaldocumentation/components/prescription/SignedDocsActions";
import { CircularLoading, NoDataWarning } from "../../../components/common";

export function TabsAssinatura({ patientName, patientCPF }: any) {
  const { user } = useSessionContext();
  const { userSpecialties, userPractitionerRole } =
    usePractitionerRoleContext();
  const { base64, setBase64, selectedInterventions } =
    useInterventionsContext();
  const { clinicalLocation } = useMedicalCareContext();

  const [showPdf, setShowPdf] = useState<boolean>(false);

  const [docToRender, setDocToRender] = useState<string>("");

  const [signedDocView, setSignedDocView] = useState<any>();

  const columns = [
    { column: "Tipo de documento", rowName: "type" },
    { column: "Assinatura", rowName: "sign" },
    // { column: "Descrição", rowName: "description" },
  ];

  let crmPractitioner =
    userPractitionerRole?.identifier?.[0]?.value?.split("/") || "";
  crmPractitioner = [
    crmPractitioner[1]?.substring(0, 2),
    crmPractitioner[1]?.substring(2),
  ];

  const data = [
    {
      title: "Profissional",
      description: `${user?.name}`,
    },

    {
      title: "Especialidade",
      description: `${userSpecialties?.[0]?.coding?.[0]?.display}`,
    },
    { title: "Data", description: `${dayjs()?.format("DD/MM/YYYY")}` },
    {
      title: "Endereço",
      description:
        "ZONA INDUSTRIAL, SIG QUADRA 1, 385, SALA 404 , BRASILIA , DF , 70610410",
    },
  ];

  return (
    <Stack width="100%" maxHeight="70vh" overflow="auto" padding={2}>
      <Accordion defaultExpanded sx={{ width: "100%" }} elevation={5}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Stack direction="row" alignItems="center" gap={1}>
            Assinaturas pendentes
            <Avatar
              sx={{
                width: 26,
                height: 26,
                fontSize: "14px",
                fontWeight: 600,
                backgroundColor: "primary100.main",
                color: "primary1000.main",
              }}
            >
              {base64?.filter((e: any) => !e?.[Object.keys(e)?.[0]]?.sign)
                ?.length || "0"}
            </Avatar>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {!base64 &&
          selectedInterventions?.all?.filter((el: any) => !el?.sign)?.length ? (
            <CircularLoading />
          ) : (
            <Grid container mt={2} borderRadius={1}>
              <Grid item md={showPdf ? 6 : 12} xs={12}>
                {selectedInterventions?.all?.filter((el: any) => !el?.sign)
                  ?.length ? (
                  <GenericTable
                    tableRows={base64
                      ?.map((el: any, index: number) => {
                        const findKey = el?.[`${Object.keys(el)?.[0]}`];
                        const listIndex = findKey?.info?.map((e: any) =>
                          selectedInterventions?.all.indexOf(e)
                        );

                        return {
                          type:
                            translateDocuments[findKey?.type?.split("-")[0]] ||
                            "",
                          sign: findKey?.sign ? "Realizada" : "Pendente",
                          // description:
                          //   (
                          //     <List
                          //       sx={{
                          //         textAlign: "right",
                          //         padding: 0,
                          //         paddingLeft: { xs: 10, sm: 20 },
                          //         margin: 0,
                          //         listStyleType: "disc",
                          //       }}
                          //     >
                          //       {findKey?.info?.map(
                          //         (el: any, index: number) => (
                          //           <ListItem
                          //             key={index}
                          //             sx={{ display: "list-item", padding: 0 }}
                          //           >
                          //             <ListItemText>
                          //               <Typography variant="body2">
                          //                 {el?.name}
                          //               </Typography>
                          //             </ListItemText>
                          //           </ListItem>
                          //         )
                          //       )}
                          //     </List>
                          //   ) ||
                          //   findKey?.info?.[0]?.label ||
                          //   findKey?.info?.[0]?.note ||
                          //   "",
                          base64: findKey?.base64,
                          index,
                          uuid: findKey?.uuid,
                          idValue: findKey?.idValue,
                          listIndex,
                          key: Object.keys(el)?.[0],
                        };
                      })
                      ?.filter((e: any) => e.sign === "Pendente")}
                    tableColumns={columns}
                    actions={(rowData: any) => (
                      <PrescriptionActions
                        row={rowData}
                        setShowPdf={setShowPdf}
                        setDocToRender={setDocToRender}
                      />
                    )}
                  />
                ) : (
                  <>
                    <NoDataWarning />
                  </>
                )}
              </Grid>
              <Grid
                display={showPdf ? "flex" : "none"}
                justifyContent="center"
                item
                md={6}
                xs={12}
              >
                {showPdf && (
                  <Stack justifyContent="center" alignItems="center">
                    <Stack width="100%">
                      <Tooltip title="Fechar">
                        <IconButton
                          sx={{ alignSelf: "flex-end" }}
                          onClick={() => setShowPdf(false)}
                        >
                          <Close />
                        </IconButton>
                      </Tooltip>

                      <Stack
                        width="100%"
                        minWidth={{ xs: "200px", md: "500px", lg: "800px" }}
                        minHeight="500px"
                      >
                        <iframe
                          src={`${docToRender}#zoom=80`}
                          title="Atendimento"
                          width="100%"
                          height="100%"
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                )}
              </Grid>
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ width: "100%" }} elevation={5}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Stack direction="row" alignItems="center" gap={1}>
            Registros assinados
            <Avatar
              sx={{
                width: 26,
                height: 26,
                fontSize: "14px",
                fontWeight: 600,
                backgroundColor: "primary100.main",
                color: "primary1000.main",
              }}
            >
              {selectedInterventions?.all?.filter((el: any) => el?.sign)
                ?.length || "0"}
            </Avatar>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container mt={2} borderRadius={1}>
            <Grid item md={signedDocView ? 6 : 12} xs={12}>
              {selectedInterventions?.all?.filter((el: any) => el?.sign)
                ?.length ? (
                <GenericTable
                  tableRows={selectedInterventions?.all
                    ?.filter((el: any) => el?.sign)
                    ?.map((el: any, index: number) => {
                      return {
                        type: translateTypes[el?.type] || el?.type,
                        description: el?.name,
                        base64: el?.signedBase64,
                        index,
                      };
                    })}
                  tableColumns={[
                    { column: "Descrição", rowName: "description" },
                    { column: "Tipo de documento", rowName: "type" },
                  ]}
                  actions={(rowData: any) => (
                    <SignedDocsActions
                      row={rowData}
                      setSignedDocView={setSignedDocView}
                    />
                  )}
                />
              ) : (
                <>
                  <NoDataWarning />
                </>
              )}
            </Grid>
            <Grid
              display={signedDocView ? "flex" : "none"}
              justifyContent="center"
              item
              md={6}
              xs={12}
            >
              {signedDocView && (
                <Stack justifyContent="center" alignItems="center">
                  <Stack width="100%">
                    <Tooltip title="Fechar">
                      <IconButton
                        sx={{ alignSelf: "flex-end" }}
                        onClick={() => setSignedDocView(undefined)}
                      >
                        <Close />
                      </IconButton>
                    </Tooltip>

                    <Stack
                      width="100%"
                      minWidth={{ xs: "200px", md: "500px", lg: "800px" }}
                      minHeight="500px"
                    >
                      <iframe
                        src={`data:application/pdf;base64,${signedDocView}#zoom=80`}
                        title="Atendimento"
                        width="100%"
                        height="100%"
                      />
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* Lista que renderiza os documentos para gerar o base64 */}

      <div style={{ display: "none" }}>
        <DocumentVisualization
          setBase64={setBase64}
          dataPdf={[
            {
              patientCPF: patientCPF,
              patientData: patientName,
              professionalData: userPractitionerRole,
              location: clinicalLocation,
            },
          ]}
        />
      </div>
      {/* <SignAndDownloadDocument base64={base64} /> */}
      {/* Fim da lista */}
    </Stack>
  );
}
