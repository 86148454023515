import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import PersonalData from "../PersonalData";
import ConfirmAccount from "../ConfirmAccount";
import SecurityData from "../SecurityData";
import ActiveAccount from "../ActiveAccount";
import { ConfirmationCode } from "../ConfirmationCode";
import { Steps } from "../../types";
import { IFormInput } from "../../types";
import { TermsOfUse } from "../../../TermsOfUse/TermOfUse";
import { endpoints } from "../../../../configs/Settings";
import logoHeader from "../../../../assets/fluxmed-logo-horizontal.png";

const defaultValues = {
  firstName: "",
  surname: "",
  identifier: "",
  birthdate: undefined,
  password: "",
  passwordConfirm: "",
  phoneNumber: "",
  email: "",
  gender: "",
  firebaseCode: "",
  firebaseToken: "123456",
};

export function Form() {
  const [page, setPage] = useState(Steps.S1PersonalData);

  const methods = useForm<IFormInput>({ defaultValues });
  const { handleSubmit, control, getValues, setValue, watch, reset } = methods;

  return (
    <FormProvider {...methods}>
      {![Steps.S5TermsOfUse, Steps.S6ActiveAccount].includes(page) && (
        <img
          src={logoHeader}
          alt={`Logo ${endpoints.AppName}`}
          width={185}
          height="auto"
          style={{ marginBottom: "16px" }}
        />
      )}
      {page === Steps.S1PersonalData && (
        <PersonalData
          reset={reset}
          control={control}
          handleSubmit={handleSubmit}
          onNextButtonClick={() => setPage(Steps.S2ConfirmAccount)}
          setValue={setValue}
        />
      )}
      {page === Steps.S2ConfirmAccount && (
        <ConfirmAccount
          watch={watch}
          control={control}
          handleSubmit={handleSubmit}
          onBackButtonClick={() => setPage(Steps.S1PersonalData)}
          navigateToNextPage={() => setPage(Steps.S3ConfirmationCode)}
        />
      )}
      {page === Steps.S3ConfirmationCode && (
        <ConfirmationCode
          control={control}
          getValues={getValues}
          handleSubmit={handleSubmit}
          onBackButtonClick={() => setPage(Steps.S2ConfirmAccount)}
          navigateToNextPage={() => setPage(Steps.S4SecurityData)}
        />
      )}
      {page === Steps.S4SecurityData && (
        <SecurityData
          control={control}
          handleSubmit={handleSubmit}
          onBackButtonClick={() => setPage(Steps.S3ConfirmationCode)}
          navigateToNextPage={() => setPage(Steps.S5TermsOfUse)}
        />
      )}
      {page === Steps.S5TermsOfUse && (
        <TermsOfUse
          termOf="Patient"
          handleSubmit={handleSubmit}
          navigateToNextPage={() => setPage(Steps.S6ActiveAccount)}
          control={control}
        />
      )}
      {page === Steps.S6ActiveAccount && <ActiveAccount />}
    </FormProvider>
  );
}
