import { LockOpen } from "@mui/icons-material";
import { useSessionContext } from "../../contexts/SessionContext";
import { IFeatures } from "../../utils/interfaces";

import { Privacy } from "./Privacy";

export function usePrivacy() {
  const { access } = useSessionContext();

  const configs: IFeatures = {
    active: access.type === "patient",

    path: "/privacy",
    text: "Privacidade",
    icon: LockOpen,

    avatarMenu: true,
    avatarIndex: 4,

    component: () => <Privacy />,
  };

  return configs;
}
