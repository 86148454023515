import { Controller } from "react-hook-form";
import { FormInputProps } from "../types";
import { Autocomplete, TextField } from "@mui/material";

interface IProps {
  selectOptions: {
    value: string | number;
    label: string;
  }[];
  defaultValue?: {
    value: string | number;
    label: string;
  };
  loading?: boolean;
  noOptionsText?: any;
  auxOnChange?: Function;
  onInputChange?: any;
  freeSolo?: boolean;
  autoFocus?: boolean;
}

type TypeMergedInterfaces = FormInputProps & IProps;

export function FormAutoComplete({
  name,
  control,
  label,
  required,
  selectOptions,
  defaultValue,
  loading,
  noOptionsText,
  auxOnChange,
  renderOption,
  freeSolo,
  onInputChange,
  autoFocus,
}: TypeMergedInterfaces) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: required,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <Autocomplete
            sx={{
              "& .MuiFilledInput-root": { backgroundColor: "neutral100.main" },
            }}
            loading={loading}
            onInputChange={onInputChange || false}
            freeSolo={freeSolo}
            disablePortal
            value={value || ""}
            onChange={(_, data) => {
              onChange(data);
              if (typeof auxOnChange === "function")
                auxOnChange() || auxOnChange(data);
            }}
            options={selectOptions}
            getOptionLabel={(option) => option.label || ""}
            isOptionEqualToValue={(option, value) =>
              value === undefined ||
              value === "" ||
              option.value === value.value
            }
            fullWidth
            noOptionsText={noOptionsText}
            renderInput={(params) => (
              <TextField
                variant="filled"
                error={error !== undefined}
                {...params}
                label={label}
                autoFocus={autoFocus}
              />
            )}
            renderOption={
              renderOption
                ? (props, option) => (
                    <li {...props} style={{ paddingLeft: 0 }}>
                      {renderOption(option)}
                    </li>
                  )
                : undefined
            }
          />
        );
      }}
    />
  );
}
