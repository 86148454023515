import { ArrowBack } from "@mui/icons-material";
import { Box } from "@mui/material";
import { PageTitle } from "./PageTitle";
import { useNavigate } from "react-router-dom";

interface IProps {
  choiseSelected: boolean;
  handleClose: VoidFunction;
  children: string;
}

export function HeaderContentBox({
  choiseSelected,
  handleClose,
  children,
}: IProps) {
  const navigate = useNavigate();
  return (
    <Box
      width="100%"
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        alignItems: "start",
      }}
    >
      <ArrowBack
        sx={{
          ":hover": {
            cursor: "pointer",
            backgroundColor: "neutral200.main",
            borderRadius: "50%",
          },
        }}
        onClick={choiseSelected ? handleClose : () => navigate(-1)}
      />
      <PageTitle>{children}</PageTitle>
    </Box>
  );
}
