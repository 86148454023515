import { Divider, Stack, Typography } from "@mui/material";
import { EditableCardRow } from "../../../components/common";

import React, { Dispatch, SetStateAction } from "react";

interface IProps {
  setIsEditCnesOpen: Dispatch<SetStateAction<boolean>>;
  cnesValue: string;
  displayNameValue: string;
  setIsEditAliasOpen: Dispatch<SetStateAction<boolean>>;
}

export function CardProfileClinic({
  setIsEditCnesOpen,
  cnesValue,
  displayNameValue,
  setIsEditAliasOpen,
}: IProps) {
  return (
    <Stack
      sx={{
        border: "1px solid",
        borderColor: "neutral700.main",
        borderRadius: "15px",
      }}
      width="100%"
      paddingTop={2}
    >
      <Typography
        variant="h5"
        fontWeight="500"
        paddingBottom={3}
        paddingLeft={5}
      >
        Perfil da Clínica
      </Typography>

      <EditableCardRow
        title="Nome de apresentação"
        titleContext={displayNameValue || "Nome de apresentação da clínica"}
        onClick={() => {
          setIsEditAliasOpen(true);
        }}
      />

      <EditableCardRow
        title="Número CNES"
        titleContext={
          cnesValue || "Você pode adicionar o CNES da sua clínica aqui."
        }
        onClick={() => {
          setIsEditCnesOpen(true);
        }}
      />

      <Divider sx={{ marginLeft: 5 }} />
      {/* <EditableCardRow
        title="Upload logomarca"
        titleContext="Acrescente a logomarca da sua clínica."
        onClick={() => {}}
      /> */}
    </Stack>
  );
}
