import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

interface PrescriptionAttendenceContextValue {
  isOpenPrescription: boolean;
  isOpenDiagnostic: boolean;
  tabSelected: "1" | "2" | "3";
  expandedInfoPatient: boolean;
  diagnosticValue: DiagnosisValue;
  setIsOpenPrescription: Dispatch<SetStateAction<any>>;
  setIsOpenDiagnostic: Dispatch<SetStateAction<any>>;
  setTabSelected: Dispatch<SetStateAction<any>>;
  setExpandedInfoPatient: Dispatch<SetStateAction<any>>;
  setRefetch: Dispatch<SetStateAction<any>>;
  setDiagnosticValue: Dispatch<SetStateAction<any>>;
}

interface DiagnosisValue {
  label: string;
  value: string;
}

export const PrescriptionAttendenceContext =
  createContext<PrescriptionAttendenceContextValue>(null!);

export function PrescriptionAttendenceContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [isOpenPrescription, setIsOpenPrescription] = useState<boolean>(false);
  const [isOpenDiagnostic, setIsOpenDiagnostic] = useState<boolean>(false);
  const [diagnosticValue, setDiagnosticValue] = useState<DiagnosisValue>({
    label: "",
    value: "",
  });
  const [tabSelected, setTabSelected] = useState<"1" | "2" | "3">("1");
  const [expandedInfoPatient, setExpandedInfoPatient] =
    useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);

  useEffect(() => {
    setRefetch((prev) => !prev);
  }, [refetch]);

  const contextValue = {
    diagnosticValue,
    isOpenPrescription,
    isOpenDiagnostic,
    tabSelected,
    expandedInfoPatient,
    setIsOpenPrescription,
    setIsOpenDiagnostic,
    setTabSelected,
    setExpandedInfoPatient,
    setRefetch,
    setDiagnosticValue,
  };

  return (
    <PrescriptionAttendenceContext.Provider value={contextValue}>
      {children}
    </PrescriptionAttendenceContext.Provider>
  );
}

export function usePrescriptionAttendenceContext() {
  return useContext(PrescriptionAttendenceContext);
}
