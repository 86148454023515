import { Warning } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { PrimaryButton } from "../../../components/common";
import { useNavigate } from "react-router-dom";

export function DocumentNotFound() {
  const navigate = useNavigate();
  return (
    <Stack justifyContent="center" alignItems="center" spacing={2}>
      <Warning fontSize="large" sx={{ color: "warning600.main" }} />
      <Typography textAlign="center" variant="h6" fontWeight={500}>
        Nenhum documento foi encontrado
      </Typography>
      <Typography variant="subtitle2" color="neutral700.main">
        Verifique e tente novamente
      </Typography>

      <Typography variant="body1" color="neutral700.main" pt={2}>
        Experimente nossa plataforma oficial e desfrute de uma experiência{" "}
        <br />
        excepcional em atendimentos presenciais e remotos.
      </Typography>
      <PrimaryButton width="100%" height="42px" onClick={() => navigate("/")}>
        Acessar
      </PrimaryButton>
    </Stack>
  );
}
