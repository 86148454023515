import { DialogContent, DialogTitle, Stack, Typography } from "@mui/material";

import { useState } from "react";
import { toast } from "react-toastify";

import { isMobile } from "react-device-detect";
import { useSessionContext } from "../../../contexts/SessionContext";
import { CommonDialog } from "../../../components/common/CommonDialog";
import { FormButtonGroup } from "../../../components/form";
import { PatchRelatedPerson } from "../../../services/fhir/relatedperson/PatchRelatedPerson";
import dayjs from "dayjs";
import { APPC_CONSENT } from "../../../services/appc";

export function ActivateConsentDialog({
  isOpen,
  setIsOpen,
  row,
  type,
  setType,
}: any) {
  const { setRefetch, user } = useSessionContext();
  const [loading, setLoading] = useState<boolean>(false);

  const handleConfirm = async () => {
    try {
      setLoading(true);
      if (type === "Remove") {
        await PatchRelatedPerson({
          id: row?.relatedId,
          operation: "replace",
          path: "/period",
          content: {
            end: dayjs().toISOString(),
          },
        });

        await PatchRelatedPerson({
          id: row?.relatedId,
          operation: "replace",
          path: "/active",
          content: false,
        });

        await APPC_CONSENT({
          rule: "Deny",
          patient_id: row?.identifier?.replace(/[.-]/g, ""),
          subject_id: [user?.username],
          cnes: null,
        });
        setRefetch((prev: any) => !prev);
      } else {
        await PatchRelatedPerson({
          id: row?.relatedId,
          operation: row?.start ? "replace" : "add",
          path: "/period",
          content: {
            start: dayjs().toISOString(),
          },
        });

        setRefetch((prev: any) => !prev);
      }
      toast.success(
        `${type === "Add" ? "Ativação" : "Inativação"} realizada com sucesso!`
      );
    } catch {
      console.log("err");
      toast.warn(
        "Não foi possível finalizar a operação, por favor tente novamente!"
      );
    } finally {
      handleClose();
    }
  };

  const handleClose = () => {
    setType("");
    setIsOpen(false);
  };

  return (
    <CommonDialog
      handleClose={handleClose}
      isOpen={isOpen}
      mobileView={isMobile}
    >
      <DialogTitle textAlign="center">
        {type === "Add" ? "Ativar" : "Inativar"} dependente
        <Typography variant="body1" color="neutral700.main">
          {`Após a confirmação o depedente será ${
            type === "Add" ? "ativado" : "inativado"
          }.`}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack width="100%" direction="row" justifyContent="space-between">
          <FormButtonGroup
            onGoBackButtonClick={handleClose}
            onNextButtonClick={handleConfirm}
            goBackButtonText="Cancelar"
            nextButtonText="Confirmar"
            loading={loading}
          />
        </Stack>
      </DialogContent>
    </CommonDialog>
  );
}
