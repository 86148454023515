import {
  Divider,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { Controller, useWatch } from "react-hook-form";
import { useMedicalCareContext } from "../../../contexts/MedicalCareContext";
import { InputText } from "../../ClinicalRegister/components/QuestionnaireViewer/components/Form";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";

export function StatusAndNote({ type, index, answer }: any) {
  const { control, unregister } = useMedicalCareContext();

  const statusWatch = useWatch({
    name: `${type}.${index}_${answer}.status`,
    control,
  });

  const getStatusConfig = (StatusType: string) => {
    if (StatusType === "CondutasPassadas") {
      return [
        { value: 1, label: "Realizada", color: "#007C6D" },
        { value: 2, label: "Parcialmente", color: "#FBBB3C" },
        { value: 3, label: "Não realizada", color: "#EF4352" },
      ];
    } else if (StatusType === "ProblemasPassados") {
      return [
        { value: 1, label: "Melhora", color: "#007C6D" },
        { value: 2, label: "Estável", color: "#FBBB3C" },
        { value: 3, label: "Piora", color: "#EF4352" },
      ];
    } else if (StatusType === "CarePlans") {
      return [
        { value: 1, label: "Concluído", color: "#007C6D" },
        { value: 2, label: "Parcialmente", color: "#FBBB3C" },
        { value: 3, label: "Falhou", color: "#EF4352" },
      ];
    }
    return [
      { value: 1, label: "Conluído", color: "#777" },
      { value: 2, label: "Parcialmente", color: "#777" },
      { value: 3, label: "Falhou", color: "#777" },
    ];
  };

  const statusConfig = getStatusConfig(type);

  return (
    <Stack>
      <Controller
        name={`${type}.${index}_${answer}.status`}
        control={control}
        render={({ field }) => (
          <>
            <Divider sx={{ mb: 2 }}></Divider>
            <Stack
              direction={"row"}
              spacing={2}
              alignItems={"end"}
              sx={{ paddingX: 2 }}
            >
              <Stack direction={"column"}>
                <FormLabel
                  component="legend"
                  sx={{ color: "#777", fontWeight: 500 }}
                >
                  Status
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  aria-label="position"
                  sx={{ width: "100%" }}
                  {...field}
                  value={field.value ?? ""}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                >
                  {statusConfig.map((config, idx) => (
                    <FormControlLabel
                      key={`${config.value}_${index}`}
                      value={config.value}
                      control={
                        <Radio
                          sx={{
                            color: config.color,
                            "&.Mui-checked": {
                              color: config.color,
                            },
                          }}
                        />
                      }
                      label={config.label}
                      sx={{ color: "#777" }}
                    />
                  ))}
                  {/* <FormControlLabel
                    key={`1_${index}`}
                    value={1}
                    control={
                      <Radio
                        sx={{
                          color: "#007C6D",
                        }}
                      />
                    }
                    label="Realizada"
                    sx={{ color: "#777" }}
                  />
                  <FormControlLabel
                    key={`2_${index}`}
                    value={2}
                    control={
                      <Radio
                        sx={{
                          color: "#FBBB3C",
                          "&.Mui-checked": {
                            color: "#FBBB3C",
                          },
                        }}
                      />
                    }
                    label="Parcialmente"
                    sx={{ color: "#777" }}
                  />
                  <FormControlLabel
                    key={`3_${index}`}
                    value={3}
                    control={
                      <Radio
                        sx={{
                          color: "#EF4352",
                          "&.Mui-checked": {
                            color: "#EF4352",
                          },
                        }}
                      />
                    }
                    label="Não realizada"
                    sx={{ color: "#777" }}
                  /> */}
                </RadioGroup>
              </Stack>
              {statusWatch && (
                <IconButton
                  sx={{ width: "fit-content" }}
                  aria-label="delete"
                  onClick={() => {
                    field.onChange("");
                    unregister(`${type}.${index}_${answer}.nota`);
                  }}
                >
                  <ReplayRoundedIcon sx={{ color: "colors.neutral700" }} />
                </IconButton>
              )}
            </Stack>
          </>
        )}
      />

      {statusWatch && (
        <InputText
          rows={4}
          key={`${type}.${index}_${answer}.nota`}
          label="Nota"
          name={`${type}.${index}_${answer}.nota`}
          control={control}
        />
      )}
    </Stack>
  );
}
