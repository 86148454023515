import { Delete, Visibility } from "@mui/icons-material";
import { IconButton, TableCell, Tooltip } from "@mui/material";

export function LayoutPdfActions({
  row,
  setModalOpen,
  setBase64,
  setModalDelete,
  setIdDelete,
}: any) {
  return (
    <TableCell align="center">
      <Tooltip title="Visualizar">
        <IconButton
          onClick={() => {
            setBase64(row?.base64);
            setModalOpen(true);
          }}
        >
          <Visibility />
        </IconButton>
      </Tooltip>
      <Tooltip title="Apagar">
        <IconButton
          onClick={() => {
            setIdDelete(row?.id);
            setModalDelete(true);
          }}
        >
          <Delete color="error" />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
}
