import { toast } from "react-toastify";
import { endpoints, oids } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";

export async function GetPerson({ identifier }: any) {
  let url = `${endpoints.ADMFHIR}/Person`;
  if (identifier) url = `${url}/${oids.cpf}-${identifier}`;

  const bearerToken = await useAuth.getUserToken();
  const headers = {
    Accept: "*/*",
    Authorization: bearerToken,
    "Content-Type": "application/fhir+json",
  };
  const response = await useAxios
    .get(url, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`GetPerson: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("GetPerson ", error);
      // toast.error(`GetPerson Error: ${error?.response?.status}`);
      return error?.response;
      // throw new Error();
    });
  if (response) return response;
}
