import { useState } from "react";
import { useWatch } from "react-hook-form";
import { Checkbox, Stack, Typography } from "@mui/material";
import {
  FormButtonGroup,
  FormSelectInput,
  FormTextInput,
} from "../../../../components/form";
import { RequiredField } from "../../../schedule/components/RequiredField";
import { useRegex } from "../../../../utils/useRegex";
import { useFormatter } from "../../../../utils/useFormatter";
import { Steps } from "../../types";

interface IProps {
  control: any;
  setModalOpen: any;
  cpfT: any;
  walkin: any;
  setPage: any;
  handleSubmit: any;
}

export function SelectAppointmentPatient({
  control,
  setModalOpen,
  cpfT,
  walkin,
  setPage,
  handleSubmit,
}: IProps) {
  const [checkbox, setCheckbox] = useState(false);

  const handleCheckbox = () => {
    setCheckbox(!checkbox);
  };

  let cpfValue = useWatch({ name: "cpf", control });
  let cpfResponsible = useWatch({ name: "cpfResponsible", control });

  const typeOptionsService: Array<any> = [
    { display: "Checkup", value: "CHECKUP" },
    { display: "Emergência", value: "EMERGENCY" },
    { display: "Retorno", value: "FOLLOWUP" },
    { display: "Rotina", value: "ROUTINE" },
    { display: "Encaixe", value: "WALKIN" },
    { display: "Primeiro atendimento", value: "FIRSTAPPOINTMENT" },
  ].filter((options) =>
    walkin ? options.value === "WALKIN" : options.value !== "WALKIN"
  );

  const KinshipOptions = [
    { display: "Pai", value: "father" },
    { display: "Mãe", value: "mother" },
    { display: "Responsável", value: "legalDependent" },
  ];

  const onSubmit = () => {
    if (cpfResponsible) {
      setPage(Steps.S2CreateResponsible);
    } else if (cpfValue) {
      setPage(Steps.S3CreatePatient);
    }
  };

  return (
    <Stack spacing={2}>
      <Stack>
        <Typography variant="h6" fontWeight="600" color="neutral600.main">
          Paciente <RequiredField />
        </Typography>
        <Typography variant="body1">
          Digite o CPF do paciente que deseja preencher a vaga.
        </Typography>
        <FormTextInput
          label="CPF"
          name="cpf"
          control={control}
          required={true}
          pattern={!cpfT && useRegex.cpf}
          mask={useFormatter.formatCPF}
          maxLength={14}
          autoFocus
        />
      </Stack>
      <Stack direction="row" alignItems="center" width="100%">
        <Checkbox
          onChange={handleCheckbox}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography variant="body1">O paciente possui responsável</Typography>
      </Stack>
      {!checkbox ? (
        ""
      ) : (
        <Stack>
          <Typography variant="h6" fontWeight="600" color="neutral600.main">
            Responsável legal
            <RequiredField />
          </Typography>
          <Typography variant="body1">
            Digite o CPF do responsável legal.
          </Typography>
          <Stack direction="row" spacing={2}>
            <FormTextInput
              label="CPF"
              name="cpfResponsible"
              control={control}
              required={true}
              pattern={useRegex.cpf}
              mask={useFormatter.formatCPF}
              maxLength={14}
              autoFocus
            />
            <FormSelectInput
              control={control}
              name="relationship"
              label="Grau de parentesco"
              selectOptions={KinshipOptions}
              required
            />
          </Stack>
        </Stack>
      )}

      <Stack>
        <Typography variant="h6" fontWeight="600" color="neutral600.main">
          Atendimento <RequiredField />
        </Typography>
        <Typography variant="body1">
          Escolha o tipo de atendimento dessa consulta.
        </Typography>
      </Stack>

      <FormSelectInput
        name="typeService"
        defaultValue={walkin && "WALKIN"}
        control={control}
        label="Tipo de atendimento"
        selectOptions={typeOptionsService}
        required
      />

      <FormButtonGroup
        goBackButtonText="Fechar"
        onGoBackButtonClick={() => setModalOpen(false)}
        onNextButtonClick={handleSubmit(onSubmit)}
      />
    </Stack>
  );
}
