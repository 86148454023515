import { Stack } from "@mui/material";
import { LargeContentBox, PageTitle } from "../../components/common";
import { PrivateMainLayout } from "../../components/layout";
import { isMobile } from "react-device-detect";
import { Form } from "./components/form";
import { ScreenMobile } from "../../components/common/ScreenMobile";

export function Myfamily({ open, onClose }: any) {
  return isMobile ? (
    <>
      <ScreenMobile title="Minha familia" open={open} onClose={onClose}>
        <Form />
      </ScreenMobile>
    </>
  ) : (
    <PrivateMainLayout noSidebar={isMobile} mobileView={isMobile}>
      <LargeContentBox>
        <Stack sx={{ width: "100%" }} spacing={2}>
          <PageTitle>Minha Família</PageTitle>
          <Form />
        </Stack>
      </LargeContentBox>
    </PrivateMainLayout>
  );
}
