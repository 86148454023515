import { IconButton, Stack } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { OptionsEditScheduleDialog } from "./OptionsEditScheduleDialog";

export function AccordionButtons({
  setOpenAccordion,
  schedule,
  setRefetch,
}: any) {
  return (
    <>
      <Stack direction="row" alignItems="center">
        <IconButton onClick={() => setOpenAccordion((prev: boolean) => !prev)}>
          <ExpandMoreIcon />
        </IconButton>

        <OptionsEditScheduleDialog
          setRefetch={setRefetch}
          scheduleData={schedule?.resource}
        />
      </Stack>
    </>
  );
}
