import { Grid, Stack, Typography } from "@mui/material";
import { FormAutoComplete } from "../../../../../../../components/form";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../../../../components/common";
import { useSessionContext } from "../../../../../../../contexts/SessionContext";
import { useEffect, useState } from "react";
import { GetValueSet } from "../../../../../../../services/terminology";
import { CustomCID10Option } from "../../../../prescription/CustomCID10Option";
import { PostCondition } from "../../../../../../../services/fhir/patientsummary/condition/PostCondition";
import { toast } from "react-toastify";
import { usePrescriptionAttendenceContext } from "../../../../../../../contexts/PrescriptionAttendenceContext";

interface IProps {
  methods: any;
  references: any;
  handleClose: any;
  setRefetch: any;
}
export function CreateConditionsForm({
  methods,
  references,
  handleClose,
  setRefetch,
}: IProps) {
  const { user } = useSessionContext();
  const { control, handleSubmit, setValue } = methods;
  const [conditionOptions, setConditionOptions] = useState<any>();
  const [searchCondition, setSearchCondition] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const { diagnosticValue } = usePrescriptionAttendenceContext();

  const clinicalStatusOptions = [
    {
      value: "active",
      label: "Ativo",
    },
    {
      value: "inactive",
      label: "Inativo",
    },
    {
      value: "recurrence",
      label: "Recorrente",
    },
    {
      value: "resolved",
      label: "Resolvido",
    },
    {
      value: "remission",
      label: "Remissão",
    },
    {
      value: "unknown",
      label: "Desconhecido",
    },
  ];

  const verificationStatusOptions = [
    {
      value: "unconfirmed",
      label: "Não confirmado",
    },
    {
      value: "confirmed",
      label: "Confirmado",
    },
    {
      value: "provisional",
      label: "Provisório",
    },
    {
      value: "refuted",
      label: "Refutado",
    },
  ];

  const conditionList =
    conditionOptions?.expansion?.contains?.map((el: any, index: number) => ({
      ...el,
      label: el?.display,
      value: el?.code,
    })) || [];

  const onSubmit = async (data: any) => {
    if (data && references) {
      try {
        await PostCondition(
          {
            ...data,
            practitionerId: user?.username,
            practitionerName: user?.name,
          },
          references
        );
        setRefetch((prev: boolean) => !prev);
      } catch (err) {
        console.log("Condition", err);
        toast.error("Não foi possível registrar a condição, tente novamente");
      } finally {
        handleClose();
      }
    }
  };

  useEffect(() => {
    async function fetchConditions() {
      const valueSetReponse = await GetValueSet({
        valueset: "CID10",
        version: "r4",
        filter: searchCondition,
      });
      setConditionOptions(valueSetReponse?.data);
      setLoading(false);
    }

    const debounce = setTimeout(() => {
      setLoading(true);
      fetchConditions();
    }, 500);
    return () => clearTimeout(debounce);
  }, [searchCondition]);
  return (
    <Stack width="750px" rowGap={4}>
      <Typography variant="h5" fontWeight={600}>
        Adicionar diagnósticos
      </Typography>
      <Typography>
        Inclua e salve os dados de um diagnóstico informado pelo paciente
      </Typography>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <FormAutoComplete
            required
            freeSolo
            loading={loading}
            name="condition"
            label="Selecionar condição"
            defaultValue={diagnosticValue}
            control={control}
            onInputChange={(e: any) => {
              if (e?.target?.value?.length) {
                setSearchCondition(e?.target?.value);

                setValue("condition", {
                  name: e?.target?.value,
                  label: e?.target?.value,
                  value: e?.target?.value,
                });
              }
            }}
            renderOption={(option: any) => (
              <CustomCID10Option option={option} />
            )}
            selectOptions={conditionList}
          />
        </Grid>
        <Grid item xs={6}>
          <FormAutoComplete
            loading={false}
            name="verificationStatus"
            label="Status de verificação"
            required
            control={control}
            selectOptions={verificationStatusOptions}
          />
        </Grid>

        <Grid item xs={6}>
          <FormAutoComplete
            loading={false}
            name="clinicalStatus"
            label="Status clínico"
            required
            control={control}
            selectOptions={clinicalStatusOptions}
          />
        </Grid>
      </Grid>

      <Stack
        width="100%"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <SecondaryButton
          onClick={handleClose}
          width="fit-content"
          height="40px"
        >
          Cancelar
        </SecondaryButton>

        <PrimaryButton
          onClick={handleSubmit(onSubmit)}
          width="fit-content"
          height="40px"
        >
          Salvar
        </PrimaryButton>
      </Stack>
    </Stack>
  );
}
