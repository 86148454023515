import { Stack } from "@mui/material";

import { useState } from "react";

import dayjs from "dayjs";
import { ISelectOption } from "../types";
import { FormAutoComplete, FormDatePicker } from "../../../components/form";
import { UFValueSet } from "../../../utils/useValueSet";

interface IProps {
  control: any;
  onChange: Function;
}

export function ServiceFilters({ control, onChange }: IProps) {
  const [cityOptions, setCityOptions] = useState<ISelectOption[]>([]);
  const [cityLoading, setCityLoading] = useState<boolean>(false);

  return (
    <Stack direction={{ xs: "column", sm: "row" }} spacing={2} width="100%">
      <FormAutoComplete
        name="serviceModel"
        control={control}
        label="Modelo de atendimento"
        selectOptions={[
          { label: "Remoto", value: "virtualService" },
          { label: "Presencial", value: "physicalLocation" },
        ]}
      />
      {/* <FormAutoComplete
        name="state"
        control={control}
        label="Estado"
        selectOptions={UFValueSet}
        auxOnChange={onChange}
        // TODO: Adicionar ao auxOnChange função para carregar cidades
      /> */}
      {/* <FormAutoComplete
        name="city"
        control={control}
        label="Cidade"
        selectOptions={cityOptions}
        noOptionsText="Selecione um estado"
        loading={cityLoading}
        auxOnChange={onChange}
      /> */}
      <FormDatePicker
        name="date"
        control={control}
        label="Data de preferência"
        defaultValue={dayjs()}
        minDate={dayjs()}
        auxOnChange={onChange}
      />
    </Stack>
  );
}
