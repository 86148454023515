import { Checkbox, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { FormButtonGroup } from "../../../../components/form";
import { PostRelatedPerson } from "../../../../services/fhir/relatedperson/PostRelatedPerson";
import { PatchPatient } from "../../../../services/fhir/patient/PatchPatient";
import { useWatch } from "react-hook-form";
import { APPC_CONSENT } from "../../../../services/appc";
import { PatchRelatedPerson } from "../../../../services/fhir/relatedperson/PatchRelatedPerson";

interface IProps {
  handleSubmit: any;
  navigateToNextPage: any;
  onBackButtonCLick: any;
  dataResponsible: any;
  control: any;
}

export function ConfirmRelationship({
  handleSubmit,
  navigateToNextPage,
  onBackButtonCLick,
  dataResponsible,
  control,
}: IProps) {
  const [checkbox, setCheckbox] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const watchMpiPatient = useWatch({ name: "auxMpiResponse", control });
  const watchReferenceResponsible = useWatch({
    name: "auxObjReference",
    control,
  });

  const patientName = watchMpiPatient?.data?.name?.[0]?.text || "";
  const responsibleName = watchReferenceResponsible?.ownerName || "";

  const handleCheckbox = () => {
    setCheckbox(!checkbox);
  };

  const createRelatedPerson = async (
    mpiResponse: any,
    data: any,
    responsibleId: any
  ) => {
    const updatedPatientActor = {
      reference: `Patient/${mpiResponse?.data?.id}`,
      display: mpiResponse?.data?.name?.[0]?.text || "",
    };

    try {
      const responseRelatedPerson = await PostRelatedPerson(
        { ...data, cpfResponsible: responsibleId },
        updatedPatientActor,
        true
      );
      const relatedPersonId = responseRelatedPerson?.data?.id;

      const auxExistsLink = dataResponsible?.hasOwnProperty("link");
      const path = !auxExistsLink ? "/link" : "/link/-";

      const content = {
        other: { reference: `RelatedPerson/${relatedPersonId}` },
        type: "seealso",
      };

      try {
        await PatchPatient({
          patientId: `${dataResponsible?.id}`,
          operation: "add",
          path,
          content: !auxExistsLink ? [content] : content,
        });
      } catch (err) {
        console.log("errPatchPatient:", err);
      } finally {
        setLoading(false);
      }
    } catch (err) {
      console.log("errRelatedPerson: ", err);
      setLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    if (data?.statusRelated?.resource.active === false) {
      await PatchRelatedPerson({
        id: data?.statusRelated?.resource?.id,
        operation: "replace",
        path: "/active",
        content: true,
      });
      await APPC_CONSENT({
        rule: "Permit",
        patient_id: data?.cpf?.replace(/[.-]/g, ""),
        subject_id: [data?.cpfResponsible?.replace(/[.-]/g, "")],
        cnes: null,
        vigency: null,
        purposeofuse: "PWATRNY",
      });
      navigateToNextPage();
      return;
    }
    await APPC_CONSENT({
      rule: "Permit",
      patient_id: data?.cpf?.replace(/[.-]/g, ""),
      subject_id: [data?.cpfResponsible?.replace(/[.-]/g, "")],
      cnes: null,
      vigency: null,
      purposeofuse: "PWATRNY",
    });

    await createRelatedPerson(
      data?.auxMpiResponse,
      data?.auxObjReference,
      data?.cpfResponsible
    );

    navigateToNextPage();
  };

  return (
    <Stack width="100%">
      <Stack flexDirection="row" alignItems="flex-start" p={1}>
        <Checkbox
          onChange={handleCheckbox}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography
          variant="body1"
          color="neutral700.main"
          fontWeight={500}
          mb={2}
        >
          Confirma que {responsibleName} é responsável por {patientName}?
        </Typography>
      </Stack>
      <FormButtonGroup
        onGoBackButtonClick={onBackButtonCLick}
        onNextButtonClick={handleSubmit(onSubmit)}
        loading={loading}
        disabled={!checkbox}
      />
    </Stack>
  );
}
