type QuestionnaireItem = {
  linkId: string;
  text: string;
  type: string;
  item?: QuestionnaireItem[];
  answerOption?: {
    valueCoding: {
      id: string;
      code: string;
      display: string;
      system?: string;
    };
  }[];
};

type Answer = {
  valueString?: string | null;
  valueDate?: string;
  valueCoding?: {
    id: string;
    code: string;
    display: string;
    system?: string;
  };
  item?: any[];
};

export function BuildQuestionAnswer(
  item: QuestionnaireItem,
  groupedAnswers: Record<string, any[]>,
  repeatIndex = 0
): any {
  if (item.type === "group") {
    const groupItems = [];
    const maxLengthAnswers = Math.max(
      ...item.item!.map(
        (subItem) => groupedAnswers[subItem.linkId]?.length || 1
      )
    );

    for (let i = 0; i < maxLengthAnswers; i++) {
      const groupItem = {
        linkId: item.linkId,
        text: item.text,
        item: item
          .item!.map((subItem) =>
            BuildQuestionAnswer(subItem, groupedAnswers, i)
          )
          .filter((subItem) => subItem !== null),
      };

      groupItems.push(groupItem);
    }
    return groupItems.length > 0 ? groupItems : null;
  } else {
    const answerObj: any = {
      linkId: item.linkId,
      text: item.text,
      answer: [] as Answer[],
    };

    const answers = groupedAnswers[item.linkId];

    if (answers && answers[repeatIndex] !== undefined) {
      const answer = answers[repeatIndex];

      if (item.type === "string" || item.type === "text") {
        answerObj.answer.push({ valueString: answer });
      } else if (item.type === "date") {
        answerObj.answer.push({ valueDate: answer });
      } else if (item.type === "choice" || item.type === "coding") {
        const selectedOption = item.answerOption!.find(
          (option) => option.valueCoding.id === answer
        );
        if (selectedOption) {
          const valueCoding = {
            valueCoding: selectedOption.valueCoding,
          };

          const answerItem: Answer = { ...valueCoding };

          if (item.item) {
            answerItem.item = item.item.map((subItem) =>
              BuildQuestionAnswer(subItem, groupedAnswers, repeatIndex)
            );
          }

          answerObj.answer.push(answerItem);
        }
      }
    } else {
      answerObj.answer.push({ valueString: null });
    }

    return answerObj;
  }
}
