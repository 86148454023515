export enum HTTPResponseStatus {
  InternalError = 500,
  Timeout = 504,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  Conflict = 409,
  NotFound = 404,
  UnprocessableEntity = 422,
  Success = 200,
  Created = 201,
}
