import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { useSessionContext } from "../../../../contexts/SessionContext";
import { useFormatter } from "../../../../utils/useFormatter";
import { ProfileContactPatch } from "../../../../services/scim";
import { HTTPResponseStatus } from "../../../../utils/enum";
import { toast } from "react-toastify";
import { CommonDialog } from "../../../../components/common/CommonDialog";
import { DialogTitle } from "@mui/material";
import { EditPhoneSteps } from "../../Types";
import { useFirebaseService } from "../../../../utils/useFirebase";
import PhoneFormContent from "./PhoneFormContent";
import ConfirmPhoneContent from "./ConfirmPhoneContent";
import { isMobile } from "react-device-detect";

interface IFormInput {
  phone: string;
  confirmationCode: string;
}

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export function EditPhoneDialog({ isOpen, setIsOpen }: IProps) {
  const { formatPhoneNumber } = useFormatter;
  const { user, fetchUser } = useSessionContext();
  const { sendVerificationCode, verifyCode } = useFirebaseService;
  const [step, setStep] = useState(EditPhoneSteps.S1PhoneForm);
  const [loading, setLoading] = useState(false);

  const defaultValues = {
    phone: formatPhoneNumber(user?.phone),
    confirmationCode: "",
  };

  const methods = useForm<IFormInput>({ defaultValues });
  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
    getValues,
    reset,
  } = methods;

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
    }
  }, [isOpen]);

  async function onSubmit(data: IFormInput) {
    setLoading(true);
    try {
      await verifyCode(data.confirmationCode);

      const response = await ProfileContactPatch({
        phone: data.phone.replace(/\D/g, ""),
        id: user.id,
      });
      if (response?.status === HTTPResponseStatus.Success) {
        await fetchUser();
        toast.success("Alteração realizada com sucesso");
        setIsOpen(false);
        return;
      }
      toast.error(
        "Houve um erro na atualização do telefone, tente novamente mais tarde"
      );
    } catch (error: any) {
      console.log("EditPhoneDialog onSubmit", error);
      if (error?.code === "auth/argument-error") {
        toast.error("Código inválido");
        return;
      }
      if (error?.code === "auth/missing-verification-code") {
        toast.error("Campo do código vazio, preencha e tente novamente");
        return;
      }
      toast.error(
        "Houve um erro na atualização do telefone, tente novamente mais tarde"
      );
    } finally {
      setLoading(false);
    }
  }

  async function onConfirm(data: IFormInput) {
    setLoading(true);
    await sendVerificationCode(data.phone)
      .then(() => {
        setStep(EditPhoneSteps.S2ConfirmPhone);
      })
      .catch((err) => {
        console.log("EditPhoneDialog onConfirm", err);
        toast.error(
          "Não foi possível enviar o código de verificação, por favor, tente novamente mais tarde"
        );
      })
      .finally(() => setLoading(false));
  }

  const resendCode = async () => {
    await sendVerificationCode(getValues("phone")).catch((err) => {
      console.log("resendCode EditPhoneDialog", err);
      toast.error(
        "Não foi possível enviar o código de verificação, por favor, tente novamente mais tarde"
      );
    });
  };

  function handleClose() {
    setIsOpen(false);
    setStep(EditPhoneSteps.S1PhoneForm);
  }

  return (
    <CommonDialog
      handleClose={handleClose}
      isOpen={isOpen}
      mobileView={isMobile}
    >
      <DialogTitle textAlign="center">Editar telefone</DialogTitle>
      {step === EditPhoneSteps.S1PhoneForm && (
        <PhoneFormContent
          loading={loading}
          control={control}
          onCancelButtonClick={() => setIsOpen(false)}
          onConfirmButtonClick={handleSubmit(onConfirm)}
          disabledConfirmButton={!isDirty || !isValid}
        />
      )}
      {step === EditPhoneSteps.S2ConfirmPhone && (
        <ConfirmPhoneContent
          control={control}
          loading={loading}
          onGoBackButtonClick={() => setStep(EditPhoneSteps.S1PhoneForm)}
          onResendButtonClick={resendCode}
          onSubmitButtonClick={handleSubmit(onSubmit)}
          phone={getValues("phone")}
        />
      )}
    </CommonDialog>
  );
}
