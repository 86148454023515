import { Dialog, IconButton } from "@mui/material";
import { CreateAllergiesForm } from "./components/CreateAllergiesForm";
import { CreateConditionsForm } from "./components/CreateConditionsForm";
import { CreateMedicationStatementForm } from "./components/CreateMedicationStatementForm/CreateMedicationStatementForm";
import { useForm } from "react-hook-form";
import { Close } from "@mui/icons-material";

interface IProps {
  resourceType: "AllergyIntolerance" | "Condition" | "MedicationStatement";
  isOpen: boolean;
  handleClose: any;
  references: any;
  setRefetch: any;
}

export function CreateResourceDialog({
  resourceType,
  isOpen,
  handleClose,
  references,
  setRefetch,
}: IProps) {
  const methods = useForm({});
  function renderSelectedForm() {
    if (resourceType === "Condition")
      return (
        <CreateConditionsForm
          setRefetch={setRefetch}
          handleClose={handleClose}
          methods={methods}
          references={references}
        />
      );
    if (resourceType === "MedicationStatement")
      return (
        <CreateMedicationStatementForm
          setRefetch={setRefetch}
          handleClose={handleClose}
          methods={methods}
          references={references}
        />
      );
    else
      return (
        <CreateAllergiesForm
          setRefetch={setRefetch}
          handleClose={handleClose}
          methods={methods}
          references={references}
        />
      );
  }

  const handleDialogClose = (event: any, reason: string) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      handleClose(event, reason);
    }
  };

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: { borderRadius: "5px", padding: 4 },
      }}
      maxWidth="lg"
      onClose={handleDialogClose}
    >
      <IconButton
        onClick={() => handleClose(null, "iconButtonClick")}
        sx={{ position: "absolute", right: 5, top: 5 }}
      >
        <Close />
      </IconButton>
      {renderSelectedForm()}
    </Dialog>
  );
}
