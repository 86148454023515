import { Stack } from "@mui/material";
import { GenericTable } from "../../../../../components/GenericTable/GenericTable";
import { PrimaryButton } from "../../../../../components/common";
import { Add } from "@mui/icons-material";
import dayjs from "dayjs";
import { useFhirData } from "../../../../../utils/useFhirData";
import { useDatetime } from "../../../../../utils/useDatetime";

interface IProps {
  data: any;
  openDialog: any;
}

export function ConditionsTab({ data, openDialog }: IProps) {
  const { formatToDDMMYYYY } = useDatetime;
  const columns = [
    {
      column: "Descrição",
      rowName: "description",
    },
    {
      column: "CID10",
      rowName: "param",
    },
    { column: "Profissional", rowName: "practitioner" },
    { column: "Informante", rowName: "informant" },
    { column: "Data", rowName: "date" },
  ];

  const rows = data?.map((el: any) => {
    return {
      param: el?.resource?.code?.coding?.[0]?.code,
      description: el?.resource?.code?.coding?.[0]?.display || "",
      date: formatToDDMMYYYY(dayjs(el?.resource?.recordedDate)),
      practitioner: useFhirData.findParticipant(
        el?.resource?.participant,
        "Practitioner"
      )?.display,
      informant: useFhirData.findParticipant(
        el?.resource?.participant,
        "Patient"
      )
        ? "Paciente"
        : "Profissional",
    };
  });
  return (
    <>
      <GenericTable tableColumns={columns} tableRows={rows} />
      <Stack direction="row" justifyContent="right" mt={4}>
        <PrimaryButton onClick={openDialog} width="250px" height="42px">
          <Add /> Adicionar condição
        </PrimaryButton>
      </Stack>
    </>
  );
}
