import {
  Chip,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { ReadOnlyField } from "../../medicaldocumentation/components/ReadOnlyField";
import { useEffect, useState } from "react";

import { GetPatient } from "../../../services/fhir";
import { useDatetime } from "../../../utils/useDatetime";
import { useFormatter } from "../../../utils/useFormatter";
import { WhatsApp } from "@mui/icons-material";
import { useSessionContext } from "../../../contexts/SessionContext";
import { useFhirData } from "../../../utils/useFhirData";
import { lastEncounterObservationsData } from "./observation";
import dayjs from "dayjs";

export function PatientData({ patientId, summaryData }: any) {
  const { access } = useSessionContext();
  const [patientData, setPatientData] = useState<fhir5.Patient>();

  const observationSummary = useFhirData?.extractPatientSummaryResource(
    summaryData,
    "Observation"
  )?.[0];

  const encounterSummary = useFhirData?.extractPatientSummaryResource(
    summaryData,
    "Encounter"
  )?.[0];

  const filterEncounter = useFhirData?.groupObservationsByEncounter(
    observationSummary?.entry,
    encounterSummary
  );

  const lastObservations = lastEncounterObservationsData({
    encounters: filterEncounter,
    observations: ["8302-2", "55284-4", "29463-7"],
  });

  const { formatDate } = useDatetime;
  const { formatNameDisplay, formatGender, formatAge } = useFormatter;

  const auxAddress = patientData?.address?.[0];

  const contact = patientData?.telecom;
  const phone = contact?.find((e: any) => e.system === "phone")?.value;

  const city = auxAddress?.city || "";
  const addressState = auxAddress?.state || "";
  const postalCode = auxAddress?.postalCode || "";
  const line = auxAddress?.line?.join(", ") || "";

  const address =
    [line, city, addressState, postalCode]
      ?.filter((el: any) => el)
      ?.join(" , ") || "-";

  const auxData = [
    {
      label: "Nome do paciente",
      description: formatNameDisplay(patientData?.name?.[0]?.text || ""),
    },
    {
      label: "Sexo de nascimento",
      description: formatGender(patientData?.gender) || "-",
    },
    {
      label: "Idade",
      description: patientData?.birthDate
        ? `${formatAge(`${patientData?.birthDate}`)} anos`
        : "-",
    },
    {
      label: "Telefone",
      description: phone ? (
        <>
          <Stack
            height="22px"
            direction="row"
            alignItems="center"
            justifyContent="left"
            alignContent="center"
          >
            <Typography>
              {useFormatter.formatPhoneNumber(phone || "")}
            </Typography>

            {access?.type === "professional" ? (
              <Tooltip title={useFormatter.formatPhoneNumber(phone || "")}>
                <IconButton
                  color="primary"
                  onClick={() =>
                    window.open(
                      `https://web.whatsapp.com/send?phone=+55${phone}`,
                      "_blank"
                    )
                  }
                >
                  <WhatsApp />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </Stack>
        </>
      ) : (
        "-"
      ),
    },
    {
      label: "Data de nascimento",
      description: patientData?.birthDate
        ? formatDate(`${patientData?.birthDate}`)
        : "-",
    },

    {
      label: "Endereço",
      description: address || "-",
    },
    {
      label: "Raça/Etnia",
      description:
        patientData?.extension?.find((ext: any) =>
          ext.url.includes("BRRacaCorEtnia")
        )?.valueCodeableConcept?.coding?.[0]?.display || "-",
    },
  ];

  useEffect(() => {
    async function fetchPatient() {
      const mpiResponse = await GetPatient({ identifier: patientId });
      setPatientData(mpiResponse?.data);
    }

    if (patientId) fetchPatient();
  }, [patientId]);
  return (
    <>
      {auxData?.map((el: any) => (
        <Grid
          item
          lg={3}
          md={3}
          sm={3}
          xs={6}
          key={`patient-information-${el.label}`}
        >
          <ReadOnlyField
            title={el.label}
            description={el.description}
            icon={false}
          />
        </Grid>
      ))}

      <Stack
        direction="row"
        alignItems={"end"}
        justifyContent={"space-between"}
        mt={2}
        paddingLeft={3}
        width={"100%"}
        flexWrap={"wrap"}
      >
        {lastObservations?.map((observation: any) => (
          <Tooltip
            title={
              <>
                <Stack
                  direction="row"
                  width="100%"
                  alignItems="center"
                  spacing={2}
                >
                  {observation?.practitioner?.professional && (
                    <Typography
                      color="neutral700.main"
                      sx={{
                        fontWeight: 500,
                        fontStyle: "italic",
                        fontSize: 12,
                      }}
                    >
                      Dr. {observation?.practitioner?.professional}
                    </Typography>
                  )}

                  <Chip
                    label={
                      observation?.practitioner?.healthcareService ||
                      "Sem especialidade"
                    }
                    size="small"
                    sx={{
                      color: "result?.practitioner?.healthcareService"
                        ? "neutral700.main"
                        : "neutral500.main",

                      fontWeight: 500,
                      backgroundColor: "result?.displayInfo?.healthcareService"
                        ? ""
                        : "#f1f1f1",
                    }}
                  />
                </Stack>
                <Stack direction="row" width="100%" spacing={2} mt={1}>
                  <Typography
                    color="neutral700.main"
                    sx={{
                      fontSize: 12,
                    }}
                  >
                    {dayjs(observation?.date).format("DD/MM/YYYY")}
                  </Typography>
                </Stack>
              </>
            }
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -14],
                    },
                  },
                ],
              },
              tooltip: {
                sx: {
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  borderRadius: "8px",
                  border: "0.5px solid #A9A9A9",
                  backdropFilter: "blur(4px)",
                  boxShadow: "4px 4px 16px 4px rgba(0, 0, 0, 0.1)",
                  color: "white",
                  fontSize: "1rem",
                },
              },
              arrow: {
                sx: {
                  color: "rgba(255, 255, 255, 1)",
                },
              },
            }}
          >
            <Stack direction="column">
              <Stack direction="row" justifyContent="center" width="180px">
                <Typography
                  variant="body1"
                  color="neutral1000.main"
                  textAlign={"center"}
                  fontWeight={500}
                  sx={{ wordBreak: "break-word" }}
                >
                  {observation?.name}
                </Typography>
              </Stack>
              <Stack
                width="180px"
                borderRadius="8px"
                justifyContent="center"
                alignItems="center"
                padding={0.5}
                sx={{ background: "#E6F7F5" }}
              >
                <Typography
                  textAlign="center"
                  fontWeight={500}
                  sx={{ color: "neutral600.main" }}
                >
                  {`${observation?.value ?? "-"} ${observation?.unit ?? ""}`}
                </Typography>
              </Stack>
            </Stack>
          </Tooltip>
        ))}
      </Stack>
    </>
  );
}
