import React from "react";
import { Button, styled } from "@mui/material";
import { Link } from "react-router-dom";

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary800.main,
  border: `2px solid ${theme.palette.neutral0.main}`,
  borderRadius: "10px",
  heigh: "43px",
  paddingLeft: "32px",
  paddingRight: "32px",
  color: theme.palette.neutral0.main,
  textTransform: "none",
}));

export default function ActionsTabItem({
  title,
  link,
  Icon,
}: {
  title: string;
  link: string;
  Icon?: any;
}) {
  return (
    <Link to={link}>
      <CustomButton>
        {title}
        {Icon && <Icon sx={{ paddingLeft: "8px" }} />}
      </CustomButton>
    </Link>
  );
}
