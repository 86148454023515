import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { IPractitioner } from "../types";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useFormatter } from "../../../utils/useFormatter";
import { GetPractitionerRole } from "../../../services/fhir";

interface IProps {
  slotType: any;
  practitioners: IPractitioner[] | undefined;
  selectedPractitioner: number;
  setSelectedPractitioner: Dispatch<SetStateAction<number>>;
  organization: any;
}

export function PractitionersOptions({
  slotType,
  organization,
  practitioners,
  selectedPractitioner,
  setSelectedPractitioner,
}: IProps) {
  const { formatNameFirstInitials } = useFormatter;

  const [cardData, setCardData] = useState<any>(practitioners);
  useEffect(() => {
    async function fetchSpecialties() {
      const ids = practitioners?.map((el: any) => el?.reference)?.join(",");
      const roles = await GetPractitionerRole({ practitioner: ids });
      const findRoles = practitioners?.map((el: any) => {
        const role = roles?.entry?.filter(
          (e: any) =>
            e?.resource?.practitioner?.reference === el?.reference &&
            e?.resource?.organization?.reference?.includes(
              organization?.resource?.id
            )
        );
        const specialties = role
          ?.map((el: any) => el?.resource?.specialty)
          ?.flatMap((item: any) => item);
        return { ...el, specialties };
      });
      setCardData(findRoles);
    }
    fetchSpecialties();
  }, [practitioners, organization]);

  return (
    <Stack>
      <Typography
        sx={{ mt: 1, mb: 1 }}
        variant="body1"
        color="neutral700.main"
        textAlign="left"
      >
        Escolha um dos profissionais disponíveis:
      </Typography>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: "100%", display: { xs: "flex" } }}
          >
            {cardData.map((el: any, index: number) => (
              <Card
                key={index}
                variant="outlined"
                component={Button}
                onClick={() => setSelectedPractitioner(index)}
                sx={{
                  p: 3,
                  height: "fit-content",
                  width: "100%",
                  background: "none",
                  backgroundColor:
                    selectedPractitioner === index
                      ? "action.selected"
                      : undefined,
                  borderColor: (theme) => {
                    if (theme.palette.mode === "light") {
                      return selectedPractitioner === index
                        ? "primary.light"
                        : "grey.200";
                    }
                    return selectedPractitioner === index
                      ? "primary.dark"
                      : "grey.800";
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    textAlign: "left",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: { md: "center" },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      textTransform: "none",
                      minWidth: "fit-content",
                      color:
                        selectedPractitioner === index
                          ? "primary.main"
                          : "#5F6C85",
                    }}
                  >
                    <Stack direction="row" alignItems="center">
                      <Avatar
                        sx={{
                          width: 36,
                          height: 36,
                          background: "#E6F7F5",
                          color: "#004941",
                          fontSize: "16px",
                          mr: 2,
                        }}
                      >
                        {formatNameFirstInitials(el.display, 2)}
                      </Avatar>
                      Dr. {el?.display}
                    </Stack>
                  </Box>
                  <Box sx={{ textTransform: "none" }}>
                    <Chip
                      label={el?.healthcare?.display}
                      size="small"
                      sx={{
                        m: 0.5,
                        color: "neutral700.main",
                        fontWeight: 500,
                        backgroundColor: "#f1f1f1",
                      }}
                    />
                  </Box>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}
