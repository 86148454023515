import { Stack } from "@mui/system";
import { GenericTable } from "../../../GenericTable/GenericTable";
import { useFormatter } from "../../../../utils/useFormatter";
import { oids } from "../../../../configs/Settings";
import { useDatetime } from "../../../../utils/useDatetime";
import { PatientSearchActions } from "./PatientSearchActions";

export function SearchResults({ data }: any) {
  const { formatDate } = useDatetime;
  const { formatNameDisplay, formatGender, formatAge } = useFormatter;
  const columns = [
    { column: "Nome", rowName: "name" },
    { column: "CPF", rowName: "identifier" },
    { column: "Idade", rowName: "age" },
    { column: "Data de nascimento", rowName: "birthDate" },
    { column: "Sexo de nascimento", rowName: "gender" },
  ];

  const rows = data?.map((el: any) => ({
    name: formatNameDisplay(el?.name?.[0]?.text || ""),
    identifier:
      el?.identifier?.find((e: any) => e?.system?.includes(oids.cpf))?.value ||
      "-",
    age: el?.birthDate ? `${formatAge(`${el?.birthDate}`)} anos` : "-",
    birthDate: el?.birthDate ? formatDate(`${el?.birthDate}`) : "-",
    gender: formatGender(el?.gender) || "-",
  }));

  return (
    <Stack width="100%" justifyContent="flex-start" paddingTop="16px">
      <GenericTable
        tableColumns={columns}
        tableRows={rows}
        actions={(rowData: any) => <PatientSearchActions row={rowData} />}
      />
    </Stack>
  );
}
