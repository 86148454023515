import { Stack, Typography } from "@mui/material";

import {
  FormStack,
  FormButtonGroup,
  FormSelectInput,
  FormDateInput,
  FormTextInput,
} from "../../../components/form";
import { ContentBoxTitle, DisplayTextField } from "../../../components/common";
import { IFormInput } from "../types";
import { useFirebaseService } from "../../../utils/useFirebase";
import { handleKeyPress } from "../../../utils/useKeyPress";
import dayjs from "dayjs";

interface IProps {
  control: any;
  handleSubmit: Function;
  onBackButtonClick: Function;
  navigateToNextPage: Function;
  watch: any;
}

export default function ConfirmAccount({
  control,
  handleSubmit,
  onBackButtonClick,
  navigateToNextPage,
  watch,
}: IProps) {
  const { sendVerificationCode } = useFirebaseService;

  const onSubmit = async (data: IFormInput) => {
    sendVerificationCode(data.phoneNumber);
    navigateToNextPage();
  };

  const genderOptions = [
    { display: "Feminino", value: "female" },
    { display: "Masculino", value: "male" },
  ];

  const watchAllFields = watch();
  const cpf = watchAllFields?.identifier;
  const nome = watchAllFields?.userData?.name;
  const email = watchAllFields?.email;
  const birthDate = watchAllFields?.userData?.birthDate;
  const phoneNumber = watchAllFields?.phoneNumber;
  const gender = watchAllFields?.gender;

  return (
    <form
      onKeyDown={(event) =>
        handleKeyPress(event, false, handleSubmit(onSubmit))
      }
      style={{ height: "100%", width: "100%" }}
    >
      <FormStack>
        <ContentBoxTitle>Informações de contato</ContentBoxTitle>

        <Typography
          textAlign="center"
          variant="body1"
          sx={{ color: "neutral600.main", mt: "-10px" }}
        >
          Verifique seus dados. No próximo passo enviaremos um SMS com um código
          para validarmos o seu número de celular.
        </Typography>
        <Stack spacing={2}>
          <DisplayTextField
            variant="filled"
            label="Nome completo"
            value={nome}
          />
          <Stack direction="row" spacing={3}>
            <DisplayTextField variant="filled" label="CPF" value={cpf} />
            <DisplayTextField
              variant="filled"
              label="Data de nascimento"
              value={dayjs(birthDate)?.format("DD/MM/YYYY")}
            />
          </Stack>
          <Stack direction="row" spacing={3}>
            <DisplayTextField
              variant="filled"
              label="Número de celular"
              value={phoneNumber}
            />
            <DisplayTextField variant="filled" label="E-mail" value={email} />
          </Stack>
          <FormSelectInput
            control={control}
            name="gender"
            label="Sexo de nascimento"
            selectOptions={genderOptions}
            required
          />
        </Stack>

        <FormButtonGroup
          onGoBackButtonClick={() => onBackButtonClick()}
          onNextButtonClick={handleSubmit(onSubmit)}
          disabled={!cpf || !nome || !gender || !email}
        />
      </FormStack>
    </form>
  );
}
