import dayjs from "dayjs";
import { Stack } from "@mui/material";
import { useDatetime } from "../../../../../utils/useDatetime";
import { GenericTable } from "../../../../../components/GenericTable/GenericTable";
import { ActionsTablePrescription } from "./ActionsTablePrescription";

import { CircularLoading } from "../../../../../components/common";

export function TablePrescription({
  data,
  documentReference,
  loading,
  setBinaryUrl,
  addItem,
}: any) {
  const { formatToDDMMYYYY } = useDatetime;

  const medicationRequest = data?.[0]?.entry?.filter(
    (el: any) => el?.resource?.resourceType === "MedicationRequest"
  );

  const columns = [
    {
      column: "Medicamentos",
      rowName: "description",
    },
    { column: "Data", rowName: "date" },
    // { column: "Especialidade", rowName: "specialty" },
    { column: "Profissional solicitante", rowName: "practitioner" },
    { column: "Status", rowName: "status" },
  ];

  const rowData = medicationRequest
    ?.map((el: any, index: number) => {
      const docRef = documentReference?.find((doc: any) => {
        const findRef = doc?.resource?.basedOn?.find((basedOn: any) =>
          basedOn?.reference.includes(el.resource.id)
        );
        return findRef;
      });
      const auxPractitioner = docRef?.resource?.contained.find(
        (contained: any) => contained?.resourceType === "Practitioner"
      );

      const auxSpecialty =
        docRef?.resource?.practiceSetting?.coding?.[0]?.display;

      const status =
        el?.resource?.status === "active" ? "valid" : el?.resource?.status;

      const description = el.resource?.medication?.concept?.text || "";
      const date = el?.resource?.effectiveDosePeriod?.start
        ? formatToDDMMYYYY(dayjs(el?.resource?.effectiveDosePeriod?.start))
        : "-";
      const binaryUrl = docRef?.resource?.content?.[0]?.attachment?.url || "";

      return {
        status,
        description,
        date,
        specialty: auxSpecialty || "-",
        binaryUrl,
        practitioner:
          auxPractitioner?.name?.[0]?.given?.[0] ||
          auxPractitioner?.identifier?.[0]?.value ||
          el?.resource?.requester?.reference?.split("-")?.[2],
        fullResource: el.resource,
      };
    })
    ?.sort((a: any, b: any) => {
      const dateA = dayjs(a.date, "DD/MM/YYYY");
      const dateB = dayjs(b.date, "DD/MM/YYYY");
      return dateB.diff(dateA);
    });

  return (
    <Stack width="100%" p={2}>
      {loading ? (
        <CircularLoading />
      ) : (
        <>
          <GenericTable
            tableColumns={columns}
            tableRows={rowData}
            actions={(rowData: any) => (
              <ActionsTablePrescription
                row={rowData}
                setBinaryUrl={setBinaryUrl}
                addItem={addItem}
              />
            )}
          />
        </>
      )}
    </Stack>
  );
}
