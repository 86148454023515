import dayjs from "dayjs";

const generateBodyPdf = (objData: any, organization: any) => {
  const parts = objData.base64.split(",");
  const fileType = parts[0]?.split(":")[1].split(";")[0];
  const base64Data = parts[1];

  return JSON.stringify({
    resourceType: "QuestionnaireResponse",
    questionnaire: "Questionnaire/customPdf",
    status: "completed",
    author: {
      reference: `Organization/${organization}`,
    },
    ...(objData.practitioner && {
      source: {
        reference: `Practitioner/${objData.practitioner}`,
      },
    }),
    authored: `${dayjs().format("YYYY-MM-DDTHH:mm:ss")}`, // Data de resposta
    item: [
      {
        linkId: "documentType",
        text: "Tipo de Documento",
        answer: [
          {
            valueString: `${objData.documentType}`,
          },
        ],
      },
      {
        linkId: "documentName",
        text: "Nome do Documento",
        answer: [
          {
            valueString: `${objData.documentName}`,
          },
        ],
      },
      {
        linkId: "documentBase64",
        text: "Arquivo em Base64",
        answer: [
          {
            valueAttachment: {
              contentType: `${fileType}`,
              data: `${base64Data}`, //base64 aqui
            },
          },
        ],
      },
    ],
  });
};

export const auxiliarPdf = {
  generateBodyPdf,
};
