import { toast } from "react-toastify";
import { endpoints, oids } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";

interface IProps {
  organization?: string;
  ids?: string;
}

export async function GetLocation({ organization, ids }: IProps) {
  let url = `${endpoints.WORKFLOWFHIR}/Location`;
  if (organization)
    url = `${url}?organization=${oids.cnpj}-${organization}&_count=250`;
  if (ids) url = `${url}?_id=${ids}&_count=250`;

  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
  };
  const response = await useAxios
    .get(url, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`Get Location: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("GetLocation ", error);
      // toast.error(`GetLocation Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
