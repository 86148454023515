import { Business, Person } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Stack, Tab, Typography } from "@mui/material";

import { FormTextInput } from "../../../../components/form";
import { useRegex } from "../../../../utils/useRegex";
import { useFormatter } from "../../../../utils/useFormatter";
import { VigencyOptions } from "../VigencyOptions";
import { OrganizationOptions } from "../OrganizationOptions";

export function NewConsentTabs({ control, selectedTab, handleChangeTab }: any) {
  return (
    <Stack width="100%">
      <TabContext value={selectedTab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TabList onChange={handleChangeTab}>
            <Tab
              style={{ display: "flex", alignItems: "center" }}
              label={
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Person sx={{ marginRight: 1 }} />
                  Indivíduo
                </Box>
              }
              value="1"
            />

            <Tab
              style={{ display: "flex", alignItems: "center" }}
              label={
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Business sx={{ marginRight: 1 }} />
                  Unidade de saúde
                </Box>
              }
              value="2"
            />
          </TabList>
        </Box>
        <TabPanel sx={{ padding: 0 }} value="1">
          <Stack mt={2} mb={2}>
            <Typography variant="body1" fontWeight={400}>
              Informe o CPF da pessoa com quem você deseja compartilhar suas
              informações de saúde:
            </Typography>
            <FormTextInput
              name="identifier"
              control={control}
              label="CPF"
              maxLength={14}
              pattern={useRegex.cpf}
              mask={useFormatter.formatCPF}
            />

            <VigencyOptions control={control} />
          </Stack>
        </TabPanel>
        <TabPanel sx={{ padding: 0 }} value="2">
          <Stack mt={2} mb={2}>
            <OrganizationOptions control={control} />
            <VigencyOptions control={control} />
          </Stack>
        </TabPanel>
      </TabContext>
    </Stack>
  );
}
