import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Typography } from "@mui/material";
import dayjs from "dayjs";

export function GenericTimeline({
  data,
  component,
  selectedField,
  dotColor,
}: any) {
  return (
    <Timeline sx={{ padding: 0, margin: 0 }}>
      {data?.map((item: any, index: number) => {
        return (
          <TimelineItem key={`${item?.awnserDate}-${index}`}>
            <TimelineOppositeContent
              sx={{
                flex: 0,
                padding: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  width: "100px",
                  fontWeight: 500,
                  fontSize: 13,
                  textAlign: "center",
                  borderColor: "neutral400.main",
                  color: "#555",
                }}
              >
                {dayjs(item?.awnserDate)?.isValid()
                  ? dayjs(item?.awnserDate)
                      ?.format("DD MMM YYYY")
                      ?.toUpperCase()
                  : "-"}
              </Typography>
            </TimelineOppositeContent>

            <TimelineSeparator>
              <TimelineDot
                sx={{ background: dotColor && dotColor?.[item?.status] }}
              />
              {index + 1 !== data?.length && (
                <TimelineConnector
                  sx={{ background: dotColor && dotColor?.[item?.status] }}
                />
              )}
            </TimelineSeparator>
            <TimelineContent sx={{ width: "500px" }}>
              {component(item, index, selectedField)}
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
}
