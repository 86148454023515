import { PersonSearch } from "@mui/icons-material";
import { IFeatures } from "../../utils/interfaces";
import { ClinicalRegister } from "./ClinicalRegister";

import { useSessionContext } from "../../contexts/SessionContext";

export function useClinicalRegister() {
  const { access } = useSessionContext();

  const configs: IFeatures = {
    active: access.type === "professional",
    path: "/clinicalregister",
    icon: PersonSearch,
    text: "Atendimentos clínicos",

    component: () => <ClinicalRegister />,
  };

  return configs;
}
