import { JitsiMeeting } from "@jitsi/react-sdk";
import { Container } from "@mui/material";
import { useEffect, useRef, useState } from "react";

interface JitsiMeetingProps {
  roomName: string;
  displayName: string;
  email: string;
  screenSize: number;
  onClose: () => void; // Adicione a prop onClose
}

const JitsiComponent: React.FC<JitsiMeetingProps> = ({ roomName, displayName, email, screenSize, onClose }) => {
  const YOUR_DOMAIN = "teleatendimento.fluxmed.com.br";
  const jitsiRef = useRef<any>(null);
  const [iframeReady, setIframeReady] = useState(false);

  const handleReadyToClose = () => {
    onClose(); // Notifique o componente pai
  };

  useEffect(() => {
    // Verificar se a conexão está sendo estabelecida corretamente
    console.log("Tentando conectar à sala:", roomName);
  }, [roomName]);

  useEffect(() => {
    if (jitsiRef.current && iframeReady) {
      const width = 400 * screenSize;
      const height = 250 * screenSize;
      jitsiRef.current.style.width = `${width}px`;
      jitsiRef.current.style.height = `${height}px`;

      const contentWindow = jitsiRef.current.contentWindow;
      if (contentWindow) {
        contentWindow.postMessage(
          {
            method: "resize",
            params: {
              width,
              height,
            },
          },
          "*",
        );
      }
    }
  }, [screenSize, iframeReady]);

  return (
    <Container>
      <>
        <JitsiMeeting
          domain={YOUR_DOMAIN}
          roomName={roomName}
          onReadyToClose={handleReadyToClose}
          configOverwrite={{
            startWithAudioMuted: true,
            startWithVideoMuted: true,
            disableModeratorIndicator: true,
            enableEmailInStats: false,
            disableProfile: true,
            prejoinPageEnabled: false,
          }}
          interfaceConfigOverwrite={{
            DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
            SHOW_JITSI_WATERMARK: false, // Desativa a marca d'água Jitsi
            SHOW_WATERMARK_FOR_GUESTS: false, // Desativa a marca d'água para convidados
            SHOW_BRAND_WATERMARK: false, // Desativa a marca d'água da marca
            BRAND_WATERMARK_LINK: "", // Remove o link da marca d'água
          }}
          userInfo={{
            displayName: displayName,
            email: email,
          }}
          getIFrameRef={(iframeRef) => {
            jitsiRef.current = iframeRef;
            setIframeReady(true);
          }}
        />
      </>
    </Container>
  );
};

export default JitsiComponent;
