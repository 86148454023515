import {
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { CommonDialog } from "../common/CommonDialog";
import { Check, Close } from "@mui/icons-material";
import { PatchRelatedPerson } from "../../services/fhir/relatedperson/PatchRelatedPerson";
import { APPC_CONSENT } from "../../services/appc";
import { toast } from "react-toastify";
import { FormButtonGroup } from "../form";
import { useClinicalRegisterContext } from "../../contexts/ClinicalRegisterContext";
import { useState } from "react";

export function VerifyResponsibleDialog({
  handleClose,
  isOpen,
  loading,
  setLoading,
  dataAppointment,
  related,
  fetchRelated,
  patientId,
  submit,
}: any) {
  const { verifyEncounter } = useClinicalRegisterContext();

  const [interactions, setInteractions] = useState<any>([]);

  return (
    <CommonDialog handleClose={handleClose} isOpen={isOpen}>
      <DialogTitle textAlign="center">
        <Typography variant="h5" fontWeight={600}>
          Responsável
        </Typography>
      </DialogTitle>

      <Typography
        textAlign="center"
        variant="body1"
        color="neutral700.main"
        fontWeight={500}
        mb={1}
      >
        {related?.length
          ? `Após a validação o responsável terá acesso aos registros eletrônicos de
        saúde do paciente`
          : "Verificação finalizada!"}
      </Typography>
      <DialogContent>
        <Stack spacing={2}>
          <Stack spacing={1}>
            {related
              ?.filter(
                (relateds: any) =>
                  !interactions?.includes(relateds?.resource?.id)
              )
              ?.map((e: any, index: number) => (
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="body1"
                    color="neutral700.main"
                    fontWeight={500}
                  >
                    {e.resource?.name?.[0]?.text} {""} - {""}
                    {e?.resource?.identifier?.[0]?.value}
                  </Typography>

                  <Stack direction="row">
                    <Tooltip title="Verificar">
                      <IconButton
                        color="primary"
                        disabled={e?.resource?.active}
                        onClick={async () => {
                          try {
                            await PatchRelatedPerson({
                              id: e?.resource?.id,
                              operation: "replace",
                              path: "/active",
                              content: true,
                            });

                            await APPC_CONSENT({
                              rule: "Permit",
                              patient_id: patientId,
                              subject_id: [e?.resource?.identifier?.[0]?.value],
                              cnes: null,
                              vigency: null,
                              purposeofuse: "PWATRNY",
                            });
                            if (!interactions.includes(index)) {
                              setInteractions([...interactions, index]);
                            }

                            await fetchRelated();
                            toast.success("Verificação realiza com sucesso  !");
                          } catch {
                            toast.warn(
                              "Não foi possível realizar a verificação, tente novamente!"
                            );
                          }
                        }}
                      >
                        <Check />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Não verificar">
                      <IconButton
                        sx={{
                          color: "error600.main",
                          "&:hover": {
                            background: "error700.main",
                          },
                        }}
                        onClick={async () => {
                          try {
                            await PatchRelatedPerson({
                              id: e?.resource?.id,
                              operation: "replace",
                              path: "/active",
                              content: false,
                            });

                            if (!interactions.includes(e?.resource?.id)) {
                              setInteractions([
                                ...interactions,
                                e?.resource?.id,
                              ]);
                            }
                            // await APPC_CONSENT({
                            //   rule: "Deny",
                            //   patient_id: patientId,
                            //   subject_id: [e?.resource?.identifier?.[0]?.value],
                            //   cnes: null,
                            //   vigency: 30,
                            // });
                            await fetchRelated();
                          } catch {
                            toast.warn(
                              "Não foi possível realizar a operação, tente novamente!"
                            );
                          }
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>
              ))}
          </Stack>
          <FormButtonGroup
            disabled={
              related?.length > 0
                ? interactions?.length !== related?.length
                : false
            }
            onGoBackButtonClick={handleClose}
            onNextButtonClick={async () => {
              setLoading(true);
              submit ? submit() : await verifyEncounter(dataAppointment);
              handleClose();
            }}
            goBackButtonText="Fechar"
            nextButtonText="Prosseguir"
            loading={loading}
          />
        </Stack>
      </DialogContent>
    </CommonDialog>
  );
}
