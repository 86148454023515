import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";

const translateCode: any = {
  wk: "semanas",
  mo: "meses",
};

export function SocialHistoryAccordion({ data, index }: any) {
  return (
    <Accordion
      defaultExpanded={index === 0}
      sx={{
        width: "500px",
        boxShadow: "1px 1px 16px 1px rgba(0, 0, 0, 0.1)",
        position: "initial",
        border: "1px solid",
        borderColor: "neutral200.main",
        borderRadius: 1,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Stack direction="row" width="100%" spacing={2}>
          <Chip
            label={
              data?.encounter?.resource?.serviceType?.[0]?.reference?.display ||
              "Serviço não encontrado"
            }
            size="small"
            sx={{
              color: data?.encounter?.resource?.serviceType?.[0]?.reference
                ?.display
                ? "neutral700.main"
                : "neutral500.main",

              fontWeight: 500,
              backgroundColor: data?.displayInfo?.healthcare ? "" : "#f1f1f1",
            }}
          />
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {data?.records.map((el: any) => {
          const verifyValue = (el: any) => {
            if (el?.resource?.code?.coding?.[0]?.code === "55284-4") {
              return `${
                el?.resource?.component?.[0]?.valueQuantity?.value || "-"
              }/${
                el?.resource?.component?.[1]?.valueQuantity?.value || "-"
              } mmHg`;
            }

            if (el?.resource?.hasOwnProperty("valueCodeableConcept")) {
              return `${
                el?.resource?.valueCodeableConcept?.coding?.[0]?.display ||
                el?.resource?.valueCodeableConcept?.text
              }`;
            }

            if (el?.resource?.hasOwnProperty("valueQuantity")) {
              const value = el?.resource?.valueQuantity?.value;
              const code = el?.resource?.valueQuantity?.code;

              if (value !== undefined && code !== undefined) {
                return `${value} ${translateCode[code] || code}`;
              }
              if (value !== undefined) {
                return `${value}`;
              }
              if (code !== undefined) {
                return `${translateCode[code] || code}`;
              }
            }
            if (el?.resource?.hasOwnProperty("valueDateTime")) {
              return dayjs(el?.resource?.valueDateTime).format("DD/MM/YYYY");
            }

            return "-";
          };

          const auxLabel = el?.resource?.code?.text;
          const auxValue = verifyValue(el);

          return (
            <Stack
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              height="50px"
              direction="row"
              sx={{ mt: 1 }}
            >
              <Stack p={1}>
                <Typography fontWeight={500}>{auxLabel}</Typography>
              </Stack>
              <Stack
                width="180px"
                borderRadius="5px"
                justifyContent="center"
                alignItems="center"
                padding={1}
                sx={{ background: "#E6F7F5" }}
              >
                <Typography
                  textAlign="center"
                  fontWeight={500}
                  sx={{ color: "neutral600.main" }}
                >
                  {`${auxValue}`}
                </Typography>
              </Stack>
            </Stack>
          );
        })}
        <Divider sx={{ my: 2 }}></Divider>
        <Typography
          color="neutral700.main"
          sx={{ fontWeight: 500, fontStyle: "italic", fontSize: 12 }}
        >
          Dr.{" "}
          {
            data?.encounter?.resource?.participant.find((el: any) =>
              el?.actor?.reference.includes("Practitioner")
            ).actor?.display
          }
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
