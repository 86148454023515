import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller } from "react-hook-form";

const InputDate = function (props: any) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue}
      rules={props.rules}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={{
              "& .MuiFilledInput-root": { backgroundColor: "neutral100.main" },
              mb: 4,
              ...props.sx,
            }}
            label={props.label}
            slotProps={{
              textField: {
                variant: "filled",
              },
            }}
            format="DD/MM/YYYY"
            {...field}
            onChange={(e) => {
              if (props.handleChange) props.handleChange(e);
              field.onChange(e);
            }}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default InputDate;
