import Box from "@mui/material/Box";
import { SignedDocs } from "./compontents/SignedDocs";
import { ScheduleForDash } from "../ScheduleForDash";
import { Carrousel } from "../Caroussel/Carrousel";
import { Stack } from "@mui/material";
import { MoreOptionsWeb } from "./compontents/MoreOptionsWeb";
import { BundlePatientSummary } from "../../../../services/fhir";
import { HTTPResponseStatus } from "../../../../utils/enum";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { useFhirData } from "../../../../utils/useFhirData";
import { Documents } from "../../../ehrrecords/components/patientSummaryAccordions/Documents/Documents";
import { PatientDocs } from "../../../ehrrecords/components/patientSummaryAccordions/Documents/PatientDocs";
export function WebPatient() {
  const { user } = useSessionContext();

  const [loading, setLoading] = useState<boolean>(true);
  const [summaryData, setSummaryData] = useState();
  const [refetch, setRefetch] = useState<boolean>(false);
  const navigate = useNavigate();

  const medicalNoteData = useFhirData.extractPatientSummaryResource(
    summaryData,
    "DocumentReference",
    "prescricao"
  )?.[0]?.entry;

  const solicitationData = useFhirData.extractPatientSummaryResource(
    summaryData,
    "DocumentReference",
    "service-request"
  )?.[0]?.entry;

  const NoteReportData = useFhirData.extractPatientSummaryResource(
    summaryData,
    "DocumentReference",
    "atestado"
  )?.[0]?.entry;

  useEffect(() => {
    async function GetBundle() {
      setLoading(true);
      try {
        const bundleResponse = await BundlePatientSummary({
          identifier: user?.username,
          purposeofuse: "Atendimento",
        });

        if (bundleResponse.status === HTTPResponseStatus.Forbidden) {
          navigate("/");
        }
        setSummaryData(bundleResponse);
      } catch (err) {
        console.log("PatientSummary error:", err);
      } finally {
        setLoading(false);
      }
    }
    if (user?.username) GetBundle();
  }, [refetch, navigate, user]);
  return (
    <Stack spacing={2}>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Box
          sx={{
            width: "50%",
            bgcolor: "white",
            overflowY: "auto", // Adiciona rolagem vertical
            maxHeight: "100%",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            height: "60vh", // Garante que a altura máxima seja o tamanho da janela
            boxShadow:
              "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
          }}
        >
          <ScheduleForDash />
        </Box>

        <Box
          sx={{
            width: "50%",
            backgroundColor: "neutral0.main",
            flexDirection: "column",
            borderRadius: "8px",
            // Adiciona rolagem vertical
            height: "60vh", // Garante que a altura máxima seja o tamanho da janela
            boxShadow:
              "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
          }}
        >
          <Stack
            spacing={1}
            sx={{
              width: "100%",
              maxHeight: "60vh",
              backgroundColor: "neutral0.main",
              borderRadius: { xs: "10px", md: "16px" },
              mb: { xs: 1, sm: 2 },
            }}
          >
            <PatientDocs
              setRefetch={setRefetch}
              sign
              medicalNoteData={medicalNoteData}
              solicitationData={solicitationData}
              resultsData={useFhirData.extractPatientSummaryResource(
                summaryData,
                "DocumentReference",
                "resultado"
              )}
              NoteReportData={NoteReportData}
            />
          </Stack>
        </Box>
      </Box>

      <Box sx={{ display: "flex", mb: 2, gap: 2 }}>
        <Box
          sx={{
            width: "50%",
            // bgcolor: "white",
            overflowY: "auto", // Adiciona rolagem vertical
            minHeight: "100%",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
          }}
        >
          <MoreOptionsWeb />
        </Box>

        <Box
          sx={{
            width: "50%",

            overflowY: "auto", // Adiciona rolagem vertical
            maxHeight: "100%",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            mb: "4px",
            boxShadow:
              "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
          }}
        >
          <Carrousel />
        </Box>
      </Box>
    </Stack>
  );
}
