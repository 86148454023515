import { ReactNode, createContext, useContext, useState } from "react";
import { RefreshToken } from "../services/auth";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";

interface AuthContextValue {
  parsedTime: any;
  timeLeft: any;
  getNewToken: any;
  logoutUser: any;
}

export const AuthContext = createContext<AuthContextValue>(null!);

export function AuthContextProvider({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const [auth, setAuth] = useState(
    localStorage.getItem("logged") ? true : false
  );

  const [expirationTime, setExpirationTime] = useState<string>(
    localStorage.getItem("expirationTime") || ""
  );
  const parsedTime = expirationTime ? parseInt(expirationTime, 10) : 0;

  const timeLeft = parsedTime ? parsedTime - Date.now() : 0;

  function logoutUser() {
    setAuth(false);
    localStorage.clear();
    toast.error("Sua sessão expirou realize o signin novamente");
    localStorage.clear();
    navigate("/signin");
  }

  async function getNewToken() {
    if (auth) {
      try {
        const { access_token, refresh_token, expires_in } =
          await RefreshToken();
        const nextExpiration = (Date.now() + expires_in * 1000).toString();
        setExpirationTime(nextExpiration);
        localStorage.setItem("expirationTime", nextExpiration);
        localStorage.setItem("token", `${access_token}`);
        localStorage.setItem("refresh_token", `${refresh_token}`);
      } catch (error) {
        logoutUser();
      }
    }
  }

  if (!auth) return <Navigate to="/signin" replace />;

  const contextValue = {
    parsedTime,
    timeLeft,
    getNewToken,
    logoutUser,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}
