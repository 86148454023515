import { Paper, Box, styled } from "@mui/material";

interface IProps {
  children: any;
}

const CustomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.neutral0.main,
  width: "100%",
  maxWidth: "100",
  height: "fit-content",
  borderRadius: "30px",

  [theme.breakpoints.down("md")]: {
    padding: "0%",
  },
}));

export function FitContentBox({ children }: IProps) {
  return (
    <CustomBox>
      <Paper
        sx={{
          display: "grid",
          // gridRowGap: "20px",
          width: "100%",
          padding: "24px 40px",
          borderRadius: "20px",
        }}
      >
        {children}
      </Paper>
    </CustomBox>
  );
}
