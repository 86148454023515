import { Grid, IconButton, Stack, Typography } from "@mui/material";
import { useFieldArray } from "react-hook-form";

import { FormTimeInput } from "../../../components/form";
import { Add, Remove } from "@mui/icons-material";
import dayjs from "dayjs";

export function AvailableTime({
  selectedDays,
  control,
  unregister,
  defaultValues,
}: any) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "controlledField",
  });

  return (
    <Stack mt={2} spacing={1}>
      {selectedDays?.map((el: string, index: number) => (
        <Grid
          container
          direction="row"
          alignItems="center"
          key={`${el}.${index}`}
        >
          <Grid item xs={1}>
            <Typography variant="body1">{el}:</Typography>
          </Grid>
          <Grid item xs={4.5}>
            <FormTimeInput
              required
              name={`timeAvailable[0].${el}.timeStart`}
              label=""
              control={control}
              defaultValue={dayjs("2022-04-17T08:00")}
              validate={(e: any) => !isNaN(e) || e === null}
              hiddenLabel={true}
            />
          </Grid>
          <Grid item xs={1} textAlign="center">
            <Typography variant="body1">até</Typography>
          </Grid>
          <Grid item xs={4.5}>
            <FormTimeInput
              required
              name={`timeAvailable[0].${el}.timeEnd`}
              label=""
              control={control}
              defaultValue={dayjs("2022-04-17T18:00")}
              validate={(e: any) => !isNaN(e) || e === null}
              hiddenLabel={true}
            />
          </Grid>
          <Grid item xs={1} textAlign="center">
            {!fields.find((elFind: any) => elFind.day === el) && (
              <IconButton
                sx={{
                  border: "1px solid",
                  borderColor: "neutral700.main",
                  borderRadius: "50%",
                  width: "10px",
                  height: "10px",
                }}
                onClick={() => {
                  append({
                    day: el,
                  });
                }}
              >
                <Add fontSize="small" />
              </IconButton>
            )}
          </Grid>
          {fields
            .filter((auxElem: any) => auxElem?.day === el)
            .map((mapElem: any) => (
              <Grid container key={mapElem.id} alignItems="center" mt={1}>
                <Grid item xs={1}></Grid>
                <Grid item xs={4.5}>
                  <FormTimeInput
                    required
                    name={`timeAvailable[1].${mapElem.day}.timeStart`}
                    label=""
                    control={control}
                    defaultValue={dayjs("2022-04-17T08:00")}
                    validate={(e: any) => !isNaN(e) || e === null}
                    hiddenLabel={true}
                  />
                </Grid>
                <Grid item xs={1} textAlign="center">
                  <Typography variant="body1">até</Typography>
                </Grid>
                <Grid item xs={4.5}>
                  <FormTimeInput
                    required
                    name={`timeAvailable[1].${mapElem.day}.timeEnd`}
                    label=""
                    control={control}
                    defaultValue={dayjs("2022-04-17T18:00")}
                    validate={(e: any) => !isNaN(e) || e === null}
                    hiddenLabel={true}
                  />
                </Grid>
                <Grid item xs={1} textAlign="center">
                  <IconButton
                    sx={{
                      border: "1px solid",
                      borderColor: "neutral700.main",
                      borderRadius: "50%",
                      width: "10px",
                      height: "10px",
                    }}
                    onClick={(e) => {
                      remove(
                        fields.findIndex((elFind: any) => elFind.day === el)
                      );
                      unregister(`timeAvailable[1].${mapElem.day}`);
                    }}
                  >
                    <Remove fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
        </Grid>
      ))}
    </Stack>
  );
}
