import { endpoints } from "../../configs/Settings";
import { useAuth } from "../../utils/useAuth";
import { useAxios } from "../../utils/useAxios";

interface ISignProps {
  pdfBase64: any;
  cpfCnpj: any;
  provider: "vidaas" | "safeid";
  userPassword?: any;
  lifeTime: number; // Novo parâmetro adicionado
}

export async function Base64SignPDF({
  pdfBase64,
  cpfCnpj,
  provider,
  userPassword,
  lifeTime, 
}: ISignProps) {
  const url = `${endpoints.DSIG}/pdf-sign`;
  const data = {
    pdfBase64,
    cpfCnpj,
    provider,
    userPassword,
    lifeTime, 
  };
  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
  };
  const response = await useAxios
    .post(url, data, {
      headers,
      timeout: 120000,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
  if (response) return response;
}
