import { Controller } from "react-hook-form";
import { FormInputProps } from "../types";
import { FormControlLabel, Switch } from "@mui/material";

interface IProps extends FormInputProps {
  labelPlacement?: "top" | "start" | "bottom" | "end";
}

export function FormSwitch({
  name,
  control,
  label,
  checked,
  defaultValue,
  labelPlacement,
}: IProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              defaultChecked={defaultValue || false}
              onChange={onChange}
            />
          }
          label={label}
          labelPlacement={labelPlacement || "end"}
        />
      )}
    />
  );
}
