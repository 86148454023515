export enum Steps {
  S1PersonalData,
  S2ConfirmAccount,
  S3ConfirmationCode,
  S4SecurityData,
  S5TermsOfUse,
  S6ActiveAccount,
}

export interface IFormInput {
  firstName: string;
  surname: string;
  identifier: string;
  birthdate: Date;
  password: string;
  passwordConfirm: string;
  phoneNumber: string;
  email: string;
  gender: string;
  firebaseCode: string;
  firebaseToken: string;
}
