import React, { useCallback, useEffect, useState } from "react";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";

export const KeyPressShortCutTabs = function () {
  const { registerAreaTab, setRegisterAreaTab } = useMedicalCareContext();
  const [keySequence, setKeySequence] = useState<string[]>([]);

  const shortCutsTabs = {
    "Control+1": () => setRegisterAreaTab(0),
    "Control+2": () => setRegisterAreaTab(1),
    "Control+3": () => setRegisterAreaTab(2),
    "Control+4": () => setRegisterAreaTab(3),
    "Control+5": () => setRegisterAreaTab(4),
    "Control+6": () => setRegisterAreaTab(5),
    "Control+7": () => setRegisterAreaTab(6),
    "Control+8": () => setRegisterAreaTab(7),
    "Control+9": () => setRegisterAreaTab(8),
    "Control+0": () => setRegisterAreaTab(9),
  };

  const moveDown = () => {
    let tabMove = registerAreaTab + 1;
    if (tabMove > 9) {
      tabMove = 0;
    }

    setRegisterAreaTab(tabMove);
    return;
  };

  const moveUp = () => {
    let tabMove = registerAreaTab - 1;
    if (tabMove < 0) {
      tabMove = 9;
    }

    setRegisterAreaTab(tabMove);

    return;
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const key = event.key;
      const isCtrl = event.ctrlKey;
      const newKeySequence = [...keySequence, key];

      setKeySequence(newKeySequence);

      const sequenceStr = newKeySequence.join("+");

      if (isCtrl && key === "ArrowDown") {
        event.preventDefault();
        moveDown();
      } else if (isCtrl && key === "ArrowUp") {
        event.preventDefault();
        moveUp();
      }

      if (isCtrl && key === "1") {
        event.preventDefault();
        shortCutsTabs["Control+1"]();
        setKeySequence([]);
      } else if (isCtrl && key === "2") {
        event.preventDefault();
        shortCutsTabs["Control+2"]();
        setKeySequence([]);
      } else if (isCtrl && key === "3") {
        event.preventDefault();
        shortCutsTabs["Control+3"]();
        setKeySequence([]);
      } else if (isCtrl && key === "4") {
        event.preventDefault();
        shortCutsTabs["Control+4"]();
        setKeySequence([]);
      } else if (isCtrl && key === "5") {
        event.preventDefault();
        shortCutsTabs["Control+5"]();
        setKeySequence([]);
      } else if (isCtrl && key === "6") {
        event.preventDefault();
        shortCutsTabs["Control+6"]();
        setKeySequence([]);
      } else if (isCtrl && key === "7") {
        event.preventDefault();
        shortCutsTabs["Control+7"]();
        setKeySequence([]);
      } else if (isCtrl && key === "8") {
        event.preventDefault();
        shortCutsTabs["Control+8"]();
        setKeySequence([]);
      } else if (isCtrl && key === "9") {
        event.preventDefault();
        shortCutsTabs["Control+9"]();
        setKeySequence([]);
      } else if (isCtrl && key === "0") {
        event.preventDefault();
        shortCutsTabs["Control+0"]();
        setKeySequence([]);
      }

      if (key === "Escape") {
        setKeySequence([]);
      }
    },
    [keySequence]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return <> </>;
};

export const KeyPressShortCutAreas = function () {
  const { activeStep, setActiveStep } = useMedicalCareContext();
  const [keySequence, setKeySequence] = useState<string[]>([]);

  const shortCutsAreas = {
    "Alt+1": () => setActiveStep(0),
    "Alt+2": () => setActiveStep(1),
    "Alt+3": () => setActiveStep(2),
  };

  const moveRight = () => {
    let tabMove = activeStep + 1;
    if (tabMove > 2) {
      tabMove = 0;
    }

    setActiveStep(tabMove);
    return;
  };

  const moveLeft = () => {
    let tabMove = activeStep - 1;
    if (tabMove < 0) {
      tabMove = 2;
    }

    setActiveStep(tabMove);

    return;
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const key = event.key;
      const isAlt = event.altKey;
      const isCtrl = event.ctrlKey;
      const newKeySequence = [...keySequence, key];

      setKeySequence(newKeySequence);

      const sequenceStr = newKeySequence.join("+");

      if (isCtrl && key === "ArrowLeft") {
        event.preventDefault();
        moveLeft();
      } else if (isCtrl && key === "ArrowRight") {
        event.preventDefault();
        moveRight();
      }

      if (isAlt && key === "1") {
        event.preventDefault();
        shortCutsAreas["Alt+1"]();
        setKeySequence([]);
      } else if (isAlt && key === "2") {
        event.preventDefault();
        shortCutsAreas["Alt+2"]();
        setKeySequence([]);
      } else if (isAlt && key === "3") {
        event.preventDefault();
        shortCutsAreas["Alt+3"]();
        setKeySequence([]);
      }

      if (key === "Escape") {
        setKeySequence([]);
      }
    },
    [keySequence]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return <> </>;
};

export const KeyPressShortCutInterventionTabs = function () {
  const { interventionTab, setInterventionTab } = useMedicalCareContext();

  const [keySequence, setKeySequence] = useState<string[]>([]);

  const moveDown = () => {
    let tabMove = interventionTab + 1;
    if (tabMove > 3) {
      tabMove = 0;
    }

    setInterventionTab(tabMove);
    return;
  };

  const moveUp = () => {
    let tabMove = interventionTab - 1;
    if (tabMove < 0) {
      tabMove = 3;
    }

    setInterventionTab(tabMove);

    return;
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const key = event.key;
      const isCtrl = event.ctrlKey;
      const newKeySequence = [...keySequence, key];

      setKeySequence(newKeySequence);

      const sequenceStr = newKeySequence.join("+");

      if (isCtrl && key === "ArrowUp") {
        event.preventDefault();
        moveUp();
      } else if (isCtrl && key === "ArrowDown") {
        event.preventDefault();
        moveDown();
      }

      if (key === "Escape") {
        setKeySequence([]);
      }
    },
    [keySequence]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return <> </>;
};
