import { Box, Typography } from "@mui/material";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useForm } from "react-hook-form";

interface SignatureContextValue {
  control: any;
  handleSubmit: any;
  selectedDuration: any;
  setSelectedDuration: any;

  provider: any;
  setProvider: any;
  providerPassword: any;
  setProviderPassword: any;
}

export const SignatureContext = createContext<SignatureContextValue>(null!);

export function SignatureContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [provider, setProvider] = useState<any>(
    localStorage.getItem("selectProvider")
  );

  const [providerPassword, setProviderPassword] = useState<any>(
    localStorage.getItem("providerPassword")
  );

  const [selectedDuration, setSelectedDuration] = useState<number>(() => {
    const storedDuration = localStorage.getItem("selectedDuration");
    return storedDuration ? Number(storedDuration) : 0;
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      provider: provider || "",
      password: providerPassword || "",
      lifeTime: selectedDuration,
    },
  });

  const contextValue = {
    selectedDuration,
    setSelectedDuration,
    control,
    handleSubmit,
    provider,
    setProvider,
    providerPassword,
    setProviderPassword,
  };

  return (
    <SignatureContext.Provider value={contextValue}>
      {children}
    </SignatureContext.Provider>
  );
}

export function useSignatureContext() {
  return useContext(SignatureContext);
}
