import { ContentBoxTitle } from "../../../components/common";
import { Stack, Typography } from "@mui/material";
import {
  FormAutoComplete,
  FormButtonGroup,
  FormMultipleAutoComplete,
  FormSelectInput,
  FormTextInput,
} from "../../../components/form";
import { useNavigate } from "react-router-dom";
import { UFValueSet } from "./form/ValueSet";
import { IFormInput } from "../Types";
import { useState } from "react";
import {
  GetPractitionerRole,
  UpdatePractitionerRole,
} from "../../../services/fhir";
import { useSessionContext } from "../../../contexts/SessionContext";
import { oids } from "../../../configs/Settings";
import { toast } from "react-toastify";
import { useWatch } from "react-hook-form";

interface IProps {
  control: any;
  onNextButtonClick: Function;
  specialties: {
    label: string;
    value: string;
  }[];
  loading: boolean;
  edit?: boolean;
}

export default function ProfessionalForm({
  control,
  onNextButtonClick,
  specialties,
  loading,
  edit,
}: IProps) {
  const navigate = useNavigate();
  const [Newloading, setNewloading] = useState(false);
  const { user, access } = useSessionContext();

  const options = [
    {
      display: "CRM - Conselho Regional de Medicina",
      value: "CRM",
      descricao: "Medicina",
      code: 1,
    },
    {
      display: "COREN - Conselho Regional de Enfermagem",
      value: "COREN",
      descricao: "Enfermagem",
      code: 2,
    },
    {
      display: "CRO - Conselho Regional de Odontologia",
      value: "CRO",
      descricao: "Odontologia",
      code: 3,
    },
    {
      display:
        "CREFITO - Conselho Regional de Fisioterapia e Terapia Ocupacional",
      value: "CREFITO",
      descricao: "Fisioterapia e Terapia Ocupacional",
      code: 4,
    },
    {
      display: "CRP - Conselho Regional de Psicologia",
      value: "CRP",
      descricao: "Psicologia",
      code: 5,
    },
    {
      display: "CRF - Conselho Regional de Farmácia",
      value: "CRF",
      descricao: "Farmácia",
      code: 6,
    },
    {
      display: "CRN - Conselho Regional de Nutricionistas",
      value: "CRN",
      descricao: "Nutrição",
      code: 7,
    },
    {
      display: "CRBM - Conselho Regional de Biomedicina",
      value: "CRBM",
      descricao: "Biomedicina",
      code: 8,
    },
    {
      display: "CREFONO - Conselho Regional de Fonoaudiologia",
      value: "CREFONO",
      descricao: "Fonoaudiologia",
      code: 9,
    },
    {
      display: "CREF - Conselho Regional de Educação Física",
      value: "CREF",
      descricao: "Educação Física",
      code: 10,
    },
  ];

  const selectedOption = useWatch({ name: "classe", control });

  const onSubmit = async (data: IFormInput) => {
    setNewloading(true);

    try {
      const practitionerRole = await GetPractitionerRole({
        id: `${access?.organizationId}-${oids.cpf}-${user.username}`,
      });

      await UpdatePractitionerRole(practitionerRole, {
        conselho: data?.classe,
        numero: data?.number,
        uf: data?.uf,
        speciality:
          data?.speciality ||
          options?.find((e: any) => e.value === selectedOption),
      });

      toast.success("Registro profissional realizado com sucesso!");
      navigate("/");
      window.location.reload();
    } catch (err) {
      console.log("err", err);
    } finally {
      setNewloading(false);
    }
  };

  return (
    <Stack
      width="100%"
      height={{ xs: "fit-content", md: "100%" }}
      flex={"1"}
      justifyContent={{ xs: "flex-start", md: "center" }}
      alignItems="center"
      spacing={4}
    >
      {!edit && (
        <>
          <ContentBoxTitle>Informações profissionais</ContentBoxTitle>
          <Typography
            variant="body1"
            color="neutral700.main"
            textAlign="center"
            m={{ xs: 2, md: 5 }}
          >
            Insira seu conselho de classe e UF para finalizar seu cadastro como
            profissional de saúde
          </Typography>
        </>
      )}

      <Stack direction="row" width="100%" spacing={2}>
        <FormSelectInput
          control={control}
          name="classe"
          label="Conselho de classe"
          selectOptions={options}
          required
        />

        <Stack width="40%">
          <FormAutoComplete
            name="uf"
            control={control}
            label="UF"
            selectOptions={UFValueSet}
            required
          />
        </Stack>
      </Stack>

      <FormTextInput
        name="number"
        maxLength={selectedOption === "CRM" ? 6 : undefined}
        control={control}
        label="Número no conselho de classe"
        required={true}
        autoFocus
      />

      {selectedOption === "CRM" && (
        <FormMultipleAutoComplete
          variant="filled"
          name="speciality"
          control={control}
          label="Especialidade"
          selectOptions={specialties}
          loading={loading}
          required
        />
      )}

      {!edit && (
        <Stack
          width="100%"
          justifyContent="flex-end"
          direction="row"
          spacing={3}
          mt={2}
        >
          <FormButtonGroup
            onGoBackButtonClick={() => navigate("/")}
            onNextButtonClick={onNextButtonClick(onSubmit)}
            goBackButtonText="Voltar"
            nextButtonText="Finalizar"
            loading={Newloading}
          />
        </Stack>
      )}
    </Stack>
  );
}
