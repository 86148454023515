export const handleKeyPress = (
  event: any,
  loading: boolean,
  handleSubmit: Function
) => {
  const activeElement = document.activeElement?.id;
  if (event.key === "Enter" && activeElement !== "goBackButton" && !loading) {
    event.preventDefault();
    handleSubmit();
  }
};
