import { Controller } from "react-hook-form";
import { FormInputProps } from "../types";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

interface IProps {
  selectOptions: any;
  defaultValue?: any;
  rowOrientation?: boolean;
}

type TypeMergedInterfaces = FormInputProps & IProps;

export function FormRadioButton({
  name,
  control,
  label,
  required,
  selectOptions,
  defaultValue,
  rowOrientation,
}: TypeMergedInterfaces) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: required,
      }}
      render={({ field: { onChange, value } }) => {
        return (
          <FormControl fullWidth>
            <FormLabel>{label}</FormLabel>
            <RadioGroup value={value} row={rowOrientation}>
              {selectOptions.map((element: any, index: number) => {
                return (
                  <FormControlLabel
                    key={`${element}.${index}`}
                    value={element.value}
                    control={<Radio />}
                    label={element.display}
                    onChange={onChange}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        );
      }}
    />
  );
}
