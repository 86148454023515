import { useSessionContext } from "../../contexts/SessionContext";
import { IFeatures } from "../../utils/interfaces";

import { currentFeatures } from "../../configs/Settings";

import { Assignment } from "@mui/icons-material";
import { EHRRecords } from "./EHRRecords";

export function useMyRecords() {
  const { access } = useSessionContext();

  const configs: IFeatures = {
    active: !currentFeatures.register && access?.type === "patient",
    path: "/myrecords",
    icon: Assignment,
    text: "Meus registros",
    sideMenuIndex: 0,
    avatarIndex: 0,
    sideMenu: true,
    avatarMenu: true,

    component: () => <EHRRecords />,
  };

  return configs;
}
