import { endpoints } from "../../../configs/Settings";
import { useAxios } from "../../../utils/useAxios";
import { auxiliarData } from "../AuxiliarData";
import { HTTPResponseStatus } from "../../../utils/enum";
import { IPatientData } from "../../../utils/interfaces";
import { OAuthApplication } from "../../auth";
import { toast } from "react-toastify";

export async function PostPatient(objData: IPatientData) {
  const url = `${endpoints.MPIFHIR}/Patient`;

  const bearerToken = await OAuthApplication();
  const data = auxiliarData.Patient(objData);
  const headers = {
    Accept: "application/fhir+json",
    Authorization: bearerToken,
    "Content-Type": "application/fhir+json",
  };

  const response = await useAxios
    .post(url, data, { headers, timeout: 20000 })
    .then((response) => {
      if (
        response.status !== HTTPResponseStatus.Created &&
        response.status !== HTTPResponseStatus.Success
      ) {
        throw new Error(`PostPatient: ${response.status}`);
      }
      return { status: HTTPResponseStatus.Success, data: response.data };
    })
    .catch((error) => {
      console.error("PostPatient ", error);
      // toast.error(`PostPatient Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
