import { Stack } from "@mui/material";
import { LargeContentBox, PageTitle } from "../../components/common";
import { PrivateMainLayout } from "../../components/layout";
import { AppointmentAccordion } from "./components/AppointmentAccordion";
import { isMobile } from "react-device-detect";

export function ScheduleViewer() {
  return (
    <PrivateMainLayout noSidebar={isMobile} mobileView={isMobile}>
      <LargeContentBox>
        <Stack sx={{ width: "100%" }} spacing={4}>
          <PageTitle>Minha agenda</PageTitle>
          <AppointmentAccordion />
        </Stack>
      </LargeContentBox>
    </PrivateMainLayout>
  );
}
