import { Dispatch, SetStateAction } from "react";
import { Divider, Stack, Typography } from "@mui/material";

import { EditableCardRow } from "../../../components/common";

import { useSessionContext } from "../../../contexts/SessionContext";
import { useFormatter } from "../../../utils/useFormatter";

interface IProps {
  setIsEditEmailOpen: Dispatch<SetStateAction<boolean>>;
  setIsEditPhoneOpen: Dispatch<SetStateAction<boolean>>;
}

export function CardContact({
  setIsEditEmailOpen,
  setIsEditPhoneOpen,
}: IProps) {
  const { user } = useSessionContext();
  return (
    <Stack
      sx={{
        border: "1px solid",
        borderColor: "neutral700.main",
        borderRadius: "15px",
      }}
      width="100%"
      paddingTop={2}
    >
      <Typography
        variant="h5"
        fontWeight="500"
        paddingBottom={3}
        paddingLeft={5}
      >
        Contato
      </Typography>
      <EditableCardRow
        title="E-mail"
        titleContext={user?.email}
        onClick={() => {
          setIsEditEmailOpen(true);
        }}
      />
      <Divider sx={{ marginLeft: 5 }} />
      <EditableCardRow
        title="Telefone"
        titleContext={useFormatter.formatPhoneNumber(user?.phone)}
        onClick={() => {
          setIsEditPhoneOpen(true);
        }}
      />
    </Stack>
  );
}
