import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";

import { FilterType, IDateLimit } from "../types";

interface IProps {
  dateFilter: Dayjs;
  setDateFilter: any;
  filterType: FilterType;
  setFilterType: any;
  dateLimit: IDateLimit;
}

export function DateFilter({
  dateFilter,
  setDateFilter,
  filterType,
  setFilterType,
  dateLimit,
}: IProps) {
  const formatType = filterType === "day" ? "DD/MM/YYYY" : "MMMM YYYY";

  function subtractDate() {
    return dayjs(dateFilter).subtract(1, filterType);
  }

  function addDate() {
    return dayjs(dateFilter).add(1, filterType);
  }

  function handleOnChangeDate(operation: "subtract" | "add") {
    switch (operation) {
      case "subtract":
        setDateFilter(subtractDate());
        return;
      case "add":
        setDateFilter(addDate());
        return;
    }
  }

  function handleOnSelectChange(selectedFilterType: FilterType) {
    setDateFilter(dateFilter.set("date", dayjs().get("date")));
    setFilterType(selectedFilterType);
  }

  return (
    <Stack spacing={1} width="fit-content" direction="row" alignItems="center">
      <FormControl
        variant="filled"
        size="small"
        hiddenLabel
        sx={{ width: "80px" }}
      >
        <Select
          value={filterType}
          onChange={(e) => handleOnSelectChange(e.target.value as FilterType)}
        >
          <MenuItem value="day">Dia</MenuItem>
          <MenuItem value="month">Mês</MenuItem>
        </Select>
      </FormControl>

      <IconButton
        onClick={() => handleOnChangeDate("subtract")}
        disabled={
          dateLimit.minDate && subtractDate().isBefore(dayjs(), filterType)
        }
      >
        <ChevronLeft fontSize="small" />
      </IconButton>

      <Typography textAlign="center" width="75px" textTransform="capitalize">
        {dateFilter?.format(formatType)}
      </Typography>

      <IconButton
        onClick={() => handleOnChangeDate("add")}
        disabled={dateLimit.maxDate && addDate().isAfter(dayjs(), filterType)}
      >
        <ChevronRight fontSize="small" />
      </IconButton>
    </Stack>
  );
}
