import { Stack } from "@mui/material";
import { ScreenMobile } from "../../components/common/ScreenMobile";
import { LineInformationWithButton } from "./components/LineInformationWithButton";
import { useState } from "react";
import { EditPasswordDialog } from "../profile/components/EditPasswordDialog";

export function PasswordScreenMobile({ open, onClose }: any) {
  const [isEditPasswordOpen, setIsEditPasswordOpen] = useState<boolean>(false);
  return (
    <>
      <ScreenMobile title="Senha" open={open} onClose={onClose}>
        <Stack width="100%">
          <LineInformationWithButton
            label="Senha"
            value="********"
            onClick={() => setIsEditPasswordOpen(true)}
          />
        </Stack>
      </ScreenMobile>

      <EditPasswordDialog
        isOpen={isEditPasswordOpen}
        setIsOpen={setIsEditPasswordOpen}
      />
    </>
  );
}
