import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import {
  ConfirmationResult,
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";

const Config = {
  apiKey: "AIzaSyAk7r2-K41mIeQI9j9v2W77XVsy8oJrEYQ",
  authDomain: "ehrrunner-74e31.firebaseapp.com",
  databaseURL: "https://ehrrunner-74e31.firebaseio.com",
  projectId: "ehrrunner-74e31",
  storageBucket: "ehrrunner-74e31.appspot.com",
  messagingSenderId: "643741161718",
  appId: "1:643741161718:web:3c79c5a0eca2f35f55f4d7",
  measurementId: "G-189CXSRTYW",
};

let confirmation: ConfirmationResult | null = null;

declare const window: any;
const app = initializeApp(Config);
const messaging = getMessaging(app);

const auth = getAuth(app);
auth.languageCode = "pt-BR";

async function firebaseSendSMS(phone: string, captcha: any) {
  try {
    confirmation = await signInWithPhoneNumber(auth, phone, captcha);
  } catch (err) {
    throw err;
  }
}

onMessage(messaging, (payload) => {
  // console.log("Mensagem recebida:", payload);
  // Aqui você pode manipular a mensagem recebida, como exibir uma notificação ou atualizar o estado do aplicativo
});

async function verifyCode(code: string) {
  if (!confirmation) {
    throw new Error(
      "Confirmation is not available. Please request SMS verification first."
    );
  }

  try {
    const result = await confirmation.confirm(code);
    return result;
  } catch (error) {
    throw error;
  }
}

async function sendVerificationCode(contact: string) {
  try {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback() {},
          "expired-callback": function () {},
        }
      );
      window.recaptchaVerifier.render();
    }

    await firebaseSendSMS(
      `+55${contact.replace(/\D/g, "")}`,
      window.recaptchaVerifier
    );
  } catch (error) {
    throw new Error();
  }
}

async function requestNotificationPermission() {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BLFrhq7p-lrJNL7NaGHk2S8Y4uCmN-oG-7Npe5UlmXvpsvcEnVLugswK4N-vgUH2m66oNdQeiik-u51OMiDehOs",
      })
        .then((currentToken) => {
          if (currentToken) {
            return currentToken;
            // Send the token to your server and update the UI if necessary
            // ...
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });

      return token;
    } else {
      console.log(
        "Permissão para notificações bloqueada ou negada pelo usuário."
      );
      // Exibir instruções claras para o usuário sobre como conceder permissão
      // Aqui você pode mostrar uma mensagem ou um modal com instruções para o usuário
      // Por exemplo:
      // showNotificationPermissionInstructions();
    }
  } catch (error) {
    console.error("Erro ao solicitar permissão para notificações:", error);
  }
}

export const useFirebaseService = {
  verifyCode,
  sendVerificationCode,
  requestNotificationPermission,
};
