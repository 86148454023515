import React, { useState } from "react";
import { TabContext, TabList } from "@mui/lab";
import { Box, Tab } from "@mui/material";

import { MedicationSearchTabs } from "./MedicationSearchTabs";

type SelectedTabType = "1" | "2" | "3" | "4";

interface IProps {
  action: Function;
  methods: any;
  isEdit: boolean;
}

export function MedicationTab({ action, methods, isEdit }: IProps) {
  const [selectedTab, setSelectedTab] = useState<SelectedTabType>("1");

  const { control, handleSubmit, reset, setValue } = methods;

  const handleChangeTab = (e: React.SyntheticEvent, value: SelectedTabType) =>
    setSelectedTab(value);

  return (
    <>
      <TabContext value={selectedTab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TabList onChange={handleChangeTab}>
            <Tab
              value="1"
              label="Nomes comerciais"
              sx={{ textTransform: "none" }}
            />
            <Tab
              value="2"
              label="Princípio ativo"
              sx={{ textTransform: "none" }}
            />
            <Tab
              value="3"
              label="Nomes genéricos"
              sx={{ textTransform: "none" }}
            />
            {/* <Tab value="4" label="Periféricos" sx={{ textTransform: "none" }} /> */}
          </TabList>
        </Box>

        <MedicationSearchTabs
          selectedTab={selectedTab}
          control={control}
          isEdit={isEdit}
          handleSubmit={handleSubmit}
          action={action}
          reset={reset}
          setValue={setValue}
        />
      </TabContext>
    </>
  );
}
