import { Stack } from "@mui/material";
import { ValidateFields } from "./components/ValidateFields";
import { useEffect, useState } from "react";
import { CircularLoading } from "../../components/common";
import { GetBinaryDocument } from "../../services/fhir/patientsummary/Binary/GetBinaryDocument";
import base64 from "base-64";

import { DocumentNotFound } from "./components/DocumentNotFound";
import { useParams } from "react-router-dom";
import { GetDocumentReferenceById } from "../../services/fhir/documentreference/GetDocumentReferenceById";
import { PatchEHRPoster } from "../../services/fhir/patientsummary/PatchEHRPoster";
import dayjs from "dayjs";
import { oids } from "../../configs/Settings";

export function ValidateDocument() {
  const [loading, setLoading] = useState<boolean>(false);

  const [documentBinary, setDocumentBinary] = useState<any>();
  const [refetchData, setRefetchData] = useState<boolean>(true);
  const [organizationId, setOrganizationId] = useState<any>();
  const [documentReference, setDocumentReference] = useState<any>();

  const { id } = useParams();

  const handleRefetch = async (orgId: any) => {
    if (documentReference) {
      try {
        const verifyAttester = documentReference?.attester?.find((el: any) =>
          el?.party?.reference?.includes(orgId)
        );

        if (!verifyAttester) {
          await PatchEHRPoster({
            resourceType: "DocumentReference",
            resourceId: documentReference?.id || "",
            operation: "add",
            path: documentReference?.attester?.length
              ? "/attester/-"
              : "/attester",
            value: documentReference?.attester?.length
              ? {
                  mode: "professional",
                  time: dayjs()?.toISOString(),
                  party: {
                    reference: `Organization/${oids.cnpj}-${orgId}`,
                  },
                }
              : [
                  {
                    mode: "professional",
                    time: dayjs()?.toISOString(),
                    party: {
                      reference: `Organization/${oids.cnpj}-${orgId}`,
                    },
                  },
                ],
            applicationAuth: true,
          });
        }
      } catch (err) {
        console.log("PatchErr", err);
      }
    }
    setOrganizationId(orgId);
    setRefetchData(true);
  };

  useEffect(() => {
    async function fetchBinary() {
      try {
        setLoading(true);

        const decodedParams = JSON.parse(base64.decode(id || ""));

        if (decodedParams?.patientId) {
          const url = `1.3.6.1.4.1.54413.3.2.3.${decodedParams?.organization}.10.${decodedParams?.idValue}.2`;
          try {
            const documentReference = await GetDocumentReferenceById({
              patient: decodedParams?.patientId,
              docId: url,
              organizationId: organizationId,
              subjectId: decodedParams.uuid,
            });
            const auxUrl =
              documentReference?.entry?.[0]?.resource?.content?.[0]?.attachment
                ?.url;

            setDocumentReference(documentReference?.entry?.[0]?.resource);

            const binary = await GetBinaryDocument({
              organizationId: organizationId,
              urlBinary: url,
              subjectId: decodedParams.uuid,
            });
            if (binary) setDocumentBinary(binary?.data);
          } catch {
            const binary = await GetBinaryDocument({
              organizationId: organizationId,
              urlBinary: url,
              subjectId: decodedParams.uuid,
            });
            if (binary) setDocumentBinary(binary?.data);
          }
        } else {
          const binary = await GetBinaryDocument({
            urlBinary: id,

            // subjectId: "05404225173",
          });
          if (binary) setDocumentBinary(binary?.data);
        }
      } catch (err) {
        console.log("Binary err", err);
      } finally {
        setLoading(false);
        setRefetchData(false);
      }
    }

    if (id && refetchData) fetchBinary();
  }, [refetchData]);

  return (
    <Stack
      direction={{ xs: "column", lg: "row" }}
      justifyContent={{ xs: "flex-start", lg: "center" }}
      alignItems="center"
      width="100%"
      height="100vh"
      sx={{ backgroundColor: "primary100.main" }}
      borderRadius={4}
    >
      {/* <Stack width="50%" height="100vh" display={{ xs: "none", lg: "flex" }}>
        <img
          src={SignInLogo}
          alt="Logo do Fluxmed"
          style={{ maxWidth: "100%", objectFit: "cover", height: "100vh" }}
        />
      </Stack> */}
      <Stack
        width={{ xs: "100%", lg: "50%" }}
        height={{ xs: "100vh", lg: "100vh" }}
        justifyContent={{ xs: "flex-start", lg: "center" }}
        alignItems="center"
        p={3}
        sx={{ backgroundColor: "neutral0.main" }}
        borderRadius={3}
      >
        {loading ? (
          <CircularLoading />
        ) : documentBinary ? (
          <ValidateFields
            documentReference={documentReference}
            documentBinary={documentBinary}
            handleRefetch={handleRefetch}
          />
        ) : (
          <DocumentNotFound />
        )}
      </Stack>
    </Stack>
  );
}
