import { Search } from "@mui/icons-material";
import { useSessionContext } from "../../contexts/SessionContext";
import { IFeatures } from "../../utils/interfaces";
import { PatientSearch } from "./PatientSearch";

export function usePatientSearch() {
  const { access } = useSessionContext();

  const configs: IFeatures = {
    active: access.type === "professional",
    path: "/patientsearch",
    icon: Search,
    text: "Pesquisar pacientes",

    avatarMenu: true,
    avatarIndex: 3,

    component: () => <PatientSearch />,
  };

  return configs;
}
