import { useEffect, useState } from "react";
import { Box, Button, DialogContent, Stack, Typography } from "@mui/material";

import { FormInputValidationNumber } from "../../../signup/components/forminputinvalidationnumber";
import { useFormatter } from "../../../../utils/useFormatter";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { Control } from "react-hook-form";
import { FormButtonGroup } from "../../../../components/form";

interface IProps {
  onGoBackButtonClick: Function;
  control: Control<IFormInput | any>;
  onResendButtonClick: Function;
  onSubmitButtonClick: Function;
  loading: boolean;
}

interface IFormInput {
  confirmationCode: string;
}

const SEND_CODE_TIMER = 10;

export default function ConfirmPhoneContent({
  onGoBackButtonClick,
  onResendButtonClick,
  onSubmitButtonClick,
  control,
  loading,
}: IProps) {
  const { formatPhoneNumber } = useFormatter;
  const { user } = useSessionContext();

  const [counter, setCounter] = useState(SEND_CODE_TIMER);

  const IS_BUTTON_DISABLED = counter !== 0;

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter > 0) {
        setCounter((prevCounter) => prevCounter - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [counter]);

  return (
    <DialogContent>
      <Stack spacing={2} alignItems="center">
        <Typography
          textAlign="center"
          color="neutral600.main"
          variant="body1"
          sx={{ px: 4 }}
        >
          Para receber o e-mail de troca de senha, insira o código enviado para{" "}
          <strong>{formatPhoneNumber(user.phone)}</strong>
        </Typography>
        <FormInputValidationNumber
          name="confirmationCode"
          control={control}
          label=""
          pattern={[1 - 9]}
          autoFocus
        />
        <Box display="flex" flexDirection="row" gap={2}>
          <Button
            disabled={IS_BUTTON_DISABLED}
            variant="text"
            sx={{ color: "neutral1000.main", textTransform: "none" }}
            onClick={() => {
              onResendButtonClick();
              setCounter(SEND_CODE_TIMER);
            }}
            id="recaptcha-button"
          >
            {IS_BUTTON_DISABLED
              ? `Você pode reenviar o código em ${counter}`
              : "Reenviar código"}
          </Button>
        </Box>
      </Stack>
      <Stack
        minWidth="100%"
        mt={3}
        direction="row"
        justifyContent="space-between"
        spacing={2}
      >
        <FormButtonGroup
          onGoBackButtonClick={() => onGoBackButtonClick()}
          onNextButtonClick={() => onSubmitButtonClick()}
          goBackButtonText="Voltar"
          nextButtonText="Salvar"
          loading={loading}
        />
      </Stack>
    </DialogContent>
  );
}
