import { Add, Description } from "@mui/icons-material";
import { IconButton, Stack, TableCell, Tooltip } from "@mui/material";
import { toast } from "react-toastify";

export function ActionsTableSolicitations({ row, setBinaryUrl, addItem }: any) {
  const auxItem = {
    type: "service",
    tabValue: "3",
    inputValue: {
      ...(row?.fullResource?.code?.concept?.coding?.[0]?.code && {
        code: row?.fullResource?.code?.concept?.coding?.[0]?.code,
        system:
          "https://fluxmed.com.br/fhir/r5/CodeSystem/TUSSProcedimentosEventos",
      }),
      display: row?.description,
      name: row?.description,
      value: row?.description,
      label: row?.description,
    },
    name: row?.description,
    tag: "EX",
    form: "",
    note: row?.fullResource?.note?.[0]?.text || "",
    condition: {
      code: row?.fullResource?.reason?.[0]?.concept?.coding?.[0]?.code,
      display: row?.fullResource?.reason?.[0]?.concept?.coding?.[0]?.display,
      label: row?.fullResource?.reason?.[0]?.concept?.coding?.[0]?.display,
      system: "https://fluxmed.com.br/fhir/r4/CodeSystem/CID10",
      value: row?.fullResource?.reason?.[0]?.concept?.coding?.[0]?.code,
    },
    category: {
      label:
        row?.fullResource?.category?.[0]?.coding?.[0]?.display === "Imaging"
          ? "Imagem"
          : row?.fullResource?.category?.[0]?.coding?.[0]?.display
          ? "Laboratorial"
          : "",
    },
  };

  return (
    <TableCell align="center">
      <Stack direction="row" justifyContent="right">
        {row.binaryUrl && (
          <>
            <Tooltip title="Adicionar item na prescrição">
              <IconButton
                onClick={() => {
                  addItem(auxItem);
                  toast.success("Item adicionado na prescrição");
                }}
              >
                <Add />
              </IconButton>
            </Tooltip>
            <Tooltip title="Visualizar pedido">
              <IconButton
                onClick={() => {
                  setBinaryUrl(row.binaryUrl);
                }}
              >
                <Description />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Stack>
    </TableCell>
  );
}
