import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Stack, Typography } from "@mui/material";

import shortLogo from "../../../assets/fluxmed-logo.png";
import { PrimaryButton } from "../../../components/common";
import { FormPasswordInput } from "../../../components/form";

interface IFormInput {
  password: string;
  repeatedPassword: string;
}

const defaultValues = {
  password: "",
  repeatedPassword: "",
};

export function PasswordData({ onClick }: any) {
  const methods = useForm<IFormInput>({ defaultValues });
  const { handleSubmit, control } = methods;
  const [loading, setLoading] = useState(false);

  const onSubmit = (data: IFormInput) => {
    setLoading(true);

    setTimeout(() => {
      onClick((prev: number) => prev + 1);
    }, 10000);
  };

  return (
    <FormProvider {...methods}>
      <Stack
        height="100%"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <img src={shortLogo} alt="Logo fluxmed" height={42} width={52} />
        <Typography
          variant="h5"
          marginBottom={4}
          marginTop={2}
          textAlign="center"
        >
          Crie uma nova senha
        </Typography>
        <FormPasswordInput
          name="password"
          control={control}
          label="Senha"
          required={true}
        />
        <Box marginBottom={2}></Box>
        <FormPasswordInput
          name="repeatedPassword"
          control={control}
          label="Confirmar senha"
          required={true}
        />
        <Box marginBottom={{ xs: 5, sm: 7 }}></Box>
        <PrimaryButton
          onClick={handleSubmit(onSubmit)}
          width="100%"
          height="52px"
          loading={loading}
        >
          Criar
        </PrimaryButton>
      </Stack>
    </FormProvider>
  );
}
