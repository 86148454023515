import { Stack } from "@mui/material";
import { LargeContentBox, PageTitle } from "../../components/common";
import { PrivateMainLayout } from "../../components/layout";
import { isMobile } from "react-device-detect";
import { Form } from "./components/form";
import { ScreenMobile } from "../../components/common/ScreenMobile";

export function MyfamilyForDash({ open, onClose }: any) {
  return (
    <>
      {isMobile ? (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Form />
        </Stack>
      ) : (
        <LargeContentBox>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Form />
          </Stack>
        </LargeContentBox>
      )}
    </>
  );
}
