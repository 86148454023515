import { DescriptionOutlined } from "@mui/icons-material";
import { useSessionContext } from "../../contexts/SessionContext";
import { IFeatures } from "../../utils/interfaces";
import { currentFeatures } from "../../configs/Settings";
import { useViewContext } from "../../contexts/ViewContext";
import { MedicalDocumentation } from "./MedicalDocumentation";

export function useMedicalDocumentation() {
  const { access } = useSessionContext();
  const { availableRoles } = useViewContext();

  const configs: IFeatures = {
    active:
      access.type === "professional" &&
      currentFeatures.profileClinic &&
      availableRoles?.includes("practitioner"),
    path: "/prescription",
    text: "Prescrição",
    icon: DescriptionOutlined,

    sideMenu: false,

    component: () => <MedicalDocumentation />,
  };

  return configs;
}
