import { Grid, Stack } from "@mui/material";
import { FormButtonGroup, FormTextInput } from "../../../../components/form";
import { useForm, useWatch } from "react-hook-form";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { PatchPatient } from "../../../../services/fhir/patient/PatchPatient";
import { toast } from "react-toastify";
import { useFormatter } from "../../../../utils/useFormatter";
import { GetAddressByCep } from "../../../../services/viaCep/GetCep";
import { useEffect, useRef, useState } from "react";

interface IProps {
  type: "add" | "edit";
  handleClose: VoidFunction;
}

export function BodyAddress({ type, handleClose }: IProps) {
  const { userPatient, fetchUser } = useSessionContext();
  const numberInputRef = useRef<HTMLInputElement>(null);
  const [numberFocus, setNumberFocus] = useState(false);

  const address = userPatient?.address?.[0];
  const auxData = userPatient?.address?.[0]?.line;
  const addressEqualPostalCode = async (data: any) => {
    const cep = data?.replace(/[^0-9]/g, "");

    if (cep?.length === 8) {
      const addressResponse = await GetAddressByCep(cep);

      if (addressResponse?.erro) {
        setError("cep", {
          type: "custom",
          message: "CEP Inválido ou não encontrado! digite um CEP válido",
        });
      } else {
        const city = addressResponse?.localidade;
        const postalCode = addressResponse?.cep;
        const state = addressResponse?.uf;
        const street = addressResponse?.logradouro;
        const zone = addressResponse?.bairro;
        const complement = addressResponse?.complemento;

        setValue("city", city);
        setValue("state", state);
        setValue("cep", postalCode);
        setValue("zone", zone);
        setValue("street", street);
        setValue("complement", complement);
        setNumberFocus(true);
      }
    } else {
      setError("cep", {
        type: "custom",
        message: "CEP Inválido! digite um CEP válido",
      });
    }
  };

  const { control, handleSubmit, setError, setValue, getValues } = useForm({
    defaultValues: {
      cep: address?.postalCode || "",
      city: address?.city || "",
      state: address?.state || "",
      zone: address?.district || "",
      street: auxData?.[0] || "",
      number: auxData?.[1] || "",
      complement: auxData?.[2] || "",
    },
  });

  const watchStreet = useWatch({ name: "street", control });
  const watchNumber = useWatch({ name: "number", control });
  const watchCEP = useWatch({ name: "cep", control });

  useEffect(() => {
    if (numberFocus && numberInputRef.current) {
      numberInputRef.current.focus();
    }
  }, [numberFocus]);
  const onSubmit = async (data: any) => {
    const regexCEP = /^\d{2}\.\d{3}-\d{3}$/;

    if (watchStreet !== "" && watchNumber === "") {
      setError("number", {
        type: "custom",
        message: "",
      });
      return;
    } else if (watchStreet === "" && watchNumber !== "") {
      setError("street", {
        type: "custom",
        message: "",
      });
      return;
    }

    const auxLine = [data.street, data.number, data.complement].filter(
      (el) => el !== ""
    );

    const auxContent = [
      {
        line: auxLine,
        city: data.city,
        district: data.zone,
        state: data.state,
        postalCode: data.cep,
      },
    ];

    const removeEmptyProperties = (obj: any) =>
      Object.fromEntries(
        Object.entries(obj).filter(([key, value]) => value !== "")
      );

    const filterContent = auxContent.map((obj: any) => {
      return removeEmptyProperties(obj);
    });

    const path = "/address";

    try {
      await PatchPatient({
        patientId: userPatient?.id,
        operation: type === "add" ? "add" : "replace",
        path,
        content: filterContent,
      });
      await fetchUser();
      toast.success(
        `Endereço ${type === "add" ? "Adicionado" : "Atualizado"} com sucesso!`
      );
      handleClose();
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <Grid container spacing={2} paddingTop={2}>
      <Grid item lg={6} sm={12}>
        <FormTextInput
          onBlur={() => {
            addressEqualPostalCode(getValues()?.cep);
          }}
          name="cep"
          label="CEP"
          control={control}
          variant="filled"
          mask={useFormatter.formatCEP}
        />
      </Grid>
      <Grid item lg={6} sm={12}>
        <FormTextInput
          name="city"
          label="Cidade"
          control={control}
          variant="filled"
        />
      </Grid>
      <Grid item lg={6} sm={12}>
        <FormTextInput
          name="state"
          label="Estado"
          control={control}
          variant="filled"
        />
      </Grid>
      <Grid item lg={6} sm={12}>
        <FormTextInput
          name="zone"
          label="Bairro"
          control={control}
          variant="filled"
        />
      </Grid>

      <Grid item lg={12} sm={12}>
        <FormTextInput
          name="street"
          label="Endereço"
          control={control}
          variant="filled"
        />
      </Grid>
      <Grid item lg={6} sm={12}>
        <FormTextInput
          inputRef={numberInputRef}
          name="number"
          label="Número"
          control={control}
          variant="filled"
        />
      </Grid>
      <Grid item lg={6} sm={12} mb={2}>
        <FormTextInput
          name="complement"
          label="Complemento"
          control={control}
          variant="filled"
        />
      </Grid>
      <Stack width="100%" ml={2}>
        <FormButtonGroup
          goBackButtonText="Cancelar"
          nextButtonText="Confirmar"
          onGoBackButtonClick={handleClose}
          onNextButtonClick={handleSubmit(onSubmit)}
        />
      </Stack>
    </Grid>
  );
}
