import { toast } from "react-toastify";
import { endpoints, oids } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";

interface IProps {
  patientId: string;
  appointmentId?: string;
  lastUpdaded?: boolean;
  count?: number;
}

export async function GetEncounter({
  appointmentId,
  lastUpdaded,
  count,
  patientId,
}: IProps) {
  let url = `${endpoints.MHD}/Encounter`;
  if (patientId)
    url = `${url}?subject:Patient.identifier=urn:oid:${oids.cpf}%7C${patientId}`;
  if (lastUpdaded) url = `${url}&_sort=-_lastUpdated`;
  if (appointmentId) url = `${url}&appointment=${appointmentId}`;
  if (count) url = `${url}&_count=${count}`;

  const subjectId = await useAuth.getSubjectId();
  const organizationId = await useAuth.getOrganizationId();

  const headers = {
    Accept: "application/fhir+json",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
    ...(subjectId && { "subject-id": subjectId }),
    ...(organizationId && { "organization-id": organizationId }),
  };
  const response = await useAxios
    .get(url, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`GetEncounter: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("GetEncounter ", error);
      // toast.error(`GetEncounter Error: ${error?.response?.status}`);
      // throw new Error();
    });
  if (response) return response;
}
