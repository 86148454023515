import {
  InputTextArea,
  InputText,
  InputDate,
  InputDateTime,
  InputNumber,
  InputSelect,
  InputCheckbox,
  InputDateMonth,
  InputDateYear,
  InputRadio,
} from "../Form";
import { Control } from "react-hook-form";
import { RenderGroupItem } from "./RenderGroupItem";
import dayjs from "dayjs";

export type renderInputFieldsItemProps = {
  item: any;
  text?: string;
  firstGroup?: boolean;
  index?: number;
  control: Control<any, any>;
  key?: any;
  questionnaire?: any;
  renderWithResponse?: boolean;
};

function removeDots(text: string) {
  return text.replace(/\./g, "");
}

function getItemQuestionnaireByLinkId(linkId: string, questionnaire: any): any {
  function findType(items: any): any {
    for (let item of items) {
      if (item?.linkId === linkId) {
        return item;
      }
      if (item?.item) {
        let result = findType(item?.item);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  return findType(questionnaire?.item || []);
}

export function RenderInputFieldsItem({
  item,
  questionnaire,
  text,
  index,
  control,
  key,
  firstGroup,
}: renderInputFieldsItemProps) {
  if (questionnaire) {
    const itemQuestionnaire = getItemQuestionnaireByLinkId(
      item?.linkId,
      questionnaire
    );
    const renderGroupBoxItems = ["Conduta", "Resultado"];

    if (itemQuestionnaire?.type === "group") {
      if (renderGroupBoxItems.includes(itemQuestionnaire?.text)) {
        return (
          <>
            {item.text &&
              RenderGroupItem({
                item,
                questionnaire,
                itemQuestionnaire,
                control,
                firstGroup,
                renderBoxGroup: true,
              })}
          </>
        );
      } else {
        return (
          <>
            {item.text &&
              RenderGroupItem({
                item,
                questionnaire,
                itemQuestionnaire,
                control,
                firstGroup,
              })}
          </>
        );
      }
    }
    if (
      itemQuestionnaire?.type === "coding" &&
      itemQuestionnaire?.extension?.[0]?.valueCodeableConcept?.coding[0]
        .code === "radio-button"
    ) {
      return (
        <>
          <InputRadio
            key={`${item.linkId}_${index}_${item.text}_${
              item.answer?.[0]?.valueString ||
              item.answer?.[0]?.valueCoding?.display ||
              item.answer?.[0]?.valueDate ||
              "-"
            }`}
            label={itemQuestionnaire?.text}
            name={`${item.linkId}_${index}_${removeDots(item.text)}_${
              item.answer?.[0]?.valueString ||
              item.answer?.[0]?.valueCoding?.display ||
              item.answer?.[0]?.valueDate ||
              "-"
            }`}
            control={control}
            answerOption={itemQuestionnaire?.answerOption}
            defaultValue={item?.answer?.[0]?.valueCoding?.id}
            value={item?.answer?.[0]?.valueCoding?.id}
            sx={{ mb: 2 }}
          />

          {item?.answer?.[0]?.item && (
            <>
              <RenderInputFieldsItem
                item={item?.answer?.[0]?.item?.[0]}
                questionnaire={questionnaire}
                index={index}
                control={control}
              />
            </>
          )}
        </>
      );
    }
    if (itemQuestionnaire?.type === "display") {
      return (
        <div
          key={`${item.linkId}_${index}_${item.text}_${
            item.answer?.[0]?.valueString ||
            item.answer?.[0]?.valueCoding?.display ||
            item.answer?.[0]?.valueDate ||
            "-"
          }`}
        >
          <p>{itemQuestionnaire?.text}</p>
        </div>
      );
    }
    if (itemQuestionnaire?.type === "text") {
      return (
        <>
          <InputTextArea
            key={`${item.linkId}_${index}_${item.text}_${
              item.answer?.[0]?.valueString ||
              item.answer?.[0]?.valueCoding?.display ||
              item.answer?.[0]?.valueDate ||
              ""
            }`}
            label={itemQuestionnaire?.text}
            name={`${item.linkId}_${index}_${removeDots(item.text)}_${
              item.answer?.[0]?.valueString ||
              item.answer?.[0]?.valueCoding?.display ||
              item.answer?.[0]?.valueDate ||
              ""
            }`}
            control={control}
            defaultValue={
              item.answer?.[0]?.valueString ||
              item.answer?.[0]?.valueCoding?.display ||
              item.answer?.[0]?.valueDate ||
              ""
            }
            sx={{ mb: 4 }}
          />
        </>
      );
    }
    if (itemQuestionnaire?.type === "string") {
      return (
        <>
          <InputText
            key={`${item.linkId}_${index}_${item.text}_${
              item.answer?.[0]?.valueString ||
              item.answer?.[0]?.valueCoding?.display ||
              item.answer?.[0]?.valueDate ||
              "-"
            }`}
            label={itemQuestionnaire?.text}
            name={`${item.linkId}_${index}_${removeDots(item.text)}_${
              item.answer?.[0]?.valueString ||
              item.answer?.[0]?.valueCoding?.display ||
              item.answer?.[0]?.valueDate ||
              "-"
            }`}
            control={control}
            defaultValue={
              item.answer?.[0]?.valueString ||
              item.answer?.[0]?.valueCoding?.display ||
              item.answer?.[0]?.valueDate ||
              ""
            }
            sx={{ mb: 4 }}
          ></InputText>
        </>
      );
    }
    if (itemQuestionnaire?.type === "quantity") {
      return (
        <>
          <InputNumber
            key={`${item.linkId}_${index}_${item.text}_${
              item.answer?.[0]?.valueString ||
              item.answer?.[0]?.valueCoding?.display ||
              item.answer?.[0]?.valueDate ||
              "-"
            }`}
            label={itemQuestionnaire?.text}
            name={`${item.linkId}_${index}_${item.text}_${
              item.answer?.[0]?.valueString ||
              item.answer?.[0]?.valueCoding?.display ||
              item.answer?.[0]?.valueDate ||
              "-"
            }`}
            control={control}
            defaultValue={
              item.answer?.[0]?.valueString ||
              item.answer?.[0]?.valueCoding?.display ||
              item.answer?.[0]?.valueDate ||
              ""
            }
            sx={{ mb: 4 }}
          />
        </>
      );
    }
    if (itemQuestionnaire?.type === "integer") {
      return (
        <>
          <InputNumber
            key={`${item.linkId}_${index}_${item.text}_${
              item.answer?.[0]?.valueString ||
              item.answer?.[0]?.valueCoding?.display ||
              item.answer?.[0]?.valueDate ||
              "-"
            }`}
            label={itemQuestionnaire?.text}
            name={`${item.linkId}_${index}_${item.text}_${
              item.answer?.[0]?.valueString ||
              item.answer?.[0]?.valueCoding?.display ||
              item.answer?.[0]?.valueDate ||
              "-"
            }`}
            control={control}
            defaultValue={
              item.answer?.[0]?.valueString ||
              item.answer?.[0]?.valueCoding?.display ||
              item.answer?.[0]?.valueDate ||
              ""
            }
            sx={{ mb: 4 }}
          />
        </>
      );
    }
    if (itemQuestionnaire?.type === "date" && !item.extension) {
      return (
        <>
          <InputDate
            key={`${item.linkId}_${index}_${item.text}_${
              item.answer?.[0]?.valueString ||
              item.answer?.[0]?.valueCoding?.display ||
              item.answer?.[0]?.valueDate ||
              "-"
            }`}
            label={itemQuestionnaire?.text}
            name={`${item.linkId}_${index}_${item.text}_${
              item.answer?.[0]?.valueString ||
              item.answer?.[0]?.valueCoding?.display ||
              item.answer?.[0]?.valueDate ||
              "-"
            }`}
            defaultValue={
              item.answer?.[0]?.valueDate
                ? dayjs(item.answer?.[0]?.valueDate)
                : null
            }
            control={control}
          />
        </>
      );
    }
    if (
      itemQuestionnaire?.type === "dateTime" &&
      !itemQuestionnaire?.extension
    ) {
      return (
        <>
          <InputDateTime
            key={`${item.linkId}_${index}_${item.text}_${
              item.answer?.[0]?.valueString ||
              item.answer?.[0]?.valueCoding?.display ||
              item.answer?.[0]?.valueDate ||
              "-"
            }`}
            label={itemQuestionnaire?.text}
            name={`${item.linkId}_${index}_${item.text}_${
              item.answer?.[0]?.valueString ||
              item.answer?.[0]?.valueCoding?.display ||
              item.answer?.[0]?.valueDate ||
              "-"
            }`}
            control={control}
          />
        </>
      );
    }
    if (
      itemQuestionnaire?.type === "date" &&
      itemQuestionnaire?.extension[0]?.valueCodeableConcept?.coding[0].code ===
        "yearMonth"
    ) {
      return (
        <>
          <InputDateMonth
            key={`${item.linkId}_${index}_${item.text}_${
              item.answer?.[0]?.valueString ||
              item.answer?.[0]?.valueCoding?.display ||
              item.answer?.[0]?.valueDate ||
              "-"
            }`}
            label={itemQuestionnaire?.text}
            name={`${item.linkId}_${index}_${item.text}_${
              item.answer?.[0]?.valueString ||
              item.answer?.[0]?.valueCoding?.display ||
              item.answer?.[0]?.valueDate ||
              "-"
            }`}
            control={control}
          />
        </>
      );
    }
    if (
      itemQuestionnaire?.type === "date" &&
      itemQuestionnaire?.extension[0]?.valueCodeableConcept?.coding[0].code ===
        "year"
    ) {
      return (
        <>
          <InputDateYear
            key={`${item.linkId}_${index}_${item.text}_${
              item.answer?.[0]?.valueString ||
              item.answer?.[0]?.valueCoding?.display ||
              item.answer?.[0]?.valueDate ||
              "-"
            }`}
            label={itemQuestionnaire?.text}
            name={`${item.linkId}_${index}_${item.text}_${
              item.answer?.[0]?.valueString ||
              item.answer?.[0]?.valueCoding?.display ||
              item.answer?.[0]?.valueDate ||
              "-"
            }`}
            control={control}
          />
        </>
      );
    }
    if (itemQuestionnaire?.type === "boolean") {
      return (
        <>
          <InputCheckbox
            key={`${item.linkId}_${index}_${item.text}_${
              item.answer?.[0]?.valueString ||
              item.answer?.[0]?.valueCoding?.display ||
              item.answer?.[0]?.valueDate ||
              "-"
            }`}
            label={itemQuestionnaire?.text}
            name={`${item.linkId}_${index}_${item.text}_${
              item.answer?.[0]?.valueString ||
              item.answer?.[0]?.valueCoding?.display ||
              item.answer?.[0]?.valueDate ||
              "-"
            }`}
            control={control}
          />
        </>
      );
    } else {
      return <>Item renderizado sem type</>;
    }
  } else {
    if (item.type === "group") {
      return (
        <>
          {item.text ? (
            RenderGroupItem({ item, index, key, control, firstGroup })
          ) : (
            <>
              {item.item &&
                item?.item?.map((subItem: any, subItemIndex: number) =>
                  RenderInputFieldsItem({
                    item: subItem,
                    index: subItemIndex,
                    key,
                    control,
                  })
                )}
            </>
          )}
        </>
      );
    }
    if (
      item.type === "coding" &&
      item?.extension[0]?.valueCodeableConcept?.coding[0].code ===
        "radio-button"
    ) {
      return (
        <>
          <InputRadio
            key={`${item.linkId}_${index ?? "index"}_${key || "key"}`}
            label={item.text}
            name={`${item.linkId}_${index ?? "index"}_${key || "key"}`}
            control={control}
            defaultValue={"104e245e-a899-4df1-8a6b-8872cc26ab18"}
            answerOption={item?.answerOption}
            sx={{ mb: 4 }}
          />
          {item.item &&
            item?.item?.map((subItem: any, subItemIndex: number) =>
              RenderInputFieldsItem({
                item: subItem,
                index: subItemIndex,
                control,
              })
            )}
        </>
      );
    }
    if (
      item.type === "coding" &&
      item?.extension[0]?.valueCodeableConcept?.coding[0].code === "drop-down"
    ) {
      return (
        <>
          <InputSelect
            key={`${item.linkId}_${index ?? "index"}_${key || "key"}`}
            name={`${item.linkId}_${index ?? "index"}_${key || "key"}`}
            control={control}
            text={item.text}
            label={item.text}
            answerOption={item?.answerOption}
          />
          {item.item &&
            item?.item?.map((subItem: any, subItemIndex: number) =>
              RenderInputFieldsItem({
                item: subItem,
                index: subItemIndex,
                control,
              })
            )}
        </>
      );
    }
    if (
      item.type === "coding" &&
      item?.extension[0]?.valueCodeableConcept?.coding[0].code === "check-box"
    ) {
      return (
        <>
          <InputCheckbox
            key={`${item.linkId}_${index ?? "index"}_${key || "key"}`}
            label={item.text}
            name={`${item.linkId}_${index ?? "index"}_${key || "key"}`}
            control={control}
          />
          {item.item &&
            item?.item?.map((subItem: any, subItemIndex: number) =>
              RenderInputFieldsItem({
                item: subItem,
                index: subItemIndex,
                control,
              })
            )}
        </>
      );
    }
    if (item.type === "display") {
      return (
        <div key={item.linkId}>
          <p>{item.text}</p>
          {item.item &&
            item?.item?.map((subItem: any, subItemIndex: number) =>
              RenderInputFieldsItem({
                item: subItem,
                index: subItemIndex,
                control,
              })
            )}
        </div>
      );
    }
    if (item.type === "text") {
      return (
        <>
          <InputTextArea
            key={`${item.linkId}_${index ?? "index"}_${key || "key"}`}
            label={item.text}
            name={`${item.linkId}_${index ?? "index"}_${key || "key"}`}
            defaultValue={""}
            control={control}
            sx={{ mb: 4 }}
          >
            {item.text}
          </InputTextArea>
          {item.item &&
            item?.item?.map((subItem: any, subItemIndex: number) =>
              RenderInputFieldsItem({
                item: subItem,
                text: subItem.text,
                index: subItemIndex,
                control,
              })
            )}
        </>
      );
    }
    if (item.type === "string") {
      return (
        <>
          <InputText
            key={`${item.linkId}_${index ?? "index"}_${key || "key"}`}
            label={item.text}
            name={`${item.linkId}_${index ?? "index"}_${key || "key"}`}
            defaultValue={""}
            control={control}
            sx={{ mb: 4 }}
          ></InputText>
          {item.item &&
            item?.item?.map((subItem: any, subItemIndex: number) =>
              RenderInputFieldsItem({
                item: subItem,
                text: subItem.text,
                index: subItemIndex,
                control,
              })
            )}
        </>
      );
    }
    if (item.type === "quantity") {
      return (
        <>
          <InputNumber
            key={`${item.linkId}_${index ?? "index"}_${key || "key"}`}
            label={item.text}
            name={`${item.linkId}_${index ?? "index"}_${key || "key"}`}
            control={control}
            sx={{ mb: 4 }}
          />
          {item.item &&
            item?.item?.map((subItem: any, subItemIndex: number) =>
              RenderInputFieldsItem({
                item: subItem,
                index: subItemIndex,
                control,
              })
            )}
        </>
      );
    }
    if (item.type === "integer") {
      return (
        <>
          <InputNumber
            key={`${item.linkId}_${index ?? "index"}_${key || "key"}`}
            label={item.text}
            name={`${item.linkId}_${index ?? "index"}_${key || "key"}`}
            control={control}
            sx={{ mb: 4 }}
          />
          {item.item &&
            item?.item?.map((subItem: any, subItemIndex: number) =>
              RenderInputFieldsItem({
                item: subItem,
                index: subItemIndex,
                control,
              })
            )}
        </>
      );
    }
    if (item.type === "date" && !item.extension) {
      return (
        <>
          <InputDate
            key={`${item.linkId}_${index ?? "index"}_${key || "key"}`}
            label={item.text}
            name={`${item.linkId}_${index ?? "index"}_${key || "key"}`}
            control={control}
          />
          {item.item &&
            item?.item?.map((subItem: any, subItemIndex: number) =>
              RenderInputFieldsItem({
                item: subItem,
                index: subItemIndex,
                control,
              })
            )}
        </>
      );
    }
    if (item.type === "dateTime" && !item.extension) {
      return (
        <>
          <InputDateTime
            key={`${item.linkId}_${index ?? "index"}_${key || "key"}`}
            label={item.text}
            name={`${item.linkId}_${index ?? "index"}_${key || "key"}`}
            control={control}
          />
          {item.item &&
            item?.item?.map((subItem: any, subItemIndex: number) =>
              RenderInputFieldsItem({
                item: subItem,
                index: subItemIndex,
                control,
              })
            )}
        </>
      );
    }
    if (
      item.type === "date" &&
      item?.extension[0]?.valueCodeableConcept?.coding[0].code === "yearMonth"
    ) {
      return (
        <>
          <InputDateMonth
            key={`${item.linkId}_${index ?? "index"}_${key || "key"}`}
            label={item.text}
            name={`${item.linkId}_${index ?? "index"}_${key || "key"}`}
            control={control}
          />
          {item.item &&
            item?.item?.map((subItem: any, subItemIndex: number) =>
              RenderInputFieldsItem({
                item: subItem,
                index: subItemIndex,
                control,
              })
            )}
        </>
      );
    }
    if (
      item.type === "date" &&
      item?.extension[0]?.valueCodeableConcept?.coding[0].code === "year"
    ) {
      return (
        <>
          <InputDateYear
            key={`${item.linkId}_${index ?? "index"}_${key || "key"}`}
            label={item.text}
            name={`${item.linkId}_${index ?? "index"}_${key || "key"}`}
            control={control}
          />
          {item.item &&
            item?.item?.map((subItem: any, subItemIndex: number) =>
              RenderInputFieldsItem({
                item: subItem,
                index: subItemIndex,
                control,
              })
            )}
        </>
      );
    }
    if (item.type === "boolean") {
      return (
        <>
          <InputCheckbox
            key={`${item.linkId}_${index ?? "index"}_${key || "key"}`}
            label={item.text}
            name={`${item.linkId}_${index ?? "index"}_${key || "key"}`}
            control={control}
          />
          {item.item &&
            item?.item?.map((subItem: any, subItemIndex: number) =>
              RenderInputFieldsItem({
                item: subItem,
                index: subItemIndex,
                control,
              })
            )}
        </>
      );
    }
    console.log("else", item);

    return null;
  }
}
