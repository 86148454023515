function getUser(data: any) {
  try {
    const givenName = data?.[0]?.name?.givenName || "";
    const familyName = data?.[0]?.name?.familyName || "";
    const name = `${givenName} ${familyName}`;
    const username = data?.[0]?.userName || "";
    const id = data?.[0]?.id || "";
    const phone =
      data?.[0]?.phoneNumbers?.[0]?.value || data?.[0]?.phoneNumbers?.[0] || "";
    const birthDate =
      data?.[0]?.["urn:ietf:params:scim:schemas:extension:enterprise:2.0:User"]
        ?.dateOfBirth;
    const email = data?.[0]?.emails || "";
    const tokenFirebaseWeb =
      data?.[0]?.["urn:ietf:params:scim:schemas:extension:enterprise:2.0:User"]
        ?.deviceIDWeb;
    const tokenFirebaseMobile =
      data?.[0]?.["urn:ietf:params:scim:schemas:extension:enterprise:2.0:User"]
        ?.deviceIDMobile;
    const notificationAgree =
      data?.[0]?.["urn:ietf:params:scim:schemas:extension:enterprise:2.0:User"]
        ?.notificationAgree;

    localStorage.setItem("username", username);

    return {
      name,
      givenName,
      username,
      phone,
      birthDate,
      email,
      id,
      tokenFirebaseWeb,
      tokenFirebaseMobile,
      notificationAgree,
    };
  } catch (err) {
    console.log("getUser error:", err);
    return {};
  }
}

export const useScimData = {
  getUser,
};
