import { useLocation } from "react-router-dom";
import { PrivateMainLayout } from "../../components/layout";
import { useEffect, useState } from "react";
import {
  LargeContentBox,
  NoDataWarning,
  CircularLoading,
} from "../../components/common";
import { Stack, Typography } from "@mui/material";
import { ScheduleFilter } from "./components/ScheduleFilter";
import { AppointmentAccordion } from "./components/AppointmentAccordion";
import { ModalSchedule } from "../home/components/ModalSchedule";
import { GetAppointment, GetSchedule } from "../../services/fhir";
import { useSessionContext } from "../../contexts/SessionContext";
import { oids } from "../../configs/Settings";
import { useFhirData } from "../../utils/useFhirData";
import dayjs from "dayjs";

interface State {
  [key: string]: boolean;
}

export function ScheduleManage() {
  const { access, user } = useSessionContext();
  const { filterIsScheduleBundleEntry, filterIsSlotBundleEntry } = useFhirData;

  const [schedule, setSchedule] = useState<fhir5.BundleEntry<fhir5.Schedule>[]>(
    []
  );
  const [refetch, setRefetch] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const { state } = useLocation();

  const [activeSchedule, setActiveSchedules] = useState<[]>([]);

  // Estados de filtro
  const [scheduleFilter, setScheduleFilter] = useState<State>();

  const [practitionerFilter, setPractitionerFilter] = useState<State>(
    state && { [state?.filter]: true }
  );
  const [healthcareFilter, setHealthcareFilter] = useState<State>();
  const [locationFilter, setLocationFilter] = useState<State>();
  const [searchFiled, setSearchField] = useState();

  // Vericação de aplicação de cada filtro
  const isScheduleFilterApply =
    scheduleFilter && Object.values(scheduleFilter).includes(true);

  const isPractitionerFilterApply =
    practitionerFilter && Object.values(practitionerFilter).includes(true);

  const isHealthcareFilterApply =
    healthcareFilter && Object.values(healthcareFilter).includes(true);

  const isLocationFilterApply =
    locationFilter && Object.values(locationFilter).includes(true);

  // Filtragem de Schedules

  const displaySchedules = schedule?.filter((el: any) => {
    const practitioners = el?.resource.actor?.filter((actor: any) =>
      actor.reference.includes("Practitioner/")
    );
    const practitionerReferences = practitioners?.map(
      (actor: any) => actor.reference
    );

    const practitionerSearch = practitioners?.find(
      (element: any) =>
        element.display?.toLowerCase().includes(`${searchFiled}`) ||
        element.reference?.toLowerCase().includes(`${searchFiled}`)
    );

    const healthcareDisplay = el?.resource.actor?.find((actor: any) =>
      actor.reference.includes("HealthcareService")
    )?.display;
    const locationReference = el?.resource.actor
      ?.find((actor: any) => actor.reference.includes("Location"))
      ?.reference?.split("/")[1];

    const search = searchFiled
      ? el?.resource.id.includes(`${searchFiled}`) ||
        healthcareDisplay.toLowerCase().includes(`${searchFiled}`) ||
        el?.resource.actor
          ?.find((actor: any) => actor.reference.includes("Location"))
          ?.display?.toLowerCase()
          .includes(`${searchFiled}`) ||
        practitionerSearch
      : true;
    return (
      (scheduleFilter?.[el?.resource?.id] || !isScheduleFilterApply) &&
      (practitionerReferences.some(
        (reference: any) => practitionerFilter?.[reference]
      ) ||
        !isPractitionerFilterApply) &&
      (healthcareFilter?.[healthcareDisplay] || !isHealthcareFilterApply) &&
      (locationFilter?.[locationReference] || !isLocationFilterApply) &&
      search
    );
  });

  const [open, setOpen] = useState(schedule?.length ? false : true);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    async function fetchSchedules() {
      setLoading(true);
      const auxActor =
        !access?.roles?.includes("manager") &&
        `Practitioner/${oids.cpf}-${user.username}`;

      const bundleEntry: fhir5.BundleEntry<fhir5.Schedule>[] = [];
      Promise.all([
        GetSchedule({
          active: true,
          organization: `${oids.cnpj}-${access.organizationId}`,
          ...(auxActor && { actor: auxActor }),
        }).then((response: fhir5.Bundle<fhir5.Schedule>) => {
          if (response?.entry) {
            bundleEntry.push(...response.entry);
          }
        }),
        GetAppointment({
          status: ["booked"],
          scheduleStatus: "false",
          organization: access.organizationId,
          includeSlot: true,
          includeSchedule: true,
          ...(auxActor && { actor: auxActor }),
        }).then((response: fhir5.Bundle<fhir5.Resource>) => {
          if (response?.entry) {
            bundleEntry.push(
              ...response.entry
                .filter(filterIsScheduleBundleEntry)
                .filter((el: fhir5.BundleEntry<fhir5.Schedule>) => {
                  const slots = response.entry
                    ?.filter(filterIsSlotBundleEntry)
                    .filter(
                      (elSlot) =>
                        elSlot.resource?.schedule?.reference ===
                        `Schedule/${el.resource?.id}`
                    );
                  let startDate = dayjs().hour(0);
                  slots?.forEach((el) => {
                    if (
                      el.resource?.end &&
                      dayjs(el.resource.end).isAfter(startDate)
                    )
                      startDate = dayjs(el.resource.end);
                  });
                  return startDate.isAfter(dayjs(), "minute");
                })
            );
          }
        }),
      ]).then(() => {
        setSchedule(bundleEntry);
        setLoading(false);
      });
    }
    fetchSchedules();
  }, [refetch, access?.roles, user.username]);

  return (
    <PrivateMainLayout>
      <LargeContentBox>
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Typography
            sx={{ color: "neutral1000.main" }}
            variant="h5"
            fontWeight={600}
            textAlign="center"
          >
            Gerenciamento de agendas da clínica
          </Typography>

          <ScheduleFilter
            active={activeSchedule}
            schedule={schedule}
            scheduleFilter={scheduleFilter}
            setScheduleFilter={setScheduleFilter}
            practitionerFilter={practitionerFilter}
            setPractitionerFilter={setPractitionerFilter}
            healthcareFilter={healthcareFilter}
            setHealthcareFilter={setHealthcareFilter}
            locationFilter={locationFilter}
            setLocationFilter={setLocationFilter}
            setSearchField={setSearchField}
          />

          {loading ? (
            <CircularLoading />
          ) : (
            <>
              {displaySchedules?.length ? (
                <>
                  {displaySchedules?.map((element: any, index: number) => (
                    <AppointmentAccordion
                      key={index}
                      defaultOpen={index === 0}
                      setRefetch={setRefetch}
                      schedule={element}
                      activeSchedule={activeSchedule}
                      setActiveSchedules={setActiveSchedules}
                      id={element?.resource?.id}
                    />
                  ))}
                </>
              ) : (
                <>
                  {!loading && (
                    <>
                      {!displaySchedules?.length && <NoDataWarning />}

                      {!schedule?.length && (
                        <ModalSchedule open={open} handleClose={handleClose} />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Stack>
      </LargeContentBox>
    </PrivateMainLayout>
  );
}
