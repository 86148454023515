import { toast } from "react-toastify";
import { endpoints, oids } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";
import { auxiliarData } from "../AuxiliarData";
import { Person } from "fhir/r5";
import { OAuthApplication } from "../../auth";

export async function PutPerson(objData: any, applicationAuth?: any) {
  const cpf = objData.identifier;
  const url = `${endpoints.ADMFHIR}/Person/${oids.cpf}-${cpf}`;

  const data = auxiliarData.PersonAux(objData);
  const headers = {
    Accept: "*/*",
    Authorization: applicationAuth
      ? await OAuthApplication()
      : await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
  };
  const response = await useAxios
    .put(url, data, { headers, timeout: 8000 })
    .then((response) => {
      if (
        response.status !== HTTPResponseStatus.Created &&
        response.status !== HTTPResponseStatus.Success
      ) {
        throw new Error(`PutPerson: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("PutPerson ", error);
      // toast.error(`PutPerson Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
