import { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormInputProps } from "../types";

interface IProps extends FormInputProps {
  variant?: "filled" | "outlined";
}

export function FormPasswordInput({
  name,
  control,
  label,
  required,
  pattern,
  autoFocus,
  shrink,
  variant,
}: IProps) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required,
        pattern,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          variant={variant === "outlined" ? "outlined" : "filled"}
          sx={{
            "& .MuiFilledInput-root": { backgroundColor: "neutral100.main" },
            "& .MuiOutlinedInput-root": { backgroundColor: "neutral0.main" },
          }}
          type={!showPassword ? "password" : "text"}
          autoFocus={autoFocus}
          InputLabelProps={{
            shrink,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  sx={{ color: "primary700.main" }}
                >
                  {!showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          helperText={error ? error.message : null}
          size="medium"
          error={error !== undefined}
          onChange={onChange}
          value={value}
          label={label}
          fullWidth
        />
      )}
    />
  );
}
