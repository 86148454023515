import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { InputText, InputTextArea } from "../Form";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

export function RenderCondutas({ control, unregister }: any) {
  const [render, setRender] = useState<boolean>(true);
  const [value, setValue] = useState("");

  const [lista, setLista] = useState<any>([]);

  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    setRender(true);
  }, [render, index]);

  return (
    <Stack sx={{ marginBottom: 2 }}>
      <Accordion
        sx={{
          border: "1px solid #ccc",
          boxShadow: "2px 2px 16px 2px rgba(0, 0, 0, 0.2)",
          position: "initial",
        }}
      >
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant={"h6"}>Condutas</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Stack spacing={1}>
            {lista?.map((el: any, index: number) => (
              <Stack direction="row" key={index}>
                <Chip
                  label={el}
                  onDelete={() => {
                    setLista(lista?.filter((e: any, i: number) => index !== i));
                    unregister(`3cccab72-a2ed-46e9-ebce-a1c6532e4bca_${index}`);
                    unregister(`2e202c22-d5bc-4243-870e-d342cb9c1b8d_${index}`);
                    unregister(`3013e032-4b24-46fa-879a-29e775153a72_${index}`);
                    setRender(false);
                  }}
                />
              </Stack>
            ))}

            <Stack direction="row" spacing={1}>
              {render && (
                <>
                  <InputText
                    handleChange={(e: any) => {
                      setValue(e.target.value);
                    }}
                    key={"condutas"}
                    label={"Conduta"}
                    name={`3cccab72-a2ed-46e9-ebce-a1c6532e4bca_${index}`}
                    control={control}
                    sx={{ mb: 4 }}
                  />

                  <div style={{ display: "none" }}>
                    <Controller
                      name={`2e202c22-d5bc-4243-870e-d342cb9c1b8d_${index}`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          onChange={(event) => {
                            onChange(event.target.value);
                          }}
                          value={value}
                        >
                          <Stack justifyContent="center" direction="row">
                            <FormControlLabel
                              value="71e7e351-3af3-44c1-f749-c26ffd4d2cc0"
                              control={<Radio />}
                              label="Realizada"
                            />
                            <FormControlLabel
                              value="69c43257-9e56-4a65-9aa2-e207fcd52885"
                              control={<Radio />}
                              label="Parcialmente"
                            />
                            <FormControlLabel
                              value="908d3dd9-8690-4da8-a1ea-e2d7892618a1"
                              control={<Radio />}
                              label="Não realizada"
                            />
                          </Stack>
                        </RadioGroup>
                      )}
                    />

                    <InputTextArea
                      key={`3013e032-4b24-46fa-879a-29e775153a72_${index}`}
                      label={"Nota"}
                      name={`3013e032-4b24-46fa-879a-29e775153a72_${index}`}
                      control={control}
                      sx={{ mb: 4 }}
                    />
                  </div>
                  <Button
                    sx={{ width: "250px" }}
                    onClick={() => {
                      setLista([...lista, value]);
                      setIndex((prev) => prev + 1);
                      setRender(false);
                    }}
                    variant="outlined"
                  >
                    Nova conduta
                  </Button>
                </>
              )}
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}
