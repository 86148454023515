import { Box, Paper, Stack, Typography, styled } from "@mui/material";
import { PrimaryButton } from "../../../components/common";
import { Check } from "@mui/icons-material";
import { SubscriptionService } from "../../../services/subscription/SubscriptionService";
import { useSessionContext } from "../../../contexts/SessionContext";

const subscriptionService = new SubscriptionService();

const CustomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.neutral0.main,
  width: "100%",
  alignItems: "center",
  maxWidth: "100%",
  height: "fit-content",
  borderRadius: "30px",
  [theme.breakpoints.down("md")]: {
    padding: "0%",
  },
  [theme.breakpoints.up("xs")]: {
    height: "100%",
  },
}));

interface IProps {
  selectedPlan: any;
  setPaymentLink: (link: string) => void;
  handleNext: () => void;
}

export function SubscriptionCard({
  selectedPlan,
  setPaymentLink,
  handleNext,
}: IProps) {
  const { user } = useSessionContext();

  const organizationValue = localStorage.getItem("organizationValue");

  const handleSubscription = async () => {
    const subscriptionData = {
      email: user.email[0],
      selectedPlan: selectedPlan,
      organizationId: `${organizationValue}`,
    };

    try {
      const paymentLink = await subscriptionService.createSubscription(
        subscriptionData
      );

      if (paymentLink) {
        setPaymentLink(paymentLink);
        handleNext();
      } else {
        console.log("Não foi possível obter o link de pagamento.");
      }
    } catch (error) {
      console.error("Erro ao criar a assinatura:", error);
    }
    localStorage.removeItem("operation");
    localStorage.removeItem("organizationValue");
  };

  return (
    <CustomBox>
      <Paper
        elevation={3}
        sx={{
          padding: "24px",
          borderRadius: "10px",
          minHeight: "420px",
        }}
      >
        <Stack spacing={4}>
          <Stack alignItems="center" spacing={2}>
            <Stack>
              <Typography variant="h6" fontWeight={600}>
                {selectedPlan?.reason || ""}
              </Typography>
            </Stack>
            <Typography
              variant="body1"
              color="neutral700.main"
              width="300px"
              textAlign="center"
            >
              Assine agora por apenas
            </Typography>
            <Stack direction="row">
              <Typography variant="h5" color="primary.main" fontWeight="600">
                R$
              </Typography>
              <Typography variant="h2" color="neutral700.main" fontWeight={600}>
                {selectedPlan?.auto_recurring?.transaction_amount
                  .toFixed(2)
                  .replace(".", ",") || ""}
              </Typography>
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <PrimaryButton
              width="100%"
              height="40px"
              onClick={handleSubscription}
            >
              Escolher
            </PrimaryButton>
            <Stack alignItems="flex-start">
              <Stack direction="row" spacing={1} alignItems="center">
                <Check sx={{ color: "primary.main", fontSize: 32 }} />
                <Typography>
                  Acesso completo a todas as funcionalidades.
                </Typography>
              </Stack>
              {selectedPlan?.auto_recurring?.free_trial?.frequency && (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Check sx={{ color: "primary.main", fontSize: 32 }} />
                  <Typography>
                    {selectedPlan?.auto_recurring?.free_trial?.frequency === 1
                      ? `${selectedPlan?.auto_recurring?.free_trial?.frequency} dia grátis para experimentar`
                      : `${selectedPlan?.auto_recurring?.free_trial?.frequency} dias grátis para experimentar `}
                  </Typography>
                </Stack>
              )}
              <Stack direction="row" spacing={1} alignItems="center">
                <Check sx={{ color: "primary.main", fontSize: 32 }} />
                <Typography>
                  Cadastre até{" "}
                  {selectedPlan?.external_reference > 1
                    ? `${selectedPlan?.external_reference} profissionais`
                    : `${selectedPlan?.external_reference} profissional`}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </CustomBox>
  );
}
