import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  Stack,
  Typography,
} from "@mui/material";

export function CondutasAccordion({ data, style }: any) {
  return (
    <>
      {data?.filterItens?.map((el: any) => {
        return (
          <Accordion
            defaultExpanded={true}
            expanded={true}
            sx={{
              boxShadow: "none",
              border: "1px solid #ccc",
              borderColor:
                style[el?.item?.[1]?.answer?.[0]?.valueCoding?.display],
              borderRadius: 1,
            }}
          >
            <AccordionSummary aria-controls="panel1-content" id="panel1-header">
              <Typography color={"neutral700.main"} fontWeight={500}>
                {el?.item?.[0]?.answer?.[0]?.valueString}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack direction="row" justifyContent={"space-between"}>
                <Chip
                  label={el?.item?.[1]?.answer?.[0]?.valueCoding?.display}
                  size="small"
                  variant="outlined"
                  sx={{
                    color:
                      style[el?.item?.[1]?.answer?.[0]?.valueCoding?.display],
                    borderColor:
                      style[el?.item?.[1]?.answer?.[0]?.valueCoding?.display],
                    borderRadius: 2,
                    marginBottom: 1,
                  }}
                />
              </Stack>

              {el?.item?.[2]?.answer?.[0]?.valueString ? (
                <Typography
                  style={{
                    backgroundColor: "#eeeeee",
                    color: "#757575",
                    padding: 6,
                    borderRadius: 8,
                    wordBreak: "break-word",
                  }}
                >
                  {el?.item?.[2]?.answer?.[0]?.valueString}
                </Typography>
              ) : (
                <></>
              )}

              <Divider sx={{ my: 2 }}></Divider>
              <Stack
                mt={2}
                direction="row"
                width="100%"
                alignItems="center"
                spacing={2}
              >
                {data?.displayInfo?.practitioner && (
                  <Typography
                    color="neutral700.main"
                    sx={{ fontWeight: 500, fontStyle: "italic", fontSize: 12 }}
                  >
                    Dr. {data?.displayInfo?.practitioner}
                  </Typography>
                )}

                {data?.displayInfo?.healthcare && (
                  <Chip
                    label={data?.displayInfo?.healthcare || "Sem especialidade"}
                    size="small"
                    sx={{
                      color: data?.displayInfo?.healthcare
                        ? "neutral700.main"
                        : "neutral500.main",

                      fontWeight: 500,
                      backgroundColor: data?.displayInfo?.healthcare
                        ? ""
                        : "#f1f1f1",
                    }}
                  />
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
}
