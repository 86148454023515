import { toast } from "react-toastify";
import { endpoints, oids } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";
import { OAuthApplication } from "../../auth";

interface formatFields {
  patient: number;
  docId: string;
  subjectId: string | undefined;
  organizationId?: string | undefined;
  isLogged?: boolean | undefined;
}

export async function GetDocumentReferenceById({
  patient,
  docId,
  isLogged,
  subjectId,
  organizationId,
}: formatFields) {
  let url = isLogged
    ? `${endpoints.MHD}/DocumentReference?subject:Patient.identifier=urn:oid:${oids.cpf}%7C${patient}&identifier=urn:ietf:rfc:3986%7Curn:oid:${docId}`
    : `${endpoints.MHD}/1.0.1/DocumentReference?subject:Patient.identifier=urn:oid:${oids.cpf}%7C${patient}&identifier=urn:ietf:rfc:3986%7Curn:oid:${docId}`;

  const headers = {
    Accept: "application/fhir+json",
    Authorization: isLogged
      ? await useAuth.getUserToken()
      : await OAuthApplication(),
    "Content-Type": "application/fhir+json",
    ...(subjectId && { "subject-id": subjectId }),
    ...(organizationId && { "organization-id": organizationId }),
    purposeofuse: "PATRQT",
  };
  const response = await useAxios
    .get(url, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`Get DocumentReference: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("GetDocumentReference ", error);
      // toast.error(`GetDocumentReference Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
