import { Stack } from "@mui/system";
import { FormTextInput } from "../../../form";
import { IconButton } from "@mui/material";
import { ReplayRounded, Search } from "@mui/icons-material";
import { GetPatient } from "../../../../services/fhir";
import { toast } from "react-toastify";
import { useRegex } from "../../../../utils/useRegex";
import { useFormatter } from "../../../../utils/useFormatter";
import { useState } from "react";

export function SimpleSearch({
  control,
  handleSubmit,
  setResult,
  setLoading,
  reset,
}: any) {
  const [submit, setSubmit] = useState<boolean>(false);

  const onSubmit = async (data: any) => {
    setSubmit(true);
    setLoading(true);
    try {
      const mpiPatient = await GetPatient({
        identifier: data?.identifier?.replace(/[^a-zA-Z0-9]/g, ""),
      })?.then((response) => response?.data);

      if (mpiPatient) setResult([mpiPatient]);
      else {
        setResult(undefined);
        toast.warn(
          "Nenhum resultado encontrado, verifique os dados e tente novamente"
        );
      }
    } catch (err) {
      console.log("SimpleSearch", err);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  return (
    <form onKeyDown={handleKeyPress}>
      <Stack
        mt={1}
        alignItems="center"
        direction="row"
        spacing={2}
        width="100%"
      >
        <FormTextInput
          label="CPF do paciente"
          name="identifier"
          control={control}
          pattern={useRegex.cpf}
          mask={useFormatter.formatCPF}
          maxLength={14}
          required
          autoFocus
        />
        <IconButton
          onClick={handleSubmit(onSubmit)}
          sx={{
            backgroundColor: "primary700.main",
            color: "white",
            borderRadius: 2,
            height: 52,
            width: 52,
            boxShadow: "1",
            ":hover": {
              backgroundColor: "primary800.main",
            },
          }}
        >
          <Search />
        </IconButton>

        {submit && (
          <IconButton
            sx={{ width: "fit-content" }}
            aria-label="delete"
            onClick={() => {
              reset();
              setResult(undefined);
              setSubmit(false);
            }}
          >
            <ReplayRounded sx={{ color: "colors.neutral700" }} />
          </IconButton>
        )}
      </Stack>
    </form>
  );
}
