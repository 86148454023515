import { useState } from "react";

import { Step, StepLabel, Stepper } from "@mui/material";

import { Steps } from "../../types";

import { Location } from "../Location";
import { HealthCare } from "../HealthCare";
import { ScheduleSettings } from "../ScheduleSettings";
import { ConfirmationScreen } from "../ConfirmationScreen";
import { useSessionContext } from "../../../../contexts/SessionContext";
import {
  PostHealthcareService,
  PostLocation,
  PostSchedule,
} from "../../../../services/fhir";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { oids } from "../../../../configs/Settings";
import { useFormatter } from "../../../../utils/useFormatter";
import { useLocationContext } from "../../../../contexts/LocationContext";

interface IFormInput {
  location: any;
  healthcare: any;
  schedule: any;
}

export function Form() {
  const { access, user } = useSessionContext();
  const { refetchLocation } = useLocationContext();

  const navigate = useNavigate();

  const labels = [
    "Localização",
    "Serviço de saúde",
    "Configurações",
    "Confirmar",
  ];

  const [formData, setFormData] = useState<IFormInput>();
  const [loading, setLoading] = useState(false);

  const [currentStep, setCurrentStep] = useState(0);
  const handleStepSubmit = async (data: any) => {
    if (currentStep === Steps.S4ConfirmationScreen) {
      setLoading(true);
      try {
        const healthcare = await PostHealthcareService(
          formData?.healthcare,
          access.organizationId
        );
        const referenceCalls = {
          locationReference: formData?.location?.locationId
            ? {
                id: formData?.location?.locationId,
                name: formData?.location.name,
              }
            : await PostLocation(
                formData?.location,
                access.organizationId
              )?.then((response: any) => ({
                id: response?.id,
                name: response?.name,
              })),

          healthcareReference: {
            id: healthcare.id,
            name: healthcare.name,
          },
          practitionerReference: formData?.schedule.professionalActor || {
            label: useFormatter.formatNameDisplay(user.name),
            value: `Practitioner/${oids.cpf}-${user.username}`,
          },
        };
        await PostSchedule(formData?.schedule, referenceCalls);

        refetchLocation();
        toast.success("Agenda criada com sucesso");
        localStorage.setItem("scheduleCreated", "true");

        navigate("/schedule");
      } catch (e) {
        toast.error("Não foi possível criar a agenda, tente novamente!");
      } finally {
        setLoading(false);
      }
    }

    setFormData({ ...formData, ...data });
  };

  return (
    <>
      <Stepper activeStep={currentStep} sx={{ width: "90%" }}>
        {labels.map((key, value) => (
          <Step key={value}>
            <StepLabel>{key}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {currentStep === Steps.S1Location && (
        <Location
          onNext={handleStepSubmit}
          setCurrentStep={setCurrentStep}
          defaultValues={formData?.location}
        />
      )}

      {currentStep === Steps.S2HealthCare && (
        <HealthCare
          onNext={handleStepSubmit}
          setCurrentStep={setCurrentStep}
          defaultValues={formData?.healthcare}
        />
      )}

      {currentStep === Steps.S3TimeAvailable && (
        <ScheduleSettings
          healthcareInfo={formData?.healthcare.healthService}
          setCurrentStep={setCurrentStep}
          onNext={handleStepSubmit}
          defaultValues={formData?.schedule}
        />
      )}
      {currentStep === Steps.S4ConfirmationScreen && (
        <ConfirmationScreen
          setCurrentStep={setCurrentStep}
          onNext={handleStepSubmit}
          loading={loading}
          location={formData?.location}
          healthcare={formData?.healthcare}
          schedule={formData?.schedule}
        />
      )}
    </>
  );
}
