import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const InputNumber = function (props: any) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue}
      rules={props.rules}
      render={({ field }) => (
        <TextField
          {...field}
          label={props.label}
          type="number"
          sx={{
            width: "100%",
            mb: 4,
            ...props.sx,
          }}
        />
      )}
    />
  );
};

export default InputNumber;
