import { Stack, Typography } from "@mui/material";
import { FormRadioButton } from "../../../components/form";

export function ScreenChoiseService({ control }: any) {
  const optionsDurationDefault = [
    { display: "Particular", value: "particular" },
    { display: "Convênio médico", value: "convenio" },
  ];

  return (
    <Stack>
      <Typography variant="body1" color="neutral700.main" fontWeight={600}>
        Forma de pagamento
      </Typography>

      <FormRadioButton
        name="typeService"
        label=""
        defaultValue="particular"
        control={control}
        rowOrientation={false}
        selectOptions={optionsDurationDefault}
      />
    </Stack>
  );
}
