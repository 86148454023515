import { Close } from "@mui/icons-material";
import { Dialog, IconButton } from "@mui/material";
import React, { ReactNode } from "react";

interface IProps {
  children: ReactNode;
  handleClose: Function;
  isOpen: boolean;
  mobileView?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  fullWidth?: boolean;
}

export function InativatePractitionerDialog({
  children,
  handleClose,
  isOpen,
  mobileView,
  maxWidth = "sm",
  fullWidth = true,
}: IProps) {
  const handleDialogClose = (event: any, reason: string) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      handleClose(event, reason);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleDialogClose}
      PaperProps={{
        sx: { borderRadius: "10px", padding: 2 },
      }}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      fullScreen={mobileView}
    >
      <IconButton
        onClick={() => handleClose(null, "iconButtonClick")}
        sx={{ position: "absolute", right: 5, top: 5 }}
      >
        <Close />
      </IconButton>
      {children}
    </Dialog>
  );
}
