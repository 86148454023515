import { Event, Person, Settings } from "@mui/icons-material";
import {
  CardActionArea,
  Grid,
  Stack,
  Card,
  Box,
  CardContent,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { usePractitionerRoleContext } from "../../contexts/PractitionerRoleContext";
import { useSessionContext } from "../../contexts/SessionContext";

export function NoScheduleOptions() {
  const { access } = useSessionContext();
  const { userSpecialties } = usePractitionerRoleContext();

  const navigate = useNavigate();

  const cards = [
    {
      name: "Agenda",
      active: true,
      description: "Crie sua primeira agenda",
      icon: <Event fontSize="large" sx={{ color: "primary800.main" }} />,
      onClick: (e: any) => {
        e.preventDefault();
        navigate("/newschedule");
      },
    },
    {
      name: "Profissionais",
      active: access?.roles?.includes("manager"),
      description: "Convide médicos para sua clínica",
      icon: <Person fontSize="large" sx={{ color: "primary800.main" }} />,
      onClick: (e: any) => {
        e.preventDefault();
        navigate("/practitioners");
      },
    },
    {
      name: "Configurações",
      active: true,
      description: "Acesse o menu de configurações da plataforma",
      icon: <Settings fontSize="large" sx={{ color: "primary800.main" }} />,
      onClick: (e: any) => {
        e.preventDefault();
        navigate("/settings");
      },
    },
  ];

  return (
    <Stack width="100%" spacing={2} minHeight="40vh" justifyContent="center">
      <Typography
        variant="body1"
        fontSize={20}
        textAlign="center"
        fontWeight={500}
      >
        Você ainda não possui nenhuma agenda configurada.
      </Typography>
      <Grid
        container
        sx={{ height: "100%", justifyContent: "center" }}
        spacing={2}
      >
        {cards
          ?.filter((e: any) => e?.active)
          ?.map((card, index) => (
            <Grid
              item
              xs={4}
              key={index}
              sx={{
                height: "100%",
              }}
            >
              <Card
                sx={{
                  height: "100%",

                  borderRadius: "8px",
                  border: "1px solid #CBD2E1",
                }}
                onClick={card?.onClick}
              >
                <CardActionArea
                  sx={{
                    minHeight: "250px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center", // Centraliza verticalmente
                    alignItems: "center", // Centraliza horizontalmente
                    height: "100%",
                    textAlign: "center", // Garante alinhamento de texto
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ marginBottom: "-8px" }}>{card.icon}</Box>
                  </Box>
                  <CardContent>
                    <Typography
                      gutterBottom
                      component="div"
                      align="center"
                      fontSize="28px"
                      fontWeight={400}
                    >
                      {card.name}
                    </Typography>

                    <Typography
                      textAlign="center"
                      variant="body1"
                      fontSize="16px"
                      color="neutral700.main"
                      fontWeight={500}
                      mb={2}
                    >
                      {card.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Stack>
  );
}
